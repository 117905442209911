import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'camelCaseToTitle'
})
export class CamelCaseToTitlePipe implements PipeTransform {
    transform(value: string): string {
        // Add a space before each capital letter and capitalize the first letter
        return value.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
    }
}
