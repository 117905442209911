import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import {
  IEarningDeductionComponent,
  IEmployeeDeduction,
  IEmployeeEarning,
} from "src/app/core/interfaces/payroll";
import { EmployeeCtcPayrollDetails } from "src/app/payslip/payslip.utility";

@Injectable({
  providedIn: "root",
})
export class PayrollService {
  private path = "";
  tabIndex: number = 0;
  constructor(private baseService: BaseService) {}

  getPayrollDetailsApi(
    empId: string,
    month: number,
    year: number
  ): Observable<any> {
    this.path = `payslip/monthlyPayslip?employeeId=${empId}&payslipYear=${year}&payslipMonth=${month}`;
    return this.baseService.get(this.path);
  }

  getCtcPayslipDetailsApi(
    empId: string,
    month: number,
    year: number
  ): Observable<EmployeeCtcPayrollDetails> {
    this.path = `ctcPayslip/monthlyPayslip?employeeId=${empId}&payslipYear=${year}&payslipMonth=${month}`;
    return this.baseService.get(this.path);
  }

  calculateMonthlyPayrollApi(year: number, month: number): Observable<any> {
    this.path = `payslip/batchInsertPayslip?payslipYear=${year}&payslipMonth=${month}`;
    return this.baseService.post(this.path);
  }

  getAllPayrollDetailsApi(
    year: number,
    month: number,
    pageNumber: number,
    pageSize: number,
    search?: string
  ): Observable<any> {
    this.path = `payslip/${year}/${month}`;

    // Construct query string manually
    let params = `pageNumber=${pageNumber}&pageSize=${pageSize}`;

    if (search && search.trim() !== "") {
      params += `&search=${search}`;
    }

    // Manually add the query string to the URL
    return this.baseService.get(`${this.path}?${params}`);
  }

  /**
   * Fetch total employees and total payable amount for a given year and month.
   * @param year - Payroll year.
   * @param month - Payroll month.
   * @returns Observable with total employees and total payable amount.
   */
  getTotalEmployeeAndAmountApi(
    payrollYear: number,
    payrollMonth: number
  ): Observable<any> {
    this.path = `payslip/summary/${payrollYear}/${payrollMonth}`;
    return this.baseService.get(this.path);
  }

  getPayrollExcelApi(year, month): Observable<any> {
    this.path = `payrollExport/${year}/${month}`;
    return this.baseService.getExcel(this.path);
  }

  getPayrollExcelSalarySheetApi(year, month): Observable<any> {
    this.path = `payrollExport/payrollExcelSalarySheet/${year}/${month}`;
    return this.baseService.getExcel(this.path);
  }

  getEmployeeMonthlyEarningsApi(
    month,
    year,
    employeeId
  ): Observable<IEmployeeEarning[]> {
    this.path = `empMonthlyEarnings?month=${month}&year=${year}&employeeId=${employeeId}`;
    return this.baseService.get(this.path);
  }

  getEmployeeEarningsKeysApi(): Observable<IEarningDeductionComponent[]> {
    this.path = `options/EMPLOYEE_MONTHLY_EARNINGS`;
    return this.baseService.get(this.path);
  }

  getEmployeeDeductionsKeysApi(): Observable<IEarningDeductionComponent[]> {
    this.path = `options/EMPLOYEE_MONTHLY_DEDUCTIONS`;
    return this.baseService.get(this.path);
  }

  getEmployeeMonthlyDeductionsApi(
    month: number,
    year: number,
    employeeId: string
  ): Observable<IEmployeeDeduction[]> {
    this.path = `monthlyDeductions?month=${month}&year=${year}&employeeId=${employeeId}`;
    return this.baseService.get(this.path);
  }

  postEmployeeMonthlyEarningsApi(data: IEmployeeEarning[]) {
    this.path = "empMonthlyEarnings";
    return this.baseService.post(this.path, data);
  }

  postEmployeeMonthlyDeductionsApi(data: IEmployeeDeduction[]) {
    this.path = "monthlyDeductions";
    return this.baseService.post(this.path, data);
  }

  postEmployeeMonthlyPayroll(
    employeeId,
    payslipYear,
    payslipMonth
  ): Observable<any> {
    this.path = `payslip?employeeId=${employeeId}&payslipYear=${payslipYear}&payslipMonth=${payslipMonth}`;
    return this.baseService.post(this.path);
  }
  /**

   * Delete Employee Monthly Earnings
   * @param monthlyEarningsId The ID of the monthly earnings record to delete
   * @returns Observable<any>
   */

  deleteEmployeeMonthlyEarningsApi(monthlyEarningsId: number): Observable<any> {
    this.path = `empMonthlyEarnings?monthlyEarningsId=${monthlyEarningsId}`;
    return this.baseService.delete(this.path);
  }
  deleteEmployeeMonthlyDeductionsApi(
    monthlyDeductionsId: number
  ): Observable<any> {
    this.path = `monthlyDeductions?monthlyDeductionsId=${monthlyDeductionsId}`;
    return this.baseService.delete(this.path);
  }
}
