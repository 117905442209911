export const ACTIVE_ICONS_DICTIONARY = {
  "/dashboard/home": "../../assets/images/home-logo-dark.svg",
  "/dashboard/empDetails": "../../assets/images/employee-details-dark.svg",
  "/dashboard/payroll": "../../assets/images/payroll-dark.svg",
  "/dashboard/investmentDeclaration":
    "../../assets/images/rupees-icon-dark.svg",
  "/dashboard/userPayroll": "../../assets/images/user-payroll-dark.svg",
  "/dashboard/ytdDetails": "../../assets/images/ytd-details-dark.svg",
  "/dashboard/allEmployeesYtdDetails":
    "../../assets/images/ytd-details-dark.svg",
  "/dashboard/user-settings": "../../assets/images/settings-dark.svg",
  "/dashboard/admin-settings": "../../assets/images/settings-dark.svg",
};

export const DEFAULT_ICONS_DICTIONARY = {
  "/dashboard/home": "../../assets/images/home-logo.svg",
  "/dashboard/empDetails": "../../assets/images/employee-details-logo.svg",
  "/dashboard/payroll": "../../assets/images/payroll-logo.svg",
  "/dashboard/investmentDeclaration":
    "../../assets/images/rupees-icon.svg",
  "/dashboard/userPayroll": "../../assets/images/user-payroll-logo.svg",
  "/dashboard/ytdDetails": "../../assets/images/ytd-details-logo.svg",
  "/dashboard/allEmployeesYtdDetails":
    "../../assets/images/ytd-details-logo.svg",
  "/dashboard/user-settings": "../../assets/images/settings-logo.svg",
  "/dashboard/admin-settings": "../../assets/images/settings-logo.svg",
};

export const HEADER_CONTENT_DICTIONARY = {
  "/dashboard/home": "Home",
  "/dashboard/empDetails": "Employee Details",
  "/dashboard/payroll": "Payroll",
  "/dashboard/investmentDeclaration": "Investment Declaration",
  "/dashboard/userPayroll": "User Payroll",
  "/dashboard/ytdDetails": "YTD Details",
  "/dashboard/allEmployeesYtdDetails": "All Employees YTD Details",
  "/dashboard/user-settings": "User Settings",
  "/dashboard/admin-settings": "Admin Settings",
};
