import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material";

@Component({
  selector: "investment-section-card",
  templateUrl: "./investment-section-card.component.html",
  styleUrls: ["./investment-section-card.component.scss"],
})
export class InvestmentSectionCardComponent implements OnInit {

  @Input() sectionName: string;
  @Input() totalLimit: number;
  @Input() amountEntered: number;
  @Input() tooltip: string;
  @Input() statusRejected?:boolean;
  @Output() isAddingInvestmentToggleEvent = new EventEmitter<string>();

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
  }

  onAddInvestmentClick() {
    this.isAddingInvestmentToggleEvent.emit(this.sectionName);
  }

  formatAmount(amount:number):string{
    if( typeof amount === 'number' && !isNaN(amount)){
      return new Intl.NumberFormat('en-IN',{style:'decimal',minimumFractionDigits: 2}).format(amount);
    }
    return amount ? amount.toString() : "";
  }
}