import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { SilentRequest } from "@azure/msal-browser";
import { of, throwError } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { catchError, mergeMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { SpinnerService } from "../shared/services/spinner.service";
import { TimeBoundFeatureConfiguration } from "../shared/services/admin.service";
import { EmployeeService } from "../shared/services/employee.service";
import { EmployeeDetails } from "../core/interfaces/user";

export const LOCAL_STORAGE_KEYS = {
  userGroups: "userGroups",
  time_bound_features_configurations: "time_bound_features_configurations",
  userLoginInfo: "userLoginInfo",
  employeeDetails: "employeeDetails",
};

@Injectable({
  providedIn: "root",
})
export class LoginService {
  private path = "";
  private _userGroups!: string[];
  private _employeeDetails!: EmployeeDetails;
  BEARER = "Bearer ";
  APPID = "93e0458e-9706-4981-a353-523734594929";
  timeBoundFeaturesConfigs!: TimeBoundFeatureConfiguration[];
  constructor(
    private httpClient: HttpClient,
    private authService: MsalService,
    private router: Router,
    private spinnerService: SpinnerService,
    private employeeService: EmployeeService,
    private ngZone: NgZone
  ) {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.userGroups)) {
      this._userGroups = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.userGroups)
      );
    }
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.employeeDetails)) {
      this._employeeDetails = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.employeeDetails)
      );
    }
  }

  get userGroups(): string[] {
    return this._userGroups;
  }

  get employeeDetails(): EmployeeDetails {
    return this._employeeDetails;
  }

  /**
   * Api to get access token from token minting service.
   * @param idToken id token fetched from azure login
   * @returns token details
   */
  tokenExchangeApi(idToken): Observable<any> {
    let headers = new HttpHeaders({ Authorization: this.BEARER + idToken });
    this.path = `https://tokenmintingsvc.geminisolutions.com/token/exchange/${this.APPID}`;
    return this.httpClient.get(this.path, { headers, responseType: "text" });
  }

  silentRefresh(): Observable<any> {
    const clientId = environment.clientId;
    const account = JSON.parse(
      sessionStorage.getItem(LOCAL_STORAGE_KEYS.userLoginInfo)
    )
      ? JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_KEYS.userLoginInfo))
          .account
      : null;
    if (account) {
      const silentRequest: SilentRequest = {
        scopes: ["openid", "email", `api://${clientId}/payroll`],
        account,
      };
      return this.authService.acquireTokenSilent(silentRequest).pipe(
        tap((res) => {
          sessionStorage.setItem("payrollAccessToken", res.accessToken);
          sessionStorage.setItem("user", JSON.stringify(res));
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
    } else {
      this.ngZone.run(() => {
        this.router.navigate(["login"]);
      });
    }
  }

  addAuthHeader(req: HttpRequest<any>): HttpRequest<any> {
    let accessToken = sessionStorage.getItem("payrollAccessToken");
    return req.clone({
      setHeaders: { Authorization: "Bearer " + accessToken },
    });
  }

  private getUserGroups(token: string): string[] {
    const { groups } = JSON.parse(atob(token.split(".")[1]));
    return groups;
  }

  login() {
    const clientId = environment.clientId;
    this.spinnerService.openSpinnerDialog();
    this.authService
      .loginPopup({ scopes: ["openid", "email", `api://${clientId}/payroll`] })
      .pipe(
        mergeMap((result) => {
          //Setting access token in session storage from tokenExchangeApi
          sessionStorage.setItem("payrollAccessToken", result.accessToken);
          this._userGroups = this.getUserGroups(result.accessToken);
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.userGroups,
            JSON.stringify(this.userGroups)
          );
          sessionStorage.setItem(
            LOCAL_STORAGE_KEYS.userLoginInfo,
            JSON.stringify(result)
          );
          return this.employeeService
            .getEmployeeDetailsByEmailApi(result.account.username)
            .pipe(
              catchError((error) => {
                this.spinnerService.closeSpinnerDialog();
                sessionStorage.removeItem(LOCAL_STORAGE_KEYS.userLoginInfo);
                console.error("Employee Details API error:", error);
                this.ngZone.run(() => {
                  this.router.navigate(["/user-not-found"]);
                });
                return of(null);
              })
            );
        }),
        catchError((error) => {
          this.spinnerService.closeSpinnerDialog();
          console.error("Login API error:", error);
          return of(null);
        })
      )
      .subscribe((res) => {
        if (res) {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.employeeDetails,
            JSON.stringify(res)
          );
          this._employeeDetails = res;
          this.spinnerService.closeSpinnerDialog();
          this.ngZone.run(() => {
            this.router.navigate(["/dashboard/home"]);
          });
        }
      });
  }
}
