import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit } from "@angular/core";
import { TOTAL_INCOME, TOTAL_DEDUCTIONS } from "./home.utility";
import { catchError, take } from "rxjs/operators";
import { of } from "rxjs";
import { NAME_KEY, NET_PAY, PAYSLIP_SUMMARY, MonthlyPayslipTableData, TOTAL_DEDUCTIONS_STRING, TOTAL_INCOME_STRING, USER_DETAILS, UserDetails, } from "../payslip/payslip.utility";
var HomeComponent = /** @class */ (function () {
    function HomeComponent(homeService, _snackBar, snackBarService, payrollService, loginService, employeeService) {
        this.homeService = homeService;
        this._snackBar = _snackBar;
        this.snackBarService = snackBarService;
        this.payrollService = payrollService;
        this.loginService = loginService;
        this.employeeService = employeeService;
        this.showSalary = false;
        this.salaryVisibilityButtonText = "Show Salary";
        this.greeting = "";
        this.isGreetingImageVisible = false;
        this.months = {
            1: "January",
            2: "February",
            3: "March",
            4: "April",
            5: "May",
            6: "June",
            7: "July",
            8: "August",
            9: "September",
            10: "October",
            11: "November",
            12: "December",
        };
        this.years = [];
        this.currentYear = new Date().getFullYear();
        this.monthsWithDays = {
            April: 30,
            May: 31,
            June: 30,
            July: 31,
            August: 31,
            September: 30,
            October: 31,
            November: 30,
            December: 31,
            January: 31,
            February: this.isLeapYear(this.currentYear) ? 29 : 28,
            March: 31,
        };
        this.userDetails = USER_DETAILS;
        this.payrollComponentsArray = [];
        this.durationInSeconds = 5;
        this.payrollSummaryDtoList = [];
    }
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user_details = this.loginService.employeeDetails;
        this.selectedPayslipMonth = this.getMonth();
        this.selectedPayslipYear = this.getYear();
        this.setLWP();
        this.setProfessionalDetails();
        this.getPayrollDetails();
        if (this.user_details) {
            this.empJoiningYear = Number(this.user_details.dateOfJoining.split("/")[2]);
        }
        this.years = Array.from({
            length: this.selectedPayslipYear -
                (this.empJoiningYear || this.selectedPayslipYear - 10) +
                1,
        }, function (_, i) { return _this.selectedPayslipYear - i; });
        this.getEmployeePayslip(this.user_details.employeeId, this.selectedPayslipYear, this.selectedPayslipMonth);
        this.getRandomQuote();
        var d = new Date();
        var currentHour = d.getHours();
        if (currentHour >= 0 && currentHour < 12) {
            this.greeting = "Good Morning";
        }
        else if (currentHour >= 12 && currentHour < 16) {
            this.greeting = "Good Afternoon";
        }
        else {
            this.greeting = "Good Evening";
        }
    };
    HomeComponent.prototype.getPayrollDetails = function () {
        var _this = this;
        this.user_details[NAME_KEY] = this.user_details.firstName + " " + this.user_details.lastName;
        this.payrollService
            .getPayrollDetailsApi(this.user_details.employeeId, this.selectedPayslipMonth, this.selectedPayslipYear)
            .pipe(take(1), catchError(function (error) {
            _this.payslipExistForSelectedYearOrSelectedMonth = false;
            return of(null);
        }))
            .subscribe(function (res) {
            if (res) {
                _this.employeePayrollDetails = res;
                _this.getPdfDetailsAndNetPay();
            }
        });
    };
    HomeComponent.prototype.getPdfDetailsAndNetPay = function () {
        this.payslipExistForSelectedYearOrSelectedMonth = true;
        //for assigning the name to the payslip
        var payslipSummary = this.employeePayrollDetails[PAYSLIP_SUMMARY][0];
        this.fileName = payslipSummary.employeeId + "_" + payslipSummary.payslipMonth + "_" + payslipSummary.fiscal + ".pdf";
        var netPayComponent = this.employeePayrollDetails[PAYSLIP_SUMMARY].find(function (component) { return component.payslipComponent === NET_PAY; });
        this.netPay = netPayComponent ? netPayComponent.componentAmount : 0;
        this.employeePayrollDetailsForPdf = this.pdfDetails(this.employeePayrollDetails);
    };
    HomeComponent.prototype.pdfDetails = function (employeePayrollDetails) {
        var e_1, _a;
        var parsedData = [];
        // Defined for code readability
        var earnings = employeePayrollDetails.earnings;
        var deductions = employeePayrollDetails.deductions;
        // case where API does not return earning or deductions is also handled
        var earningsLength = earnings ? earnings.length : 0;
        var deductionsLength = deductions ? deductions.length : 0;
        var counter = Math.max(earningsLength, deductionsLength);
        if (counter !== 0) {
            for (var i = 0; i < counter; i++) {
                var data = {
                    earningsComponent: earnings
                        ? earnings[i]
                            ? earnings[i].payslipComponent.replace(/_/g, " ")
                            : ""
                        : "",
                    earningsComponentAmount: earnings
                        ? earnings[i]
                            ? earnings[i].componentAmount
                            : ""
                        : "",
                    deductionsComponent: deductions
                        ? deductions[i]
                            ? deductions[i].payslipComponent.replace(/_/g, " ")
                            : ""
                        : "",
                    deductionsComponentAmount: deductions
                        ? deductions[i]
                            ? deductions[i].componentAmount
                            : ""
                        : "",
                };
                parsedData.push(data);
            }
            var totalIncome = 0;
            var totalDeductions = 0;
            try {
                for (var _b = tslib_1.__values(employeePayrollDetails.payslipSummary), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var component = _c.value;
                    if (component.payslipComponent === TOTAL_DEDUCTIONS_STRING) {
                        totalDeductions = component.componentAmount;
                    }
                    else if (component.payslipComponent === TOTAL_INCOME_STRING) {
                        totalIncome = component.componentAmount;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            parsedData.push({
                earningsComponent: "Total Income",
                earningsComponentAmount: totalIncome.toString(),
                deductionsComponent: "Total Deductions",
                deductionsComponentAmount: totalDeductions.toString(),
            });
        }
        return parsedData;
    };
    HomeComponent.prototype.onResize = function (event) {
        this.setIsGreetingImageVisible();
    };
    HomeComponent.prototype.setIsGreetingImageVisible = function () {
        if (window.innerWidth < 1150) {
            this.isGreetingImageVisible = false;
        }
        else {
            this.isGreetingImageVisible = true;
        }
    };
    HomeComponent.prototype.ngAfterViewInit = function () {
        this.setIsGreetingImageVisible();
        if (this.apiResponseReceived) {
            this.drawDonutChart();
        }
    };
    HomeComponent.prototype.drawDonutChart = function () {
        var _this = this;
        this.canvas = document.getElementById("donutChart");
        this.ctx = this.canvas.getContext("2d");
        this.percentages = [this.deductionPercent, this.netPayPercent];
        this.colors = ["#B9E3C6", "#1C7293"];
        this.centerX = this.canvas.width / 3;
        this.centerY = this.canvas.height / 3;
        this.outerRadius = Math.min(this.canvas.width, this.canvas.height) / 3.5;
        this.innerRadius = this.outerRadius / 2;
        this.total = this.percentages.reduce(function (acc, val) { return acc + val; }, 0);
        this.angles = this.percentages.map(function (percentage) { return (percentage / _this.total) * 2 * Math.PI; });
        this.startAngle = -Math.PI / 2;
        this.angles.forEach(function (angle, index) {
            var textX = _this.centerX +
                (_this.outerRadius / 1.3) * Math.cos(_this.startAngle + angle / 2);
            var textY = _this.centerY +
                (_this.outerRadius / 1.3) * Math.sin(_this.startAngle + angle / 2);
            _this.ctx.beginPath();
            _this.ctx.moveTo(_this.centerX, _this.centerY);
            _this.ctx.arc(_this.centerX, _this.centerY, _this.outerRadius, _this.startAngle, _this.startAngle + angle);
            _this.ctx.fillStyle = _this.colors[index];
            _this.ctx.fill();
            _this.ctx.imageSmoothingEnabled = true;
            _this.ctx.font = "12px Arial";
            _this.ctx.fillStyle = "white";
            _this.ctx.textAlign = "center";
            _this.ctx.textBaseline = "middle";
            var percentageText = _this.percentages[index] + "%";
            _this.ctx.fillText(percentageText, textX, textY);
            _this.startAngle += angle;
        });
        // Draw the inner  circle
        this.ctx.beginPath();
        this.ctx.arc(this.centerX, this.centerY, this.innerRadius, 0, 2 * Math.PI);
        this.ctx.fillStyle = "white";
        this.ctx.fill();
        // Draw the outer border
        this.ctx.beginPath();
        this.ctx.arc(this.centerX, this.centerY, this.outerRadius, 0, 2 * Math.PI);
        this.ctx.lineWidth = 10;
        this.ctx.strokeStyle = "#D8EFF0";
        this.ctx.stroke();
    };
    HomeComponent.prototype.clearDonutChart = function () {
        if (this.ctx) {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        }
    };
    HomeComponent.prototype.onShowSalaryClick = function () {
        this.showSalary = !this.showSalary;
        switch (this.showSalary) {
            case true:
                this.salaryVisibilityButtonText = "Hide Salary";
                break;
            case false:
                this.salaryVisibilityButtonText = "Show Salary";
                break;
        }
    };
    HomeComponent.prototype.getRandomQuote = function () {
        var _this = this;
        this.homeService.getRandomQuoteApi().subscribe(function (res) {
            _this.quotesDetails = res;
        }, function (error) {
            _this.openSnackbar("getRandomQuoteApi failed  " + error.error.message, "Close");
            console.log("getRandomQuoteApi failed", error);
        });
    };
    HomeComponent.prototype.getEmployeePayslip = function (empId, year, month) {
        var _this = this;
        this.homeService.getEmployeePayslipApi(empId, year, month).subscribe(function (res) {
            var e_2, _a;
            _this.currentFiscalPayrollDetails = res;
            _this.payrollSummaryDtoList = res.payrollSummaryDtoList;
            var income = 0;
            var deductions = 0;
            var netPay = 0;
            try {
                for (var _b = tslib_1.__values(res.payrollSummaryDtoList), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var amount = _c.value;
                    if (amount.payslipComponent === TOTAL_INCOME) {
                        income = amount.componentAmount;
                    }
                    else if (amount.payslipComponent === TOTAL_DEDUCTIONS)
                        deductions = amount.componentAmount;
                    else
                        netPay = amount.componentAmount;
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
            if (income) {
                _this.netPayPercent = Math.round((netPay / income) * 100);
                _this.deductionPercent = Math.round((deductions / income) * 100);
            }
            _this.apiResponseReceived = true;
            _this.drawDonutChart();
        }, function (error) {
            _this.apiResponseReceived = false;
            _this.currentFiscalPayrollDetails = null;
            _this.clearDonutChart();
            if (error.status === 500 || error.status === 404) {
                _this.openSnackbar("Some issue occurred while loading payslip. Please contact the backend team to resolve this.", "Close");
            }
            else if (error.status === 403) {
                _this.openSnackbar("you are not authorized to access this resource", "Close");
            }
            else {
                var message = error.error.message + " for " + [
                    _this.months[_this.selectedPayslipMonth],
                ] + " " + _this.selectedPayslipYear;
                _this.snackBarService.add({
                    message: message,
                    action: "Close",
                    config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
                });
            }
        });
    };
    HomeComponent.prototype.onMonthOrYearChange = function () {
        if (this.selectedPayslipYear === this.getYear()) {
            if (this.selectedPayslipMonth > this.getMonth()) {
                this.selectedPayslipMonth = 1;
            }
        }
        this.getEmployeePayslip(this.user_details.employeeId, this.selectedPayslipYear, this.selectedPayslipMonth);
        this.getPayrollDetails();
        this.setLWP();
    };
    HomeComponent.prototype.isLeapYear = function (year) {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };
    HomeComponent.prototype.openSnackbar = function (message, action) {
        this._snackBar.open(message, action, {
            duration: this.durationInSeconds * 1000,
            panelClass: ["custom-snackbar-class"],
        });
    };
    HomeComponent.prototype.getMonth = function () {
        return new Date().getMonth() > 0 ? new Date().getMonth() : 12;
    };
    HomeComponent.prototype.getYear = function () {
        return new Date().getMonth() > 0
            ? new Date().getFullYear()
            : new Date().getFullYear() - 1;
    };
    HomeComponent.prototype.setLWP = function () {
        var _this = this;
        if (this.user_details) {
            this.payrollService
                .getEmployeeMonthlyDeductionsApi(this.selectedPayslipMonth, this.selectedPayslipYear, this.user_details.employeeId)
                .subscribe(function (lwp) {
                var element = lwp.find(function (element) { return element.deduction.toLowerCase() === "lwp"; });
                _this.user_details.lwpCount =
                    element && element.amount ? element.amount : 0;
                _this.setEffectiveWorkingDays(_this.user_details.lwpCount);
            });
        }
    };
    HomeComponent.prototype.setProfessionalDetails = function () {
        if (this.user_details && this.employeeService.employeePersonalDetails) {
            var _a = this.employeeService.employeePersonalDetails, department = _a.department, designation = _a.designation;
            this.user_details.department = department;
            this.user_details.designation = designation;
        }
    };
    HomeComponent.prototype.setEffectiveWorkingDays = function (lwpCount) {
        var daysInMonth = new Date(this.selectedPayslipYear, this.selectedPayslipMonth, 0).getDate();
        this.user_details.effectiveWorkingDays = daysInMonth - lwpCount;
    };
    return HomeComponent;
}());
export { HomeComponent };
