<div class="container-fluid">
  <div>
    <div class="nav">
      <div class="nav-heading">Add Employee Details</div>
      <div class="close-butn-div">
        <button mat-icon-button mat-dialog-close class="close-btn">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <mat-horizontal-stepper
      labelPosition="linear"
      #stepper
      [linear]="true"
      (selectionChange)="onStepChange()"
    >
      <ng-template #stepperIcon>
        <img
          [src]="
            getIconPath(stepper?.selectedIndex === 0 ? 0 : 4) ||
            '../../assets/images/personal-details-icon.svg'
          "
          alt=""
        />
      </ng-template>
      <mat-step
        [stepControl]="personalDetailsFormGroup"
        label="Personal Details"
      >
        <mat-divider class="line"></mat-divider>
        <form [formGroup]="personalDetailsFormGroup" class="text-center mt-2">
          <div class="scroller">
            <div class="row">
              <div class="col-lg-4">
                <div class="label-container">
                  <label for="fullName" class="my-label"
                    >Full Name<span style="color: red">*</span></label
                  >
                </div>
                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    stringsOnly
                    autocomplete="off"
                    matInput
                    formControlName="fullName"
                    placeholder="Ex. Ajay Singh"
                    id="fullName"
                  />
                </mat-form-field>
                <ng-container
                  *ngIf="
                    personalDetailsFormGroup.controls.fullName.invalid &&
                    (personalDetailsFormGroup.controls.fullName.dirty ||
                      personalDetailsFormGroup.controls.fullName.touched)
                  "
                >
                  <mat-error class="error-message">
                    {{ personalDetailsErrorMessage("fullName") }}
                  </mat-error>
                </ng-container>
              </div>
              <div class="col-lg-4">
                <div class="label-container">
                  <label for="email" class="my-label"
                    >Email Address<span style="color: red">*</span></label
                  >
                </div>
                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    autocomplete="off"
                    required
                    matInput
                    formControlName="emailAddress"
                    placeholder="Email address"
                    id="email"
                    required
                  />
                </mat-form-field>
                <ng-container
                  *ngIf="
                    personalDetailsFormGroup.controls.emailAddress?.invalid &&
                    personalDetailsFormGroup.controls.emailAddress?.touched
                  "
                >
                  <mat-error class="error-message">
                    {{ personalDetailsErrorMessage("emailAddress") }}
                  </mat-error>
                </ng-container>
              </div>
              <div class="col-lg-4">
                <div class="label-container">
                  <label for="employeeCode" class="my-label"
                    >Employee Code<span style="color: red">*</span></label
                  >
                </div>
                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    autocomplete="off"
                    matInput
                    formControlName="employeeCode"
                    placeholder="Employee Code"
                    id="employeeCode"
                    required
                  />
                </mat-form-field>
                <ng-container
                  *ngIf="
                    personalDetailsFormGroup.controls.employeeCode?.invalid &&
                    (personalDetailsFormGroup.controls.employeeCode?.dirty ||
                      personalDetailsFormGroup.controls.employeeCode?.touched)
                  "
                >
                  <mat-error class="error-message">
                    {{ personalDetailsErrorMessage("employeeCode") }}
                  </mat-error>
                </ng-container>
              </div>
              <div class="col-lg-4">
                <div class="label-container">
                  <label for="doj" class="my-label"
                    >DOJ<span style="color: red">*</span></label
                  >
                </div>
                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    autocomplete="off"
                    matInput
                    formControlName="dateOfJoining"
                    placeholder="Date Of Joining"
                    required
                    id="doj"
                    [matDatepicker]="picker2"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker2">
                    <mat-icon matDatepickerToggleIcon>
                      <img
                        src="../../assets/images/date-icon.svg"
                        alt=""
                        style="width: 20px"
                      />
                    </mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker2 color="primary"></mat-datepicker>
                </mat-form-field>
                <ng-container
                  *ngIf="
                    personalDetailsFormGroup.controls.dateOfJoining?.invalid &&
                    (personalDetailsFormGroup.controls.dateOfJoining?.dirty ||
                      personalDetailsFormGroup.controls.dateOfJoining?.touched)
                  "
                >
                  <mat-error class="error-message">
                    {{ personalDetailsErrorMessage("dateOfJoining") }}
                  </mat-error>
                </ng-container>
              </div>
              <div class="col-lg-4">
                <div class="label-container">
                  <label for="dob" class="my-label"
                    >DOB<span style="color: red">*</span></label
                  >
                </div>

                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    autocomplete="off"
                    matInput
                    formControlName="dateOfBirth"
                    placeholder="Date Of Birth"
                    id="dob"
                    required
                    [matDatepicker]="picker1"
                    [max]="maxDate"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker1">
                    <mat-icon matDatepickerToggleIcon>
                      <img
                        src="../../assets/images/date-icon.svg"
                        alt=""
                        style="width: 20px"
                      />
                    </mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker1 color="primary"></mat-datepicker>
                </mat-form-field>
                <ng-container
                  *ngIf="
                    personalDetailsFormGroup.controls.dateOfBirth?.invalid &&
                    (personalDetailsFormGroup.controls.dateOfBirth?.dirty ||
                      personalDetailsFormGroup.controls.dateOfBirth?.touched)
                  "
                >
                  <mat-error class="error-message">
                    {{ personalDetailsErrorMessage("dateOfBirth") }}
                  </mat-error>
                </ng-container>
              </div>
              <div class="col-lg-4">
                <div class="label-container">
                  <label for="pan" class="my-label"
                    >Permanent Account Number<span style="color: red"
                      >*</span
                    ></label
                  >
                </div>
                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    autocomplete="off"
                    matInput
                    formControlName="pan"
                    placeholder="Permanent Account Number"
                    id="pan"
                    required
                  />
                </mat-form-field>
                <ng-container
                  *ngIf="
                    personalDetailsFormGroup.controls.pan?.invalid &&
                    (personalDetailsFormGroup.controls.pan?.dirty ||
                      personalDetailsFormGroup.controls.pan?.touched)
                  "
                >
                  <mat-error class="error-message">
                    {{ personalDetailsErrorMessage("pan") }}
                  </mat-error>
                </ng-container>
              </div>
              <div class="col-lg-4">
                <div class="label-container">
                  <label for="aadhar" class="my-label"
                    >Aadhar Card Number<span style="color: red">*</span></label
                  >
                </div>
                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    autocomplete="off"
                    numbersOnly
                    matInput
                    maxlength="12"
                    formControlName="aadhaar"
                    placeholder="Aadhar Card Number"
                    id="aadhar"
                    required
                  />
                </mat-form-field>
                <ng-container
                  *ngIf="
                    personalDetailsFormGroup.controls.aadhaar?.invalid &&
                    (personalDetailsFormGroup.controls.aadhaar?.dirty ||
                      personalDetailsFormGroup.controls.aadhaar?.touched)
                  "
                >
                  <mat-error class="error-message">
                    {{ personalDetailsErrorMessage("aadhaar") }}
                  </mat-error>
                </ng-container>
              </div>
              <div class="col-lg-4">
                <div class="label-container">
                  <label for="uan" class="my-label"
                    >UAN number<span style="color: red">*</span></label
                  >
                </div>
                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    autocomplete="off"
                    numbersOnly
                    matInput
                    formControlName="uanNo"
                    placeholder="UAN Number"
                    id="uan"
                    required
                  />
                </mat-form-field>
                <ng-container
                  *ngIf="
                    personalDetailsFormGroup.controls.uanNo?.invalid &&
                    (personalDetailsFormGroup.controls.uanNo?.dirty ||
                      personalDetailsFormGroup.controls.uanNo?.touched)
                  "
                >
                  <mat-error class="error-message">
                    {{ personalDetailsErrorMessage("uanNo") }}
                  </mat-error>
                </ng-container>
              </div>
              <div class="col-lg-4">
                <div class="label-container">
                  <label for="pf" class="my-label"
                    >PF Number<span style="color: red">*</span></label
                  >
                </div>
                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    autocomplete="off"
                    matInput
                    formControlName="pfNo"
                    placeholder="PF Number"
                    id="pf"
                    required
                  />
                </mat-form-field>
                <ng-container
                  *ngIf="
                    personalDetailsFormGroup.controls.pfNo?.invalid &&
                    (personalDetailsFormGroup.controls.pfNo?.dirty ||
                      personalDetailsFormGroup.controls.pfNo?.touched)
                  "
                >
                  <mat-error class="error-message">
                    {{ personalDetailsErrorMessage("pfNo") }}
                  </mat-error>
                </ng-container>
              </div>
              <div class="col-lg-4">
                <div class="label-container">
                  <label for="permAddress" class="my-label"
                    >Permanent Address<span style="color: red">*</span></label
                  >
                </div>
                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    autocomplete="off"
                    matInput
                    formControlName="permenantAddress"
                    placeholder="Ex. 1 Main St, New York, NY"
                    id="permAddress"
                    (input)="permanentAddressChanged($event)"
                    required
                  />
                </mat-form-field>
                <ng-container
                  *ngIf="
                    personalDetailsFormGroup.controls.permenantAddress
                      ?.invalid &&
                    (personalDetailsFormGroup.controls.permenantAddress
                      ?.dirty ||
                      personalDetailsFormGroup.controls.permenantAddress
                        ?.touched)
                  "
                >
                  <mat-error class="error-message">
                    {{ personalDetailsErrorMessage("permenantAddress") }}
                  </mat-error>
                </ng-container>
              </div>
              <div class="col-lg-4">
                <div class="label-container">
                  <label for="corrAdddress" class="my-label"
                    >Correspondence Address</label
                  >
                  &nbsp;
                  <mat-checkbox
                    [matTooltip]="
                      disableCheckbox
                        ? 'Permanent address is empty'
                        : 'Correspondence address same as permanent address'
                    "
                    matTooltipPosition="above"
                    [disabled]="disableCheckbox"
                    formControlName="isChecked"
                    (change)="changeCheckboxValue($event)"
                    [ngStyle]="{
                      cursor: disableCheckbox ? 'not-allowed' : 'pointer'
                    }"
                  ></mat-checkbox>
                </div>
                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    autocomplete="off"
                    matInput
                    formControlName="correspondenceAddress"
                    placeholder="Ex. 1 Main St, New York, NY"
                    id="corrAdddress"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="footer">
            <button mat-button class="cancel-butn" mat-dialog-close>
              Cancel
            </button>
            <button
              mat-button
              matStepperNext
              (click)="onNextClick(personalDetailsFormGroup)"
              class="next-butn"
            >
              Next
            </button>
            <button type="reset" style="display: none" #resetBtn1></button>
          </div>
        </form>
      </mat-step>
      <ng-template #stepperIcon>
        <img
          [src]="
            getIconPath(stepper?.selectedIndex === 1 ? 1 : 4) ||
            '../../assets/images/bank-icon.svg'
          "
          alt=""
        />
        <!-- <img src="../../assets/images/bank-details-active-icon.svg" alt=""> -->
      </ng-template>
      <mat-step [stepControl]="bankDetailsFormGroup" label="Bank Details">
        <mat-divider class="line"></mat-divider>
        <form [formGroup]="bankDetailsFormGroup" class="text-center mt-2">
          <div class="scroller">
            <div class="row justify-content-center">
              <div class="col-lg-4">
                <div class="label-container">
                  <label for="bankAccountNo" class="my-label"
                    >Bank Account Number<span style="color: red">*</span></label
                  >
                </div>
                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    numbersOnly
                    autocomplete="off"
                    matInput
                    required
                    formControlName="bankAcctNo"
                    placeholder="Bank Account Number"
                    id="bankAccountNo"
                  />
                </mat-form-field>
                <ng-container
                  *ngIf="
                    bankDetailsFormGroup.controls.bankAcctNo?.invalid &&
                    (bankDetailsFormGroup.controls.bankAcctNo?.dirty ||
                      bankDetailsFormGroup.controls.bankAcctNo?.touched)
                  "
                >
                  <mat-error class="error-message">
                    {{ bankDetailsErrorMessage("bankAcctNo") }}
                  </mat-error>
                </ng-container>
                <div class="label-container">
                  <label for="bankAccountName" class="my-label"
                    >Bank Name<span style="color: red">*</span></label
                  >
                </div>
                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    stringsOnly
                    autocomplete="off"
                    matInput
                    formControlName="bankAcctName"
                    placeholder="Bank Name"
                    id="bankAccountName"
                  />
                </mat-form-field>
                <ng-container
                  *ngIf="
                    bankDetailsFormGroup.controls.bankAcctName?.invalid &&
                    (bankDetailsFormGroup.controls.bankAcctName?.dirty ||
                      bankDetailsFormGroup.controls.bankAcctName?.touched)
                  "
                >
                  <mat-error class="error-message">
                    {{ bankDetailsErrorMessage("bankAcctName") }}
                  </mat-error>
                </ng-container>
              </div>
              <div class="col-lg-4">
                <div class="label-container">
                  <label for="bankAccountAdd" class="my-label"
                    >Bank Account Address<span style="color: red"
                      >*</span
                    ></label
                  >
                </div>
                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    autocomplete="off"
                    matInput
                    formControlName="bankAcctAddress"
                    placeholder="Bank Account Address"
                    id="bankAccountAdd"
                  />
                </mat-form-field>
                <ng-container
                  *ngIf="
                    bankDetailsFormGroup.controls.bankAcctAddress?.invalid &&
                    (bankDetailsFormGroup.controls.bankAcctAddress?.dirty ||
                      bankDetailsFormGroup.controls.bankAcctAddress?.touched)
                  "
                >
                  <mat-error class="error-message">
                    {{ bankDetailsErrorMessage("bankAcctAddress") }}
                  </mat-error>
                </ng-container>
                <div class="label-container">
                  <label for="bankAccountIFSC" class="my-label"
                    >Bank Account IFSC<span style="color: red">*</span></label
                  >
                </div>
                <mat-form-field appearance="outline" class="mat-elevation-z3">
                  <input
                    autocomplete="off"
                    matInput
                    formControlName="bankIFSC"
                    placeholder="Bank Account IFSC"
                    id="bankAccountIFSC"
                  />
                </mat-form-field>
                <ng-container
                  *ngIf="
                    bankDetailsFormGroup.controls.bankIFSC?.invalid &&
                    (bankDetailsFormGroup.controls.bankIFSC?.dirty ||
                      bankDetailsFormGroup.controls.bankIFSC?.touched)
                  "
                >
                  <mat-error class="error-message">
                    {{ bankDetailsErrorMessage("bankIFSC") }}
                  </mat-error>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="footer">
            <button mat-button matStepperPrevious class="cancel-butn">
              Previous
            </button>
            <button
              mat-button
              matStepperNext
              (click)="onNextClick(bankDetailsFormGroup)"
              class="next-butn"
            >
              Next
            </button>
            <button type="reset" style="display: none" #resetBtn2></button>
          </div>
        </form>
      </mat-step>
      <ng-template #stepperIcon>
        <img
          [src]="
            getIconPath(stepper?.selectedIndex === 2 ? 2 : 4) ||
            '../../assets/images/ctc-icon.svg'
          "
          alt=""
        />
      </ng-template>
      <mat-step [stepControl]="ctcDetailsFormGroup" label="CTC Details">
        <mat-divider class="line"></mat-divider>
        <form
          [formGroup]="ctcDetailsFormGroup"
          class="text-center mt-2"
          *ngIf="CTCComponentsDetails.length ? true : false"
        >
          <div class="scroller">
            <button
              matTooltip="Click here to calculate payroll"
              class="cal-btn float-right mr-2"
              (click)="calculateCTC()"
            >
              <img
                width="30px"
                height="30px"
                src="../../assets/images/calculator.svg"
              />
            </button>
            <div class="row">
              <div
                [ngClass]="
                  ctcComponentDetail &&
                  ctcComponentDetail.type &&
                  ctcComponentDetail.type.toLowerCase() !== 'monthly'
                    ? 'col-lg-4'
                    : 'col-0'
                "
                *ngFor="
                  let ctcComponentDetail of CTCComponentsDetails;
                  index as i
                "
              >
                <ng-container
                  *ngIf="
                    ctcComponentDetail &&
                    ctcComponentDetail.componentName &&
                    ctcComponentDetail.componentName.toLowerCase() !== 'pf' &&
                    ctcComponentDetail.type &&
                    ctcComponentDetail.type.toLowerCase() !== 'monthly'
                  "
                >
                  <div class="label-container">
                    <label for="{{ 'ctcComp' + i }}" class="my-label">{{
                      ctcComponentDetail.componentName
                    }}</label>
                  </div>
                  <mat-form-field
                    appearance="outline"
                    class="mat-elevation-z3"
                    [ngClass]="{
                      'readonly-form-field':
                        ctcComponentDetail &&
                        ctcComponentDetail.type &&
                        ctcComponentDetail.type.toLowerCase() === 'total'
                    }"
                  >
                    <input
                      numbersOnly
                      autocomplete="off"
                      matInput
                      [formControlName]="
                        ctcComponentDetail.componentName.toLowerCase()
                      "
                      [placeholder]="ctcComponentDetail.componentName"
                      id="{{ 'ctcComp' + i }}"
                      [readonly]="
                        ctcComponentDetail &&
                        ctcComponentDetail.type &&
                        ctcComponentDetail.type.toLowerCase() === 'total'
                      "
                    />
                  </mat-form-field>
                </ng-container>
                <ng-container
                  *ngIf="
                    ctcComponentDetail &&
                    ctcComponentDetail.componentName &&
                    ctcComponentDetail.componentName.toLowerCase() === 'pf'
                  "
                >
                  <div class="label-container">
                    <label for="{{ 'ctcComp' + i }}" class="my-label"
                      >{{ ctcComponentDetail.componentName
                      }}<span style="color: red">*</span></label
                    >
                  </div>
                  <mat-form-field appearance="outline" class="mat-elevation-z3">
                    <mat-select
                      [formControlName]="
                        ctcComponentDetail.componentName.toLowerCase()
                      "
                      required
                      placeholder="Select PF Preference"
                      id="{{ 'ctcComp' + i }}"
                    >
                      <mat-option
                        *ngFor="let pfPreferenceType of pfPreferenceTypes"
                        [value]="pfPreferenceType"
                      >
                        {{
                          pfPreferenceType === "NA"
                            ? pfPreferenceType
                            : pfPreferenceType
                        }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
                <div
                  *ngIf="
                    ctcDetailsFormGroup.get(
                      ctcComponentDetail.componentName.toLowerCase()
                    ) &&
                    ctcDetailsFormGroup.get(
                      ctcComponentDetail.componentName.toLowerCase()
                    ).invalid &&
                    (ctcDetailsFormGroup.get(
                      ctcComponentDetail.componentName.toLowerCase()
                    ).dirty ||
                      ctcDetailsFormGroup.get(
                        ctcComponentDetail.componentName.toLowerCase()
                      ).touched)
                  "
                >
                  <mat-error class="error-message">
                    {{
                      ctcDetailsErrorMessage(
                        ctcComponentDetail.componentName.toLowerCase()
                      )
                    }}
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
          <div class="footer">
            <button
              mat-button
              matStepperPrevious
              class="action-btn"
              style="right: 191px; margin-top: 11px"
            >
              Previous
            </button>
            <button
              mat-button
              class="next-butn"
              style="top: 5px; right: 110px; margin-top: 11px"
              (click)="onNextClick(ctcDetailsFormGroup)"
              matStepperNext
            >
              Next
            </button>

            <button
              mat-button
              (click)="openReset(resetDialog)"
              class="action-btn"
              style="right: 25px; margin-top: 11px"
            >
              Reset
            </button>
            <button type="reset" style="display: none" #resetBtn3></button>
          </div>
        </form>
      </mat-step>
      <ng-template #stepperIcon>
        <img
          [src]="
            getIconPath(stepper.selectedIndex === 3 ? 3 : 4) ||
            '../../assets/images/review-details-icon.svg'
          "
          alt=""
        />
      </ng-template>
      <mat-step label="Review Details">
        <div class="review-details-stepper">
          <mat-tab-group #tabGroup mat-align-tabs="start">
            <mat-tab label="PERSONAL DETAILS">
              <div class="scrollable-container">
                <div class="table-wrapper">
                  <table>
                    <tr *ngFor="let detail of personalFormDetails">
                      <td class="detail-type">
                        {{ detail?.type | camelCaseToTitle }}
                      </td>
                      <td
                        class="detail-value"
                        *ngIf="detail?.value && !isValidDate(detail.value)"
                      >
                        {{ detail?.value }}
                      </td>
                      <td
                        class="detail-value"
                        *ngIf="detail?.value && isValidDate(detail.value)"
                      >
                        {{ detail?.value | date: "dd/MM/yyyy" }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="footer footer-dark">
                <button
                  mat-button
                  class="cancel-butn cancel-dark"
                  mat-dialog-close
                >
                  Cancel
                </button>
                <button mat-button class="next-butn" (click)="nextTab()">
                  Next
                </button>
              </div>
            </mat-tab>

            <mat-tab label="BANK DETAILS">
              <div class="scrollable-container">
                <div class="table-wrapper">
                  <table>
                    <tr *ngFor="let detail of bankFormDetails">
                      <td class="detail-type">
                        {{ detail?.type | camelCaseToTitle }}
                      </td>
                      <td class="detail-value">{{ detail?.value }}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="footer footer-dark">
                <button
                  mat-button
                  class="cancel-butn prev-light"
                  (click)="previousTab()"
                >
                  Previous
                </button>
                <button mat-button class="next-butn" (click)="nextTab()">
                  Next
                </button>
              </div>
            </mat-tab>

            <mat-tab label="CTC DETAILS">
              <div class="scrollable-container">
                <div class="table-wrapper">
                  <table>
                    <tr *ngFor="let detail of ctcFormDetails">
                      <td class="detail-type">
                        {{ detail?.type }}
                      </td>
                      <td class="detail-value">{{ detail?.value }}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="footer footer-dark">
                <button
                  mat-button
                  class="cancel-butn prev-light"
                  (click)="previousTab()"
                >
                  Previous
                </button>
                <button
                  mat-button
                  class="next-butn"
                  (click)="postEmployeeDetails()"
                >
                  Submit
                </button>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-step>
      <ng-template matStepperIcon="number" let-index="index">
        <ng-container
          [ngTemplateOutlet]="matStepperIcons && matStepperIcons[index]"
        ></ng-container>
      </ng-template>
      <ng-template matStepperIcon="edit" let-index="index">
        <ng-container
          [ngTemplateOutlet]="matStepperIcons && matStepperIcons[index]"
        ></ng-container>
      </ng-template>
      <ng-template matStepperIcon="done" let-index="index">
        <ng-container
          [ngTemplateOutlet]="matStepperIcons && matStepperIcons[index]"
        ></ng-container>
      </ng-template>
      <ng-template matStepperIcon="error" let-index="index">
        <ng-container
          [ngTemplateOutlet]="matStepperIcons && matStepperIcons[index]"
        ></ng-container>
      </ng-template>
    </mat-horizontal-stepper>
  </div>
</div>
<ng-template #resetDialog>
  <div style="padding: 24px">
    <p class="reset">Do you want to reset?</p>
    <div class="btn-optn d-flex">
      <button
        disableRipple
        mat-raised-button
        class="btn"
        (click)="onRestYesClick()"
      >
        Yes
      </button>
      <span></span>
      <button mat-raised-button class="btn" (click)="onResetNoClick()">
        No
      </button>
    </div>
  </div>
</ng-template>
