/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-payroll.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../payslip/payslip.component.ngfactory";
import * as i3 from "../payslip/payslip.component";
import * as i4 from "../shared/services/employee.service";
import * as i5 from "../shared/services/snackbar.service";
import * as i6 from "../shared/services/payroll.service";
import * as i7 from "../shared/services/spinner.service";
import * as i8 from "../account/login.service";
import * as i9 from "./user-payroll.component";
var styles_UserPayrollComponent = [i0.styles];
var RenderType_UserPayrollComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserPayrollComponent, data: {} });
export { RenderType_UserPayrollComponent as RenderType_UserPayrollComponent };
export function View_UserPayrollComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "card "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "card-title row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col gem-surface-secondary "]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Your Payslip for the month of ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "card-content pr-3 py-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-payslip", [], null, [[null, "dataEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dataEvent" === en)) {
        var pd_0 = (_co.receiveData($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PayslipComponent_0, i2.RenderType_PayslipComponent)), i1.ɵdid(7, 114688, null, 0, i3.PayslipComponent, [i4.EmployeeService, i5.SnackBarService, i6.PayrollService, i7.SpinnerService, i8.LoginService], { userPayroll: [0, "userPayroll"] }, { dataEvent: "dataEvent" })], function (_ck, _v) { var currVal_1 = true; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.months[_co.selectedPayslipMonth]; _ck(_v, 4, 0, currVal_0); }); }
export function View_UserPayrollComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-payroll", [], null, null, null, View_UserPayrollComponent_0, RenderType_UserPayrollComponent)), i1.ɵdid(1, 49152, null, 0, i9.UserPayrollComponent, [], null, null)], null, null); }
var UserPayrollComponentNgFactory = i1.ɵccf("app-user-payroll", i9.UserPayrollComponent, View_UserPayrollComponent_Host_0, {}, {}, []);
export { UserPayrollComponentNgFactory as UserPayrollComponentNgFactory };
