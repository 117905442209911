<div class="dialog-wrapper">
  <div [ngClass]="getDialogImgBackgroundColorClass()" class="dialog-img">
    <img
      [src]="getImgUrl()"
      [ngClass]="{ 'logout-img': isLogoutDialog() }"
      alt="img"
    />
  </div>
  <div class="dialog-content">
    <div class="title">{{ data?.title }}</div>
    <div class="msg" [innerHTML]="data.msg"></div>
    <div *ngIf="data.selectedCtcTab !== undefined && !data.selectedCtcTab">
      <section class="component-section mt-5">
        <div class="overflow-scroll">
          <div
            class="ctc-component-card py-3 px-2 mb-3"
            *ngFor="let element of data.ctcCompData; let i = index"
          >
            <div class="row m-0 w-100">
              <div class="col-md-4 mb-md-0 mb-2">
                <div class="text">CTC Component Name</div>
                <div class="data">
                  {{
                    element && element.componentName
                      ? element.componentName
                      : "-"
                  }}
                </div>
              </div>
              <div class="col-md-4 mb-md-0 mb-2">
                <div class="text">Component Type</div>
                <div class="data">
                  {{ element && element.type ? element.type : "-" }}
                </div>
              </div>
              <div
                [ngClass]="{
                  'tax-component': !isExisting
                }"
                class="col-md-4 mb-md-0 mb-2"
              >
                <div>
                  <div class="text">Taxable Component?</div>
                  <div class="data">
                    {{
                      element && element.taxable
                        ? element.taxable === "Y"
                          ? "YES"
                          : "NO"
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div *ngIf="data.selectedCtcTab !== undefined && data.selectedCtcTab">
      <section class="component-section mt-5">
        <div class="overflow-scroll">
          <div
            class="ctc-component-card py-3 px-2 mb-3"
            *ngFor="let element of data.ctcCompData; let i = index"
          >
            <div class="row m-0 w-100">
              <div class="col-md-3 mb-md-0 mb-2">
                <div class="text">CTC Component Name</div>
                <div class="data">
                  {{
                    element && element.deductionName
                      ? element.deductionName
                      : "-"
                  }}
                </div>
              </div>
              <div class="col-md-3 mb-md-0 mb-2">
                <div class="text">Component Type</div>
                <div class="data">
                  {{ element && element.type ? element.type : "-" }}
                </div>
              </div>

              <div class="col-md-3 mb-md-0 mb-2">
                <div class="text">Limit</div>
                <div class="data">
                  {{
                    element && element.deductionLimit !== null
                      ? "₹" + element.deductionLimit
                      : "-"
                  }}
                </div>
              </div>
              <div
                [ngClass]="{
                  'tax-component': !isExisting
                }"
                class="col-md-3 mb-md-0 mb-2"
              >
                <div>
                  <div class="text">Tax-Saving Component?</div>
                  <div class="data">
                    {{
                      element &&
                      element.isTaxSaving !== null &&
                      element.isTaxSaving !== undefined
                        ? element.isTaxSaving
                          ? "YES"
                          : "NO"
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="button-list">
      <button
        [ngClass]="getDialogNoBtnClass()"
        mat-button
        mat-dialog-close
        disableRipple
        (click)="onNoClick()"
      >
        {{ data?.noButtonText || "No" }}
      </button>
      <button
        [ngClass]="getDialogYesBtnClass()"
        mat-button
        mat-dialog-close
        (click)="data?.onYesClickFunction()"
        class="yes-button"
      >
        {{ data?.yesButtonText || "Yes" }}
      </button>
    </div>
  </div>
</div>
