import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { YtdDetailsService } from "../shared/services/ytd-details.service";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { SnackBarService } from "../shared/services/snackbar.service";
import { EmployeeDetails } from "../core/interfaces/user";
import { LoginService } from "../account/login.service";

const HEADERS: { displayName: string; mapping: string }[] = [
  { displayName: "Income", mapping: "Income" },
  { displayName: "Deductions", mapping: "Deductions" },
  { displayName: "Days Worked", mapping: "DAYS" },
  { displayName: "Net Pay", mapping: "Net Pay" },
];

export const MONTHS_MAPPING: { [month: string]: number } = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11,
};
class EmployeeYtdTableData {
  items = "";
  total = 0;
  april = 0;
  may = 0;
  june = 0;
  july = 0;
  august = 0;
  september = 0;
  october = 0;
  november = 0;
  december = 0;
  january = 0;
  february = 0;
  march = 0;

  constructor(items = "") {
    this.items = items;
  }
}

@Component({
  selector: "app-ytd-details",
  templateUrl: "./ytd-details.component.html",
  styleUrls: ["./ytd-details.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class YtdDetailsComponent implements OnInit {
  @ViewChildren("tdElements") tdElements: QueryList<ElementRef>;

  /** Columns displayed in the table. */
  displayedColumns = Object.keys(new EmployeeYtdTableData());
  employeeDetails: EmployeeDetails;
  expandedElement = null;
  ytdDetailsApiData: any;
  ytdDetailsData: { [section: string]: EmployeeYtdTableData[] } = {};
  itemSectionMapping: { [item: string]: string } = {};
  ytdDetailsComponentData = {};
  headers = HEADERS;
  years: number[] = [];
  fiscal: any = getCurrentFiscalYear();
  ytdData: any;
  showSpinner: boolean = true;

  constructor(
    private ytdDetailsService: YtdDetailsService,
    private snackBarService: SnackBarService,
    private loginService: LoginService
  ) {}
  ngOnInit() {
    this.employeeDetails = this.loginService.employeeDetails;
    this.years = Array.from(
      new Array(11),
      (x, i) => getCurrentFiscalYear() - i
    );
    this.getYtdDetails();
  }

  dataProcessing(ytdDetailsApiData) {
    for (const el of ytdDetailsApiData) {
      if (el.components) {
        this.ytdDetailsData[el.category] = [];
        for (const componentKey in el.components) {
          const dataObj = new EmployeeYtdTableData(componentKey);
          this.itemSectionMapping[componentKey] = el.category;
          for (const dataItem of el.components[componentKey]) {
            dataObj[dataItem.payrollKey.toLowerCase()] = dataItem.amount;
          }
          if (el.category === "DAYS") {
            dataObj["total"] = dataObj["total working days"];
          }
          this.ytdDetailsData[el.category].push(dataObj);
        }
      }
    }
  }

  isIncome(row: EmployeeYtdTableData): boolean {
    return this.itemSectionMapping[row.items] === "INCOME";
  }

  isDeduction(row: EmployeeYtdTableData): boolean {
    return this.itemSectionMapping[row.items] === "DEDUCTIONS";
  }

  getYtdDetails() {
    const id = this.employeeDetails.employeeId;
    this.ytdDetailsData = {};
    this.ytdDetailsService.getYtdDetailsAPI(id, this.fiscal).subscribe(
      (res) => {
        this.ytdData = res;
        this.dataProcessing(res);
      },
      (err) => {
        this.snackBarService.add({
          message: "Get all Payroll Details api failed",
          action: "close",
          config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
        });
        this.showSpinner = false;
      }
    );
  }

  getYearByMonth(month: string): string {
    if (month === "january" || month === "february" || month === "march") {
      return `${this.fiscal + 1}`.substring(2, 4);
    } else {
      return `${this.fiscal}`.substring(2, 4);
    }
  }

  isMonthName(col: string): boolean {
    const months: string[] = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ];
    const lowerCaseStr = col.toLowerCase();
    return months.includes(lowerCaseStr);
  }

  getMonthAbbreviation(monthName: string): string {
    let abbreviation = monthName.substring(0, 3);
    abbreviation = abbreviation.charAt(0).toUpperCase() + abbreviation.slice(1);
    return abbreviation;
  }

  getTdId(i: number, j: number, k: number): string {
    return `td-${i}-${j}-${k}`;
  }

  showTooltip(tdId: string): boolean {
    const tdElement = document.getElementById(tdId);
    if (tdElement) {
      const tdWidth = tdElement.offsetWidth;
      const contentWidth = tdElement.scrollWidth;
      return contentWidth > tdWidth;
    }
  }

  shouldDisplayWorkingDays(col, header) {
    const date = new Date();
    const currMonth = date.getMonth();

    if (header.mapping === "DAYS" && col !== "total") {
      if (currMonth > 3) {
        if (MONTHS_MAPPING[col] >= currMonth || MONTHS_MAPPING[col] < 3) {
          return false;
        }
      } else {
        if (
          currMonth === 3 ||
          (MONTHS_MAPPING[col] >= currMonth && MONTHS_MAPPING[col] < 3)
        ) {
          return false;
        }
      }
    }
    return true;
  }

  shouldDisplayValue(col, header) {
    return col !== "items" && this.shouldDisplayWorkingDays(col, header);
  }
}
