import {
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  DIALOG_TYPE_IMG_URL_DICT,
  PayrollDialogComponentInput,
} from "./payroll-dialog.utility";

@Component({
  selector: "app-payroll-dialog",
  templateUrl: "./payroll-dialog.component.html",
  styleUrls: ["./payroll-dialog.component.scss"],
})
export class PayrollDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PayrollDialogComponentInput
  ) {}

  ngOnInit() {}

  getDialogYesBtnClass() {
    switch (this.data.dialogType) {
      case "post":
        return "post-dialog-yes-btn";
      case "delete":
        return "delete-dialog-yes-btn";
      case "logout":
        return "post-dialog-yes-btn";
    }
  }

  getDialogImgBackgroundColorClass() {
    switch (this.data.dialogType) {
      case "post":
        return "post-dialog-img-background";
      case "delete":
        return "delete-dialog-img-background";
      case "logout":
        return "post-dialog-img-background";
    }
  }

  getDialogNoBtnClass() {
    switch (this.data.dialogType) {
      case "post":
        return "post-dialog-no-btn";
      case "delete":
        return "delete-dialog-no-btn";
      case "logout":
        return "post-dialog-no-btn";
    }
  }

  getImgUrl(): string {
    return DIALOG_TYPE_IMG_URL_DICT[this.data.dialogType];
  }

  onNoClick(){
   if(this.data.onNoClickFunction){
    this.data.onNoClickFunction();
   }
  }
  
  isLogoutDialog(): boolean {
    return this.data.dialogType === 'logout';
  }
}
