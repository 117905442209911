import { Component, OnDestroy, OnInit } from "@angular/core";

import { MatDialog, MatSnackBar } from "@angular/material";
import { EmployeeService } from "../shared/services/employee.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { InvestmentDeclarationService } from "../shared/services/investment-declaration.service";
import { forkJoin } from "rxjs";
import * as moment from "moment";
import { SnackBarService } from "../shared/services/snackbar.service";
import { SpinnerService } from "../shared/services/spinner.service";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { LOCAL_STORAGE_KEYS, LoginService } from "../account/login.service";
import { TimeBoundFeatureConfiguration } from "../shared/services/admin.service";
import { addHouse } from "./investment-declaration.util";
import { finalize, take } from "rxjs/operators";
import { PayrollDialogComponent } from "../payroll-dialog/payroll-dialog.component";
import { PayrollDialogComponentInput } from "../payroll-dialog/payroll-dialog.utility";

@Component({
  selector: "investment-declaration",
  templateUrl: "./investment-declaration.component.html",
  styleUrls: ["./investment-declaration.component.scss"],
})
export class InvestmentDeclarationComponent implements OnInit, OnDestroy {
  fiscal: any = getCurrentFiscalYear();
  investmentDeclarationForm: FormGroup;
  allSections: any = [];
  user_details: any;
  investmentBySectionData: any = [];
  limits: any = [];
  investmentViaIdDict = {};
  sectionTotalAmount: { [sectionName: string]: number } = {};
  investmentDeclarationFormData = null;
  years: number[] = [];
  isFormReady = false;
  isFormSubmitted = false;
  timeBoundFeaturesConfigs: TimeBoundFeatureConfiguration[];
  isInvestmentDeclarationWindowOpen = false;
  houses: any[] = [];
  fiscalMonths: string[] = [];
  groupNames: any;
  isAddingInvestmentDict: { [sectionName: string]: boolean } = {};
  isPreviewScreen = false;
  months = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];
  incomeTaxResponse: any;
  selectedRegime: string;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private snackBarService: SnackBarService,
    private employeeService: EmployeeService,
    private formBuilder: FormBuilder,
    private spinnerService: SpinnerService,
    private investmentDeclarationService: InvestmentDeclarationService,
    private loginService: LoginService
  ) {
    this.user_details = JSON.parse(localStorage.getItem("user_details")) as any;
    this.timeBoundFeaturesConfigs = loginService.timeBoundFeaturesConfigs
      ? loginService.timeBoundFeaturesConfigs
      : (JSON.parse(
          localStorage.getItem(
            LOCAL_STORAGE_KEYS.time_bound_features_configurations
          )
        ) as TimeBoundFeatureConfiguration[]);
    this.isInvestmentDeclarationWindowOpen =
      this.getIsInvestmentDeclarationWindowOpen();
  }
  ngOnDestroy(): void {
    //this.updateAppContentWrapperHeight("calc(100% - 74px)");
  }
  ngOnInit(): void {
    this.updateAppContentWrapperHeight("auto");
    const currYear = getCurrentFiscalYear();
    for (let i = currYear; i >= currYear - 15; i--) {
      this.years.push(i);
    }
    this.fiscalMonths = this.generateFiscalMonths(this.fiscal);
    this.investmentDeclarationForm = this.formBuilder.group({
      investmentSections: this.formBuilder.group({}), //form group
    });
    this.getInvestmentDelarationSection();
    this.getInvestmentLimit();
    if (!this.employeeService.userDetails) {
      this.employeeService.userDetails = JSON.parse(
        localStorage.getItem("user_details")
      );
    }
    // disabling form on the basis of time bound configurations
    if (!this.isInvestmentDeclarationWindowOpen) {
      this.investmentDeclarationForm.disable();
    }
  }

  updateAppContentWrapperHeight(value) {
    const element = document.querySelector("#appContentWrapper") as HTMLElement;
    element.style.height = value;
  }
  onFiscalYearChange() {
    this.investmentDeclarationService
      .getEmployeeInvestDeclarationByEmpId(
        this.employeeService.userDetails.employeeId,
        this.fiscal
      )
      .pipe(
        take(1),
        finalize(() => {
          this.isFormReady = true;
        })
      )
      .subscribe(
        (data) => {
          this.investmentDeclarationFormData = data;

          //To disable form if already submitted
          this.formAlreadySubmitted();
        },
        (err) => {
          this.snackBarService.add({
            message: `It's taking a bit long than expected, please try to reload the page.`,
            action: "close",
            config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
          });
          this.isFormReady = true;
        }
      );
    this.fiscalMonths = this.generateFiscalMonths(parseInt(this.fiscal));
  }
  expandedElements: boolean[] = new Array(this.allSections.length).fill(false);

  createFormGroup(data: any) {
    let fg = this.formBuilder.group({});
    for (let i = 0; i <= data.length - 1; i++) {
      fg.addControl(
        data[i].investmentLabelName,
        new FormControl(null, [Validators.maxLength(8)])
      );
    }
    return fg;
  }
  createFormGroupWithValues(data: any, values: any) {
    let fg = this.formBuilder.group({});
    for (let i = 0; i <= data.length - 1; i++) {
      let valueExist = false;
      for (let x of values) {
        if (x.investmentViaId == data[i].investmentViaId) {
          fg.addControl(
            data[i].investmentLabelName,
            new FormControl(x.amount, [Validators.maxLength(8)])
          );
          valueExist = true;
          break;
        }
      }
      if (!valueExist) {
        fg.addControl(
          data[i].investmentLabelName,
          new FormControl(null, [Validators.maxLength(8)])
        );
      }
    }
    return fg;
  }
  /*For changing the expansion mat icon*/
  changeArrow(i: number) {
    this.expandedElements[i] = !this.expandedElements[i];
  }
  investmentViaIdMapping(data: any) {
    for (let i = 0; i <= data.length - 1; i++) {
      this.investmentViaIdDict[data[i].investmentLabelName] =
        data[i].investmentViaId;
    }
  }

  getLimit(sectionName: string) {
    return this.limits.filter((limit) => limit.section === sectionName)[0]
      .investmentLimit;
  }

  //FORK JOIN APPROACH REPLACING ASYNC
  getInvestmentDelarationSection() {
    this.investmentDeclarationService
      .getInvestmentDelarationSectionApi()
      .subscribe(
        (res) => {
          this.allSections = res;
          this.setInitialValuesForIsAddingInvestmentFlags(this.allSections);
          let observablesArr = [];
          for (let i = 0; i <= this.allSections.length - 1; i++) {
            const sectionName = this.allSections[i].optionValue;
            const sectionGroup = this.formBuilder.group({});
            this.investmentSectionsForm.addControl(sectionName, sectionGroup);
            // Setting section wise total amount to zero
            this.sectionTotalAmount[this.allSections[i].optionValue] = 0;
            observablesArr.push(
              this.investmentDeclarationService.getInvestmentBySectionApi(
                this.allSections[i].optionValue
              )
            );
          }
          forkJoin(observablesArr).subscribe((data) => {
            this.investmentBySectionData = data;
            for (let val of this.investmentBySectionData) {
              this.addAbstractControlsToFormGroups(val);
              this.investmentViaIdMapping(val);
            }
            this.investmentDeclarationService
              .getEmployeeInvestDeclarationByEmpId(
                this.employeeService.userDetails.employeeId,
                this.fiscal
              )
              .pipe(
                take(1),
                finalize(() => {
                  this.isFormReady = true;
                })
              )
              .subscribe((data) => {
                this.investmentDeclarationFormData = data;

                //To disable form if already submitted
                this.formAlreadySubmitted();
              });
          });
        },
        (error) => {
          this.snackBarService.add({
            message: `It's taking a bit long than expected, please try to reload the page.`,
            action: "close",
            config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
          });
          this.isFormReady = true;
          console.log("getInvestmentDelarationSectionApi failed", error);
        }
      );
  }

    formAlreadySubmitted() {
      if (this.investmentDeclarationFormData.length !== 0) {
        this.isFormSubmitted = true;
        for (let val of this.investmentBySectionData) {
          let sections = (
            this.investmentDeclarationForm.get("investmentSections") as FormGroup
          ).get(val[0].section);

          for (let i = 0; i <= val.length - 1; i++) {
            let valueExist = false;
            for (let x of this.investmentDeclarationFormData) {
              if (val[0].section === "HRA") {
                if (x.hras.length && x.investmentViaId == 20) {
                  this.houses = [];
                  const hra = (
                    this.investmentDeclarationForm.get(
                      "investmentSections"
                    ) as FormGroup
                  ).get("HRA") as FormGroup;
                  x.hras.forEach((hraItem, index) => {
                    const fromFiscalYear =
                      parseInt(x.fiscal) +
                      (hraItem.fromMonth >= 1 && hraItem.fromMonth <= 3 ? 1 : 0);
                    const toFiscalYear =
                      parseInt(x.fiscal) +
                      (hraItem.toMonth >= 1 && hraItem.toMonth <= 3 ? 1 : 0);
                    const fromMonthName = moment()
                      .month(hraItem.fromMonth - 1)
                      .format("MMMM");
                    const toMonthName = moment()
                      .month(hraItem.toMonth - 1)
                      .format("MMMM");
                    const fromMonthLabel = fromMonthName + " " + fromFiscalYear;
                    const toMonthLabel = toMonthName + " " + toFiscalYear;

                    const fromMonthOption = this.fiscalMonths.find(
                      (month) => month == fromMonthLabel
                    );
                    const toMonthOption = this.fiscalMonths.find(
                      (month) => month == toMonthLabel
                    );
                    addHouse(
                      this.investmentSectionsForm.get("HRA") as FormGroup,
                      this.houses,
                      this.formBuilder
                    );
                    const hraForm = hra.get(`House ${index + 1}`);
                    hraForm.patchValue({
                      fromMonth: fromMonthOption,
                      toMonth: toMonthOption,
                      monthlyRent: x.hras[index].monthlyRentAmount,
                      metroCity: x.hras[index].metroCity,
                    });
                  });
                  break;
                }
              } else {
                if (x.investmentViaId == val[i].investmentViaId) {
                  sections.get(val[i].investmentLabelName).patchValue(x.amount);
                  valueExist = true;
                  break;
                }
              }
            }
            if (!valueExist && val[0].section !== 'HRA') {
              sections.get(val[i].investmentLabelName).patchValue(null);
            }
          }
          this.updateSectionTotalAmount(val[0].section);
        }
        // disabling the form
        this.investmentDeclarationForm.disable();
      } else {
        this.loadFormData(null, true);
        this.investmentDeclarationForm.enable();
      }
    }

  getInvestmentLimit() {
    this.investmentDeclarationService.getInvestmentLimitApi().subscribe(
      (res) => {
        this.limits = res;
      },
      (error) => {
        console.log("getInvestmentLimitApi failed", error);
      }
    );
  }

  onSubmitButtonClick(): void {
    if (this.isHRAFormValid()) {
      this.openSubmissionDialog();
    } else {
      this.openInvalidHRAFormDialog();
    }
  }

  openHRAForm(): void {
    this.isPreviewScreen = false;
    this.toggleIsAddingInvestment("HRA");
  }

  openInvalidHRAFormDialog() {
    let dialogRef = this.dialog.open(PayrollDialogComponent, {
      data: {
        title: "Invalid",
        msg: "HRA form is incomplete. Do you want to be redirected to HRA form?",
        dialogType: "delete",
        onYesClickFunction: () => this.openHRAForm(),
      } as PayrollDialogComponentInput,
    });
  }

  openSubmissionDialog(): void {
    let dialogRef = this.dialog.open(PayrollDialogComponent, {
      data: {
        title: "Confirmation",
        msg: `Do you want to submit investment declaration for fiscal year ${
          this.fiscal
        }-${this.fiscal + 1}?`,
        dialogType: "post",
        onYesClickFunction: () => this.postIncomeTax(),
      } as PayrollDialogComponentInput,
    });
  }

  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: ["custom-snackbar-class"],
    });
  }

  updateTotalAmountForHRA() {
    for (let i = 0; i < this.houses.length; i++) {
      const houseForm = this.investmentSectionsForm
        .get("HRA")
        .get("House " + (i + 1)) as FormGroup;
      const monthlyRent = houseForm.get("monthlyRent").value;
      const fromMonth = this.getMonthNumber(houseForm.get("fromMonth").value);
      const toMonth = this.getMonthNumber(houseForm.get("toMonth").value);
      // months are 1 base index and plus one is to include toMonth in the duration
      const duration =
        (toMonth < 4 ? toMonth + 12 : toMonth) -
        (fromMonth < 4 ? fromMonth + 12 : fromMonth) +
        1;
      if (monthlyRent) {
        this.sectionTotalAmount["HRA"] += parseFloat(monthlyRent) * duration;
      }
    }
  }

  updateSectionTotalAmount(sectionName: string) {
    this.sectionTotalAmount[sectionName] = 0;
    if (sectionName === "HRA") {
      this.updateTotalAmountForHRA();
    } else {
      let amounts =
        this.investmentDeclarationForm.value.investmentSections[sectionName];
      for (let amount in amounts) {
        if (amounts[amount]) {
          this.sectionTotalAmount[sectionName] += parseInt(amounts[amount]);
        }
      }
    }
  }

  getIsInvestmentDeclarationWindowOpen(): boolean {
    let startDate: Date;
    let endDate: Date;
    let todaysDate = new Date();
    for (let featureConfigs of this.timeBoundFeaturesConfigs) {
      if (featureConfigs.featureName === "Investment Declaration") {
        startDate = new Date(featureConfigs.startDate);
        endDate = new Date(featureConfigs.endDate);
      }
    }
    if (todaysDate <= endDate && todaysDate >= startDate) {
      return true;
    }
    return false;
  }

  generateFiscalMonths(year: number): string[] {
    const fiscalMonths: string[] = [];
    for (let i = 0; i < this.months.length; i++) {
      fiscalMonths.push(`${this.months[i]} ${year + Math.floor(i / 9)}`);
    }
    return fiscalMonths;
  }

  get investmentSectionsForm() {
    return this.investmentDeclarationForm.get(
      "investmentSections"
    ) as FormGroup;
  }
  addAbstractControlsToFormGroups(data: any) {
    if (data[0].section !== "HRA") {
      for (let i = 0; i <= data.length - 1; i++) {
        (
          this.investmentSectionsForm.get(data[0].section) as FormGroup
        ).addControl(data[i].investmentLabelName, new FormControl(null));
      }
    } else {
      addHouse(
        this.investmentSectionsForm.get("HRA") as FormGroup,
        this.houses,
        this.formBuilder
      );
    }
  }
  getFormGroupsArray() {
    this.groupNames = [];
    const section = [];
    for (let key in this.investmentSectionsForm.controls) {
      section.push(this.investmentSectionsForm.controls[key]);
      this.groupNames.push(key);
    }
    return section;
  }

  getMonthNumber(monthName: string): number {
    const month = monthName.split(" ")[0];
    return moment(month, "MMM").month() + 1;
  }

  getSectionInputNames(sectionValue) {
    return Object.keys(sectionValue);
  }

  toggleIsAddingInvestment(sectionName) {
    this.isAddingInvestmentDict[sectionName] =
      !this.isAddingInvestmentDict[sectionName];
    this.isAddingInvestmentDict[sectionName]
      ? this.updateAppContentWrapperHeight("calc(100% - 70px")
      : this.updateAppContentWrapperHeight("auto");
  }

  openNextSection(currentSectionName) {
    this.isAddingInvestmentDict[currentSectionName] = false;
    let isNextSection = false;
    for (const sectionName in this.isAddingInvestmentDict) {
      if (isNextSection) {
        this.isAddingInvestmentDict[sectionName] = true;
        break;
      }
      if (sectionName === currentSectionName) {
        isNextSection = true;
      }
    }
  }

  setInitialValuesForIsAddingInvestmentFlags(sections) {
    sections.forEach((section) => {
      this.isAddingInvestmentDict[section.optionValue] = false;
    });
  }

  isAddingInvestment(): boolean {
    for (let sectionName in this.isAddingInvestmentDict) {
      if (this.isAddingInvestmentDict[sectionName]) {
        return true;
      }
    }
    return false;
  }

  resetSectionAmount(sectionName: string) {
    this.sectionTotalAmount[sectionName] = 0;
  }

  resetAllSectionAmount() {
    for (const sectionName in this.sectionTotalAmount) {
      this.sectionTotalAmount[sectionName] = 0;
    }
  }

  saveInvestmentDeclarationForm() {
    const formData = this.investmentDeclarationForm.value;
    localStorage.setItem(
      "investmentDeclarationFormData",
      JSON.stringify(formData)
    );
  }

  loadFormData(sectionName?: string, isLoadingFirstTime = false) {
    const savedData = localStorage.getItem("investmentDeclarationFormData");
    this.houses = [{}];
    if (savedData) {
      const parsedSavedData = JSON.parse(savedData);
      // if there are more that one houses in the saved form data we need to add those as controls in the investmentDeclarationForm
      if (isLoadingFirstTime) {
        const houses = Object.keys(
          parsedSavedData["investmentSections"]["HRA"]
        );
        if (houses.length > 1) {
          for (let i = 1; i < houses.length; i++) {
            addHouse(
              this.investmentDeclarationForm
                .get("investmentSections")
                .get("HRA") as FormGroup,
              this.houses,
              this.formBuilder
            );
          }
        }
      }
      this.investmentDeclarationForm.setValue(parsedSavedData);
      const controls = (
        this.investmentDeclarationForm.get("investmentSections") as FormGroup
      ).controls;
      for (const controlName in controls) {
        this.updateSectionTotalAmount(controlName);
      }
    } else {
      if (sectionName) {
        (
          this.investmentDeclarationForm
            .get("investmentSections")
            .get(sectionName) as FormGroup
        ).reset();
        this.updateSectionTotalAmount(sectionName);
      }
    }
  }

  toggleIsPreviewScreen() {
    if (this.isPreviewScreen) {
      this.isPreviewScreen = false;
    } else {
      this.getIncomeTaxComparison();
    }
  }

  removeHouse(index: number) {
    this.houses.pop();
  }

  getIncomeTaxComparison() {
    const payloadArray = this.getInvestmentDeclarationPayload();
    this.spinnerService.openSpinnerDialog();
    this.investmentDeclarationService
      .getIncomeTaxComparisonApi(
        this.fiscal,
        this.user_details.employeeId,
        payloadArray
      )
      .pipe(
        finalize(() => {
          this.spinnerService.closeSpinnerDialog();
          this.isPreviewScreen = !this.isPreviewScreen;
        })
      )
      .subscribe((res) => {
        this.incomeTaxResponse = res;
      });
  }

  postIncomeTax() {
    const employeeInvestmentDeclarationListDTO =
      this.getInvestmentDeclarationPayload();
    const empTaxRegimeDTO = {
      employeeId: this.user_details.employeeId,
      fiscal: this.fiscal as string,
      taxRegimeType: this.selectedRegime,
    };
    const requestBody = {
      employeeId: this.user_details.employeeId,
      employeeInvestmentDeclarationListDTO,
      empTaxRegimeDTO,
    };
    this.spinnerService.openSpinnerDialog();
    this.investmentDeclarationService
      .postIncomeTaxApi(requestBody)
      .pipe(
        finalize(() => {
          this.spinnerService.closeSpinnerDialog();
        })
      )
      .subscribe(
        (res) => {
          this.snackBarService.add({
            message: res.message,
            config: {
              duration: 2000,
            },
          });
          this.investmentDeclarationService
            .getEmployeeInvestDeclarationByEmpId(
              this.employeeService.userDetails.employeeId,
              this.fiscal
            )
            .subscribe((data) => {
              this.investmentDeclarationFormData = data;
              this.formAlreadySubmitted();
            });
        },
        (err) => {
          this.snackBarService.add({
            message: err.error.message,
            config: {
              duration: 2000,
            },
          });
        }
      );
  }
/*
Don't use this funcution after the investemnt data is submitted
*/
  isHRAFormValid(): boolean {
    const hraFrom = (
      this.investmentDeclarationForm.get("investmentSections") as FormGroup
    ).get("HRA");
    if (Object.keys(hraFrom.value).length === 1) {
      let isHouse1Untouched = true;
      const house = "House 1"
        for (const key of Object.keys(hraFrom.value[house])) {
          console.log(hraFrom.value[house][key]);
          if (hraFrom.value[house][key] !== null && hraFrom.value[house][key] !== false) {
            console.log(hraFrom.value[house][key])
            isHouse1Untouched=false;
            break;
          }
        }
      
      if (isHouse1Untouched) {
        return true;
      }
    }
    return hraFrom.valid;
  }

  getInvestmentDeclarationPayload() {
    const investmentSectionsValues =
      this.investmentDeclarationForm.value.investmentSections;
    let keys = Object.keys(this.investmentViaIdDict);
    const payloadArray = [];
    let amounts = {};
    for (let sectionKey in investmentSectionsValues) {
      if (sectionKey !== "HRA") {
        amounts = { ...amounts, ...investmentSectionsValues[sectionKey] };
      } else {
        let payload = {};
        payload["amount"] = 0;
        payload["employeeId"] = this.user_details.employeeId;
        payload["fiscal"] = this.fiscal;
        payload["investmentViaId"] = this.investmentViaIdDict["Rent Paid"];
        payload["status"] = "Active";
        payload["hras"] = [];
        for (
          let houseIndex = 0;
          houseIndex < this.houses.length;
          houseIndex++
        ) {
          let houseKey = `House ${houseIndex + 1}`;
          let hraForm = this.investmentSectionsForm.get("HRA").get(houseKey);
          if (hraForm.status === "VALID" || hraForm.status === "DISABLED") {
            let hraPayload = {
              fromMonth: this.getMonthNumber(hraForm.get("fromMonth").value),
              toMonth: this.getMonthNumber(hraForm.get("toMonth").value),
              monthlyRentAmount: hraForm.get("monthlyRent").value,
              metroCity: hraForm.get("metroCity").value || false,
            };
            payload["hras"].push(hraPayload);
          }
        }

        payload["amount"] = this.sectionTotalAmount["HRA"];
        if (payload["amount"] !== 0) {
          payloadArray.push(payload);
        }
      }
    }

    for (let key of keys) {
      if (amounts[key] !== null && key != "Rent Paid") {
        let amount = amounts[key];
        let inv_id = this.investmentViaIdDict[key];
        let payload = {};

        payload["amount"] = amount;
        payload["employeeId"] = this.user_details.employeeId;
        payload["fiscal"] = this.fiscal;
        payload["investmentViaId"] = inv_id;
        payload["status"] = "Active";
        payload["hras"] = [];
        payloadArray.push(payload);
      }
    }
    return payloadArray;
  }

  getNumberOfRowsArray(noOfInputs: number): number[] {
    const itemsPerRow = 3; // Updated items per row to 3
    const arrLength =
      noOfInputs % itemsPerRow === 0
        ? noOfInputs / itemsPerRow
        : Math.ceil(noOfInputs / itemsPerRow);
    return Array.from({ length: arrLength }, (_, index) => index);
  }

  getRowWiseSections(rowNumber) {
    const sections = this.getFormGroupsArray().slice(
      rowNumber * 3,
      (rowNumber + 1) * 3
    );
    return sections;
  }

  setSelectedRegime(regimeType: string) {
    this.selectedRegime = regimeType;
  }

  resetInvestmentDeclarationForm() {
    const hraFormGroup = (
      this.investmentDeclarationForm.get("investmentSections") as FormGroup
    ).get("HRA") as FormGroup;
    hraFormGroup.controls = {};
    this.houses.length = 0;
    addHouse(hraFormGroup, this.houses, this.formBuilder);
    (
      this.investmentDeclarationForm.get("investmentSections") as FormGroup
    ).reset();
    this.resetAllSectionAmount();
    this.saveInvestmentDeclarationForm();
    this.snackBarService.add({
      message: "Reset successful",
      config: { duration: 2000 },
    });
  }
}
