<div>
  <div class="form-wrapper">
    <form [formGroup]="deductionsFormGroup">
      <div class="row">
        <div class="col">
          <div>
            <div class="d-flex justify-content-center">
              <mat-spinner *ngIf="showSpinner" [diameter]="60"></mat-spinner>
            </div>
            <ng-container *ngIf="!showSpinner">
              <div class="row inputs-row">
                <ng-container *ngFor="let deduction of employeeDeductionsList">
                  <div class="col col-3 input-wrapper">
                    <div class="d-flex align-items-center">
                      <label class="inv_title mb-0">
                        {{ deduction?.deduction | replaceUnderscore }}
                      </label>
                      <button
                        mat-icon-button
                        color="warn"
                        class="small-delete-icon"
                        (click)="
                          deleteDeduction(
                            deduction?.monthlyDeductionsId,
                            deduction?.deduction
                          )
                        "
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                    <br />
                    <input
                      numbersOnly
                      (input)="onControlInput(deduction?.deduction)"
                      (keyup)="restrictInput($event, deduction?.deduction)"
                      autocomplete="off"
                      [formControlName]="deduction?.deduction"
                      type="text"
                      id="deduction?.optionId"
                    />
                    <div
                      *ngIf="
                        deductionsFormGroup.controls[deduction?.deduction]
                          .invalid &&
                        deductionsFormGroup.controls[deduction?.deduction]
                          .touched
                      "
                      class="error-message"
                    >
                      <span
                        *ngIf="
                        deductionsFormGroup.controls[deduction?.deduction]?.hasError(
                            'required'
                          )
                        "
                        >Amount is required</span
                      >
                      <span
                        *ngIf="
                        deductionsFormGroup.controls[deduction?.deduction]?.hasError(
                            'min'
                          )
                        "
                        >Zero value is not allowed</span
                      >
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="row">
    <div class="col text-center add">
      <button
        type="button"
        (click)="openAddDeductionModal()"
        class="btn btn-primary add-button"
      >
        <mat-icon class="small-icon mr-1">add</mat-icon> Add Deduction Component
      </button>
    </div>
  </div>
</div>

<ng-template #addDeductionModal>
  <div class="modal-overlay">
    <div class="modal-content">
      <div class="modal-header">
        <div>Add Deduction Component</div>
      </div>
      <div class="modal-body">
        <form [formGroup]="addDeductionFormGroup">
          <div class="form-group">
            <label for="deduction">Component Name</label>
            <mat-select
              formControlName="deduction"
              id="deduction"
              placeholder="Select Component Name"
              [disabled]="filteredDeductionComponents.length === 0"
              matTooltip="No component available"
              [matTooltipDisabled]="filteredDeductionComponents.length > 0"
            >
              <mat-option
                *ngFor="let deduction of filteredDeductionComponents"
                [value]="deduction.optionValue"
              >
                {{ deduction.optionName | replaceUnderscore }}
              </mat-option>
            </mat-select>

            <div
              *ngIf="
                addDeductionFormGroup.controls['deduction'].invalid &&
                addDeductionFormGroup.controls['deduction'].touched
              "
              class="error-message"
            >
              Component is required
            </div>
          </div>
          <div class="form-group">
            <label for="amount">Amount</label>
            <input
              matInput
              formControlName="amount"
              id="amount"
              type="text"
              numbersOnly
              class="input-field"
              (keyup)="
                restrictInput(
                  $event,
                  addDeductionFormGroup.controls['deduction'].value
                )
              "
              [disabled]="addDeductionFormGroup.controls['amount'].disabled"
            />
            <div
              *ngIf="
                addDeductionFormGroup.controls['amount'].invalid &&
                addDeductionFormGroup.controls['amount'].touched
              "
              class="error-message"
            >
              <span
                *ngIf="
                  addDeductionFormGroup.controls['amount'].hasError('required')
                "
                >Amount is required</span
              >
              <span
                *ngIf="addDeductionFormGroup.controls['amount'].hasError('min')"
                >Zero value is not allowed</span
              >
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button mat-button mat-dialog-close class="cancel-button">
          Cancel
        </button>
        <button
          mat-button
          mat-dialog-close
          class="submit-button"
          [disabled]="addDeductionFormGroup.invalid"
          (click)="addControlToDeductionsForm()"
        >
          Add
        </button>
      </div>
    </div>
  </div>
</ng-template>
