import { EventEmitter, } from "@angular/core";
var DndDirective = /** @class */ (function () {
    function DndDirective() {
        this.fileDropped = new EventEmitter();
    }
    // Dragover listener
    DndDirective.prototype.onDragOver = function (evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = true;
    };
    // Dragleave listener
    DndDirective.prototype.onDragLeave = function (evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
    };
    // Drop listener
    DndDirective.prototype.ondrop = function (evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        var files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    };
    return DndDirective;
}());
export { DndDirective };
