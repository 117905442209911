<div
  style="position: absolute; left: -9999px; height: 297mm; width: 196mm"
  [id]="selectedTabIndex === 0 ? 'userPayslipPDF' : 'userCtcPayslipPDF'"
>
  <div>
    <div class="pdf-head">Cauchy Hardy AS Technologies Pvt. Ltd.</div>
    <div class="address">
      RZ H-324-0 NO. 5-A, KH-62/16-G NO-6-R,<br />
      RAJ NAGAR-II NEW DELHI - 110077
    </div>
    <div class="pdf-subhead">
      Payslip for the month of {{ selectedPayslipMonth }} -
      {{ selectedPayslipYear }}
    </div>
    <br />
    <table class="table-pdf details">
      <ng-container
        *ngFor="
          let pair of getPairsForPdf(userDetails);
          trackBy: trackByUserDetail
        "
      >
        <tr class="table-pdf-row">
          <td class="pdf-cell">{{ pair[0]?.label }}</td>
          <td class="pdf-cell">
            <ng-container *ngIf="pair[0]?.objKey; else bankName">
              {{ selectedEmployeeDetails[pair[0]?.objKey] }}
            </ng-container>
            <ng-template #bankName> Yes Bank Ltd </ng-template>
          </td>
          <ng-container *ngIf="pair[1]">
            <td class="pdf-cell">{{ pair[1]?.label }}</td>
            <td class="pdf-cell">
              <ng-container *ngIf="pair[1]?.objKey; else bankName">
                {{ selectedEmployeeDetails[pair[1]?.objKey] }}
              </ng-container>
              <ng-template #bankName> Yes Bank Ltd </ng-template>
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </table>

    <br />
    <table class="table-pdf payslip">
      <tr class="table-pdf-row">
        <td
          class="pdf-cell"
          *ngFor="
            let header of selectedTabIndex === 0 ? headers_monthly : headers_ctc;
            trackBy: trackByHeader
          "
        >
          <b>{{ header[1] }}</b>
        </td>
      </tr>
      <tr
        class="table-pdf-row"
        *ngFor="
          let data of employeePayrollDetailsForPdf;
          trackBy: trackByPayrollDetail
        "
      >
        <td
          class="pdf-cell"
          *ngFor="
            let header of selectedTabIndex === 0 ? headers_monthly : headers_ctc;
            trackBy: trackByHeader
          "
        >
          {{ data[header[0]] }}
        </td>
      </tr>
    </table>

    <div class="net-pay" *ngIf="selectedTabIndex === 0">
      <div>Net Pay for the month: {{ netPay }}</div>
      <div>Rupees {{ netPay | numberToWords | capitalizeFirst }} only</div>
    </div>
  </div>
</div>
