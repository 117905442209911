import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

type GetEmpPFPreferenceByEmpIdAndFiscalApiResponse = {
  "empPFPreferenceId": number,
  "employeeId": string
  "pfType": string,
  "fiscal": string
}

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  private path: any;
  constructor(private baseService: BaseService) { }

  getAllPFTypesApi(): Observable<string[]> {
    this.path = `employeePf`;
    return this.baseService.get(this.path);
  }

  getEmpPFPreferenceByEmpIdAndFiscalApi(empId: string, fiscal: string): Observable<GetEmpPFPreferenceByEmpIdAndFiscalApiResponse> {
    this.path = `employeePf/empPFPreferenceByEmpIdAndFiscal?employeeId=${empId}&fiscal=${fiscal}`;
    return this.baseService.get(this.path)
  }

}
