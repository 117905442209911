<div>
  <div class="form-wrapper">
    <form [formGroup]="earningsFormGroup">
      <div class="row">
        <div class="col">
          <div>
            <div class="d-flex justify-content-center">
              <mat-spinner *ngIf="showSpinner" [diameter]="60"></mat-spinner>
            </div>
            <ng-container *ngIf="!showSpinner">
              <div class="row inputs-row">
                <ng-container *ngFor="let earning of employeeEarningsList">
                  <div class="col col-3 input-wrapper">
                    <div class="d-flex align-items-center">
                      <label class="inv_title mb-0">
                        {{ earning?.component | replaceUnderscore }}
                      </label>
                      <button
                        mat-icon-button
                        color="warn"
                        class="small-delete-icon"
                        (click)="
                          deleteEarning(
                            earning?.monthlyEarningsId,
                            earning?.component
                          )
                        "
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>

                    <input
                      numbersOnly
                      autocomplete="off"
                      [formControl]="
                        earningsFormGroup.controls[earning?.component]
                      "
                      id="earning?.optionId"
                      type="text"
                      class="form-control mt-1"
                    />

                    <!-- Validation Message -->
                    <div
                      *ngIf="
                        earningsFormGroup.controls[earning?.component]
                          .invalid &&
                        earningsFormGroup.controls[earning?.component].touched
                      "
                      class="error-message"
                    >
                      <span
                        *ngIf="
                          earningsFormGroup.controls[
                            earning?.component
                          ]?.hasError('required')
                        "
                        >Amount is required</span
                      >
                      <span
                        *ngIf="
                          earningsFormGroup.controls[
                            earning?.component
                          ]?.hasError('min')
                        "
                        >Zero value is not allowed</span
                      >
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="row">
    <div class="col text-center add">
      <button
        type="button"
        (click)="openAddEarningModal()"
        class="btn btn-primary add-button"
      >
        <mat-icon class="small-icon mr-1">add</mat-icon> Add Earning Component
      </button>
    </div>
  </div>
</div>

<!-- Add Earning Modal -->
<ng-template #addEarningModal>
  <div class="modal-overlay">
    <div class="modal-content">
      <div class="modal-header">
        <div>Add Earning Component</div>
      </div>

      <div class="modal-body">
        <form [formGroup]="addEarningFormGroup">
          <!-- Component Name -->
          <div class="form-group">
            <label for="component">Component Name</label>
            <mat-select
              formControlName="component"
              id="component"
              placeholder="Select Component Name"
              [disabled]="filteredEarningComponents.length === 0"
              matTooltip="No component available"
              [matTooltipDisabled]="filteredEarningComponents.length > 0"
            >
              <mat-option
                *ngFor="let earning of filteredEarningComponents"
                [value]="earning.optionValue"
              >
                {{ earning.optionName | replaceUnderscore }}
              </mat-option>
            </mat-select>

            <!-- Error Message -->
            <div
              *ngIf="
                addEarningFormGroup.controls['component'].invalid &&
                addEarningFormGroup.controls['component'].touched
              "
              class="error-message"
            >
              Component is required
            </div>
          </div>

          <!-- Amount Input -->
          <div class="form-group">
            <label for="amount">Amount</label>
            <input
              matInput
              formControlName="amount"
              id="amount"
              type="text"
              numbersOnly
              [disabled]="addEarningFormGroup.controls['amount'].disabled"
              class="input-field"
            />

            <!-- Error Message -->
            <div
              *ngIf="
                addEarningFormGroup.controls['amount'].invalid &&
                addEarningFormGroup.controls['amount'].touched
              "
              class="error-message"
            >
              <span
                *ngIf="
                  addEarningFormGroup.controls['amount'].hasError('required')
                "
                >Amount is required</span
              >
              <span
                *ngIf="addDeductionFormGroup.controls['amount'].hasError('min')"
                >Zero value is not allowed</span
              >
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button mat-button mat-dialog-close class="cancel-button">
          Cancel
        </button>
        <button
          mat-button
          mat-dialog-close
          class="submit-button"
          [disabled]="addEarningFormGroup.invalid"
          (click)="addControlToEarningsForm()"
        >
          Add
        </button>
      </div>
    </div>
  </div>
</ng-template>
