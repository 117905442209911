import { Component, Input } from "@angular/core";
import jspdf from "jspdf";
import html2canvas from "html2canvas";
import { EmployeePayslipDetails } from "src/app/core/interfaces/user";
import {
  CtcPayslipTableData,
  MONTHLY_PAYSLIP_HEADERS,
  MonthlyPayslipTableData,
  CTC_PAYSLIP_HEADERS,
  UserDetails,
} from "../payslip.utility";
import { SnackBarService } from "src/app/shared/services/snackbar.service";
import { PayrollService } from "src/app/shared/services/payroll.service";
@Component({
  selector: "app-payslip-pdf",
  templateUrl: "./payslip-pdf.component.html",
  styleUrls: ["./payslip-pdf.component.scss"],
})
export class PayslipPdfComponent {
  @Input() userDetails: UserDetails[];
  @Input() selectedEmployeeDetails: EmployeePayslipDetails;
  @Input() fileName: string;
  @Input() employeePayrollDetailsForPdf: MonthlyPayslipTableData[];
  @Input() employeectcPayrollDetailsForPdf: CtcPayslipTableData[];
  @Input() selectedPayslipYear: number;
  @Input() selectedPayslipMonth: number;
  @Input() netPay: number;
  @Input() selectedTabIndex: number;
  headers_monthly: [string, string][] = MONTHLY_PAYSLIP_HEADERS;
  headers_ctc: [string, string][] = CTC_PAYSLIP_HEADERS;
  constructor(
    private snackBarService: SnackBarService,
    private payrollService: PayrollService
  ) {}

  public download_pdf(): void {
    const snackbarRef = this.snackBarService.open("Cancel", 0);
    snackbarRef.progress = 1;
    snackbarRef.runProgressBar();

    let data = document.getElementById(
      this.selectedTabIndex === 0 ? "userPayslipPDF" : "userCtcPayslipPDF"
    );

    if (!data) {
      snackbarRef.progressText = "Error: Unable to generate payslip";
      setTimeout(() => snackbarRef.closeSnackbar(), 2000);
      return;
    }

    data.style.display = "inline";
    let pdf = new jspdf("p", "mm", "a4");

    html2canvas(data)
      .then((canvas) => {
        let imgWidth = 196;
        let imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL("image/png");
        let position = 0;
        pdf.addImage(contentDataURL, "PNG", 7, position, imgWidth, 297);
        pdf.output("blob");
        data.style.display = "none";
        pdf.save(this.fileName);
        snackbarRef.progress = 100;
        snackbarRef.runProgressBar();
        setTimeout(() => snackbarRef.closeSnackbar(), 2000);
      })
      .catch(() => {
        snackbarRef.progressText = "Error occurred in downloading payslip";
        setTimeout(() => snackbarRef.closeSnackbar(), 2000);
      });
  }

  getPairsForPdf(arr: UserDetails[]): UserDetails[][] {
    const pairs: UserDetails[][] = [];
    if (arr) {
      for (let i = 0; i < arr.length; i += 2) {
        pairs.push(arr.slice(i, i + 2));
      }
    }
    return pairs;
  }

  trackByUserDetail(detail: UserDetails): string {
    return detail.objKey;
  }

  trackByHeader(header: [string, string]): string {
    return header[0];
  }
  trackByHeader_ctc(header_ctc: [string, string]): string {
    return header_ctc[0];
  }

  trackByPayrollDetail(index: number): number {
    return index;
  }
}
