import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-investment-declaration-dialog",
  templateUrl: "./investment-declaration-dialog.component.html",
  styleUrls: ["./investment-declaration-dialog.component.scss"],
})
export class InvestmentDeclarationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      sectionName: string;
      sectionLimit: string;
      sectionInputs: string[];
      formGroupName: string;
      parentFormGroup: FormGroup;
    }
  ) {}
}
