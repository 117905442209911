<div class="admin-settings-wrapper">
  <div class="card">
    <div class="card-title">
      <p>Please select the options given below to update admin settings</p>
      <select
        class="fiscal-dropdown"
        [(ngModel)]="fiscal"
        name="fiscal"
        (change)="onOrgSettingsFiscalChange()"
      >
        <option *ngFor="let year of years" [value]="year">
          {{ year }}-{{ (year % 100) + 1 }}
        </option>
      </select>
    </div>
    <div class="card-content-wrapper">
      <form [formGroup]="adminSettingsForm">
        <mat-spinner
          class="my-auto mx-auto"
          [diameter]="60"
          *ngIf="!adminSettingsForm.controls || showSpinner"
        ></mat-spinner>
        <div
          *ngFor="let configGroup of adminSettingsForm.controls | keyvalue"
          class="mb-4"
        >
          <div class="configs-group" *ngIf="!showSpinner">
            <div>{{ configGroup.key }}</div>
            <div class="configs" formGroupName="{{ configGroup.key }}">
              <ng-container
                *ngFor="
                  let i of getNumberOfRowsArray(
                    (configGroup.value.controls | keyvalue).length
                  )
                "
              >
                <div class="row configs-row">
                  <ng-container
                    *ngFor="
                      let config of (
                        configGroup.value.controls
                        | keyvalue : orderBySequenceNumberAcs
                      ).slice(i * 2, (i + 1) * 2)
                    "
                  >
                    <div class="col-6">
                      <div class="config row">
                        <div class="col config-heading">
                          {{ config.key | replaceUnderscore }}
                          <span class="info"
                            ><i
                              matTooltipPosition="right"
                              matTooltip="{{ tooltipsDictionary[config.key] }}"
                              class="icon-info-sign"
                            ></i
                          ></span>
                        </div>
                        <div class="col config-value">
                          <mat-radio-group
                            formControlName="{{ config.key }}"
                            (change)="
                              onChange($event, configGroup.key, config.key)
                            "
                            [disabled]="fiscal != currentFiscalYear"
                          >
                            <mat-radio-button [value]="true">
                              Yes
                            </mat-radio-button>
                            <mat-radio-button
                              style="margin-left: 55px"
                              [value]="false"
                            >
                              No
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <button
          class="btn submit-btn"
          (click)="openSubmissionDialog()"
          [matTooltip]="
            fiscal != currentFiscalYear
              ? 'Values cannot be submitted for previous fiscal years'
              : null
          "
          [disabled]="fiscal != currentFiscalYear"
        >
          Submit
        </button>
      </form>
    </div>
    <div class="card-footer">
      <button
        class="btn reset-btn"
        [matTooltip]="
          fiscal != currentFiscalYear
            ? 'Values cannot be reset for previous fiscal year'
            : null
        "
        [disabled]="fiscal != currentFiscalYear"
        (click)="onResetButtonClick()"
      >
        Reset
      </button>
    </div>
  </div>
  <div class="card">
    <div class="card-title">
      <p>Configure Time Bound Features</p>
      <select
        class="fiscal-dropdown"
        [(ngModel)]="timeBoundFeaturesFiscal"
        name="fiscal"
        [ngStyle]="{
          cursor: isAddingFeature || isEditingFeature ? 'not-allowed' : ''
        }"
        [disabled]="isAddingFeature || isEditingFeature"
        [matTooltip]="
          isAddingFeature || isEditingFeature
            ? 'Cannot change fiscal year if a feature is being added or edited'
            : ''
        "
      >
        <option *ngFor="let year of years" [value]="year">
          {{ year }}-{{ (year % 100) + 1 }}
        </option>
      </select>
      <button
        *ngIf="
          !(isAddingFeature || isEditingFeature) &&
          timeBoundFeaturesFiscal == currentFiscalYear
        "
        (click)="isAddingFeature = true"
        class="add-features-btn"
      >
        Add Features
      </button>
    </div>
    <div
      [ngStyle]="{
        height:
          isAddingFeature || isEditingFeature
            ? 'fit-content'
            : activeTimeBoundFeaturesConfigs.length === 0 &&
              inactiveTimeBoundFeaturesConfigs.length === 0
            ? '100px'
            : 'fit-content'
      }"
    >
      <div
        class="d-flex align-items-center justify-content-center"
        style="height: 100px"
        *ngIf="
          activeTimeBoundFeaturesConfigs.length === 0 &&
          inactiveTimeBoundFeaturesConfigs.length === 0 &&
          !isAddingFeature &&
          !isEditingFeature
        "
      >
        <p style="margin: 0">
          Click on Add Settings to add time bound configurations
        </p>
      </div>
      <div class="card-content-wrapper">
        <div
          *ngIf="isAddingFeature || isEditingFeature"
          class="row add-features-row"
          style="font-size: 13.5px"
        >
          <div class="col-md-3">
            <mat-form-field appearance="outline">
              <mat-select
                *ngIf="!isEditingFeature"
                panelClass="panelClass"
                placeholder="Feature"
                disableOptionCentering
                [(ngModel)]="selectedFeature"
              >
                <mat-option
                  *ngFor="let featureName of timeBoundFeatureNames"
                  [value]="featureName"
                >
                  {{ featureName }}
                </mat-option>
              </mat-select>
              <mat-select
                *ngIf="isEditingFeature"
                panelClass="panelClass"
                placeholder="Feature"
                disableOptionCentering
                [(ngModel)]="selectedFeature"
              >
                <mat-option [value]="featureForEdit.featureName">
                  {{ featureForEdit.featureName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline">
              <input
                autocomplete="off"
                [min]="minStartDate"
                [max]="maxStartAndEndDate"
                matInput
                [(ngModel)]="startDate"
                placeholder="Start Date"
                [matDatepicker]="picker1"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1 color="primary"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline">
              <input
                autocomplete="off"
                matInput
                [disabled]="!startDate"
                [(ngModel)]="endDate"
                [min]="updateEndDateMinDate()"
                [max]="maxStartAndEndDate"
                placeholder="End Date"
                [matDatepicker]="picker2"
                [matTooltip]="!startDate ? 'Select a start date first' : null"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
                [matTooltip]="!startDate ? 'Select a start date first' : null"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2 color="primary"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <button
              class="btn add-btn"
              [disabled]="!selectedFeature || !startDate || !endDate"
              [matTooltip]="
                !selectedFeature || !startDate || !endDate
                  ? 'Provide all inputs to add feature'
                  : null
              "
              (click)="addFeatureToUnsavedList()"
            >
              {{ isEditingFeature ? "Update" : "Add" }}
            </button>
            <button
              *ngIf="isEditingFeature"
              (click)="discardFeatureEdit()"
              class="btn close-btn ml-4"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="features-list-wrapper">
        <div *ngIf="isAddingFeature || isEditingFeature">
          <div
            *ngFor="
              let featureConfigs of unsavedTimeBoundFeaturesConfigs;
              index as i
            "
            class="row unsaved-row"
            style="margin-bottom: 26px"
            [ngStyle]="{ opacity: isEditingFeature ? '50%' : '100%' }"
          >
            <div class="col-md feature-name-col">
              <div class="feature-row-item">
                {{ featureConfigs.featureName }}
              </div>
            </div>
            <div class="col-md feature-date-range-col">
              <div class="feature-row-item">
                {{ featureConfigs.startDate | dateFormat }} -
                {{ featureConfigs.endDate | dateFormat }}
              </div>
            </div>
            <div class="col-md feature-options-col">
              <div class="feature-row-icons">
                <span
                  [matTooltip]="'Edit Feature'"
                  [ngStyle]="{
                    cursor: isEditingFeature ? 'not-allowed' : 'pointer'
                  }"
                  (click)="
                    isEditingFeature
                      ? null
                      : editUnsavedFeature(featureConfigs, i)
                  "
                >
                  <i
                    class="far fa-edit fa-2x"
                    style="padding-right: 10px; color: grey"
                  ></i>
                </span>
                <span
                  [matTooltip]="'Delete Feature'"
                  [ngStyle]="{
                    cursor: isEditingFeature ? 'not-allowed' : 'pointer'
                  }"
                  (click)="
                    isEditingFeature
                      ? null
                      : removeFeatureFromUnsavedList(
                          i,
                          featureConfigs.featureName
                        )
                  "
                >
                  <i
                    class="far fa-trash-alt fa-2x"
                    style="padding-right: 10px; color: #c82727"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            activeTimeBoundFeaturesConfigs.length !== 0 ||
            inactiveTimeBoundFeaturesConfigs.length !== 0
          "
        >
          <div
            *ngFor="
              let featureConfigs of activeTimeBoundFeaturesConfigs;
              index as i
            "
            class="row active-row"
            style="margin-bottom: 26px"
            [ngStyle]="{ opacity: isEditingFeature ? '50%' : '100%' }"
          >
            <div class="col-md feature-name-col">
              <div class="feature-row-item">
                {{ featureConfigs.featureName }}
              </div>
            </div>
            <div class="col-md feature-date-range-col">
              <div class="feature-row-item">
                {{ featureConfigs.startDate | dateFormat }} -
                {{ featureConfigs.endDate | dateFormat }}
              </div>
            </div>
            <div class="col-md feature-options-col">
              <div class="feature-row-icons">
                <span
                  [matTooltip]="'Edit Feature'"
                  [ngStyle]="{
                    cursor: isEditingFeature ? 'not-allowed' : 'pointer'
                  }"
                  (click)="
                    isEditingFeature
                      ? null
                      : editFeature(featureConfigs, i, 'ACTIVE')
                  "
                >
                  <i
                    class="far fa-edit fa-2x"
                    style="padding-right: 10px; color: grey"
                  ></i>
                </span>
                <span
                  [matTooltip]="'Delete Feature'"
                  [ngStyle]="{
                    cursor: isEditingFeature ? 'not-allowed ' : 'pointer'
                  }"
                  (click)="
                    isEditingFeature
                      ? null
                      : openDeleteFeatureDialog(
                          i,
                          'ACTIVE',
                          featureConfigs.featureName
                        )
                  "
                >
                  <i
                    class="far fa-trash-alt fa-2x"
                    style="padding-right: 10px; color: #c82727"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            activeTimeBoundFeaturesConfigs.length !== 0 ||
            inactiveTimeBoundFeaturesConfigs.length !== 0
          "
        >
          <div
            *ngFor="
              let featureConfigs of inactiveTimeBoundFeaturesConfigs;
              index as i
            "
            class="row inactive-row"
            style="margin-bottom: 26px"
            [ngStyle]="{ opacity: isEditingFeature ? '50%' : '100%' }"
          >
            <div class="col-md feature-name-col">
              <div class="feature-row-item">
                {{ featureConfigs.featureName }}
              </div>
            </div>
            <div class="col-md feature-date-range-col">
              <div class="feature-row-item">
                {{ featureConfigs.startDate | dateFormat }} -
                {{ featureConfigs.endDate | dateFormat }}
              </div>
            </div>
            <div class="col-md feature-options-col">
              <div class="feature-row-icons">
                <span
                  *ngIf="timeBoundFeaturesFiscal == currentFiscalYear"
                  [matTooltip]="'Edit Feature'"
                  [ngStyle]="{
                    cursor: isEditingFeature ? 'not-allowed' : 'pointer'
                  }"
                  (click)="
                    isEditingFeature
                      ? null
                      : editFeature(featureConfigs, i, 'INACTIVE')
                  "
                >
                  <i
                    class="far fa-edit fa-2x"
                    style="padding-right: 10px; color: grey"
                  ></i>
                </span>
                <span
                  *ngIf="timeBoundFeaturesFiscal == currentFiscalYear"
                  [matTooltip]="'Delete Feature'"
                  [ngStyle]="{
                    cursor: isEditingFeature ? 'not-allowed' : 'pointer'
                  }"
                  (click)="
                    isEditingFeature
                      ? null
                      : openDeleteFeatureDialog(
                          i,
                          'INACTIVE',
                          featureConfigs.featureName
                        )
                  "
                >
                  <i
                    class="far fa-trash-alt fa-2x"
                    style="padding-right: 10px; color: #c82727"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div *ngIf="isAddingFeature || isEditingFeature">
        <button class="btn reset-btn" (click)="onCancelClick()">Cancel</button>
        <button
          class="btn submit-btn"
          (click)="openSubmitFeaturesDialog(submitFeaturesDialog)"
          [style.cursor]="
            unsavedTimeBoundFeaturesConfigs.length === 0
              ? 'not-allowed'
              : 'pointer'
          "
          [disabled]="unsavedTimeBoundFeaturesConfigs.length === 0"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
  <ng-template #submitFeaturesDialog let-data>
    <div class="dialog-wrapper">
      <div class="dialog-img">
        <img src="../../assets/images/confirmation-dialog.svg" alt="img" />
      </div>
      <div class="dialog-content">
        <div class="title">Confirmation</div>
        <div class="msg">
          <div>
            Do you want to submit configurations for the following features:
          </div>
          <div *ngFor="let featureConfig of unsavedTimeBoundFeaturesConfigs">
            <div>
              {{ featureConfig.featureName }} :
              {{ featureConfig.startDate | dateFormat }} -
              {{ featureConfig.endDate | dateFormat }}
            </div>
          </div>
        </div>
        <div class="button-list">
          <button
            class="post-dialog-no-btn"
            mat-button
            mat-dialog-close
            disableRipple
          >
            No
          </button>
          <button
            mat-button
            mat-dialog-close
            (click)="submitTimeBoundFeaturesConfigs()"
            class="yes-button"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="card">
    <div class="card-title">
      <p>CTC & Deductions</p>
    </div>
    <div class="card-content-wrapper mt-3">
      <mat-tab-group
        mat-align-tabs="start"
        animationDuration="0ms"
        (selectedTabChange)="handleTabChange($event)"
        [selectedIndex]="selectedTabIndex"
        class="fixed-tab-group setting-tab"
      >
        <mat-tab label="Earnings">
          <ng-template mat-tab-label>Earnings</ng-template>
          <ng-template matTabContent>
            <ng-container>
              <app-ctc-earning
                [ctcEarnForm]="ctcEarnForm"
                [existingEarnComp]="existingEarnComp"
                [recentAddedEarnComp]="recentAddedEarnComp"
                [editEarnCompDetails]="editEarnCompDetails"
                [editEarnCompIndex]="editEarnCompIndex"
              ></app-ctc-earning>
            </ng-container>
          </ng-template>
        </mat-tab>
        <mat-tab label="Deductions">
          <ng-template mat-tab-label>Deductions</ng-template>
          <ng-template matTabContent>
            <ng-container>
              <app-ctc-deduction
                [ctcDeductForm]="ctcDeductForm"
                [existingDeductComp]="existingDeductComp"
                [recentAddedDeductComp]="recentAddedDeductComp"
                [editDeductCompDetails]="editDeductCompDetails"
                [editDeductCompIndex]="editDeductCompIndex"
              ></app-ctc-deduction>
            </ng-container>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="card-footer">
      <div *ngIf="displayCtcActionButton()">
        <button class="btn reset-btn" (click)="clearCtcData()">Cancel</button>
        <button class="btn submit-btn" (click)="submitCtcData()">Submit</button>
      </div>
    </div>
  </div>
</div>
