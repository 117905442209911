import { PipeTransform } from '@angular/core';
var KeyValueOriginalOrderPipe = /** @class */ (function () {
    function KeyValueOriginalOrderPipe() {
    }
    KeyValueOriginalOrderPipe.prototype.transform = function (object) {
        return Object.keys(object).map(function (key) { return ({ key: key, value: object[key] }); });
    };
    return KeyValueOriginalOrderPipe;
}());
export { KeyValueOriginalOrderPipe };
