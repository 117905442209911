import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EmployeeDetails } from "src/app/core/interfaces/user";
import { getCurrentFiscalYear } from "src/app/shared/app.utility";
import { EmployeeService } from "src/app/shared/services/employee.service";

@Component({
  selector: "app-employee-pf-update",
  templateUrl: "./employee-pf-update.component.html",
  styleUrls: ["./employee-pf-update.component.scss"],
})
export class EmployeePfUpdateComponent implements OnInit {
  @Input() selectedEmployeeDetails: EmployeeDetails;
  @Output() closePfEditScreen = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  ctcDetails: {
    ctcComponent: string;
    amount: string | any;
    fiscal: number;
    employeeCtcId: number;
  }[] = []; // To store CTC details
  selectedFiscalYear: any = getCurrentFiscalYear();
  fiscalYears: { fiscal: string; fiscalName: string }[] = [];
  fyear: number | string;

  minYear: Date = new Date();
  maxYear: Date = new Date();
  today: Date;
  expandedElement: any;
  allEmpDetails: any;
  lwpMonth: any;
  pfContributionOptions: string[] = [];
  selectedPfValue: number;
  selectedPfType: string = "";
  deductionAmount: number = 0;
  basicPay: number = 0;
  lwpYear: number;
  months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];
  constructor(
    private employeeService: EmployeeService,
    private snackBarService: MatSnackBar
  ) {}

  ngOnInit() {
    this.selectedPfType = "fixed";
    this.calculateDeductionAmount();
    if (this.selectedEmployeeDetails) {
      this.getEmployeePFPreference();

      this.getEmployeeCTCDetails(this.selectedEmployeeDetails.employeeId);
    }
    this.getPfTypeValues();
    this.changeAppContentWrapperStyle();
  }

  changeAppContentWrapperStyle(): void {
    const appContentWrapperDiv = document.querySelector(
      "#appContentWrapper"
    ) as HTMLElement;
    appContentWrapperDiv.style.paddingTop = "0";
    appContentWrapperDiv.style.height = "100%";
    appContentWrapperDiv.style.backgroundColor = "white";
  }
  resetAppContentWrapperStyle(): void {
    const appContentWrapperDiv = document.querySelector(
      "#appContentWrapper"
    ) as HTMLElement;
    appContentWrapperDiv.style.paddingTop = "24px";
    appContentWrapperDiv.style.height = "auto";
    appContentWrapperDiv.style.backgroundColor = "";
  }
  getEmployeeCTCDetails(empId: string) {
    this.employeeService
      .getEmployeeCTCDetailsApi(empId, this.selectedFiscalYear)
      .subscribe(
        (res) => {
          this.ctcDetails = res.filter((item) => item.status === "ACTIVE");
          this.fyear = res[0].fiscal;

          const basicPayDetail = this.ctcDetails.find(
            (item) => item.ctcComponent === "BASIC_PAY"
          );
          this.basicPay = basicPayDetail ? basicPayDetail.amount : 0;
        },
        (error) => {
          this.ctcDetails = [];
          this.snackBarService.open("Failed to fetch CTC details", "Close", {
            duration: 2000,
          });
          console.error("getEmployeeCTCDetailsApi failed", error);
        }
      );
  }
  calculateDeductionAmount() {
    switch (this.selectedPfType) {
      case "fixed":
        this.deductionAmount = 1800;
        break;
      case "variable":
        this.deductionAmount = 0.12 * this.basicPay;
        break;
      case "default":
        this.deductionAmount = Math.min(1800 * 12, 0.12 * this.basicPay);
        break;
      case "NA":
      default:
        this.deductionAmount = 0;
        break;
    }
  }

  setCurrentMonth() {
    const currMonth = this.today.getMonth();
    const currMonthObj = this.months.filter(
      (month) => month.value == currMonth
    );
    this.lwpMonth = currMonthObj[0].name;
  }
  getDetailsAsPerYear() {
    this.allEmpDetails.forEach((element) => {
      if (this.expandedElement === element) {
        this.getEmployeeCTCDetails(element);
      }
    });
  }
  setCurrentYear() {
    const currYear = this.today.getFullYear();
    this.lwpYear = currYear;
  }
  onClosePfEditScreen() {
    this.closePfEditScreen.emit();
  }

  getPfTypeValues() {
    this.employeeService.getEmployeePFPreferenceApi().subscribe(
      (res: string[]) => {
        this.pfContributionOptions = res;
      },
      (err) => {
        console.error("Failed to fetch PF types:", err);
      }
    );
  }
  getEmployeePFPreference() {
    const employeeId = this.selectedEmployeeDetails.employeeId;
    const fiscalYear = this.selectedFiscalYear;

    this.employeeService
      .getEmployeePFPreferenceByEmpIdAndFiscal(employeeId, fiscalYear)
      .subscribe(
        (response) => {
          this.selectedPfType = response.pfType || "fixed";
          this.calculateDeductionAmount();
        },
        (error) => {
          console.error("Failed to fetch PF preference:", error);
          this.snackBarService.open("Failed to fetch PF preference", "Close", {
            duration: 2000,
          });
        }
      );
  }
  onPfTypeChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedPfType = selectElement.value;
    this.calculateDeductionAmount();
  }
  onClose(): void {
    this.close.emit();
  }

  updateEmployeeCtcDetails() {
    const hasInvalidFields = this.ctcDetails.some(
      (ctc) =>
        ctc.amount === null || ctc.amount === undefined || ctc.amount === ""
    );

    if (hasInvalidFields) {
      this.snackBarService.open(
        "Please ensure all fields are filled.",
        "Close",
        {
          duration: 2000,
        }
      );
      return;
    }

    const requestBody = this.ctcDetails.map((ctc) => ({
      employeeCtcId: ctc.employeeCtcId,
      employeeId: this.selectedEmployeeDetails.employeeId,
      ctcComponent: ctc.ctcComponent,
      amount: ctc.amount,
      fiscal: this.fyear,
      status: "ACTIVE",
      notes: "Updated CTC details",
    }));

    this.employeeService.updateEmployeeCtc(requestBody).subscribe(
      (response) => {
        this.snackBarService.open("CTC details updated successfully", "Close", {
          duration: 2000,
        });
        this.onClose();
      },
      (error) => {
        console.error("Failed to update CTC details:", error);
        this.snackBarService.open("Failed to update CTC details", "Close", {
          duration: 2000,
        });
      }
    );
  }

  updateEmployeePFPreference() {
    const employeeId = this.selectedEmployeeDetails.employeeId;
    const fiscalYear = this.selectedFiscalYear;

    // Fetch empPFPreferenceId first
    this.employeeService
      .getEmployeePFPreferenceByEmpIdAndFiscal(employeeId, fiscalYear)
      .subscribe(
        (response) => {
          const empPFPreferenceId = response.empPFPreferenceId;

          const requestBody = {
            empPFPreferenceId,
            employeeId: this.selectedEmployeeDetails.employeeId,
            pfType: this.selectedPfType,
            fiscal: this.selectedFiscalYear,
          };

          this.employeeService
            .putEmployeePFPreferenceApi(requestBody)
            .subscribe(
              (response) => {
                this.snackBarService.open(
                  "PF preference updated successfully",
                  "Close",
                  { duration: 2000 }
                );
                this.onClose();
              },
              (error) => {
                console.error("Failed to update PF preference:", error);
                this.snackBarService.open(
                  "Failed to update PF preference",
                  "Close",
                  { duration: 2000 }
                );
              }
            );
        },
        (error) => {
          console.error("Failed to fetch PF preference:", error);
          this.snackBarService.open("Failed to fetch PF preference", "Close", {
            duration: 2000,
          });
        }
      );
  }

  transformCtcComponent(component: string): string {
    if (!component) return "";
    return component.replace(/_/g, " ").toUpperCase();
  }
  ngOnDestroy(): void {
    this.resetAppContentWrapperStyle();
  }
}
