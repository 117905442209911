<ng-template
  style="border: 1px solid orange"
  [ngxPermissionsOnly]="featurePermissions.write"
  (permissionsAuthorized)="isAuthorized.write = true"
  (permissionsUnauthorized)="isAuthorized.write = false"
>
</ng-template>
<ng-template
  [ngxPermissionsOnly]="featurePermissions.update"
  (permissionsAuthorized)="isAuthorized.update = true"
  (permissionsUnauthorized)="isAuthorized.update = false"
>
</ng-template>
<ng-template
  [ngxPermissionsOnly]="featurePermissions.delete"
  (permissionsAuthorized)="isAuthorized.delete = true"
  (permissionsUnauthorized)="isAuthorized.delete = false"
>
</ng-template>

<div
  class="emp-details-wrapper"
  *ngIf="!isEditingPersonalDetails && !isEditingCtcDetails"
>
  <div class="card">
    <div class="row filters-row">
      <div class="col-md-5 column">
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn search-icon" (click)="onSearch($event)">
              <i class="fa fa-search"></i>
            </button>
            <input
              type="text"
              class="form-control"
              style="border-left: none"
              name="search"
              placeholder="Search by Name, Emp Id, Email"
              (keyup)="onSearch($event)"
              [(ngModel)]="searchInputValue"
            />
          </div>
        </div>
      </div>
      <div
        class="col-md-7 column"
        [ngClass]="
          isScreenSizeLessThanMdBreakPoint
            ? 'mt-3'
            : 'd-flex justify-content-end'
        "
      >
        <select
          class="select-year text-start"
          [(ngModel)]="selectedYear"
          (change)="getDetailsAsPerYear()"
        >
          <option
            *ngFor="let year of years"
            [ngValue]="year"
            selected="selectedOrNot"
          >
            {{ year }}
          </option>
        </select>
        <button
          class="my-btn"
          matTooltip="Add Employee"
          matTooltipPosition="above"
        >
          <img
            src="../../assets/images/add-employee.svg"
            alt=""
            class="filters-row-icons"
            (click)="openAddEmpDialog()"
          />
        </button>
        <button class="my-btn" matTooltip="Export" matTooltipPosition="above">
          <img
            src="../../assets/images/download.svg"
            alt=""
            class="filters-row-icons"
            (click)="getEmployeesExcel()"
          />
        </button>
        <button
          class="my-btn"
          matTooltip="Bulk Upload"
          matTooltipPosition="above"
        >
          <img
            src="../../assets/images/upload.svg"
            alt=""
            class="filters-row-icons"
            (click)="openUploadFilesDialog(uploadFilesDialog)"
          />
        </button>
      </div>
    </div>
    <mat-spinner
      class="my-auto mx-auto"
      [diameter]="60"
      *ngIf="showSpinner"
    ></mat-spinner>
    <ng-container *ngIf="!showSpinner">
      <div class="table-wrapper">
        <table
          class="main-table request_table"
          mat-table
          matSort
          [dataSource]="this.empListDataSource"
          multiTemplateDataRows
        >
          <ng-container
            *ngFor="let col of displayedColumns"
            [matColumnDef]="col"
          >
            <ng-container *ngIf="col === 'expand_action'">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let element">
                <mat-icon
                  *ngIf="allEmpDetails"
                  [ngStyle]="{ color: '#38A3A5' }"
                  style="cursor: pointer"
                  (click)="
                    expandedElement =
                      expandedElement === element ? null : element
                  "
                >
                  {{
                    expandedElement === element
                      ? "keyboard_arrow_down"
                      : "keyboard_arrow_right"
                  }}</mat-icon
                >
              </td>
            </ng-container>
            <ng-container *ngIf="col !== 'expand_action' && col !== 'options'">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ col | camelCaseToTitle }}
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngStyle]="{
                  color:
                    col === 'status'
                      ? element[col]?.toLowerCase() === 'inactive'
                        ? 'red'
                        : 'green'
                      : ''
                }"
                matTooltip="{{
                  getIsTooltipRequiredForCol(col)
                    ? col === 'name'
                      ? element.firstName + ' ' + element.lastName
                      : element[col]
                    : null
                }}"
              >
                {{
                  col === "name"
                    ? element?.firstName + " " + element?.lastName
                    : element[col]
                }}
              </td>
            </ng-container>
            <ng-container *ngIf="col == 'options'">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Options</th>
              <td mat-cell *matCellDef="let element">
                <span
                  [matTooltip]="
                    isAuthorized?.update && isAuthorized?.write
                      ? null
                      : ' You are not authorized to perform this task!'
                  "
                >
                  <a
                    routerLink="./"
                    routerLinkActive="active"
                    [matTooltip]="'Edit CTC Details'"
                    (click)="openEditCtcDetials(element)"
                    class="mr-3"
                  >
                    <img src="../../assets/images/edit-icon.svg" alt="" />
                  </a>
                </span>
                <span
                  [matTooltip]="
                    isAuthorized?.update && isAuthorized?.write
                      ? null
                      : ' You are not authorized to perform this task!'
                  "
                >
                  <a
                    routerLink="./"
                    routerLinkActive="active"
                    [matTooltip]="'Edit Personal Details'"
                    (click)="editPersonalDetails(element)"
                    class="mr-3"
                  >
                    <i class="fas fa-users fa-lg" style="cursor: pointer"></i>
                  </a>
                </span>
                <span
                  [matTooltip]="
                    isAuthorized.delete
                      ? null
                      : ' You are not authorized to perform this task!'
                  "
                >
                  <a
                    routerLink="./"
                    routerLinkActive="active"
                    [matTooltip]="'Delete Employee'"
                    (click)="
                      openDeleteEmpDialog(
                        element.firstName,
                        element.lastName,
                        element.employeeId
                      )
                    "
                  >
                    <img src="../../assets/images/delete-icon.svg" alt="" />
                  </a>
                </span>
              </td>
            </ng-container>
          </ng-container>
          <ng-container matColumnDef="loading">
            <mat-footer-cell *matFooterCellDef>
              Loading data....
            </mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="noData">
            <mat-footer-cell *matFooterCellDef> No Data </mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="expandedDetail" id="demo">
            <td
              class="expand_row"
              mat-cell
              *matCellDef="let element"
              [attr.colspan]="displayedColumns?.length"
            >
              <div
                class="request-detail"
                [@detailExpand]="
                  element == expandedElement ? 'expanded' : 'collapsed'
                "
              >
                <div class="inner-tables">
                  <table
                    style="border: 1px solid gainsboro"
                    class="table table-sm mt-2 mb-2"
                  >
                    <thead>
                      <tr style="border: 1px solid gainsboro">
                        <th
                          style="
                            border: 1px solid gainsboro;
                            font-size: 12px;
                            color: #25477f;
                            background: #d7e6ff;
                          "
                          *ngFor="let emp of empDetails"
                        >
                          {{ emp.header }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="border: 1px solid gainsboro">
                        <td
                          style="
                            border: 1px solid gainsboro;
                            font-size: 12px;
                            color: rgb(44, 42, 42);
                          "
                          *ngFor="let emp of empDetails"
                        >
                          {{ element[emp.field] }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mt-4 inner-tables">
                  <p class="ctc-details-heading">CTC Details :</p>
                  <table
                    style="border: 1px solid gainsboro"
                    class="table table-sm mt-2 mb-2"
                    *ngIf="ctcDetails"
                  >
                    <thead>
                      <tr style="border: 1px solid gainsboro">
                        <th
                          style="
                            border: 1px solid gainsboro;
                            font-size: 12px;
                            color: #25477f;
                            background: #d7e6ff;
                          "
                          *ngFor="let ctc of employeeCTCDetails"
                        >
                          {{ ctc.ctcComponent | replaceUnderscore | titlecase }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="border: 1px solid gainsboro">
                        <td
                          style="
                            border: 1px solid gainsboro;
                            font-size: 12px;
                            color: rgb(44, 42, 42);
                          "
                          *ngFor="let ctc of employeeCTCDetails"
                        >
                          {{ ctc.amount }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div *ngIf="!ctcDetails">
                    <p class="text-center">
                      <i>No CTC details exist for year {{ selectedYear }}</i>
                    </p>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: displayedColumns"
            (click)="getEmployeeCTCDetails(element)"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="request-detail-row expand_row"
          ></tr>
        </table>
      </div>
    </ng-container>
    <div class="paginator shadow" *ngIf="empListDataSource && !showSpinner">
      <mat-paginator
        [length]="totalEmployees"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="[10, 20, 50]"
        (page)="onPageChange($event)"
      >
      </mat-paginator>
    </div>

    <ng-template #editDialog class="test">
      <mat-dialog-content class="my-content">
        <div class="employee-info">
          <div class="row">
            <div class="col-md-6">Employee Name : {{ selectedName }}</div>
            <div class="col-md-6">
              Employee Code : {{ selectedEmployeeCode }}
            </div>
          </div>
        </div>
        <div class="scroller">
          <mat-tab-group
            animationDuration="0ms"
            (selectedTabChange)="handleTabChange($event)"
            class="fixed-tab-group"
          >
            <mat-tab label="CTC Component">
              <ng-template matTabContent>
                <div class="tab-content">
                  <form [formGroup]="editEmployeeForm" #formContainer>
                    <div class="employee-ctc-container">
                      <div
                        class="employee-ctc-details"
                        *ngFor="let ctc of employeeCTCDetailsForEditDialog"
                      >
                        <label class="ctc-heading">
                          {{
                            ctc.ctcComponent | replaceUnderscore | titlecase
                          }} </label
                        ><br />
                        <input
                          class="ctc-box ctc-value"
                          numbersOnly
                          type="text"
                          formControlName="{{ ctc.ctcComponent }}"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </ng-template>
            </mat-tab>
            <!-- <mat-tab label="LWP">
              <ng-template matTabContent>
                <div class="employee-ctc-container">
                  <div class="employee-ctc-details">
                    <div class="ctc-heading">LWP</div>
                    <div class="ctc-box">
                      <p>
                        <input
                          type="number"
                          [(ngModel)]="lwpNoOfDays"
                          class="ctc-box"
                        />
                      </p>
                    </div>
                  </div>
                  <div class="employee-ctc-details">
                    <div class="ctc-heading">Month</div>
                    <div class="ctc-box">
                      <p class="">
                        <select class="ctc-box" [(ngModel)]="lwpMonth">
                          <ng-container *ngFor="let month of months">
                            <ng-container *ngIf="showMonth(month.value)">
                              <option [ngValue]="month.name">
                                {{ month.name }}
                              </option>
                            </ng-container>
                          </ng-container>
                        </select>
                      </p>
                    </div>
                  </div>
                  <div class="employee-ctc-details">
                    <div class="ctc-heading">Year</div>
                    <div class="ctc-box">
                      <p class="">
                        <select
                          class="ctc-box"
                          [(ngModel)]="lwpYear"
                          (change)="onYearChange()"
                        >
                          <option *ngFor="let year of years" [ngValue]="year">
                            {{ year }}
                          </option>
                        </select>
                      </p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </mat-tab> -->
          </mat-tab-group>
        </div>
        <mat-action-list
          *ngIf="isCtcTabSelected"
          align="end"
          style="background: #edfef8"
          class="buttons-row d-flex align-items-center justify-content-end"
        >
          <button class="edit-dialog-btn" mat-button mat-dialog-close>
            Cancel
          </button>
          <button
            class="edit-dialog-btn"
            mat-button
            (click)="resetForm()"
            [style.cursor]="
              editEmployeeForm.pristine ? 'not-allowed' : 'pointer'
            "
            [disabled]="editEmployeeForm.pristine"
          >
            Reset
          </button>
          <button
            class="edit-dialog-btn update-btn"
            mat-button
            (click)="openSubmissionDialog(submissionDialog)"
          >
            Update
          </button>
        </mat-action-list>
      </mat-dialog-content>
    </ng-template>
    <ng-template #submissionDialog>
      <div style="padding: 24px">
        <div class="submission-dialog-title" mat-dialog-title>
          Are you sure you want to update CTC values?
        </div>
        <div class="updateTable">
          <table>
            <thead>
              <tr>
                <th>CTC Component</th>
                <th>Old Value</th>
                <th>New Value</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of confirmValueList; let i = index">
                <td>{{ item.label | replaceUnderscore }}</td>
                <td>{{ item.oldValues }}</td>
                <td>{{ item.newValues }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <mat-action-list align="end">
          <button mat-button mat-dialog-close (click)="onNoClick()">No</button>
          <button
            mat-button
            mat-dialog-close
            *ngIf="confirmValueList?.length > 0"
            (click)="updateEmployeeCTC()"
          >
            Yes
          </button>
        </mat-action-list>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #uploadFilesDialog style="border: 1px solid">
  <div>
    <div class="upload-container">
      <div class="upload-heading">Upload Excel</div>
    </div>
    <div class="">
      <div class="">
        <div class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
          <label for="fileInput" style="cursor: pointer">
            <img
              src="../../assets/images/file-icon.svg"
              alt=""
              class="file-icon-img"
            />
          </label>
          <input
            #fileInp
            style="display: none"
            type="file"
            id="fileInput"
            (change)="fileBrowseHandler($event)"
          />
          <h4
            style="
              font-family: Lato;
              font-size: 16px;
              font-weight: 500;
              line-height: 19px;
              letter-spacing: 0em;
              opacity: 70%;
            "
          >
            Select a Excel file to upload
          </h4>
          <h5
            style="
              font-family: Lato;
              font-size: 12px;
              font-weight: 500;
              line-height: 14px;
              letter-spacing: 0em;
              opacity: 50%;
            "
          >
            or drag and drop it here
          </h5>
        </div>
      </div>
      <div class="">
        <!-- file list -->
        <div class="files-list">
          <div class="single-file" *ngIf="browsedFile">
            <img
              src="../../assets/images/file-icon-blue.svg"
              alt=""
              class="file-uploaded-icon"
            />
            <div class="info">
              <h4 class="uploaded-file-name">
                {{ browsedFile?.name }} ({{
                  formatBytes(browsedFile?.size, 2)
                }})
              </h4>
              <!-- <p class="size">
                {{ formatBytes(browsedFile?.size, 2) }}
              </p> -->
              <app-progress [progress]="browsedFile?.progress"></app-progress>
            </div>
            <div class="delete" (click)="deleteFile()">
              <img src="../../assets/images/delete-icon.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 20px"></div>
  </div>
  <div class="text-center">
    <span class="pointer" (click)="downloadBulkUploadSampleExcel()"
      ><u>Click here to download sample excel file</u></span
    >
  </div>
  <div
    class="row btn-container"
    style="
      margin-top: 12px;
      padding-top: 11px;
      height: 64px;
      background: #edfef8;
    "
  >
    <div class="col-auto" style="padding: 0; margin: 0">
      <button class="upload-employee-btn cancel-btn" mat-dialog-close>
        Cancel
      </button>
    </div>
    <div class="col-auto" style="padding: 0; margin: 0">
      <button
        class="upload-employee-btn upload-btn"
        [class.upload-btn-disabled]="browsedFile?.progress !== 100"
        [disabled]="browsedFile?.progress !== 100"
        (click)="uploadBulkData()"
      >
        {{ submitOrupload }}
      </button>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="isEditingPersonalDetails">
  <app-edit-employee-personal-details
    (close)="closePersonalDetails()"
    [selectedEmployeeDetails]="selectedEmployeeDetails"
    (closeEditScreen)="onCloseEditScreen()"
  ></app-edit-employee-personal-details>
</ng-container>
<ng-container *ngIf="isEditingCtcDetails">
  <app-employee-pf-update
    (close)="closeCtcDetails()"
    [selectedEmployeeDetails]="selectedEmployeeDetails"
    (closePfEditScreen)="onClosePfEditScreen()"
  ></app-employee-pf-update>
</ng-container>
