<div class="pf-update-container">
  <!-- Header Section -->
  <div class="row header-row">
    <div
      class="col main-heading"
      matTooltip="Back"
      matTooltipPosition="right"
      (click)="onClosePfEditScreen()"
    >
      <mat-icon>chevron_left</mat-icon>
      <div>Edit CTC Details</div>
    </div>
  </div>

  <!-- Content Wrapper for Card and Tabs -->
  <div class="card-wrapper">
    <!-- Employee Info Card -->
    <div class="employee-card">
      <div class="card-content">
        <!-- <mat-icon class="photo-icon">person</mat-icon> -->
        <div class="employee-detail">
          <h2>{{ selectedEmployeeDetails.name }}</h2>
          <p>Employee Code: {{ selectedEmployeeDetails.employeeCode }}</p>
        </div>
      </div>
    </div>

    <!-- Tabs Section -->
    <div class="tabs-container">
      <mat-tab-group>
        <mat-tab label="CTC Details">
          <div class="tab-content">
            <!-- Employee and Salary Details -->
            <div class="details-row">
              <!-- Employee Details Section -->
              <div class="employee-details">
                <h3>Employee Details</h3>
                <div class="details-list">
                  <p>
                    <strong>Employee ID:</strong>
                    <span class="break-word">{{
                      selectedEmployeeDetails.employeeId
                    }}</span>
                  </p>
                  <p>
                    <strong>DOJ:</strong>
                    <span>{{
                      selectedEmployeeDetails.dateOfJoining | date
                    }}</span>
                  </p>
                  <p>
                    <strong>Permanent Address:</strong>
                    <span>{{ selectedEmployeeDetails.permanentAddress }}</span>
                  </p>
                  <p>
                    <strong>PAN:</strong>
                    <span>{{ selectedEmployeeDetails.pan }}</span>
                  </p>
                  <p>
                    <strong>UAN:</strong>
                    <span>{{ selectedEmployeeDetails.uanNo }}</span>
                  </p>
                  <p>
                    <strong>PF No.:</strong>
                    <span>{{ selectedEmployeeDetails.pfNo }}</span>
                  </p>
                  <p>
                    <strong>Account No.:</strong>
                    <span>{{ selectedEmployeeDetails.bankAcctNo }}</span>
                  </p>
                  <p>
                    <strong>Branch:</strong>
                    <span>{{ selectedEmployeeDetails.bankAcctAddress }}</span>
                  </p>
                  <p>
                    <strong>IFSC:</strong>
                    <span>{{ selectedEmployeeDetails.bankIFSC }}</span>
                  </p>
                </div>
              </div>

              <!-- Salary Details -->
              <div class="salary-details row">
                <div
                  *ngFor="let ctc of ctcDetails; let i = index"
                  class="col-lg-4 col-md-4 salary-item"
                >
                  <label [for]="'ctc-' + i">
                    {{ transformCtcComponent(ctc.ctcComponent) }}:</label
                  >
                  <input
                    type="text"
                    [id]="'ctc-' + i"
                    [(ngModel)]="ctc.amount"
                    class="salary-input"
                    required
                  />
               
                </div>
              
                <div class="col-lg-4 col-md-4 salary-item">
                  <label>FISCAL YEAR</label>
                  <input
                    type="text"
                    [(ngModel)]="fyear"
                    class="salary-input"
                    readonly
                  />
                </div>
              </div>
              
            </div>
            <!-- Update Button -->
            <div class="d-flex justify-content-end">
              <button
                class="btn update-button"
                (click)="updateEmployeeCtcDetails()"
              >
                Update
              </button>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="PF Preference">
          <div class="tab-content">
            <div class="details-row">
              <!-- Employee Details Section -->
              <div class="employee-details">
                <h3>Employee Details</h3>
                <div class="details-list">
                  <p>
                    <strong>Employee ID:</strong>
                    <span>{{ selectedEmployeeDetails.employeeId }}</span>
                  </p>
                  <p>
                    <strong>DOJ:</strong>
                    <span>{{
                      selectedEmployeeDetails.dateOfJoining | date
                    }}</span>
                  </p>
                  <p>
                    <strong>Permanent Address:</strong>
                    <span>{{ selectedEmployeeDetails.permanentAddress }}</span>
                  </p>
                  <p>
                    <strong>PAN:</strong>
                    <span>{{ selectedEmployeeDetails.pan }}</span>
                  </p>
                  <p>
                    <strong>UAN:</strong>
                    <span>{{ selectedEmployeeDetails.uanNo }}</span>
                  </p>
                  <p>
                    <strong>PF No.:</strong>
                    <span>{{ selectedEmployeeDetails.pfNo }}</span>
                  </p>
                  <p>
                    <strong>Account No.:</strong>
                    <span>{{ selectedEmployeeDetails.bankAcctNo }}</span>
                  </p>
                  <p>
                    <strong>Branch:</strong>
                    <span>{{ selectedEmployeeDetails.bankAcctAddress }}</span>
                  </p>
                  <p>
                    <strong>IFSC:</strong>
                    <span>{{ selectedEmployeeDetails.bankIFSC }}</span>
                  </p>
                </div>
              </div>

              <!-- PF Preference section -->
              <div class="pr-details">
                <div class="details-list">
                  <div class="input-row">
                    <div class="input-item">
                      <label for="pr-contribution">PF Contribution:</label>
                      <select
                        id="pr-contribution"
                        class="salary-input"
                        [(ngModel)]="selectedPfType"
                        (change)="onPfTypeChange($event)"
                      >
                        <option
                          *ngFor="let option of pfContributionOptions"
                          [value]="option"
                        >
                          {{ option }}
                        </option>
                      </select>
                    </div>

                    <div class="input-item">
                      <label for="deduction-amount">Deduction Amount:</label>
                      <input
                        type="number"
                        id="deduction-amount"
                        class="salary-input"
                        [value]="deductionAmount"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="initial-info-text">
                  <mat-icon class="info-text-icon">info</mat-icon>
                  <span class="info-text-message">
                    The deduction percentage should range from a minimum of
                    <span class="highlighted-text">18%</span> to a maximum of
                    <span class="highlighted-text">24%</span>.
                  </span>
                </div>

                <!-- Update Button -->

                <div class="update-button-wrapper">
                  <button
                    class="btn pf-update-button"
                    (click)="updateEmployeePFPreference()"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
