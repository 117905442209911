import { MatPaginatorIntl } from "@angular/material";
var dutchRangeLabel = function (page, pageSize, length) {
    if (length == 0 || pageSize == 0) {
        return "0 of " + length;
    }
    length = Math.max(length, 0);
    var startIndex = page * pageSize;
    var endIndex = startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return "Showing " + (startIndex + 1) + " to " + endIndex + " of " + length + " entries";
};
var ɵ0 = dutchRangeLabel;
export function getDutchPaginatorIntl() {
    var paginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel = "Items per page";
    paginatorIntl.nextPageLabel = "Next";
    paginatorIntl.previousPageLabel = "Previous";
    paginatorIntl.getRangeLabel = dutchRangeLabel;
    return paginatorIntl;
}
export { ɵ0 };
