var CtcDeductionComponent = /** @class */ (function () {
    function CtcDeductionComponent(formBuilder) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.removeComponent = function (index) {
            _this.recentAddedDeductComp.splice(index, 1);
        };
        this.editComponent = function (index) {
            var removedData = _this.recentAddedDeductComp.splice(index, 1);
            _this.editDeductCompIndex = index;
            _this.editDeductCompDetails = removedData[0];
            _this.ctcDeductForm
                .get("componentName")
                .setValue(_this.editDeductCompDetails.deductionName);
            _this.ctcDeductForm
                .get("componentType")
                .setValue(_this.editDeductCompDetails.type);
            _this.ctcDeductForm
                .get("isTaxSaving")
                .setValue(_this.editDeductCompDetails.isTaxSaving);
            _this.ctcDeductForm
                .get("limit")
                .setValue(_this.editDeductCompDetails.deductionLimit);
        };
        this.updateRecentEarningList = function () {
            var updatedDetails = {
                deductionName: _this.ctcDeductForm.value.componentName,
                deductionLimit: _this.ctcDeductForm.value.limit,
                isTaxSaving: _this.ctcDeductForm.value.isTaxSaving,
                type: _this.ctcDeductForm.value.componentType,
            };
            _this.recentAddedDeductComp.splice(_this.editDeductCompIndex, 0, updatedDetails);
            _this.editDeductCompIndex = -1;
            _this.editDeductCompDetails = {};
            _this.ctcDeductForm.reset();
        };
        this.cancelUpdateComponent = function () {
            _this.recentAddedDeductComp.splice(_this.editDeductCompIndex, 0, _this.editDeductCompDetails);
            _this.editDeductCompIndex = -1;
            _this.editDeductCompDetails = {};
            _this.ctcDeductForm.reset();
        };
        this.addComponent = function () {
            var isTaxSaving = _this.ctcDeductForm &&
                _this.ctcDeductForm.value &&
                _this.ctcDeductForm.value.isTaxSaving;
            _this.recentAddedDeductComp.push({
                deductionName: _this.ctcDeductForm &&
                    _this.ctcDeductForm.value &&
                    _this.ctcDeductForm.value.componentName
                    ? _this.ctcDeductForm.value.componentName
                    : "-",
                deductionLimit: _this.ctcDeductForm &&
                    _this.ctcDeductForm.value &&
                    _this.ctcDeductForm.value.limit &&
                    typeof _this.ctcDeductForm.value.limit === "string"
                    ? _this.ctcDeductForm.value.limit
                    : "-",
                type: _this.ctcDeductForm &&
                    _this.ctcDeductForm.value &&
                    _this.ctcDeductForm.value.componentType
                    ? _this.ctcDeductForm.value.componentType
                    : "-",
                isTaxSaving: isTaxSaving == null ? "-" : isTaxSaving,
            });
            _this.ctcDeductForm.reset();
        };
        this.ctcDeductForm = this.formBuilder.group({});
        this.recentAddedDeductComp = [];
        this.existingDeductComp = [];
        this.editDeductCompDetails = {};
        this.editDeductCompIndex = -1;
    }
    return CtcDeductionComponent;
}());
export { CtcDeductionComponent };
