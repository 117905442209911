<div class="card">
  <div class="card-header">
    <h4>Employee CTC</h4>
  </div>
  <div class="card-body">
    <div class="ctc-row header-row">
      <span class="component-header"><strong>CTC Components</strong></span>
      <span class="component-header"><strong>Amount</strong></span>
    </div>

    <div *ngFor="let item of employeeCtcDetails" class="ctc-row">
      <span class="component-name">{{ item.ctcComponent }}</span>
      <span class="amount">{{ item.amount | currency : "INR" }}</span>
    </div>

    <hr class="total-divider" />

    <div class="ctc-row total-row">
      <span class="component-name"><strong>Total</strong></span>
      <span class="amount"
        ><strong>{{ totalAmount | currency : "INR" }}</strong></span
      >
    </div>
  </div>
</div>
