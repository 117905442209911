import { ElementRef, OnInit, AfterViewInit, ChangeDetectorRef, NgZone, } from "@angular/core";
import { PAGE_TO_GROUPS_MAPPING, } from "../core/authorization/permissions";
import { ACTIVE_ICONS_DICTIONARY, DEFAULT_ICONS_DICTIONARY, HEADER_CONTENT_DICTIONARY, } from "../dashboard/dashboard.utility";
import { LOCAL_STORAGE_KEYS } from "../account/login.service";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { PayrollDialogComponent } from "../payroll-dialog/payroll-dialog.component";
import { take } from "rxjs/operators";
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(dialog, router, cdr, adminService, loginService, ngZone, employeeService) {
        var _this = this;
        this.dialog = dialog;
        this.router = router;
        this.cdr = cdr;
        this.adminService = adminService;
        this.loginService = loginService;
        this.ngZone = ngZone;
        this.employeeService = employeeService;
        this.showNotifications = false;
        this.notifications = [];
        this.urlForHeaderImage = "";
        router.events.subscribe(function (ev) {
            _this.HeaderContentText = HEADER_CONTENT_DICTIONARY[_this.router.url];
            _this.urlForHeaderImage = _this.router.url;
        });
    }
    DashboardComponent.prototype.onDocumentClick = function (event) {
        this.showNotifications = false;
    };
    DashboardComponent.prototype.ngAfterViewInit = function () {
        this.updateSidenavMode();
    };
    DashboardComponent.prototype.onResize = function (event) {
        this.updateSidenavMode();
    };
    DashboardComponent.prototype.updateSidenavMode = function () {
        // 992px is from bootstrap Large(lg) breakpoint
        if (window.innerWidth >= 992) {
            this.sidenavRef.mode = "side";
        }
        else {
            if (this.sidenavRef.opened) {
                this.sidenavRef.toggle();
            }
            this.sidenavRef.mode = "over";
        }
        this.cdr.detectChanges();
    };
    DashboardComponent.prototype.ngOnInit = function () {
        this.setEmployeePersonalDetails();
        this.permissions = PAGE_TO_GROUPS_MAPPING;
        this.setTimeBoundFeaturesConfigs();
        this.employeeName = this.getEmployeeName();
    };
    DashboardComponent.prototype.setEmployeePersonalDetails = function () {
        var _this = this;
        this.employeeService
            .getEmployeeProfessionalDetailsApi(this.loginService.employeeDetails.employeeId)
            .pipe(take(1))
            .subscribe(function (empProfessionalDetails) {
            _this.employeeService.employeePersonalDetails = empProfessionalDetails;
        }, function (err) {
            console.error(err);
        });
    };
    DashboardComponent.prototype.getEmployeeName = function () {
        var employeeDetails = this.loginService.employeeDetails;
        return employeeDetails.firstName + " " + employeeDetails.lastName;
    };
    DashboardComponent.prototype.openNotifications = function (event) {
        this.showNotifications = !this.showNotifications;
        event.stopPropagation();
        if (this.showNotifications) {
        }
    };
    DashboardComponent.prototype.openLogout = function () {
        var _this = this;
        var dialogRef = this.dialog.open(PayrollDialogComponent, {
            data: {
                title: "Confirmation",
                msg: "Do you want to logout?",
                dialogType: "logout",
                onYesClickFunction: function () { return _this.signOut(); },
            },
        });
    };
    DashboardComponent.prototype.signOut = function () {
        var _this = this;
        localStorage.removeItem(LOCAL_STORAGE_KEYS.userGroups);
        sessionStorage.removeItem(LOCAL_STORAGE_KEYS.userLoginInfo);
        this.dialog.closeAll();
        this.ngZone.run(function () {
            _this.router.navigate(["/login"]);
        });
    };
    DashboardComponent.prototype.getIcon = function (route) {
        if (this.router.url === route) {
            return ACTIVE_ICONS_DICTIONARY[route];
        }
        else {
            return DEFAULT_ICONS_DICTIONARY[route];
        }
    };
    DashboardComponent.prototype.setTimeBoundFeaturesConfigs = function () {
        var _this = this;
        this.adminService
            .getAllTimeBoundFeaturesConfigurationsApi(getCurrentFiscalYear().toString())
            .subscribe(function (featuresConfigs) {
            _this.loginService.timeBoundFeaturesConfigs = featuresConfigs;
            localStorage.setItem(LOCAL_STORAGE_KEYS.time_bound_features_configurations, JSON.stringify(featuresConfigs));
        });
    };
    return DashboardComponent;
}());
export { DashboardComponent };
