import { HttpParams } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "./base.service";
var YtdDetailsService = /** @class */ (function () {
    function YtdDetailsService(baseService) {
        this.baseService = baseService;
    }
    YtdDetailsService.prototype.getYtdDetailsAPI = function (employeeId, fiscal) {
        this.params = new HttpParams()
            .set("employeeId", employeeId)
            .set("fiscal", fiscal.toString());
        return this.baseService.get("ytd", this.params);
    };
    YtdDetailsService.prototype.getYtdDetailsForAllEmployeeAPI = function (fiscal, searchText) {
        this.params = new HttpParams();
        if (searchText) {
            this.params = this.params.append("search", searchText);
        }
        this.path = "ytd/" + fiscal;
        return this.baseService.get(this.path, this.params);
    };
    YtdDetailsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function YtdDetailsService_Factory() { return new YtdDetailsService(i0.ɵɵinject(i1.BaseService)); }, token: YtdDetailsService, providedIn: "root" });
    return YtdDetailsService;
}());
export { YtdDetailsService };
