<div class="my-4">
  <section class="ctc-add-component-form">
    <form [formGroup]="ctcEarnForm" class="mt-2">
      <div class="row">
        <div class="col-xl-3 col-lg-12">
          <label for="componentName" class="my-label"
            >Earning Component Name</label
          >
          <mat-form-field appearance="outline" class="matpart w-100">
            <input
              autocomplete="off"
              matInput
              formControlName="componentName"
              placeholder="Enter Component Name"
              id="componentName"
            />
          </mat-form-field>
        </div>
        <div class="col-xl-3 col-lg-3">
          <label class="my-label">Component Type</label>
          <mat-form-field appearance="outline" class="matpart w-100">
            <mat-select
              formControlName="componentType"
              placeholder="Select Component Type"
            >
              <mat-option value="fixed">fixed</mat-option>
              <mat-option value="flexi">flexi</mat-option>
              <mat-option value="total">total</mat-option>
              <mat-option value="monthly">monthly</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-xl-6 col-lg-9 col-md-12 component-taxable">
          <div>
            Is the component taxable ?
            <img src="assets/images/info-icon.svg" alt="" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <mat-radio-group formControlName="isTaxable" class="d-inline-block">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>

          <button
            *ngIf="editEarnCompIndex < 0"
            [disabled]="
              !ctcEarnForm.value.componentType ||
              ctcEarnForm.value.isTaxable === null ||
              ctcEarnForm.value.isTaxable === undefined ||
              !ctcEarnForm.value.componentName
            "
            [matTooltip]="
              !ctcEarnForm.value.componentType ||
              ctcEarnForm.value.isTaxable === null ||
              ctcEarnForm.value.isTaxable === undefined ||
              !ctcEarnForm.value.componentName
                ? 'Provide all inputs to add component'
                : null
            "
            (click)="addComponent()"
            class="btn add-component-btn text-center"
          >
            Add
          </button>
          <div *ngIf="editEarnCompIndex >= 0" class="d-flex align-items-center">
            <button
              [disabled]="
                !ctcEarnForm.value.componentType ||
                ctcEarnForm.value.isTaxable === null ||
                ctcEarnForm.value.isTaxable === undefined ||
                !ctcEarnForm.value.componentName
              "
              [matTooltip]="
                !ctcEarnForm.value.componentType ||
                ctcEarnForm.value.isTaxable === null ||
                ctcEarnForm.value.isTaxable === undefined ||
                !ctcEarnForm.value.componentName
                  ? 'Provide all inputs to update component'
                  : 'Save Changes'
              "
              (click)="updateRecentEarningList()"
              class="btn update-component-btn p-3 curser-pointer"
            >
              <img src="assets/images/save-icon.svg" alt="" />
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              matTooltip="Discard Changes"
              (click)="cancelUpdateComponent()"
              class="btn update-component-btn p-3 text-center"
            >
              <img src="assets/images/cancel-icon.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </form>
  </section>
  <ng-container
    *ngTemplateOutlet="
      myTemplate;
      context: { isExisting: false, compData: recentAddedEarnComp }
    "
  ></ng-container>
  <ng-container
    *ngTemplateOutlet="
      myTemplate;
      context: { isExisting: true, compData: existingEarnComp }
    "
  ></ng-container>
</div>

<ng-template #myTemplate let-isExisting="isExisting" let-compData="compData">
  <section class="component-section mt-4" *ngIf="compData && compData.length">
    <div class="mb-3">
      {{ isExisting ? "Existing Components" : "Recently Added Components" }}
    </div>
    <div class="overflow-scroll">
      <div
        class="ctc-component-card py-3 px-2 mb-3"
        *ngFor="let element of compData; let i = index"
      >
        <div class="row m-0 w-100">
          <div class="col-md-4 mb-md-0 mb-2">
            <div class="text">CTC Component Name</div>
            <div class="data">
              {{
                element && element.componentName ? element.componentName : "-"
              }}
            </div>
          </div>
          <div class="col-md-4 mb-md-0 mb-2">
            <div class="text">Component Type</div>
            <div class="data">
              {{ element && element.type ? element.type : "-" }}
            </div>
          </div>
          <div
            [ngClass]="{
              'tax-component': !isExisting
            }"
            class="col-md-4 mb-md-0 mb-2"
          >
            <div>
              <div class="text">Taxable Component?</div>
              <div class="data">
                {{
                  element && element.taxable
                    ? element.taxable === "Y"
                      ? "YES"
                      : "NO"
                    : "-"
                }}
              </div>
            </div>

            <div *ngIf="!isExisting">
              <img
                matTooltip="Edit"
                class="curser-pointer"
                src="assets/images/edit-icon.svg"
                alt=""
                (click)="editComponent(i)"
              />
              <img
                matTooltip="Delete"
                class="curser-pointer"
                src="assets/images/delete-icon.svg"
                alt=""
                (click)="removeComponent(i)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
