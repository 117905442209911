import { OnInit, } from "@angular/core";
import { DIALOG_TYPE_IMG_URL_DICT, } from "./payroll-dialog.utility";
var PayrollDialogComponent = /** @class */ (function () {
    function PayrollDialogComponent(data) {
        this.data = data;
    }
    PayrollDialogComponent.prototype.ngOnInit = function () { };
    PayrollDialogComponent.prototype.getDialogYesBtnClass = function () {
        switch (this.data.dialogType) {
            case "post":
                return "post-dialog-yes-btn";
            case "delete":
                return "delete-dialog-yes-btn";
            case "logout":
                return "post-dialog-yes-btn";
        }
    };
    PayrollDialogComponent.prototype.getDialogImgBackgroundColorClass = function () {
        switch (this.data.dialogType) {
            case "post":
                return "post-dialog-img-background";
            case "delete":
                return "delete-dialog-img-background";
            case "logout":
                return "post-dialog-img-background";
        }
    };
    PayrollDialogComponent.prototype.getDialogNoBtnClass = function () {
        switch (this.data.dialogType) {
            case "post":
                return "post-dialog-no-btn";
            case "delete":
                return "delete-dialog-no-btn";
            case "logout":
                return "post-dialog-no-btn";
        }
    };
    PayrollDialogComponent.prototype.getImgUrl = function () {
        return DIALOG_TYPE_IMG_URL_DICT[this.data.dialogType];
    };
    PayrollDialogComponent.prototype.onNoClick = function () {
        if (this.data.onNoClickFunction) {
            this.data.onNoClickFunction();
        }
    };
    PayrollDialogComponent.prototype.isLogoutDialog = function () {
        return this.data.dialogType === 'logout';
    };
    return PayrollDialogComponent;
}());
export { PayrollDialogComponent };
