export function getCurrentFiscalYear(startMonth: number = 4): number {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  let fiscalYear = today.getFullYear();
  if (currentMonth < startMonth) {
    fiscalYear--;
  }
  return fiscalYear;
}

export const ytdResponse = [
  {
    category: "INCOME",
    components: {
      BASIC_PAY: [
        {
          payrollKey: "May",
          amount: 45833.33,
        },
        {
          payrollKey: "July",
          amount: 45833.33,
        },
        {
          payrollKey: "April",
          amount: 45833.33,
        },
        {
          payrollKey: "June",
          amount: 45833.33,
        },
        {
          payrollKey: "December",
          amount: 45833.33,
        },
        {
          payrollKey: "September",
          amount: 45833.33,
        },
        {
          payrollKey: "August",
          amount: 45833.33,
        },
        {
          payrollKey: "November",
          amount: 45833.33,
        },
        {
          payrollKey: "TOTAL",
          amount: 366666.64,
        },
      ],
      HRA: [
        {
          payrollKey: "May",
          amount: 8333.33,
        },
        {
          payrollKey: "July",
          amount: 8333.33,
        },
        {
          payrollKey: "April",
          amount: 8333.33,
        },
        {
          payrollKey: "June",
          amount: 8333.33,
        },
        {
          payrollKey: "December",
          amount: 8333.33,
        },
        {
          payrollKey: "September",
          amount: 8333.33,
        },
        {
          payrollKey: "August",
          amount: 8333.33,
        },
        {
          payrollKey: "November",
          amount: 8333.33,
        },
        {
          payrollKey: "TOTAL",
          amount: 66666.64,
        },
      ],
      SPECIAL_ALLOWANCE: [
        {
          payrollKey: "May",
          amount: 8750,
        },
        {
          payrollKey: "July",
          amount: 8750,
        },
        {
          payrollKey: "April",
          amount: 8750,
        },
        {
          payrollKey: "June",
          amount: 8750,
        },
        {
          payrollKey: "December",
          amount: 8750,
        },
        {
          payrollKey: "September",
          amount: 8750,
        },
        {
          payrollKey: "August",
          amount: 8750,
        },
        {
          payrollKey: "November",
          amount: 8750,
        },
        {
          payrollKey: "TOTAL",
          amount: 70000,
        },
      ],
      STANDARD_ALLOWANCE: [
        {
          payrollKey: "May",
          amount: 4166.67,
        },
        {
          payrollKey: "July",
          amount: 4166.67,
        },
        {
          payrollKey: "April",
          amount: 4166.67,
        },
        {
          payrollKey: "June",
          amount: 4166.67,
        },
        {
          payrollKey: "December",
          amount: 4166.67,
        },
        {
          payrollKey: "September",
          amount: 4166.67,
        },
        {
          payrollKey: "August",
          amount: 4166.67,
        },
        {
          payrollKey: "November",
          amount: 4166.67,
        },
        {
          payrollKey: "TOTAL",
          amount: 33333.36,
        },
      ],
      TOTAL_INCOME: [
        {
          payrollKey: "May",
          amount: 67083.33,
        },
        {
          payrollKey: "July",
          amount: 67083.33,
        },
        {
          payrollKey: "April",
          amount: 67083.33,
        },
        {
          payrollKey: "June",
          amount: 67083.33,
        },
        {
          payrollKey: "December",
          amount: 67083.33,
        },
        {
          payrollKey: "September",
          amount: 67083.33,
        },
        {
          payrollKey: "August",
          amount: 67083.33,
        },
        {
          payrollKey: "November",
          amount: 67083.33,
        },
        {
          payrollKey: "TOTAL",
          amount: 536666.64,
        },
      ],
    },
  },
  {
    category: "DEDUCTIONS",
    components: {
      INCOME_TAX: [
        {
          payrollKey: "May",
          amount: 5291.67,
        },
        {
          payrollKey: "July",
          amount: 5291.67,
        },
        {
          payrollKey: "April",
          amount: 5291.67,
        },
        {
          payrollKey: "June",
          amount: 5291.67,
        },
        {
          payrollKey: "December",
          amount: 5291.67,
        },
        {
          payrollKey: "September",
          amount: 5291.67,
        },
        {
          payrollKey: "August",
          amount: 5291.67,
        },
        {
          payrollKey: "November",
          amount: 5291.67,
        },
        {
          payrollKey: "TOTAL",
          amount: 42333.36,
        },
      ],
      TOTAL_DEDUCTIONS: [
        {
          payrollKey: "May",
          amount: 5291.67,
        },
        {
          payrollKey: "July",
          amount: 5291.67,
        },
        {
          payrollKey: "April",
          amount: 5291.67,
        },
        {
          payrollKey: "June",
          amount: 5291.67,
        },
        {
          payrollKey: "December",
          amount: 5291.67,
        },
        {
          payrollKey: "September",
          amount: 5291.67,
        },
        {
          payrollKey: "August",
          amount: 5291.67,
        },
        {
          payrollKey: "November",
          amount: 5291.67,
        },
        {
          payrollKey: "TOTAL",
          amount: 42333.36,
        },
      ],
    },
  },
  {
    category: "NET_PAY",
    components: {
      NET_PAY: [
        {
          payrollKey: "April",
          amount: 61791.67,
        },
        {
          payrollKey: "August",
          amount: 61791.67,
        },
        {
          payrollKey: "May",
          amount: 61791.67,
        },
        {
          payrollKey: "November",
          amount: 61791.67,
        },
        {
          payrollKey: "July",
          amount: 61791.67,
        },
        {
          payrollKey: "December",
          amount: 61791.67,
        },
        {
          payrollKey: "September",
          amount: 61791.67,
        },
        {
          payrollKey: "June",
          amount: 61791.67,
        },
        {
          payrollKey: "TOTAL",
          amount: 494333.36,
        },
      ],
    },
  },
  {
    category: "DAYS",
    components: {
      WorkingDays: [
        {
          payrollKey: "JUNE",
          amount: 30,
        },
        {
          payrollKey: "JANUARY",
          amount: 31,
        },
        {
          payrollKey: "MAY",
          amount: 31,
        },
        {
          payrollKey: "OCTOBER",
          amount: 31,
        },
        {
          payrollKey: "DECEMBER",
          amount: 31,
        },
        {
          payrollKey: "MARCH",
          amount: 31,
        },
        {
          payrollKey: "AUGUST",
          amount: 31,
        },
        {
          payrollKey: "FEBRUARY",
          amount: 29,
        },
        {
          payrollKey: "JULY",
          amount: 31,
        },
        {
          payrollKey: "SEPTEMBER",
          amount: 30,
        },
        {
          payrollKey: "NOVEMBER",
          amount: 30,
        },
        {
          payrollKey: "APRIL",
          amount: 30,
        },
        {
          payrollKey: "Total Working Days",
          amount: 366,
        },
      ],
    },
  },
];
