import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { BaseService } from "./base.service";
import {
  InvestmentDeclarationSectionInfo,
  SectionLimit,
} from "src/app/investment-declaration/investment-declaration.util";

@Injectable({
  providedIn: "root",
})
export class PoiService {
  private path;
  private params: HttpParams;

  constructor(private baseService: BaseService) {}

  savePoiAsDraftApi(empId: string, formData: FormData) {
    this.path = `empInvestProof/draftPOI?employeeId=${empId}`;
    return this.baseService.post(this.path, formData);
  }
}
