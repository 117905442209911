import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MatDialog,
  MatPaginator,
  MatSnackBar,
  MatSort,
  MatTableDataSource,
  PageEvent,
} from "@angular/material";
import { EmployeeService } from "../shared/services/employee.service";
import { PayrollService } from "../shared/services/payroll.service";
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {
  DISPLAYED_COLUMNS,
  MONTHS,
  MD_SCREEN_SIZE_BREAKPOINT,
  COLS_WHICH_REQUIRE_TOOLTIP,
  EMPLOYEE_ID,
  getFormattedDate,
  getFirstDayOfNextMonth,
} from "./payroll.utility";
import { AddEmployeeDetailsComponent } from "../add-employee-details/add-employee-details.component";
import { SpinnerService } from "../shared/services/spinner.service";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  take,
} from "rxjs/operators";
import { of, Subject } from "rxjs";
import {
  EmployeePayrollDetails,
  SNACKBAR_LABEL,
} from "../payslip/payslip.utility";
import { SnackBarService } from "../shared/services/snackbar.service";
import {
  EmployeeDetails,
  ToggleChildComponentPayload,
} from "../core/interfaces/user";
import { PayrollDialogComponent } from "../payroll-dialog/payroll-dialog.component";
import { PayrollDialogComponentInput } from "../payroll-dialog/payroll-dialog.utility";
// (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "payroll",
  templateUrl: "./payroll.component.html",
  styleUrls: ["./payroll.component.scss"],
})
export class PayrollComponent implements OnInit, AfterViewInit {
  empListSourceRaw: any;
  today: Date;
  public empListSource: MatTableDataSource<any>;
  allEmpDetails: any;
  public empListDataSource: MatTableDataSource<any>;
  searchSubject = new Subject<string>();
  allPayrollDetails: any;
  employeePayrollDetails: any;
  parsedEmployeePayrollDetails: any;
  durationInSeconds = 5;
  selectedEmployeeDetails: any;
  selectedPayslipMonth: number;
  selectedPayslipYear: number;
  years: number[] = [];
  months: { [month: number]: string } = MONTHS;
  displayedColumns: string[] = DISPLAYED_COLUMNS;
  payrollDetails: EmployeePayrollDetails;
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.empListDataSource) {
      this.empListDataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("payslipPDF", { static: true }) payslipPDF: ElementRef;
  url: any = "";
  totalEmployees: number = 0;
  totalEmployeeRecords: number = 0;
  pageIndex: number = 0;
  pageSize: number = 10;
  isScreenSizeLessThanMdBreakPoint = false;
  totalPayableAmount: number;
  formattedDate: string;
  payslipTitleText: string;
  showLoading: boolean = false;
  searchInputValue: string = "";
  showSpinner: boolean = true;
  openEarningsAndDeductions: boolean = false;
  currentEmployeeElement: any;
  constructor(
    public dialog: MatDialog,
    private employeeService: EmployeeService,
    private _snackBar: MatSnackBar,
    private payrollService: PayrollService,
    private spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef,
    private snackBarService: SnackBarService
  ) {
    this.today = new Date();
    this.years = Array.from(
      new Array(11),
      (x, i) => this.getAdjustedYear() - i
    );
  }

  ngOnInit(): void {
    this.selectedPayslipMonth = this.getAdjustedMonth() + 1;
    this.setCurrentYear();
    this.getAllPayrollDetails(
      this.selectedPayslipYear,
      this.selectedPayslipMonth
    );
    this.getTotalEmployeesAndAmount(
      this.selectedPayslipYear,
      this.selectedPayslipMonth
    );
    this.formattedDate = getFormattedDate(getFirstDayOfNextMonth(this.today));
    this.searchSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.performSearch(searchTerm);
      });
  }

  ngAfterViewInit(): void {
    this.setIsScreenSizeLessThanMdBreakPoint();
    this.cdr.detectChanges();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.setIsScreenSizeLessThanMdBreakPoint();
  }

  async payslipExist(
    employeeId: string,
    dialog: any,
    element: EmployeeDetails
  ) {
    try {
      const res = await this.payrollService
        .getPayrollDetailsApi(
          employeeId,
          this.selectedPayslipMonth,
          this.selectedPayslipYear
        )
        .pipe(
          take(1),
          catchError((error) => {
            this.openSnackbar(error.error.message, SNACKBAR_LABEL);
            return of(null);
          })
        )
        .toPromise();

      const payslipExists = !!res;

      if (payslipExists) {
        this.openPayslipDialog(dialog, element);
        this.payrollDetails = res;
      }
    } catch (error) {
      this.openSnackbar("Error fetching payroll details", SNACKBAR_LABEL);
    }
  }

  setIsScreenSizeLessThanMdBreakPoint() {
    this.isScreenSizeLessThanMdBreakPoint =
      window.innerWidth >= MD_SCREEN_SIZE_BREAKPOINT ? false : true;
  }

  openConfirmationDialog() {
    this.dialog.open(PayrollDialogComponent, {
      data: {
        title: "Confirmation",
        msg: `Do you want to trigger monthly payroll generation for
${this.months[this.selectedPayslipMonth]} ${this.selectedPayslipYear} ?`,
        dialogType: "post",
        onYesClickFunction: (param?: any) =>
          this.calculateMonthlyPayroll(param),
      } as PayrollDialogComponentInput,
    });
  }

  setCurrentMonth() {
    this.selectedPayslipMonth = new Date().getMonth() + 1;
  }

  setCurrentYear() {
    this.selectedPayslipYear = this.getAdjustedYear();
  }
  getTotalEmployeesAndAmount(payrollYear: number, payrollMonth: number): void {
    this.payrollService
      .getTotalEmployeeAndAmountApi(payrollYear, payrollMonth)
      .subscribe(
        (res) => {
          if (res) {
            this.totalEmployees = res.totalEmployees || 0;

            this.totalPayableAmount = res.totalPayableAmount || 0;
          } else {
            console.error(
              "Invalid response structure for total employees and amount."
            );
            this.totalEmployees = 0;
            this.totalPayableAmount = 0;
          }
        },
        (error) => {
          console.error("Failed to fetch total employees and amount.", error);
          this.totalEmployees = 0;
          this.totalPayableAmount = 0;
        }
      );
  }

  getAllPayrollDetails(year: number, month: number, search?: string): void {
    this.showSpinner = true;
    this.payrollService
      .getAllPayrollDetailsApi(
        year,
        month,
        this.pageIndex,
        this.pageSize,
        search
      )
      .subscribe(
        (res) => {
          this.showSpinner = false;

          if (
            res &&
            res.content &&
            res.content.length > 0 &&
            res.content[0].employees
          ) {
            this.allPayrollDetails = res.content[0].employees;

            this.allEmpDetails = Object.keys(this.allPayrollDetails).map(
              (key) => {
                return { ...this.allPayrollDetails[key], employeeId: key };
              }
            );

            this.totalEmployeeRecords = res.totalElements;
            this.empListDataSource = this.allEmpDetails;
            this.empListDataSource.sort = this.sort;
            this.empListDataSource.paginator = this.paginator;
          } else if (res.content.length === 0) {
            console.error(
              "Invalid response structure or employees data is missing."
            );
            this.allPayrollDetails = [];
            this.allEmpDetails = [];
            this.empListDataSource = this.allEmpDetails;
            this.totalEmployeeRecords = 0;
          }
        },
        (error) => {
          this.showSpinner = false;
          this.snackBarService.add({
            message: "Failed to load payroll details, please try again later",
            action: "close",
            config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
          });
          console.error("Get all payroll details failed", error);
        }
      );
  }

  onSearch(): void {
    const searchTerm = this.searchInputValue.trim();
    this.searchSubject.next(searchTerm);
  }

  performSearch(searchTerm: string): void {
    this.pageIndex = 0;
    this.getAllPayrollDetails(
      this.selectedPayslipYear,
      this.selectedPayslipMonth,
      searchTerm
    );
  }
  onPageChange(event: PageEvent): void {
    if (this.pageSize !== event.pageSize) {
      this.pageIndex = 0;
    } else {
      this.pageIndex = event.pageIndex;
    }

    this.pageSize = event.pageSize;

    this.getAllPayrollDetails(
      this.selectedPayslipYear,
      this.selectedPayslipMonth,
      this.searchInputValue
    );
  }

  getPayrollData(employeeId: string, col: string) {
    return this.allPayrollDetails
      ? this.allPayrollDetails[employeeId]
        ? this.allPayrollDetails[employeeId][col]
          ? this.allPayrollDetails[employeeId][col]
          : ""
        : ""
      : "";
  }
  onMonthChange(): void {
    const searchTerm = this.searchInputValue.trim();
    this.getAllPayrollDetails(
      this.selectedPayslipYear,
      this.selectedPayslipMonth,
      searchTerm
    );
  }

  onYearChange(): void {
    if (
      this.selectedPayslipYear === this.getAdjustedYear() &&
      this.selectedPayslipMonth > this.getAdjustedMonth() + 1
    ) {
      this.selectedPayslipMonth = 1;
    }
    const searchTerm = this.searchInputValue.trim();
    this.getAllPayrollDetails(
      this.selectedPayslipYear,
      this.selectedPayslipMonth,
      searchTerm
    );
  }

  openPayslipDialog(templateRef: TemplateRef<any>, element: EmployeeDetails) {
    this.selectedPayslipMonth = this.selectedPayslipMonth;
    this.selectedPayslipYear = this.selectedPayslipYear;
    this.payslipTitleText = `${
      element.firstName
    } ${element.lastName.trim()}'s Payslip`;
    let dialogRef = this.dialog.open(templateRef, {
      width: "800px",
      height: "600px",
    });
    this.selectedEmployeeDetails = this.allEmpDetails.filter(
      (el) => el.employeeId.toLowerCase() === element.employeeId.toLowerCase()
    )[0];
    dialogRef.afterClosed().subscribe((result) => {
      this.onClose();
    });
  }

  onClose() {
    this.dialog.closeAll();
    this.url = "";
    this.selectedEmployeeDetails = null;
    this.employeePayrollDetails = null;
  }

  calculateMonthlyPayroll(param?: any) {
    this.spinnerService.openSpinnerDialog();
    this.payrollService
      .calculateMonthlyPayrollApi(
        this.selectedPayslipYear,
        this.selectedPayslipMonth
      )
      .subscribe(
        (res) => {
          this.spinnerService.closeSpinnerDialog();
          setTimeout(() => {
            this.openSnackbar(res.message, "Close");
          }, 500);
        },
        (error) => {
          this.spinnerService.closeSpinnerDialog();
          this.openSnackbar("'Monthly payroll calculation failed'", "Close");
        }
      );
  }

  getBankTransferSheet() {
    const snackbarRef = this.snackBarService.open("Cancel", 0);
    snackbarRef.progress = 1;
    snackbarRef.runProgressBar();
    this.payrollService
      .getPayrollExcelApi(this.selectedPayslipYear, this.selectedPayslipMonth)
      .subscribe(
        (res) => {
          let url = window.URL.createObjectURL(res.body);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = `Bank_Transfer_Sheet_${this.months[
            this.selectedPayslipMonth
          ].toUpperCase()}_${this.selectedPayslipYear}.xlsx`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          snackbarRef.progress = 100;
          snackbarRef.runProgressBar();
          setTimeout(() => {
            snackbarRef.closeSnackbar();
          }, 2000);
        },
        (err) => {
          snackbarRef.progressText =
            "Error occurred in downloading bank transfer sheet";
          setTimeout(() => {
            snackbarRef.closeSnackbar();
          }, 2000);
        }
      );
  }

  getSalarySheet() {
    const snackbarRef = this.snackBarService.open("Cancel", 0);
    snackbarRef.progress = 1;
    snackbarRef.runProgressBar();
    this.payrollService
      .getPayrollExcelSalarySheetApi(
        this.selectedPayslipYear,
        this.selectedPayslipMonth
      )
      .subscribe(
        (res) => {
          let url = window.URL.createObjectURL(res.body);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = `Salary_Sheet_${this.months[
            this.selectedPayslipMonth
          ].toUpperCase()}_${this.selectedPayslipYear}.xlsx`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          snackbarRef.progress = 100;
          snackbarRef.runProgressBar();
          setTimeout(() => {
            snackbarRef.closeSnackbar();
          }, 2000);
        },
        (err) => {
          snackbarRef.progressText =
            "Error occurred in downloading salary sheet";
          setTimeout(() => {
            snackbarRef.closeSnackbar();
          }, 2000);
        }
      );
  }

  getIsTooltipRequiredForCol(col: string): boolean {
    return COLS_WHICH_REQUIRE_TOOLTIP.includes(col);
  }

  openSnackbar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: this.durationInSeconds * 1000,
      panelClass: ["custom-snackbar-class"],
    });
  }

  openAddEmpDialog(): void {
    const dialogRef = this.dialog.open(AddEmployeeDetailsComponent, {
      width: "1154px",
      height: "610px",
      panelClass: "my-panel-class",
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  columnExist(
    employeeId: string,
    dialog: any,
    element: any,
    columnName: string
  ): void {
    if (columnName === EMPLOYEE_ID) {
      this.payslipExist(employeeId, dialog, element);
    }
  }

  getAdjustedMonth(): number {
    const now = new Date();
    const month = now.getMonth();
    const date = now.getDate();

    if (date >= 25) {
      return month;
    } else {
      return month === 0 ? 11 : month - 1;
    }
  }

  getAdjustedYear(): number {
    const now = new Date();
    const month = now.getMonth();
    const date = now.getDate();

    if (month > 0 || (month === 0 && date > 24)) {
      return now.getFullYear();
    }
    return now.getFullYear() - 1;
  }

  showMonth(monthKey: number): boolean {
    --monthKey;
    if (
      this.getAdjustedYear() === this.selectedPayslipYear &&
      monthKey > this.getAdjustedMonth()
    )
      return false;
    return true;
  }

  toggleEarningsAndDeductions(element) {
    this.currentEmployeeElement = element;
    this.openEarningsAndDeductions = !this.openEarningsAndDeductions;
  }

  toggleChildComponent(value: ToggleChildComponentPayload) {
    this.openEarningsAndDeductions = value.openEarningsAndDeductions;
    if (value.isPayslipTriggered) {
      this.getAllPayrollDetails(
        this.selectedPayslipYear,
        this.selectedPayslipMonth
      );
    }
  }
}
