import { SpinnerComponent } from "src/app/spinner/spinner.component";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var SpinnerService = /** @class */ (function () {
    function SpinnerService(dialog) {
        this.dialog = dialog;
    }
    SpinnerService.prototype.openSpinnerDialog = function () {
        this.dialogRef1 = this.dialog.open(SpinnerComponent, {
            width: "100%",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            panelClass: "my-spinner-class",
            backdropClass: "backdropBackground",
        });
    };
    SpinnerService.prototype.closeSpinnerDialog = function () {
        this.dialogRef1.close();
    };
    SpinnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpinnerService_Factory() { return new SpinnerService(i0.ɵɵinject(i1.MatDialog)); }, token: SpinnerService, providedIn: "root" });
    return SpinnerService;
}());
export { SpinnerService };
