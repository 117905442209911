<div class="add-investment-wrapper">
  <div class="row header-row justify-content-between">
    <div class="col-4 main-heading" matTooltip="Back" (click)="onBackClick()">
      <mat-icon>chevron_left</mat-icon>
      <div>Section {{ sectionName }}</div>
    </div>
    <div class="col-4 d-flex align-items-center">
      Total Limit : {{ totalLimit }}
    </div>
    <div class="col-4 d-flex align-items-center">
      Amount : {{ amountEntered }}
    </div>
  </div>
  <div class="form-wrapper">
    <form [formGroup]="parentFormGroup">
      <div formGroupName="investmentSections" class="row">
        <div class="col" [formGroupName]="formGroupName">
          <div *ngIf="sectionName != 'HRA'">
            <ng-container
              *ngFor="
                let rowNumber of getNumberOfRowsArray(sectionInputs.length)
              "
            >
              <div class="row inputs-row">
                <ng-container
                  *ngFor="
                    let investmentDeclarationInput of sectionInputs.slice(
                      rowNumber * 4,
                      (rowNumber + 1) * 4
                    );
                    trackBy: trackByFn
                  "
                >
                  <div
                    class="col col-3 input-wrapper d-flex flex-column justify-content-end"
                  >
                    <label class="inv_title"
                      >{{ investmentDeclarationInput }}
                    </label>
                    <input
                      numbersOnly
                      [ngClass]="{
                        'form-disabled': isDisabled(investmentDeclarationInput)
                      }"
                      (keyup)="onKeyUp($event, investmentDeclarationInput)"
                      type="text"
                      formControlName="{{ investmentDeclarationInput }}"
                      maxlength="8"
                      [disabled]="isDisabled(investmentDeclarationInput)"
                    />
                    <div
                      class="input-limit-message"
                      *ngIf="rowHasInputLimitMessage(rowNumber)"
                    >
                      <div
                        *ngIf="inputLimitMap?.get(investmentDeclarationInput)"
                      >
                        8-digit limit reached
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <div *ngIf="sectionName === 'HRA'">
            <add-investment-hra-form
            #hraFormComponent
              [hraFormGroup]="getSectionFormGroup('HRA')"
              [fiscalMonths]="fiscalMonths"
              [houses]="houses"
              [isFormSubmitted]="isFormSubmitted"
              (keyUpEmitter)="onKeyUp($event)"
              (onRemoveHouseClickEmitter)="onRemoveHouseClick($event)"
              (updateSectionTotalAmountEmitter)="
                emitUpdateSectionTotalAmountForHRA()
              "
            ></add-investment-hra-form>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="d-flex justify-content-end align-items-center footer">
    <div *ngIf="!isFormSubmitted">
      <button
        style="
          background-color: white;
          color: #38a3a5;
          border: 1px solid #38a3a5;
        "
        class="mr-4"
        mat-button
        (click)="onRestButtonClick()"
      >
        Reset
      </button>
      <button
        style="background-color: #38a3a5; color: white"
        class="mr-4"
        mat-button
        (click)="onSaveAndNextButtonClick()"
      >
        Save as draft and Next
      </button>
    </div>
  </div>
</div>
