import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class YtdDetailsService {
  private path: string;
  private params: HttpParams;

  constructor(private baseService: BaseService) { }

  getYtdDetailsAPI(employeeId: number, fiscal: number): Observable<any> {
    this.params = new HttpParams()
                  .set("employeeId", employeeId.toString())
                  .set("fiscal", fiscal.toString());
    return this.baseService.get("ytd", this.params);
  }

  getYtdDetailsForAllEmployeeAPI(fiscal: string, searchText: string | undefined) {
    this.params = new HttpParams();
    if (searchText) {
      this.params = this.params.append("search", searchText);
    }
    this.path = `ytd/${fiscal}`;
    return this.baseService.get(this.path, this.params);
  }

}
