<div class="investment-declaration-dialog-wrapper">
  <div mat-dialog-title class="header-row row d-flex align-items-center">
    <div class="col-10">
      <div class="row d-flex justify-content-between">
        <div class="col-4 heading">Section {{ data.sectionName }}</div>
        <div class="col-4">Total Limit : {{ data.sectionLimit }}</div>
        <div class="col-4">Amount :</div>
      </div>
    </div>
    <div class="col-2 d-flex align-items-center justify-content-end">
      <mat-icon [mat-dialog-close]="true" class="mat-icon-medium">close</mat-icon>
    </div>
  </div>
  <mat-dialog-content>
    <div class="form-wrapper" [formGroup]="data.parentFormGroup">
      <div formGroupName="investmentSections">
        <div [formGroupName]="data.formGroupName">
          <div *ngIf="data.sectionName != 'HRA'">
            <ng-container
              *ngFor="
                let rowNumber of getNumberOfRowsArray(data.sectionInputs.length)
              "
            >
              <div class="row inputs-row">
                <ng-container
                  *ngFor="
                    let investmentDeclarationInput of data.sectionInputs.slice(
                      rowNumber * 3,
                      (rowNumber + 1) * 3
                    );
                    trackBy: trackByFn
                  "
                >
                  <div
                    class="col col-4 input-wrapper d-flex flex-column justify-content-end"
                  >
                    <label class="inv_title"
                      >{{ investmentDeclarationInput | titlecase }} </label
                    ><br />
                    <input
                      numbersOnly
                      [ngClass]="{
                        formDisabled:
                          investmentDeclarationFormData?.length !== 0
                      }"
                      (keyup)="totalAmountCalc(i, allSections[i].optionValue)"
                      type="text"
                      formControlName="{{ investmentDeclarationInput }}"
                    />
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" style="background-color: #edfef8">
    <div class="footer">
      <button
        style="
          background-color: white;
          color: #38a3a5;
          border: 1px solid #38a3a5;
        "
        class="mr-2"
        mat-button
        mat-dialog-close
      >
        Cancel
      </button>
      <button style="background-color: #38a3a5;color: white;" class="mr-4" mat-button [mat-dialog-close]="true">
        Add Details
      </button>
    </div>
  </mat-dialog-actions>
</div>
