import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import { HomeService } from "../shared/services/home.service";
import { MatSnackBar } from "@angular/material";
import { SnackBarService } from "../shared/services/snackbar.service";
import { TOTAL_INCOME, TOTAL_DEDUCTIONS } from "./home.utility";
import { PayrollService } from "../shared/services/payroll.service";
import { catchError, take } from "rxjs/operators";
import { of } from "rxjs";
import {
  NAME_KEY,
  NET_PAY,
  PAYSLIP_SUMMARY,
  MonthlyPayslipTableData,
  SNACKBAR_LABEL,
  TOTAL_DEDUCTIONS_STRING,
  TOTAL_INCOME_STRING,
  USER_DETAILS,
  UserDetails,
} from "../payslip/payslip.utility";
import { EmployeePayslipDetails } from "../core/interfaces/user";
import { LoginService } from "../account/login.service";
import { EmployeeService } from "../shared/services/employee.service";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, AfterViewInit {
  showSalary = false;
  salaryVisibilityButtonText = "Show Salary";
  greeting = "";
  isGreetingImageVisible = false;
  quotesDetails: any;
  quotesInterval: NodeJS.Timer;
  user: any;
  months: { [month: number]: string } = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  years: number[] = [];
  currentYear = new Date().getFullYear();
  monthsWithDays: { [month: string]: number } = {
    April: 30,
    May: 31,
    June: 30,
    July: 31,
    August: 31,
    September: 30,
    October: 31,
    November: 30,
    December: 31,
    January: 31,
    February: this.isLeapYear(this.currentYear) ? 29 : 28,
    March: 31,
  };
  userDetails: UserDetails[] = USER_DETAILS;
  selectedPayslipMonth: number;
  selectedPayslipYear: number;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  percentages: number[];
  colors: string[];
  centerX: number;
  centerY: number;
  radius: number;
  total: number;
  angles: number[];
  startAngle: number;
  outerRadius: number;
  innerRadius: number;
  currentFiscalPayrollDetails: any;
  payrollComponentsArray: any = [];
  user_details: EmployeePayslipDetails;
  durationInSeconds: number = 5;
  netPayPercent: number;
  deductionPercent: number;
  apiResponseReceived: boolean;
  empJoiningYear: number;
  payslipExistForSelectedYearOrSelectedMonth: boolean;
  employeePayrollDetails: any;
  fileName: string;
  employeePayrollDetailsForPdf: any;
  payrollSummaryDtoList: {
    componentAmount: number;
    payslipComponent: string;
  }[] = [];
  netPay: number;

  constructor(
    private homeService: HomeService,
    private _snackBar: MatSnackBar,
    private snackBarService: SnackBarService,
    private payrollService: PayrollService,
    private loginService: LoginService,
    private employeeService: EmployeeService
  ) {}

  ngOnInit() {
    this.user_details = this.loginService.employeeDetails;
    this.selectedPayslipMonth = this.getMonth();
    this.selectedPayslipYear = this.getYear();
    this.setLWP();
    this.setProfessionalDetails();
    this.getPayrollDetails();
    if (this.user_details) {
      this.empJoiningYear = Number(
        this.user_details.dateOfJoining.split("/")[2]
      );
    }
    this.years = Array.from(
      {
        length:
          this.selectedPayslipYear -
          (this.empJoiningYear || this.selectedPayslipYear - 10) +
          1,
      },
      (_, i) => this.selectedPayslipYear - i
    );
    this.getEmployeePayslip(
      this.user_details.employeeId,
      this.selectedPayslipYear,
      this.selectedPayslipMonth
    );
    this.getRandomQuote();
    const d = new Date();
    const currentHour = d.getHours();
    if (currentHour >= 0 && currentHour < 12) {
      this.greeting = "Good Morning";
    } else if (currentHour >= 12 && currentHour < 16) {
      this.greeting = "Good Afternoon";
    } else {
      this.greeting = "Good Evening";
    }
  }
  getPayrollDetails(): void {
    this.user_details[
      NAME_KEY
    ] = `${this.user_details.firstName} ${this.user_details.lastName}`;
    this.payrollService
      .getPayrollDetailsApi(
        this.user_details.employeeId,
        this.selectedPayslipMonth,
        this.selectedPayslipYear
      )
      .pipe(
        take(1),
        catchError((error) => {
          this.payslipExistForSelectedYearOrSelectedMonth = false;
          return of(null);
        })
      )
      .subscribe((res) => {
        if (res) {
          this.employeePayrollDetails = res;
          this.getPdfDetailsAndNetPay();
        }
      });
  }
  getPdfDetailsAndNetPay(): void {
    this.payslipExistForSelectedYearOrSelectedMonth = true;
    //for assigning the name to the payslip
    const payslipSummary = this.employeePayrollDetails[PAYSLIP_SUMMARY][0];
    this.fileName = `${payslipSummary.employeeId}_${payslipSummary.payslipMonth}_${payslipSummary.fiscal}.pdf`;

    const netPayComponent = this.employeePayrollDetails[PAYSLIP_SUMMARY].find(
      (component) => component.payslipComponent === NET_PAY
    );

    this.netPay = netPayComponent ? netPayComponent.componentAmount : 0;

    this.employeePayrollDetailsForPdf = this.pdfDetails(
      this.employeePayrollDetails
    );
  }
  pdfDetails(employeePayrollDetails): MonthlyPayslipTableData[] {
    const parsedData: MonthlyPayslipTableData[] = [];

    // Defined for code readability
    const earnings = employeePayrollDetails.earnings;
    const deductions = employeePayrollDetails.deductions;

    // case where API does not return earning or deductions is also handled
    const earningsLength = earnings ? earnings.length : 0;
    const deductionsLength = deductions ? deductions.length : 0;
    const counter: number = Math.max(earningsLength, deductionsLength);

    if (counter !== 0) {
      for (let i = 0; i < counter; i++) {
        const data = {
          earningsComponent: earnings
            ? earnings[i]
              ? earnings[i].payslipComponent.replace(/_/g, " ")
              : ""
            : "",
          earningsComponentAmount: earnings
            ? earnings[i]
              ? earnings[i].componentAmount
              : ""
            : "",
          deductionsComponent: deductions
            ? deductions[i]
              ? deductions[i].payslipComponent.replace(/_/g, " ")
              : ""
            : "",
          deductionsComponentAmount: deductions
            ? deductions[i]
              ? deductions[i].componentAmount
              : ""
            : "",
        };
        parsedData.push(data);
      }

      let totalIncome = 0;
      let totalDeductions = 0;
      for (const component of employeePayrollDetails.payslipSummary) {
        if (component.payslipComponent === TOTAL_DEDUCTIONS_STRING) {
          totalDeductions = component.componentAmount;
        } else if (component.payslipComponent === TOTAL_INCOME_STRING) {
          totalIncome = component.componentAmount;
        }
      }
      parsedData.push({
        earningsComponent: "Total Income",
        earningsComponentAmount: totalIncome.toString(),
        deductionsComponent: "Total Deductions",
        deductionsComponentAmount: totalDeductions.toString(),
      });
    }
    return parsedData;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.setIsGreetingImageVisible();
  }

  setIsGreetingImageVisible() {
    if (window.innerWidth < 1150) {
      this.isGreetingImageVisible = false;
    } else {
      this.isGreetingImageVisible = true;
    }
  }

  ngAfterViewInit() {
    this.setIsGreetingImageVisible();
    if (this.apiResponseReceived) {
      this.drawDonutChart();
    }
  }

  drawDonutChart() {
    this.canvas = document.getElementById("donutChart") as HTMLCanvasElement;
    this.ctx = this.canvas.getContext("2d") as CanvasRenderingContext2D;
    this.percentages = [this.deductionPercent, this.netPayPercent];
    this.colors = ["#B9E3C6", "#1C7293"];
    this.centerX = this.canvas.width / 3;
    this.centerY = this.canvas.height / 3;
    this.outerRadius = Math.min(this.canvas.width, this.canvas.height) / 3.5;
    this.innerRadius = this.outerRadius / 2;
    this.total = this.percentages.reduce((acc, val) => acc + val, 0);
    this.angles = this.percentages.map(
      (percentage) => (percentage / this.total) * 2 * Math.PI
    );
    this.startAngle = -Math.PI / 2;
    this.angles.forEach((angle, index) => {
      const textX =
        this.centerX +
        (this.outerRadius / 1.3) * Math.cos(this.startAngle + angle / 2);
      const textY =
        this.centerY +
        (this.outerRadius / 1.3) * Math.sin(this.startAngle + angle / 2);
      this.ctx.beginPath();
      this.ctx.moveTo(this.centerX, this.centerY);
      this.ctx.arc(
        this.centerX,
        this.centerY,
        this.outerRadius,
        this.startAngle,
        this.startAngle + angle
      );
      this.ctx.fillStyle = this.colors[index];
      this.ctx.fill();
      this.ctx.imageSmoothingEnabled = true;
      this.ctx.font = "12px Arial";
      this.ctx.fillStyle = "white";
      this.ctx.textAlign = "center";
      this.ctx.textBaseline = "middle";
      const percentageText = `${this.percentages[index]}%`;
      this.ctx.fillText(percentageText, textX, textY);

      this.startAngle += angle;
    });

    // Draw the inner  circle
    this.ctx.beginPath();
    this.ctx.arc(this.centerX, this.centerY, this.innerRadius, 0, 2 * Math.PI);
    this.ctx.fillStyle = "white";
    this.ctx.fill();

    // Draw the outer border
    this.ctx.beginPath();
    this.ctx.arc(this.centerX, this.centerY, this.outerRadius, 0, 2 * Math.PI);
    this.ctx.lineWidth = 10;
    this.ctx.strokeStyle = "#D8EFF0";
    this.ctx.stroke();
  }
  clearDonutChart() {
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
  }

  onShowSalaryClick() {
    this.showSalary = !this.showSalary;
    switch (this.showSalary) {
      case true:
        this.salaryVisibilityButtonText = "Hide Salary";
        break;
      case false:
        this.salaryVisibilityButtonText = "Show Salary";
        break;
    }
  }

  getRandomQuote() {
    this.homeService.getRandomQuoteApi().subscribe(
      (res) => {
        this.quotesDetails = res;
      },
      (error) => {
        this.openSnackbar(
          "getRandomQuoteApi failed  " + error.error.message,
          "Close"
        );
        console.log("getRandomQuoteApi failed", error);
      }
    );
  }

  getEmployeePayslip(empId: any, year: number, month: number) {
    this.homeService.getEmployeePayslipApi(empId, year, month).subscribe(
      (res) => {
        this.currentFiscalPayrollDetails = res;
        this.payrollSummaryDtoList = res.payrollSummaryDtoList;
        let income = 0;
        let deductions = 0;
        let netPay = 0;
        for (let amount of res.payrollSummaryDtoList) {
          if (amount.payslipComponent === TOTAL_INCOME) {
            income = amount.componentAmount;
          } else if (amount.payslipComponent === TOTAL_DEDUCTIONS)
            deductions = amount.componentAmount;
          else netPay = amount.componentAmount;
        }
        if (income) {
          this.netPayPercent = Math.round((netPay / income) * 100);
          this.deductionPercent = Math.round((deductions / income) * 100);
        }

        this.apiResponseReceived = true;
        this.drawDonutChart();
      },
      (error) => {
        this.apiResponseReceived = false;
        this.currentFiscalPayrollDetails = null;
        this.clearDonutChart();
        if (error.status === 500 || error.status === 404) {
          this.openSnackbar(
            "Some issue occurred while loading payslip. Please contact the backend team to resolve this.",
            "Close"
          );
        } else if (error.status === 403) {
          this.openSnackbar(
            "you are not authorized to access this resource",
            "Close"
          );
        } else {
          const message = `${error.error.message} for ${[
            this.months[this.selectedPayslipMonth],
          ]} ${this.selectedPayslipYear}`;
          this.snackBarService.add({
            message,
            action: "Close",
            config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
          });
        }
      }
    );
  }

  onMonthOrYearChange() {
    if (this.selectedPayslipYear === this.getYear()) {
      if (this.selectedPayslipMonth > this.getMonth()) {
        this.selectedPayslipMonth = 1;
      }
    }
    this.getEmployeePayslip(
      this.user_details.employeeId,
      this.selectedPayslipYear,
      this.selectedPayslipMonth
    );
    this.getPayrollDetails();
    this.setLWP();
  }

  isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  openSnackbar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: this.durationInSeconds * 1000,
      panelClass: ["custom-snackbar-class"],
    });
  }

  getMonth() {
    return new Date().getMonth() > 0 ? new Date().getMonth() : 12;
  }

  getYear() {
    return new Date().getMonth() > 0
      ? new Date().getFullYear()
      : new Date().getFullYear() - 1;
  }

  setLWP() {
    if (this.user_details) {
      this.payrollService
        .getEmployeeMonthlyDeductionsApi(
          this.selectedPayslipMonth,
          this.selectedPayslipYear,
          this.user_details.employeeId
        )
        .subscribe((lwp) => {
          let element = lwp.find(
            (element) => element.deduction.toLowerCase() === "lwp"
          );
          this.user_details.lwpCount =
            element && element.amount ? element.amount : 0;
          this.setEffectiveWorkingDays(this.user_details.lwpCount);
        });
    }
  }

  setProfessionalDetails(): void {
    if (this.user_details && this.employeeService.employeePersonalDetails) {
      const { department, designation } =
        this.employeeService.employeePersonalDetails;
      this.user_details.department = department;
      this.user_details.designation = designation;
    }
  }

  setEffectiveWorkingDays(lwpCount: number) {
    const daysInMonth = new Date(
      this.selectedPayslipYear,
      this.selectedPayslipMonth,
      0
    ).getDate();
    this.user_details.effectiveWorkingDays = daysInMonth - lwpCount;
  }
}
