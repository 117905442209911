<div class="hra-add-investment-wrapper">
  <div *ngFor="let house of houses; let i = index">
    <div class="title-row">
      <div class="house-title">House {{ i + 1 }}</div>
      <div
        *ngIf="houses.length > 1 && !isFormSubmitted"
        class="delete-icon"
        (click)="onRemoveHouseClick(i)"
      >
        <i
          class="far fa-trash-alt fa-2x"
          style="padding-right: 10px; color: #c82727"
        ></i>
      </div>
    </div>
    <form [formGroup]="hraFormGroup.get('House ' + (i + 1))">
      <div class="row">
        <div class="col-md-4">
          <div class="input-wrapper">
            <label>From Month</label>
            <mat-select
              [ngClass]="{
                'form-disabled': isFormSubmitted
              }"
              formControlName="fromMonth"
              class="form-control"
              (selectionChange)="onFromMonthValueChange($event, i)"
            >
              <mat-option *ngFor="let month of fiscalMonths" [value]="month">
                {{ month }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="input-wrapper">
            <label>To Month</label>
            <mat-select
              [ngClass]="{
                'form-disabled':
                  isFormSubmitted ||
                  !houseInputsValidationMap?.get(i + 1)?.get('toMonth')
              }"
              formControlName="toMonth"
              class="form-control"
              (selectionChange)="onToMonthValueChange(i)"
              [matTooltip]="
                !isFormSubmitted &&
                !houseInputsValidationMap?.get(i + 1)?.get('toMonth')
                  ? 'From date not selected'
                  : null
              "
            >
              <mat-option *ngFor="let month of toMonthOptions" [value]="month">
                {{ month }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="input-wrapper">
            <label>Monthly Rent</label>
            <input
              numbersOnly
              [ngClass]="{
                'form-disabled':
                  isFormSubmitted ||
                  !houseInputsValidationMap?.get(i + 1)?.get('monthlyRent')
              }"
              (keyup)="onKeyUp($event, i)"
              type="text"
              formControlName="monthlyRent"
              maxlength="8"
              [matTooltip]="
                !isFormSubmitted &&
                !houseInputsValidationMap?.get(i + 1)?.get('monthlyRent')
                  ? 'To date not selected'
                  : null
              "
            />
            <div
              class="input-limit-message"
              *ngIf="houseMonthlyRentLimitMap?.get(i + 1)"
            >
              8-digit limit reached
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-2">
          <div class="input-wrapper">
            <label>
              <input type="checkbox" formControlName="metroCity" />
              Metro City
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="add-button-wrapper">
    <button
      *ngIf="!isFormSubmitted"
      class="action-button mr-4"
      (click)="onAddHouseClick()"
      [disabled]="houses.length >= 4"
    >
      Add House
    </button>
  </div>
</div>
