import { Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { HomeComponent } from "./home/home.component";
import { EmployeeDetailsComponent } from "./employee-details/employee-details.component";
import { PayrollComponent } from "./payroll/payroll.component";
import { InvestmentDeclarationComponent } from "./investment-declaration/investment-declaration.component";
import { LoginComponent } from "./account/login/login.component";
import { AuthGuard } from "./core/authentication/auth.guard";
import { UserPayrollComponent } from "./user-payroll/user-payroll.component";
import { YtdDetailsComponent } from "./ytd-details/ytd-details.component";
import { AdminSettingsComponent } from "./admin-settings/admin-settings.component";
import { YtdDetailsAllEmployeesComponent } from "./ytd-details-all-employees/ytd-details-all-employees.component";
import { NgxPermissionsGuard } from "ngx-permissions";
import { PAGE_TO_GROUPS_MAPPING } from "./core/authorization/permissions";
import { AuthorizationGuard } from "./core/authorization/authorization.guard";
// import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { UserNotFoundComponent } from "./user-not-found/user-not-found.component";
import { EmployeePoiComponent } from "./employee-poi/employee-poi.component";
import { ProofOfIncomeAdminComponent } from "./admin-poi/proof-of-income-admin/proof-of-income-admin.component";
import { ProofOfIncomeDetailComponent } from "./admin-poi/proof-of-income-detail/proof-of-income-detail.component";
import { UserCtcDetailsComponent } from "./user-ctc-details/user-ctc-details.component";
var ɵ0 = {
    permissions: {
        only: PAGE_TO_GROUPS_MAPPING.empDetails,
    },
}, ɵ1 = {
    permissions: {
        only: PAGE_TO_GROUPS_MAPPING.payroll,
    },
}, ɵ2 = {
    permissions: {
        only: PAGE_TO_GROUPS_MAPPING.allEmployeesYtdDetails,
    },
}, ɵ3 = {
    permissions: {
        only: PAGE_TO_GROUPS_MAPPING.adminSettings,
    },
};
var routes = [
    {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard, AuthorizationGuard],
        children: [
            {
                path: "",
                redirectTo: "home",
                pathMatch: "full",
            },
            {
                path: "home",
                component: HomeComponent,
            },
            {
                path: "empDetails",
                component: EmployeeDetailsComponent,
                canActivate: [NgxPermissionsGuard],
                data: ɵ0,
            },
            {
                path: "payroll",
                component: PayrollComponent,
                canActivate: [NgxPermissionsGuard],
                data: ɵ1,
            },
            {
                path: "investmentDeclaration",
                // canDeactivate: [InvestmentDeclarationGuard],
                component: InvestmentDeclarationComponent,
            },
            { path: "proofOfIncome", component: ProofOfIncomeAdminComponent },
            {
                path: "proofOfIncome/detail/:employeeId",
                component: ProofOfIncomeDetailComponent,
            },
            // {
            //   path: "proofOfIncome",
            //   component: ProofOfIncomeAdminComponent,
            //   children: [
            //     {
            //       path: "proofOfIncome/detail/:employeeId",
            //       component: ProofOfIncomeDetailComponent,
            //     },
            //   ],
            // },
            {
                path: "userPayroll",
                component: UserPayrollComponent,
            },
            {
                path: "ytdDetails",
                component: YtdDetailsComponent,
            },
            {
                path: "userCtcDetials",
                component: UserCtcDetailsComponent,
            },
            {
                path: "allEmployeesYtdDetails",
                component: YtdDetailsAllEmployeesComponent,
                canActivate: [NgxPermissionsGuard],
                data: ɵ2,
            },
            {
                path: "admin-settings",
                component: AdminSettingsComponent,
                canActivate: [NgxPermissionsGuard],
                data: ɵ3,
            },
            {
                path: "employee-poi",
                component: EmployeePoiComponent,
            },
        ],
    },
    {
        path: "login",
        component: LoginComponent,
    },
    {
        path: "user-not-found",
        component: UserNotFoundComponent,
    },
    { path: "", pathMatch: "full", redirectTo: "dashboard" },
];
var isIframe = window !== window.parent && !window.opener;
var isPopup = window.opener && window !== window.opener;
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
