var CtcEarningComponent = /** @class */ (function () {
    function CtcEarningComponent(formBuilder) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.removeComponent = function (index) {
            _this.recentAddedEarnComp.splice(index, 1);
        };
        this.editComponent = function (index) {
            var removedData = _this.recentAddedEarnComp.splice(index, 1);
            _this.editEarnCompIndex = index;
            _this.editEarnCompDetails = removedData[0];
            _this.ctcEarnForm
                .get("componentName")
                .setValue(_this.editEarnCompDetails.componentName);
            _this.ctcEarnForm
                .get("componentType")
                .setValue(_this.editEarnCompDetails.type);
            _this.ctcEarnForm
                .get("isTaxable")
                .setValue(_this.editEarnCompDetails.taxable === "Y" ? true : false);
        };
        this.updateRecentEarningList = function () {
            var updatedDetails = {
                taxable: _this.ctcEarnForm.value.isTaxable ? "Y" : "N",
                componentName: _this.ctcEarnForm.value.componentName,
                type: _this.ctcEarnForm.value.componentType,
            };
            _this.recentAddedEarnComp.splice(_this.editEarnCompIndex, 0, updatedDetails);
            _this.editEarnCompIndex = -1;
            _this.editEarnCompDetails = {};
            _this.ctcEarnForm.reset();
        };
        this.cancelUpdateComponent = function () {
            _this.recentAddedEarnComp.splice(_this.editEarnCompIndex, 0, _this.editEarnCompDetails);
            _this.editEarnCompIndex = -1;
            _this.editEarnCompDetails = {};
            _this.ctcEarnForm.reset();
        };
        this.addComponent = function () {
            var isTaxable = _this.ctcEarnForm &&
                _this.ctcEarnForm.value &&
                _this.ctcEarnForm.value.isTaxable;
            _this.recentAddedEarnComp.push({
                componentName: _this.ctcEarnForm &&
                    _this.ctcEarnForm.value &&
                    _this.ctcEarnForm.value.componentName
                    ? _this.ctcEarnForm.value.componentName
                    : "-",
                type: _this.ctcEarnForm &&
                    _this.ctcEarnForm.value &&
                    _this.ctcEarnForm.value.componentType
                    ? _this.ctcEarnForm.value.componentType
                    : "-",
                taxable: isTaxable == null ? "-" : isTaxable ? "Y" : "N",
            });
            _this.ctcEarnForm.reset();
        };
        this.ctcEarnForm = this.formBuilder.group({});
        this.recentAddedEarnComp = [];
        this.existingEarnComp = [];
        this.editEarnCompDetails = {};
        this.editEarnCompIndex = -1;
    }
    return CtcEarningComponent;
}());
export { CtcEarningComponent };
