<div class="container-fluid">
  <div class="row greeting-wrapper">
    <div [ngClass]="isGreetingImageVisible ? 'col-8 column' : 'col'">
      <div class="greeting-message">
        {{ greeting }}, {{ user_details.firstName }} {{ user_details.lastName }}
      </div>
      <div class="quote-wrapper">
        <div class="quote-text">{{ quotesDetails?.quote }}</div>
        <div
          class="quote-writer"
          [ngClass]="isGreetingImageVisible ? '' : 'mb-2 mt-1'"
        >
          - {{ quotesDetails?.author }}
        </div>
      </div>
    </div>
    <div *ngIf="isGreetingImageVisible" class="greeting-img">
      <img
        src="../../../assets/images/payroll-greeting.svg"
        alt="Gemini Logo"
        class="greeting-img"
      />
    </div>
    <div class="overlay">
      <div class="wave">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <defs>
            <linearGradient id="MyGradient" gradientTransform="rotate(269.26)">
              <stop offset="13.7%" stop-color="rgba(28, 114, 147, 0.1)" />
              <stop offset="67.15%" stop-color="rgba(28, 114, 147, 0.739841)" />
              <stop offset="88.88%" stop-color="#1c7293" />
            </linearGradient>
          </defs>
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  </div>
  <div class="quicklinks">Quick Links</div>
  <div class="links-container cards">
    <!-- <div class="my-container">
      <div class="img-container">
        <img
          src="../../assets/images/IT Statements icon.svg"
          alt=""
          class="links-img"
        />
      </div>
      <div class="link-container">
        <a href="#" matTooltip="IT Statements">IT Statements</a>
      </div>
    </div> -->

    <div class="my-container">
      <div class="img-container">
        <img
          src="../../assets/images/CTC payslip icon.svg"
          alt=""
          class="links-img"
        />
      </div>
      <div class="link-container">
        <a routerLink="/dashboard/userPayroll" matTooltip="CTC Payslip"
          >CTC Payslip</a
        >
      </div>
    </div>

    <div class="my-container">
      <div class="img-container">
        <img
          src="../../assets/images/YTD reports icon.svg"
          alt=""
          class="links-img"
        />
      </div>
      <div class="link-container">
        <a routerLink="/dashboard/ytdDetails" matTooltip="YTD Reports"
          >YTD Reports</a
        >
      </div>
    </div>

    <!-- <div class="my-container">
      <div class="img-container">
        <img
          src="../../assets/images/Reimbursement payslip icon.svg"
          alt=""
          class="links-img"
        />
      </div>
      <div class="link-container">
        <a href="#" matTooltip="Reimbursement Payslip">Reimbursement Payslip</a>
      </div>
    </div> -->

    <!-- <div class="my-container">
      <div class="img-container">
        <img
          src="../../assets/images/Loan statements icon.svg"
          alt=""
          class="links-img"
        />
      </div>
      <div class="link-container">
        <a href="#" matTooltip="Loan Statements">Loan Statements</a>
      </div>
    </div> -->
  </div>

  <div class="cards">
    <div class="row cards-row">
      <div class="col column" style="padding: 0">
        <div class="payslip-card my-card">
          <div class="row title-row">
            <div class="col column payslip-title-wrapper">
              <div class="payslip-title">Payslip</div>
              <img
                src="../../assets/images/clock icon.svg"
                alt=""
                class="clock-icon"
              />
              <div class="dates-container">
                1-{{ monthsWithDays[months[selectedPayslipMonth]] }}
                {{ currentFiscalPayrollDetails?.payslipYear }}
              </div>
            </div>
            <div
              class="col column d-flex justify-content-end payslip-card-options"
            >
              <a
                href="#"
                [matTooltip]="
                  payslipExistForSelectedYearOrSelectedMonth
                    ? 'Download Payslip'
                    : 'Payslip does not exist'
                "
                (click)="
                  !payslipExistForSelectedYearOrSelectedMonth
                    ? $event.preventDefault()
                    : downloadPayslip.download_pdf()
                "
                [class.disabled]="!payslipExistForSelectedYearOrSelectedMonth"
              >
                <img
                  src="../../assets/images/download icon.svg"
                  alt=""
                  class="payslip-card-title-icon"
                />
              </a>
              <!-- <a href="#" [matTooltip]="'New Tab'">
                <img
                  src="../../assets/images/new tab icon.svg"
                  alt=""
                  class="payslip-card-title-icon"
                />
              </a> -->
              <select
                class="select-month text-start"
                [(ngModel)]="selectedPayslipMonth"
                (change)="onMonthOrYearChange()"
              >
                <ng-container
                  *ngFor="let month of months | keyValueOriginalOrder"
                >
                  <ng-container
                    *ngIf="
                      +month.key <= this.getMonth() &&
                      this.getYear() == this.selectedPayslipYear
                    "
                  >
                    <option [ngValue]="+month.key">
                      {{ month.value | abbreviateMonth }}
                    </option>
                  </ng-container>
                  <ng-container
                    *ngIf="this.getYear() != this.selectedPayslipYear"
                  >
                    <option [ngValue]="+month.key">
                      {{ month.value | abbreviateMonth }}
                    </option>
                  </ng-container>
                </ng-container>
              </select>
              <select
                class="select-year text-start"
                [(ngModel)]="selectedPayslipYear"
                (change)="onMonthOrYearChange()"
              >
                <option *ngFor="let year of years" [ngValue]="year">
                  {{ year }}
                </option>
              </select>
            </div>
          </div>
          <mat-divider style="margin: 24px 0 24px 0"></mat-divider>

          <div class="row" style="padding: 0 30px 0 28px">
            <div
              class="col column"
              style="margin-right: 18px; position: relative"
            >
              <p class="month-heading">
                Month: {{ months[selectedPayslipMonth] }}
              </p>
              <canvas id="donutChart" width="300px" height="300px"
                >No Payslip exist for this month.</canvas
              >
            </div>
            <div class="col column">
              <div class="row">
                <div class="col column month-heading">
                  Paid Days:
                  {{
                    currentFiscalPayrollDetails
                      ? currentFiscalPayrollDetails?.paidDays
                      : "-"
                  }}
                </div>
                <div class="col column">
                  <button class="show-salary" (click)="onShowSalaryClick()">
                    {{ salaryVisibilityButtonText }}
                  </button>
                </div>
              </div>
              <div
                class="row my-salary"
                *ngFor="let element of payrollSummaryDtoList; let i = index"
              >
                <div class="col" style="display: flex">
                  <img
                    [src]="
                      i === 2
                        ? '../../assets/images/net-pay-rectangle.svg'
                        : i === 0
                        ? '../../assets/images/gross-pay-rectangle.svg'
                        : '../../assets/images/deductions-rectangle.svg'
                    "
                    alt=""
                  />
                  <p class="salary-component">{{ element.payslipComponent }}</p>
                </div>
                <div
                  class="col salary-value"
                  *ngIf="showSalary; else elseBlock"
                >
                  {{
                    apiResponseReceived
                      ? (element.componentAmount | currency : "₹")
                      : ""
                  }}
                </div>
                <ng-template #elseBlock>
                  <div class="col salary-value">₹******</div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col column">
        <div class="row" style="height: 100%">
          <div class="col column">
            <mat-card class="my-card it-declaration-card">
              <mat-card-header style="padding-bottom: 10px">
                <mat-card-title class="IT-Declaration-heading"
                  >IT Declaration</mat-card-title
                >
              </mat-card-header>
              <mat-card-content>
                <div class="row">
                  <div class="col">
                    <img
                      src="../../assets/images/IT Declaration.svg"
                      alt=""
                      class="IT-declaration-img"
                    />
                    <p class="it-declaration-para">
                      Hurry! Your IT declaration is awaiting. Please submit it
                      before 23 Apr 2023.
                    </p>
                    <button
                      class="btn declare-btn"
                      routerLink="/dashboard/investmentDeclaration"
                    >
                      Declare
                    </button>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="position: fixed">
  <app-payslip-pdf
    #downloadPayslip
    [userDetails]="userDetails"
    [selectedEmployeeDetails]="user_details"
    [fileName]="fileName"
    [employeePayrollDetailsForPdf]="employeePayrollDetailsForPdf"
    [selectedPayslipYear]="selectedPayslipYear"
    [selectedPayslipMonth]="months[selectedPayslipMonth]"
    [netPay]="netPay"
  ></app-payslip-pdf>
</div>
