import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  MatDialog,
  MatPaginator,
  MatSnackBar,
  MatSort,
  MatTableDataSource,
  PageEvent,
} from "@angular/material";
import { AddEmployeeDetailsComponent } from "../add-employee-details/add-employee-details.component";
import { EmployeeService } from "../shared/services/employee.service";
import { FEATURE_TO_GROUPS_MAPPING } from "../core/authorization/permissions";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { SpinnerService } from "../shared/services/spinner.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Subject } from "rxjs";
import { PayrollDialogComponent } from "../payroll-dialog/payroll-dialog.component";
import { SnackBarService } from "../shared/services/snackbar.service";
import { PayrollDialogComponentInput } from "../payroll-dialog/payroll-dialog.utility";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

const COLS_WHICH_REQUIRE_TOOLTIP = ["employeeId", "name"];

@Component({
  selector: "app-employee-details",
  templateUrl: "./employee-details.component.html",
  styleUrls: ["./employee-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class EmployeeDetailsComponent implements OnInit, AfterViewInit {
  editEmployeeForm: FormGroup;
  isClearEnable: boolean = false;
  showSearchParams: boolean = true;
  expandedElement: any;
  allEmpDetails: any;
  searchResults: any;
  employeeCTCDetails: any[] = [];
  employeeCTCDetailsForEditDialog: any[] = [];
  files: any[] = [];
  browsedFile: any;
  employeeCalculatedDetails: any;
  durationInSeconds = 5;

  ctcDetails: boolean = true;
  featurePermissions = FEATURE_TO_GROUPS_MAPPING["empDetails"];
  isAuthorized = {
    write: false,
    update: false,
    delete: false,
  };
  pageIndex: number = 0;
  pageSize: number = 10;
  totalEmployees: number = 0;
  @ViewChild("fileInp", { static: false }) fileInp: ElementRef;

  empDetails: any = [
    { header: "Aadhaar No.", field: "aadhaar" },
    { header: "Permanent Address", field: "permanentAddress" },
    { header: "Correspondence Address", field: "correspondenceAddress" },
    { header: "PAN", field: "pan" },
    { header: "UAN", field: "uanNo" },
    { header: "PF No.", field: "pfNo" },
    { header: "Acc. No.", field: "bankAcctNo" },
    { header: "Bank Acc. Name", field: "bankAcctName" },
    { header: "Bank Acc. Address", field: "bankAcctAddress" },
    { header: "IFSC", field: "bankIFSC" },
  ];

  public empListDataSource: MatTableDataSource<any>;

  private searchText$ = new Subject<string>();
  selectedName: string;
  selectedId: number;
  selectedEmployeeCode: string;
  years: any = [];
  today: Date;
  displayedColumns: string[] = [
    "expand_action",
    "employeeId",
    "name",
    "dateOfJoining",
    "dateOfExit",
    "employeeCode",
    "status",
    "options",
  ];
  months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];
  fiscal: any = getCurrentFiscalYear();
  selectedYear: number = getCurrentFiscalYear();
  lwpMonth: any;
  lwpYear: any;
  lwpNoOfDays: any;
  submitOrupload: string = "Upload";
  confirmValueList = [];
  isCtcTabSelected: boolean = true;
  editDialogRef: any;
  editElement: any;
  editTemplateRef: TemplateRef<any>;
  showSpinner: boolean = true;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild("formContainer", { static: false }) formContainer: ElementRef;
  @ViewChild("deleteEmpDialog", { static: false })
  deleteEmpDialog: TemplateRef<any>;
  initialFormValues: any;
  originalLwpNoOfDays: any;
  isEditMode: boolean = false;
  isEditPf: boolean = false;
  isEditingPersonalDetails: boolean = false;
  isEditingCtcDetails: boolean = false;
  selectedEmployeeDetails: any;
  searchInputValue: string = "";
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.empListDataSource) {
      this.empListDataSource.paginator = value;
    }
  }
  isScreenSizeLessThanMdBreakPoint = false;
  constructor(
    public dialog: MatDialog,
    private employeeService: EmployeeService,
    private _snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    public fb: FormBuilder,
    private snackBarService: SnackBarService
  ) {}

  ngAfterViewInit(): void {
    this.setIsScreenSizeLessThanMdBreakPoint();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.setIsScreenSizeLessThanMdBreakPoint();
  }

  ngOnInit() {
    this.today = new Date();
    this.editEmployeeForm = this.fb.group({});
    this.getAllEmployeesDetails();

    this.searchText$
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.pageIndex = 0;
        this.getAllEmployeesDetails(searchTerm);
      });

    this.years = Array.from(
      new Array(11),
      (x, i) => getCurrentFiscalYear() - i
    );
    this.setCurrentMonth();
    this.setCurrentYear();
  }

  handleTabChange(event: MatTabChangeEvent) {
    this.isCtcTabSelected = event.index === 0;
  }
  setCurrentMonth() {
    const currMonth = this.today.getMonth() + 1;
    const currMonthObj = this.months.filter(
      (month) => month.value == currMonth
    );
    this.lwpMonth = currMonthObj[0].name;
  }

  setCurrentYear() {
    const currYear = this.today.getFullYear();
    this.lwpYear = currYear;
  }

  openAddEmpDialog(): void {
    const dialogRef = this.dialog.open(AddEmployeeDetailsComponent, {
      width: "1154px",
      height: "610px",
      panelClass: "my-panel-class",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (this.employeeService.newUserAdded) {
        this.getAllEmployeesDetails();
      }
      this.employeeService.newUserAdded = false;
    });
  }
  getDetailsAsPerYear() {
    this.allEmpDetails.forEach((element) => {
      if (this.expandedElement === element) {
        this.getEmployeeCTCDetails(element);
      }
    });
  }

  getAllEmployeesDetails(search?: string): void {
    this.showSpinner = true;
    this.employeeService
      .getAllEmployeesDetailsApi(this.pageIndex, this.pageSize, search)
      .subscribe(
        (res) => {
          // allEmpDetails should be removed
          this.allEmpDetails = res.content;
          this.showSpinner = false;
          this.totalEmployees = res.totalElements;
          this.empListDataSource = res.content;
        },
        (error) => {
          this.showSpinner = false;
          console.error("Error fetching employee details", error);
        }
      );
  }
  onSearch($event: any): void {
    this.searchText$.next(this.searchInputValue.trim());
  }
  onPageChange(event: PageEvent): void {
    this.pageIndex = this.pageSize !== event.pageSize ? 0 : event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllEmployeesDetails(this.searchInputValue);
  }

  // onPageChange(event: PageEvent): void {
  //   if (this.pageSize !== event.pageSize) {
  //     this.pageIndex = 0;
  //   } else {
  //     this.pageIndex = event.pageIndex;
  //   }

  //   this.pageSize = event.pageSize;

  //   this.getAllEmployeesDetails(this.searchInputValue);
  // }
  getEmployeeDetailsByName() {
    this.employeeService
      .getEmployeeDetailsByNameApi(this.searchInputValue)
      .subscribe((res) => {
        this.searchResults = res;
        this.empListDataSource = new MatTableDataSource<any>(
          this.searchResults
        );
        this.empListDataSource.sort = this.sort;
        this.empListDataSource.paginator = this.paginator;
      });
  }

  displayAllEmpDataOnEmptyInput() {
    if (this.searchInputValue === "") {
      this.empListDataSource = new MatTableDataSource<any>(this.allEmpDetails);
      this.empListDataSource.sort = this.sort;
      this.empListDataSource.paginator = this.paginator;
    }
  }

  getEmployeeCTCDetails(element, edit = false) {
    if (this.expandedElement === element || edit === true) {
      this.employeeService
        .getEmployeeCTCDetailsApi(element.employeeId, String(this.fiscal))
        .subscribe(
          (res) => {
            if (edit === true) {
              this.employeeCTCDetailsForEditDialog = res;
              this.employeeCTCDetailsForEditDialog.forEach(
                ({ ctcComponent, amount }) => {
                  this.editEmployeeForm.addControl(
                    ctcComponent,
                    new FormControl(amount.toString())
                  );
                  this.editEmployeeForm.get(ctcComponent).patchValue(amount);
                }
              );
              this.initialFormValues = this.editEmployeeForm.value;
              this.editEmployeeForm.markAsPristine();
            } else {
              this.ctcDetails = true;
              this.employeeCTCDetails = res;
            }
          },
          (error) => {
            this.employeeCTCDetailsForEditDialog = [];
            this.ctcDetails = false;
            this.snackBarService.add({
              message: error.error.message,
              action: "close",
              config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
            });
          }
        );
    }
  }

  resetForm() {
    this.editEmployeeForm.reset(this.initialFormValues);
  }

  uploadBulkData() {
    const formData = new FormData();
    formData.append("file", this.browsedFile);
    if (!this.browsedFile) {
      alert("Select a file to continue.");
    }
    this.spinnerService.openSpinnerDialog();
    this.employeeService.postBulkFileUploadApi(formData).subscribe(
      (res) => {
        this.spinnerService.closeSpinnerDialog();
        this.openSnackbar(res.message, "Close");
        this.dialog.closeAll();
        this.browsedFile = null;
        this.getAllEmployeesDetails();
      },
      (error) => {
        this.spinnerService.closeSpinnerDialog();
        this.openSnackbar(error.error.message, "Close");
      }
    );
  }

  /**
   * on file drop handler
   */
  onFileDropped() {
    this.prepareFilesList();
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event) {
    this.browsedFile = event.target.files[0];
    this.prepareFilesList();
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile() {
    this.submitOrupload = "Upload";
    this.fileInp.nativeElement.value = "";
    this.browsedFile = null;
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      const progressInterval = setInterval(() => {
        if (this.browsedFile != null) {
          if (this.browsedFile.progress === 100) {
            this.submitOrupload = "Submit";
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.browsedFile.progress += 5;
          }
        } else {
          clearInterval(progressInterval);
        }
      }, 200);
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList() {
    this.browsedFile.progress = 0;
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes) {
    let decimals;
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  openUploadFilesDialog(templateRef: TemplateRef<any>) {
    let dialogRef = this.dialog.open(templateRef, {
      panelClass: "upload-panel-class",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  onClose() {
    this.dialog.closeAll();
    this.browsedFile = null;
  }

  openSnackbar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: this.durationInSeconds * 1000,
      panelClass: ["custom-snackbar-class"],
    });
  }

  deleteEmployee(id) {
    this.spinnerService.openSpinnerDialog();
    this.employeeService.deleteEmployeeByIdApi(id).subscribe(
      (result) => {
        this.getAllEmployeesDetails();
        this.spinnerService.closeSpinnerDialog();
        this.openSnackbar(result.message, "close");
      },
      (error) => {
        this.spinnerService.closeSpinnerDialog();
        this.openSnackbar("Some problem occurred", "close");
        console.error(error);
      }
    );
  }

  openDeleteEmpDialog(firstName: any, lastName: any, id) {
    const name = firstName + " " + lastName;
    let dialogRef = this.dialog.open(PayrollDialogComponent, {
      data: {
        title: "Confirmation",
        msg: `Are you sure you want to delete details for:
        <br> Employee Name: ${name}
        <br> Employee Id: ${id || ""}`,
        dialogType: "delete",
        onYesClickFunction: () => this.deleteEmployee(id),
      } as PayrollDialogComponentInput,
    });
  }

  getEmployeesExcel() {
    const snackbarRef = this.snackBarService.open("Close", 0);
    snackbarRef.progress = 1;
    snackbarRef.runProgressBar();
    this.employeeService.getEmployeesExcelApi().subscribe(
      (res) => {
        let filename = res.headers
          .get("content-disposition")
          .split("filename=")[1];
        let url = window.URL.createObjectURL(res.body);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        snackbarRef.progress = 100;
        snackbarRef.runProgressBar();
        setTimeout(() => {
          snackbarRef.closeSnackbar();
        }, 2000);
      },
      (err) => {
        snackbarRef.progressText =
          "Error occurred in downloading employees excel data";
        setTimeout(() => {
          snackbarRef.closeSnackbar();
        }, 2000);
      }
    );
  }

  resetEditEmployeeForm() {
    const formValues = {};
    this.employeeCTCDetailsForEditDialog.forEach(({ ctcComponent, amount }) => {
      formValues[ctcComponent] = amount;
    });
    this.editEmployeeForm.patchValue(formValues);
  }

  updateEmployeeCTC() {
    let payloadArray = [];

    this.employeeCTCDetailsForEditDialog.forEach((ctc) => {
      let payload = {};
      payload["employeeCtcId"] = ctc.employeeCtcId;
      payload["employeeId"] = ctc.employeeId;
      payload["ctcComponent"] = ctc.ctcComponent;
      payload["amount"] = parseInt(
        this.editEmployeeForm.get(ctc.ctcComponent).value
      );
      payload["fiscal"] = ctc.fiscal;
      payload["status"] = ctc.status;
      payload["notes"] = ctc.notes;
      payloadArray.push(payload);
    });

    this.employeeService.updateEmployeeCtc(payloadArray).subscribe(
      (res) => {
        this.openSnackbar(res.message, "close");
        this.resetEditEmployeeForm();
        this.employeeService
          .getEmployeeCTCDetailsApi(
            payloadArray[0].employeeId,
            String(this.fiscal)
          )
          .subscribe((res) => {
            this.ctcDetails = true;
            this.employeeCTCDetails = res;
          });
      },
      (err) => {
        if (err.status === 500) {
          this.openSnackbar(
            "Some issue occurred, CTC is not updated. Please contact the backend team to resolve this.",
            "Close"
          );
        } else {
          this.openSnackbar(err.error.message, "Close");
        }
      }
    );
    this.dialog.closeAll();
  }
  getIsTooltipRequiredForCol(col: string) {
    return COLS_WHICH_REQUIRE_TOOLTIP.includes(col);
  }

  downloadBulkUploadSampleExcel() {
    const snackbarRef = this.snackBarService.open("Close", 0);
    snackbarRef.progress = 1;
    snackbarRef.runProgressBar();
    this.employeeService.getEmployeeOnboardForm().subscribe(
      (res) => {
        let filename = res.headers
          .get("content-disposition")
          .split("filename=")[1];
        filename = filename.replace(/^["]/, "").replace(/["]$/, "");
        let url = window.URL.createObjectURL(res.body);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        snackbarRef.progress = 100;
        snackbarRef.runProgressBar();
        setTimeout(() => {
          snackbarRef.closeSnackbar();
        }, 2000);
      },
      (error) => {
        snackbarRef.progressText =
          "Error occurred in downloading sample excel file";
        setTimeout(() => {
          snackbarRef.closeSnackbar();
        }, 2000);
      }
    );
  }

  openSubmissionDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
    this.editDialogRef.close();
    if (!Array.isArray(this.confirmValueList)) {
      this.confirmValueList = [];
    }
    this.confirmValueList = [];
    let index = 0;
    this.employeeCTCDetailsForEditDialog.forEach(({ ctcComponent, amount }) => {
      if (!this.confirmValueList[index]) {
        this.confirmValueList[index] = {
          label: "",
          oldValues: 0,
          newValues: 0,
        };
      }
      this.confirmValueList[index].label = ctcComponent;
      this.confirmValueList[index].oldValues = amount;
      this.confirmValueList[index].newValues =
        this.editEmployeeForm.get(ctcComponent).value;
      index++;
    });
  }

  openAgainEditDialog(templateRef: TemplateRef<any>, element) {
    this.editElement = element;
    this.editTemplateRef = templateRef;
    let dialogRef = this.dialog.open(templateRef, {
      width: "744px",
      panelClass: "edit-employee-dialog",
    });
    this.editDialogRef = dialogRef;
    this.selectedName = element.firstName + " " + element.lastName;
    this.selectedEmployeeCode = element.employeeCode
      ? element.employeeCode
      : "";
    this.selectedId = element.employeeId;
  }

  onNoClick() {
    this.openAgainEditDialog(this.editTemplateRef, this.editElement);
    if (this.confirmValueList.length > 0) {
      this.confirmValueList.forEach(({ label, newValues }) => {
        this.editEmployeeForm.get(label).patchValue(newValues);
      });
    }
  }

  resetValues() {
    this.lwpNoOfDays = this.originalLwpNoOfDays;
  }
  lwpChanges(): boolean {
    return this.lwpNoOfDays !== this.originalLwpNoOfDays;
  }

  getAdjustedMonth(): number {
    const now = new Date();
    const month = now.getMonth();
    const date = now.getDate();
    if (date >= 25) {
      return month;
    } else {
      return month === 0 ? 11 : month - 1;
    }
  }

  getAdjustedYear(): number {
    const now = new Date();
    const month = now.getMonth();
    const date = now.getDate();
    if (month > 0 || (month === 0 && date > 24)) {
      return now.getFullYear();
    }
    return now.getFullYear() - 1;
  }

  showMonth(monthKey: number): boolean {
    --monthKey;
    if (
      this.getAdjustedYear() === this.lwpYear &&
      monthKey > this.getAdjustedMonth()
    )
      return false;
    return true;
  }

  getMonthValueFromMonthName(lwpMonth) {
    for (let month of this.months) {
      if (month.name === lwpMonth) return month.value;
    }
  }

  onYearChange() {
    if (this.lwpYear == this.getAdjustedYear()) {
      if (
        this.getMonthValueFromMonthName(this.lwpMonth) > this.getAdjustedMonth()
      ) {
        this.lwpMonth = "January";
      }
    }
  }
  closePersonalDetails() {
    this.isEditingPersonalDetails = false;
    this.getAllEmployeesDetails();
  }
  onCloseEditScreen() {
    this.isEditingPersonalDetails = false;
  }
  closeCtcDetails() {
    this.isEditingCtcDetails = false;
  }
  onClosePfEditScreen() {
    this.isEditingCtcDetails = false;
  }
  setIsScreenSizeLessThanMdBreakPoint() {
    this.isScreenSizeLessThanMdBreakPoint =
      window.innerWidth >= 768 ? false : true;
  }
  editPersonalDetails(element) {
    this.isEditingPersonalDetails = true;
    this.selectedEmployeeDetails = element;
  }
  openEditCtcDetials(element) {
    this.isEditingCtcDetails = true;

    this.selectedEmployeeDetails = {
      ...element,
      name: `${element.firstName} ${element.lastName}`,
    };
  }
}
