import { PipeTransform } from "@angular/core";
var ReplaceUnderscorePipe = /** @class */ (function () {
    function ReplaceUnderscorePipe() {
    }
    ReplaceUnderscorePipe.prototype.transform = function (value) {
        return value ? value.replace(/_/g, " ") : value;
    };
    return ReplaceUnderscorePipe;
}());
export { ReplaceUnderscorePipe };
