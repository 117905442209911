import { Component, OnInit } from '@angular/core';
import { UserSettingsService } from '../shared/services/user-settings.service';
import { AdminService } from '../shared/services/admin.service';
import { EmployeeService } from '../shared/services/employee.service';
import { SnackBarService } from '../shared/services/snackbar.service';
import { getCurrentFiscalYear } from '../shared/app.utility';

const PF_PREFERENCE_NOT_SELECTED_MSG = "Select PF preference from the given options";
const PF_PREFERENCE_SUCCESSFULLY_SAVED_MSG = "Preference was saved successfully.";
const PF_SETTINGS_NOT_CONFIGURED_MSG = "PF settings are not configured, contact admin";


@Component({
  selector: "app-user-settings",
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"],
})
export class UserSettingsComponent implements OnInit {
  fiscal: any = getCurrentFiscalYear(4);
  years: number[];
  pfRadioGroupValue: string | null;
  pfTypes: string[]
  userInfo: any;
  savedPFPreference: string | null = null;
  isPFPreferenceConfigurable = false;
  isDefaultValueSet = false;
  isPFConfigurable = false;
  user_details: any;
  empJoiningYear: number;
  constructor(private userSettingsService: UserSettingsService, private adminService: AdminService, private employeeService: EmployeeService,
    private snackBarService: SnackBarService) { }

  ngOnInit() {
    this.user_details = JSON.parse(localStorage.getItem("user_details"));
    this.userInfo = JSON.parse(sessionStorage.getItem('user'));
    this.empJoiningYear = this.user_details.dateOfJoining.split(
      "/"
    )[2] as number;
    this.years = Array.from(
      { length: getCurrentFiscalYear() - this.empJoiningYear + 1 },
      (_, i) => getCurrentFiscalYear() - i
    )
    this.setPFTypes();
  }

  setPFTypes() {
    this.userSettingsService.getAllPFTypesApi().subscribe((pfTypes) => {
      this.pfTypes = pfTypes;
      this.setDefaultPFType();
    });
  }

  reset_flags_radioGroupValue_savedPreference() {
    this.isPFConfigurable = false;
    this.isPFPreferenceConfigurable = false;
    this.pfRadioGroupValue = null;
    this.savedPFPreference = null;
  }

  setDefaultPFType() {
    this.adminService.getConfigDetailsByFiscalApi(this.fiscal).subscribe(details => {
      if (details.length === 0) {
        this.snackBarService.add({ message: PF_SETTINGS_NOT_CONFIGURED_MSG, action: 'close', config: { duration: 2000,
          panelClass: ['custom-snackbar-class']
         } });
        this.isDefaultValueSet = true;
        this.reset_flags_radioGroupValue_savedPreference()
      }
      else {
        for (const detail of details) {
          if (detail.configuredSubComponent === "PF_CONFIGURABLE") {
            if (detail.status) {
              this.isPFConfigurable = true;
              for (const data of details) {
                if (data.configuredSubComponent === "PF_PREFERENCE_CONFIGURABLE") {
                  if (data.status) {
                    this.isPFPreferenceConfigurable = true;
                    this.setSavedEmpPFPreference();
                  } else {
                    this.pfRadioGroupValue = 'VARIABLE'
                    this.isDefaultValueSet = true;
                  }
                  break;
                }
              }
            } else {
              this.pfRadioGroupValue = 'NO_PF'
              this.isDefaultValueSet = true;
            }
            break;
          }
        }
      }
    });
  }
  setSavedEmpPFPreference() {
    this.userSettingsService.getEmpPFPreferenceByEmpIdAndFiscalApi(this.userInfo.account.username.toLowerCase(), this.fiscal).subscribe(res => {
      this.savedPFPreference = res.pfType;
      this.pfRadioGroupValue = res.pfType;
      this.isDefaultValueSet = true;
    }, err => {
      this.isDefaultValueSet = true;
    })
  }

  resetPFRadioGroup() {
    this.pfRadioGroupValue = this.savedPFPreference;
  }

  savePFPreference() {
    if (!this.pfRadioGroupValue) {
      this.snackBarService.add({ message: PF_PREFERENCE_NOT_SELECTED_MSG, action: 'close', config: { duration: 2000,
        panelClass: ['custom-snackbar-class']
       } });
    } else {
      const requestBody = {
        "employeeId": this.userInfo.account.username.toLowerCase(),
        "pfType": this.pfRadioGroupValue,
        "fiscal": this.fiscal
      };
      this.employeeService.postEmployeePFPreferenceApi(requestBody).subscribe(res => {
        this.snackBarService.add({ message: PF_PREFERENCE_SUCCESSFULLY_SAVED_MSG, action: 'close', config: { duration: 2000,
          panelClass: ['custom-snackbar-class']
         } });
      }, err => {
        this.resetPFRadioGroup();
        this.snackBarService.add({ message: err.error, action: 'close', config: { duration: 2000,
          panelClass: ['custom-snackbar-class']
         } });
      })
    }
  }

  onFiscalChange() {
    this.isDefaultValueSet = false;
    this.setDefaultPFType();
  }

}
