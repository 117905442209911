export type DialogType = "post" | "delete" | "logout";

export type PayrollDialogComponentInput = {
  title: string;
  msg: string;
  dialogType: DialogType;
  onYesClickFunction: (param?: any) => void;
  onNoClickFunction?: (param?: any) => void;
  yesButtonText?: string;
  noButtonText?: string;
};

export const DIALOG_TYPE_IMG_URL_DICT: { [type in DialogType]: string } = {
  post: "../../assets/images/confirmation-dialog.svg",
  delete: "../../assets/images/delete-confirmation.svg",
  logout: "../../assets/images/logout-1.png",
};
