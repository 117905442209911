import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material";
import { of } from "rxjs";
import { catchError, finalize, mergeMap } from "rxjs/operators";
import {
  IEarningDeductionComponent,
  IEmployeeEarning,
} from "src/app/core/interfaces/payroll";
import { PayrollService } from "src/app/shared/services/payroll.service";
import { SnackBarService } from "src/app/shared/services/snackbar.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";

@Component({
  selector: "app-employee-earnings",
  templateUrl: "./employee-earnings.component.html",
  styleUrls: ["./employee-earnings.component.scss"],
})
export class EmployeeEarningsComponent implements OnInit {
  @Input() selectedPayslipMonth: number;
  @Input() selectedPayslipYear: number;
  @Input() element: any;
  @Output() earningsFormStatus = new EventEmitter<any>();

  earningsFormGroup: FormGroup;
  addEarningFormGroup: FormGroup;
  allEarningComponents: IEarningDeductionComponent[] = [];
  employeeEarningsList: IEmployeeEarning[] = [];
  filteredEarningComponents: IEarningDeductionComponent[] = [];
  showSpinner: boolean = true;

  @ViewChild("addEarningModal", { static: false }) addEarningModal;

  constructor(
    private payrollService: PayrollService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getEmployeeEarningsKeys();
    this.earningsFormGroup = this.formBuilder.group({});
    this.addEarningFormGroup = this.formBuilder.group({
      component: ["", Validators.required],
      amount: ["", [Validators.required, Validators.min(1)]],
    });
  }

  getEmployeeEarningsKeys() {
    this.showSpinner = true;

    this.payrollService
      .getEmployeeEarningsKeysApi()
      .pipe(
        mergeMap((keysResponse) => {
          this.allEarningComponents = keysResponse;
          return this.payrollService
            .getEmployeeMonthlyEarningsApi(
              this.selectedPayslipMonth,
              this.selectedPayslipYear,
              this.element.employeeId
            )
            .pipe(catchError(() => of([])));
        }),
        catchError(() => of([])),
        finalize(() => {
          this.showSpinner = false;
        })
      )
      .subscribe((result) => {
        this.employeeEarningsList = result.length ? result : [];

        this.initializeAndPatchForm(result);
      });
  }

  initializeAndPatchForm(data: any[]): void {
    const controls: { [key: string]: FormControl } = {};

    data.forEach((earning) => {
      controls[earning.component] = new FormControl(null, [
        Validators.required,
        Validators.min(1),
      ]);
    });

    this.earningsFormGroup = this.formBuilder.group(controls);

    const patchValues = {};
    data.forEach((item) => {
      if (this.earningsFormGroup.controls[item.component]) {
        patchValues[item.component] = item.amount;
      }
    });
    this.earningsFormGroup.patchValue(patchValues);

    this.earningsFormGroup.valueChanges.subscribe(() => {
      if (this.earningsFormGroup.dirty) {
        this.emitFormStatus();
      }
    });

    this.emitFormStatus();
  }

  emitFormStatus(): void {
    const formValues = this.earningsFormGroup.value;
    this.earningsFormStatus.emit({
      hasValues: Object.values(formValues).some(
        (value) => value !== null && value !== ""
      ),
      formData: this.preparePayload(formValues),
      touched: this.earningsFormGroup.dirty,
      empty: Object.values(formValues).every(
        (value) => value === null || value === ""
      ),
      invalid: this.earningsFormGroup.invalid,
    });
  }

  preparePayload(formValues: any): any[] {
    return Object.keys(formValues)
      .filter((key) => Number(formValues[key]) > 0)
      .map((key) => ({
        employeeId: this.element.employeeId,
        year: this.selectedPayslipYear,
        component: key,
        amount: Number(formValues[key]),
        month: this.selectedPayslipMonth,
        fiscal: `${this.selectedPayslipYear}`,
      }));
  }

  openAddEarningModal() {
    this.filteredEarningComponents = this.allEarningComponents.filter(
      (component) =>
        !this.employeeEarningsList.some(
          (earning) => earning.component === component.optionValue
        )
    );

    this.addEarningFormGroup.reset();
    if (this.filteredEarningComponents.length === 0) {
      this.addEarningFormGroup.get("amount").disable();
    } else {
      this.addEarningFormGroup.get("amount").enable();
    }
    this.dialog.open(this.addEarningModal, { width: "400px" });
  }

  updateEarningsWithIds(data) {
    this.employeeEarningsList = this.employeeEarningsList.map((el) => {
      if (!el.monthlyEarningsId) {
        const matchedElement = data.find(
          (element) => el.component === element.component
        );
        if (matchedElement) {
          return {
            ...el,
            monthlyEarningsId: matchedElement.monthlyEarningsId,
          };
        }
      }
      return el;
    });
  }

  addControlToEarningsForm(): void {
    if (this.addEarningFormGroup.invalid) {
      return;
    }

    const newEarning: IEmployeeEarning = {
      employeeId: this.element.employeeId,
      year: this.selectedPayslipYear,
      component: this.addEarningFormGroup.value.component,
      amount: Number(this.addEarningFormGroup.value.amount),
      month: this.selectedPayslipMonth,
      fiscal: `${this.selectedPayslipYear}`,
    };

    this.employeeEarningsList.push(newEarning);
    this.earningsFormGroup.addControl(
      newEarning.component,
      new FormControl(newEarning.amount, [
        Validators.required,
        Validators.min(1),
      ])
    );

    this.earningsFormGroup.markAsDirty();
    this.emitFormStatus();

    this.dialog.closeAll();
  }

  deleteEarning(earningId: number | null, optionValue: string): void {
    if (!earningId) {
      this.employeeEarningsList = this.employeeEarningsList.filter(
        (earning) => earning.component !== optionValue
      );

      this.earningsFormGroup.removeControl(optionValue);
      this.emitFormStatus();
      return;
    }

    this.payrollService.deleteEmployeeMonthlyEarningsApi(earningId).subscribe(
      () => {
        this.employeeEarningsList = this.employeeEarningsList.filter(
          (earning) => earning.monthlyEarningsId !== earningId
        );
        this.earningsFormGroup.removeControl(optionValue);
        this.emitFormStatus();
      },
      (error) => console.error("Error deleting earning:", error)
    );
  }
}
