import * as i0 from "@angular/core";
import * as i1 from "../../account/login.service";
import * as i2 from "ngx-permissions";
var AuthorizationGuard = /** @class */ (function () {
    function AuthorizationGuard(loginService, ngxPermissionsService) {
        this.loginService = loginService;
        this.ngxPermissionsService = ngxPermissionsService;
    }
    AuthorizationGuard.prototype.canActivate = function (next, state) {
        if (this.loginService.userGroups) {
            this.ngxPermissionsService.loadPermissions(this.loginService.userGroups);
        }
        return true;
    };
    AuthorizationGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizationGuard_Factory() { return new AuthorizationGuard(i0.ɵɵinject(i1.LoginService), i0.ɵɵinject(i2.NgxPermissionsService)); }, token: AuthorizationGuard, providedIn: "root" });
    return AuthorizationGuard;
}());
export { AuthorizationGuard };
