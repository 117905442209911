export type EarningsOrDeductionArray = { label: string; amount: number }[];
export type Header = [string, string];
export type PayslipTableData = {
  earningsComponent: string;
  earningsComponentAmount: string;
  deductionsComponent: string;
  deductionsComponentAmount: string;
};
export type UserDetails = { label: string; objKey: string };

export interface Payslip {
  label: string;
  amount: number;
}
export interface Payslip {
  monthlyPayslipId: number;
  payslipYear: number;
  employeeId: string;
  payslipComponent: string;
  componentAmount: number;
  payslipMonth: number;
  fiscal: string;
}

export interface EmployeePayrollDetails {
  earnings: Payslip[];
  deductions: Payslip[];
  payslipSummary: Payslip[];
}
export const USER_DETAILS: UserDetails[] = [
  {
    label: "Name",
    objKey: "name",
  },
  {
    label: "Employee Code",
    objKey: "employeeCode",
  },
  {
    label:"Employee ID",
    objKey:"employeeId"
  },
  {
    label: "Joining Date",
    objKey: "dateOfJoining",
  },
  {
    label: "Bank Name",
    objKey: "bankAcctName",
  },
  {
    label: "Aadhar",
    objKey: "aadhaar",
  },
  {
    label: "PAN Number",
    objKey: "pan",
  },
  {
    label: "Bank Account No",
    objKey: "bankAcctNo",
  },
  {
    label: "PF No",
    objKey: "pfNo",
  },
  {
    label: "PF UAN",
    objKey: "uanNo",
  },
];
export const USER_KEY = "user_details";
export const NAME_KEY = "name";
export const SNACKBAR_LABEL = "Close";
export const PAYSLIP_SUMMARY = "payslipSummary";
export const EARNINGS = "earnings";
export const DEDUCTIONS = "deductions";
export const TOTAL_DEDUCTIONS_STRING = "TOTAL_DEDUCTIONS";
export const TOTAL_INCOME_STRING = "TOTAL_INCOME";
export const PAYSLIP_COMPONENT = "payslipComponent";
export const PAYSLIP_COMPONENT_AMOUNT = "componentAmount";
export const HEADERS: Header[] = [
  ["earningsComponent", "Earnings"],
  ["earningsComponentAmount", "Amount"],
  ["deductionsComponent", "Deductions"],
  ["deductionsComponentAmount", "Amount"],
];

export const MONTH: { [month: number]: string } = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};
