import { ElementRef } from '@angular/core';
var NumberOnlyDirective = /** @class */ (function () {
    function NumberOnlyDirective(el) {
        this.el = el;
    }
    NumberOnlyDirective.prototype.onInputChange = function (event) {
        var initalValue = this.el.nativeElement.value;
        this.el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
        if (initalValue !== this.el.nativeElement.value) {
            event.stopPropagation();
        }
    };
    NumberOnlyDirective.prototype.onKeyDown = function (event) {
        if (event.key && !/^[0-9]$/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab' && event.key !== 'Enter' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
            event.preventDefault();
        }
    };
    return NumberOnlyDirective;
}());
export { NumberOnlyDirective };
