import { MatPaginatorIntl } from "@angular/material";

const dutchRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) {
    return `0 of ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  const endIndex =
    startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

  return `Showing ${startIndex + 1} to ${endIndex} of ${length} entries`;
};

export function getDutchPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = "Items per page";
  paginatorIntl.nextPageLabel = "Next";
  paginatorIntl.previousPageLabel = "Previous";
  paginatorIntl.getRangeLabel = dutchRangeLabel;

  return paginatorIntl;
}
