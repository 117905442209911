<div class="d-flex my-3 justify-content-end">
  <div *ngIf="userPayroll">
    <select
      class="select-month text-start imp-sub-text custom-select-spacing"
      [(ngModel)]="selectedPayslipMonth"
      (change)="onMonthChange($event)"
    >
      <ng-container *ngFor="let month of months | keyValueOriginalOrder">
        <ng-container *ngIf="showMonth(month.key)">
          <option [ngValue]="+month.key">
            {{ month.value | abbreviateMonth }}
          </option>
        </ng-container>
        <ng-container *ngIf="showYear()">
          <option [ngValue]="+month.key">
            {{ month.value | abbreviateMonth }}
          </option>
        </ng-container>
      </ng-container>
    </select>
    <select
      class="select-year text-start imp-sub-text"
      [(ngModel)]="selectedPayslipYear"
      (change)="onYearChange()"
    >
      <option *ngFor="let year of years" [ngValue]="year">
        {{ year }}
      </option>
    </select>
  </div>
  <button
    class="gem-button-secondary d-flex align-items-center px-3 mr-4"
    (click)="downloadPayslip.download_pdf()"
    [disabled]="!payslipExistForSelectedYearOrSelectedMonth"
  >
    <i class="ph ph-download-simple"></i>
  </button>
</div>
<div style="position: fixed">
  <app-payslip-pdf
    #downloadPayslip
    [userDetails]="userDetails"
    [selectedEmployeeDetails]="selectedEmployeeDetails"
    [fileName]="fileName"
    [employeePayrollDetailsForPdf]="
      selectedTabIndex === 0
        ? employeePayrollDetailsForPdf
        : employeeCtcPayrollDetailsForPdf
    "
    [selectedPayslipYear]="selectedPayslipYear"
    [selectedPayslipMonth]="months[selectedPayslipMonth]"
    [netPay]="netPay"
    [selectedTabIndex]="selectedTabIndex"
  ></app-payslip-pdf>
</div>

<mat-tab-group class="my-3" (selectedTabChange)="onTabChange($event)">
  <mat-tab label="Monthly Payslip">
    <div>
      <div
        class="row flex-container pt-5"
        *ngIf="payslipExistForSelectedYearOrSelectedMonth"
      >
        <div class="col-md-7 pl-0 earnings-deductions-container">
          <div class="row">
            <div *ngIf="employeeMonthlyPayrollDetails" class="col-md-6">
              <app-income-card
                [income]="getMonthlyEarningsOrDeductionsArray('earnings')"
                [total]="getTotalMonthlyEarningsOrDeductions('earnings')"
                [amountType]="'Earnings'"
              ></app-income-card>
            </div>

            <div *ngIf="employeeMonthlyPayrollDetails" class="col-md-6">
              <app-income-card
                [income]="getMonthlyEarningsOrDeductionsArray('deductions')"
                [total]="getTotalMonthlyEarningsOrDeductions('deductions')"
                [amountType]="'Deductions'"
              ></app-income-card>
            </div>
          </div>
        </div>

        <div class="col-md-5 mb-4 employee-details-container">
          <app-employee-payslip-details
            [userDetails]="userDetails"
            [selectedEmployeeDetails]="selectedEmployeeDetails"
          ></app-employee-payslip-details>

          <div class="income-box gem-card-surface mt-4 p-4">
            <div class="pb-1 text gem-text-medium">
              Net Pay for {{ months[selectedPayslipMonth] }}
              {{ selectedPayslipYear }}
            </div>
            <div class="ruppee-text gem-text-secondary pb-1">
              &#8377;{{ netPay | currency : "INR" : "" }}
            </div>
            <div class="text gem-text-medium">
              {{ netPay | numberToWords | capitalizeFirst }} only
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <!-- CTC Payslip Tab -->
  <mat-tab label="CTC Payslip">
    <div
      class="flex-container pt-5"
      *ngIf="payslipExistForSelectedYearOrSelectedMonth"
    >
      <div
        *ngIf="payslipExistForSelectedYearOrSelectedMonth"
        class="col-lg-8 pl-0 earnings-container"
      >
        <div class="">
          <div class="" *ngIf="employeeCtcPayrollDetails">
            <app-income-card
              [income]="getEarningsOrDeductionsArrayForCTC('earnings')"
              [total]="getTotalCTCDetails('earnings')"
              [amountType]="'Earnings'"
            ></app-income-card>
          </div>
        </div>
      </div>

      <div class="col-lg-4 mb-4 earnings-container">
        <app-employee-payslip-details
          [userDetails]="userDetails"
          [selectedEmployeeDetails]="selectedEmployeeDetails"
        ></app-employee-payslip-details>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
