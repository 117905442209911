<div class="investment-declaration-preview-wrapper">
  <div class="row">
    <div class="tax-regime-card-wrapper" *ngFor="let regime of taxRegimes">
      <div
        class="col-auto tax-regime-card"
        [ngClass]="{ selected: isSelectedRegime(regime.id) }"
        (click)="onRegimeCardClick(regime.id)"
      >
        <div class="title-wrapper d-flex">
          <div class="title-text">
            {{ regime.label }}
          </div>
          <div><i [matTooltip]="'Tooltip'" class="icon-info-sign"></i></div>
        </div>
        <div class="values-wrapper">
          <div class="value-wrapper">
            <div class="value-label">Taxable Income</div>
            <i class="icon-inr inr"></i>
            <div
              class="value"
              [matTooltip]="regime.taxableIncome"
              matTooltipPosition="above"
            >
              {{ regime.taxableIncome }}
            </div>
          </div>
          <div class="value-wrapper">
            <div class="value-label">Net Tax</div>
            <i class="icon-inr inr"></i>
            <div
              class="value"
              [matTooltip]="regime.netTax"
              matTooltipPosition="above"
            >
              {{ regime.netTax }}
            </div>
          </div>
        </div>
        <div class="recommended-label" *ngIf="isRecommendedRegime(regime.id)">
          Recommended
        </div>
      </div>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="title">Tax Comparison</div>
    <table mat-table [dataSource]="taxComparisonData">
      <ng-container
        [matColumnDef]="col.id"
        *ngFor="let col of displayedColumns"
      >
        <th mat-header-cell *matHeaderCellDef>{{ col.label }}</th>
        <td mat-cell *matCellDef="let element">{{ element[col.id] }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
      <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()"></tr>
    </table>
  </div>
</div>
