import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import * as moment from "moment";
import { PayrollDialogComponent } from "src/app/payroll-dialog/payroll-dialog.component";
var EditEmployeePersonalDetailsComponent = /** @class */ (function () {
    function EditEmployeePersonalDetailsComponent(snackBarService, fb, employeeService, dialog, spinnerService) {
        this.snackBarService = snackBarService;
        this.fb = fb;
        this.employeeService = employeeService;
        this.dialog = dialog;
        this.spinnerService = spinnerService;
        this.close = new EventEmitter();
        this.closeEditScreen = new EventEmitter();
        this.showSpinner = true;
        this.basicFields = [
            {
                label: "Full Name",
                controlName: "fullName",
                directive: "stringsOnly",
                required: true,
            },
            {
                label: "Employee Code",
                controlName: "employeeCode",
                directive: null,
                required: true,
            },
            {
                label: "Email Address",
                controlName: "employeeId",
                directive: null,
                required: true,
            },
            {
                label: "Aadhaar Card Number",
                controlName: "aadhaar",
                directive: "numbersOnly",
                required: true,
            },
            {
                label: "Permanent Account Number",
                controlName: "pan",
                directive: null,
                required: true,
            },
            {
                label: "UAN Number",
                controlName: "uanNo",
                directive: "numbersOnly",
                required: true,
            },
            {
                label: "PF Number",
                controlName: "pfNo",
                directive: null,
                required: true,
            },
            {
                label: "Permanent Address",
                controlName: "permanantAddress",
                required: true,
            },
            {
                label: "Correspondence Address",
                controlName: "correspondenceAddress",
                required: false,
            },
            {
                label: "Bank Account No",
                controlName: "bankAcctNo",
                directive: "numbersOnly",
                required: true,
            },
            {
                label: "Bank Name",
                controlName: "bankAcctName",
                directive: "stringsOnly",
                required: true,
            },
            {
                label: "Bank Account Address",
                controlName: "bankAcctAddress",
                directive: null,
                required: true,
            },
            {
                label: "Bank IFSC",
                controlName: "bankIFSC",
                directive: null,
                required: true,
            },
        ];
    }
    EditEmployeePersonalDetailsComponent.prototype.ngOnInit = function () {
        this.editEmployeePersonalDetails = this.fb.group({
            employeeId: [this.selectedEmployeeDetails.employeeId],
            employeeCode: [this.selectedEmployeeDetails.employeeCode],
            fullName: [
                "" + (this.selectedEmployeeDetails.firstName +
                    " " +
                    this.selectedEmployeeDetails.lastName),
                [Validators.required],
            ],
            pan: [
                this.selectedEmployeeDetails.pan,
                [Validators.required],
            ],
            aadhaar: [
                this.selectedEmployeeDetails.aadhaar,
                [Validators.required, Validators.pattern("^[0-9]{12}$")],
            ],
            dateOfBirth: [
                this.parseDate(this.selectedEmployeeDetails.dateOfBirth),
                [Validators.required],
            ],
            dateOfJoining: [
                this.parseDate(this.selectedEmployeeDetails.dateOfJoining),
                [Validators.required],
            ],
            dateOfExit: [
                this.selectedEmployeeDetails.dateOfExit
                    ? this.parseDate(this.selectedEmployeeDetails.dateOfExit)
                    : null,
            ],
            permanantAddress: [
                this.selectedEmployeeDetails.permanentAddress,
                [Validators.required],
            ],
            correspondenceAddress: [
                this.selectedEmployeeDetails.correspondenceAddress,
            ],
            uanNo: [
                this.selectedEmployeeDetails.uanNo,
                [Validators.required],
            ],
            pfNo: [this.selectedEmployeeDetails.pfNo, [Validators.required]],
            bankAcctNo: [
                this.selectedEmployeeDetails.bankAcctNo,
                [Validators.required],
            ],
            bankAcctName: [
                this.selectedEmployeeDetails.bankAcctName,
                [Validators.required],
            ],
            bankAcctAddress: [
                this.selectedEmployeeDetails.bankAcctAddress,
                [Validators.required],
            ],
            bankIFSC: [this.selectedEmployeeDetails.bankIFSC, [Validators.required]],
            cityType: [this.selectedEmployeeDetails.cityType, [Validators.required]],
        });
        this.changeAppContentWrapperStyle();
    };
    EditEmployeePersonalDetailsComponent.prototype.changeAppContentWrapperStyle = function () {
        var appContentWrapperDiv = document.querySelector("#appContentWrapper");
        appContentWrapperDiv.style.paddingTop = "0";
        appContentWrapperDiv.style.height = "100%";
        appContentWrapperDiv.style.backgroundColor = "white";
    };
    EditEmployeePersonalDetailsComponent.prototype.resetAppContentWrapperStyle = function () {
        var appContentWrapperDiv = document.querySelector("#appContentWrapper");
        appContentWrapperDiv.style.paddingTop = "24px";
        appContentWrapperDiv.style.height = "auto";
        appContentWrapperDiv.style.backgroundColor = "";
    };
    EditEmployeePersonalDetailsComponent.prototype.onClose = function () {
        this.close.emit();
    };
    EditEmployeePersonalDetailsComponent.prototype.openConfirmationDialog = function () {
        var _this = this;
        this.dialog.open(PayrollDialogComponent, {
            data: {
                title: "Confirmation",
                msg: "Do you want to Edit CTC Details ?",
                dialogType: "post",
                onYesClickFunction: function (param) { return _this.updateEmployeeDetails(); },
            },
        });
    };
    EditEmployeePersonalDetailsComponent.prototype.parseDate = function (dateString) {
        if (!dateString)
            return null; // Handle null or empty date strings
        var _a = tslib_1.__read(dateString.split("/").map(Number), 3), day = _a[0], month = _a[1], year = _a[2]; // Split the string by '/'
        return new Date(year, month - 1, day); // Create the date object (months are zero-indexed)
    };
    EditEmployeePersonalDetailsComponent.prototype.getFirstAndLastName = function () {
        var fullName = this.editEmployeePersonalDetails.get("fullName").value;
        fullName = fullName.trim().replace(/\s+/g, " ");
        var words = fullName.split(" ");
        this.firstName = words[0];
        if (words.length === 1) {
            this.lastName = ".";
        }
        else {
            this.lastName = words.slice(1).join(" ");
        }
    };
    EditEmployeePersonalDetailsComponent.prototype.updateEmployeeDetails = function () {
        var _this = this;
        this.getFirstAndLastName();
        this.spinnerService.openSpinnerDialog();
        var element = {
            cityType: "M",
            firstName: this.firstName,
            lastName: this.lastName,
            employeeEmail: this.editEmployeePersonalDetails.get("employeeId").value,
            employeeCode: this.editEmployeePersonalDetails.get("employeeCode").value,
            employeeId: this.editEmployeePersonalDetails.get("employeeId").value,
            dateOfBirth: moment(this.editEmployeePersonalDetails.get("dateOfBirth").value).format("DD/MM/yyyy"),
            dateOfJoining: moment(this.editEmployeePersonalDetails.get("dateOfJoining").value).format("DD/MM/yyyy"),
            dateOfExit: this.editEmployeePersonalDetails.get("dateOfExit").value
                ? moment(this.editEmployeePersonalDetails.get("dateOfExit").value).format("DD/MM/yyyy")
                : null,
            bankAcctNo: this.editEmployeePersonalDetails.get("bankAcctNo").value,
            bankAcctAddress: this.editEmployeePersonalDetails.get("bankAcctAddress").value,
            bankAcctName: this.editEmployeePersonalDetails.get("bankAcctName").value,
            bankIFSC: this.editEmployeePersonalDetails.get("bankIFSC").value,
            aadhaar: this.editEmployeePersonalDetails.get("aadhaar").value,
            permanantAddress: this.editEmployeePersonalDetails.get("permanantAddress").value,
            correspondenceAddress: this.editEmployeePersonalDetails.get("correspondenceAddress").value,
            uanNo: this.editEmployeePersonalDetails.get("uanNo").value,
            pfNo: this.editEmployeePersonalDetails.get("pfNo").value,
            pan: this.editEmployeePersonalDetails.get("pan").value,
        };
        this.employeeService.updateEmployeeDetails(element).subscribe(function (res) {
            _this.spinnerService.closeSpinnerDialog();
            _this.snackBarService.add({
                message: "Employee Personal Details Updated Successfully.",
                action: "close",
                config: { duration: 2000 },
            });
            _this.onClose();
        }, function (error) {
            _this.spinnerService.closeSpinnerDialog();
            if (error.status === 500) {
                _this.snackBarService.add({
                    message: "Some issue occurred Please contact the backend team to resolve this.",
                    action: "close",
                    config: { duration: 2000 },
                });
            }
            else {
                _this.snackBarService.add({
                    message: error.error.message,
                    action: "close",
                    config: { duration: 2000 },
                });
            }
        });
        // }
    };
    EditEmployeePersonalDetailsComponent.prototype.isFieldInvalid = function (controlName) {
        var control = this.editEmployeePersonalDetails.get(controlName);
        return control.invalid && (control.dirty || control.touched);
    };
    EditEmployeePersonalDetailsComponent.prototype.onCloseEditScreen = function () {
        this.closeEditScreen.emit();
    };
    EditEmployeePersonalDetailsComponent.prototype.ngOnDestroy = function () {
        this.resetAppContentWrapperStyle();
    };
    return EditEmployeePersonalDetailsComponent;
}());
export { EditEmployeePersonalDetailsComponent };
