import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class PayrollService {
  private path = "";
  constructor(private baseService: BaseService) { }

  getPayrollDetailsApi(
    empId: string,
    month: number,
    year: number
  ): Observable<any> {
    this.path = `payslip/monthlyPayslip?employeeId=${empId}&payslipYear=${year}&payslipMonth=${month}`;
    return this.baseService.get(this.path);
  }

  calculateMonthlyPayrollApi(year: number, month: number): Observable<any> {
    this.path = `payslip/batchInsertPayslip?payslipYear=${year}&payslipMonth=${month}`;
    return this.baseService.post(this.path);
  }

  getAllPayrollDetailsApi(year, month): Observable<any> {
    this.path = `payslip/${year}/${month}`;
    return this.baseService.get(this.path);
  }

  getPayrollExcelApi(year, month): Observable<any> {
    this.path = `payrollExport/${year}/${month}`;
    return this.baseService.getExcel(this.path);
  }

  getPayrollExcelSalarySheetApi(year, month): Observable<any> {
    this.path = `payrollExport/payrollExcelSalarySheet/${year}/${month}`;
    return this.baseService.getExcel(this.path);
  }

  getEmployeeMonthlyEarningsApi(month,year,employeeId): Observable<any> {
    this.path = `empMonthlyEarnings?month=${month}&year=${year}&employeeId=${employeeId}`
    return this.baseService.get(this.path);
  }

  getEmployeeEarningsKeysApi():Observable<any> {
    this.path = `options/EMPLOYEE_MONTHLY_EARNINGS`
    return this.baseService.get(this.path);
  }

  getEmployeeDeductionsKeysApi():Observable<any> {
    this.path = `options/EMPLOYEE_MONTHLY_DEDUCTIONS`
    return this.baseService.get(this.path);
  }

  getEmployeeMonthlyDeductionsApi(month,year,employeeId):Observable<any>{
    this.path = `monthlyDeductions?month=${month}&year=${year}&employeeId=${employeeId}`
    return this.baseService.get(this.path);
  }

  postEmployeeMonthlyEarningsApi(data: any) {
    this.path = "empMonthlyEarnings";
    return this.baseService.post(this.path, data);
  }

  postEmployeeMonthlyDeductionsApi(data: any) {
    this.path = "monthlyDeductions";
    return this.baseService.post(this.path, data);
  }

  postEmployeeMonthlyLeaves(body): Observable<any> {
    this.path = "employeeMonthlyLeaves";
    return this.baseService.post(this.path, body);
  }

  getEmployeeMonthlyLeaves(
    empId: string,
    leaveYear: number,
    leaveMonth: number
  ): Observable<any> {
    this.path = `employeeMonthlyLeaves?employeeId=${empId}&leaveYear=${leaveYear}&leaveMonth=${leaveMonth}`;
    return this.baseService.get(this.path);
  }

  postEmployeeMonthlyPayroll(employeeId,payslipYear,payslipMonth): Observable <any> {
    this.path = `payslip?employeeId=${employeeId}&payslipYear=${payslipYear}&payslipMonth=${payslipMonth}`
    return this.baseService.post(this.path);
  }
}
