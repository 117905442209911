import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[numbersOnly]'
})
export class NumberOnlyDirective {
	constructor(private el: ElementRef) {}

	@HostListener('input', ['$event']) onInputChange(event: any) {
		const initalValue = this.el.nativeElement.value;
		this.el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
		if (initalValue !== this.el.nativeElement.value) {
			event.stopPropagation();
		}
	}

	 @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (event.key && !/^[0-9]$/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab' && event.key !== 'Enter' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
      event.preventDefault();
    }
  }
}
