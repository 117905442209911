import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { Observable } from "rxjs";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  private path;
  private param: HttpParams;

  constructor(private baseService: BaseService) {}

  getRandomQuoteApi(): Observable<any> {
    this.path = "quote/randomQuote";
    return this.baseService.get(this.path);
  }

  getEmployeePayslipApi(
    empId: any,
    year:number,
    month: number
  ): Observable<any> {
    return this.baseService.get(`payslip?PayslipYear=${year}&PayslipMonth=${month}&employeeId=${empId}`);
  }
}
