import jspdf from "jspdf";
import html2canvas from "html2canvas";
import { MONTHLY_PAYSLIP_HEADERS, CTC_PAYSLIP_HEADERS, } from "../payslip.utility";
var PayslipPdfComponent = /** @class */ (function () {
    function PayslipPdfComponent(snackBarService, payrollService) {
        this.snackBarService = snackBarService;
        this.payrollService = payrollService;
        this.headers_monthly = MONTHLY_PAYSLIP_HEADERS;
        this.headers_ctc = CTC_PAYSLIP_HEADERS;
    }
    PayslipPdfComponent.prototype.download_pdf = function () {
        var _this = this;
        var snackbarRef = this.snackBarService.open("Cancel", 0);
        snackbarRef.progress = 1;
        snackbarRef.runProgressBar();
        var data = document.getElementById(this.selectedTabIndex === 0 ? "userPayslipPDF" : "userCtcPayslipPDF");
        if (!data) {
            snackbarRef.progressText = "Error: Unable to generate payslip";
            setTimeout(function () { return snackbarRef.closeSnackbar(); }, 2000);
            return;
        }
        data.style.display = "inline";
        var pdf = new jspdf("p", "mm", "a4");
        html2canvas(data)
            .then(function (canvas) {
            var imgWidth = 196;
            var imgHeight = (canvas.height * imgWidth) / canvas.width;
            var contentDataURL = canvas.toDataURL("image/png");
            var position = 0;
            pdf.addImage(contentDataURL, "PNG", 7, position, imgWidth, 297);
            pdf.output("blob");
            data.style.display = "none";
            pdf.save(_this.fileName);
            snackbarRef.progress = 100;
            snackbarRef.runProgressBar();
            setTimeout(function () { return snackbarRef.closeSnackbar(); }, 2000);
        })
            .catch(function () {
            snackbarRef.progressText = "Error occurred in downloading payslip";
            setTimeout(function () { return snackbarRef.closeSnackbar(); }, 2000);
        });
    };
    PayslipPdfComponent.prototype.getPairsForPdf = function (arr) {
        var pairs = [];
        if (arr) {
            for (var i = 0; i < arr.length; i += 2) {
                pairs.push(arr.slice(i, i + 2));
            }
        }
        return pairs;
    };
    PayslipPdfComponent.prototype.trackByUserDetail = function (detail) {
        return detail.objKey;
    };
    PayslipPdfComponent.prototype.trackByHeader = function (header) {
        return header[0];
    };
    PayslipPdfComponent.prototype.trackByHeader_ctc = function (header_ctc) {
        return header_ctc[0];
    };
    PayslipPdfComponent.prototype.trackByPayrollDetail = function (index) {
        return index;
    };
    return PayslipPdfComponent;
}());
export { PayslipPdfComponent };
