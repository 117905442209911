import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, } from "@angular/core";
import { addHouse } from "../../investment-declaration.util";
import { PayrollDialogComponent } from "src/app/payroll-dialog/payroll-dialog.component";
var AddInvestmentHRAFormComponent = /** @class */ (function () {
    function AddInvestmentHRAFormComponent(formBuilder, dialog) {
        this.formBuilder = formBuilder;
        this.dialog = dialog;
        this.keyUpEmitter = new EventEmitter();
        this.updateSectionTotalAmountEmitter = new EventEmitter();
        this.onRemoveHouseClickEmitter = new EventEmitter();
        this.deletedHousesCount = 0;
        this.houseMonthlyRentLimitMap = new Map();
        this.houseInputsValidationMap = new Map();
    }
    AddInvestmentHRAFormComponent.prototype.ngOnInit = function () {
        this.toMonthOptions = this.fiscalMonths;
        if (!this.isFormSubmitted) {
            this.initSetup();
        }
        else {
            this.disableInputs();
        }
    };
    AddInvestmentHRAFormComponent.prototype.initSetup = function () {
        this.initializeHouseMonthlyRentLimitMap();
        this.initializeHouseInputsValidationMap();
        this.disableHouseInputsBasedOnValidations();
    };
    AddInvestmentHRAFormComponent.prototype.disableInputs = function () {
        var _this = this;
        this.houses.forEach(function (el, i) {
            var fromMonthControl = _this.hraFormGroup.get("House " + (i + 1)).get("fromMonth");
            var toMonthControl = _this.hraFormGroup.get("House " + (i + 1)).get("toMonth");
            var monthlyRentControl = _this.hraFormGroup.get("House " + (i + 1)).get("monthlyRent");
            fromMonthControl.disable();
            toMonthControl.disable();
            monthlyRentControl.disable;
        });
    };
    AddInvestmentHRAFormComponent.prototype.disableHouseInputsBasedOnValidations = function () {
        var _this = this;
        this.houses.forEach(function (el, i) {
            var fromMonthControl = _this.hraFormGroup.get("House " + (i + 1)).get("fromMonth");
            var toMonthControl = _this.hraFormGroup.get("House " + (i + 1)).get("toMonth");
            var monthlyRentControl = _this.hraFormGroup.get("House " + (i + 1)).get("monthlyRent");
            if (fromMonthControl.value === null) {
                toMonthControl.disable();
                monthlyRentControl.disable();
            }
            else if (toMonthControl.value === null) {
                monthlyRentControl.disable();
            }
        });
    };
    AddInvestmentHRAFormComponent.prototype.updateSectionTotalAmount = function (houseNumber) {
        var monthlyRentControl = this.hraFormGroup
            .get("House " + (houseNumber + 1))
            .get("monthlyRent");
        if (monthlyRentControl.value !== null) {
            this.updateSectionTotalAmountEmitter.emit();
        }
    };
    AddInvestmentHRAFormComponent.prototype.onFromMonthValueChange = function (event, houseNumber) {
        this.updateSectionTotalAmount(houseNumber);
        this.toMonthOptions = this.fiscalMonths.slice(this.fiscalMonths.indexOf(event.value));
        var toMonthControl = this.hraFormGroup
            .get("House " + (houseNumber + 1))
            .get("toMonth");
        if (toMonthControl.disabled) {
            toMonthControl.enable();
            this.houseInputsValidationMap.get(houseNumber + 1).set("toMonth", true);
        }
        else {
            if (toMonthControl.value !== null) {
                if (this.fiscalMonths.indexOf(event.value) >
                    this.fiscalMonths.indexOf(toMonthControl.value)) {
                    toMonthControl.setValue(null);
                }
            }
        }
    };
    AddInvestmentHRAFormComponent.prototype.onToMonthValueChange = function (houseNumber) {
        this.updateSectionTotalAmount(houseNumber);
        var monthlyRentControl = this.hraFormGroup
            .get("House " + (houseNumber + 1))
            .get("monthlyRent");
        if (monthlyRentControl.disabled) {
            monthlyRentControl.enable();
            this.houseInputsValidationMap
                .get(houseNumber + 1)
                .set("monthlyRent", true);
        }
    };
    AddInvestmentHRAFormComponent.prototype.initializeHouseInputsValidationMap = function () {
        var _this = this;
        this.houses.forEach(function (el, i) {
            var fromMonthControl = _this.hraFormGroup
                .get("House " + (i + 1))
                .get("fromMonth");
            var toMonthControl = _this.hraFormGroup
                .get("House " + (i + 1))
                .get("toMonth");
            _this.houseInputsValidationMap.set(i + 1, new Map([
                ["toMonth", fromMonthControl.value !== null],
                ["monthlyRent", toMonthControl.value !== null],
            ]));
        });
    };
    AddInvestmentHRAFormComponent.prototype.initializeHouseMonthlyRentLimitMap = function () {
        var _this = this;
        this.houses.forEach(function (el, i) {
            _this.houseMonthlyRentLimitMap.set(i + 1, false);
        });
    };
    AddInvestmentHRAFormComponent.prototype.getTabLabel = function (index) {
        return "House " + (index + 1);
    };
    AddInvestmentHRAFormComponent.prototype.onAddHouseClick = function () {
        addHouse(this.hraFormGroup, this.houses, this.formBuilder);
        var newHouse = this.hraFormGroup.get("House " + this.houses.length);
        newHouse.get("toMonth").disable();
        newHouse.get("monthlyRent").disable();
        this.houseInputsValidationMap.set(this.houses.length, new Map([
            ["toMonth", false],
            ["monthlyRent", false],
        ]));
    };
    AddInvestmentHRAFormComponent.prototype.isConvertibleToNumber = function (value) {
        if (value.trim() === "") {
            return false;
        }
        return !isNaN(Number(value));
    };
    AddInvestmentHRAFormComponent.prototype.onKeyUp = function (event, houseNumber) {
        if (event.key === "Backspace" || this.isConvertibleToNumber(event.key)) {
            this.updateIsInputDigitLimitReached(houseNumber + 1);
            this.keyUpEmitter.emit(event);
        }
    };
    /**
     * @param houseNumber should be 1 based index
     */
    AddInvestmentHRAFormComponent.prototype.updateIsInputDigitLimitReached = function (houseNumber) {
        if (this.hraFormGroup.get("House " + houseNumber).get("monthlyRent").value.length === 8) {
            this.houseMonthlyRentLimitMap.set(houseNumber, true);
        }
        else {
            this.houseMonthlyRentLimitMap.set(houseNumber, false);
        }
    };
    AddInvestmentHRAFormComponent.prototype.onRemoveHouseClick = function (index) {
        var _a;
        var _this = this;
        this.dialog.open(PayrollDialogComponent, (_a = {},
            _a["data"] = {
                title: "Confirmation",
                msg: "Are you sure you want to delete info for House " + (index + 1),
                dialogType: "delete",
                onYesClickFunction: function () { return _this.removeHouse(index); },
                noButtonText: "No",
                yesButtonText: "Yes",
            },
            _a));
    };
    AddInvestmentHRAFormComponent.prototype.removeHouse = function (index) {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d, e_5, _e, e_6, _f;
        var _this = this;
        var keyToRemove = "House " + (index + 1);
        this.hraFormGroup.removeControl(keyToRemove);
        // Renumber the remaining controls
        var controls = this.hraFormGroup.controls;
        var newControls = {};
        var houseCount = 1;
        for (var key in controls) {
            if (controls.hasOwnProperty(key)) {
                newControls["House " + houseCount] = controls[key];
                houseCount++;
            }
        }
        Object.keys(controls).forEach(function (key) {
            return _this.hraFormGroup.removeControl(key);
        });
        Object.keys(newControls).forEach(function (key) {
            return _this.hraFormGroup.addControl(key, newControls[key]);
        });
        // this event should be emitted  after the hraFormGroup has been updated
        this.onRemoveHouseClickEmitter.emit(index);
        // Renumbering houseInputsValidationMap
        houseCount = 1;
        var keysToDelete = [];
        var entriesToAdd = [];
        this.houseInputsValidationMap.delete(index + 1);
        try {
            for (var _g = tslib_1.__values(this.houseInputsValidationMap.entries()), _h = _g.next(); !_h.done; _h = _g.next()) {
                var _j = tslib_1.__read(_h.value, 2), key = _j[0], value = _j[1];
                if (key !== houseCount) {
                    entriesToAdd.push([houseCount, value]);
                    keysToDelete.push(key);
                }
                houseCount++;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_h && !_h.done && (_a = _g.return)) _a.call(_g);
            }
            finally { if (e_1) throw e_1.error; }
        }
        try {
            for (var keysToDelete_1 = tslib_1.__values(keysToDelete), keysToDelete_1_1 = keysToDelete_1.next(); !keysToDelete_1_1.done; keysToDelete_1_1 = keysToDelete_1.next()) {
                var key = keysToDelete_1_1.value;
                this.houseInputsValidationMap.delete(key);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (keysToDelete_1_1 && !keysToDelete_1_1.done && (_b = keysToDelete_1.return)) _b.call(keysToDelete_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        try {
            for (var entriesToAdd_1 = tslib_1.__values(entriesToAdd), entriesToAdd_1_1 = entriesToAdd_1.next(); !entriesToAdd_1_1.done; entriesToAdd_1_1 = entriesToAdd_1.next()) {
                var _k = tslib_1.__read(entriesToAdd_1_1.value, 2), newKey = _k[0], newValue = _k[1];
                this.houseInputsValidationMap.set(newKey, newValue);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (entriesToAdd_1_1 && !entriesToAdd_1_1.done && (_c = entriesToAdd_1.return)) _c.call(entriesToAdd_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        // Renumbering houseMonthlyRentLimitMap
        keysToDelete.length = 0;
        entriesToAdd.length = 0;
        houseCount = 1;
        this.houseMonthlyRentLimitMap.delete(index + 1);
        try {
            for (var _l = tslib_1.__values(this.houseMonthlyRentLimitMap.entries()), _m = _l.next(); !_m.done; _m = _l.next()) {
                var _o = tslib_1.__read(_m.value, 2), key = _o[0], value = _o[1];
                if (key !== houseCount) {
                    entriesToAdd.push([houseCount, value]);
                    keysToDelete.push(key);
                }
                houseCount++;
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_m && !_m.done && (_d = _l.return)) _d.call(_l);
            }
            finally { if (e_4) throw e_4.error; }
        }
        try {
            for (var keysToDelete_2 = tslib_1.__values(keysToDelete), keysToDelete_2_1 = keysToDelete_2.next(); !keysToDelete_2_1.done; keysToDelete_2_1 = keysToDelete_2.next()) {
                var key = keysToDelete_2_1.value;
                this.houseMonthlyRentLimitMap.delete(key);
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (keysToDelete_2_1 && !keysToDelete_2_1.done && (_e = keysToDelete_2.return)) _e.call(keysToDelete_2);
            }
            finally { if (e_5) throw e_5.error; }
        }
        try {
            for (var entriesToAdd_2 = tslib_1.__values(entriesToAdd), entriesToAdd_2_1 = entriesToAdd_2.next(); !entriesToAdd_2_1.done; entriesToAdd_2_1 = entriesToAdd_2.next()) {
                var _p = tslib_1.__read(entriesToAdd_2_1.value, 2), newKey = _p[0], newValue = _p[1];
                this.houseMonthlyRentLimitMap.set(newKey, newValue);
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (entriesToAdd_2_1 && !entriesToAdd_2_1.done && (_f = entriesToAdd_2.return)) _f.call(entriesToAdd_2);
            }
            finally { if (e_6) throw e_6.error; }
        }
    };
    return AddInvestmentHRAFormComponent;
}());
export { AddInvestmentHRAFormComponent };
