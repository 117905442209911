import { Component, Inject, OnInit } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material";

@Component({
  selector: "app-snackbar-with-progress",
  templateUrl: "./snackbar-with-progress.component.html",
  styleUrls: ["./snackbar-with-progress.component.scss"],
})
export class SnackbarWithProgressComponent implements OnInit {
  downloadCompleted: boolean = false;
  downloadStartedIcon: string = "../../assets/images/download-started.svg";
  downloadCompletedIcon: string = "../../assets/images/download-completed.svg";
  progress: number = 0;
  currentIntervalId: any;
  progressText: string = "Starting Download";
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<SnackbarWithProgressComponent>
  ) {}

  ngOnInit() {}

  closeSnackbar() {
    this.snackBarRef.dismiss();
  }

  runProgressBar() {
      if (this.progress > 0 && this.progress < 100)
        this.progressText = "Downloading";
      if (this.progress === 100) {
        if (this.data.action) 
            this.data.action = "Close";
        this.downloadCompleted = true;
        this.progressText = "Complete";
  }
}

  dismissWithAction() {
    this.snackBarRef.dismissWithAction();
  }
}
