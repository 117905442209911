import * as tslib_1 from "tslib";
import { environment } from "../../../environments/environment";
var PROD_GROUPS = [
    "Payroll_Admin_prod",
    "Payroll_Accounts_prod",
    "Payroll_HR_prod",
];
var NON_PROD_GROUPS = [
    "Payroll_Admin_np",
    "Payroll_Accounts_np",
    "Payroll_HR_np",
];
var PAGE_TO_GROUPS_MAPPING_DEV = {
    empDetails: [],
    payroll: [],
    allEmployeesYtdDetails: [],
    adminSettings: [],
    poiReview: [],
};
var PAGE_TO_GROUPS_MAPPING_BETA = {
    empDetails: tslib_1.__spread(NON_PROD_GROUPS),
    payroll: ["Payroll_Admin_np", "Payroll_Accounts_np"],
    allEmployeesYtdDetails: ["Payroll_Admin_np", "Payroll_Accounts_np"],
    adminSettings: ["Payroll_Admin_np", "Payroll_Accounts_np"],
    poiReview: ["Payroll_Admin_np", "Payroll_Accounts_np"],
};
var PAGE_TO_GROUPS_MAPPING_PROD = {
    empDetails: tslib_1.__spread(PROD_GROUPS),
    payroll: ["Payroll_Admin_prod", "Payroll_Accounts_prod"],
    allEmployeesYtdDetails: ["Payroll_Admin_prod", "Payroll_Accounts_prod"],
    adminSettings: ["Payroll_Admin_prod", "Payroll_Accounts_prod"],
    poiReview: ["Payroll_Admin_np", "Payroll_Accounts_np"],
};
var FEATURE_TO_GROUPS_MAPPING_DEV = {
    empDetails: {
        write: [],
        update: [],
        delete: [],
    },
};
var FEATURE_TO_GROUPS_MAPPING_BETA = {
    empDetails: {
        write: ["Payroll_Admin_np", "Payroll_Accounts_np"],
        update: ["Payroll_Admin_np", "Payroll_Accounts_np"],
        delete: ["Payroll_Admin_np", "Payroll_Accounts_np"],
    },
};
var FEATURE_TO_GROUPS_MAPPING_PROD = {
    empDetails: {
        write: ["Payroll_Admin_prod", "Payroll_Accounts_prod"],
        update: ["Payroll_Admin_prod", "Payroll_Accounts_prod"],
        delete: ["Payroll_Admin_prod", "Payroll_Accounts_prod"],
    },
};
var ENV_PAGE_PERMISSIONS = {
    dev: PAGE_TO_GROUPS_MAPPING_DEV,
    beta: PAGE_TO_GROUPS_MAPPING_BETA,
    prod: PAGE_TO_GROUPS_MAPPING_PROD,
};
var ENV_FEATURE_PERMISSIONS = {
    dev: FEATURE_TO_GROUPS_MAPPING_DEV,
    beta: FEATURE_TO_GROUPS_MAPPING_BETA,
    prod: FEATURE_TO_GROUPS_MAPPING_PROD,
};
export var FEATURE_TO_GROUPS_MAPPING = ENV_FEATURE_PERMISSIONS[environment.environment];
export var PAGE_TO_GROUPS_MAPPING = ENV_PAGE_PERMISSIONS[environment.environment];
