import { Injectable } from "@angular/core";
import { SpinnerComponent } from "src/app/spinner/spinner.component";
import { MatDialog, MatDialogRef } from "@angular/material";

@Injectable({
  providedIn: "root",
})
export class SpinnerService {
  constructor(private dialog: MatDialog) {}
  private dialogRef1: MatDialogRef<any>;

  openSpinnerDialog(): void {
    this.dialogRef1 = this.dialog.open(SpinnerComponent, {
      width: "100%",
      height: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
      panelClass: "my-spinner-class",
      backdropClass: "backdropBackground",
    });
  }

  closeSpinnerDialog(): void {
    this.dialogRef1.close();
  }
}
