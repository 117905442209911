<div class="login-wrapper">
  <div class="circle"></div>
  <div class="login-section d-flex flex-column align-items-center">
    <div>
      <img
        style="height: 64px; width: 335px; "
        src="../../assets/images/Gemini_New_Logo.svg"
        alt="Gemini Logo"
      />
    </div>
    <div class="heading">Welcome To Payroll</div>
    <div class="login-text">Please login to your account</div>
    <button class="login-btn py-1" mat-raised-button (click)="login()">
      Login via SSO
    </button>
  </div>
  <div style="padding: 47px 0 0 77px">
    <div class="">
      <img
        src="../../assets/images/login-page-image.svg"
        style="height: 74%; width: 40%; margin-top: 21px;"
        alt=""
      />
    </div>
  </div>
</div>
