import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { combineLatest, merge, of } from "rxjs";
import { EmployeeDetails } from "src/app/core/interfaces/user";
import { catchError, finalize, mergeMap } from "rxjs/operators";
import { PayrollDialogComponent } from "src/app/payroll-dialog/payroll-dialog.component";
import { PayrollDialogComponentInput } from "src/app/payroll-dialog/payroll-dialog.utility";
import { PayrollService } from "src/app/shared/services/payroll.service";
import { SnackBarService } from "src/app/shared/services/snackbar.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { ToggleChildComponentPayload } from "../../core/interfaces/user";
import { EmployeeEarningsComponent } from "./employee-earnings/employee-earnings.component";
import { EmployeeDeductionsComponent } from "./employee-deductions/employee-deductions.component";
@Component({
  selector: "app-earnings-deductions",
  templateUrl: "./earnings-deductions.component.html",
  styleUrls: ["./earnings-deductions.component.scss"],
})
export class EarningsDeductionsComponent implements OnInit, OnDestroy {
  @Input() element: any;
  // @Output() openEarningsAndDeductionsComponent = new EventEmitter<{
  //   openEarningsAndDeductions: boolean;
  //   isPayslipTriggered: boolean;
  // }>();
  @Output() openEarningsAndDeductionsComponent =
    new EventEmitter<ToggleChildComponentPayload>();
  @Input() selectedPayslipYear: number;
  @Input() selectedPayslipMonth: number;
  @Output() refreshTable = new EventEmitter<void>();
  @ViewChild(EmployeeEarningsComponent, { static: false })
  employeeEarningsComponent: EmployeeEarningsComponent;
  @ViewChild(EmployeeDeductionsComponent, { static: false })
  employeeDeductionsComponent: EmployeeDeductionsComponent;
  totalPayableAmount: number;
  allPayrollDetails: any;
  newItemsArray: any[] = [];
  sectionName: string = "Earnings";
  selectedIndex: any = 0;
  earningsFormStatus = {
    hasValues: false,
    formData: [],
    touched: false,
    empty: false,
    invalid: false,
  };
  deductionsFormStatus = {
    hasValues: false,
    formData: [],
    touched: false,
    empty: false,
    invalid: false,
  };
  earningsExecuted: boolean = false;
  deductionsExecuted: boolean = false;
  lwpExecuted: boolean = false;
  isPayslipTriggered: boolean = false;
  months: { [key: number]: string } = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  updateButtonClicked: boolean = false;

  constructor(
    private payrollService: PayrollService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    this.changeAppContentWrapperStyle();
  }

  onTabChange(event: any) {
    this.selectedIndex = event.index;
    if (this.selectedIndex === 1) this.sectionName = "Deductions";
    else this.sectionName = "Earnings";
  }

  onBackClick() {
    if (this.updateButtonClicked) {
      this.openTriggerPayslipConfirmationDialog();
      this.updateButtonClicked = false;
    } else {
      this.openEarningsAndDeductionsComponent.emit({
        openEarningsAndDeductions: false,
        isPayslipTriggered: this.isPayslipTriggered,
      });
    }
  }

  changeAppContentWrapperStyle() {
    const appContentWrapperDiv = document.querySelector(
      "#appContentWrapper"
    ) as HTMLElement;
    appContentWrapperDiv.style.paddingTop = "0";
    appContentWrapperDiv.style.backgroundColor = "white";
    appContentWrapperDiv.style.height = "calc(100% - 70px)";
  }

  resetAppContentWrapperStyle() {
    const appContentWrapperDiv = document.querySelector(
      "#appContentWrapper"
    ) as HTMLElement;
    appContentWrapperDiv.style.paddingTop = "24px";
    appContentWrapperDiv.style.backgroundColor = "";
    appContentWrapperDiv.style.height = "auto";
  }

  onFormStatusChange(status: any): void {
    this.earningsFormStatus = status;
  }

  onDeductionsFormStatusChange(status: any) {
    this.deductionsFormStatus = status;
  }

  ngOnDestroy() {
    this.resetAppContentWrapperStyle();
  }

  openTriggerPayslipConfirmationDialog() {
    this.dialog.open(PayrollDialogComponent, {
      ["data"]: {
        title: "Confirmation",
        msg: `Are you sure you want to go back without generating the payslip?`,
        dialogType: "post",
        onYesClickFunction: () =>
          this.triggerPayslip(
            this.element.email,
            this.selectedPayslipYear,
            this.selectedPayslipMonth
          ),
        onNoClickFunction: () => this.onBackClick(),
        noButtonText: "Yes",
        yesButtonText: "Generate Payslip",
      } as PayrollDialogComponentInput,
    });
  }

  triggerPayslip(email: string, payslipYear: number, payslipMonth: number) {
    const employeeId = this.element.email;

    if (!employeeId) {
      console.error("Employee email is undefined.");
      this.snackBarService.add({
        message: "Error: Employee email is undefined.",
        action: "Close",
        config: { duration: 3000 },
      });
      return;
    }

    this.spinnerService.openSpinnerDialog();

    this.payrollService
      .postEmployeeMonthlyPayroll(employeeId, payslipYear, payslipMonth)
      .subscribe(
        (res) => {
          this.isPayslipTriggered = true;
          this.spinnerService.closeSpinnerDialog();
          this.snackBarService.add({
            message: `Payslip generated successfully for ${employeeId}`,
            action: "Close",
            config: { duration: 3000 },
          });
          this.updateButtonClicked = false;
          this.onBackClick();
        },
        (err) => {
          console.error("Error generating payslip", err);
          this.spinnerService.closeSpinnerDialog();
          this.snackBarService.add({
            message: `Error occurred in generating payslip`,
            action: "close",
            config: { duration: 3000 },
          });
        }
      );
  }

  onUpdateButtonClick() {
    of(null)
      .pipe(
        mergeMap(() => {
          this.spinnerService.openSpinnerDialog();

          let earningsApiCall = of(null);
          let deductionsApiCall = of(null);

          if (
            this.earningsFormStatus.formData.length > 0 &&
            this.earningsFormStatus.hasValues &&
            this.earningsFormStatus.touched &&
            !this.earningsFormStatus.invalid
          ) {
            this.earningsExecuted = true;
            earningsApiCall = this.payrollService
              .postEmployeeMonthlyEarningsApi(this.earningsFormStatus.formData)
              .pipe(
                catchError((err) => {
                  this.earningsExecuted = false;
                  this.earningsFormStatus.touched = false;
                  console.error(err);
                  this.snackBarService.add({
                    message: err.error.message,
                    action: "Close",
                    config: {
                      duration: 2000,
                      panelClass: ["custom-snackbar-class"],
                    },
                  });
                  return of(null);
                })
              );
          }

          if (
            this.deductionsFormStatus.formData.length > 0 &&
            this.deductionsFormStatus.hasValues &&
            this.deductionsFormStatus.touched &&
            !this.deductionsFormStatus.invalid
          ) {
            this.deductionsExecuted = true;
            deductionsApiCall = this.payrollService
              .postEmployeeMonthlyDeductionsApi(
                this.deductionsFormStatus.formData
              )
              .pipe(
                catchError((err) => {
                  this.deductionsExecuted = false;
                  this.deductionsFormStatus.touched = false;
                  console.error(err);
                  this.snackBarService.add({
                    message: err.error.message,
                    action: "Close",
                    config: {
                      duration: 2000,
                      panelClass: ["custom-snackbar-class"],
                    },
                  });
                  return of(null);
                })
              );
          }

          return combineLatest([earningsApiCall, deductionsApiCall]);
        }),
        finalize(() => {
          this.spinnerService.closeSpinnerDialog();
        })
      )
      .subscribe(([earningsData, deductionsData]) => {
        let showSuccessMessage = false;
        let successMessage = "";
        if (earningsData && deductionsData) {
          successMessage =
            "Employee Monthly Earnings and Deductions updated successfully.";
        } else if (earningsData) {
          successMessage = "Employee Monthly Earnings updated successfully.";
        } else if (deductionsData) {
          successMessage = "Employee Monthly Deductions updated successfully.";
        }
        if (this.earningsFormStatus.touched && this.earningsExecuted) {
          this.earningsFormStatus.touched = false;
          this.earningsExecuted = false;
          this.employeeEarningsComponent.updateEarningsWithIds(earningsData);

          showSuccessMessage = true;
        }

        if (this.deductionsFormStatus.touched && this.deductionsExecuted) {
          this.deductionsFormStatus.touched = false;
          this.deductionsExecuted = false;
          this.employeeDeductionsComponent.updateDeductionsWithIds(
            deductionsData
          );
          showSuccessMessage = true;
        }

        if (showSuccessMessage) {
          this.snackBarService.add({
            message: successMessage,
            action: "Close",
            config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
          });
        }
      });
    if (
      !this.earningsFormStatus.touched &&
      !this.deductionsFormStatus.touched
    ) {
      this.snackBarService.add({
        message: "You have not made any changes",
        action: "Close",
        config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
      });
    } else if (
      this.earningsFormStatus.invalid ||
      this.deductionsFormStatus.invalid
    ) {
      let errorMessage = "Please enter valid input in: ";

      if (this.earningsFormStatus.invalid) {
        errorMessage += "Earnings ";
      }

      if (this.deductionsFormStatus.invalid) {
        errorMessage += errorMessage.includes("Earnings")
          ? "and Deductions"
          : "Deductions";
      }

      this.snackBarService.add({
        message: errorMessage.trim(),
        action: "Close",
        config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
      });
    }
  }
}
