import { Component, Input, OnInit } from "@angular/core";
import jspdf from "jspdf";
import html2canvas from "html2canvas";
import { EmployeeDetails } from "src/app/core/interfaces/user";
import {
  Header,
  HEADERS,
  PayslipTableData,
  UserDetails,
} from "../payslip.utility";
import { SnackBarService } from "src/app/shared/services/snackbar.service";
@Component({
  selector: "app-payslip-pdf",
  templateUrl: "./payslip-pdf.component.html",
  styleUrls: ["./payslip-pdf.component.scss"],
})
export class PayslipPdfComponent implements OnInit {
  @Input() userDetails: UserDetails[];
  @Input() selectedEmployeeDetails: EmployeeDetails;
  @Input() fileName: string;
  @Input() employeePayrollDetailsForPdf: PayslipTableData[];
  @Input() selectedPayslipYear: number;
  @Input() selectedPayslipMonth: number;

  headers: Header[] = HEADERS;
  constructor(private snackBarService:SnackBarService) {}

  ngOnInit() {}

  public download_pdf(): void {
    const snackbarRef = this.snackBarService.open("Cancel", 0)
    snackbarRef.progress=1;
    snackbarRef.runProgressBar();
    let data = document.getElementById("userPayslipPDF");
    data.style.display = "inline";
    let pdf = new jspdf("p", "mm", "a4");

    html2canvas(data)
      .then((canvas) => {
        let imgWidth = 180;
        let imgHeight = (canvas.height * imgWidth) / canvas.width;

        const contentDataURL = canvas.toDataURL("image/png");

        let position = 0;
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        pdf.output("blob");
        data.style.display = "none";
        pdf.save(this.fileName);
        snackbarRef.progress=100;
      snackbarRef.runProgressBar();
      setTimeout(() => {
        snackbarRef.closeSnackbar();
      }, 2000);
      })
      .catch(() => {
        snackbarRef.progressText="Error occurred in downloading payslip";
      setTimeout(() => {
        snackbarRef.closeSnackbar();
      }, 2000);
      });
  }

  getPairsForPdf(arr: UserDetails[]): UserDetails[][] {
    const pairs: UserDetails[][] = [];
    if(arr){
    for (let i = 0; i < arr.length; i += 2) {
      pairs.push(arr.slice(i, i + 2));
    }}
    return pairs;
  }

  trackByUserDetail(detail: UserDetails): string {
    return detail.objKey;
  }

  trackByHeader(header: Header): string {
    return header[0];
  }

  trackByPayrollDetail(index: number): number {
    return index;
  }
}
