<div class="card ">
  <div class="card-title row">
    <div class="col gem-surface-secondary ">
      <p>Your Payslip for the month of {{ months[selectedPayslipMonth] }}</p>
    </div>
  </div>
  <div class="card-content pr-3 py-3">
    <app-payslip
      (dataEvent)="receiveData($event)"
      [userPayroll]="true"
    ></app-payslip>
  </div>
</div>
