/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./snackbar-with-progress.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "./snackbar-with-progress.component";
import * as i10 from "@angular/material/snack-bar";
var styles_SnackbarWithProgressComponent = [i0.styles];
var RenderType_SnackbarWithProgressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SnackbarWithProgressComponent, data: {} });
export { RenderType_SnackbarWithProgressComponent as RenderType_SnackbarWithProgressComponent };
function View_SnackbarWithProgressComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "padding-right: 25px; padding-left: 30px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "progress-bar mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(2, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 2).mode === "indeterminate") || (i1.ɵnov(_v, 2).mode === "query")) ? null : i1.ɵnov(_v, 2).value); var currVal_1 = i1.ɵnov(_v, 2).mode; var currVal_2 = i1.ɵnov(_v, 2)._isNoopAnimation; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_SnackbarWithProgressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "progress-text"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgStyleImpl, i5.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i5.NgStyle, [i5.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "top": 0 }), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "snackbar-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", ""], ["style", "width: 37px"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SnackbarWithProgressComponent_1)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "d-flex justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "action-button"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissWithAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(13, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, (_co.downloadCompleted ? "24px" : "18px")); _ck(_v, 3, 0, currVal_0); var currVal_3 = !_co.downloadCompleted; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.progressText; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.downloadCompleted ? _co.downloadCompletedIcon : _co.downloadStartedIcon); _ck(_v, 7, 0, currVal_2); var currVal_4 = (i1.ɵnov(_v, 12).disabled || null); var currVal_5 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_4, currVal_5); var currVal_6 = ((_co.data == null) ? null : _co.data.action); _ck(_v, 13, 0, currVal_6); }); }
export function View_SnackbarWithProgressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-snackbar-with-progress", [], null, null, null, View_SnackbarWithProgressComponent_0, RenderType_SnackbarWithProgressComponent)), i1.ɵdid(1, 114688, null, 0, i9.SnackbarWithProgressComponent, [i10.MAT_SNACK_BAR_DATA, i10.MatSnackBarRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SnackbarWithProgressComponentNgFactory = i1.ɵccf("app-snackbar-with-progress", i9.SnackbarWithProgressComponent, View_SnackbarWithProgressComponent_Host_0, {}, {}, []);
export { SnackbarWithProgressComponentNgFactory as SnackbarWithProgressComponentNgFactory };
