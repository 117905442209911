import * as i0 from "@angular/core";
import * as i1 from "./base.service";
var HomeService = /** @class */ (function () {
    function HomeService(baseService) {
        this.baseService = baseService;
    }
    HomeService.prototype.getRandomQuoteApi = function () {
        this.path = "quote/randomQuote";
        return this.baseService.get(this.path);
    };
    HomeService.prototype.getEmployeePayslipApi = function (empId, year, month) {
        return this.baseService.get("payslip?PayslipYear=" + year + "&PayslipMonth=" + month + "&employeeId=" + empId);
    };
    HomeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HomeService_Factory() { return new HomeService(i0.ɵɵinject(i1.BaseService)); }, token: HomeService, providedIn: "root" });
    return HomeService;
}());
export { HomeService };
