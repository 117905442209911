import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormControlName,
  FormGroup,
} from "@angular/forms";
import { of } from "rxjs";
import { catchError, distinctUntilChanged, mergeMap } from "rxjs/operators";
import { PayrollService } from "src/app/shared/services/payroll.service";
import { SnackBarService } from "src/app/shared/services/snackbar.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";

@Component({
  selector: "app-employee-deductions",
  templateUrl: "./employee-deductions.component.html",
  styleUrls: ["./employee-deductions.component.scss"],
})
export class EmployeeDeductionsComponent implements OnInit {
  @Input() selectedPayslipMonth: number;
  @Input() selectedPayslipYear: number;
  @Input() element: any;
  @Output() deductionsFormStatus = new EventEmitter<any
    >();
  @Output() lwpChanged = new EventEmitter<any>();
  @Output() DeductionsFormGroup = new EventEmitter<any>()
  employeeDeductionsList: any;
  deductionsFormGroup: FormGroup;
  isFormInitialized: boolean = false;
  idMap: any;
  initialLwpValue: number = 0;
  lwpSet:boolean = false;
  showSpinner:boolean = true;
  lwpFormGroup:FormGroup;

  constructor(
    private snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private payrollService: PayrollService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.getEmployeeDeductionKeys();
    this.deductionsFormGroup = this.formBuilder.group({});
  }

  getEmployeeDeductionKeys() {
    this.payrollService
      .getEmployeeDeductionsKeysApi()
      .pipe(
        mergeMap((keysResponse) => {
          this.employeeDeductionsList = keysResponse;
          this.showSpinner = false;
          this.initializeForm();
          return this.payrollService
            .getEmployeeMonthlyDeductionsApi(
              this.selectedPayslipMonth,
              this.selectedPayslipYear,
              this.element.employeeId
            )
            .pipe(
              // Handle errors for the second API call
              catchError((err) => {
                console.log("Error occurred in the second API call:", err);
                return of(null);
              })
            );
        }),
        // Handle errors for the first API call
        catchError((err) => {
          console.log("Error occurred in the first API call:", err);
          this.showSpinner = false;
          this.initializeForm();
          return of(null);
        })
      )
      .subscribe(
        (result) => {
          this.showSpinner = false;
          if (result) {
            this.patchFormValues(result);
          }
        },
        (err) => {
          console.log("Error occurred during subscription:", err);
        }
      );
  }

  initializeForm(): void {
    let controls: { [key: string]: FormControl } = {};
    const idMap: { [key: string]: number } = {};

    if (this.employeeDeductionsList && this.employeeDeductionsList.length > 0) {
      this.employeeDeductionsList.forEach((deduction) => {
        controls[deduction.optionValue] = new FormControl("");
        idMap[deduction.optionValue] = deduction.optionId;
      });
    }
    this.idMap = idMap;
    controls["lwp"] = new FormControl("");
    this.isFormInitialized = true;
    this.deductionsFormGroup = this.formBuilder.group(controls);
    // this.lwpFormGroup = this.formBuilder.group({
    //   lwp: new
    // })
    this.getEmployeeMonthlyLeaves();
    this.deductionsFormGroup.valueChanges.subscribe(() => this.emitFormStatus());
  }

  emitFormStatus(): void {
    const formValues = this.deductionsFormGroup.value;
    delete formValues["lwp"]; 
    const isTouched = Object.keys(this.deductionsFormGroup.controls).some(
      (controlName) => controlName !== 'lwp' && this.deductionsFormGroup.get(controlName).touched
    );
    this.DeductionsFormGroup.emit(this.deductionsFormGroup)
    this.deductionsFormStatus.emit({
      hasValues: Object.values(formValues).some(
        (value) => value !== null && value !== ""
      ),
      formData: this.preparePayload(formValues),
      touched:isTouched,
      empty: Object.values(formValues).every(
        (value) => value === null || value === ""
      )
    });
  }

  preparePayload(formValues: any): any[] {
    return Object.keys(formValues).map((key) => ({
      // Set appropriate value or remove if not needed
      employeeId: this.element.employeeId,
      year: this.selectedPayslipYear,
      deduction: key,
      amount: Number(formValues[key]),
      month: this.selectedPayslipMonth,
      fiscal: `${this.selectedPayslipYear}`,
    }));
  }

  patchFormValues(data: any[]) {
    const patchValues = {};
    data.forEach((item) => {
      if (this.deductionsFormGroup.controls[item.deduction]) {
        patchValues[item.deduction] = item.amount;
      }
    });

    this.deductionsFormGroup.patchValue(patchValues);
    this.emitFormStatus()
  }

  getEmployeeMonthlyLeaves() {
    this.payrollService
      .getEmployeeMonthlyLeaves(
        this.element.employeeId,
        this.selectedPayslipYear,
        this.selectedPayslipMonth
      )
      .subscribe(
        (res) => {
          this.deductionsFormGroup.get("lwp").setValue(res.leaveCount);
          this.lwpSet=true;
          this.initialLwpValue = res.leaveCount
          
          this.deductionsFormGroup.get('lwp').valueChanges.subscribe(value => {
             this.onLwpValueChange(value);
             console.log("lwp triggered")
          });
        },
        (err) => {
          console.log(err);
          this.lwpSet=true;
        }
      );
  }

  onLwpValueChange(value: any) {
    this.lwpChanged.emit(value);
  }

  preventDirtyState(): void {
    const lwpControl = this.deductionsFormGroup.get('lwp');
      lwpControl.markAsUntouched();
      console.log("inside prevent dirty state")
  }

  onControlInput(controlName: string){
    this.deductionsFormGroup.get(controlName).markAsTouched();
    console.log("marked as touched")
    this.emitFormStatus();
  }
  
}
