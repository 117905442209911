export type EarningsOrDeductionArray = { label: string; amount: number }[];
export type MonthlyPayslipTableData = {
  earningsComponent: string;
  earningsComponentAmount: string;
  deductionsComponent: string;
  deductionsComponentAmount: string;
};
export type CtcPayslipTableData = {
  earningsComponent: string;
  earningsComponentAmount: string;
};

export type UserDetails = { label: string; objKey: string };

export interface PayslipComponentOption {
  label: string;
  amount: number;
}
export interface Payslip {
  monthlyPayslipId: number;
  payslipYear: number;
  employeeId: string;
  payslipComponent: string;
  componentAmount: number;
  payslipMonth: number;
  fiscal: string;
}

export interface EmployeePayrollDetails {
  earnings: Payslip[];
  deductions: Payslip[];
  payslipSummary: Payslip[];
}
//
export interface CtcPayslipHeader {
  label: string;
  amount: number;
}
export interface CtcPayslip {
  monthlyPayslipId: number;
  payslipYear: number;
  employeeId: string;
  payslipComponent: string;
  componentAmount: number;
  payslipMonth: number;
  fiscal: string;
}

export interface EmployeeCtcPayrollDetails {
  earnings: CtcPayslip[];
  payslipSummary: CtcPayslip[];
}
export const USER_DETAILS: UserDetails[] = [
  {
    label: "Name",
    objKey: "name",
  },
  {
    label: "Employee Code",
    objKey: "employeeCode",
  },
  {
    label: "Joining Date",
    objKey: "dateOfJoining",
  },
  {
    label: "Bank Name",
    objKey: "bankAcctName",
  },
  {
    label: "Designation",
    objKey: "designation",
  },
  {
    label: "Bank Account No",
    objKey: "bankAcctNo",
  },
  {
    label: "Department",
    objKey: "department",
  },
  {
    label: "PAN Number",
    objKey: "pan",
  },
  {
    label: "Location",
    objKey: "location",
  },
  {
    label: "PF No",
    objKey: "pfNo",
  },
  {
    label: "Effective Working Days",
    objKey: "effectiveWorkingDays",
  },
  {
    label: "PF UAN",
    objKey: "uanNo",
  },
  {
    label: "LOP",
    objKey: "lwpCount",
  },
];
export const NAME_KEY = "name";
export const SNACKBAR_LABEL = "Close";
export const PAYSLIP_SUMMARY = "payslipSummary";
export const EARNINGS = "earnings";
export const DEDUCTIONS = "deductions";
export const TOTAL_DEDUCTIONS_STRING = "Total Deductions";
export const NET_PAY = "Net Pay";
export const TOTAL_INCOME_STRING = "Total Income";
export const PAYSLIP_COMPONENT = "payslipComponent";
export const PAYSLIP_COMPONENT_AMOUNT = "componentAmount";
export const MONTHLY_PAYSLIP_HEADERS: [string,string][] = [
  ["earningsComponent", "Earnings"],
  ["earningsComponentAmount", "Amount"],
  ["deductionsComponent", "Deductions"],
  ["deductionsComponentAmount", "Amount"],
];
export const CTC_PAYSLIP_HEADERS: [string,string][] = [
  ["earningsComponent", "Earnings"],
  ["earningsComponentAmount", "Amount"],
];

export const MONTH: { [month: number]: string } = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};
