<div class="earnings-deductions-wrapper">
  <div class="row header-row justify-content-between">
    <div class="col-2 main-heading" matTooltip="Back" (click)="onBackClick()">
      <mat-icon>chevron_left</mat-icon>
      <div>{{ sectionName }}</div>
    </div>
    <div class="col-3 d-flex align-items-center justify-content-end">
      {{ months[selectedPayslipMonth] }} &nbsp; {{ selectedPayslipYear }}
    </div>
    <div class="col-7 d-flex align-items-center justify-content-end">
      <b>Employee Id </b> &nbsp; : &nbsp; {{ element?.employeeId }}
    </div>
  </div>

  <mat-tab-group
    class="earnings-deductions-tab"
    (selectedTabChange)="onTabChange($event)"
  >
    <mat-tab label="Earnings">
      <ng-container>
        <app-employee-earnings
          [selectedPayslipYear]="selectedPayslipYear"
          [selectedPayslipMonth]="selectedPayslipMonth"
          [element]="element"
          (earningsFormStatus)="onFormStatusChange($event)"
        ></app-employee-earnings>
      </ng-container>
    </mat-tab>
    <mat-tab label="Deductions">
      <ng-container>
        <app-employee-deductions
          [selectedPayslipYear]="selectedPayslipYear"
          [selectedPayslipMonth]="selectedPayslipMonth"
          [element]="element"
          (deductionsFormStatus)="onDeductionsFormStatusChange($event)"
        ></app-employee-deductions>
      </ng-container>
    </mat-tab>
  </mat-tab-group>

  <div class="d-flex justify-content-end align-items-center footer">
    <div>
      <button
        style="background-color: #38a3a5; color: white"
        class="mr-4"
        mat-button
        (click)="
          triggerPayslip(
            element?.employeeId,
            selectedPayslipYear,
            selectedPayslipMonth
          )
        "
      >
        Generate Payslip
      </button>
      <button
        style="background-color: #38a3a5; color: white"
        class="mr-4"
        mat-button
        (click)="onUpdateButtonClick()"
      >
        Update
      </button>
    </div>
  </div>
</div>
