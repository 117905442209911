import { HttpParams } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "./base.service";
var EmployeeService = /** @class */ (function () {
    function EmployeeService(baseService) {
        this.baseService = baseService;
        this.newUserAdded = false;
    }
    EmployeeService.prototype.getAllEmployeesDetailsApi = function (pageIndex, pageSize, search) {
        this.path = "employee";
        var params = "pageNumber=" + pageIndex + "&pageSize=" + pageSize;
        if (search && search.trim() !== "") {
            params += "&search=" + search;
        }
        return this.baseService.get(this.path + "?" + params);
    };
    EmployeeService.prototype.postEmployeeDetailsApi = function (data) {
        this.path = "employee";
        return this.baseService.post(this.path, data);
    };
    EmployeeService.prototype.postEmployeeCTCDetailsApi = function (data) {
        this.path = "employeeCtc";
        return this.baseService.post(this.path, data);
    };
    EmployeeService.prototype.getCTCComponentsApi = function () {
        this.path = "options/CTC_COMPONENT";
        return this.baseService.get(this.path);
    };
    EmployeeService.prototype.getAllCTCComponentsApi = function () {
        this.path = "ctc";
        return this.baseService.get(this.path);
    };
    EmployeeService.prototype.calculateCTCApi = function (data) {
        this.path = "payrollCalc";
        return this.baseService.post(this.path, data);
    };
    EmployeeService.prototype.getEmployeeCTCDetailsApi = function (empId, fiscal) {
        this.path = "employeeCtc/employeeCtcDetailByEmployeeId?employeeId=" + empId + "&fiscal=" + fiscal;
        return this.baseService.get(this.path);
    };
    EmployeeService.prototype.getEmployeeDetailsApi = function (empId) {
        this.param = new HttpParams().set("employeeId", empId);
        this.path = "employee/getEmployeeById";
        return this.baseService.get(this.path, this.param);
    };
    EmployeeService.prototype.getEmployeeDetailsByEmailApi = function (email) {
        this.path = "employee/employeeByEmail?emailAddress=" + email;
        return this.baseService.get(this.path);
    };
    EmployeeService.prototype.postBulkFileUploadApi = function (data) {
        this.path = "employee/bulkImportEmployee";
        return this.baseService.post(this.path, data);
    };
    EmployeeService.prototype.deleteEmployeeByIdApi = function (empId) {
        this.path = "employee?employeeId=" + empId;
        return this.baseService.delete(this.path);
    };
    EmployeeService.prototype.getEmployeeDetailsByNameApi = function (name) {
        this.param = new HttpParams().set("name", name);
        this.path = "employee/employeeByName";
        return this.baseService.get(this.path, this.param);
    };
    EmployeeService.prototype.getEmployeesExcelApi = function () {
        this.path = "employee/employeesExcel";
        return this.baseService.getExcel(this.path);
    };
    EmployeeService.prototype.putEmployeePFPreferenceApi = function (body) {
        this.path = "employeePf";
        return this.baseService.put(this.path, body);
    };
    EmployeeService.prototype.putEmployeePFPreferencePutApi = function (body) {
        this.path = "employeePf";
        return this.baseService.put(this.path, body);
    };
    EmployeeService.prototype.getEmployeePFPreferenceApi = function () {
        this.path = "employeePf";
        return this.baseService.get(this.path);
    };
    EmployeeService.prototype.getEmployeeOnboardForm = function () {
        this.path = "employee/onboardForm";
        return this.baseService.getExcel(this.path);
    };
    EmployeeService.prototype.updateEmployeeCtc = function (body) {
        this.path = "employeeCtc";
        return this.baseService.put(this.path, body);
    };
    EmployeeService.prototype.updateEmployeeDetails = function (body) {
        this.path = "employee";
        return this.baseService.put(this.path, body);
    };
    /**
     * Fetch Employee PF Preference by Employee ID and Fiscal Year
     * @param employeeId The Employee ID
     * @param fiscal The fiscal year
     * @returns Observable with the PF Preference response
     */
    EmployeeService.prototype.getEmployeePFPreferenceByEmpIdAndFiscal = function (employeeId, fiscal) {
        this.path = "employeePf/empPFPreferenceByEmpIdAndFiscal?employeeId=" + employeeId + "&fiscal=" + fiscal;
        return this.baseService.get(this.path);
    };
    EmployeeService.prototype.postEmployeePFPreferenceApi = function (body) {
        this.path = "employeePf";
        return this.baseService.post(this.path, body);
    };
    EmployeeService.prototype.getEmployeeProfessionalDetailsApi = function (empId) {
        this.path = "employeeProfessionalDetails?employeeId=" + empId;
        return this.baseService.get(this.path);
    };
    EmployeeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmployeeService_Factory() { return new EmployeeService(i0.ɵɵinject(i1.BaseService)); }, token: EmployeeService, providedIn: "root" });
    return EmployeeService;
}());
export { EmployeeService };
