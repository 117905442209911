import { Injector, NgZone } from "@angular/core";
import * as moment from "moment";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { LOCAL_STORAGE_KEYS, LoginService } from "src/app/account/login.service";
import { SnackBarService } from "src/app/shared/services/snackbar.service";
var SESSION_EXPIRED_MESSAGE = "Session Expired.. Logging Out..";
var NOT_AUTHORIZED_MESSAGE = "You are not authorized to access this service.";
var RequestInterceptor = /** @class */ (function () {
    function RequestInterceptor(injector, router, ngZone) {
        this.injector = injector;
        this.router = router;
        this.ngZone = ngZone;
        this.snackBarService = this.injector.get(SnackBarService);
        this.refreshTokenInProgress = false;
        this.tokenRefreshedSource = new Subject();
        this.tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
    }
    RequestInterceptor.prototype.refreshToken = function () {
        var _this = this;
        /* This if block covers the case when new token is still not received
         and the user makes another API request .*/
        if (this.refreshTokenInProgress) {
            return new Observable(function (observer) {
                _this.tokenRefreshed$.subscribe(function () {
                    observer.next();
                    observer.complete();
                });
            });
        }
        else {
            this.refreshTokenInProgress = true;
            return this.loginService.silentRefresh().pipe(tap(function () {
                _this.refreshTokenInProgress = false;
                _this.tokenRefreshedSource.next();
            }), catchError(function (err) {
                _this.refreshTokenInProgress = false;
                return err;
            }));
        }
    };
    RequestInterceptor.prototype.logout = function () {
        var _this = this;
        if (sessionStorage.getItem(LOCAL_STORAGE_KEYS.userLoginInfo) !== null) {
            sessionStorage.removeItem(LOCAL_STORAGE_KEYS.userLoginInfo);
        }
        this.ngZone.run(function () {
            _this.router.navigate(["/login"]);
        });
    };
    RequestInterceptor.prototype.isTokenExpired = function () {
        var expiresOn = moment(JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_KEYS.userLoginInfo)).expiresOn)
            .utc()
            .local();
        return expiresOn < moment(new Date());
    };
    RequestInterceptor.prototype.handleResponseError = function (error, request, next) {
        var _this = this;
        switch (error.status) {
            case 400:
                return throwError(error);
            case 0:
                if (this.isTokenExpired()) {
                    return this.refreshToken().pipe(switchMap(function () {
                        request = _this.loginService.addAuthHeader(request);
                        return next.handle(request);
                    }), catchError(function (e) {
                        _this.snackBarService.add({
                            message: SESSION_EXPIRED_MESSAGE,
                            config: { duration: 2000,
                                panelClass: ['custom-snackbar-class']
                            },
                        });
                        _this.logout();
                        return throwError(e);
                    }));
                }
                else {
                    this.snackBarService.add({
                        message: NOT_AUTHORIZED_MESSAGE,
                        config: { duration: 2000,
                            panelClass: ['custom-snackbar-class']
                        },
                    });
                    return throwError(NOT_AUTHORIZED_MESSAGE);
                }
            case 500:
                return throwError(error);
            case 503:
                return throwError(error);
            default:
                return throwError(error);
        }
    };
    RequestInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        this.loginService = this.injector.get(LoginService);
        // Setting access token in request headers.
        req = this.loginService.addAuthHeader(req);
        return next.handle(req).pipe(catchError(function (error) {
            return _this.handleResponseError(error, req, next);
        }));
    };
    return RequestInterceptor;
}());
export { RequestInterceptor };
