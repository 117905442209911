import * as i0 from "@angular/core";
import * as i1 from "./base.service";
var PoiService = /** @class */ (function () {
    function PoiService(baseService) {
        this.baseService = baseService;
    }
    PoiService.prototype.savePoiAsDraftApi = function (empId, formData) {
        this.path = "empInvestProof/draftPOI?employeeId=" + empId;
        return this.baseService.post(this.path, formData);
    };
    PoiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PoiService_Factory() { return new PoiService(i0.ɵɵinject(i1.BaseService)); }, token: PoiService, providedIn: "root" });
    return PoiService;
}());
export { PoiService };
