import { PipeTransform } from '@angular/core';
var DateFormatPipe = /** @class */ (function () {
    function DateFormatPipe() {
    }
    DateFormatPipe.prototype.transform = function (value) {
        var options = { day: 'numeric', month: 'short', year: 'numeric' };
        var date = new Date(value);
        return date.toLocaleDateString('en-US', options);
    };
    return DateFormatPipe;
}());
export { DateFormatPipe };
