import { EventEmitter, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material";
var REGIME_NAMES = ["New", "Old"];
var TAX_COMPARISON_TABLE_COLUMNS = [
    { id: "particulars", label: "Particulars" },
    { id: "oldTaxRegime", label: "Old Tax Regime" },
    { id: "newTaxRegime", label: "New Tax Regime" },
];
var InvestmentDeclarationPreviewComponent = /** @class */ (function () {
    function InvestmentDeclarationPreviewComponent() {
        this.taxRegimes = [];
        this.displayedColumns = TAX_COMPARISON_TABLE_COLUMNS;
        this.onRegimeSelect = new EventEmitter();
    }
    InvestmentDeclarationPreviewComponent.prototype.ngOnInit = function () {
        this.setTaxRegimes();
        this.setTableData(this.incomeTaxResponse);
    };
    InvestmentDeclarationPreviewComponent.prototype.setTaxRegimes = function () {
        var _this = this;
        REGIME_NAMES.forEach(function (name) {
            var label = name + " Tax Regime";
            var id = name.toLocaleLowerCase() + "Regime";
            _this.taxRegimes.push({
                label: label,
                id: id,
            });
        });
    };
    InvestmentDeclarationPreviewComponent.prototype.setSelectedRegime = function (id) {
        this.selectedRegime = id;
        this.emitSelectedRegime();
    };
    InvestmentDeclarationPreviewComponent.prototype.setRecommendedRegime = function () {
        this.recommendedRegime =
            this.taxRegimes[0].netTax < this.taxRegimes[1].netTax
                ? this.taxRegimes[0].id
                : this.taxRegimes[1].id;
        this.setSelectedRegime(this.recommendedRegime);
        this.emitSelectedRegime();
    };
    InvestmentDeclarationPreviewComponent.prototype.isRecommendedRegime = function (id) {
        return id === this.recommendedRegime;
    };
    InvestmentDeclarationPreviewComponent.prototype.isSelectedRegime = function (id) {
        return id === this.selectedRegime;
    };
    InvestmentDeclarationPreviewComponent.prototype.getDisplayedColumns = function () {
        return this.displayedColumns.map(function (col) { return col.id; });
    };
    InvestmentDeclarationPreviewComponent.prototype.setTableData = function (data) {
        var tableData = [];
        var oldRegimeData = data.find(function (regime) { return regime.taxRegimeTypes === "OLD_REGIME"; });
        var oldRegime = this.taxRegimes.find(function (el) { return el.id === "oldRegime"; });
        oldRegime.netTax = oldRegimeData.taxAmount;
        oldRegime.taxableIncome = oldRegimeData.taxableIncome;
        var oldSectionNames = Object.keys(oldRegimeData.investmentAcknowledged);
        oldSectionNames.forEach(function (sectionName) {
            var tableDataElement = {
                particulars: sectionName,
            };
            tableData.push(tableDataElement);
            var sectionComponentNames = Object.keys(oldRegimeData.investmentAcknowledged[sectionName]);
            sectionComponentNames.forEach(function (componentName) {
                var sectionComponentValue = oldRegimeData.investmentAcknowledged[sectionName][componentName];
                var tableDataElement = {
                    particulars: componentName,
                    oldTaxRegime: sectionComponentValue.toString(),
                    newTaxRegime: "0",
                };
                tableData.push(tableDataElement);
            });
        });
        var newRegimeData = data.find(function (regime) { return regime.taxRegimeTypes === "NEW_REGIME"; });
        var newRegime = this.taxRegimes.find(function (el) { return el.id === "newRegime"; });
        newRegime.netTax = newRegimeData.taxAmount;
        newRegime.taxableIncome = newRegimeData.taxableIncome;
        var sectionNames = Object.keys(newRegimeData.investmentAcknowledged);
        sectionNames.forEach(function (sectionName) {
            var sectionComponentNames = Object.keys(newRegimeData.investmentAcknowledged[sectionName]);
            sectionComponentNames.forEach(function (componentName) {
                var sectionComponent = tableData.find(function (el) { return el.particulars === componentName; });
                var sectionComponentValue = newRegimeData.investmentAcknowledged[sectionName][componentName];
                sectionComponent.newTaxRegime = sectionComponentValue.toString();
            });
        });
        tableData.push({
            particulars: "Net Tax",
            newTaxRegime: newRegime.netTax.toString(),
            oldTaxRegime: oldRegime.netTax.toString(),
        });
        this.taxComparisonData = new MatTableDataSource(tableData);
        this.setRecommendedRegime();
    };
    InvestmentDeclarationPreviewComponent.prototype.emitSelectedRegime = function () {
        if (this.selectedRegime === "newRegime") {
            this.onRegimeSelect.emit("NEW_REGIME");
        }
        else {
            this.onRegimeSelect.emit("OLD_REGIME");
        }
    };
    InvestmentDeclarationPreviewComponent.prototype.onRegimeCardClick = function (id) {
        this.setSelectedRegime(id);
        this.emitSelectedRegime();
    };
    return InvestmentDeclarationPreviewComponent;
}());
export { InvestmentDeclarationPreviewComponent };
