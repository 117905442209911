var StringOnlyDirective = /** @class */ (function () {
    function StringOnlyDirective(ngControl) {
        this.ngControl = ngControl;
    }
    StringOnlyDirective.prototype.onInputChange = function (event) {
        var initialValue = this.ngControl.value;
        this.ngControl.control.setValue(initialValue.replace(/[^a-zA-Z ]*/g, ''));
        if (initialValue !== this.ngControl.value) {
            event.stopPropagation();
        }
    };
    return StringOnlyDirective;
}());
export { StringOnlyDirective };
