<div class="emp-details gem-surface-secondary-50 pl-4 pt-3 ">
  <div class="grid-container">
    <span class="gem-text-dark card-heading pb-2">Employee Details</span>
    <i
      class="ph pr-4 gem-text-primary"
      [ngClass]="showDetails ? 'ph-eye' : 'ph-eye-slash'"
      (click)="toggleInfo()"
    >
    </i>
  </div>
  <div *ngIf="showDetails" class="emp-grid">
    <div class="card-item pl-0 pb-2" *ngFor="let detail of userDetails;let last=last;trackBy: trackByEmployeeDetail">
      <div [ngClass]="{'pb-2': last}">
      <span class="emp-heading gem-text-muted">{{ detail?.label }}</span
      ><br />
      <span class="text  gem-text-dark">
        {{ selectedEmployeeDetails[detail?.objKey] }}
      </span>
    </div>
    </div>
  </div>
</div>

