import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit, TemplateRef, } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource, } from "@angular/material";
import { AddEmployeeDetailsComponent } from "../add-employee-details/add-employee-details.component";
import { FEATURE_TO_GROUPS_MAPPING } from "../core/authorization/permissions";
import { FormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { PayrollDialogComponent } from "../payroll-dialog/payroll-dialog.component";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
var COLS_WHICH_REQUIRE_TOOLTIP = ["employeeId", "name"];
var EmployeeDetailsComponent = /** @class */ (function () {
    function EmployeeDetailsComponent(dialog, employeeService, _snackBar, spinnerService, fb, snackBarService) {
        this.dialog = dialog;
        this.employeeService = employeeService;
        this._snackBar = _snackBar;
        this.spinnerService = spinnerService;
        this.fb = fb;
        this.snackBarService = snackBarService;
        this.isClearEnable = false;
        this.showSearchParams = true;
        this.employeeCTCDetails = [];
        this.employeeCTCDetailsForEditDialog = [];
        this.files = [];
        this.durationInSeconds = 5;
        this.ctcDetails = true;
        this.featurePermissions = FEATURE_TO_GROUPS_MAPPING["empDetails"];
        this.isAuthorized = {
            write: false,
            update: false,
            delete: false,
        };
        this.pageIndex = 0;
        this.pageSize = 10;
        this.totalEmployees = 0;
        this.empDetails = [
            { header: "Aadhaar No.", field: "aadhaar" },
            { header: "Permanent Address", field: "permanentAddress" },
            { header: "Correspondence Address", field: "correspondenceAddress" },
            { header: "PAN", field: "pan" },
            { header: "UAN", field: "uanNo" },
            { header: "PF No.", field: "pfNo" },
            { header: "Acc. No.", field: "bankAcctNo" },
            { header: "Bank Acc. Name", field: "bankAcctName" },
            { header: "Bank Acc. Address", field: "bankAcctAddress" },
            { header: "IFSC", field: "bankIFSC" },
        ];
        this.searchText$ = new Subject();
        this.years = [];
        this.displayedColumns = [
            "expand_action",
            "employeeId",
            "name",
            "dateOfJoining",
            "dateOfExit",
            "employeeCode",
            "status",
            "options",
        ];
        this.months = [
            { name: "January", value: 1 },
            { name: "February", value: 2 },
            { name: "March", value: 3 },
            { name: "April", value: 4 },
            { name: "May", value: 5 },
            { name: "June", value: 6 },
            { name: "July", value: 7 },
            { name: "August", value: 8 },
            { name: "September", value: 9 },
            { name: "October", value: 10 },
            { name: "November", value: 11 },
            { name: "December", value: 12 },
        ];
        this.fiscal = getCurrentFiscalYear();
        this.selectedYear = getCurrentFiscalYear();
        this.submitOrupload = "Upload";
        this.confirmValueList = [];
        this.isCtcTabSelected = true;
        this.showSpinner = true;
        this.isEditMode = false;
        this.isEditPf = false;
        this.isEditingPersonalDetails = false;
        this.isEditingCtcDetails = false;
        this.searchInputValue = "";
        this.isScreenSizeLessThanMdBreakPoint = false;
    }
    Object.defineProperty(EmployeeDetailsComponent.prototype, "paginator", {
        set: function (value) {
            if (this.empListDataSource) {
                this.empListDataSource.paginator = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    EmployeeDetailsComponent.prototype.ngAfterViewInit = function () {
        this.setIsScreenSizeLessThanMdBreakPoint();
    };
    EmployeeDetailsComponent.prototype.onResize = function (event) {
        this.setIsScreenSizeLessThanMdBreakPoint();
    };
    EmployeeDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.today = new Date();
        this.editEmployeeForm = this.fb.group({});
        this.getAllEmployeesDetails();
        this.searchText$
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(function (searchTerm) {
            _this.pageIndex = 0;
            _this.getAllEmployeesDetails(searchTerm);
        });
        this.years = Array.from(new Array(11), function (x, i) { return getCurrentFiscalYear() - i; });
        this.setCurrentMonth();
        this.setCurrentYear();
    };
    EmployeeDetailsComponent.prototype.handleTabChange = function (event) {
        this.isCtcTabSelected = event.index === 0;
    };
    EmployeeDetailsComponent.prototype.setCurrentMonth = function () {
        var currMonth = this.today.getMonth() + 1;
        var currMonthObj = this.months.filter(function (month) { return month.value == currMonth; });
        this.lwpMonth = currMonthObj[0].name;
    };
    EmployeeDetailsComponent.prototype.setCurrentYear = function () {
        var currYear = this.today.getFullYear();
        this.lwpYear = currYear;
    };
    EmployeeDetailsComponent.prototype.openAddEmpDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(AddEmployeeDetailsComponent, {
            width: "1154px",
            height: "610px",
            panelClass: "my-panel-class",
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (_this.employeeService.newUserAdded) {
                _this.getAllEmployeesDetails();
            }
            _this.employeeService.newUserAdded = false;
        });
    };
    EmployeeDetailsComponent.prototype.getDetailsAsPerYear = function () {
        var _this = this;
        this.allEmpDetails.forEach(function (element) {
            if (_this.expandedElement === element) {
                _this.getEmployeeCTCDetails(element);
            }
        });
    };
    EmployeeDetailsComponent.prototype.getAllEmployeesDetails = function (search) {
        var _this = this;
        this.showSpinner = true;
        this.employeeService
            .getAllEmployeesDetailsApi(this.pageIndex, this.pageSize, search)
            .subscribe(function (res) {
            // allEmpDetails should be removed
            _this.allEmpDetails = res.content;
            _this.showSpinner = false;
            _this.totalEmployees = res.totalElements;
            _this.empListDataSource = res.content;
        }, function (error) {
            _this.showSpinner = false;
            console.error("Error fetching employee details", error);
        });
    };
    EmployeeDetailsComponent.prototype.onSearch = function ($event) {
        this.searchText$.next(this.searchInputValue.trim());
    };
    EmployeeDetailsComponent.prototype.onPageChange = function (event) {
        this.pageIndex = this.pageSize !== event.pageSize ? 0 : event.pageIndex;
        this.pageSize = event.pageSize;
        this.getAllEmployeesDetails(this.searchInputValue);
    };
    // onPageChange(event: PageEvent): void {
    //   if (this.pageSize !== event.pageSize) {
    //     this.pageIndex = 0;
    //   } else {
    //     this.pageIndex = event.pageIndex;
    //   }
    //   this.pageSize = event.pageSize;
    //   this.getAllEmployeesDetails(this.searchInputValue);
    // }
    EmployeeDetailsComponent.prototype.getEmployeeDetailsByName = function () {
        var _this = this;
        this.employeeService
            .getEmployeeDetailsByNameApi(this.searchInputValue)
            .subscribe(function (res) {
            _this.searchResults = res;
            _this.empListDataSource = new MatTableDataSource(_this.searchResults);
            _this.empListDataSource.sort = _this.sort;
            _this.empListDataSource.paginator = _this.paginator;
        });
    };
    EmployeeDetailsComponent.prototype.displayAllEmpDataOnEmptyInput = function () {
        if (this.searchInputValue === "") {
            this.empListDataSource = new MatTableDataSource(this.allEmpDetails);
            this.empListDataSource.sort = this.sort;
            this.empListDataSource.paginator = this.paginator;
        }
    };
    EmployeeDetailsComponent.prototype.getEmployeeCTCDetails = function (element, edit) {
        var _this = this;
        if (edit === void 0) { edit = false; }
        if (this.expandedElement === element || edit === true) {
            this.employeeService
                .getEmployeeCTCDetailsApi(element.employeeId, String(this.fiscal))
                .subscribe(function (res) {
                if (edit === true) {
                    _this.employeeCTCDetailsForEditDialog = res;
                    _this.employeeCTCDetailsForEditDialog.forEach(function (_a) {
                        var ctcComponent = _a.ctcComponent, amount = _a.amount;
                        _this.editEmployeeForm.addControl(ctcComponent, new FormControl(amount.toString()));
                        _this.editEmployeeForm.get(ctcComponent).patchValue(amount);
                    });
                    _this.initialFormValues = _this.editEmployeeForm.value;
                    _this.editEmployeeForm.markAsPristine();
                }
                else {
                    _this.ctcDetails = true;
                    _this.employeeCTCDetails = res;
                }
            }, function (error) {
                _this.employeeCTCDetailsForEditDialog = [];
                _this.ctcDetails = false;
                _this.snackBarService.add({
                    message: error.error.message,
                    action: "close",
                    config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
                });
            });
        }
    };
    EmployeeDetailsComponent.prototype.resetForm = function () {
        this.editEmployeeForm.reset(this.initialFormValues);
    };
    EmployeeDetailsComponent.prototype.uploadBulkData = function () {
        var _this = this;
        var formData = new FormData();
        formData.append("file", this.browsedFile);
        if (!this.browsedFile) {
            alert("Select a file to continue.");
        }
        this.spinnerService.openSpinnerDialog();
        this.employeeService.postBulkFileUploadApi(formData).subscribe(function (res) {
            _this.spinnerService.closeSpinnerDialog();
            _this.openSnackbar(res.message, "Close");
            _this.dialog.closeAll();
            _this.browsedFile = null;
            _this.getAllEmployeesDetails();
        }, function (error) {
            _this.spinnerService.closeSpinnerDialog();
            _this.openSnackbar(error.error.message, "Close");
        });
    };
    /**
     * on file drop handler
     */
    EmployeeDetailsComponent.prototype.onFileDropped = function () {
        this.prepareFilesList();
    };
    /**
     * handle file from browsing
     */
    EmployeeDetailsComponent.prototype.fileBrowseHandler = function (event) {
        this.browsedFile = event.target.files[0];
        this.prepareFilesList();
    };
    /**
     * Delete file from files list
     * @param index (File index)
     */
    EmployeeDetailsComponent.prototype.deleteFile = function () {
        this.submitOrupload = "Upload";
        this.fileInp.nativeElement.value = "";
        this.browsedFile = null;
    };
    /**
     * Simulate the upload process
     */
    EmployeeDetailsComponent.prototype.uploadFilesSimulator = function (index) {
        var _this = this;
        setTimeout(function () {
            var progressInterval = setInterval(function () {
                if (_this.browsedFile != null) {
                    if (_this.browsedFile.progress === 100) {
                        _this.submitOrupload = "Submit";
                        clearInterval(progressInterval);
                        _this.uploadFilesSimulator(index + 1);
                    }
                    else {
                        _this.browsedFile.progress += 5;
                    }
                }
                else {
                    clearInterval(progressInterval);
                }
            }, 200);
        }, 1000);
    };
    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    EmployeeDetailsComponent.prototype.prepareFilesList = function () {
        this.browsedFile.progress = 0;
        this.uploadFilesSimulator(0);
    };
    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    EmployeeDetailsComponent.prototype.formatBytes = function (bytes) {
        var decimals;
        if (bytes === 0) {
            return "0 Bytes";
        }
        var k = 1024;
        var dm = decimals <= 0 ? 0 : decimals || 2;
        var sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };
    EmployeeDetailsComponent.prototype.openUploadFilesDialog = function (templateRef) {
        var dialogRef = this.dialog.open(templateRef, {
            panelClass: "upload-panel-class",
        });
        dialogRef.afterClosed().subscribe(function (result) { });
    };
    EmployeeDetailsComponent.prototype.onClose = function () {
        this.dialog.closeAll();
        this.browsedFile = null;
    };
    EmployeeDetailsComponent.prototype.openSnackbar = function (message, action) {
        this._snackBar.open(message, action, {
            duration: this.durationInSeconds * 1000,
            panelClass: ["custom-snackbar-class"],
        });
    };
    EmployeeDetailsComponent.prototype.deleteEmployee = function (id) {
        var _this = this;
        this.spinnerService.openSpinnerDialog();
        this.employeeService.deleteEmployeeByIdApi(id).subscribe(function (result) {
            _this.getAllEmployeesDetails();
            _this.spinnerService.closeSpinnerDialog();
            _this.openSnackbar(result.message, "close");
        }, function (error) {
            _this.spinnerService.closeSpinnerDialog();
            _this.openSnackbar("Some problem occurred", "close");
            console.error(error);
        });
    };
    EmployeeDetailsComponent.prototype.openDeleteEmpDialog = function (firstName, lastName, id) {
        var _this = this;
        var name = firstName + " " + lastName;
        var dialogRef = this.dialog.open(PayrollDialogComponent, {
            data: {
                title: "Confirmation",
                msg: "Are you sure you want to delete details for:\n        <br> Employee Name: " + name + "\n        <br> Employee Id: " + (id || ""),
                dialogType: "delete",
                onYesClickFunction: function () { return _this.deleteEmployee(id); },
            },
        });
    };
    EmployeeDetailsComponent.prototype.getEmployeesExcel = function () {
        var snackbarRef = this.snackBarService.open("Close", 0);
        snackbarRef.progress = 1;
        snackbarRef.runProgressBar();
        this.employeeService.getEmployeesExcelApi().subscribe(function (res) {
            var filename = res.headers
                .get("content-disposition")
                .split("filename=")[1];
            var url = window.URL.createObjectURL(res.body);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display: none");
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
            snackbarRef.progress = 100;
            snackbarRef.runProgressBar();
            setTimeout(function () {
                snackbarRef.closeSnackbar();
            }, 2000);
        }, function (err) {
            snackbarRef.progressText =
                "Error occurred in downloading employees excel data";
            setTimeout(function () {
                snackbarRef.closeSnackbar();
            }, 2000);
        });
    };
    EmployeeDetailsComponent.prototype.resetEditEmployeeForm = function () {
        var formValues = {};
        this.employeeCTCDetailsForEditDialog.forEach(function (_a) {
            var ctcComponent = _a.ctcComponent, amount = _a.amount;
            formValues[ctcComponent] = amount;
        });
        this.editEmployeeForm.patchValue(formValues);
    };
    EmployeeDetailsComponent.prototype.updateEmployeeCTC = function () {
        var _this = this;
        var payloadArray = [];
        this.employeeCTCDetailsForEditDialog.forEach(function (ctc) {
            var payload = {};
            payload["employeeCtcId"] = ctc.employeeCtcId;
            payload["employeeId"] = ctc.employeeId;
            payload["ctcComponent"] = ctc.ctcComponent;
            payload["amount"] = parseInt(_this.editEmployeeForm.get(ctc.ctcComponent).value);
            payload["fiscal"] = ctc.fiscal;
            payload["status"] = ctc.status;
            payload["notes"] = ctc.notes;
            payloadArray.push(payload);
        });
        this.employeeService.updateEmployeeCtc(payloadArray).subscribe(function (res) {
            _this.openSnackbar(res.message, "close");
            _this.resetEditEmployeeForm();
            _this.employeeService
                .getEmployeeCTCDetailsApi(payloadArray[0].employeeId, String(_this.fiscal))
                .subscribe(function (res) {
                _this.ctcDetails = true;
                _this.employeeCTCDetails = res;
            });
        }, function (err) {
            if (err.status === 500) {
                _this.openSnackbar("Some issue occurred, CTC is not updated. Please contact the backend team to resolve this.", "Close");
            }
            else {
                _this.openSnackbar(err.error.message, "Close");
            }
        });
        this.dialog.closeAll();
    };
    EmployeeDetailsComponent.prototype.getIsTooltipRequiredForCol = function (col) {
        return COLS_WHICH_REQUIRE_TOOLTIP.includes(col);
    };
    EmployeeDetailsComponent.prototype.downloadBulkUploadSampleExcel = function () {
        var snackbarRef = this.snackBarService.open("Close", 0);
        snackbarRef.progress = 1;
        snackbarRef.runProgressBar();
        this.employeeService.getEmployeeOnboardForm().subscribe(function (res) {
            var filename = res.headers
                .get("content-disposition")
                .split("filename=")[1];
            filename = filename.replace(/^["]/, "").replace(/["]$/, "");
            var url = window.URL.createObjectURL(res.body);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display: none");
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
            snackbarRef.progress = 100;
            snackbarRef.runProgressBar();
            setTimeout(function () {
                snackbarRef.closeSnackbar();
            }, 2000);
        }, function (error) {
            snackbarRef.progressText =
                "Error occurred in downloading sample excel file";
            setTimeout(function () {
                snackbarRef.closeSnackbar();
            }, 2000);
        });
    };
    EmployeeDetailsComponent.prototype.openSubmissionDialog = function (templateRef) {
        var _this = this;
        this.dialog.open(templateRef);
        this.editDialogRef.close();
        if (!Array.isArray(this.confirmValueList)) {
            this.confirmValueList = [];
        }
        this.confirmValueList = [];
        var index = 0;
        this.employeeCTCDetailsForEditDialog.forEach(function (_a) {
            var ctcComponent = _a.ctcComponent, amount = _a.amount;
            if (!_this.confirmValueList[index]) {
                _this.confirmValueList[index] = {
                    label: "",
                    oldValues: 0,
                    newValues: 0,
                };
            }
            _this.confirmValueList[index].label = ctcComponent;
            _this.confirmValueList[index].oldValues = amount;
            _this.confirmValueList[index].newValues =
                _this.editEmployeeForm.get(ctcComponent).value;
            index++;
        });
    };
    EmployeeDetailsComponent.prototype.openAgainEditDialog = function (templateRef, element) {
        this.editElement = element;
        this.editTemplateRef = templateRef;
        var dialogRef = this.dialog.open(templateRef, {
            width: "744px",
            panelClass: "edit-employee-dialog",
        });
        this.editDialogRef = dialogRef;
        this.selectedName = element.firstName + " " + element.lastName;
        this.selectedEmployeeCode = element.employeeCode
            ? element.employeeCode
            : "";
        this.selectedId = element.employeeId;
    };
    EmployeeDetailsComponent.prototype.onNoClick = function () {
        var _this = this;
        this.openAgainEditDialog(this.editTemplateRef, this.editElement);
        if (this.confirmValueList.length > 0) {
            this.confirmValueList.forEach(function (_a) {
                var label = _a.label, newValues = _a.newValues;
                _this.editEmployeeForm.get(label).patchValue(newValues);
            });
        }
    };
    EmployeeDetailsComponent.prototype.resetValues = function () {
        this.lwpNoOfDays = this.originalLwpNoOfDays;
    };
    EmployeeDetailsComponent.prototype.lwpChanges = function () {
        return this.lwpNoOfDays !== this.originalLwpNoOfDays;
    };
    EmployeeDetailsComponent.prototype.getAdjustedMonth = function () {
        var now = new Date();
        var month = now.getMonth();
        var date = now.getDate();
        if (date >= 25) {
            return month;
        }
        else {
            return month === 0 ? 11 : month - 1;
        }
    };
    EmployeeDetailsComponent.prototype.getAdjustedYear = function () {
        var now = new Date();
        var month = now.getMonth();
        var date = now.getDate();
        if (month > 0 || (month === 0 && date > 24)) {
            return now.getFullYear();
        }
        return now.getFullYear() - 1;
    };
    EmployeeDetailsComponent.prototype.showMonth = function (monthKey) {
        --monthKey;
        if (this.getAdjustedYear() === this.lwpYear &&
            monthKey > this.getAdjustedMonth())
            return false;
        return true;
    };
    EmployeeDetailsComponent.prototype.getMonthValueFromMonthName = function (lwpMonth) {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(this.months), _c = _b.next(); !_c.done; _c = _b.next()) {
                var month = _c.value;
                if (month.name === lwpMonth)
                    return month.value;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    EmployeeDetailsComponent.prototype.onYearChange = function () {
        if (this.lwpYear == this.getAdjustedYear()) {
            if (this.getMonthValueFromMonthName(this.lwpMonth) > this.getAdjustedMonth()) {
                this.lwpMonth = "January";
            }
        }
    };
    EmployeeDetailsComponent.prototype.closePersonalDetails = function () {
        this.isEditingPersonalDetails = false;
        this.getAllEmployeesDetails();
    };
    EmployeeDetailsComponent.prototype.onCloseEditScreen = function () {
        this.isEditingPersonalDetails = false;
    };
    EmployeeDetailsComponent.prototype.closeCtcDetails = function () {
        this.isEditingCtcDetails = false;
    };
    EmployeeDetailsComponent.prototype.onClosePfEditScreen = function () {
        this.isEditingCtcDetails = false;
    };
    EmployeeDetailsComponent.prototype.setIsScreenSizeLessThanMdBreakPoint = function () {
        this.isScreenSizeLessThanMdBreakPoint =
            window.innerWidth >= 768 ? false : true;
    };
    EmployeeDetailsComponent.prototype.editPersonalDetails = function (element) {
        this.isEditingPersonalDetails = true;
        this.selectedEmployeeDetails = element;
    };
    EmployeeDetailsComponent.prototype.openEditCtcDetials = function (element) {
        this.isEditingCtcDetails = true;
        this.selectedEmployeeDetails = tslib_1.__assign({}, element, { name: element.firstName + " " + element.lastName });
    };
    return EmployeeDetailsComponent;
}());
export { EmployeeDetailsComponent };
