import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  NgZone,
} from "@angular/core";
import { MatDialog, MatSidenav } from "@angular/material";
import { Router } from "@angular/router";
import {
  PagePermissions,
  PAGE_TO_GROUPS_MAPPING,
} from "../core/authorization/permissions";
import {
  ACTIVE_ICONS_DICTIONARY,
  DEFAULT_ICONS_DICTIONARY,
  HEADER_CONTENT_DICTIONARY,
} from "../dashboard/dashboard.utility";
import { LOCAL_STORAGE_KEYS, LoginService } from "../account/login.service";
import { AdminService } from "../shared/services/admin.service";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { PayrollDialogComponent } from "../payroll-dialog/payroll-dialog.component";
import { PayrollDialogComponentInput } from "../payroll-dialog/payroll-dialog.utility";
import { EmployeeService } from "../shared/services/employee.service";
import { take } from "rxjs/operators";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChild("appBody", { static: false })
  readonly appBodyRef: ElementRef<HTMLElement>;
  @ViewChild("sidenav", { static: false }) sidenavRef: MatSidenav;
  showNotifications = false;
  notifications: any = [];
  permissions: PagePermissions;
  urlForHeaderImage: string = "";
  HeaderContentText: string;
  employeeName: string;

  @HostListener("document:click", ["$event"]) onDocumentClick(event) {
    this.showNotifications = false;
  }

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private adminService: AdminService,
    private loginService: LoginService,
    private ngZone: NgZone,
    private employeeService: EmployeeService,
  ) {
    router.events.subscribe((ev) => {
      this.HeaderContentText = HEADER_CONTENT_DICTIONARY[this.router.url];
      this.urlForHeaderImage = this.router.url;
    });
  }
  ngAfterViewInit(): void {
    this.updateSidenavMode();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.updateSidenavMode();
  }

  updateSidenavMode() {
    // 992px is from bootstrap Large(lg) breakpoint
    if (window.innerWidth >= 992) {
      this.sidenavRef.mode = "side";
    } else {
      if (this.sidenavRef.opened) {
        this.sidenavRef.toggle();
      }
      this.sidenavRef.mode = "over";
    }
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.setEmployeePersonalDetails();
    this.permissions = PAGE_TO_GROUPS_MAPPING;
    this.setTimeBoundFeaturesConfigs();
    this.employeeName = this.getEmployeeName();
  }

  setEmployeePersonalDetails(): void {
    this.employeeService
      .getEmployeeProfessionalDetailsApi(
        this.loginService.employeeDetails.employeeId
      )
      .pipe(take(1))
      .subscribe(
        (empProfessionalDetails) => {
          this.employeeService.employeePersonalDetails = empProfessionalDetails;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  getEmployeeName(): string {
    const employeeDetails = this.loginService.employeeDetails;
    return `${employeeDetails.firstName} ${employeeDetails.lastName}`;
  }

  openNotifications(event) {
    this.showNotifications = !this.showNotifications;
    event.stopPropagation();
    if (this.showNotifications) {
    }
  }

  openLogout() {
    let dialogRef = this.dialog.open(PayrollDialogComponent, {
      data: {
        title: "Confirmation",
        msg: `Do you want to logout?`,
        dialogType: "logout",
        onYesClickFunction: () => this.signOut(),
      } as PayrollDialogComponentInput,
    });
  }

  signOut(): void {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.userGroups);
    sessionStorage.removeItem(LOCAL_STORAGE_KEYS.userLoginInfo);

    this.dialog.closeAll();

    this.ngZone.run(() => {
      this.router.navigate(["/login"]);
    });
  }

  getIcon(route: string): string {
    if (this.router.url === route) {
      return ACTIVE_ICONS_DICTIONARY[route];
    } else {
      return DEFAULT_ICONS_DICTIONARY[route];
    }
  }

  setTimeBoundFeaturesConfigs() {
    this.adminService
      .getAllTimeBoundFeaturesConfigurationsApi(
        getCurrentFiscalYear().toString()
      )
      .subscribe((featuresConfigs) => {
        this.loginService.timeBoundFeaturesConfigs = featuresConfigs;
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.time_bound_features_configurations,
          JSON.stringify(featuresConfigs)
        );
      });
  }
}
