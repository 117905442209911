import { HttpHeaders } from "@angular/common/http";
import { NgZone } from "@angular/core";
import { of, throwError } from "rxjs";
import { catchError, mergeMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@azure/msal-angular";
import * as i3 from "@angular/router";
import * as i4 from "../shared/services/spinner.service";
import * as i5 from "../shared/services/employee.service";
export var LOCAL_STORAGE_KEYS = {
    userGroups: "userGroups",
    time_bound_features_configurations: "time_bound_features_configurations",
    userLoginInfo: "userLoginInfo",
    employeeDetails: "employeeDetails",
};
var LoginService = /** @class */ (function () {
    function LoginService(httpClient, authService, router, spinnerService, employeeService, ngZone) {
        this.httpClient = httpClient;
        this.authService = authService;
        this.router = router;
        this.spinnerService = spinnerService;
        this.employeeService = employeeService;
        this.ngZone = ngZone;
        this.path = "";
        this.BEARER = "Bearer ";
        this.APPID = "93e0458e-9706-4981-a353-523734594929";
        if (localStorage.getItem(LOCAL_STORAGE_KEYS.userGroups)) {
            this._userGroups = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.userGroups));
        }
        if (localStorage.getItem(LOCAL_STORAGE_KEYS.employeeDetails)) {
            this._employeeDetails = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.employeeDetails));
        }
    }
    Object.defineProperty(LoginService.prototype, "userGroups", {
        get: function () {
            return this._userGroups;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginService.prototype, "employeeDetails", {
        get: function () {
            return this._employeeDetails;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Api to get access token from token minting service.
     * @param idToken id token fetched from azure login
     * @returns token details
     */
    LoginService.prototype.tokenExchangeApi = function (idToken) {
        var headers = new HttpHeaders({ Authorization: this.BEARER + idToken });
        this.path = "https://tokenmintingsvc.geminisolutions.com/token/exchange/" + this.APPID;
        return this.httpClient.get(this.path, { headers: headers, responseType: "text" });
    };
    LoginService.prototype.silentRefresh = function () {
        var _this = this;
        var clientId = environment.clientId;
        var account = JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_KEYS.userLoginInfo))
            ? JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_KEYS.userLoginInfo))
                .account
            : null;
        if (account) {
            var silentRequest = {
                scopes: ["openid", "email", "api://" + clientId + "/payroll"],
                account: account,
            };
            return this.authService.acquireTokenSilent(silentRequest).pipe(tap(function (res) {
                sessionStorage.setItem("payrollAccessToken", res.accessToken);
                sessionStorage.setItem("user", JSON.stringify(res));
            }), catchError(function (err) {
                return throwError(err);
            }));
        }
        else {
            this.ngZone.run(function () {
                _this.router.navigate(["login"]);
            });
        }
    };
    LoginService.prototype.addAuthHeader = function (req) {
        var accessToken = sessionStorage.getItem("payrollAccessToken");
        return req.clone({
            setHeaders: { Authorization: "Bearer " + accessToken },
        });
    };
    LoginService.prototype.getUserGroups = function (token) {
        var groups = JSON.parse(atob(token.split(".")[1])).groups;
        return groups;
    };
    LoginService.prototype.login = function () {
        var _this = this;
        var clientId = environment.clientId;
        this.spinnerService.openSpinnerDialog();
        this.authService
            .loginPopup({ scopes: ["openid", "email", "api://" + clientId + "/payroll"] })
            .pipe(mergeMap(function (result) {
            //Setting access token in session storage from tokenExchangeApi
            sessionStorage.setItem("payrollAccessToken", result.accessToken);
            _this._userGroups = _this.getUserGroups(result.accessToken);
            localStorage.setItem(LOCAL_STORAGE_KEYS.userGroups, JSON.stringify(_this.userGroups));
            sessionStorage.setItem(LOCAL_STORAGE_KEYS.userLoginInfo, JSON.stringify(result));
            return _this.employeeService
                .getEmployeeDetailsByEmailApi(result.account.username)
                .pipe(catchError(function (error) {
                _this.spinnerService.closeSpinnerDialog();
                sessionStorage.removeItem(LOCAL_STORAGE_KEYS.userLoginInfo);
                console.error("Employee Details API error:", error);
                _this.ngZone.run(function () {
                    _this.router.navigate(["/user-not-found"]);
                });
                return of(null);
            }));
        }), catchError(function (error) {
            _this.spinnerService.closeSpinnerDialog();
            console.error("Login API error:", error);
            return of(null);
        }))
            .subscribe(function (res) {
            if (res) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.employeeDetails, JSON.stringify(res));
                _this._employeeDetails = res;
                _this.spinnerService.closeSpinnerDialog();
                _this.ngZone.run(function () {
                    _this.router.navigate(["/dashboard/home"]);
                });
            }
        });
    };
    LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.MsalService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.SpinnerService), i0.ɵɵinject(i5.EmployeeService), i0.ɵɵinject(i0.NgZone)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
