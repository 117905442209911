<div class="card">
  <div
    class="p-3 gem-text-dark card-heading"
  >
    {{ amountType}}
  </div>
  <div
    class="d-flex justify-content-end gem-surface-secondary-50"
  >
    <span class="p-3 text gem-text-medium">Amount in (&#8377;)</span>
  </div>
  <div class="earnings-deductions-container">
    <div class="items">
      <div *ngFor="let data of income; let last=last ; trackBy: trackByIncomeComponent">
        <div class="pl-3 pt-3 grid-container" [ngClass]="{'pb-3': last}">
          <div class="imp-sub-text gem-text-medium">
            {{ data?.label }}
          </div>
          <div class="imp-sub-text gem-text-medium px-3">{{ data?.amount| currency : "INR" : "" }}</div>
        </div>
      </div>
    </div>
    <div class="p-3 py-3 grid-container gem-surface-light total-border">
      <div class="total gem-text-dark ">{{ total[0]?.label }}</div>
      <div class="total gem-text-dark ">
        {{ total[0]?.amount | currency : "INR" : "" }}
      </div>
    </div>
  </div>
</div>
