import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, } from "@angular/core";
import { FormControl, Validators, } from "@angular/forms";
import { of } from "rxjs";
import { catchError, finalize, mergeMap } from "rxjs/operators";
var EmployeeEarningsComponent = /** @class */ (function () {
    function EmployeeEarningsComponent(payrollService, formBuilder, dialog) {
        this.payrollService = payrollService;
        this.formBuilder = formBuilder;
        this.dialog = dialog;
        this.earningsFormStatus = new EventEmitter();
        this.allEarningComponents = [];
        this.employeeEarningsList = [];
        this.filteredEarningComponents = [];
        this.showSpinner = true;
    }
    EmployeeEarningsComponent.prototype.ngOnInit = function () {
        this.getEmployeeEarningsKeys();
        this.earningsFormGroup = this.formBuilder.group({});
        this.addEarningFormGroup = this.formBuilder.group({
            component: ["", Validators.required],
            amount: ["", [Validators.required, Validators.min(1)]],
        });
    };
    EmployeeEarningsComponent.prototype.getEmployeeEarningsKeys = function () {
        var _this = this;
        this.showSpinner = true;
        this.payrollService
            .getEmployeeEarningsKeysApi()
            .pipe(mergeMap(function (keysResponse) {
            _this.allEarningComponents = keysResponse;
            return _this.payrollService
                .getEmployeeMonthlyEarningsApi(_this.selectedPayslipMonth, _this.selectedPayslipYear, _this.element.employeeId)
                .pipe(catchError(function () { return of([]); }));
        }), catchError(function () { return of([]); }), finalize(function () {
            _this.showSpinner = false;
        }))
            .subscribe(function (result) {
            _this.employeeEarningsList = result.length ? result : [];
            _this.initializeAndPatchForm(result);
        });
    };
    EmployeeEarningsComponent.prototype.initializeAndPatchForm = function (data) {
        var _this = this;
        var controls = {};
        data.forEach(function (earning) {
            controls[earning.component] = new FormControl(null, [
                Validators.required,
                Validators.min(1),
            ]);
        });
        this.earningsFormGroup = this.formBuilder.group(controls);
        var patchValues = {};
        data.forEach(function (item) {
            if (_this.earningsFormGroup.controls[item.component]) {
                patchValues[item.component] = item.amount;
            }
        });
        this.earningsFormGroup.patchValue(patchValues);
        this.earningsFormGroup.valueChanges.subscribe(function () {
            if (_this.earningsFormGroup.dirty) {
                _this.emitFormStatus();
            }
        });
        this.emitFormStatus();
    };
    EmployeeEarningsComponent.prototype.emitFormStatus = function () {
        var formValues = this.earningsFormGroup.value;
        this.earningsFormStatus.emit({
            hasValues: Object.values(formValues).some(function (value) { return value !== null && value !== ""; }),
            formData: this.preparePayload(formValues),
            touched: this.earningsFormGroup.dirty,
            empty: Object.values(formValues).every(function (value) { return value === null || value === ""; }),
            invalid: this.earningsFormGroup.invalid,
        });
    };
    EmployeeEarningsComponent.prototype.preparePayload = function (formValues) {
        var _this = this;
        return Object.keys(formValues)
            .filter(function (key) { return Number(formValues[key]) > 0; })
            .map(function (key) { return ({
            employeeId: _this.element.employeeId,
            year: _this.selectedPayslipYear,
            component: key,
            amount: Number(formValues[key]),
            month: _this.selectedPayslipMonth,
            fiscal: "" + _this.selectedPayslipYear,
        }); });
    };
    EmployeeEarningsComponent.prototype.openAddEarningModal = function () {
        var _this = this;
        this.filteredEarningComponents = this.allEarningComponents.filter(function (component) {
            return !_this.employeeEarningsList.some(function (earning) { return earning.component === component.optionValue; });
        });
        this.addEarningFormGroup.reset();
        if (this.filteredEarningComponents.length === 0) {
            this.addEarningFormGroup.get("amount").disable();
        }
        else {
            this.addEarningFormGroup.get("amount").enable();
        }
        this.dialog.open(this.addEarningModal, { width: "400px" });
    };
    EmployeeEarningsComponent.prototype.updateEarningsWithIds = function (data) {
        this.employeeEarningsList = this.employeeEarningsList.map(function (el) {
            if (!el.monthlyEarningsId) {
                var matchedElement = data.find(function (element) { return el.component === element.component; });
                if (matchedElement) {
                    return tslib_1.__assign({}, el, { monthlyEarningsId: matchedElement.monthlyEarningsId });
                }
            }
            return el;
        });
    };
    EmployeeEarningsComponent.prototype.addControlToEarningsForm = function () {
        if (this.addEarningFormGroup.invalid) {
            return;
        }
        var newEarning = {
            employeeId: this.element.employeeId,
            year: this.selectedPayslipYear,
            component: this.addEarningFormGroup.value.component,
            amount: Number(this.addEarningFormGroup.value.amount),
            month: this.selectedPayslipMonth,
            fiscal: "" + this.selectedPayslipYear,
        };
        this.employeeEarningsList.push(newEarning);
        this.earningsFormGroup.addControl(newEarning.component, new FormControl(newEarning.amount, [
            Validators.required,
            Validators.min(1),
        ]));
        this.earningsFormGroup.markAsDirty();
        this.emitFormStatus();
        this.dialog.closeAll();
    };
    EmployeeEarningsComponent.prototype.deleteEarning = function (earningId, optionValue) {
        var _this = this;
        if (!earningId) {
            this.employeeEarningsList = this.employeeEarningsList.filter(function (earning) { return earning.component !== optionValue; });
            this.earningsFormGroup.removeControl(optionValue);
            this.emitFormStatus();
            return;
        }
        this.payrollService.deleteEmployeeMonthlyEarningsApi(earningId).subscribe(function () {
            _this.employeeEarningsList = _this.employeeEarningsList.filter(function (earning) { return earning.monthlyEarningsId !== earningId; });
            _this.earningsFormGroup.removeControl(optionValue);
            _this.emitFormStatus();
        }, function (error) { return console.error("Error deleting earning:", error); });
    };
    return EmployeeEarningsComponent;
}());
export { EmployeeEarningsComponent };
