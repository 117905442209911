import { Component } from "@angular/core";

@Component({
  selector: "app-user-payroll",
  templateUrl: "./user-payroll.component.html",
  styleUrls: ["./user-payroll.component.scss"],
})
export class UserPayrollComponent {
  selectedPayslipMonth: number;
  months: { [month: number]: string } = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  constructor() {
    this.selectedPayslipMonth = new Date().getMonth();
  }

  receiveData(data: any) {
    this.selectedPayslipMonth = data;
  }
}
