import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export function addHouse(
  formGroup: FormGroup,
  houses: any[],
  formBuilder: FormBuilder
): void {
  const houseCount = houses.length + 1;
  const houseGroupName = "House " + houseCount;
  const houseGroup = formBuilder.group({
    fromMonth: [null, Validators.required],
    toMonth: [null, Validators.required],
    monthlyRent: [null, Validators.required],
    metroCity: [false],
  });
  formGroup.addControl(houseGroupName, houseGroup);
  houses.push({});
}

export function removeHouse(houses: any[]): void {
  houses.pop();
}

export const PF_STRING = "PF";

export interface InvestmentDeclarationSectionInfo {
  optionDesc: string;
  optionId: number;
  optionKey: string;
  optionName: string;
  optionValue: string;
}

export interface InvestmentSectionCardData {
  name: string;
  limit?: number;
  totalAmountEntered: number;
  tooltip: string;
}

export interface SectionLimit {
  section: string;
  investmentLimit: number;
}
