import { Component, OnInit, ViewChild } from "@angular/core";
import { YtdDetailsService } from "../shared/services/ytd-details.service";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { SnackBarService } from "../shared/services/snackbar.service";

type EmpYtdComponentData = {
  [index: string]: {
    incomeData: YtdComponentTableData[];
    deductionsData: YtdComponentTableData[];
  };
};

/*Properties are initialized so that Object.keys(new AllEmployees()) return an array having columns in
desired order.*/
class AllEmployeesYtdTableData {
  expandAction = true;
  name = "";
  email = "";
  uanNo = "";
  totalIncome = 0;
  totalDeductions = 0;
  netPay = 0;
  days = 0;
  constructor(name = "", email = "", uanNo = "N/A") {
    this.name = name;
    this.email = email;
    this.uanNo = uanNo;
  }
}

class YtdComponentTableData {
  categories = "";
  april = 0;
  may = 0;
  june = 0;
  july = 0;
  august = 0;
  september = 0;
  october = 0;
  november = 0;
  december = 0;
  january = 0;
  february = 0;
  march = 0;

  constructor(catagories = "") {
    this.categories = catagories;
  }
}

const INCOME = 0;
const DEDUCTIONS = 1;
const NET_PAY = 2;
const DAYS = 3;
const TOTAL_INCOME_STRING = "Total Income";
const TOTAL_DEDUCTIONS_STRING = "Total Deductions";
const TOTAL_STRING = "TOTAL";
const COMPONENTS_STRING = "components";
const YTD_MODEL_LIST_STRING = "ytdModelList";
const TOTAL_WORKING_DAYS = "WorkingDays";
const TOTAL_DAYS_STRING = "Total Working Days";
const NET_PAY_STRING = "Net Pay";

@Component({
  selector: "app-ytd-details-all-employees",
  templateUrl: "./ytd-details-all-employees.component.html",
  styleUrls: ["./ytd-details-all-employees.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class YtdDetailsAllEmployeesComponent implements OnInit {
    allEmployeesYtdDetails: MatTableDataSource<AllEmployeesYtdTableData>;
    displayedColumns = Object.keys(new AllEmployeesYtdTableData());
    expandedElement: AllEmployeesYtdTableData | null = null;
    empYtdComponentsTableData: YtdComponentTableData;
    fiscal: any = getCurrentFiscalYear();
    years: number[] = [];
    searchInputValue: string;
    columnDisplayNames = {
        income: "Income",
        deductions: "Deductions",
        april: "Apr",
        may: "May",
        june: "Jun",
        july: "Jul",
        august: "Aug",
        september: "Sep",
        october: "Oct",
        november: "Nov",
        december: "Dec",
        january: "Jan",
        february: "Feb",
        march: "Mar",
    };
    tooltipForColumn = {
        name: true,
        email: true,
        employeeId: true,
        uanNo: true,
    };
    showSpinner:boolean=true;

    s = Object.keys(new YtdComponentTableData());
    incomeCols = ["income", ...Object.keys(new YtdComponentTableData())];
    deductionsCols = [
        "deductions",
        ...Object.keys(new YtdComponentTableData()),
    ];
    @ViewChild(MatPaginator, { static: false })
    set paginator(value: MatPaginator) {
        if (this.allEmployeesYtdDetails) {
            this.allEmployeesYtdDetails.paginator = value;
        }
    }
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    private searchText$ = new Subject<string>();
    constructor(private ytdDetailsService: YtdDetailsService, private snackBarService:SnackBarService) {}

    ngOnInit() {
        this.searchText$
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((value) =>
                    this.ytdDetailsService.getYtdDetailsForAllEmployeeAPI(
                        this.fiscal,
                        value
                    )
                )
            )
            .subscribe((res) => {
                this.allEmployeesYtdDetails =
                    new MatTableDataSource<AllEmployeesYtdTableData>(
                        this.convertToAllEmployeeYtdTableDataArray(res)
                    );
                this.allEmployeesYtdDetails.sort = this.sort;
                this.allEmployeesYtdDetails.paginator = this.paginator;
            });
        this.years = Array.from(
            new Array(11),
            (x, i) => getCurrentFiscalYear() - i
        );
        this.getYtdDetailsForAllEmployees(this.fiscal);
        this.incomeCols.splice(1, 1);
        this.deductionsCols.splice(1, 1);
    }

  onSearch($event: any) {
    const value = $event.target.value;
    this.searchText$.next(value);
  }

  generateNameFromEmail(email: string): string {
    const nameArray = email.split("@")[0].split(".");
    if (nameArray.length === 1) {
      return this.capitalizeFirstLetter(nameArray[0]);
    }
    return `${this.capitalizeFirstLetter(
      nameArray[0]
    )} ${this.capitalizeFirstLetter(nameArray[1])}`;
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  convertToAllEmployeeYtdTableDataArray(data): AllEmployeesYtdTableData[] {
    const convertedData: AllEmployeesYtdTableData[] = [];
    for (const emp of data) {
      const email = emp.employeeId;
      const tableDataRow = new AllEmployeesYtdTableData(
        `${this.capitalizeFirstLetter(
          emp.firstName
        )} ${this.capitalizeFirstLetter(emp.lastName)}`,
        email,
        emp.uanNo
      );
      if (emp[YTD_MODEL_LIST_STRING].length !== 0) {
        const totalIncomeComponent =
          emp[YTD_MODEL_LIST_STRING][INCOME][COMPONENTS_STRING][
            TOTAL_INCOME_STRING
          ];
        const totalDeductionsComponent =
          emp[YTD_MODEL_LIST_STRING][DEDUCTIONS][COMPONENTS_STRING][
            TOTAL_DEDUCTIONS_STRING
          ];
        const totalWorkingDays =
          emp[YTD_MODEL_LIST_STRING][DAYS][COMPONENTS_STRING][
            TOTAL_WORKING_DAYS
          ];
        const netPay =
          emp[YTD_MODEL_LIST_STRING][NET_PAY][COMPONENTS_STRING][
            NET_PAY_STRING
          ];
        /*This check is added because some test employees have undefined components*/
        if (
          totalIncomeComponent &&
          totalDeductionsComponent &&
          totalWorkingDays
        ) {
          for (const el of totalIncomeComponent) {
            if (el.payrollKey === TOTAL_STRING) {
              tableDataRow.totalIncome = el.amount;
            }
          }
          for (const el of totalDeductionsComponent) {
            if (el.payrollKey === TOTAL_STRING) {
              tableDataRow.totalDeductions = el.amount;
            }
          }
          for (const el of totalWorkingDays) {
            if (el.payrollKey === TOTAL_DAYS_STRING) {
              tableDataRow.days = el.amount;
            }
          }
          for (const el of netPay) {
            if (el.payrollKey === TOTAL_STRING) {
              tableDataRow.netPay = el.amount;
            }
          }
        }
      } else {
        tableDataRow.totalIncome = 0;
        tableDataRow.totalDeductions = 0;
        tableDataRow.days = 0;
        tableDataRow.netPay = 0;
      }
      convertedData.push(tableDataRow);
      this.empYtdComponentsTableData = {
        ...this.empYtdComponentsTableData,
        ...this.convertToYtdComponentsTableDataArray(emp, email),
      };
    }
    return convertedData;
  }

  convertToYtdComponentsTableDataArray(
    empData,
    empEmail: string
  ): EmpYtdComponentData {
    const empYtdComponentsData: EmpYtdComponentData = {
      [empEmail]: {
        incomeData: [],
        deductionsData: [],
      },
    };
    /*Setting value for Income components.*/
    if (empData[YTD_MODEL_LIST_STRING].length !== 0) {
      for (const component in empData[YTD_MODEL_LIST_STRING][INCOME][
        COMPONENTS_STRING
      ]) {
        const incomeData = new YtdComponentTableData(component);
        for (const el of empData[YTD_MODEL_LIST_STRING][INCOME][
          COMPONENTS_STRING
        ][component]) {
          incomeData[el.payrollKey.toLowerCase()] = el.amount;
        }
        empYtdComponentsData[empEmail].incomeData.push(incomeData);
      }
      /*Setting value for Deduction components.*/
      for (const component in empData[YTD_MODEL_LIST_STRING][DEDUCTIONS][
        COMPONENTS_STRING
      ]) {
        const deductionsData = new YtdComponentTableData(component);
        for (const el of empData[YTD_MODEL_LIST_STRING][DEDUCTIONS][
          COMPONENTS_STRING
        ][component]) {
          deductionsData[el.payrollKey.toLowerCase()] = el.amount;
        }
        empYtdComponentsData[empEmail].deductionsData.push(deductionsData);
      }
    }

    return empYtdComponentsData;
  }

    getYtdDetailsForAllEmployees(
        fiscal: string = this.fiscal,
        searchText: string = this.searchInputValue
    ) {
        this.ytdDetailsService
            .getYtdDetailsForAllEmployeeAPI(fiscal, searchText)
            .subscribe((res) => {
                this.allEmployeesYtdDetails =
                    new MatTableDataSource<AllEmployeesYtdTableData>(
                        this.convertToAllEmployeeYtdTableDataArray(res)
                    );
                this.allEmployeesYtdDetails.sort = this.sort;
                this.allEmployeesYtdDetails.paginator = this.paginator;
            },
        (err)=>{
            this.snackBarService.add({
                message: `It's taking a bit long than expected, please try to reload the page.`,
                action: "close",
                config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
              });
            this.showSpinner = false;
        });
    }

  showTooltip(col: string): boolean {
    return this.tooltipForColumn[col];
  }
}
