import { PipeTransform } from '@angular/core';
var CamelCaseToTitlePipe = /** @class */ (function () {
    function CamelCaseToTitlePipe() {
    }
    CamelCaseToTitlePipe.prototype.transform = function (value) {
        // Add a space before each capital letter and capitalize the first letter
        return value.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
    };
    return CamelCaseToTitlePipe;
}());
export { CamelCaseToTitlePipe };
