var UserPayrollComponent = /** @class */ (function () {
    function UserPayrollComponent() {
        this.months = {
            1: "January",
            2: "February",
            3: "March",
            4: "April",
            5: "May",
            6: "June",
            7: "July",
            8: "August",
            9: "September",
            10: "October",
            11: "November",
            12: "December",
        };
        this.selectedPayslipMonth = new Date().getMonth();
    }
    UserPayrollComponent.prototype.receiveData = function (data) {
        this.selectedPayslipMonth = data;
    };
    return UserPayrollComponent;
}());
export { UserPayrollComponent };
