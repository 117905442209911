import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit, } from "@angular/core";
import { PayrollDialogComponent } from "src/app/payroll-dialog/payroll-dialog.component";
import { PF_STRING, addHouse, removeHouse, } from "../investment-declaration.util";
var AddInvestmentComponent = /** @class */ (function () {
    function AddInvestmentComponent(dialog, snackBarService, formBuilder) {
        this.dialog = dialog;
        this.snackBarService = snackBarService;
        this.formBuilder = formBuilder;
        this.isAddingInvestmentToggleEvent = new EventEmitter();
        this.openNextSectionEvent = new EventEmitter();
        this.saveFormEvent = new EventEmitter();
        this.loadSavedFormEvent = new EventEmitter();
        this.updateSectionTotalAmount = new EventEmitter();
        this.resetSectionAmount = new EventEmitter();
        this.onRemoveHouseClickEmitter = new EventEmitter();
        this.inputLimitMap = new Map();
    }
    AddInvestmentComponent.prototype.ngOnDestroy = function () {
        this.resetAppContentWrapperStyle();
    };
    AddInvestmentComponent.prototype.ngOnInit = function () {
        this.changeAppContentWrapperStyle();
        this.saveInitialValues();
        this.initializeInputLimitMapValues();
    };
    AddInvestmentComponent.prototype.initializeInputLimitMapValues = function () {
        var _this = this;
        this.sectionInputs.forEach(function (sectionInputName) {
            _this.inputLimitMap.set(sectionInputName, false);
        });
    };
    AddInvestmentComponent.prototype.updateSectionInputLimitValue = function (sectionInputName) {
        if (this.getSectionFormGroup(this.sectionName).get(sectionInputName).value
            .length === 8) {
            this.inputLimitMap.set(sectionInputName, true);
        }
        else {
            this.inputLimitMap.set(sectionInputName, false);
        }
    };
    AddInvestmentComponent.prototype.saveInitialValues = function () {
        this.initialValues = this.getSectionFormGroup(this.sectionName).value;
    };
    AddInvestmentComponent.prototype.changeAppContentWrapperStyle = function () {
        var appContentWrapperDiv = document.querySelector("#appContentWrapper");
        appContentWrapperDiv.style.paddingTop = "0";
        appContentWrapperDiv.style.backgroundColor = "white";
        appContentWrapperDiv.style.height = "calc(100% - 70px)";
    };
    AddInvestmentComponent.prototype.resetAppContentWrapperStyle = function () {
        var appContentWrapperDiv = document.querySelector("#appContentWrapper");
        appContentWrapperDiv.style.paddingTop = "24px";
        appContentWrapperDiv.style.backgroundColor = "";
        appContentWrapperDiv.style.height = "auto";
    };
    /**
     * Calculates numbers of rows required in a section dropdown based on number of inputs in that section.
     * @param noOfInputs Number of inputs in a section.
     * @returns Array of numbers containing elements from 0 to calculated required number of rows - 1.
     */
    AddInvestmentComponent.prototype.getNumberOfRowsArray = function (noOfInputs) {
        var itemsPerRow = 4;
        var arrLength = noOfInputs % itemsPerRow === 0
            ? noOfInputs / itemsPerRow
            : Math.ceil(noOfInputs / itemsPerRow);
        return Array.from({ length: arrLength }, function (_, index) { return index; });
    };
    AddInvestmentComponent.prototype.trackByFn = function (index, item) {
        return index;
    };
    AddInvestmentComponent.prototype.onRestButtonClick = function () {
        this.resetSectionValuesAndTotalAmount();
        this.snackBarService.add({
            message: "Reset successful",
            config: { duration: 2000 },
        });
    };
    AddInvestmentComponent.prototype.removeAddedHousesFromForm = function () {
        var _this = this;
        var updatedValues = this.getSectionFormGroup(this.sectionName).value;
        var initialKeys = Object.keys(this.initialValues);
        var updatedKeys = Object.keys(updatedValues);
        if (updatedKeys.length !== initialKeys.length) {
            var newHouses = updatedKeys.filter(function (el) { return !initialKeys.includes(el); });
            newHouses.forEach(function (house) {
                removeHouse(_this.houses);
                _this.getSectionFormGroup("HRA").removeControl(house);
            });
            return true;
        }
    };
    AddInvestmentComponent.prototype.resetAndShowCards = function () {
        // if (this.sectionName === "HRA") {
        //   this.removeAddedHousesFromForm();
        // }
        this.resetSectionValuesAndTotalAmountToInitialValues();
        this.showAllSectionsCards();
    };
    AddInvestmentComponent.prototype.resetSectionValuesAndTotalAmountToInitialValues = function () {
        var sectionFormGroup = this.getSectionFormGroup(this.sectionName);
        if (this.sectionName === "HRA") {
            sectionFormGroup.controls = {};
            this.houses.length = 0;
            for (var key in this.initialValues) {
                addHouse(sectionFormGroup, this.houses, this.formBuilder);
            }
            sectionFormGroup.setValue(this.initialValues);
        }
        else {
            sectionFormGroup.setValue(this.initialValues);
        }
        this.updateSectionTotalAmount.emit(this.sectionName);
    };
    AddInvestmentComponent.prototype.resetSectionValuesAndTotalAmount = function () {
        var sectionFormGroup = this.getSectionFormGroup(this.sectionName);
        if (this.sectionName === "HRA") {
            sectionFormGroup.controls = {};
            this.houses.length = 0;
            addHouse(sectionFormGroup, this.houses, this.formBuilder);
            this.hraFormComponent.initSetup();
        }
        else {
            var pfValue = sectionFormGroup.value["PF"];
            sectionFormGroup.reset();
            sectionFormGroup.patchValue({ PF: pfValue });
        }
        this.updateSectionTotalAmount.emit(this.sectionName);
    };
    AddInvestmentComponent.prototype.showAllSectionsCards = function () {
        this.isAddingInvestmentToggleEvent.emit(this.sectionName);
    };
    AddInvestmentComponent.prototype.openNextSection = function () {
        this.openNextSectionEvent.emit(this.sectionName);
    };
    AddInvestmentComponent.prototype.onBackClick = function () {
        if (this.isAnyValueChanged()) {
            this.openConfirmationDialogOnBackClick();
        }
        else {
            this.showAllSectionsCards();
        }
    };
    AddInvestmentComponent.prototype.openConfirmationDialogOnBackClick = function () {
        var _a;
        var _this = this;
        this.dialog.open(PayrollDialogComponent, (_a = {},
            _a["data"] = {
                title: "Confirmation",
                msg: "Are you sure you want to go back without saving your changes?",
                dialogType: "post",
                onYesClickFunction: function () { return _this.onSaveButtonClick(); },
                onNoClickFunction: function () { return _this.resetAndShowCards(); },
                noButtonText: "Back without saving",
                yesButtonText: "Save as draft",
            },
            _a));
    };
    AddInvestmentComponent.prototype.onSaveButtonClick = function () {
        this.saveFormEvent.emit();
        this.showAllSectionsCards();
    };
    AddInvestmentComponent.prototype.onSaveAndNextButtonClick = function () {
        this.saveFormEvent.emit();
        this.openNextSection();
    };
    AddInvestmentComponent.prototype.isConvertibleToNumber = function (value) {
        if (value.trim() === "") {
            return false;
        }
        return !isNaN(Number(value));
    };
    AddInvestmentComponent.prototype.onKeyUp = function (event, sectionInputName) {
        if (event.key === "Backspace" || this.isConvertibleToNumber(event.key)) {
            if (this.sectionName !== "HRA") {
                this.updateSectionInputLimitValue(sectionInputName);
            }
            this.updateSectionTotalAmount.emit(this.sectionName);
        }
    };
    AddInvestmentComponent.prototype.emitUpdateSectionTotalAmountForHRA = function () {
        this.updateSectionTotalAmount.emit("HRA");
    };
    AddInvestmentComponent.prototype.getSectionFormGroup = function (formGroupName) {
        return this.parentFormGroup.get("investmentSections").get(formGroupName);
    };
    AddInvestmentComponent.prototype.isAnyValueChanged = function () {
        var e_1, _a, e_2, _b;
        var updatedValues = this.getSectionFormGroup(this.sectionName).value;
        var initialKeys = Object.keys(this.initialValues);
        if (this.sectionName !== "HRA") {
            try {
                for (var initialKeys_1 = tslib_1.__values(initialKeys), initialKeys_1_1 = initialKeys_1.next(); !initialKeys_1_1.done; initialKeys_1_1 = initialKeys_1.next()) {
                    var key = initialKeys_1_1.value;
                    if (updatedValues[key] !== this.initialValues[key]) {
                        return true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (initialKeys_1_1 && !initialKeys_1_1.done && (_a = initialKeys_1.return)) _a.call(initialKeys_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        else {
            var updatedKeys = Object.keys(updatedValues);
            if (updatedKeys.length !== initialKeys.length) {
                return true;
            }
            else {
                try {
                    for (var initialKeys_2 = tslib_1.__values(initialKeys), initialKeys_2_1 = initialKeys_2.next(); !initialKeys_2_1.done; initialKeys_2_1 = initialKeys_2.next()) {
                        var key = initialKeys_2_1.value;
                        for (var control in this.initialValues[key]) {
                            var initControlValue = this.initialValues[key][control];
                            var updatedControlValue = this.getSectionFormGroup(this.sectionName)
                                .get(key)
                                .get(control).value;
                            if (initControlValue !== updatedControlValue) {
                                return true;
                            }
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (initialKeys_2_1 && !initialKeys_2_1.done && (_b = initialKeys_2.return)) _b.call(initialKeys_2);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        return false;
    };
    AddInvestmentComponent.prototype.onRemoveHouseClick = function (index) {
        this.onRemoveHouseClickEmitter.emit(index);
        this.updateSectionTotalAmount.emit(this.sectionName);
    };
    AddInvestmentComponent.prototype.rowHasInputLimitMessage = function (rowNumber) {
        var e_3, _a;
        var rowInputs = this.sectionInputs.slice(rowNumber * 4, (rowNumber + 1) * 4);
        try {
            for (var rowInputs_1 = tslib_1.__values(rowInputs), rowInputs_1_1 = rowInputs_1.next(); !rowInputs_1_1.done; rowInputs_1_1 = rowInputs_1.next()) {
                var sectionInputName = rowInputs_1_1.value;
                if (this.inputLimitMap.get(sectionInputName)) {
                    return true;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (rowInputs_1_1 && !rowInputs_1_1.done && (_a = rowInputs_1.return)) _a.call(rowInputs_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return false;
    };
    AddInvestmentComponent.prototype.isDisabled = function (componentName) {
        return componentName === PF_STRING ? true : this.isFormSubmitted;
    };
    return AddInvestmentComponent;
}());
export { AddInvestmentComponent };
