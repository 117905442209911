import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit, } from "@angular/core";
import { combineLatest, of } from "rxjs";
import { catchError, finalize, mergeMap } from "rxjs/operators";
import { PayrollDialogComponent } from "src/app/payroll-dialog/payroll-dialog.component";
import { EmployeeEarningsComponent } from "./employee-earnings/employee-earnings.component";
import { EmployeeDeductionsComponent } from "./employee-deductions/employee-deductions.component";
var EarningsDeductionsComponent = /** @class */ (function () {
    function EarningsDeductionsComponent(payrollService, snackBarService, dialog, spinnerService) {
        this.payrollService = payrollService;
        this.snackBarService = snackBarService;
        this.dialog = dialog;
        this.spinnerService = spinnerService;
        // @Output() openEarningsAndDeductionsComponent = new EventEmitter<{
        //   openEarningsAndDeductions: boolean;
        //   isPayslipTriggered: boolean;
        // }>();
        this.openEarningsAndDeductionsComponent = new EventEmitter();
        this.refreshTable = new EventEmitter();
        this.newItemsArray = [];
        this.sectionName = "Earnings";
        this.selectedIndex = 0;
        this.earningsFormStatus = {
            hasValues: false,
            formData: [],
            touched: false,
            empty: false,
            invalid: false,
        };
        this.deductionsFormStatus = {
            hasValues: false,
            formData: [],
            touched: false,
            empty: false,
            invalid: false,
        };
        this.earningsExecuted = false;
        this.deductionsExecuted = false;
        this.lwpExecuted = false;
        this.isPayslipTriggered = false;
        this.months = {
            1: "January",
            2: "February",
            3: "March",
            4: "April",
            5: "May",
            6: "June",
            7: "July",
            8: "August",
            9: "September",
            10: "October",
            11: "November",
            12: "December",
        };
        this.updateButtonClicked = false;
    }
    EarningsDeductionsComponent.prototype.ngOnInit = function () {
        this.changeAppContentWrapperStyle();
    };
    EarningsDeductionsComponent.prototype.onTabChange = function (event) {
        this.selectedIndex = event.index;
        if (this.selectedIndex === 1)
            this.sectionName = "Deductions";
        else
            this.sectionName = "Earnings";
    };
    EarningsDeductionsComponent.prototype.onBackClick = function () {
        if (this.updateButtonClicked) {
            this.openTriggerPayslipConfirmationDialog();
            this.updateButtonClicked = false;
        }
        else {
            this.openEarningsAndDeductionsComponent.emit({
                openEarningsAndDeductions: false,
                isPayslipTriggered: this.isPayslipTriggered,
            });
        }
    };
    EarningsDeductionsComponent.prototype.changeAppContentWrapperStyle = function () {
        var appContentWrapperDiv = document.querySelector("#appContentWrapper");
        appContentWrapperDiv.style.paddingTop = "0";
        appContentWrapperDiv.style.backgroundColor = "white";
        appContentWrapperDiv.style.height = "calc(100% - 70px)";
    };
    EarningsDeductionsComponent.prototype.resetAppContentWrapperStyle = function () {
        var appContentWrapperDiv = document.querySelector("#appContentWrapper");
        appContentWrapperDiv.style.paddingTop = "24px";
        appContentWrapperDiv.style.backgroundColor = "";
        appContentWrapperDiv.style.height = "auto";
    };
    EarningsDeductionsComponent.prototype.onFormStatusChange = function (status) {
        this.earningsFormStatus = status;
    };
    EarningsDeductionsComponent.prototype.onDeductionsFormStatusChange = function (status) {
        this.deductionsFormStatus = status;
    };
    EarningsDeductionsComponent.prototype.ngOnDestroy = function () {
        this.resetAppContentWrapperStyle();
    };
    EarningsDeductionsComponent.prototype.openTriggerPayslipConfirmationDialog = function () {
        var _a;
        var _this = this;
        this.dialog.open(PayrollDialogComponent, (_a = {},
            _a["data"] = {
                title: "Confirmation",
                msg: "Are you sure you want to go back without generating the payslip?",
                dialogType: "post",
                onYesClickFunction: function () {
                    return _this.triggerPayslip(_this.element.email, _this.selectedPayslipYear, _this.selectedPayslipMonth);
                },
                onNoClickFunction: function () { return _this.onBackClick(); },
                noButtonText: "Yes",
                yesButtonText: "Generate Payslip",
            },
            _a));
    };
    EarningsDeductionsComponent.prototype.triggerPayslip = function (email, payslipYear, payslipMonth) {
        var _this = this;
        var employeeId = this.element.email;
        if (!employeeId) {
            console.error("Employee email is undefined.");
            this.snackBarService.add({
                message: "Error: Employee email is undefined.",
                action: "Close",
                config: { duration: 3000 },
            });
            return;
        }
        this.spinnerService.openSpinnerDialog();
        this.payrollService
            .postEmployeeMonthlyPayroll(employeeId, payslipYear, payslipMonth)
            .subscribe(function (res) {
            _this.isPayslipTriggered = true;
            _this.spinnerService.closeSpinnerDialog();
            _this.snackBarService.add({
                message: "Payslip generated successfully for " + employeeId,
                action: "Close",
                config: { duration: 3000 },
            });
            _this.updateButtonClicked = false;
            _this.onBackClick();
        }, function (err) {
            console.error("Error generating payslip", err);
            _this.spinnerService.closeSpinnerDialog();
            _this.snackBarService.add({
                message: "Error occurred in generating payslip",
                action: "close",
                config: { duration: 3000 },
            });
        });
    };
    EarningsDeductionsComponent.prototype.onUpdateButtonClick = function () {
        var _this = this;
        of(null)
            .pipe(mergeMap(function () {
            _this.spinnerService.openSpinnerDialog();
            var earningsApiCall = of(null);
            var deductionsApiCall = of(null);
            if (_this.earningsFormStatus.formData.length > 0 &&
                _this.earningsFormStatus.hasValues &&
                _this.earningsFormStatus.touched &&
                !_this.earningsFormStatus.invalid) {
                _this.earningsExecuted = true;
                earningsApiCall = _this.payrollService
                    .postEmployeeMonthlyEarningsApi(_this.earningsFormStatus.formData)
                    .pipe(catchError(function (err) {
                    _this.earningsExecuted = false;
                    _this.earningsFormStatus.touched = false;
                    console.error(err);
                    _this.snackBarService.add({
                        message: err.error.message,
                        action: "Close",
                        config: {
                            duration: 2000,
                            panelClass: ["custom-snackbar-class"],
                        },
                    });
                    return of(null);
                }));
            }
            if (_this.deductionsFormStatus.formData.length > 0 &&
                _this.deductionsFormStatus.hasValues &&
                _this.deductionsFormStatus.touched &&
                !_this.deductionsFormStatus.invalid) {
                _this.deductionsExecuted = true;
                deductionsApiCall = _this.payrollService
                    .postEmployeeMonthlyDeductionsApi(_this.deductionsFormStatus.formData)
                    .pipe(catchError(function (err) {
                    _this.deductionsExecuted = false;
                    _this.deductionsFormStatus.touched = false;
                    console.error(err);
                    _this.snackBarService.add({
                        message: err.error.message,
                        action: "Close",
                        config: {
                            duration: 2000,
                            panelClass: ["custom-snackbar-class"],
                        },
                    });
                    return of(null);
                }));
            }
            return combineLatest([earningsApiCall, deductionsApiCall]);
        }), finalize(function () {
            _this.spinnerService.closeSpinnerDialog();
        }))
            .subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), earningsData = _b[0], deductionsData = _b[1];
            var showSuccessMessage = false;
            var successMessage = "";
            if (earningsData && deductionsData) {
                successMessage =
                    "Employee Monthly Earnings and Deductions updated successfully.";
            }
            else if (earningsData) {
                successMessage = "Employee Monthly Earnings updated successfully.";
            }
            else if (deductionsData) {
                successMessage = "Employee Monthly Deductions updated successfully.";
            }
            if (_this.earningsFormStatus.touched && _this.earningsExecuted) {
                _this.earningsFormStatus.touched = false;
                _this.earningsExecuted = false;
                _this.employeeEarningsComponent.updateEarningsWithIds(earningsData);
                showSuccessMessage = true;
            }
            if (_this.deductionsFormStatus.touched && _this.deductionsExecuted) {
                _this.deductionsFormStatus.touched = false;
                _this.deductionsExecuted = false;
                _this.employeeDeductionsComponent.updateDeductionsWithIds(deductionsData);
                showSuccessMessage = true;
            }
            if (showSuccessMessage) {
                _this.snackBarService.add({
                    message: successMessage,
                    action: "Close",
                    config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
                });
            }
        });
        if (!this.earningsFormStatus.touched &&
            !this.deductionsFormStatus.touched) {
            this.snackBarService.add({
                message: "You have not made any changes",
                action: "Close",
                config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
            });
        }
        else if (this.earningsFormStatus.invalid ||
            this.deductionsFormStatus.invalid) {
            var errorMessage = "Please enter valid input in: ";
            if (this.earningsFormStatus.invalid) {
                errorMessage += "Earnings ";
            }
            if (this.deductionsFormStatus.invalid) {
                errorMessage += errorMessage.includes("Earnings")
                    ? "and Deductions"
                    : "Deductions";
            }
            this.snackBarService.add({
                message: errorMessage.trim(),
                action: "Close",
                config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
            });
        }
    };
    return EarningsDeductionsComponent;
}());
export { EarningsDeductionsComponent };
