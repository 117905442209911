import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { EmployeeService } from "../shared/services/employee.service";
import { PayrollService } from "../shared/services/payroll.service";
import { SpinnerService } from "../shared/services/spinner.service";
import {
  EmployeeDetails,
  EmployeePayslipDetails,
} from "../core/interfaces/user";
import { catchError, finalize, take } from "rxjs/operators";
import { of } from "rxjs";
import {
  TOTAL_DEDUCTIONS_STRING,
  TOTAL_INCOME_STRING,
  MonthlyPayslipTableData,
  MONTH,
  EarningsOrDeductionArray,
  EmployeePayrollDetails,
  PAYSLIP_COMPONENT,
  PAYSLIP_COMPONENT_AMOUNT,
  EARNINGS,
  DEDUCTIONS,
  PAYSLIP_SUMMARY,
  SNACKBAR_LABEL,
  NAME_KEY,
  USER_DETAILS,
  UserDetails,
  NET_PAY,
  EmployeeCtcPayrollDetails,
  CtcPayslipTableData,
} from "./payslip.utility";
import { SnackBarService } from "../shared/services/snackbar.service";
import { MatTabChangeEvent } from "@angular/material";
import { LoginService } from "../account/login.service";
@Component({
  selector: "app-payslip",
  templateUrl: "./payslip.component.html",
  styleUrls: ["./payslip.component.scss"],
})
export class PayslipComponent implements OnInit {
  @Input() selectedEmployeeDetails: EmployeePayslipDetails;
  @Input() showTable: boolean;
  @Input() userPayroll: boolean;
  @Input() payrollMonth: number = this.getCurrentMonth();
  @Input() payrollYear: number = this.getCurrentYear();
  @Input() payrollDetails: EmployeePayrollDetails;
  @Output() dataEvent: EventEmitter<number> = new EventEmitter<number>();

  userDetails: UserDetails[] = USER_DETAILS;
  netPay: number = 0;
  months: { [month: number]: string } = MONTH;
  employeeDetails: EmployeeDetails;
  empJoiningYear: number;
  fileName: string;
  years: number[] = [];
  durationInSeconds = 5;
  selectedPayslipMonth: number;
  selectedPayslipYear: number;
  employeeMonthlyPayrollDetails: EmployeePayrollDetails;
  employeeCtcPayrollDetails: EmployeeCtcPayrollDetails;
  employeePayrollDetailsForPdf: MonthlyPayslipTableData[];
  employeeCtcPayrollDetailsForPdf: CtcPayslipTableData[];
  payslipExistForSelectedYearOrSelectedMonth: boolean = false;
  selectedTabIndex: number = 0;
  loadingCtcPayslip: boolean = false;

  constructor(
    public employeeService: EmployeeService,
    private snackBarService: SnackBarService,
    private payrollService: PayrollService,
    private spinnerService: SpinnerService,
    private loginService: LoginService
  ) {
    this.selectedPayslipMonth = this.getCurrentMonth();
    this.selectedPayslipYear = this.getCurrentYear();
  }

  ngOnInit() {
    this.employeeDetails = this.loginService.employeeDetails;
    if (!this.selectedEmployeeDetails) {
      this.selectedEmployeeDetails = this.employeeDetails;
      this.getMonthlyPayrollDetails();
    } else if (this.payrollMonth || this.payrollYear || this.payrollDetails) {
      this.selectedPayslipMonth = this.payrollMonth;
      this.selectedPayslipYear = this.payrollYear;
      this.employeeMonthlyPayrollDetails = this.payrollDetails;
      this.getMonthlyPdfDetailsAndNetPay();
      this.getCtcPdfDetailsAndNetPay();
    }
    this.selectedEmployeeDetails[
      NAME_KEY
    ] = `${this.selectedEmployeeDetails.firstName} ${this.selectedEmployeeDetails.lastName}`;
    this.empJoiningYear = parseInt(
      this.employeeDetails.dateOfJoining.split("/")[2]
    );
    this.years = Array.from(
      { length: this.selectedPayslipYear - this.empJoiningYear + 1 },
      (_, i) => this.selectedPayslipYear - i
    );
    this.setLWP();
    this.setProfessionalDetails();
    this.emitPayslipMonthValue();
  }

  setLWP() {
    if (this.selectedEmployeeDetails) {
      this.payrollService
        .getEmployeeMonthlyDeductionsApi(
          this.selectedPayslipMonth,
          this.selectedPayslipYear,
          this.selectedEmployeeDetails.employeeId
        )
        .subscribe((lwp) => {
          let element = lwp.find(
            (element) => element.deduction.toLowerCase() === "lwp"
          );
          this.selectedEmployeeDetails.lwpCount =
            element && element.amount ? element.amount : 0;
          this.setEffectiveWorkingDays(this.selectedEmployeeDetails.lwpCount);
        });
    }
  }

  setProfessionalDetails(): void {
    if (
      this.selectedEmployeeDetails &&
      this.employeeService.employeePersonalDetails
    ) {
      const { department, designation } =
        this.employeeService.employeePersonalDetails;
      this.selectedEmployeeDetails.department = department;
      this.selectedEmployeeDetails.designation = designation;
    }
  }

  setEffectiveWorkingDays(lwpCount: number) {
    const daysInMonth = new Date(
      this.selectedPayslipYear,
      this.selectedPayslipMonth,
      0
    ).getDate();
    this.selectedEmployeeDetails.effectiveWorkingDays = daysInMonth - lwpCount;
  }

  emitPayslipMonthValue(): void {
    const data = this.selectedPayslipMonth;
    this.dataEvent.emit(data);
  }

  getMonthlyPayrollDetails(): void {
    this.spinnerService.openSpinnerDialog();
    this.payrollService
      .getPayrollDetailsApi(
        this.selectedEmployeeDetails.employeeId,
        this.selectedPayslipMonth,
        this.selectedPayslipYear
      )
      .pipe(
        take(1),
        catchError((error) => {
          this.netPay = 0;
          this.payslipExistForSelectedYearOrSelectedMonth = false;
          return of(null);
        }),
        finalize(() => {
          this.spinnerService.closeSpinnerDialog();
        })
      )
      .subscribe((res) => {
        if (res) {
          this.employeeMonthlyPayrollDetails = res;
          this.getMonthlyPdfDetailsAndNetPay();
        } else {
          this.payslipExistForSelectedYearOrSelectedMonth = false;
          this.showPayslipNotFoundSnackbar();
        }
      });
  }

  getCtcPayslipDetails(): void {
    this.spinnerService.openSpinnerDialog();
    this.payrollService
      .getCtcPayslipDetailsApi(
        this.selectedEmployeeDetails.employeeId,
        this.selectedPayslipMonth,
        this.selectedPayslipYear
      )
      .pipe(
        take(1),
        catchError((error) => {
          this.payslipExistForSelectedYearOrSelectedMonth = false;
          return of(null);
        }),
        finalize(() => {
          this.spinnerService.closeSpinnerDialog();
        })
      )
      .subscribe((res) => {
        if (res) {
          this.employeeCtcPayrollDetails = res;
          this.getCtcPdfDetailsAndNetPay();
        } else {
          this.payslipExistForSelectedYearOrSelectedMonth = false;
          this.showPayslipNotFoundSnackbar();
        }
      });
  }

  showPayslipNotFoundSnackbar(): void {
    this.snackBarService.add({
      message: "Payslip does not exist for the selected month and year.",
      action: SNACKBAR_LABEL,
      config: {
        duration: this.durationInSeconds * 1000,
        panelClass: ["custom-snackbar-class"],
      },
    });
  }

  getCtcPdfDetailsAndNetPay(): void {
    this.payslipExistForSelectedYearOrSelectedMonth = true;
    if (this.employeeCtcPayrollDetails[PAYSLIP_SUMMARY]) {
      const payslipSummary = this.employeeCtcPayrollDetails[PAYSLIP_SUMMARY][0];
      this.fileName = `${payslipSummary.employeeId}_${payslipSummary.payslipMonth}_${payslipSummary.fiscal}_CTC.pdf`;
    }
    this.employeeCtcPayrollDetailsForPdf = this.pdfDetailsForCtc(
      this.employeeCtcPayrollDetails
    );
  }

  pdfDetailsForCtc(employeeCtcPayrollDetails): CtcPayslipTableData[] {
    const parsedData: CtcPayslipTableData[] = [];

    const earnings = employeeCtcPayrollDetails.earnings;
    const earningsLength = earnings ? earnings.length : 0;

    if (earningsLength !== 0) {
      for (let i = 0; i < earningsLength; i++) {
        const data: CtcPayslipTableData = {
          earningsComponent: earnings[i]
            ? earnings[i].payslipComponent.replace(/_/g, " ")
            : "",
          earningsComponentAmount: earnings[i]
            ? earnings[i].componentAmount
            : "",
        };
        parsedData.push(data);
      }

      if (employeeCtcPayrollDetails.payslipSummary) {
        let totalIncome = 0;

        for (const component of employeeCtcPayrollDetails.payslipSummary) {
          if (component.payslipComponent === "Total") {
            totalIncome = component.componentAmount;
          }
        }

        parsedData.push({
          earningsComponent: "Total Income",
          earningsComponentAmount: totalIncome.toString(),
        });
      }
    }

    return parsedData;
  }

  getMonthlyPdfDetailsAndNetPay(): void {
    this.payslipExistForSelectedYearOrSelectedMonth = true;

    if (this.employeeMonthlyPayrollDetails[PAYSLIP_SUMMARY]) {
      const payslipSummary =
        this.employeeMonthlyPayrollDetails[PAYSLIP_SUMMARY][0];
      this.fileName = `${payslipSummary.employeeId}_${payslipSummary.payslipMonth}_${payslipSummary.fiscal}_Monthly.pdf`;

      const netPayComponent = this.employeeMonthlyPayrollDetails[
        PAYSLIP_SUMMARY
      ].find((component) => component.payslipComponent === NET_PAY);

      this.netPay = netPayComponent ? netPayComponent.componentAmount : 0;
    }

    this.employeePayrollDetailsForPdf = this.monthlyPdfDetails(
      this.employeeMonthlyPayrollDetails
    );
  }

  monthlyPdfDetails(employeeMonthlyPayrollDetails): MonthlyPayslipTableData[] {
    const parsedData: MonthlyPayslipTableData[] = [];

    const earnings = employeeMonthlyPayrollDetails.earnings;
    const deductions = employeeMonthlyPayrollDetails.deductions;

    const earningsLength = earnings ? earnings.length : 0;
    const deductionsLength = deductions ? deductions.length : 0;
    const counter: number = Math.max(earningsLength, deductionsLength);

    if (counter !== 0) {
      for (let i = 0; i < counter; i++) {
        const data = {
          earningsComponent: earnings
            ? earnings[i]
              ? earnings[i].payslipComponent.replace(/_/g, " ")
              : ""
            : "",
          earningsComponentAmount: earnings
            ? earnings[i]
              ? earnings[i].componentAmount
              : ""
            : "",
          deductionsComponent: deductions
            ? deductions[i]
              ? deductions[i].payslipComponent.replace(/_/g, " ")
              : ""
            : "",
          deductionsComponentAmount: deductions
            ? deductions[i]
              ? deductions[i].componentAmount
              : ""
            : "",
        };
        parsedData.push(data);
      }

      if (employeeMonthlyPayrollDetails.payslipSummary) {
        let totalIncome = 0;
        let totalDeductions = 0;
        for (const component of employeeMonthlyPayrollDetails.payslipSummary) {
          if (component.payslipComponent === TOTAL_DEDUCTIONS_STRING) {
            totalDeductions = component.componentAmount;
          } else if (component.payslipComponent === TOTAL_INCOME_STRING) {
            totalIncome = component.componentAmount;
          }
        }
        parsedData.push({
          earningsComponent: "Total Income",
          earningsComponentAmount: totalIncome.toString(),
          deductionsComponent: "Total Deductions",
          deductionsComponentAmount: totalDeductions.toString(),
        });
      }
    }
    return parsedData;
  }

  getCurrentMonth(): number {
    return new Date().getMonth() > 0 ? new Date().getMonth() : 12;
  }

  getCurrentYear(): number {
    return new Date().getMonth() > 0
      ? new Date().getFullYear()
      : new Date().getFullYear() - 1;
  }

  onMonthChange(): void {
    if (this.selectedTabIndex === 0) {
      this.getMonthlyPayrollDetails();
    } else if (this.selectedTabIndex === 1) {
      this.getCtcPayslipDetails();
    }
    this.emitPayslipMonthValue();
    this.setLWP();
  }

  onYearChange(): void {
    if (this.selectedPayslipYear === this.getCurrentYear()) {
      if (this.selectedPayslipMonth > this.getCurrentMonth()) {
        this.selectedPayslipMonth = 1;
      }
    }
    if (this.selectedTabIndex === 0) {
      this.getMonthlyPayrollDetails();
    } else if (this.selectedTabIndex === 1) {
      this.getCtcPayslipDetails();
    }
    this.setLWP();
  }

  getMonthlyEarningsOrDeductionsArray(
    type: typeof EARNINGS | typeof DEDUCTIONS
  ): EarningsOrDeductionArray {
    const result: EarningsOrDeductionArray = [];
    let incomeDetails = this.employeeMonthlyPayrollDetails[type];

    if (incomeDetails) {
      for (let item of incomeDetails) {
        result.push({
          label: item[PAYSLIP_COMPONENT].replace(/_/g, " "),
          amount: item[PAYSLIP_COMPONENT_AMOUNT],
        });
      }
    }
    return result;
  }

  getTotalMonthlyEarningsOrDeductions(
    type: typeof EARNINGS | typeof DEDUCTIONS
  ): EarningsOrDeductionArray {
    const total: EarningsOrDeductionArray = [];
    if (this.employeeMonthlyPayrollDetails[PAYSLIP_SUMMARY]) {
      for (let item of this.employeeMonthlyPayrollDetails[PAYSLIP_SUMMARY]) {
        if (
          (type === EARNINGS &&
            item[PAYSLIP_COMPONENT] === TOTAL_INCOME_STRING) ||
          (type === DEDUCTIONS &&
            item[PAYSLIP_COMPONENT] === TOTAL_DEDUCTIONS_STRING)
        ) {
          total.push({
            label: "Total",
            amount: item[PAYSLIP_COMPONENT_AMOUNT],
          });
        }
      }
    }
    return total;
  }

  getEarningsOrDeductionsArrayForCTC(type: string) {
    const result: EarningsOrDeductionArray = [];
    const ctcDetails = this.employeeCtcPayrollDetails[type] || [];

    if (ctcDetails) {
      ctcDetails.forEach((item) => {
        result.push({
          label: item.payslipComponent.replace(/_/g, " "),
          amount: item.componentAmount,
        });
      });
    }
    return result;
  }

  getTotalCTCDetails(type: string) {
    const total: EarningsOrDeductionArray = [];
    const payslipSummary = this.employeeCtcPayrollDetails.payslipSummary || [];

    payslipSummary.forEach((item) => {
      if (type === "earnings" && item.payslipComponent === "Total") {
        total.push({
          label: "Total",
          amount: item.componentAmount,
        });
      }
    });
    return total;
  }

  showMonth(monthKey: number): boolean {
    return (
      monthKey <= this.getCurrentMonth() &&
      this.getCurrentYear() == this.selectedPayslipYear
    );
  }

  showYear(): boolean {
    return this.getCurrentYear() != this.selectedPayslipYear;
  }
  currentTab: string = "monthly";

  onTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
    this.payrollService.tabIndex = event.index;

    if (event.index === 1) {
      this.getCtcPayslipDetails();
    } else if (event.index === 0) {
      this.getMonthlyPayrollDetails();
    }

    if (!this.payslipExistForSelectedYearOrSelectedMonth) {
      this.showPayslipNotFoundSnackbar();
    }
  }
}
