import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { getCurrentFiscalYear } from "../../shared/app.utility";
import { finalize } from "rxjs/operators";
var STATUS_OPTIONS = [
    "CONSIDERED",
    "REJECTED",
    "OPEN",
    "SAVED",
    "SUBMITTED",
    "UPDATED",
];
var ProofOfIncomeAdminComponent = /** @class */ (function () {
    function ProofOfIncomeAdminComponent(investmentService, router, snackBarService, spinnerService) {
        this.investmentService = investmentService;
        this.router = router;
        this.snackBarService = snackBarService;
        this.spinnerService = spinnerService;
        this.fiscal = getCurrentFiscalYear();
        this.displayedColumns = [
            "fullName",
            "investmentProofStatus",
            "submissionDate",
        ];
        this.dataSource = new MatTableDataSource();
        this.searchInputValue = "";
        this.sectionInvestmentData = {};
        this.years = [];
        this.selectedEmployeeId = "";
        this.investmentProofStatusCount = {};
        this.selectedCard = "";
        this.selectedRegime = "ALL";
        this.statusList = [];
        this.isPoiNotReleased = false;
        this.isLoading = false;
    }
    ProofOfIncomeAdminComponent.prototype.ngOnInit = function () {
        this.spinnerService.openSpinnerDialog();
        this.statusList = STATUS_OPTIONS;
        this.fetchInvestmentProofStatusCount();
        this.years = Array.from(new Array(11), function (x, i) { return getCurrentFiscalYear() - i; });
        this.selectedFiscalYear = getCurrentFiscalYear();
        this.selectCard("All");
    };
    ProofOfIncomeAdminComponent.prototype.onFiscalYearChange = function () {
        this.fiscal = this.selectedFiscalYear;
        this.getAllEmpInvestProof();
    };
    ProofOfIncomeAdminComponent.prototype.getStatusClass = function (status) {
        switch (status.toUpperCase()) {
            case "CONSIDERED":
                return "status-approved";
            case "REJECTED":
                return "status-rejected";
            case "OPEN":
                return "status-open";
            case "SAVED":
                return "status-saved";
            case "SUBMITTED":
                return "status-submitted";
            case "UPDATED":
                return "status-updated";
            default:
                return "";
        }
    };
    ProofOfIncomeAdminComponent.prototype.fetchInvestmentProofStatusCount = function () {
        var _this = this;
        this.investmentService.getAdminEmpInvestProofCount(this.fiscal).subscribe(function (response) {
            _this.investmentProofStatusCount = response;
        }, function (error) {
            console.error("Error fetching investment proof status count:", error);
        });
    };
    ProofOfIncomeAdminComponent.prototype.getAllEmpInvestProof = function (status) {
        var _this = this;
        var taxRegime = this.selectedRegime === "ALL" ? undefined : this.selectedRegime;
        this.isLoading = true;
        this.sectionInvestmentData = {};
        this.investmentService
            .getAdminEmpInvestProof(this.fiscal, taxRegime, status)
            .pipe(finalize(function () {
            setTimeout(function () {
                _this.isLoading = false;
                if (_this.selectedEmployeeId) {
                    _this.selectEmployee(_this.selectedEmployeeId);
                }
            }, 500);
            _this.spinnerService.closeSpinnerDialog();
        }))
            .subscribe(function (response) {
            response.sort(function (a, b) { return a.fullName.localeCompare(b.fullName); });
            _this.dataSource.data = response.map(function (emp) { return (tslib_1.__assign({}, emp, { submissionDate: _this.convertDateFormat(emp.statusDate) })); });
            if (!status && response.length === 0) {
                _this.isPoiNotReleased = true;
            }
            else {
                _this.isPoiNotReleased = false;
            }
            _this.dataSource.paginator = _this.paginator;
            if (response.length > 0) {
                _this.selectedEmployeeName = response[0].fullName;
                _this.selectedEmployeeId = response[0].employeeId;
                _this.selectedEmployeeStatus = response[0].investmentProofStatus;
            }
            else {
                _this.selectedEmployeeName = "";
                _this.selectedEmployeeId = "";
                _this.selectedEmployeeStatus = "";
            }
            _this.dataSource.filterPredicate = function (data) {
                var searchText = _this.searchInputValue.trim().toLowerCase();
                var matchesSearch = data.fullName
                    .toLowerCase()
                    .includes(searchText);
                var matchesStatus = data.investmentProofStatus
                    .toLowerCase()
                    .includes(searchText);
                return matchesSearch || matchesStatus;
            };
        }, function (error) {
            console.error("Error fetching employee proof status:", error);
            _this.snackBarService.add({
                message: "Failed to load employee data",
                config: { duration: 2000 },
            });
        });
    };
    ProofOfIncomeAdminComponent.prototype.selectCard = function (status) {
        this.selectedCard = status;
        var apiStatus = status === "All" ? undefined : status;
        this.getAllEmpInvestProof(apiStatus);
    };
    ProofOfIncomeAdminComponent.prototype.onRegimeChange = function () {
        this.getAllEmpInvestProof();
    };
    ProofOfIncomeAdminComponent.prototype.getStatusKeys = function () {
        return Object.keys(this.investmentProofStatusCount);
    };
    ProofOfIncomeAdminComponent.prototype.convertDateFormat = function (dateStr) {
        if (!dateStr)
            return null;
        var parts = dateStr.split("/");
        if (parts.length === 3) {
            return parts[2] + "-" + parts[1] + "-" + parts[0];
        }
        return null;
    };
    ProofOfIncomeAdminComponent.prototype.fetchSectionInvestmentDetails = function (employeeId) {
        var _this = this;
        this.investmentService
            .getSectionInvestmentDetails(employeeId, this.fiscal)
            .subscribe(function (response) {
            _this.sectionInvestmentData = response;
        }, function (error) {
            console.error("Error fetching section investment details:", error);
        });
    };
    ProofOfIncomeAdminComponent.prototype.applyFilter = function () {
        // to trigger filtering in mat-table
        this.dataSource.filter = this.searchInputValue.trim().toLowerCase();
    };
    ProofOfIncomeAdminComponent.prototype.selectEmployee = function (employeeId) {
        var selectedEmp = this.dataSource.data.find(function (emp) { return emp.employeeId === employeeId; });
        if (selectedEmp) {
            this.selectedEmployeeId = selectedEmp.employeeId;
            this.selectedEmployeeName = selectedEmp.fullName;
            this.selectedEmployeeStatus = selectedEmp.investmentProofStatus;
            this.fetchSectionInvestmentDetails(this.selectedEmployeeId);
        }
    };
    ProofOfIncomeAdminComponent.prototype.viewForm = function () {
        if (this.selectedEmployeeId && this.selectedEmployeeName) {
            var encodedId = encodeURIComponent(this.selectedEmployeeId);
            var encodedName = encodeURIComponent(this.selectedEmployeeName);
            var encodedStatus = encodeURIComponent(this.selectedEmployeeStatus);
            this.router.navigate(["dashboard", "proofOfIncome", "detail", encodedId], {
                queryParams: { name: encodedName, status: encodedStatus },
            });
        }
    };
    ProofOfIncomeAdminComponent.prototype.releasePoi = function () {
        var _this = this;
        this.spinnerService.openSpinnerDialog();
        this.investmentService
            .releasePoiApi(this.selectedFiscalYear.toString())
            .subscribe(function (res) {
            _this.snackBarService.add({
                message: res.message,
                config: { duration: 2000 },
            });
            _this.selectedCard = "All";
            _this.getAllEmpInvestProof();
        }, function (err) {
            _this.snackBarService.add({
                message: err.error.message,
                config: { duration: 2000 },
            });
            _this.spinnerService.closeSpinnerDialog();
        });
    };
    return ProofOfIncomeAdminComponent;
}());
export { ProofOfIncomeAdminComponent };
