import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators, } from "@angular/forms";
import { forkJoin } from "rxjs";
import * as moment from "moment";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { LOCAL_STORAGE_KEYS } from "../account/login.service";
import { addHouse } from "./investment-declaration.util";
import { finalize, take } from "rxjs/operators";
import { PayrollDialogComponent } from "../payroll-dialog/payroll-dialog.component";
var InvestmentDeclarationComponent = /** @class */ (function () {
    function InvestmentDeclarationComponent(dialog, snackBar, snackBarService, formBuilder, spinnerService, investmentDeclarationService, loginService) {
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.snackBarService = snackBarService;
        this.formBuilder = formBuilder;
        this.spinnerService = spinnerService;
        this.investmentDeclarationService = investmentDeclarationService;
        this.loginService = loginService;
        this.fiscal = getCurrentFiscalYear();
        this.allSections = [];
        this.investmentBySectionData = [];
        this.limits = [];
        this.investmentViaIdDict = {};
        this.sectionTotalAmount = {};
        this.investmentDeclarationFormData = null;
        this.years = [];
        this.isFormReady = false;
        this.isFormSubmitted = false;
        this.isInvestmentDeclarationWindowOpen = false;
        this.houses = [];
        this.fiscalMonths = [];
        this.isAddingInvestmentDict = {};
        this.isPreviewScreen = false;
        this.months = [
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
            "January",
            "February",
            "March",
        ];
        this.expandedElements = new Array(this.allSections.length).fill(false);
        this.timeBoundFeaturesConfigs = loginService.timeBoundFeaturesConfigs
            ? loginService.timeBoundFeaturesConfigs
            : JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.time_bound_features_configurations));
        this.isInvestmentDeclarationWindowOpen =
            this.getIsInvestmentDeclarationWindowOpen();
    }
    InvestmentDeclarationComponent.prototype.ngOnDestroy = function () {
        //this.updateAppContentWrapperHeight("calc(100% - 74px)");
    };
    InvestmentDeclarationComponent.prototype.ngOnInit = function () {
        this.employeeDetails = this.loginService.employeeDetails;
        this.updateAppContentWrapperHeight("auto");
        var currYear = getCurrentFiscalYear();
        for (var i = currYear; i >= currYear - 15; i--) {
            this.years.push(i);
        }
        this.fiscalMonths = this.generateFiscalMonths(this.fiscal);
        this.investmentDeclarationForm = this.formBuilder.group({
            investmentSections: this.formBuilder.group({}),
        });
        this.getInvestmentDelarationSection();
        this.getInvestmentLimit();
        // disabling form on the basis of time bound configurations
        if (!this.isInvestmentDeclarationWindowOpen) {
            this.investmentDeclarationForm.disable();
        }
    };
    InvestmentDeclarationComponent.prototype.updateAppContentWrapperHeight = function (value) {
        var element = document.querySelector("#appContentWrapper");
        element.style.height = value;
    };
    InvestmentDeclarationComponent.prototype.onFiscalYearChange = function () {
        var _this = this;
        this.investmentDeclarationService
            .getEmployeeInvestDeclarationByEmpId(this.loginService.employeeDetails.employeeId, this.fiscal)
            .pipe(take(1), finalize(function () {
            _this.isFormReady = true;
        }))
            .subscribe(function (data) {
            _this.investmentDeclarationFormData = data;
            //To disable form if already submitted
            _this.setFormData();
        }, function (err) {
            _this.snackBarService.add({
                message: "It's taking a bit long than expected, please try to reload the page.",
                action: "close",
                config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
            });
            _this.isFormReady = true;
        });
        this.fiscalMonths = this.generateFiscalMonths(parseInt(this.fiscal));
    };
    InvestmentDeclarationComponent.prototype.createFormGroup = function (data) {
        var fg = this.formBuilder.group({});
        for (var i = 0; i <= data.length - 1; i++) {
            fg.addControl(data[i].investmentLabelName, new FormControl(null, [Validators.maxLength(8)]));
        }
        return fg;
    };
    InvestmentDeclarationComponent.prototype.createFormGroupWithValues = function (data, values) {
        var e_1, _a;
        var fg = this.formBuilder.group({});
        for (var i = 0; i <= data.length - 1; i++) {
            var valueExist = false;
            try {
                for (var values_1 = (e_1 = void 0, tslib_1.__values(values)), values_1_1 = values_1.next(); !values_1_1.done; values_1_1 = values_1.next()) {
                    var x = values_1_1.value;
                    if (x.investmentViaId == data[i].investmentViaId) {
                        fg.addControl(data[i].investmentLabelName, new FormControl(x.amount, [Validators.maxLength(8)]));
                        valueExist = true;
                        break;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (values_1_1 && !values_1_1.done && (_a = values_1.return)) _a.call(values_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            if (!valueExist) {
                fg.addControl(data[i].investmentLabelName, new FormControl(null, [Validators.maxLength(8)]));
            }
        }
        return fg;
    };
    /*For changing the expansion mat icon*/
    InvestmentDeclarationComponent.prototype.changeArrow = function (i) {
        this.expandedElements[i] = !this.expandedElements[i];
    };
    InvestmentDeclarationComponent.prototype.investmentViaIdMapping = function (data) {
        for (var i = 0; i <= data.length - 1; i++) {
            this.investmentViaIdDict[data[i].investmentLabelName] =
                data[i].investmentViaId;
        }
    };
    InvestmentDeclarationComponent.prototype.getLimit = function (sectionName) {
        return this.limits.filter(function (limit) { return limit.section === sectionName; })[0]
            .investmentLimit;
    };
    //FORK JOIN APPROACH REPLACING ASYNC
    InvestmentDeclarationComponent.prototype.getInvestmentDelarationSection = function () {
        var _this = this;
        this.investmentDeclarationService
            .getInvestmentDeclarationSectionApi()
            .subscribe(function (res) {
            _this.allSections = res;
            _this.setInitialValuesForIsAddingInvestmentFlags(_this.allSections);
            var observablesArr = [];
            for (var i = 0; i <= _this.allSections.length - 1; i++) {
                var sectionName = _this.allSections[i].optionValue;
                var sectionGroup = _this.formBuilder.group({});
                _this.investmentSectionsForm.addControl(sectionName, sectionGroup);
                // Setting section wise total amount to zero
                _this.sectionTotalAmount[_this.allSections[i].optionValue] = 0;
                observablesArr.push(_this.investmentDeclarationService.getInvestmentBySectionApi(_this.allSections[i].optionValue));
            }
            forkJoin(observablesArr).subscribe(function (data) {
                var e_2, _a;
                _this.investmentBySectionData = data;
                try {
                    for (var _b = tslib_1.__values(_this.investmentBySectionData), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var val = _c.value;
                        _this.addAbstractControlsToFormGroups(val);
                        _this.investmentViaIdMapping(val);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                _this.investmentDeclarationService
                    .getEmployeeInvestDeclarationByEmpId(_this.loginService.employeeDetails.employeeId, _this.fiscal)
                    .pipe(take(1), finalize(function () {
                    _this.isFormReady = true;
                }))
                    .subscribe(function (data) {
                    _this.investmentDeclarationFormData = data;
                    //To disable form if already submitted
                    _this.setFormData();
                });
            });
        }, function (error) {
            _this.snackBarService.add({
                message: "It's taking a bit long than expected, please try to reload the page.",
                action: "close",
                config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
            });
            _this.isFormReady = true;
            console.log("getInvestmentDelarationSectionApi failed", error);
        });
    };
    InvestmentDeclarationComponent.prototype.setFormData = function () {
        var e_3, _a, e_4, _b;
        var _this = this;
        if (this.investmentDeclarationFormData.length !== 0) {
            // PF component value will already be set for all users
            this.isFormSubmitted =
                this.investmentDeclarationFormData.length === 1 ? false : true;
            try {
                for (var _c = tslib_1.__values(this.investmentBySectionData), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var val = _d.value;
                    if (val.length !== 0 && val[0].section) {
                        var sections = this.investmentDeclarationForm.get("investmentSections").get(val[0].section);
                        for (var i = 0; i <= val.length - 1; i++) {
                            var valueExist = false;
                            var _loop_1 = function (x) {
                                if (val[0].section === "HRA") {
                                    if (x.hras.length && x.investmentViaId == 20) {
                                        this_1.houses = [];
                                        var hra_1 = this_1.investmentDeclarationForm.get("investmentSections").get("HRA");
                                        x.hras.forEach(function (hraItem, index) {
                                            var fromFiscalYear = parseInt(x.fiscal) +
                                                (hraItem.fromMonth >= 1 && hraItem.fromMonth <= 3
                                                    ? 1
                                                    : 0);
                                            var toFiscalYear = parseInt(x.fiscal) +
                                                (hraItem.toMonth >= 1 && hraItem.toMonth <= 3 ? 1 : 0);
                                            var fromMonthName = moment()
                                                .month(hraItem.fromMonth - 1)
                                                .format("MMMM");
                                            var toMonthName = moment()
                                                .month(hraItem.toMonth - 1)
                                                .format("MMMM");
                                            var fromMonthLabel = fromMonthName + " " + fromFiscalYear;
                                            var toMonthLabel = toMonthName + " " + toFiscalYear;
                                            var fromMonthOption = _this.fiscalMonths.find(function (month) { return month == fromMonthLabel; });
                                            var toMonthOption = _this.fiscalMonths.find(function (month) { return month == toMonthLabel; });
                                            addHouse(_this.investmentSectionsForm.get("HRA"), _this.houses, _this.formBuilder);
                                            var hraForm = hra_1.get("House " + (index + 1));
                                            hraForm.patchValue({
                                                fromMonth: fromMonthOption,
                                                toMonth: toMonthOption,
                                                monthlyRent: x.hras[index].monthlyRentAmount,
                                                metroCity: x.hras[index].metroCity,
                                            });
                                        });
                                        return "break";
                                    }
                                }
                                else {
                                    if (x.investmentViaId == val[i].investmentViaId) {
                                        sections.get(val[i].investmentLabelName).patchValue(x.amount);
                                        valueExist = true;
                                        return "break";
                                    }
                                }
                            };
                            var this_1 = this;
                            try {
                                for (var _e = (e_4 = void 0, tslib_1.__values(this.investmentDeclarationFormData)), _f = _e.next(); !_f.done; _f = _e.next()) {
                                    var x = _f.value;
                                    var state_1 = _loop_1(x);
                                    if (state_1 === "break")
                                        break;
                                }
                            }
                            catch (e_4_1) { e_4 = { error: e_4_1 }; }
                            finally {
                                try {
                                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                                }
                                finally { if (e_4) throw e_4.error; }
                            }
                            if (!valueExist && val[0].section !== "HRA") {
                                sections.get(val[i].investmentLabelName).patchValue(null);
                            }
                        }
                        this.updateSectionTotalAmount(val[0].section);
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        if (this.isFormSubmitted) {
            this.investmentDeclarationForm.disable();
        }
        else {
            this.loadFormData(null, true);
            this.investmentDeclarationForm.enable();
        }
    };
    InvestmentDeclarationComponent.prototype.getInvestmentLimit = function () {
        var _this = this;
        this.investmentDeclarationService.getInvestmentLimitApi().subscribe(function (res) {
            _this.limits = res;
        }, function (error) {
            console.log("getInvestmentLimitApi failed", error);
        });
    };
    InvestmentDeclarationComponent.prototype.onSubmitButtonClick = function () {
        if (this.isHRAFormValid()) {
            this.openSubmissionDialog();
        }
        else {
            this.openInvalidHRAFormDialog();
        }
    };
    InvestmentDeclarationComponent.prototype.openHRAForm = function () {
        this.isPreviewScreen = false;
        this.toggleIsAddingInvestment("HRA");
    };
    InvestmentDeclarationComponent.prototype.openInvalidHRAFormDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(PayrollDialogComponent, {
            data: {
                title: "Invalid",
                msg: "HRA form is incomplete. Do you want to be redirected to HRA form?",
                dialogType: "delete",
                onYesClickFunction: function () { return _this.openHRAForm(); },
            },
        });
    };
    InvestmentDeclarationComponent.prototype.openSubmissionDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(PayrollDialogComponent, {
            data: {
                title: "Confirmation",
                msg: "Do you want to submit investment declaration for fiscal year " + this.fiscal + "-" + (this.fiscal + 1) + "?",
                dialogType: "post",
                onYesClickFunction: function () { return _this.postIncomeTax(); },
            },
        });
    };
    InvestmentDeclarationComponent.prototype.openSnackbar = function (message, action) {
        this.snackBar.open(message, action, {
            duration: 5000,
            panelClass: ["custom-snackbar-class"],
        });
    };
    InvestmentDeclarationComponent.prototype.updateTotalAmountForHRA = function () {
        for (var i = 0; i < this.houses.length; i++) {
            var houseForm = this.investmentSectionsForm
                .get("HRA")
                .get("House " + (i + 1));
            var monthlyRent = houseForm.get("monthlyRent").value;
            var fromMonth = this.getMonthNumber(houseForm.get("fromMonth").value);
            var toMonth = this.getMonthNumber(houseForm.get("toMonth").value);
            // months are 1 base index and plus one is to include toMonth in the duration
            var duration = (toMonth < 4 ? toMonth + 12 : toMonth) -
                (fromMonth < 4 ? fromMonth + 12 : fromMonth) +
                1;
            if (monthlyRent) {
                this.sectionTotalAmount["HRA"] += parseFloat(monthlyRent) * duration;
            }
        }
    };
    InvestmentDeclarationComponent.prototype.updateSectionTotalAmount = function (sectionName) {
        this.sectionTotalAmount[sectionName] = 0;
        if (sectionName === "HRA") {
            this.updateTotalAmountForHRA();
        }
        else {
            var amounts = this.investmentDeclarationForm.value.investmentSections[sectionName];
            for (var amount in amounts) {
                if (amounts[amount]) {
                    this.sectionTotalAmount[sectionName] += parseInt(amounts[amount]);
                }
            }
        }
    };
    InvestmentDeclarationComponent.prototype.getIsInvestmentDeclarationWindowOpen = function () {
        var e_5, _a;
        var startDate;
        var endDate;
        var todaysDate = new Date();
        try {
            for (var _b = tslib_1.__values(this.timeBoundFeaturesConfigs), _c = _b.next(); !_c.done; _c = _b.next()) {
                var featureConfigs = _c.value;
                if (featureConfigs.featureName === "Investment Declaration") {
                    startDate = new Date(featureConfigs.startDate);
                    endDate = new Date(featureConfigs.endDate);
                }
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_5) throw e_5.error; }
        }
        if (todaysDate <= endDate && todaysDate >= startDate) {
            return true;
        }
        return false;
    };
    InvestmentDeclarationComponent.prototype.generateFiscalMonths = function (year) {
        var fiscalMonths = [];
        for (var i = 0; i < this.months.length; i++) {
            fiscalMonths.push(this.months[i] + " " + (year + Math.floor(i / 9)));
        }
        return fiscalMonths;
    };
    Object.defineProperty(InvestmentDeclarationComponent.prototype, "investmentSectionsForm", {
        get: function () {
            return this.investmentDeclarationForm.get("investmentSections");
        },
        enumerable: true,
        configurable: true
    });
    InvestmentDeclarationComponent.prototype.addAbstractControlsToFormGroups = function (data) {
        if (data && data.length) {
            if (data[0].section !== "HRA") {
                for (var i = 0; i <= data.length - 1; i++) {
                    this.investmentSectionsForm.get(data[0].section).addControl(data[i].investmentLabelName, new FormControl(null));
                }
            }
            else {
                addHouse(this.investmentSectionsForm.get("HRA"), this.houses, this.formBuilder);
            }
        }
    };
    InvestmentDeclarationComponent.prototype.getFormGroupsArray = function () {
        this.groupNames = [];
        var section = [];
        for (var key in this.investmentSectionsForm.controls) {
            section.push(this.investmentSectionsForm.controls[key]);
            this.groupNames.push(key);
        }
        return section;
    };
    InvestmentDeclarationComponent.prototype.getMonthNumber = function (monthName) {
        if (monthName) {
            var month = monthName.split(" ")[0];
            return moment(month, "MMM").month() + 1;
        }
    };
    InvestmentDeclarationComponent.prototype.getSectionInputNames = function (sectionValue) {
        return Object.keys(sectionValue);
    };
    InvestmentDeclarationComponent.prototype.toggleIsAddingInvestment = function (sectionName) {
        this.isAddingInvestmentDict[sectionName] =
            !this.isAddingInvestmentDict[sectionName];
        this.isAddingInvestmentDict[sectionName]
            ? this.updateAppContentWrapperHeight("calc(100% - 70px")
            : this.updateAppContentWrapperHeight("auto");
    };
    InvestmentDeclarationComponent.prototype.openNextSection = function (currentSectionName) {
        this.isAddingInvestmentDict[currentSectionName] = false;
        var isNextSection = false;
        for (var sectionName in this.isAddingInvestmentDict) {
            if (isNextSection) {
                this.isAddingInvestmentDict[sectionName] = true;
                break;
            }
            if (sectionName === currentSectionName) {
                isNextSection = true;
            }
        }
    };
    InvestmentDeclarationComponent.prototype.setInitialValuesForIsAddingInvestmentFlags = function (sections) {
        var _this = this;
        sections.forEach(function (section) {
            _this.isAddingInvestmentDict[section.optionValue] = false;
        });
    };
    InvestmentDeclarationComponent.prototype.isAddingInvestment = function () {
        for (var sectionName in this.isAddingInvestmentDict) {
            if (this.isAddingInvestmentDict[sectionName]) {
                return true;
            }
        }
        return false;
    };
    InvestmentDeclarationComponent.prototype.resetSectionAmount = function (sectionName) {
        this.sectionTotalAmount[sectionName] = 0;
    };
    InvestmentDeclarationComponent.prototype.resetAllSectionAmount = function (pfValue) {
        for (var sectionName in this.sectionTotalAmount) {
            this.sectionTotalAmount[sectionName] = 0;
        }
        if (pfValue) {
            this.sectionTotalAmount["80C"] = pfValue;
        }
    };
    InvestmentDeclarationComponent.prototype.saveInvestmentDeclarationForm = function () {
        var formData = this.investmentDeclarationForm.value;
        localStorage.setItem("investmentDeclarationFormData", JSON.stringify(formData));
    };
    InvestmentDeclarationComponent.prototype.loadFormData = function (sectionName, isLoadingFirstTime) {
        if (isLoadingFirstTime === void 0) { isLoadingFirstTime = false; }
        var savedData = localStorage.getItem("investmentDeclarationFormData");
        this.houses = [{}];
        if (savedData) {
            var parsedSavedData = JSON.parse(savedData);
            // if there are more that one houses in the saved form data we need to add those as controls in the investmentDeclarationForm
            if (isLoadingFirstTime) {
                var houses = Object.keys(parsedSavedData["investmentSections"]["HRA"]);
                if (houses.length > 1) {
                    for (var i = 1; i < houses.length; i++) {
                        addHouse(this.investmentDeclarationForm
                            .get("investmentSections")
                            .get("HRA"), this.houses, this.formBuilder);
                    }
                }
            }
            this.investmentDeclarationForm.patchValue(parsedSavedData);
            var controls = this.investmentDeclarationForm.get("investmentSections").controls;
            for (var controlName in controls) {
                this.updateSectionTotalAmount(controlName);
            }
        }
        else {
            if (sectionName) {
                this.investmentDeclarationForm
                    .get("investmentSections")
                    .get(sectionName).reset();
                this.updateSectionTotalAmount(sectionName);
            }
        }
    };
    InvestmentDeclarationComponent.prototype.toggleIsPreviewScreen = function () {
        if (this.isPreviewScreen) {
            this.isPreviewScreen = false;
        }
        else {
            this.getIncomeTaxComparison();
        }
    };
    InvestmentDeclarationComponent.prototype.removeHouse = function (index) {
        this.houses.pop();
    };
    InvestmentDeclarationComponent.prototype.getIncomeTaxComparison = function () {
        var _this = this;
        var payloadArray = this.getInvestmentDeclarationPayload();
        this.spinnerService.openSpinnerDialog();
        this.investmentDeclarationService
            .getIncomeTaxComparisonApi(this.fiscal, this.employeeDetails.employeeId, payloadArray)
            .pipe(finalize(function () {
            _this.spinnerService.closeSpinnerDialog();
            _this.isPreviewScreen = !_this.isPreviewScreen;
        }))
            .subscribe(function (res) {
            _this.incomeTaxResponse = res;
        });
    };
    InvestmentDeclarationComponent.prototype.postIncomeTax = function () {
        var employeeInvestmentDeclarationListDTO = this.getInvestmentDeclarationPayload();
        var empTaxRegimeDTO = {
            employeeId: this.employeeDetails.employeeId,
            fiscal: this.fiscal,
            taxRegimeType: this.selectedRegime,
        };
        this.sendEmpTaxRegime(empTaxRegimeDTO, employeeInvestmentDeclarationListDTO);
    };
    InvestmentDeclarationComponent.prototype.sendEmployeeInvestmentDeclaration = function (empDeclarationReqBody) {
        var _this = this;
        this.investmentDeclarationService
            .postEmployeeInvestmentDeclarationApi(empDeclarationReqBody)
            .pipe(finalize(function () {
            _this.spinnerService.closeSpinnerDialog();
        }))
            .subscribe(function (res) {
            _this.snackBarService.add({
                message: "Your Investments have been added",
                config: {
                    duration: 2000,
                },
            });
            _this.investmentDeclarationService
                .getEmployeeInvestDeclarationByEmpId(_this.loginService.employeeDetails.employeeId, _this.fiscal)
                .subscribe(function (data) {
                _this.investmentDeclarationFormData = data;
                _this.setFormData();
            });
        }, function (err) {
            _this.snackBarService.add({
                message: err.error.message,
                config: {
                    duration: 2000,
                },
            });
        });
    };
    InvestmentDeclarationComponent.prototype.sendEmpTaxRegime = function (empTaxRegimeReqBody, empDeclarationReqBody) {
        var _this = this;
        this.investmentDeclarationService
            .postEmpTaxRegimeApi(empTaxRegimeReqBody)
            .pipe(finalize(function () {
            _this.spinnerService.closeSpinnerDialog();
        }))
            .subscribe(function (res) {
            _this.sendEmployeeInvestmentDeclaration(empDeclarationReqBody);
        }, function (err) {
            _this.snackBarService.add({
                message: err.error.message,
                config: {
                    duration: 2000,
                },
            });
        });
    };
    /*
  Don't use this function after the investment data is submitted
  */
    InvestmentDeclarationComponent.prototype.isHRAFormValid = function () {
        var e_6, _a;
        var hraFrom = this.investmentDeclarationForm.get("investmentSections").get("HRA");
        if (Object.keys(hraFrom.value).length === 1) {
            var isHouse1Untouched = true;
            var house = "House 1";
            try {
                for (var _b = tslib_1.__values(Object.keys(hraFrom.value[house])), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var key = _c.value;
                    if (hraFrom.value[house][key] !== null &&
                        hraFrom.value[house][key] !== false) {
                        isHouse1Untouched = false;
                        break;
                    }
                }
            }
            catch (e_6_1) { e_6 = { error: e_6_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_6) throw e_6.error; }
            }
            if (isHouse1Untouched) {
                return true;
            }
        }
        return hraFrom.valid;
    };
    InvestmentDeclarationComponent.prototype.getInvestmentDeclarationPayload = function () {
        var e_7, _a;
        var investmentSectionsValues = this.investmentDeclarationForm.value.investmentSections;
        var keys = Object.keys(this.investmentViaIdDict);
        var payloadArray = [];
        var amounts = {};
        for (var sectionKey in investmentSectionsValues) {
            if (sectionKey !== "HRA") {
                amounts = tslib_1.__assign({}, amounts, investmentSectionsValues[sectionKey]);
            }
            else {
                var payload = {};
                payload["amount"] = 0;
                payload["employeeId"] = this.employeeDetails.employeeId;
                payload["fiscal"] = this.fiscal;
                payload["investmentViaId"] = this.investmentViaIdDict["Rent Paid"];
                payload["status"] = "Active";
                payload["hras"] = [];
                for (var houseIndex = 0; houseIndex < this.houses.length; houseIndex++) {
                    var houseKey = "House " + (houseIndex + 1);
                    var hraForm = this.investmentSectionsForm.get("HRA").get(houseKey);
                    if (hraForm.status === "VALID" || hraForm.status === "DISABLED") {
                        var hraPayload = {
                            fromMonth: this.getMonthNumber(hraForm.get("fromMonth").value),
                            toMonth: this.getMonthNumber(hraForm.get("toMonth").value),
                            monthlyRentAmount: hraForm.get("monthlyRent").value,
                            metroCity: hraForm.get("metroCity").value || false,
                        };
                        payload["hras"].push(hraPayload);
                    }
                }
                payload["amount"] = this.sectionTotalAmount["HRA"];
                if (payload["amount"] !== 0) {
                    payloadArray.push(payload);
                }
            }
        }
        try {
            for (var keys_1 = tslib_1.__values(keys), keys_1_1 = keys_1.next(); !keys_1_1.done; keys_1_1 = keys_1.next()) {
                var key = keys_1_1.value;
                if (amounts[key] !== null && key != "Rent Paid") {
                    var amount = amounts[key];
                    var inv_id = this.investmentViaIdDict[key];
                    var payload = {};
                    payload["amount"] = amount;
                    payload["employeeId"] = this.employeeDetails.employeeId;
                    payload["fiscal"] = this.fiscal;
                    payload["investmentViaId"] = inv_id;
                    payload["status"] = "Active";
                    payload["hras"] = [];
                    payloadArray.push(payload);
                }
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (keys_1_1 && !keys_1_1.done && (_a = keys_1.return)) _a.call(keys_1);
            }
            finally { if (e_7) throw e_7.error; }
        }
        return payloadArray;
    };
    InvestmentDeclarationComponent.prototype.getNumberOfRowsArray = function (noOfInputs) {
        var itemsPerRow = 3; // Updated items per row to 3
        var arrLength = noOfInputs % itemsPerRow === 0
            ? noOfInputs / itemsPerRow
            : Math.ceil(noOfInputs / itemsPerRow);
        return Array.from({ length: arrLength }, function (_, index) { return index; });
    };
    InvestmentDeclarationComponent.prototype.getRowWiseSections = function (rowNumber) {
        var sections = this.getFormGroupsArray().slice(rowNumber * 3, (rowNumber + 1) * 3);
        return sections;
    };
    InvestmentDeclarationComponent.prototype.setSelectedRegime = function (regimeType) {
        this.selectedRegime = regimeType;
    };
    InvestmentDeclarationComponent.prototype.resetInvestmentDeclarationForm = function () {
        var hraFormGroup = this.investmentDeclarationForm.get("investmentSections").get("HRA");
        hraFormGroup.controls = {};
        this.houses.length = 0;
        addHouse(hraFormGroup, this.houses, this.formBuilder);
        var section80CFormGroup = this.investmentDeclarationForm.get("investmentSections").get("80C");
        var pfValue = section80CFormGroup.value["PF"];
        this.investmentDeclarationForm.get("investmentSections").reset();
        section80CFormGroup.patchValue({ PF: pfValue });
        this.resetAllSectionAmount(pfValue);
        this.saveInvestmentDeclarationForm();
        this.snackBarService.add({
            message: "Reset successful",
            config: { duration: 2000 },
        });
    };
    return InvestmentDeclarationComponent;
}());
export { InvestmentDeclarationComponent };
