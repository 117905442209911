import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
} from "@angular/core";
import {
  InvestmentDeclarationSectionInfo,
  InvestmentSectionCardData,
  SectionLimit,
} from "../investment-declaration/investment-declaration.util";
import { InvestmentDeclarationService } from "../shared/services/investment-declaration.service";
import { LoginService } from "../account/login.service";
import { forkJoin, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { getCurrentFiscalYear } from "../shared/app.utility";
import {
  PoiData,
  PoiHraData,
} from "../employee-add-poi/employee-add-poi.component";
import { SnackBarService } from "../shared/services/snackbar.service";
import { SpinnerService } from "../shared/services/spinner.service";
import { MatDialog } from "@angular/material";

export type PoiStatus =
  | "OPEN"
  | "SAVED"
  | "SUBMITTED"
  | "REJECTED"
  | "CONSIDERED"
  | "APPROVED"
  | "UPDATED";

@Component({
  selector: "app-employee-poi",
  templateUrl: "./employee-poi.component.html",
  styleUrls: ["./employee-poi.component.scss"],
})
export class EmployeePoiComponent implements OnInit {
  investmentSectionsCards: InvestmentSectionCardData[];
  sectionOptionsMap = new Map<string, any[]>();
  isAddingPoiForSectionMap = new Map<string, boolean>();
  sectionPoiDataMap = new Map<string, PoiData[]>();
  sectionPoiStatusRejectedMap = new Map<string, boolean>();
  cardsReady = false;
  empPoiStatus: PoiStatus;
  hraInvestmentViaId: number;
  hraDeclaredAmount: number | null = null;
  hraApprovedAmount: number | null = null;
  hraEmpInvestProofId: number | null = null;
  // numberOfRejectedDeclarations: number = 0;
  // totalDeclarations: number = 0;

  constructor(
    private investmentDeclarationService: InvestmentDeclarationService,
    private loginService: LoginService,
    private snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.spinnerService.openSpinnerDialog();
    this.setPoiStatus();
    this.initializeData();
  }

  initializeData(): void {
    forkJoin([
      this.investmentDeclarationService
        .getInvestmentDeclarationSectionApi()
        .pipe(
          catchError((error) => {
            console.error(error);
            return of([] as InvestmentDeclarationSectionInfo[]);
          })
        ),
      this.investmentDeclarationService.getInvestmentLimitApi().pipe(
        catchError((error) => {
          console.error(error);
          return of([]);
        })
      ),
    ])
      .pipe(
        switchMap(([sections, limits]) => {
          if (!Array.isArray(sections)) {
            return of({
              sections,
              limits,
              sectionOptionsInfo: new Map(),
              invProofData: {},
              invDecData: [],
            });
          }

          const sectionRequests = sections.map((section) =>
            this.investmentDeclarationService
              .getInvestmentBySectionApi(section.optionValue)
              .pipe(
                catchError((error) => {
                  console.error(error);
                  return of([]);
                }),
                map((investments) => ({
                  key: section.optionValue,
                  value: investments,
                }))
              )
          );

          return forkJoin(sectionRequests).pipe(
            switchMap((sectionResults) => {
              const sectionOptionsInfo = new Map(
                sectionResults.map((res) => [res.key, res.value])
              );

              return this.investmentDeclarationService
                .getEmpInvestProofDetails(
                  this.loginService.employeeDetails.employeeId,
                  getCurrentFiscalYear().toString()
                )
                .pipe(
                  catchError(() => of({})),
                  switchMap((invProofData) => {
                    return this.investmentDeclarationService
                      .getEmployeeInvestDeclarationByEmpId(
                        this.loginService.employeeDetails.employeeId,
                        getCurrentFiscalYear().toString()
                      )
                      .pipe(
                        catchError(() => of({})),
                        map((invDecData) => ({
                          sections,
                          limits,
                          sectionOptionsInfo,
                          invProofData,
                          invDecData,
                        }))
                      );
                  })
                );
            })
          );
        })
      )
      .subscribe(
        ({
          sections,
          limits,
          sectionOptionsInfo,
          invProofData,
          invDecData,
        }) => {
          this.sectionOptionsMap = sectionOptionsInfo;
          this.initIsAddingPoiForSectionMap(sections);
          this.initInvestmentSectionsCards(sections, limits);
          this.setHraInvestmentViaId(this.sectionOptionsMap);
          // this.numberOfRejectedDeclarations = 0;
          // this.totalDeclarations = 0;
          if (Object.keys(invProofData).length === 0) {
            this.setSectionPoiDataMapFromInvDecData(
              sectionOptionsInfo,
              invDecData
            );
          } else {
            console.log("invProofData", invProofData);

            for (const sectionName in invProofData) {
              const sectionData: PoiData[] = [];
              let rejectedEntry = false;
              for (const optionName in invProofData[sectionName]) {
                if (sectionName === "HRA" && invProofData["HRA"]) {
                  console.log("here", invProofData["HRA"]["Rent Paid"]);

                  const hraInfo = invProofData["HRA"]["Rent Paid"][0];
                  const hraData = hraInfo["hraProofs"];
                  this.hraDeclaredAmount = hraInfo.declaredAmount;
                  this.hraApprovedAmount = hraInfo.approvedAmount;
                  this.hraEmpInvestProofId = hraInfo.empInvestProofId;
                  if (hraInfo.status === "REJECTED") {
                    // this.numberOfRejectedDeclarations++;
                    // this.totalDeclarations++;
                    rejectedEntry = true;
                  }
                  // else if (hraInfo.status === "APPROVED") {
                  //   this.totalDeclarations++;
                  // }
                  hraData.forEach((el, i) => {
                    const approvedAmount = el.approvedAmount
                      ? el.approvedAmount.toString()
                      : "0";
                    let fileName = "";
                    const proofNameIdMap = new Map<string, number>();
                    el.hraInvestmentProofAttachments.forEach((attachment) => {
                      fileName += attachment.investmentProofFileName + ",";
                      proofNameIdMap.set(
                        attachment.investmentProofFileName,
                        attachment.hraInvestmentProofAttachmentId
                      );
                    });
                    //removing last comma
                    fileName = fileName.slice(0, -1);
                    const monthlyRent = el.monthlyRentAmount;
                    const fromMonth = el.fromMonth;
                    const toMonth = el.toMonth;
                    // months are 1 base index and plus one is to include toMonth in the duration
                    const duration =
                      (toMonth < 4 ? toMonth + 12 : toMonth) -
                      (fromMonth < 4 ? fromMonth + 12 : fromMonth) +
                      1;
                    const declared = parseFloat(monthlyRent) * duration;
                    const optionData: PoiHraData = {
                      particulars: `House ${i + 1}`,
                      declared: declared.toString(),
                      approved: approvedAmount,
                      investmentViaId: hraInfo.investmentViaId,
                      status: hraInfo.status,
                      fromMonth: fromMonth,
                      toMonth: toMonth,
                      monthlyRentAmount: monthlyRent,
                      metroCity: el.metroCity,
                      employeeRemark: el.employeeRemarks,
                      employerRemark: el.adminRemarks,
                      proofIds: proofNameIdMap,
                      proofFileName: fileName,
                      optionId: el.hraProofId,
                    };
                    sectionData.push(optionData);
                  });
                } else {
                  const data = invProofData[sectionName][optionName][0];
                  if (data.status === "REJECTED") {
                    // this.numberOfRejectedDeclarations++;
                    // this.totalDeclarations++;
                    rejectedEntry = true;
                  }
                  // else if (data.status === "APPROVED") {
                  //   this.totalDeclarations++;
                  // }
                  const approvedAmount = data.approvedAmount
                    ? data.approvedAmount.toString()
                    : "0";
                  let fileName = "";
                  const proofNameIdMap = new Map<string, number>();
                  data.investmentProofAttachments.forEach((el) => {
                    fileName += el.investmentProofFileName + ",";
                    proofNameIdMap.set(
                      el.investmentProofFileName,
                      el.investmentProofAttachmentId
                    );
                  });
                  //removing last comma
                  fileName = fileName.slice(0, -1);
                  // employeeRemark & employerRemark should be removed from here since we fetch them from a separate API now
                  const optionData: PoiData = {
                    particulars: optionName,
                    declared: data.declaredAmount,
                    investmentViaId: data.investmentViaId,
                    employeeRemark: data.employeeRemarks,
                    employerRemark: data.adminRemarks,
                    proofIds: proofNameIdMap,
                    proofFileName: fileName,
                    status: data.status,
                    approved: approvedAmount,
                    optionId: data.empInvestProofId,
                  };
                  sectionData.push(optionData);
                }
              }
              if (rejectedEntry) {
                this.sectionPoiStatusRejectedMap.set(sectionName, true);
              } else {
                this.sectionPoiStatusRejectedMap.set(sectionName, false);
              }
              this.sectionPoiDataMap.set(sectionName, sectionData);
            }
            this.setSectionPoiDataMapFromInvDecData(
              sectionOptionsInfo,
              invDecData
            );
          }
          this.setSectionsTotalAmount();
          this.spinnerService.closeSpinnerDialog();
        },
        (error) => {
          console.error("Error loading data:", error);
          this.spinnerService.closeSpinnerDialog();
        }
      );
  }
  setHraInvestmentViaId(sectionOptionsInfo: Map<string, any[]>) {
    for (const [sectionName, value] of sectionOptionsInfo.entries()) {
      if (sectionName === "HRA") {
        this.hraInvestmentViaId = value[0].investmentViaId;
      }
    }
  }

  setSectionsTotalAmount() {
    for (const [sectionName, data] of this.sectionPoiDataMap.entries()) {
      let total = 0;
      data.forEach((el) => {
        total += Number(el.declared);
      });
      this.investmentSectionsCards.find(
        (el) => el.name === sectionName
      ).totalAmountEntered = total;
    }
  }

  setSectionPoiDataMapFromInvDecData(
    sectionOptionsInfo: Map<string, any[]>,
    invDecData
  ) {
    for (const [sectionName, options] of sectionOptionsInfo.entries()) {
      if (options.length > 0) {
        let sectionData: PoiData[] = [];
        if (sectionName === "HRA") {
          const isHraDataSaved = this.sectionPoiDataMap.get("HRA");
          if (!isHraDataSaved && invDecData.length > 0) {
            const hraSectionData = invDecData.find((el) => {
              if (el.hras) {
                return el.hras.length !== 0;
              }
            });
            if (hraSectionData) {
              const hraData = hraSectionData.hras;
              hraData.forEach((el, i) => {
                const monthlyRent = el.monthlyRentAmount;
                const fromMonth = el.fromMonth;
                const toMonth = el.toMonth;
                // months are 1 base index and plus one is to include toMonth in the duration
                const duration =
                  (toMonth < 4 ? toMonth + 12 : toMonth) -
                  (fromMonth < 4 ? fromMonth + 12 : fromMonth) +
                  1;
                const declared = parseFloat(monthlyRent) * duration;
                const optionData: PoiHraData = {
                  particulars: `House ${i + 1}`,
                  declared: declared.toString(),
                  approved: "0",
                  investmentViaId: options[0].investmentViaId,
                  status: "OPEN",
                  fromMonth: fromMonth,
                  toMonth: toMonth,
                  monthlyRentAmount: monthlyRent,
                  metroCity: el.metroCity,
                };
                sectionData.push(optionData);
              });
            }
          }
        } else {
          options.forEach((option) => {
            if (!this.savedDataExist(sectionName, option.investmentLabelName)) {
              const declaredOption = invDecData.find(
                (incDecElement) =>
                  incDecElement.investmentViaId === option.investmentViaId
              );
              const optionData: PoiData = {
                particulars: option.investmentLabelName,
                declared: declaredOption
                  ? declaredOption.amount.toString()
                  : "0",
                status: "OPEN",
                approved: "0",
                investmentViaId: option.investmentViaId,
              };
              sectionData.push(optionData);
            }
          });
        }

        const savedData = this.sectionPoiDataMap.get(sectionName);
        if (!!savedData) {
          sectionData = [...savedData, ...sectionData];
        }
        this.sectionPoiDataMap.set(sectionName, sectionData);
      }
    }
  }

  savedDataExist(sectionName: string, optionName: string): boolean {
    const sectionData = this.sectionPoiDataMap.get(sectionName);
    return (
      !!sectionData && !!sectionData.find((el) => el.particulars === optionName)
    );
  }

  initIsAddingPoiForSectionMap(
    sections: InvestmentDeclarationSectionInfo[]
  ): void {
    if (sections.length > 0) {
      this.isAddingPoiForSectionMap = new Map<string, boolean>(
        sections.map(
          (section) =>
            [section.optionValue as string, false] as [string, boolean]
        )
      );
    }
  }

  initInvestmentSectionsCards(
    sections: InvestmentDeclarationSectionInfo[],
    limits: SectionLimit[]
  ): void {
    if (sections.length > 0) {
      this.investmentSectionsCards = sections.map((el) => {
        const foundLimit = limits.find((lim) => lim.section === el.optionValue);
        return {
          name: el.optionValue,
          tooltip: el.optionDesc,
          totalAmountEntered: 0,
          limit: foundLimit ? foundLimit.investmentLimit : 0,
        };
      });
      this.cardsReady = true;
    }
  }

  getNumberOfRowsArray(noOfInputs: number): number[] {
    const itemsPerRow = 3;
    const arrLength =
      noOfInputs % itemsPerRow === 0
        ? noOfInputs / itemsPerRow
        : Math.ceil(noOfInputs / itemsPerRow);
    return Array.from({ length: arrLength }, (_, index) => index);
  }

  getRowWiseSections(rowNumber: number): InvestmentSectionCardData[] {
    const sections = this.investmentSectionsCards.slice(
      rowNumber * 3,
      (rowNumber + 1) * 3
    );
    return sections;
  }

  toggleIsAddingPoiForSection(sectionName: string): void {
    this.isAddingPoiForSectionMap.set(sectionName, true);
  }

  isAddingPoi(): boolean {
    for (const [key, value] of this.isAddingPoiForSectionMap.entries()) {
      if (value) {
        return true;
      }
    }
    return false;
  }

  getAddPoiSectionName(): string {
    for (const [key, value] of this.isAddingPoiForSectionMap.entries()) {
      if (value) {
        return key;
      }
    }
  }

  getSectionOptionsData(): PoiData[] {
    for (const [key, value] of this.isAddingPoiForSectionMap.entries()) {
      if (value) {
        return this.sectionPoiDataMap.get(key);
      }
    }
  }

  updateHraOptionsData(newData) {
    this.sectionPoiDataMap.set("HRA", newData);
    this.cdr.detectChanges();
  }

  openNextSection(currentSectionName) {
    this.isAddingPoiForSectionMap.set(currentSectionName, false);
    this.initializeData();
    // let isNextSection = false;
    // for (const [
    //   sectionName,
    //   value,
    // ] of this.isAddingPoiForSectionMap.entries()) {
    //   if (isNextSection) {
    //     this.isAddingPoiForSectionMap.set(sectionName, true);
    //     break;
    //   }
    //   if (sectionName === currentSectionName) {
    //     isNextSection = true;
    //   }
    // }
  }

  closeAddPoiComponent(currentSectionName: string) {
    this.isAddingPoiForSectionMap.set(currentSectionName, false);
  }

  submitPoi() {
    this.investmentDeclarationService
      .submitPoiApi(
        this.loginService.employeeDetails.employeeId,
        getCurrentFiscalYear().toString()
      )
      .subscribe(
        (res) => {
          this.setPoiStatus();
          this.snackBarService.add({
            message: res.message,
            config: { duration: 2000 },
          });
        },
        (err) => {
          console.error(err);

          this.snackBarService.add({
            message: err.error.message,
            config: { duration: 2000 },
          });
        }
      );
  }

  setPoiStatus() {
    this.investmentDeclarationService
      .getEmployeePoiStatus(this.loginService.employeeDetails.employeeId)
      .subscribe((res) => {
        if (res[0] && res[0].investmentProofStatus) {
          this.empPoiStatus = res[0].investmentProofStatus;
        }
      });
  }

  isSubmitDisabled() {
    const disableForStatus: PoiStatus[] = [
      "CONSIDERED",
      "APPROVED",
      "SUBMITTED",
    ];
    return !this.empPoiStatus || disableForStatus.includes(this.empPoiStatus);
  }

  isOverallStatusApproved() {
    const disableForStatus: PoiStatus[] = ["CONSIDERED", "APPROVED"];
    return this.empPoiStatus && disableForStatus.includes(this.empPoiStatus);
  }

  isOverallStatusRejected() {
    const disableForStatus: PoiStatus[] = ["REJECTED"];
    return this.empPoiStatus && disableForStatus.includes(this.empPoiStatus);
  }

  isOverallStatusOpen() {
    const disableForStatus: PoiStatus[] = ["OPEN", "SAVED", "UPDATED"];
    return this.empPoiStatus && disableForStatus.includes(this.empPoiStatus);
  }

  isOverallStatusSubmitted() {
    const disableForStatus: PoiStatus[] = ["SUBMITTED"];
    return this.empPoiStatus && disableForStatus.includes(this.empPoiStatus);
  }

  downloadApprovedPoi() {
    const employeeId = this.loginService.employeeDetails.employeeId;
    const fiscalYear = getCurrentFiscalYear().toString();

    this.investmentDeclarationService
      .downloadApprovedPoi(employeeId, fiscalYear)
      .subscribe(
        (response) => {
          try {
            const responseJson = JSON.parse(response);
            const fileUrl = responseJson.message;
            if (fileUrl) {
              const link = document.createElement("a");
              link.href = fileUrl;
              link.setAttribute("download", "Approved_POI.pdf");
              link.setAttribute("target", "_blank");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } else {
              console.error("No valid file URL found in the response.");
            }
          } catch (error) {
            console.error("Error parsing API response:", error);
          }
        },
        (error) => {
          console.error("Error fetching attachment URL:", error);
          this.snackBarService.add({
            message: "Failed to download Form.",
            config: { duration: 2000 },
          });
        }
      );
  }

  getInvestmentStatusMessage() {
    if (this.isOverallStatusApproved()) {
      return "Your Proof of Investments (POIs) has been successfully approved.";
    }

    if (this.isOverallStatusRejected()) {
      return `Your Proof of Investments (POIs) have been rejected. Please review and resubmit the Proof of Investments accordingly.`;
      //`${this.numberOfRejectedDeclarations} out of the ${this.totalDeclarations} Proof of Investments (POIs) have been rejected. Please review and resubmit the Proof of Investments accordingly.`;
    }

    if (this.isOverallStatusOpen()) {
      return "Your Proof of Investments (POIs) is currently open. Please review and submit your details before the deadline.";
    }

    if (this.isOverallStatusSubmitted()) {
      return "Your Proof of Investments (POIs) has been successfully submitted. No further action is required at this time.";
    }

    return "Proof of Investments (POIs) window is not opened yet";
  }

  openConfirmationDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      panelClass: "confirmation-dialog-class",
    });
  }
}
