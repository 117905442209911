import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export function addHouse(
  formGroup: FormGroup,
  houses: any[],
  formBuilder: FormBuilder
): void {
  const houseCount = houses.length + 1;
  const houseGroupName = "House " + houseCount;
  const houseGroup = formBuilder.group({
    fromMonth: [null, Validators.required],
    toMonth: [null, Validators.required],
    monthlyRent: [null, Validators.required],
    metroCity: [false],
  });
  formGroup.addControl(houseGroupName, houseGroup);
  houses.push({});
}

export function removeHouse(houses: any[]): void {
  houses.pop();
}
