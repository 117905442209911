import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material";
import { of } from "rxjs";
import { catchError, finalize, mergeMap } from "rxjs/operators";
import {
  IEarningDeductionComponent,
  IEmployeeDeduction,
} from "src/app/core/interfaces/payroll";
import { PayrollService } from "src/app/shared/services/payroll.service";

@Component({
  selector: "app-employee-deductions",
  templateUrl: "./employee-deductions.component.html",
  styleUrls: ["./employee-deductions.component.scss"],
})
export class EmployeeDeductionsComponent implements OnInit {
  @Input() selectedPayslipMonth: number;
  @Input() selectedPayslipYear: number;
  @Input() element: any;
  @Output() deductionsFormStatus = new EventEmitter<any>();
  @Output() lwpChanged = new EventEmitter<any>();
  @ViewChild("addDeductionModal", { static: false }) addDeductionModal;
  employeeDeductionsList: IEmployeeDeduction[] = [];
  deductionsFormGroup: FormGroup;
  addDeductionFormGroup: FormGroup;
  allDeductionComponents: IEarningDeductionComponent[] = [];
  lwpSet: boolean = false;
  showSpinner: boolean = true;
  filteredDeductionComponents: IEarningDeductionComponent[] = [];

  constructor(
    private payrollService: PayrollService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getEmployeeDeductionKeys();
    this.deductionsFormGroup = this.formBuilder.group({});
    this.addDeductionFormGroup = this.formBuilder.group({
      deduction: ["", Validators.required],
      amount: ["", [Validators.required, Validators.min(1)]],
    });
  }

  getEmployeeDeductionKeys() {
    this.showSpinner = true;

    this.payrollService
      .getEmployeeDeductionsKeysApi()
      .pipe(
        mergeMap((keysResponse) => {
          this.allDeductionComponents = keysResponse;
          return this.payrollService
            .getEmployeeMonthlyDeductionsApi(
              this.selectedPayslipMonth,
              this.selectedPayslipYear,
              this.element.employeeId
            )
            .pipe(
              catchError((err) => {
                console.error(err);
                return of([]);
              })
            );
        }),
        catchError((err) => {
          console.error(err);
          return of([]);
        }),
        finalize(() => {
          this.showSpinner = false;
        })
      )
      .subscribe(
        (result) => {
          this.employeeDeductionsList = result.length ? result : [];

          this.initializeAndPatchForm(result);
        },
        (err) => {
          console.error(err);
        }
      );
  }

  initializeAndPatchForm(data: any[]): void {
    const controls: { [key: string]: FormControl } = {};

    data.forEach((deduction) => {
      controls[deduction.deduction] = new FormControl(
        null,
        [Validators.required, Validators.min(1)]
      );
    });

    this.deductionsFormGroup = this.formBuilder.group(controls);

    const patchValues = {};
    data.forEach((item) => {
      if (this.deductionsFormGroup.controls[item.deduction]) {
        patchValues[item.deduction] = item.amount;
      }
    });
    this.deductionsFormGroup.patchValue(patchValues);

    this.deductionsFormGroup.valueChanges.subscribe(() => {
      if (this.deductionsFormGroup.dirty) {
        this.emitFormStatus();
      }
    });

    this.emitFormStatus();
  }

  emitFormStatus(): void {
    const formValues = this.deductionsFormGroup.value;
    this.deductionsFormStatus.emit({
      hasValues: Object.values(formValues).some(
        (value) => value !== null && value !== ""
      ),
      formData: this.preparePayload(formValues),
      invalid: this.deductionsFormGroup.invalid,
      touched: this.deductionsFormGroup.dirty,
      empty: Object.values(formValues).every(
        (value) => value === null || value === ""
      ),
    });
  }

  preparePayload(formValues: any): any[] {
    return Object.keys(formValues)
      .filter((key) => Number(formValues[key]) > 0)
      .map((key) => ({
        employeeId: this.element.employeeId,
        year: this.selectedPayslipYear,
        deduction: key,
        amount: Number(formValues[key]),
        month: this.selectedPayslipMonth,
        fiscal: `${this.selectedPayslipYear}`,
      }));
  }

  onControlInput(controlName: string) {
    this.deductionsFormGroup.get(controlName).markAsTouched();
    this.emitFormStatus();
  }
  openAddDeductionModal() {
    this.filteredDeductionComponents = this.allDeductionComponents.filter(
      (component) =>
        !this.employeeDeductionsList.some(
          (deduction) => deduction.deduction === component.optionValue
        )
    );

    this.addDeductionFormGroup.reset();
    if (this.filteredDeductionComponents.length === 0) {
      this.addDeductionFormGroup.get("amount").disable();
    } else {
      this.addDeductionFormGroup.get("amount").enable();
    }
    this.dialog.open(this.addDeductionModal, { width: "400px" });
  }

  addControlToDeductionsForm() {
    if (this.addDeductionFormGroup.invalid) {
      return;
    }

    const newDeduction: IEmployeeDeduction = {
      employeeId: this.element.employeeId,
      year: this.selectedPayslipYear,
      deduction: this.addDeductionFormGroup.value.deduction,
      amount: Number(this.addDeductionFormGroup.value.amount),
      month: this.selectedPayslipMonth,
      fiscal: `${this.selectedPayslipYear}`,
    };
    this.employeeDeductionsList.push(newDeduction);

    this.deductionsFormGroup.addControl(
      newDeduction.deduction,
      new FormControl(newDeduction.amount, [Validators.required, Validators.min(1)])
    );

    this.deductionsFormGroup.markAsDirty();
    this.deductionsFormGroup.updateValueAndValidity();

    this.emitFormStatus();

    this.addDeductionFormGroup.reset();
    this.dialog.closeAll();
  }

  deleteDeduction(deductionId: number | null, optionValue: string): void {
    if (!deductionId) {
      this.employeeDeductionsList = this.employeeDeductionsList.filter(
        (deduction) => deduction.deduction !== optionValue
      );
      this.deductionsFormGroup.removeControl(optionValue);
      this.emitFormStatus();
      return;
    }

    this.payrollService
      .deleteEmployeeMonthlyDeductionsApi(deductionId)
      .subscribe(
        () => {
          this.employeeDeductionsList = this.employeeDeductionsList.filter(
            (deduction) => deduction.monthlyDeductionsId !== deductionId
          );
          this.deductionsFormGroup.removeControl(optionValue);
          this.emitFormStatus();
        },
        (error) => console.error("Error deleting deduction:", error)
      );
  }
  updateDeductionsWithIds(data) {
    const updatedList = [];
    this.employeeDeductionsList.forEach((el) => {
      if (!el.monthlyDeductionsId) {
        el.monthlyDeductionsId = data.find(
          (element) => el.deduction === element.deduction
        ).monthlyDeductionsId;
      }
      updatedList.push(el);
    });
    this.employeeDeductionsList = updatedList;
  }

  restrictInput(event: any, optionValue: string) {
    let inputValue = event.target.value;
    const daysInMonth = new Date(
      this.selectedPayslipYear,
      this.selectedPayslipMonth,
      0
    ).getDate();

    if (optionValue.toUpperCase() === "LWP" && inputValue > daysInMonth) {
      this.addDeductionFormGroup.controls["amount"].setValue(daysInMonth);
      this.deductionsFormGroup.controls["LWP"].setValue(daysInMonth);
    }
  }
}
