import { LOCAL_STORAGE_KEYS } from "src/app/account/login.service";
import * as i0 from "@angular/core";
var AuthService = /** @class */ (function () {
    function AuthService() {
    }
    AuthService.prototype.isUserLoggedIn = function () {
        var user = sessionStorage.getItem(LOCAL_STORAGE_KEYS.userLoginInfo);
        return !(user === null);
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
