import { OnInit } from "@angular/core";
import { MatSnackBarRef } from "@angular/material";
var SnackbarWithProgressComponent = /** @class */ (function () {
    function SnackbarWithProgressComponent(data, snackBarRef) {
        this.data = data;
        this.snackBarRef = snackBarRef;
        this.downloadCompleted = false;
        this.downloadStartedIcon = "../../assets/images/download-started.svg";
        this.downloadCompletedIcon = "../../assets/images/download-completed.svg";
        this.progress = 0;
        this.progressText = "Starting Download";
    }
    SnackbarWithProgressComponent.prototype.ngOnInit = function () { };
    SnackbarWithProgressComponent.prototype.closeSnackbar = function () {
        this.snackBarRef.dismiss();
    };
    SnackbarWithProgressComponent.prototype.runProgressBar = function () {
        if (this.progress > 0 && this.progress < 100)
            this.progressText = "Downloading";
        if (this.progress === 100) {
            if (this.data.action)
                this.data.action = "Close";
            this.downloadCompleted = true;
            this.progressText = "Complete";
        }
    };
    SnackbarWithProgressComponent.prototype.dismissWithAction = function () {
        this.snackBarRef.dismissWithAction();
    };
    return SnackbarWithProgressComponent;
}());
export { SnackbarWithProgressComponent };
