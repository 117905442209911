<div class="add-poi-wrapper">
  <div class="section-heading">Section {{ sectionName }}</div>
  <div class="amounts-strip" *ngIf="empPoiStatus === 'CONSIDERED'">
    <div class="row">
      <div class="col-6">
        <div class="d-flex justify-content-center">
          <div>Total Declared Amount :</div>
          <div class="ml-2">{{ hraDeclaredAmount }}</div>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-center">
          <div>Approved Amount :</div>
          <div class="ml-2">{{ hraApprovedAmount }}</div>
        </div>
      </div>
    </div>
  </div>
  <table mat-table [dataSource]="addPoiTableData">
    <ng-container [matColumnDef]="col.key" *ngFor="let col of displayedColumns">
      <th mat-header-cell *matHeaderCellDef>{{ col.label }}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="col.key === 'proofId'">
          <div
            *ngIf="
              !poiForm?.value[element.particulars]?.proofFileName;
              else fileName
            "
            class="proof"
            (click)="
              openUploadProofDialog(
                uploadProofDialog,
                element.particulars,
                true
              )
            "
          >
            Upload Proof
          </div>
          <ng-template #fileName>
            <div
              class="proof"
              *ngFor="
                let fileName of poiForm.value[
                  element.particulars
                ].proofFileName.split(',')
              "
              (click)="downloadFile(element.particulars, fileName)"
            >
              {{ fileName }}
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="col.key === 'options'">
          <div class="d-flex">
            <div
              (click)="
                openEditOptionDialog(
                  editOption,
                  element.particulars,
                  element['status']
                )
              "
              [matTooltip]="
                element['status'] !== 'APPROVED'
                  ? 'Edit'
                  : 'Approved declarations cannot be updated'
              "
            >
              <i
                class="far fa-edit fa-2x edit-icon"
                [ngClass]="{
                  'disable-icon': element['status'] === 'APPROVED'
                }"
                style="padding-right: 10px; color: grey"
              ></i>
            </div>
            <ng-container *ngIf="sectionName === 'HRA'">
              <div
                [matTooltip]="
                  element['status'] !== 'APPROVED'
                    ? 'Remove House'
                    : 'Approved declarations cannot be removed'
                "
                (click)="removeHouse(element.particulars, element['status'])"
              >
                <i
                  class="far fa-trash-alt fa-2x delete-icon"
                  [ngClass]="{
                    'disable-icon': element['status'] === 'APPROVED'
                  }"
                  style="padding-right: 10px; color: #c82727"
                ></i>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="col.key === 'declared'">
          <ng-container *ngIf="isStringValue(col.key)">
            {{ poiForm?.get(element.particulars).value[col.key] }}
          </ng-container>
        </ng-container>
        <ng-container *ngIf="col.key === 'remarks'">
          <button
            mat-button
            class="view-btn"
            [disabled]="element.status === 'OPEN' || element.status === 'SAVED'"
            (click)="openRemarksDialog(remarksDialog, element.particulars)"
          >
            View
          </button>
        </ng-container>
        <ng-container *ngIf="isSimpleValue(col.key)">
          <div
            [ngClass]="getStatusClass(element[col.key], col.key)"
            [matTooltip]="col.key === 'particulars' ? element[col.key] : null"
          >
            {{ element[col.key] }}
          </div>
        </ng-container>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()"></tr>
  </table>
  <div class="d-flex justify-content-end align-items-center footer">
    <button
      style="background-color: white; color: #38a3a5; border: 1px solid #38a3a5"
      class="mr-4"
      mat-button
      (click)="closeAddPoiComponent()"
    >
      Back
    </button>
    <ng-container *ngIf="sectionName === 'HRA' && !isSaveDisabled">
      <button
        style="background-color: #38a3a5; color: white"
        class="btn mr-4"
        (click)="addHouse()"
      >
        Add House
      </button>
    </ng-container>
    <ng-container *ngIf="sectionName === 'HRA' && !isSaveDisabled">
      <button
        style="background-color: #38a3a5; color: white"
        class="btn mr-4"
        (click)="openEmployeeHraRemarksDialog(employeeHraRemarks)"
      >
        Add Remark
      </button>
    </ng-container>
    <button
      [matTooltip]="empPoiStatus ? null : 'POI declaration window is not open'"
      style="background-color: #38a3a5; color: white"
      class="btn mr-4"
      [disabled]="
        isSaveDisabled || (!isThereAnyFormChanges && sectionName !== 'HRA')
      "
      (click)="onSaveAndNextButtonClick()"
    >
      Save as draft
    </button>
  </div>
</div>

<ng-template #uploadProofDialog style="border: 1px solid" let-data="data">
  <div class="upload-container">
    <div class="upload-heading">Upload Proof</div>
  </div>
  <div
    class="dropzone"
    appDnd
    (fileDropped)="onFileDropped($event)"
    (click)="fileInp.click()"
  >
    <input
      #fileInp
      style="display: none"
      type="file"
      id="fileInput"
      multiple
      (change)="fileBrowseHandler($event)"
    />
    <div class="dropzone-content">
      <img
        src="../../assets/images/file-icon.svg"
        alt=""
        class="file-icon-img"
      />
      <h4>Select a file to upload</h4>
      <h5>or drag and drop it here</h5>
    </div>
  </div>
  <div class="files-list-container" *ngIf="browsedFiles.length > 0">
    <div class="files-list">
      <div class="single-file" *ngFor="let file of browsedFiles; let i = index">
        <img
          src="../../assets/images/file-icon-blue.svg"
          class="file-uploaded-icon"
        />
        <div class="info">
          <h4 class="uploaded-file-name" [title]="file?.name">
            {{ file?.name }} ({{ formatBytes(file?.size, 2) }})
          </h4>
          <app-progress [progress]="file?.progress"></app-progress>
        </div>
        <div class="delete" (click)="removeFile(i)">
          <img src="../../assets/images/delete-icon.svg" />
        </div>
      </div>
    </div>
  </div>
  <div style="height: 20px"></div>
  <div
    class="row btn-container"
    style="
      margin-top: 12px;
      padding-top: 11px;
      height: 64px;
      background: #edfef8;
    "
  >
    <div class="col-auto" style="padding: 0; margin: 0">
      <button class="cancel-btn" mat-dialog-close>Cancel</button>
    </div>
    <div class="col-auto" style="padding: 0; margin: 0">
      <button mat-dialog-close class="upload-btn" (click)="addProofFileValue()">
        Add
      </button>
    </div>
  </div>
</ng-template>

<ng-template #editOption>
  <div class="dialog-wrapper">
    <div class="dialog-header">
      {{ selectedOptionName }}
    </div>
    <div class="option-form-wrapper">
      <form [formGroup]="getFormGroup()">
        <div *ngIf="sectionName !== 'HRA'; else hraSection">
          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>Declared Amount</mat-label>
                <input matInput type="number" formControlName="declared" />
              </mat-form-field>
            </div>
            <div class="col-6">
              <label>Attachments</label>
              <div
                *ngIf="!getFormGroup()?.value?.proofFileName; else fileName"
                class="proof"
                (click)="
                  openUploadProofDialog(uploadProofDialog, selectedOptionName)
                "
              >
                Upload Proof
              </div>
              <ng-template #fileName>
                <div class="scrollable">
                  <div class="file-container">
                    <div
                      *ngFor="
                        let file of getFormGroup()?.value?.proofFileName.split(
                          ','
                        );
                        let i = index
                      "
                      class="file-item"
                    >
                      <span class="file-name" [title]="file">{{ file }}</span>
                      <img
                        src="../../assets/images/delete-icon.svg"
                        class="delete-icon"
                        (click)="removeAddedAttachment(i)"
                      />
                    </div>
                  </div>
                </div>
                <button
                  class="btn add-more-btn"
                  (click)="
                    openUploadProofDialog(uploadProofDialog, selectedOptionName)
                  "
                >
                  Add More
                </button>
              </ng-template>
            </div>
          </div>
        </div>
        <ng-template #hraSection>
          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>From Month</mat-label>
                <input matInput type="number" formControlName="fromMonth" />
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>To Month</mat-label>
                <input matInput type="number" formControlName="toMonth" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>Monthly Rent</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="monthlyRentAmount"
                />
              </mat-form-field>
            </div>
            <div class="col-6">
              <label>Attachment</label>
              <div
                *ngIf="!getFormGroup()?.value?.proofFileName; else fileName"
                class="proof"
                (click)="
                  openUploadProofDialog(uploadProofDialog, selectedOptionName)
                "
              >
                Upload Proof
              </div>
              <ng-template #fileName>
                <div class="scrollable">
                  <div class="file-container">
                    <div
                      *ngFor="
                        let file of getFormGroup()?.value?.proofFileName.split(
                          ','
                        );
                        let i = index
                      "
                      class="file-item"
                    >
                      <span class="file-name">{{ file }}</span>
                      <img
                        src="../../assets/images/delete-icon.svg"
                        class="delete-icon"
                        (click)="removeAddedAttachment(i)"
                      />
                    </div>
                  </div>
                </div>
                <button
                  class="btn add-more-btn"
                  (click)="
                    openUploadProofDialog(uploadProofDialog, selectedOptionName)
                  "
                >
                  Add More
                </button>
              </ng-template>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-checkbox formControlName="metroCity"
                >Metro City</mat-checkbox
              >
            </div>
          </div>
        </ng-template>
        <div *ngIf="sectionName !== 'HRA'" class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="text-area">
              <mat-label>Employee Remarks</mat-label>
              <textarea matInput formControlName="employeeRemark"></textarea>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div
      class="row btn-container footer"
      style="
        margin-top: 12px;
        padding-top: 11px;
        height: 64px;
        background: #edfef8;
      "
    >
      <div class="col d-flex justify-content-end" style="padding: 0; margin: 0">
        <button class="cancel-btn" mat-dialog-close>Close</button>
        <button
          class="update-btn ml-3"
          mat-dialog-close
          (click)="updatePoiForm()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #remarksDialog>
  <div class="dialog-wrapper">
    <div class="dialog-header">Employer Remarks</div>
    <ng-container *ngIf="isEmployerRemarksPresent(); else noRemarks">
      <div class="remarks">
        <ul>
          <li class="remark" *ngFor="let remarkInfo of getEmployerRemarks()">
            <div>{{ remarkInfo.remark }}</div>
            <div class="remark-timestamp">{{ remarkInfo.remarkDate }}</div>
          </li>
        </ul>
      </div>
    </ng-container>
    <ng-template #noRemarks>
      <div class="no-remarks">No remarks available</div>
    </ng-template>
  </div>
</ng-template>

<ng-template #employeeHraRemarks>
  <div class="dialog-wrapper">
    <div class="dialog-header">Employee Remarks</div>
    <div class="remarks">
      <textarea
        class="hra-remark-text-area"
        matInput
        [(ngModel)]="hraRemark"
      ></textarea>
    </div>
  </div>
</ng-template>
