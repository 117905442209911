import { Subscription } from "rxjs";
import { Subject } from "rxjs";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSnackBarRef, SimpleSnackBar } from "@angular/material/snack-bar";
import { SnackbarWithProgressComponent } from "src/app/snackbar-with-progress/snackbar-with-progress.component";
export class SnackBarMessage {
  message: string;
  action?: string = null;
  config: MatSnackBarConfig = null;
}

const CLOSE_ACTION = "close";

@Injectable()
export class SnackBarService implements OnDestroy {
  private messageQueue: Subject<SnackBarMessage> =
    new Subject<SnackBarMessage>();
  private subscription: Subscription;
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private msgQueue: SnackBarMessage[] = [];
  private isInstanceVisible: any;

  constructor(public snackBar: MatSnackBar) {
    this.subscription = this.messageQueue.subscribe((message) => {
      this.snackBarRef = this.snackBar.open(
        message.message,
        message.action,
        message.config
      );
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Add a message
   * @param message The message to show in the snackbar.
   * @param action The label for the snackbar action.
   * @param config Additional configuration options for the snackbar.
   */
  add(sbMessage: SnackBarMessage): void {
    if (!sbMessage.action) {
      sbMessage.action = CLOSE_ACTION;
    }
    if (!sbMessage.config.panelClass) {
      sbMessage.config.panelClass = ["custom-snackbar-class"];
    }
    this.msgQueue.push(sbMessage);
    if (!this.isInstanceVisible) {
      this.showNext();
    }
  }

  private showNext() {
    if (this.msgQueue.length === 0) {
      return;
    }

    let message = this.msgQueue.shift();
    this.isInstanceVisible = true;
    this.snackBarRef = this.snackBar.open(
      message.message,
      message.action,
      message.config
    );
    this.snackBarRef.afterDismissed().subscribe(() => {
      this.isInstanceVisible = false;
      this.showNext();
    });
  }

  open(action: string, duration: number) {
    const snackBarRef = this.snackBar.openFromComponent(
      SnackbarWithProgressComponent,
      {
        data: { action: action },
        duration: duration * 1000,
        panelClass: ["custom-snackbar-class"],
      }
    );
    return snackBarRef.instance;
  }
}
