import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { of } from "rxjs";
import { catchError, concat, finalize, mergeMap } from "rxjs/operators";
import { PayrollDialogComponent } from "src/app/payroll-dialog/payroll-dialog.component";
import { PayrollDialogComponentInput } from "src/app/payroll-dialog/payroll-dialog.utility";
import { PayrollService } from "src/app/shared/services/payroll.service";
import { SnackBarService } from "src/app/shared/services/snackbar.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";

@Component({
  selector: "app-earnings-deductions",
  templateUrl: "./earnings-deductions.component.html",
  styleUrls: ["./earnings-deductions.component.scss"],
})
export class EarningsDeductionsComponent implements OnInit, OnDestroy {
  @Input() element: any;
  @Output() openEarningsAndDeductionsComponent = new EventEmitter<boolean>();
  @Input() selectedPayslipYear: number;
  @Input() selectedPayslipMonth: number;

  sectionName: string = "Earnings";
  triggerLwp: boolean = false;
  lwpNewValue: number = 0;
  selectedIndex: any = 0;
  earningsFormStatus = {
    hasValues: false,
    formData: [],
    touched: false,
    empty: false,
  };

  deductionsFormStatus = {
    hasValues: false,
    formData: [],
    touched: false,
    empty: false,
  };
  earningsExecuted: boolean = false;
  deductionsExecuted: boolean = false;
  lwpExecuted: boolean = false;
  months: { [key: number]: string } = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  updateButtonClicked: boolean = false;

  constructor(
    private payrollService: PayrollService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.changeAppContentWrapperStyle();
  }

  onTabChange(event: any) {
    this.selectedIndex = event.index;
    if (this.selectedIndex === 1) this.sectionName = "Deductions";
    else this.sectionName = "Earnings";
  }

  onBackClick() {
    if (this.updateButtonClicked) {
      this.openTriggerPayslipConfirmationDialog();
      this.updateButtonClicked = false;
    } else this.openEarningsAndDeductionsComponent.emit(false);
  }

  changeAppContentWrapperStyle() {
    const appContentWrapperDiv = document.querySelector(
      "#appContentWrapper"
    ) as HTMLElement;
    appContentWrapperDiv.style.paddingTop = "0";
    appContentWrapperDiv.style.backgroundColor = "white";
    appContentWrapperDiv.style.height = "calc(100% - 70px)";
  }

  resetAppContentWrapperStyle() {
    const appContentWrapperDiv = document.querySelector(
      "#appContentWrapper"
    ) as HTMLElement;
    appContentWrapperDiv.style.paddingTop = "24px";
    appContentWrapperDiv.style.backgroundColor = "";
    appContentWrapperDiv.style.height = "auto";
  }

  onFormStatusChange(status: any): void {
    this.earningsFormStatus = status;
  }

  onDeductionsFormStatusChange(status: any) {
    this.deductionsFormStatus = status;
  }

  onLwpChanged(newValue: any) {
    this.lwpNewValue = newValue;
    this.triggerLwp = true;
  }

  ngOnDestroy() {
    this.resetAppContentWrapperStyle();
  }

  openTriggerPayslipConfirmationDialog() {
    this.dialog.open(PayrollDialogComponent, {
      ["data"]: {
        title: "Confirmation",
        msg: `Are you sure you want to go back without generating the payslip?`,
        dialogType: "post",
        onYesClickFunction: () =>
          this.triggerPayslip(
            this.element.employeeId,
            this.selectedPayslipYear,
            this.selectedPayslipMonth
          ),
        onNoClickFunction: () => this.onBackClick(),
        noButtonText: "Yes, I will go back",
        yesButtonText: "Generate Payslip",
      } as PayrollDialogComponentInput,
    });
  }

  triggerPayslip(employeeId, payslipYear, payslipMonth) {
    if (this.updateButtonClicked) this.updateButtonClicked = false;
    this.spinnerService.openSpinnerDialog();
    this.payrollService
      .postEmployeeMonthlyPayroll(employeeId, payslipYear, payslipMonth)
      .subscribe(
        (res) => {
          this.spinnerService.closeSpinnerDialog();
          this.snackBarService.add({
            message: `Payslip Generated successfully for ${this.element.employeeId}`,
            action: "close",
            config: { duration: 3000 },
          });
          this.onBackClick();
        },
        (err) => {
          console.log(err);
          this.spinnerService.closeSpinnerDialog();
          this.snackBarService.add({
            message: `Error occured in generating payslip`,
            action: "close",
            config: { duration: 3000 },
          });
        }
      );
  }

  getEarningsFormGroup(value) {
  }

  getDeductionsFormGroup(value) {}

  onUpdateButtonClick() {
    of(null)
      .pipe(
        mergeMap(() => {
          this.spinnerService.openSpinnerDialog();
          if (
            this.earningsFormStatus.formData.length > 0 &&
            this.earningsFormStatus.hasValues &&
            this.earningsFormStatus.touched
          ) {
            this.earningsExecuted = true;
            return this.payrollService
              .postEmployeeMonthlyEarningsApi(this.earningsFormStatus.formData)
              .pipe(
                catchError((err) => {
                  this.earningsExecuted = false;
                  this.earningsFormStatus.touched = false;
                  console.log(err)
                  this.snackBarService.add({
                    message: err.error.message,
                    action: "Close",
                    config: {
                      duration: 2000,
                      panelClass: ["custom-snackbar-class"],
                    },
                  });
                  return of(null);
                })
              );
          }
          return of(null);
        }),

        mergeMap((res) => {
          if (
            this.deductionsFormStatus.formData.length > 0 &&
            this.deductionsFormStatus.hasValues &&
            this.deductionsFormStatus.touched
          ) {
            this.deductionsExecuted = true;
            return this.payrollService
              .postEmployeeMonthlyDeductionsApi(
                this.deductionsFormStatus.formData
              )
              .pipe(
                catchError((err) => {
                  this.deductionsExecuted = false;
                  this.deductionsFormStatus.touched = false;
                  this.snackBarService.add({
                    message: err.error.message,
                    action: "Close",
                    config: {
                      duration: 2000,
                      panelClass: ["custom-snackbar-class"],
                    },
                  });
                  return of(null);
                })
              );
          }
          return of(null);
        }),

        mergeMap((res) => {
          if (this.triggerLwp && this.lwpNewValue) {
            const body = {
              employeeId: this.element.employeeId,
              leaveMonth: this.selectedPayslipMonth,
              leaveYear: this.selectedPayslipYear,
              leaveCount: this.lwpNewValue,
            };
            this.lwpExecuted = true;
            return this.payrollService.postEmployeeMonthlyLeaves(body).pipe(
              catchError((err) => {
                this.lwpExecuted = false;
                this.triggerLwp = false;
                this.snackBarService.add({
                  message: err.error.message,
                  action: "Close",
                  config: {
                    duration: 2000,
                    panelClass: ["custom-snackbar-class"],
                  },
                });
                return of(null);
              })
            );
          }
          return of(null);
        }),
        finalize(() => {
          this.spinnerService.closeSpinnerDialog();
        })
      )
      .subscribe((results) => {
        if (this.earningsFormStatus.touched && this.earningsExecuted) {
          this.earningsFormStatus.touched = false;
          this.earningsExecuted = false;
          if (!this.updateButtonClicked) this.updateButtonClicked = true;
          this.snackBarService.add({
            message: "Employee Monthly Earnings added successfully!",
            action: "Close",
            config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
          });
        }

        if (this.deductionsFormStatus.touched && this.deductionsExecuted) {
          this.deductionsFormStatus.touched = false;
          this.deductionsExecuted = false;
          if (!this.updateButtonClicked) this.updateButtonClicked = true;
          this.snackBarService.add({
            message: "Employee Monthly Deductions added successfully",
            action: "Close",
            config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
          });
        }

        if (this.triggerLwp && this.lwpNewValue && this.lwpExecuted) {
          this.triggerLwp = false;
          this.lwpExecuted = false;
          if (!this.updateButtonClicked) this.updateButtonClicked = true;
          this.snackBarService.add({
            message: "LWP updated successfully",
            action: "Close",
            config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
          });
        }
      });
      
    if (
      !this.earningsFormStatus.touched &&
      !this.deductionsFormStatus.touched &&
      !this.triggerLwp
    ) {
      console.log("Please change any value to update them");
      this.snackBarService.add({
        message: "You have not made any changes",
        action: "Close",
        config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
      });
    } else if (this.triggerLwp && !this.lwpNewValue) {
      this.snackBarService.add({
        message: "Lwp value is null",
        action: "Close",
        config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
      });
       this.triggerLwp = false;
    } else if (
      this.earningsFormStatus.empty &&
      this.deductionsFormStatus.empty &&
      !this.lwpNewValue
    ) {
      console.log("everything empty");
      this.snackBarService.add({
        message: "All fields are empty",
        action: "Close",
        config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
      });
      }

  }
}
