import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { PayrollDialogComponent } from "src/app/payroll-dialog/payroll-dialog.component";
import { InvestmentValues } from "../investment-declaration.models";
import {
  PF_STRING,
  addHouse,
  removeHouse,
} from "../investment-declaration.util";
import { PayrollDialogComponentInput } from "src/app/payroll-dialog/payroll-dialog.utility";
import { SnackBarService } from "src/app/shared/services/snackbar.service";
import { AddInvestmentHRAFormComponent } from "./add-investment-hra-form/add-investment-hra-form.component";

@Component({
  selector: "add-investment",
  templateUrl: "./add-investment.component.html",
  styleUrls: ["./add-investment.component.scss"],
})
export class AddInvestmentComponent implements OnInit, OnDestroy {
  @Input() sectionName: string;
  @Input() totalLimit: string;
  @Input() amountEntered: string;
  @Input() tooltip: string;
  @Input() formGroupName: string;
  @Input() sectionInputs: string[];
  @Input() parentFormGroup: FormGroup;
  @Input() fiscalMonths: string[];
  @Input() houses: any[];
  @Input() isFormSubmitted: boolean;
  @Output() isAddingInvestmentToggleEvent = new EventEmitter<string>();
  @Output() openNextSectionEvent = new EventEmitter<string>();
  @Output() saveFormEvent = new EventEmitter<void>();
  @Output() loadSavedFormEvent = new EventEmitter<string>();
  @Output() updateSectionTotalAmount = new EventEmitter<string>();
  @Output() resetSectionAmount = new EventEmitter<string>();
  @Output() onRemoveHouseClickEmitter = new EventEmitter<number>();
  inputLimitMap = new Map<string, boolean>();
  initialValues: InvestmentValues;
  @ViewChild("hraFormComponent", { static: false })
  hraFormComponent: AddInvestmentHRAFormComponent;

  constructor(
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder
  ) {}
  ngOnDestroy(): void {
    this.resetAppContentWrapperStyle();
  }

  ngOnInit() {
    this.changeAppContentWrapperStyle();
    this.saveInitialValues();
    this.initializeInputLimitMapValues();
  }

  initializeInputLimitMapValues(): void {
    this.sectionInputs.forEach((sectionInputName) => {
      this.inputLimitMap.set(sectionInputName, false);
    });
  }

  updateSectionInputLimitValue(sectionInputName: string): void {
    if (
      this.getSectionFormGroup(this.sectionName).get(sectionInputName).value
        .length === 8
    ) {
      this.inputLimitMap.set(sectionInputName, true);
    } else {
      this.inputLimitMap.set(sectionInputName, false);
    }
  }

  saveInitialValues() {
    this.initialValues = this.getSectionFormGroup(this.sectionName).value;
  }

  changeAppContentWrapperStyle() {
    const appContentWrapperDiv = document.querySelector(
      "#appContentWrapper"
    ) as HTMLElement;
    appContentWrapperDiv.style.paddingTop = "0";
    appContentWrapperDiv.style.backgroundColor = "white";
    appContentWrapperDiv.style.height = "calc(100% - 70px)";
  }

  resetAppContentWrapperStyle() {
    const appContentWrapperDiv = document.querySelector(
      "#appContentWrapper"
    ) as HTMLElement;
    appContentWrapperDiv.style.paddingTop = "24px";
    appContentWrapperDiv.style.backgroundColor = "";
    appContentWrapperDiv.style.height = "auto";
  }

  /**
   * Calculates numbers of rows required in a section dropdown based on number of inputs in that section.
   * @param noOfInputs Number of inputs in a section.
   * @returns Array of numbers containing elements from 0 to calculated required number of rows - 1.
   */
  getNumberOfRowsArray(noOfInputs: number): number[] {
    const itemsPerRow = 4;
    const arrLength =
      noOfInputs % itemsPerRow === 0
        ? noOfInputs / itemsPerRow
        : Math.ceil(noOfInputs / itemsPerRow);
    return Array.from({ length: arrLength }, (_, index) => index);
  }

  trackByFn(index, item) {
    return index;
  }

  onRestButtonClick() {
    this.resetSectionValuesAndTotalAmount();
    this.snackBarService.add({
      message: "Reset successful",
      config: { duration: 2000 },
    });
  }

  removeAddedHousesFromForm() {
    const updatedValues = this.getSectionFormGroup(this.sectionName).value;
    const initialKeys = Object.keys(this.initialValues);
    const updatedKeys = Object.keys(updatedValues);
    if (updatedKeys.length !== initialKeys.length) {
      const newHouses = updatedKeys.filter((el) => !initialKeys.includes(el));
      newHouses.forEach((house) => {
        removeHouse(this.houses);
        this.getSectionFormGroup("HRA").removeControl(house);
      });
      return true;
    }
  }

  resetAndShowCards() {
    // if (this.sectionName === "HRA") {
    //   this.removeAddedHousesFromForm();
    // }
    this.resetSectionValuesAndTotalAmountToInitialValues();
    this.showAllSectionsCards();
  }

  resetSectionValuesAndTotalAmountToInitialValues() {
    const sectionFormGroup = this.getSectionFormGroup(this.sectionName);
    if (this.sectionName === "HRA") {
      sectionFormGroup.controls = {};
      this.houses.length = 0;
      for (const key in this.initialValues) {
        addHouse(sectionFormGroup, this.houses, this.formBuilder);
      }
      sectionFormGroup.setValue(this.initialValues);
    } else {
      sectionFormGroup.setValue(this.initialValues);
    }
    this.updateSectionTotalAmount.emit(this.sectionName);
  }

  resetSectionValuesAndTotalAmount() {
    const sectionFormGroup = this.getSectionFormGroup(this.sectionName);
    if (this.sectionName === "HRA") {
      sectionFormGroup.controls = {};
      this.houses.length = 0;
      addHouse(sectionFormGroup, this.houses, this.formBuilder);
      this.hraFormComponent.initSetup();
    } else {
      const pfValue = sectionFormGroup.value["PF"];
      sectionFormGroup.reset();
      sectionFormGroup.patchValue({ PF: pfValue });
    }
    this.updateSectionTotalAmount.emit(this.sectionName);
  }

  showAllSectionsCards() {
    this.isAddingInvestmentToggleEvent.emit(this.sectionName);
  }

  openNextSection() {
    this.openNextSectionEvent.emit(this.sectionName);
  }

  onBackClick() {
    if (this.isAnyValueChanged()) {
      this.openConfirmationDialogOnBackClick();
    } else {
      this.showAllSectionsCards();
    }
  }

  openConfirmationDialogOnBackClick() {
    this.dialog.open(PayrollDialogComponent, {
      ["data"]: {
        title: "Confirmation",
        msg: `Are you sure you want to go back without saving your changes?`,
        dialogType: "post",
        onYesClickFunction: () => this.onSaveButtonClick(),
        onNoClickFunction: () => this.resetAndShowCards(),
        noButtonText: "Back without saving",
        yesButtonText: "Save as draft",
      } as PayrollDialogComponentInput,
    });
  }

  onSaveButtonClick() {
    this.saveFormEvent.emit();
    this.showAllSectionsCards();
  }

  onSaveAndNextButtonClick() {
    this.saveFormEvent.emit();
    this.openNextSection();
  }

  isConvertibleToNumber(value: string): boolean {
    if (value.trim() === "") {
      return false;
    }
    return !isNaN(Number(value));
  }

  onKeyUp(event, sectionInputName: string) {
    if (event.key === "Backspace" || this.isConvertibleToNumber(event.key)) {
      if (this.sectionName !== "HRA") {
        this.updateSectionInputLimitValue(sectionInputName);
      }
      this.updateSectionTotalAmount.emit(this.sectionName);
    }
  }

  emitUpdateSectionTotalAmountForHRA() {
    this.updateSectionTotalAmount.emit("HRA");
  }

  getSectionFormGroup(formGroupName: string): FormGroup {
    return (this.parentFormGroup.get("investmentSections") as FormGroup).get(
      formGroupName
    ) as FormGroup;
  }

  isAnyValueChanged(): boolean {
    const updatedValues = this.getSectionFormGroup(this.sectionName).value;
    const initialKeys = Object.keys(this.initialValues);
    if (this.sectionName !== "HRA") {
      for (const key of initialKeys) {
        if (updatedValues[key] !== this.initialValues[key]) {
          return true;
        }
      }
    } else {
      const updatedKeys = Object.keys(updatedValues);
      if (updatedKeys.length !== initialKeys.length) {
        return true;
      } else {
        for (const key of initialKeys) {
          for (const control in this.initialValues[key]) {
            const initControlValue = this.initialValues[key][control];
            const updatedControlValue = this.getSectionFormGroup(
              this.sectionName
            )
              .get(key)
              .get(control).value;
            if (initControlValue !== updatedControlValue) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  onRemoveHouseClick(index: number) {
    this.onRemoveHouseClickEmitter.emit(index);
    this.updateSectionTotalAmount.emit(this.sectionName);
  }

  rowHasInputLimitMessage(rowNumber: number): boolean {
    const rowInputs = this.sectionInputs.slice(
      rowNumber * 4,
      (rowNumber + 1) * 4
    );
    for (const sectionInputName of rowInputs) {
      if (this.inputLimitMap.get(sectionInputName)) {
        return true;
      }
    }
    return false;
  }

  isDisabled(componentName: string) {
    return componentName === PF_STRING ? true : this.isFormSubmitted;
  }
}
