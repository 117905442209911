<div>
  <div class="progress-text" [ngStyle]="{'top': downloadCompleted ? '24px' : '18px'}">
    {{ progressText }}
  </div>
  <div class="snackbar-icon">
    <img
      [src]="downloadCompleted ? downloadCompletedIcon : downloadStartedIcon"
      alt=""
      style="width: 37px"
    />
  </div>
  <div style="padding-right: 25px; padding-left: 30px" *ngIf="!downloadCompleted">
    <mat-progress-bar
      mode="indeterminate"
      class="progress-bar"
    ></mat-progress-bar>
  </div>
  <div class="d-flex justify-content-end">
    <button class="action-button" (click)="dismissWithAction()" mat-button>
      {{ data?.action }}
    </button>
  </div>
</div>
