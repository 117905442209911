<div class="content-wrapper">
  <div class="emp-info-card row">
    <div class="col-md-2 emp-info-card-col">
      <div class="img-wrapper">
        <mat-icon class="user-icon">person</mat-icon>
      </div>
    </div>
    <div class="col-md-2 emp-info-card-col">
      <div class="d-flex flex-column">
        <div class="emp-info-title">Name</div>
        <div class="emp-info-value" style="word-break: keep-all">
          {{ employeeDetails?.firstName | titlecase}} {{ employeeDetails?.lastName | titlecase }}
        </div>
      </div>
    </div>
    <div class="col-md-4 emp-info-card-col">
      <div class="d-flex flex-column">
        <div class="emp-info-title">Email Id</div>
        <div
          class="emp-info-value ellipsis"
          [matTooltip]="employeeDetails?.employeeEmail"
        >
          {{ employeeDetails?.employeeEmail }}
        </div>
      </div>
    </div>
    <div class="col-md-2 emp-info-card-col">
      <div class="d-flex flex-column">
        <div class="emp-info-title">Employee Id</div>
        <div class="emp-info-value" style="word-break: keep-all">GSI 1234</div>
      </div>
    </div>
    <div class="col-md-2 emp-info-card-col">
      <div class="d-flex flex-column">
        <div class="emp-info-title">UAN No.</div>
        <div class="emp-info-value" style="word-break: keep-all">
          {{ employeeDetails?.uanNo }}
        </div>
      </div>
    </div>
    <div class="overlay">
      <div class="wave">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <defs>
            <linearGradient id="MyGradient" gradientTransform="rotate(269.26)">
              <stop offset="13.7%" stop-color="rgba(28, 114, 147, 0.1)" />
              <stop offset="67.15%" stop-color="rgba(28, 114, 147, 0.739841)" />
              <stop offset="88.88%" stop-color="#1c7293" />
            </linearGradient>
          </defs>
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="table-title-row row">
      <div class="table-title col-md-auto">YTD Summary</div>
      <div
        class="col-md column d-flex justify-content-end align-items-start"
        style="padding-top: 10px; padding-right: 0"
      >
        <select
          class="select-year text-start"
          [(ngModel)]="fiscal"
          name="year"
          (change)="getYtdDetails()"
        >
          <option *ngFor="let year of years" [ngValue]="year">
            {{ year }}-{{ (year % 100) + 1 }}
          </option>
        </select>
      </div>
    </div>
    <mat-spinner *ngIf="!ytdData && showSpinner" class="my-auto mx-auto spinner" [diameter]="60" ></mat-spinner>
    <ng-container *ngIf="ytdData">
      <table>
        <tr class="header-row">
          <ng-container *ngFor="let col of displayedColumns">
            <th *ngIf="!isMonthName(col)" class="{{ col }}">
              {{ col | camelCaseToTitle }}
            </th>
            <th *ngIf="isMonthName(col)" class="{{ col }}">
              {{ getMonthAbbreviation(col) }} {{ getYearByMonth(col) }}
            </th>
          </ng-container>
        </tr>
        <ng-container *ngFor="let header of headers; index as i">
          <tr class="sub-header" [ngClass]="{ 'first-sub-header': i === 0 }">
            <th>{{ header.displayName }}</th>
          </tr>
          <ng-container
            *ngFor="let row of ytdDetailsData[header.mapping]; index as j"
          >
            <tr
              class="data-row"
              [ngClass]="{
                'last-data-row-of-section':
                  j === ytdDetailsData[header.mapping].length - 1,
                'last-data-row-of-section-income-or-deductions':
                  j === ytdDetailsData[header.mapping].length - 1 &&
                  (header.mapping === 'INCOME' ||
                    header.mapping === 'DEDUCTIONS'),
                'days-worked-or-net-pay':
                  header.mapping === 'DAYS' || header.mapping === 'NET_PAY'
              }"
            >
              <ng-container *ngFor="let col of displayedColumns; index as k">
                <td
                  [id]="getTdId(i, j, k)"
                 
                  class="{{ col }}"
                  [matTooltip]="showTooltip(getTdId(i, j, k)) ? row[col] : null"
                >
                  {{ row[col] }}
                </td>
                <!-- <td *ngIf="col === 'items'" class="{{ col }}">
                  {{ row[col] | replaceUnderscore }}
                </td> -->
                <!-- <ng-template #zero>
                  <td
                    [id]="getTdId(i, j, k)"
                    *ngIf="!shouldDisplayValue(col, header)"
                    class="{{ col }}"
                    [matTooltip]="showTooltip(getTdId(i, j, k)) ? row[col] : null"
                  >
                    0
                  </td>
                </ng-template> -->
              </ng-container>
            </tr>
          </ng-container>
        </ng-container>
      </table>
    </ng-container>
  </div>
  <div style="height: 1px"></div>
</div>
