import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbreviateMonth'
})
export class AbbreviateMonthPipe implements PipeTransform {

  transform(value: any): any {
    if (typeof value !== 'string') {
      return value;
    }
    
    const monthsMap = {
      'January': 'Jan',
      'February': 'Feb',
      'March': 'Mar',
      'April': 'Apr',
      'May': 'May',
      'June': 'Jun',
      'July': 'Jul',
      'August': 'Aug',
      'September': 'Sep',
      'October': 'Oct',
      'November': 'Nov',
      'December': 'Dec'
    };

    return monthsMap[value] || value;
  }
}
