<div class="content-wrapper">
  <div class="container-fluid">
    <form class="search-form">
      <div class="row functions-row">
        <div class="col-auto mr-auto px-0">
          <div class="input-group">
            <div class="input-group-prepend">
              <button
                class="btn search-icon"
                (click)="getYtdDetailsForAllEmployees()"
              >
                <i class="fa fa-search"></i>
              </button>
              <input
                type="text"
                class="form-control"
                name="search"
                placeholder="Search by Name, Emp Id, Email"
                (keyup)="onSearch($event)"
                [(ngModel)]="searchInputValue"
              />
            </div>
          </div>
        </div>
        <div class="col-auto">
          <select
            class="fiscal-dropdown"
            [(ngModel)]="fiscal"
            name="fiscal"
            (change)="getYtdDetailsForAllEmployees()"
          >
            <option *ngFor="let year of years" [value]="year">
              {{ year }}-{{ (year % 100) + 1 }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>

    <mat-spinner *ngIf="!allEmployeesYtdDetails && showSpinner"class="my-auto mx-auto spinner" [diameter]="60" ></mat-spinner>

  <ng-container *ngIf="allEmployeesYtdDetails">
    <div class="table-wrapper shadow">
      <table
        mat-table
        [dataSource]="allEmployeesYtdDetails"
        multiTemplateDataRows
      >
        <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col">
          <ng-container *ngIf="col === 'expandAction'">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="table_cells">
              <mat-icon
                [ngStyle]="{ color: '#C1C1C1' }"
                style="cursor: pointer"
                (click)="
                  expandedElement = expandedElement === element ? null : element
                "
              >
                {{
                  expandedElement === element
                    ? "expand_more"
                    : "keyboard_arrow_right"
                }}
              </mat-icon>
            </td>
          </ng-container>
          <ng-container *ngIf="col !== 'expandAction'">
            <th mat-header-cell *matHeaderCellDef>
              {{ col | camelCaseToTitle }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              matTooltip="{{ showTooltip(col) ? element[col] : null }}"
            >
              {{ element[col] }}
            </td>
          </ng-container>
        </ng-container>
        <ng-container matColumnDef="expandedDetail" id="demo">
          <td
            class="expand_row"
            mat-cell
            *matCellDef="let element; let i = dataIndex"
            [attr.colspan]="displayedColumns?.length"
          >
            <div
              class="request-detail"
              [@detailExpand]="
                element == expandedElement ? 'expanded' : 'collapsed'
              "
            >
              <div
                class="inner-tables"
                [ngStyle]="{
                  'background-color': i % 2 === 0 ? '#FFFFFF' : '#F6F6F6'
                }"
              >
                <table
                  mat-table
                  [dataSource]="
                    empYtdComponentsTableData[element.email].incomeData
                  "
                >
                  <ng-container
                    *ngFor="let col of incomeCols"
                    [matColumnDef]="col"
                  >
                    <th mat-header-cell *matHeaderCellDef>
                      {{ columnDisplayNames[col] }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        col === "income" ? element["categories"] : element[col]
                      }}
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="incomeCols"></tr>
                  <tr mat-row *matRowDef="let row; columns: incomeCols"></tr>
                </table>
                <table
                  mat-table
                  [dataSource]="
                    empYtdComponentsTableData[element.email].deductionsData
                  "
                >
                  <ng-container
                    *ngFor="let col of deductionsCols"
                    [matColumnDef]="col"
                  >
                    <th mat-header-cell *matHeaderCellDef>
                      {{ columnDisplayNames[col] }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        col === "deductions"
                          ? element["categories"]
                          : element[col]
                      }}
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="deductionsCols"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: deductionsCols"
                  ></tr>
                </table>
              </div>
            </div>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="main-table-rows"
        ></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="request-detail-row expand_row"
        ></tr>
      </table>
    </div>
  </ng-container>
  <div class="paginator shadow" *ngIf="allEmployeesYtdDetails">
    <mat-paginator
      #paginator
      [length]="allEmployeesYtdDetails.data ? allEmployeesYtdDetails.data.length : 0"
      [pageSize]="10"
      [pageSizeOptions]="[10,20,50]"
      [pageIndex]="0"
    >
    </mat-paginator>
  </div>
</div>
