import * as i0 from "@angular/core";
import * as i1 from "./base.service";
var PayrollService = /** @class */ (function () {
    function PayrollService(baseService) {
        this.baseService = baseService;
        this.path = "";
        this.tabIndex = 0;
    }
    PayrollService.prototype.getPayrollDetailsApi = function (empId, month, year) {
        this.path = "payslip/monthlyPayslip?employeeId=" + empId + "&payslipYear=" + year + "&payslipMonth=" + month;
        return this.baseService.get(this.path);
    };
    PayrollService.prototype.getCtcPayslipDetailsApi = function (empId, month, year) {
        this.path = "ctcPayslip/monthlyPayslip?employeeId=" + empId + "&payslipYear=" + year + "&payslipMonth=" + month;
        return this.baseService.get(this.path);
    };
    PayrollService.prototype.calculateMonthlyPayrollApi = function (year, month) {
        this.path = "payslip/batchInsertPayslip?payslipYear=" + year + "&payslipMonth=" + month;
        return this.baseService.post(this.path);
    };
    PayrollService.prototype.getAllPayrollDetailsApi = function (year, month, pageNumber, pageSize, search) {
        this.path = "payslip/" + year + "/" + month;
        // Construct query string manually
        var params = "pageNumber=" + pageNumber + "&pageSize=" + pageSize;
        if (search && search.trim() !== "") {
            params += "&search=" + search;
        }
        // Manually add the query string to the URL
        return this.baseService.get(this.path + "?" + params);
    };
    /**
     * Fetch total employees and total payable amount for a given year and month.
     * @param year - Payroll year.
     * @param month - Payroll month.
     * @returns Observable with total employees and total payable amount.
     */
    PayrollService.prototype.getTotalEmployeeAndAmountApi = function (payrollYear, payrollMonth) {
        this.path = "payslip/summary/" + payrollYear + "/" + payrollMonth;
        return this.baseService.get(this.path);
    };
    PayrollService.prototype.getPayrollExcelApi = function (year, month) {
        this.path = "payrollExport/" + year + "/" + month;
        return this.baseService.getExcel(this.path);
    };
    PayrollService.prototype.getPayrollExcelSalarySheetApi = function (year, month) {
        this.path = "payrollExport/payrollExcelSalarySheet/" + year + "/" + month;
        return this.baseService.getExcel(this.path);
    };
    PayrollService.prototype.getEmployeeMonthlyEarningsApi = function (month, year, employeeId) {
        this.path = "empMonthlyEarnings?month=" + month + "&year=" + year + "&employeeId=" + employeeId;
        return this.baseService.get(this.path);
    };
    PayrollService.prototype.getEmployeeEarningsKeysApi = function () {
        this.path = "options/EMPLOYEE_MONTHLY_EARNINGS";
        return this.baseService.get(this.path);
    };
    PayrollService.prototype.getEmployeeDeductionsKeysApi = function () {
        this.path = "options/EMPLOYEE_MONTHLY_DEDUCTIONS";
        return this.baseService.get(this.path);
    };
    PayrollService.prototype.getEmployeeMonthlyDeductionsApi = function (month, year, employeeId) {
        this.path = "monthlyDeductions?month=" + month + "&year=" + year + "&employeeId=" + employeeId;
        return this.baseService.get(this.path);
    };
    PayrollService.prototype.postEmployeeMonthlyEarningsApi = function (data) {
        this.path = "empMonthlyEarnings";
        return this.baseService.post(this.path, data);
    };
    PayrollService.prototype.postEmployeeMonthlyDeductionsApi = function (data) {
        this.path = "monthlyDeductions";
        return this.baseService.post(this.path, data);
    };
    PayrollService.prototype.postEmployeeMonthlyPayroll = function (employeeId, payslipYear, payslipMonth) {
        this.path = "payslip?employeeId=" + employeeId + "&payslipYear=" + payslipYear + "&payslipMonth=" + payslipMonth;
        return this.baseService.post(this.path);
    };
    /**
  
     * Delete Employee Monthly Earnings
     * @param monthlyEarningsId The ID of the monthly earnings record to delete
     * @returns Observable<any>
     */
    PayrollService.prototype.deleteEmployeeMonthlyEarningsApi = function (monthlyEarningsId) {
        this.path = "empMonthlyEarnings?monthlyEarningsId=" + monthlyEarningsId;
        return this.baseService.delete(this.path);
    };
    PayrollService.prototype.deleteEmployeeMonthlyDeductionsApi = function (monthlyDeductionsId) {
        this.path = "monthlyDeductions?monthlyDeductionsId=" + monthlyDeductionsId;
        return this.baseService.delete(this.path);
    };
    PayrollService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PayrollService_Factory() { return new PayrollService(i0.ɵɵinject(i1.BaseService)); }, token: PayrollService, providedIn: "root" });
    return PayrollService;
}());
export { PayrollService };
