<div class="section-card position-relative">
  <div class="section-header-wrapper">
    <div
      *ngIf="statusRejected !== undefined && statusRejected"
      class="position-absolute status-ribben"
    >
      Rejected
    </div>
    <div class="section-header-row row">
      <span class="mr-3">Section {{ sectionName }}</span>
      <span><i [matTooltip]="tooltip" class="icon-info-sign"></i></span>
    </div>
  </div>
  <div class="limit-and-amount">
    <div class="row">
      <span class="text mr-2">Total Limit:</span>
      <div class="d-flex align-items-center">
        <img
          src="../../../assets/images/rupees-icon-dark.svg"
          alt="Rupees Icon"
          class="rupees-icon mr-1"
        />
      </div>
      <span class="value">{{ formatAmount(totalLimit) }}</span>
    </div>
    <div class="row">
      <span class="text mr-2">Amount:</span>
      <div class="d-flex align-items-center">
        <img
          src="../../../assets/images/rupees-icon-dark.svg"
          alt="Rupees Icon"
          class="rupees-icon mr-1"
        />
      </div>
      <span class="value">{{ formatAmount(amountEntered) }}</span>
    </div>
  </div>
  <div class="add-investment-button-wrapper">
    <button class="add-investment-button btn" (click)="onAddInvestmentClick()">
      Add Investment
    </button>
  </div>
</div>
