import { Component, Input } from "@angular/core";
import { Payslip } from "../payslip.utility";


@Component({
  selector: "app-income-card",
  templateUrl: "./income-card.component.html",
  styleUrls: ["./income-card.component.scss"],
})
export class IncomeCardComponent {
  @Input() income: Payslip;
  @Input() total: Payslip;
  @Input() amountType: string;

  trackByIncomeComponent(data: { label: string; amount: number }): string {
    return data.label;
  }
}
