/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./earnings-deductions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i12 from "@angular/material/tabs";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "./employee-earnings/employee-earnings.component.ngfactory";
import * as i15 from "./employee-earnings/employee-earnings.component";
import * as i16 from "../../shared/services/payroll.service";
import * as i17 from "@angular/forms";
import * as i18 from "@angular/material/dialog";
import * as i19 from "./employee-deductions/employee-deductions.component.ngfactory";
import * as i20 from "./employee-deductions/employee-deductions.component";
import * as i21 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i22 from "@angular/material/button";
import * as i23 from "./earnings-deductions.component";
import * as i24 from "../../shared/services/snackbar.service";
import * as i25 from "../../shared/services/spinner.service";
var styles_EarningsDeductionsComponent = [i0.styles];
var RenderType_EarningsDeductionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EarningsDeductionsComponent, data: {} });
export { RenderType_EarningsDeductionsComponent as RenderType_EarningsDeductionsComponent };
export function View_EarningsDeductionsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { employeeEarningsComponent: 0 }), i1.ɵqud(671088640, 2, { employeeDeductionsComponent: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 39, "div", [["class", "earnings-deductions-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 13, "div", [["class", "row header-row justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 6, "div", [["class", "col-2 main-heading"], ["matTooltip", "Back"]], null, [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.onBackClick() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["chevron_left"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "col-3 d-flex align-items-center justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " \u00A0 ", " "])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", "col-7 d-flex align-items-center justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Employee Id "])), (_l()(), i1.ɵted(16, null, [" \u00A0 : \u00A0 ", " "])), (_l()(), i1.ɵeld(17, 0, null, null, 16, "mat-tab-group", [["class", "earnings-deductions-tab mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.onTabChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatTabGroup_0, i11.RenderType_MatTabGroup)), i1.ɵdid(18, 3325952, null, 1, i12.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i12.MAT_TABS_CONFIG], [2, i13.ANIMATION_MODULE_TYPE]], null, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 3, { _tabs: 1 }), (_l()(), i1.ɵeld(20, 16777216, null, null, 6, "mat-tab", [["label", "Earnings"]], null, null, null, i11.View_MatTab_0, i11.RenderType_MatTab)), i1.ɵdid(21, 770048, [[3, 4]], 2, i12.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 4, { templateLabel: 0 }), i1.ɵqud(335544320, 5, { _explicitContent: 0 }), (_l()(), i1.ɵeld(24, 0, null, 0, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "app-employee-earnings", [], null, [[null, "earningsFormStatus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("earningsFormStatus" === en)) {
        var pd_0 = (_co.onFormStatusChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_EmployeeEarningsComponent_0, i14.RenderType_EmployeeEarningsComponent)), i1.ɵdid(26, 114688, [[1, 4]], 0, i15.EmployeeEarningsComponent, [i16.PayrollService, i17.FormBuilder, i18.MatDialog], { selectedPayslipMonth: [0, "selectedPayslipMonth"], selectedPayslipYear: [1, "selectedPayslipYear"], element: [2, "element"] }, { earningsFormStatus: "earningsFormStatus" }), (_l()(), i1.ɵeld(27, 16777216, null, null, 6, "mat-tab", [["label", "Deductions"]], null, null, null, i11.View_MatTab_0, i11.RenderType_MatTab)), i1.ɵdid(28, 770048, [[3, 4]], 2, i12.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 6, { templateLabel: 0 }), i1.ɵqud(335544320, 7, { _explicitContent: 0 }), (_l()(), i1.ɵeld(31, 0, null, 0, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 1, "app-employee-deductions", [], null, [[null, "deductionsFormStatus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deductionsFormStatus" === en)) {
        var pd_0 = (_co.onDeductionsFormStatusChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_EmployeeDeductionsComponent_0, i19.RenderType_EmployeeDeductionsComponent)), i1.ɵdid(33, 114688, [[2, 4]], 0, i20.EmployeeDeductionsComponent, [i16.PayrollService, i17.FormBuilder, i18.MatDialog], { selectedPayslipMonth: [0, "selectedPayslipMonth"], selectedPayslipYear: [1, "selectedPayslipYear"], element: [2, "element"] }, { deductionsFormStatus: "deductionsFormStatus" }), (_l()(), i1.ɵeld(34, 0, null, null, 7, "div", [["class", "d-flex justify-content-end align-items-center footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 2, "button", [["class", "mr-4"], ["mat-button", ""], ["style", "background-color: #38a3a5; color: white"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.triggerPayslip(((_co.element == null) ? null : _co.element.employeeId), _co.selectedPayslipYear, _co.selectedPayslipMonth) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i21.View_MatButton_0, i21.RenderType_MatButton)), i1.ɵdid(37, 180224, null, 0, i22.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Generate Payslip "])), (_l()(), i1.ɵeld(39, 0, null, null, 2, "button", [["class", "mr-4"], ["mat-button", ""], ["style", "background-color: #38a3a5; color: white"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUpdateButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i21.View_MatButton_0, i21.RenderType_MatButton)), i1.ɵdid(40, 180224, null, 0, i22.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Update "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Back"; _ck(_v, 5, 0, currVal_0); _ck(_v, 7, 0); var currVal_9 = "Earnings"; _ck(_v, 21, 0, currVal_9); var currVal_10 = _co.selectedPayslipMonth; var currVal_11 = _co.selectedPayslipYear; var currVal_12 = _co.element; _ck(_v, 26, 0, currVal_10, currVal_11, currVal_12); var currVal_13 = "Deductions"; _ck(_v, 28, 0, currVal_13); var currVal_14 = _co.selectedPayslipMonth; var currVal_15 = _co.selectedPayslipYear; var currVal_16 = _co.element; _ck(_v, 33, 0, currVal_14, currVal_15, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 7).inline; var currVal_2 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = _co.sectionName; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.months[_co.selectedPayslipMonth]; var currVal_5 = _co.selectedPayslipYear; _ck(_v, 12, 0, currVal_4, currVal_5); var currVal_6 = ((_co.element == null) ? null : _co.element.employeeId); _ck(_v, 16, 0, currVal_6); var currVal_7 = i1.ɵnov(_v, 18).dynamicHeight; var currVal_8 = (i1.ɵnov(_v, 18).headerPosition === "below"); _ck(_v, 17, 0, currVal_7, currVal_8); var currVal_17 = (i1.ɵnov(_v, 37).disabled || null); var currVal_18 = (i1.ɵnov(_v, 37)._animationMode === "NoopAnimations"); _ck(_v, 36, 0, currVal_17, currVal_18); var currVal_19 = (i1.ɵnov(_v, 40).disabled || null); var currVal_20 = (i1.ɵnov(_v, 40)._animationMode === "NoopAnimations"); _ck(_v, 39, 0, currVal_19, currVal_20); }); }
export function View_EarningsDeductionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-earnings-deductions", [], null, null, null, View_EarningsDeductionsComponent_0, RenderType_EarningsDeductionsComponent)), i1.ɵdid(1, 245760, null, 0, i23.EarningsDeductionsComponent, [i16.PayrollService, i24.SnackBarService, i18.MatDialog, i25.SpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EarningsDeductionsComponentNgFactory = i1.ɵccf("app-earnings-deductions", i23.EarningsDeductionsComponent, View_EarningsDeductionsComponent_Host_0, { element: "element", selectedPayslipYear: "selectedPayslipYear", selectedPayslipMonth: "selectedPayslipMonth" }, { openEarningsAndDeductionsComponent: "openEarningsAndDeductionsComponent", refreshTable: "refreshTable" }, []);
export { EarningsDeductionsComponentNgFactory as EarningsDeductionsComponentNgFactory };
