import { Component, OnInit } from "@angular/core";
import { EmployeeService } from "../shared/services/employee.service";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { LoginService } from "../account/login.service";
import { EmployeeCTC } from "../core/interfaces/user";

@Component({
  selector: "app-user-ctc-details",
  templateUrl: "./user-ctc-details.component.html",
  styleUrls: ["./user-ctc-details.component.scss"],
})
export class UserCtcDetailsComponent implements OnInit {
  employeeCtcDetails: EmployeeCTC[] = [];
  fiscalYear: any = getCurrentFiscalYear();
  totalAmount: number = 0;

  constructor(
    private employeeService: EmployeeService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.getEmployeeCTCDetails();
  }

  getEmployeeCTCDetails() {
    this.employeeService
      .getEmployeeCTCDetailsApi(
        this.loginService.employeeDetails.employeeId,
        this.fiscalYear
      )
      .subscribe(
        (data: EmployeeCTC[]) => {
          this.employeeCtcDetails = data.filter((item) => item.amount > 0);
          this.calculateTotal();
        },
        (error) => {
          console.error("Error fetching employee CTC details", error);
        }
      );
  }

  calculateTotal() {
    this.totalAmount = this.employeeCtcDetails.reduce(
      (sum, item) => sum + item.amount,
      0
    );
  }
}
