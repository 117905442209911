import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { InvestmentDeclarationService } from "../../shared/services/investment-declaration.service";
import {
  EmployeePoiInfo,
  SectionInvestmentData,
} from "../../core/interfaces/employeeProofStatus";
import { getCurrentFiscalYear } from "../../shared/app.utility";
import { Router } from "@angular/router";
import { SnackBarService } from "src/app/shared/services/snackbar.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { finalize } from "rxjs/operators";

const STATUS_OPTIONS = [
  "CONSIDERED",
  "REJECTED",
  "OPEN",
  "SAVED",
  "SUBMITTED",
  "UPDATED",
];

@Component({
  selector: "app-proof-of-income-admin",
  templateUrl: "./proof-of-income-admin.component.html",
  styleUrls: ["./proof-of-income-admin.component.scss"],
})
export class ProofOfIncomeAdminComponent implements OnInit {
  fiscal: any = getCurrentFiscalYear();
  displayedColumns: string[] = [
    "fullName",
    "investmentProofStatus",
    "submissionDate",
  ];
  dataSource = new MatTableDataSource<EmployeePoiInfo>();
  selectedEmployeeName: string;
  selectedEmployeeStatus: string;
  searchInputValue: string = "";
  sectionInvestmentData: SectionInvestmentData = {};
  years: number[] = [];
  selectedEmployeeId: string = "";
  investmentProofStatusCount: Record<string, number> = {};
  selectedFiscalYear: number;
  selectedCard: string = "";
  selectedRegime: string = "ALL";
  statusList: string[] = [];
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  isPoiNotReleased: boolean = false;
  isLoading: boolean = false;

  constructor(
    private investmentService: InvestmentDeclarationService,
    private router: Router,
    private snackBarService: SnackBarService,
    private spinnerService: SpinnerService
  ) {}
  ngOnInit() {
    this.spinnerService.openSpinnerDialog();
    this.statusList = STATUS_OPTIONS;
    this.fetchInvestmentProofStatusCount();
    this.years = Array.from(
      new Array(11),
      (x, i) => getCurrentFiscalYear() - i
    );
    this.selectedFiscalYear = getCurrentFiscalYear();
    this.selectCard("All");
  }

  onFiscalYearChange() {
    this.fiscal = this.selectedFiscalYear;
    this.getAllEmpInvestProof();
  }

  getStatusClass(status: string): string {
    switch (status.toUpperCase()) {
      case "CONSIDERED":
        return "status-approved";
      case "REJECTED":
        return "status-rejected";
      case "OPEN":
        return "status-open";
      case "SAVED":
        return "status-saved";
      case "SUBMITTED":
        return "status-submitted";
      case "UPDATED":
        return "status-updated";
      default:
        return "";
    }
  }

  fetchInvestmentProofStatusCount() {
    this.investmentService.getAdminEmpInvestProofCount(this.fiscal).subscribe(
      (response) => {
        this.investmentProofStatusCount = response;
      },
      (error) => {
        console.error("Error fetching investment proof status count:", error);
      }
    );
  }

  getAllEmpInvestProof(status?: string) {
    const taxRegime =
      this.selectedRegime === "ALL" ? undefined : this.selectedRegime;

    this.isLoading = true;
    this.sectionInvestmentData = {};

    this.investmentService
      .getAdminEmpInvestProof(this.fiscal, taxRegime, status)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            this.isLoading = false;
            if (this.selectedEmployeeId) {
              this.selectEmployee(this.selectedEmployeeId);
            }
          }, 500);
          this.spinnerService.closeSpinnerDialog();
        })
      )
      .subscribe(
        (response) => {
          response.sort((a, b) => a.fullName.localeCompare(b.fullName));

          this.dataSource.data = response.map((emp) => ({
            ...emp,
            submissionDate: this.convertDateFormat(emp.statusDate),
          }));

          if (!status && response.length === 0) {
            this.isPoiNotReleased = true;
          } else {
            this.isPoiNotReleased = false;
          }

          this.dataSource.paginator = this.paginator;

          if (response.length > 0) {
            this.selectedEmployeeName = response[0].fullName;
            this.selectedEmployeeId = response[0].employeeId;
            this.selectedEmployeeStatus = response[0].investmentProofStatus;
          } else {
            this.selectedEmployeeName = "";
            this.selectedEmployeeId = "";
            this.selectedEmployeeStatus = "";
          }
          this.dataSource.filterPredicate = (
            data: EmployeePoiInfo
          ) => {
            const searchText = this.searchInputValue.trim().toLowerCase();
            const matchesSearch = data.fullName
              .toLowerCase()
              .includes(searchText);
            const matchesStatus = data.investmentProofStatus
              .toLowerCase()
              .includes(searchText);
            return matchesSearch || matchesStatus;
          };
        },
        (error) => {
          console.error("Error fetching employee proof status:", error);
          this.snackBarService.add({
            message: "Failed to load employee data",
            config: { duration: 2000 },
          });
        }
      );
  }

  selectCard(status: string) {
    this.selectedCard = status;
    const apiStatus = status === "All" ? undefined : status;
    this.getAllEmpInvestProof(apiStatus);
  }

  onRegimeChange() {
    this.getAllEmpInvestProof();
  }

  getStatusKeys(): string[] {
    return Object.keys(this.investmentProofStatusCount);
  }

  convertDateFormat(dateStr: string): string | null {
    if (!dateStr) return null;

    const parts = dateStr.split("/");
    if (parts.length === 3) {
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
    return null;
  }

  fetchSectionInvestmentDetails(employeeId: string) {
    this.investmentService
      .getSectionInvestmentDetails(employeeId, this.fiscal)
      .subscribe(
        (response) => {
          this.sectionInvestmentData = response;
        },
        (error) => {
          console.error("Error fetching section investment details:", error);
        }
      );
  }

  applyFilter() {
    // to trigger filtering in mat-table
    this.dataSource.filter = this.searchInputValue.trim().toLowerCase();
  }

  selectEmployee(employeeId: string) {
    const selectedEmp = this.dataSource.data.find(
      (emp) => emp.employeeId === employeeId
    );
    if (selectedEmp) {
      this.selectedEmployeeId = selectedEmp.employeeId;
      this.selectedEmployeeName = selectedEmp.fullName;
      this.selectedEmployeeStatus = selectedEmp.investmentProofStatus;
      this.fetchSectionInvestmentDetails(this.selectedEmployeeId);
    }
  }

  viewForm() {
    if (this.selectedEmployeeId && this.selectedEmployeeName) {
      const encodedId = encodeURIComponent(this.selectedEmployeeId);
      const encodedName = encodeURIComponent(this.selectedEmployeeName);
      const encodedStatus = encodeURIComponent(this.selectedEmployeeStatus);
      this.router.navigate(
        ["dashboard", "proofOfIncome", "detail", encodedId],
        {
          queryParams: { name: encodedName, status: encodedStatus },
        }
      );
    }
  }
  releasePoi() {
    this.spinnerService.openSpinnerDialog();

    this.investmentService
      .releasePoiApi(this.selectedFiscalYear.toString())
      .subscribe(
        (res) => {
          this.snackBarService.add({
            message: res.message,
            config: { duration: 2000 },
          });

          this.selectedCard = "All";
          this.getAllEmpInvestProof();
        },
        (err) => {
          this.snackBarService.add({
            message: err.error.message,
            config: { duration: 2000 },
          });
          this.spinnerService.closeSpinnerDialog();
        }
      );
  }
}
