import { EventEmitter, OnInit, } from "@angular/core";
import { FormControl, Validators, } from "@angular/forms";
import { of } from "rxjs";
import { catchError, finalize, mergeMap } from "rxjs/operators";
var EmployeeDeductionsComponent = /** @class */ (function () {
    function EmployeeDeductionsComponent(payrollService, formBuilder, dialog) {
        this.payrollService = payrollService;
        this.formBuilder = formBuilder;
        this.dialog = dialog;
        this.deductionsFormStatus = new EventEmitter();
        this.lwpChanged = new EventEmitter();
        this.employeeDeductionsList = [];
        this.allDeductionComponents = [];
        this.lwpSet = false;
        this.showSpinner = true;
        this.filteredDeductionComponents = [];
    }
    EmployeeDeductionsComponent.prototype.ngOnInit = function () {
        this.getEmployeeDeductionKeys();
        this.deductionsFormGroup = this.formBuilder.group({});
        this.addDeductionFormGroup = this.formBuilder.group({
            deduction: ["", Validators.required],
            amount: ["", [Validators.required, Validators.min(1)]],
        });
    };
    EmployeeDeductionsComponent.prototype.getEmployeeDeductionKeys = function () {
        var _this = this;
        this.showSpinner = true;
        this.payrollService
            .getEmployeeDeductionsKeysApi()
            .pipe(mergeMap(function (keysResponse) {
            _this.allDeductionComponents = keysResponse;
            return _this.payrollService
                .getEmployeeMonthlyDeductionsApi(_this.selectedPayslipMonth, _this.selectedPayslipYear, _this.element.employeeId)
                .pipe(catchError(function (err) {
                console.error(err);
                return of([]);
            }));
        }), catchError(function (err) {
            console.error(err);
            return of([]);
        }), finalize(function () {
            _this.showSpinner = false;
        }))
            .subscribe(function (result) {
            _this.employeeDeductionsList = result.length ? result : [];
            _this.initializeAndPatchForm(result);
        }, function (err) {
            console.error(err);
        });
    };
    EmployeeDeductionsComponent.prototype.initializeAndPatchForm = function (data) {
        var _this = this;
        var controls = {};
        data.forEach(function (deduction) {
            controls[deduction.deduction] = new FormControl(null, [Validators.required, Validators.min(1)]);
        });
        this.deductionsFormGroup = this.formBuilder.group(controls);
        var patchValues = {};
        data.forEach(function (item) {
            if (_this.deductionsFormGroup.controls[item.deduction]) {
                patchValues[item.deduction] = item.amount;
            }
        });
        this.deductionsFormGroup.patchValue(patchValues);
        this.deductionsFormGroup.valueChanges.subscribe(function () {
            if (_this.deductionsFormGroup.dirty) {
                _this.emitFormStatus();
            }
        });
        this.emitFormStatus();
    };
    EmployeeDeductionsComponent.prototype.emitFormStatus = function () {
        var formValues = this.deductionsFormGroup.value;
        this.deductionsFormStatus.emit({
            hasValues: Object.values(formValues).some(function (value) { return value !== null && value !== ""; }),
            formData: this.preparePayload(formValues),
            invalid: this.deductionsFormGroup.invalid,
            touched: this.deductionsFormGroup.dirty,
            empty: Object.values(formValues).every(function (value) { return value === null || value === ""; }),
        });
    };
    EmployeeDeductionsComponent.prototype.preparePayload = function (formValues) {
        var _this = this;
        return Object.keys(formValues)
            .filter(function (key) { return Number(formValues[key]) > 0; })
            .map(function (key) { return ({
            employeeId: _this.element.employeeId,
            year: _this.selectedPayslipYear,
            deduction: key,
            amount: Number(formValues[key]),
            month: _this.selectedPayslipMonth,
            fiscal: "" + _this.selectedPayslipYear,
        }); });
    };
    EmployeeDeductionsComponent.prototype.onControlInput = function (controlName) {
        this.deductionsFormGroup.get(controlName).markAsTouched();
        this.emitFormStatus();
    };
    EmployeeDeductionsComponent.prototype.openAddDeductionModal = function () {
        var _this = this;
        this.filteredDeductionComponents = this.allDeductionComponents.filter(function (component) {
            return !_this.employeeDeductionsList.some(function (deduction) { return deduction.deduction === component.optionValue; });
        });
        this.addDeductionFormGroup.reset();
        if (this.filteredDeductionComponents.length === 0) {
            this.addDeductionFormGroup.get("amount").disable();
        }
        else {
            this.addDeductionFormGroup.get("amount").enable();
        }
        this.dialog.open(this.addDeductionModal, { width: "400px" });
    };
    EmployeeDeductionsComponent.prototype.addControlToDeductionsForm = function () {
        if (this.addDeductionFormGroup.invalid) {
            return;
        }
        var newDeduction = {
            employeeId: this.element.employeeId,
            year: this.selectedPayslipYear,
            deduction: this.addDeductionFormGroup.value.deduction,
            amount: Number(this.addDeductionFormGroup.value.amount),
            month: this.selectedPayslipMonth,
            fiscal: "" + this.selectedPayslipYear,
        };
        this.employeeDeductionsList.push(newDeduction);
        this.deductionsFormGroup.addControl(newDeduction.deduction, new FormControl(newDeduction.amount, [Validators.required, Validators.min(1)]));
        this.deductionsFormGroup.markAsDirty();
        this.deductionsFormGroup.updateValueAndValidity();
        this.emitFormStatus();
        this.addDeductionFormGroup.reset();
        this.dialog.closeAll();
    };
    EmployeeDeductionsComponent.prototype.deleteDeduction = function (deductionId, optionValue) {
        var _this = this;
        if (!deductionId) {
            this.employeeDeductionsList = this.employeeDeductionsList.filter(function (deduction) { return deduction.deduction !== optionValue; });
            this.deductionsFormGroup.removeControl(optionValue);
            this.emitFormStatus();
            return;
        }
        this.payrollService
            .deleteEmployeeMonthlyDeductionsApi(deductionId)
            .subscribe(function () {
            _this.employeeDeductionsList = _this.employeeDeductionsList.filter(function (deduction) { return deduction.monthlyDeductionsId !== deductionId; });
            _this.deductionsFormGroup.removeControl(optionValue);
            _this.emitFormStatus();
        }, function (error) { return console.error("Error deleting deduction:", error); });
    };
    EmployeeDeductionsComponent.prototype.updateDeductionsWithIds = function (data) {
        var updatedList = [];
        this.employeeDeductionsList.forEach(function (el) {
            if (!el.monthlyDeductionsId) {
                el.monthlyDeductionsId = data.find(function (element) { return el.deduction === element.deduction; }).monthlyDeductionsId;
            }
            updatedList.push(el);
        });
        this.employeeDeductionsList = updatedList;
    };
    EmployeeDeductionsComponent.prototype.restrictInput = function (event, optionValue) {
        var inputValue = event.target.value;
        var daysInMonth = new Date(this.selectedPayslipYear, this.selectedPayslipMonth, 0).getDate();
        if (optionValue.toUpperCase() === "LWP" && inputValue > daysInMonth) {
            this.addDeductionFormGroup.controls["amount"].setValue(daysInMonth);
            this.deductionsFormGroup.controls["LWP"].setValue(daysInMonth);
        }
    };
    return EmployeeDeductionsComponent;
}());
export { EmployeeDeductionsComponent };
