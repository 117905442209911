import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from "@angular/core";
import { catchError, finalize, take } from "rxjs/operators";
import { of } from "rxjs";
import { TOTAL_DEDUCTIONS_STRING, TOTAL_INCOME_STRING, MONTH, PAYSLIP_COMPONENT, PAYSLIP_COMPONENT_AMOUNT, EARNINGS, DEDUCTIONS, PAYSLIP_SUMMARY, SNACKBAR_LABEL, NAME_KEY, USER_DETAILS, NET_PAY, } from "./payslip.utility";
var PayslipComponent = /** @class */ (function () {
    function PayslipComponent(employeeService, snackBarService, payrollService, spinnerService, loginService) {
        this.employeeService = employeeService;
        this.snackBarService = snackBarService;
        this.payrollService = payrollService;
        this.spinnerService = spinnerService;
        this.loginService = loginService;
        this.payrollMonth = this.getCurrentMonth();
        this.payrollYear = this.getCurrentYear();
        this.dataEvent = new EventEmitter();
        this.userDetails = USER_DETAILS;
        this.netPay = 0;
        this.months = MONTH;
        this.years = [];
        this.durationInSeconds = 5;
        this.payslipExistForSelectedYearOrSelectedMonth = false;
        this.selectedTabIndex = 0;
        this.loadingCtcPayslip = false;
        this.currentTab = "monthly";
        this.selectedPayslipMonth = this.getCurrentMonth();
        this.selectedPayslipYear = this.getCurrentYear();
    }
    PayslipComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.employeeDetails = this.loginService.employeeDetails;
        if (!this.selectedEmployeeDetails) {
            this.selectedEmployeeDetails = this.employeeDetails;
            this.getMonthlyPayrollDetails();
        }
        else if (this.payrollMonth || this.payrollYear || this.payrollDetails) {
            this.selectedPayslipMonth = this.payrollMonth;
            this.selectedPayslipYear = this.payrollYear;
            this.employeeMonthlyPayrollDetails = this.payrollDetails;
            this.getMonthlyPdfDetailsAndNetPay();
            this.getCtcPdfDetailsAndNetPay();
        }
        this.selectedEmployeeDetails[NAME_KEY] = this.selectedEmployeeDetails.firstName + " " + this.selectedEmployeeDetails.lastName;
        this.empJoiningYear = parseInt(this.employeeDetails.dateOfJoining.split("/")[2]);
        this.years = Array.from({ length: this.selectedPayslipYear - this.empJoiningYear + 1 }, function (_, i) { return _this.selectedPayslipYear - i; });
        this.setLWP();
        this.setProfessionalDetails();
        this.emitPayslipMonthValue();
    };
    PayslipComponent.prototype.setLWP = function () {
        var _this = this;
        if (this.selectedEmployeeDetails) {
            this.payrollService
                .getEmployeeMonthlyDeductionsApi(this.selectedPayslipMonth, this.selectedPayslipYear, this.selectedEmployeeDetails.employeeId)
                .subscribe(function (lwp) {
                var element = lwp.find(function (element) { return element.deduction.toLowerCase() === "lwp"; });
                _this.selectedEmployeeDetails.lwpCount =
                    element && element.amount ? element.amount : 0;
                _this.setEffectiveWorkingDays(_this.selectedEmployeeDetails.lwpCount);
            });
        }
    };
    PayslipComponent.prototype.setProfessionalDetails = function () {
        if (this.selectedEmployeeDetails &&
            this.employeeService.employeePersonalDetails) {
            var _a = this.employeeService.employeePersonalDetails, department = _a.department, designation = _a.designation;
            this.selectedEmployeeDetails.department = department;
            this.selectedEmployeeDetails.designation = designation;
        }
    };
    PayslipComponent.prototype.setEffectiveWorkingDays = function (lwpCount) {
        var daysInMonth = new Date(this.selectedPayslipYear, this.selectedPayslipMonth, 0).getDate();
        this.selectedEmployeeDetails.effectiveWorkingDays = daysInMonth - lwpCount;
    };
    PayslipComponent.prototype.emitPayslipMonthValue = function () {
        var data = this.selectedPayslipMonth;
        this.dataEvent.emit(data);
    };
    PayslipComponent.prototype.getMonthlyPayrollDetails = function () {
        var _this = this;
        this.spinnerService.openSpinnerDialog();
        this.payrollService
            .getPayrollDetailsApi(this.selectedEmployeeDetails.employeeId, this.selectedPayslipMonth, this.selectedPayslipYear)
            .pipe(take(1), catchError(function (error) {
            _this.netPay = 0;
            _this.payslipExistForSelectedYearOrSelectedMonth = false;
            return of(null);
        }), finalize(function () {
            _this.spinnerService.closeSpinnerDialog();
        }))
            .subscribe(function (res) {
            if (res) {
                _this.employeeMonthlyPayrollDetails = res;
                _this.getMonthlyPdfDetailsAndNetPay();
            }
            else {
                _this.payslipExistForSelectedYearOrSelectedMonth = false;
                _this.showPayslipNotFoundSnackbar();
            }
        });
    };
    PayslipComponent.prototype.getCtcPayslipDetails = function () {
        var _this = this;
        this.spinnerService.openSpinnerDialog();
        this.payrollService
            .getCtcPayslipDetailsApi(this.selectedEmployeeDetails.employeeId, this.selectedPayslipMonth, this.selectedPayslipYear)
            .pipe(take(1), catchError(function (error) {
            _this.payslipExistForSelectedYearOrSelectedMonth = false;
            return of(null);
        }), finalize(function () {
            _this.spinnerService.closeSpinnerDialog();
        }))
            .subscribe(function (res) {
            if (res) {
                _this.employeeCtcPayrollDetails = res;
                _this.getCtcPdfDetailsAndNetPay();
            }
            else {
                _this.payslipExistForSelectedYearOrSelectedMonth = false;
                _this.showPayslipNotFoundSnackbar();
            }
        });
    };
    PayslipComponent.prototype.showPayslipNotFoundSnackbar = function () {
        this.snackBarService.add({
            message: "Payslip does not exist for the selected month and year.",
            action: SNACKBAR_LABEL,
            config: {
                duration: this.durationInSeconds * 1000,
                panelClass: ["custom-snackbar-class"],
            },
        });
    };
    PayslipComponent.prototype.getCtcPdfDetailsAndNetPay = function () {
        this.payslipExistForSelectedYearOrSelectedMonth = true;
        if (this.employeeCtcPayrollDetails[PAYSLIP_SUMMARY]) {
            var payslipSummary = this.employeeCtcPayrollDetails[PAYSLIP_SUMMARY][0];
            this.fileName = payslipSummary.employeeId + "_" + payslipSummary.payslipMonth + "_" + payslipSummary.fiscal + "_CTC.pdf";
        }
        this.employeeCtcPayrollDetailsForPdf = this.pdfDetailsForCtc(this.employeeCtcPayrollDetails);
    };
    PayslipComponent.prototype.pdfDetailsForCtc = function (employeeCtcPayrollDetails) {
        var e_1, _a;
        var parsedData = [];
        var earnings = employeeCtcPayrollDetails.earnings;
        var earningsLength = earnings ? earnings.length : 0;
        if (earningsLength !== 0) {
            for (var i = 0; i < earningsLength; i++) {
                var data = {
                    earningsComponent: earnings[i]
                        ? earnings[i].payslipComponent.replace(/_/g, " ")
                        : "",
                    earningsComponentAmount: earnings[i]
                        ? earnings[i].componentAmount
                        : "",
                };
                parsedData.push(data);
            }
            if (employeeCtcPayrollDetails.payslipSummary) {
                var totalIncome = 0;
                try {
                    for (var _b = tslib_1.__values(employeeCtcPayrollDetails.payslipSummary), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var component = _c.value;
                        if (component.payslipComponent === "Total") {
                            totalIncome = component.componentAmount;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                parsedData.push({
                    earningsComponent: "Total Income",
                    earningsComponentAmount: totalIncome.toString(),
                });
            }
        }
        return parsedData;
    };
    PayslipComponent.prototype.getMonthlyPdfDetailsAndNetPay = function () {
        this.payslipExistForSelectedYearOrSelectedMonth = true;
        if (this.employeeMonthlyPayrollDetails[PAYSLIP_SUMMARY]) {
            var payslipSummary = this.employeeMonthlyPayrollDetails[PAYSLIP_SUMMARY][0];
            this.fileName = payslipSummary.employeeId + "_" + payslipSummary.payslipMonth + "_" + payslipSummary.fiscal + "_Monthly.pdf";
            var netPayComponent = this.employeeMonthlyPayrollDetails[PAYSLIP_SUMMARY].find(function (component) { return component.payslipComponent === NET_PAY; });
            this.netPay = netPayComponent ? netPayComponent.componentAmount : 0;
        }
        this.employeePayrollDetailsForPdf = this.monthlyPdfDetails(this.employeeMonthlyPayrollDetails);
    };
    PayslipComponent.prototype.monthlyPdfDetails = function (employeeMonthlyPayrollDetails) {
        var e_2, _a;
        var parsedData = [];
        var earnings = employeeMonthlyPayrollDetails.earnings;
        var deductions = employeeMonthlyPayrollDetails.deductions;
        var earningsLength = earnings ? earnings.length : 0;
        var deductionsLength = deductions ? deductions.length : 0;
        var counter = Math.max(earningsLength, deductionsLength);
        if (counter !== 0) {
            for (var i = 0; i < counter; i++) {
                var data = {
                    earningsComponent: earnings
                        ? earnings[i]
                            ? earnings[i].payslipComponent.replace(/_/g, " ")
                            : ""
                        : "",
                    earningsComponentAmount: earnings
                        ? earnings[i]
                            ? earnings[i].componentAmount
                            : ""
                        : "",
                    deductionsComponent: deductions
                        ? deductions[i]
                            ? deductions[i].payslipComponent.replace(/_/g, " ")
                            : ""
                        : "",
                    deductionsComponentAmount: deductions
                        ? deductions[i]
                            ? deductions[i].componentAmount
                            : ""
                        : "",
                };
                parsedData.push(data);
            }
            if (employeeMonthlyPayrollDetails.payslipSummary) {
                var totalIncome = 0;
                var totalDeductions = 0;
                try {
                    for (var _b = tslib_1.__values(employeeMonthlyPayrollDetails.payslipSummary), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var component = _c.value;
                        if (component.payslipComponent === TOTAL_DEDUCTIONS_STRING) {
                            totalDeductions = component.componentAmount;
                        }
                        else if (component.payslipComponent === TOTAL_INCOME_STRING) {
                            totalIncome = component.componentAmount;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                parsedData.push({
                    earningsComponent: "Total Income",
                    earningsComponentAmount: totalIncome.toString(),
                    deductionsComponent: "Total Deductions",
                    deductionsComponentAmount: totalDeductions.toString(),
                });
            }
        }
        return parsedData;
    };
    PayslipComponent.prototype.getCurrentMonth = function () {
        return new Date().getMonth() > 0 ? new Date().getMonth() : 12;
    };
    PayslipComponent.prototype.getCurrentYear = function () {
        return new Date().getMonth() > 0
            ? new Date().getFullYear()
            : new Date().getFullYear() - 1;
    };
    PayslipComponent.prototype.onMonthChange = function () {
        if (this.selectedTabIndex === 0) {
            this.getMonthlyPayrollDetails();
        }
        else if (this.selectedTabIndex === 1) {
            this.getCtcPayslipDetails();
        }
        this.emitPayslipMonthValue();
        this.setLWP();
    };
    PayslipComponent.prototype.onYearChange = function () {
        if (this.selectedPayslipYear === this.getCurrentYear()) {
            if (this.selectedPayslipMonth > this.getCurrentMonth()) {
                this.selectedPayslipMonth = 1;
            }
        }
        if (this.selectedTabIndex === 0) {
            this.getMonthlyPayrollDetails();
        }
        else if (this.selectedTabIndex === 1) {
            this.getCtcPayslipDetails();
        }
        this.setLWP();
    };
    PayslipComponent.prototype.getMonthlyEarningsOrDeductionsArray = function (type) {
        var e_3, _a;
        var result = [];
        var incomeDetails = this.employeeMonthlyPayrollDetails[type];
        if (incomeDetails) {
            try {
                for (var incomeDetails_1 = tslib_1.__values(incomeDetails), incomeDetails_1_1 = incomeDetails_1.next(); !incomeDetails_1_1.done; incomeDetails_1_1 = incomeDetails_1.next()) {
                    var item = incomeDetails_1_1.value;
                    result.push({
                        label: item[PAYSLIP_COMPONENT].replace(/_/g, " "),
                        amount: item[PAYSLIP_COMPONENT_AMOUNT],
                    });
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (incomeDetails_1_1 && !incomeDetails_1_1.done && (_a = incomeDetails_1.return)) _a.call(incomeDetails_1);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        return result;
    };
    PayslipComponent.prototype.getTotalMonthlyEarningsOrDeductions = function (type) {
        var e_4, _a;
        var total = [];
        if (this.employeeMonthlyPayrollDetails[PAYSLIP_SUMMARY]) {
            try {
                for (var _b = tslib_1.__values(this.employeeMonthlyPayrollDetails[PAYSLIP_SUMMARY]), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var item = _c.value;
                    if ((type === EARNINGS &&
                        item[PAYSLIP_COMPONENT] === TOTAL_INCOME_STRING) ||
                        (type === DEDUCTIONS &&
                            item[PAYSLIP_COMPONENT] === TOTAL_DEDUCTIONS_STRING)) {
                        total.push({
                            label: "Total",
                            amount: item[PAYSLIP_COMPONENT_AMOUNT],
                        });
                    }
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
        return total;
    };
    PayslipComponent.prototype.getEarningsOrDeductionsArrayForCTC = function (type) {
        var result = [];
        var ctcDetails = this.employeeCtcPayrollDetails[type] || [];
        if (ctcDetails) {
            ctcDetails.forEach(function (item) {
                result.push({
                    label: item.payslipComponent.replace(/_/g, " "),
                    amount: item.componentAmount,
                });
            });
        }
        return result;
    };
    PayslipComponent.prototype.getTotalCTCDetails = function (type) {
        var total = [];
        var payslipSummary = this.employeeCtcPayrollDetails.payslipSummary || [];
        payslipSummary.forEach(function (item) {
            if (type === "earnings" && item.payslipComponent === "Total") {
                total.push({
                    label: "Total",
                    amount: item.componentAmount,
                });
            }
        });
        return total;
    };
    PayslipComponent.prototype.showMonth = function (monthKey) {
        return (monthKey <= this.getCurrentMonth() &&
            this.getCurrentYear() == this.selectedPayslipYear);
    };
    PayslipComponent.prototype.showYear = function () {
        return this.getCurrentYear() != this.selectedPayslipYear;
    };
    PayslipComponent.prototype.onTabChange = function (event) {
        this.selectedTabIndex = event.index;
        this.payrollService.tabIndex = event.index;
        if (event.index === 1) {
            this.getCtcPayslipDetails();
        }
        else if (event.index === 0) {
            this.getMonthlyPayrollDetails();
        }
        if (!this.payslipExistForSelectedYearOrSelectedMonth) {
            this.showPayslipNotFoundSnackbar();
        }
    };
    return PayslipComponent;
}());
export { PayslipComponent };
