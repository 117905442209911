import { formatDate } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MatStepper,
  MatTabGroup,
} from "@angular/material";
import { EmployeeService } from "../shared/services/employee.service";
import * as moment from "moment";
import { AdminService } from "../shared/services/admin.service";
import { UserSettingsService } from "../shared/services/user-settings.service";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { SnackBarService } from "../shared/services/snackbar.service";

const PF_PREFERENCE_VALUES = {
  VARIABLE: "VARIABLE",
  FIXED: "FIXED",
  NO_PF: "NO_PF",
};
const PF_STRING = "PF";
const FISCAL = getCurrentFiscalYear(4);
const PF_CONFIGURABLE_STRING = "PF_CONFIGURABLE";
const PF_PREFERENCE_CONFIGURABLE_STRING = "PF_PREFERENCE_CONFIGURABLE";

const CTC_COMPONENTS_DEFAULT_VALUES = {
  HRA: "0",
  BASIC_PAY: "0",
  STANDARD_ALLOWANCE: "0",
  SPECIAL_ALLOWANCE: "0",
  WELFARE_CONTRIBUTION: "0",
  BONUS: "0",
  CONVEYANCE_ALLOWANCE: "0",
  LTA: "0",
  PF: "",
  GRATUITY: "0",
  GROSS_SALARY: "0",
  CTC: "0",
  TOTAL_FLEXI_BASKET: "0",
  LOYALTY_BONUS: "0",
  FOOD_WALLET: "0",
};

type Council = { label: string; id: number };

@Component({
  selector: "app-add-employee-details",
  templateUrl: "./add-employee-details.component.html",
  styleUrls: ["./add-employee-details.component.scss"],
})
export class AddEmployeeDetailsComponent implements OnInit, AfterViewInit {
  personalDetailsFormGroup: FormGroup;
  allFormgroups: any;
  bankDetailsFormGroup: FormGroup;
  ctcDetailsFormGroup: FormGroup;
  date: string;
  personalFormDetails: any = [];
  bankFormDetails: any = [];
  ctcFormDetails: any = [];
  CTCComponentsDetails: any[] = [];
  calculatedPayroll: any[] = [];
  durationInSeconds = 5;
  pfPreferenceTypes: string[];
  maxDate = new Date();
  pfConfigurable: boolean;
  pfPreferenceConfigurable: boolean;
  years: number[] = [];
  matStepperIcons: any[];
  displayErrors: boolean = true;
  ecList: Council[] = [];
  dcList: Council[] = [];
  firstName:string="";
  lastName:string="";
  disableCheckbox:boolean=true;
  @ViewChild("stepper", { static: false }) private stepper: MatStepper;
  @ViewChild('tabGroup', { static: false }) tabGroup: MatTabGroup;
  @ViewChild("resetBtn1", { static: false })
  resetButton1: ElementRef<HTMLButtonElement>;
  @ViewChild("resetBtn2", { static: false })
  resetButton2: ElementRef<HTMLButtonElement>;
  @ViewChild("resetBtn3", { static: false })
  resetButton3: ElementRef<HTMLButtonElement>;
  private form1Directive: FormGroupDirective;
  @ViewChildren("stepperIcon") private matStepperIconViewChildren;
  // idEngineeringCouncilDict: { [id: string]: {} } = {};
  idEngineeringCouncilDict: any = {};
  // idDeliveryCouncilDict: { [id: string]: {} } = {};
  idDeliveryCouncilDict: any = {};
  constructor(
    public dialogRef: MatDialogRef<AddEmployeeDetailsComponent>,
    private _formBuilder: FormBuilder,
    private employeeService: EmployeeService,
    private _snackBar: MatSnackBar,
    private adminService: AdminService,
    private userSettingsService: UserSettingsService,
    private cdr: ChangeDetectorRef,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    public resetDialogRef: MatDialogRef<any>
  ) {
    const currYear = new Date().getFullYear();
    for (let i = currYear; i >= currYear - 10; i--) {
      this.years.push(i);
    }
    let currentDate = new Date();
    this.date = formatDate(currentDate, "dd/MM/yyyy", "en-US");
    this.getCTCCompoenets();
  }
  ngAfterViewInit(): void {
    this.matStepperIcons = this.matStepperIconViewChildren.toArray();
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
    this.personalDetailsFormGroup = this._formBuilder.group({
      fullName: ["", Validators.required],
      emailAddress: [
        "",
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ],
      dateOfBirth: ["", Validators.required],
      dateOfJoining: ["", Validators.required],
      pan: ["", Validators.required],
      aadhaar: ["", Validators.pattern("^[0-9]{12}$")],
      permenantAddress: ["", Validators.required],
      correspondenceAddress: [""],
      ec: ["", Validators.required],
      dc: ["", Validators.required],
      uanNo: ["", Validators.required],
      pfNo: ["", Validators.required],
      employeeCode: ["", Validators.required],
      isChecked:[false]
    });
    this.bankDetailsFormGroup = this._formBuilder.group({
      bankAcctNo: ["", Validators.required],
      bankAcctName: ["", Validators.required],
      bankAcctAddress: ["", Validators.required],
      bankIFSC: ["", Validators.required],
    });
    this.ctcDetailsFormGroup = this._formBuilder.group({});
    this.getEngineeringCouncilList();
    this.getDeliveryCouncilList();
    this.allFormgroups = [
      this.personalDetailsFormGroup,
      this.bankDetailsFormGroup,
      this.ctcDetailsFormGroup,
    ];
    this.setPFPreferenceTypes();
  }

  getEngineeringCouncilList(): void {
    this.employeeService.getAllEngineeringCouncil().subscribe(
      (res) => {
        this.ecList = [];
        if (res) {
          for (let ec of res) {
            this.idEngineeringCouncilDict[ec.engineeringCouncil] = ec;
            const council: Council = {
              label: ec.engineeringCouncilName,
              id: ec.engineeringCouncil,
            };
            this.ecList.push(council);
          }
        }
      },
      (err) => {
        console.log(err);
        this.snackBarService.add({
          message: err.error.message,
          action: "close",
          config: { duration: 2000 },
        });
      }
    );
  }

  getDeliveryCouncilList(): void {
    this.employeeService.getAllDeliveryCouncil().subscribe(
      (res) => {
        this.dcList = [];
        if (res) {
          for (let dc of res) {
            this.idDeliveryCouncilDict[dc.deliveryCouncil] = dc;

            const council: Council = {
              label: dc.deliveryCouncilName,
              id: dc.deliveryCouncil,
            };
            this.dcList.push(council);
          }
        }
      },
      (err) => {
        console.log(err);
        this.snackBarService.add({
          message: err.error.message,
          action: "close",
          config: { duration: 2000 },
        });
      }
    );
  }

  setPFPreferenceTypes() {
    this.userSettingsService
      .getAllPFTypesApi()
      .subscribe((pfPreferenceType) => {
        this.pfPreferenceTypes = pfPreferenceType;
      });
  }

  addFormCOntrolsTOCtcDetailsForm(ctcComponentsDetails) {
    for (const el of ctcComponentsDetails) {
      if(el.type != "MONTHLY")
        {
          this.ctcDetailsFormGroup.addControl(
            el.ctcComponent,
            new FormControl(
              CTC_COMPONENTS_DEFAULT_VALUES[el.ctcComponent],
              Validators.required
            )
          );
        }

    }
  }

  setDefaultPFPreferenceValueAndPFConfigurable() {
    this.adminService
      .getConfigDetailsByFiscalApi(FISCAL)
      .subscribe((details) => {
        for (const detail of details) {
          if (detail.configuredSubComponent === PF_CONFIGURABLE_STRING) {
            if (detail.status) {
              this.pfConfigurable = true;
              for (const data of details) {
                if (
                  data.configuredSubComponent ===
                  PF_PREFERENCE_CONFIGURABLE_STRING
                ) {
                  if (!data.status) {
                    this.pfPreferenceConfigurable = false;
                    this.ctcDetailsFormGroup
                      .get(PF_STRING)
                      .patchValue(PF_PREFERENCE_VALUES.VARIABLE);
                    this.ctcDetailsFormGroup.get(PF_STRING).disable();
                  } else {
                    this.pfPreferenceConfigurable = true;
                  }
                  break;
                }
              }
            } else {
              this.pfConfigurable = false;
              this.ctcDetailsFormGroup
                .get(PF_STRING)
                .patchValue(PF_PREFERENCE_VALUES.NO_PF);
              this.ctcDetailsFormGroup.get(PF_STRING).disable();
            }
            break;
          }
        }
      });
  }

  getCTCCompoenets() {
    this.employeeService.getAllCTCComponentsApi().subscribe(
      (res) => {
        this.CTCComponentsDetails = res;
        this.addFormCOntrolsTOCtcDetailsForm(res);
        this.setDefaultPFPreferenceValueAndPFConfigurable();
      },
      (error) => {
        console.log("get all CTC Components details failed", error);
      }
    );
  }

  postEmployeePFPreference(empId) {
    const requestBody = {
      employeeId: empId,
      pfType: this.ctcDetailsFormGroup.get(PF_STRING).value,
      fiscal: FISCAL.toString(),
    };
    this.employeeService.postEmployeePFPreferenceApi(requestBody).subscribe(
      (res) => {
        this.snackBarService.add({
          message: "Employee pf preference added Successfully",
          action: "close",
          config: { duration: 2000},
        });
        this.postEmployeeCTCDetails(res.employeeId);
      },
      (err) => {
        console.log("pf preference addition failed", err);
        this.snackBarService.add({
          message: err.error.message,
          action: "close",
          config: { duration: 2000  },
        });
      }
    );
  }

  getFirstAndLastName(){
    let fullName=this.personalDetailsFormGroup.get("fullName").value;
    fullName=fullName.trim().replace(/\s+/g, ' ');
    const words=fullName.split(" ");
    this.firstName = words[0];
    if(words.length === 1){
       this.lastName = '.';
    }
    else{
        this.lastName = words.slice(1).join(' ');
    }
  }

  postEmployeeDetails() {
    this.getFirstAndLastName();
    if (this.personalDetailsFormGroup.valid) {
      let element = {
        cityType: "M",
        firstName: this.firstName,
        lastName: this.lastName,
        employeeEmail: this.personalDetailsFormGroup.get("emailAddress").value,
        employeeId: this.personalDetailsFormGroup.get("emailAddress").value,
        dateOfBirth: moment(
          this.personalDetailsFormGroup.get("dateOfBirth").value
        ).format("DD/MM/yyyy"),
        dateOfJoining: moment(
          this.personalDetailsFormGroup.get("dateOfJoining").value
        ).format("DD/MM/yyyy"),
        bankAcctNo: this.bankDetailsFormGroup.get("bankAcctNo").value,
        bankAcctAddress: this.bankDetailsFormGroup.get("bankAcctAddress").value,
        bankAcctName: this.bankDetailsFormGroup.get("bankAcctName").value,
        bankIFSC: this.bankDetailsFormGroup.get("bankIFSC").value,
        aadhaar: this.personalDetailsFormGroup.get("aadhaar").value,
        permenantAddress:
          this.personalDetailsFormGroup.get("permenantAddress").value,
        correspondenceAddress: this.personalDetailsFormGroup.get(
          "correspondenceAddress"
        ).value,
        uanNo: this.personalDetailsFormGroup.get("uanNo").value,
        pfNo: this.personalDetailsFormGroup.get("pfNo").value,
        pan: this.personalDetailsFormGroup.get("pan").value,
        deliveryCouncil: this.idDeliveryCouncilDict[this.personalDetailsFormGroup.get("dc").value],
        engineeringCouncil: this.idEngineeringCouncilDict[this.personalDetailsFormGroup.get("ec").value],
        employeeCode: this.personalDetailsFormGroup.get("employeeCode").value,
      };
      this.employeeService.postEmployeeDetailsApi(element).subscribe(
        (res) => {
          if (this.pfConfigurable && this.pfPreferenceConfigurable) {
            this.postEmployeePFPreference(res.employeeId);
          } else {
            this.postEmployeeCTCDetails(res.employeeId);
          }
          this.snackBarService.add({
            message: "Employee Personal Details Submitted Successfully.",
            action: "close",
            config: { duration: 2000,   },
          });
        },
        (error) => {
          console.log("addEmployeeService failed", error);
          if (error.status === 500) {
            this.snackBarService.add({
              message:
                "Some issue occurred Please contact the backend team to resolve this.",
              action: "close",
              config: { duration: 2000,   },
            });
          } else {
            this.snackBarService.add({
              message: error.error.message,
              action: "close",
              config: { duration: 2000,   },
            });
          }
        }
      );
    }
  }

  postEmployeeCTCDetails(empId: any) {
    if (this.ctcDetailsFormGroup.valid) {
      const requestBody = [];
      for (const ctcComponentDetail of this.CTCComponentsDetails) {
        if (ctcComponentDetail.ctcComponent !== PF_STRING && ctcComponentDetail.type != "MONTHLY" ) {
          const data = {
            amount: this.ctcDetailsFormGroup.get(
              ctcComponentDetail.ctcComponent
            ).value,
            ctcComponent: ctcComponentDetail.ctcComponent,
            employeeId: empId,
            fiscal: FISCAL.toString(),
            notes: "Some random text to see the change in CTC",
            status: "ACTIVE",
          };
          requestBody.push(data);
        }
      }
      this.employeeService.postEmployeeCTCDetailsApi(requestBody).subscribe(
        (res) => {
          this.onClose();
          this.snackBarService.add({
            message: "Employee CTC Details Submitted Successfully",
            action: "close",
            config: { duration: 2000,   },
          });
          this.employeeService.newUserAdded=true;
        },
        (error) => {
          if (error.status === 400) {
            this.snackBarService.add({
              message: error.error.message,
              action: "close",
              config: { duration: 2000,   },
            });
          } else {
            this.snackBarService.add({
              message: "Failed to submit ctc details.",
              action: "close",
              config: { duration: 2000,   },
            });
            console.log("postEmployeeCTCDetails failed", error);
          }
        }
      );
    }
  }

  getFormDetails() {
    console.log(this.ctcDetailsFormGroup);
    this.personalFormDetails = Object.keys(
      this.personalDetailsFormGroup.value
    ).map((key) => ({
      type: key,
      value: this.personalDetailsFormGroup.value[key],
    }));
    this.bankFormDetails = Object.keys(this.bankDetailsFormGroup.value).map(
      (key) => ({ type: key, value: this.bankDetailsFormGroup.value[key] })
    );
    this.ctcFormDetails = Object.keys(this.ctcDetailsFormGroup.value).map(
      (key) => ({ type: key, value: this.ctcDetailsFormGroup.value[key] })
    );

  }

  checkDateType(value: any) {
    if (value instanceof Date) {
      return true;
    }
    return false;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  calculateCTC() {
    console.log(this.ctcDetailsFormGroup);
    let data = [
      {
        ctcComponent: "BASIC_PAY",
        amount: this.ctcDetailsFormGroup.value.BASIC_PAY,
      },
      {
        ctcComponent: "HRA",
        amount: this.ctcDetailsFormGroup.value.HRA,
      },
      {
        ctcComponent: "CONVEYANCE_ALLOWANCE",
        amount: this.ctcDetailsFormGroup.value.CONVEYANCE_ALLOWANCE,
      },
      {
        ctcComponent: "BONUS",
        amount: this.ctcDetailsFormGroup.value.BONUS,
      },
      {
        ctcComponent: "SPECIAL_ALLOWANCE",
        amount: this.ctcDetailsFormGroup.value.SPECIAL_ALLOWANCE,
      },
      {
        ctcComponent: "STANDARD_ALLOWANCE",
        amount: this.ctcDetailsFormGroup.value.STANDARD_ALLOWANCE,
      },
      {
        ctcComponent: "WELFARE_CONTRIBUTION",
        amount: this.ctcDetailsFormGroup.value.WELFARE_CONTRIBUTION,
      },
      {
        ctcComponent: "GRATUITY",
        amount: this.ctcDetailsFormGroup.value.GRATUITY,
      },
      {
        ctcComponent: "LTA",
        amount: this.ctcDetailsFormGroup.value.LTA,
      },
      {
        ctcComponent:"FOOD_WALLET",
        amount: this.ctcDetailsFormGroup.value.FOOD_WALLET
      }
    ];
    this.employeeService.calculateCTCApi(data).subscribe(
      (res) => {
        this.calculatedPayroll = res;
        this.ctcDetailsFormGroup.patchValue({
          GROSS_SALARY: this.calculatedPayroll[0].amount,
        });
        this.ctcDetailsFormGroup.patchValue({
          CTC: this.calculatedPayroll[1].amount,
        });
        this.ctcDetailsFormGroup.patchValue({
          TOTAL_FLEXI_BASKET: this.calculatedPayroll[2].amount,
        });
        this.snackBarService.add({
          message: 'Value of ctc and gross pay are updated',
          action: "close",
          config: { duration: 2000 },
        });
      },
      (error) => {
        console.log("calculateCTC failed", error);
        this.snackBarService.add({
          message: error.error.message,
          action: "close",
          config: { duration: 2000 },
        });
      }
    );
  }

  openSnackbar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: this.durationInSeconds * 1000,
      panelClass: ['custom-snackbar-class']
    });
  }

  personalDetailsErrorMessage(formControlName) {
    if (
      Object.keys(
        this.personalDetailsFormGroup.controls[formControlName].errors
      )[0] === "required"
    ) {
      return "This field is required!";
    } else if (formControlName === "dateOfBirth") {
      switch (
        Object.keys(
          this.personalDetailsFormGroup.controls[formControlName].errors
        )[0]
      ) {
        case "matDatepickerMax":
          return "Employee should be at least 18 years old!";
      }
    } else if (
      formControlName === "emailAddress" ||
      formControlName === "employeeCode"
    ) {
      switch (
        Object.keys(
          this.personalDetailsFormGroup.controls[formControlName].errors
        )[0]
      ) {
        case "pattern":
          return "Enter a valid email address!";
      }
    } else if (formControlName === "aadhaar") {
      switch (
        Object.keys(
          this.personalDetailsFormGroup.controls[formControlName].errors
        )[0]
      ) {
        case "pattern":
          return "Aaadhaar number should be of 12 digits";
      }
    }
  }

  bankDetailsErrorMessage(formControlName) {
    if (
      Object.keys(
        this.bankDetailsFormGroup.controls[formControlName].errors
      )[0] === "required"
    ) {
      return "This field is required!";
    }
  }

  ctcDetailsErrorMessage(formControlName: string) {
    const control = this.ctcDetailsFormGroup.get(formControlName);
    if (control && control.errors) {
      return "This field is required!";
    }
    return "";
  }

  openReset(templateRef: TemplateRef<any>) {
    this.resetDialogRef = this.dialog.open(templateRef, {
      width: "280px",
      height: "140px",
    });
    this.resetDialogRef.afterClosed().subscribe((result) => {});
  }

  onResetNoClick() {
    this.resetDialogRef.close();
  }

  onRestYesClick() {
    this.stepper.reset();
    this.resetButton1.nativeElement.click();
    this.resetButton2.nativeElement.click();
    this.resetButton3.nativeElement.click();
    this.resetDialogRef.close();
  }
  resetForm() {
    this.clearErrorsAndMarkUntouched(this.personalDetailsFormGroup);
    this.clearErrorsAndMarkUntouched(this.bankDetailsFormGroup);
    this.clearErrorsAndMarkUntouched(this.ctcDetailsFormGroup);
  }

  clearErrorsAndMarkUntouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key).clearValidators();
    });
  }
  onNextClick(formName) {
    formName.markAllAsTouched();
  }


  getIconPath(index: number): string {
      switch (index) {
        case 0:
          return '../../assets/images/personal-details-active-icon.svg';
        case 1:
          return '../../assets/images/bank-details-active-icon.svg';
        case 2:
          return '../../assets/images/ctc-details-active-icon.svg'
        case 3:
          return '../../assets/images/review-details-active-icon.svg';
        case 4:
          return "";
      }
  }

  changeCheckboxValue($event:any){
    if($event.checked){
      this.personalDetailsFormGroup.patchValue({
        correspondenceAddress: this.personalDetailsFormGroup.get("permenantAddress").value
      });
    }
    else{
      this.personalDetailsFormGroup.patchValue({
        correspondenceAddress: ""
      });
    }
  }

  permanentAddressChanged($event:any){
    if($event.target.value)
      {
        this.disableCheckbox=false;
      }
      else
      {
        if(!this.disableCheckbox)
          this.personalDetailsFormGroup.patchValue({
            correspondenceAddress: this.personalDetailsFormGroup.get("permenantAddress").value
          });
        this.disableCheckbox=true;
      }

  }

  isValidDate(value:any):boolean{
    if(value.toString().includes("India Standard Time"))
      return true;
    return false;
  }

  nextTab(){
    if(this.tabGroup.selectedIndex<2)
    this.tabGroup.selectedIndex+=1;
  }

  previousTab(){
    if(this.tabGroup.selectedIndex>0)
      this.tabGroup.selectedIndex-=1;
  }

  getEcName(num:any){
    return this.idEngineeringCouncilDict[num].engineeringCouncilName;
  }

  getDcName(num:any){
    return this.idDeliveryCouncilDict[num].deliveryCouncilName;
  }

  onStepChange($event){
    this.getFormDetails();
}
}
