<div class="poi-container">
  <h2 class="header">Employee POI</h2>

  <div class="no-data-card" *ngIf="isPoiNotReleased">
    <div class="no-data-content">
      <p>
        Declaration window is not open yet. Please click on the release button
        to enable POI declaration for all employees.
      </p>
      <button mat-button class="release-btn" (click)="releasePoi()">
        Release
      </button>
    </div>
  </div>

  <!-- Card Section -->
  <div class="card-container">
    <div
      class="card"
      *ngFor="let status of getStatusKeys()"
      (click)="selectCard(status)"
      [ngClass]="{ selected: selectedCard === status }"
    >
      <div class="card-content">
        <h3>{{ investmentProofStatusCount[status] || 0 }}</h3>
        <p>{{ status }}</p>
      </div>
    </div>
  </div>

  <hr />

  <!-- Dropdown Filters -->
  <div class="dropdown-container">
    <div class="dropdown">
      <label>Fiscal Year</label>
      <mat-select
        [(value)]="selectedFiscalYear"
        (selectionChange)="onFiscalYearChange()"
      >
        <mat-option *ngFor="let year of years" [value]="year">
          {{ year }}
        </mat-option>
      </mat-select>
    </div>

    <div class="dropdown">
      <label>Filter Employees as per Regime</label>
      <mat-select
        [(value)]="selectedRegime"
        (selectionChange)="onRegimeChange()"
        placeholder="Select"
      >
        <mat-option value="ALL">All</mat-option>
        <mat-option value="NEW_REGIME">New Regime</mat-option>
        <mat-option value="OLD_REGIME">Old Regime</mat-option>
      </mat-select>
    </div>
  </div>

  <div class="content-container">
    <!-- Employee Details Table -->
    <div class="table-container">
      <div class="table-header-row">
        <h3 class="table-header">Employee Details</h3>
        <!-- <div class="status-dropdown">
          <mat-select
            [(value)]="selectedStatus"
            (selectionChange)="applyFilter()"
          >
            <mat-option [value]="''">All</mat-option>
            <mat-option *ngFor="let status of statusList" [value]="status">
              {{ status }}
            </mat-option>
          </mat-select>
        </div> -->
        <div class="table-search-container">
          <div class="input-container">
            <span class="search-icon"><i class="fa fa-search"></i></span>
            <input
              type="text"
              class="table-search"
              style="border-left: none"
              name="search"
              placeholder="Search"
              [(ngModel)]="searchInputValue"
              (keyup)="applyFilter()"
            />
          </div>
        </div>
      </div>
      <table
        mat-table
        [dataSource]="dataSource"
        class="employee-table"
        *ngIf="!isLoading && dataSource.filteredData.length > 0"
      >
        <ng-container matColumnDef="fullName">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td
            mat-cell
            *matCellDef="let employee"
            (click)="selectEmployee(employee.employeeId)"
            class="clickable"
          >
            {{ employee.fullName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="investmentProofStatus">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td
            mat-cell
            *matCellDef="let employee"
            [ngClass]="getStatusClass(employee.investmentProofStatus)"
          >
            {{ employee.investmentProofStatus }}
          </td>
        </ng-container>

        <ng-container matColumnDef="submissionDate">
          <th mat-header-cell *matHeaderCellDef>Date of Submission</th>
          <td mat-cell *matCellDef="let employee">
            {{
              employee.submissionDate
                ? (employee.submissionDate | date : "dd MMM yyyy")
                : "N/A"
            }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <div
        class="no-data-message"
        *ngIf="!isLoading && dataSource.filteredData.length === 0"
      >
        No Data Available
      </div>
      <div
        *ngIf="isLoading"
        style="
          position: relative;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>

      <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]">
      </mat-paginator>
    </div>

    <!-- Employee Investment Details Table -->
    <div class="detial-table">
      <h3 class="detail-header">{{ selectedEmployeeName }}</h3>

      <table class="investment-table">
        <thead>
          <tr>
            <th>Sections</th>
            <th>Total Declared in Rs.</th>
            <th>Total Approved in Rs.</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngIf="
              sectionInvestmentData &&
                (sectionInvestmentData | keyvalue).length > 0;
              else noData
            "
          ></tr>
          <tr *ngFor="let section of sectionInvestmentData | keyvalue">
            <td>{{ section.key }}</td>
            <td>{{ section.value.totalDeclaredAmount | number }}</td>
            <td>{{ section.value.totalApprovedAmount | number }}</td>
          </tr>
        </tbody>
      </table>

      <ng-template #noData>
        <tr>
          <td colspan="3">
            <div class="no-data-message">No Data Available</div>
          </td>
        </tr>
      </ng-template>

      <div
        class="table-footer"
        *ngIf="
          sectionInvestmentData && (sectionInvestmentData | keyvalue).length > 0
        "
      >
        <button class="view-form-btn" (click)="viewForm()">View Form</button>
      </div>
    </div>
  </div>
</div>
