import { environment } from "../../../environments/environment";

export type PagePermissions = { [page: string]: string[] };

export type FeaturesPermissions = {
  [page: string]: {
    write: string[];
    update: string[];
    delete: string[];
  };
};

const PROD_GROUPS = [
  "Payroll_Admin_prod",
  "Payroll_Accounts_prod",
  "Payroll_HR_prod",
];

const NON_PROD_GROUPS = [
  "Payroll_Admin_np",
  "Payroll_Accounts_np",
  "Payroll_HR_np",
];

const PAGE_TO_GROUPS_MAPPING_DEV: PagePermissions = {
  empDetails: [],
  payroll: [],
  allEmployeesYtdDetails: [],
  adminSettings: [],
  poiReview: [],
};

const PAGE_TO_GROUPS_MAPPING_BETA: PagePermissions = {
  empDetails: [...NON_PROD_GROUPS],
  payroll: ["Payroll_Admin_np", "Payroll_Accounts_np"],
  allEmployeesYtdDetails: ["Payroll_Admin_np", "Payroll_Accounts_np"],
  adminSettings: ["Payroll_Admin_np", "Payroll_Accounts_np"],
  poiReview: ["Payroll_Admin_np", "Payroll_Accounts_np"],
};

const PAGE_TO_GROUPS_MAPPING_PROD: PagePermissions = {
  empDetails: [...PROD_GROUPS],
  payroll: ["Payroll_Admin_prod", "Payroll_Accounts_prod"],
  allEmployeesYtdDetails: ["Payroll_Admin_prod", "Payroll_Accounts_prod"],
  adminSettings: ["Payroll_Admin_prod", "Payroll_Accounts_prod"],
  poiReview: ["Payroll_Admin_np", "Payroll_Accounts_np"],
};

const FEATURE_TO_GROUPS_MAPPING_DEV: FeaturesPermissions = {
  empDetails: {
    write: [],
    update: [],
    delete: [],
  },
};

const FEATURE_TO_GROUPS_MAPPING_BETA: FeaturesPermissions = {
  empDetails: {
    write: ["Payroll_Admin_np", "Payroll_Accounts_np"],
    update: ["Payroll_Admin_np", "Payroll_Accounts_np"],
    delete: ["Payroll_Admin_np", "Payroll_Accounts_np"],
  },
};

const FEATURE_TO_GROUPS_MAPPING_PROD: FeaturesPermissions = {
  empDetails: {
    write: ["Payroll_Admin_prod", "Payroll_Accounts_prod"],
    update: ["Payroll_Admin_prod", "Payroll_Accounts_prod"],
    delete: ["Payroll_Admin_prod", "Payroll_Accounts_prod"],
  },
};

const ENV_PAGE_PERMISSIONS = {
  dev: PAGE_TO_GROUPS_MAPPING_DEV,
  beta: PAGE_TO_GROUPS_MAPPING_BETA,
  prod: PAGE_TO_GROUPS_MAPPING_PROD,
};

const ENV_FEATURE_PERMISSIONS = {
  dev: FEATURE_TO_GROUPS_MAPPING_DEV,
  beta: FEATURE_TO_GROUPS_MAPPING_BETA,
  prod: FEATURE_TO_GROUPS_MAPPING_PROD,
};

export const FEATURE_TO_GROUPS_MAPPING: FeaturesPermissions =
  ENV_FEATURE_PERMISSIONS[environment.environment];

export const PAGE_TO_GROUPS_MAPPING: PagePermissions =
  ENV_PAGE_PERMISSIONS[environment.environment];
