<div class="container-fluid dashboard">
  <mat-sidenav-container autosize>
    <mat-sidenav #sidenav class="sidenav" [mode]="'side'" opened="true">
      <img
        class="logo d-flex justify-content-left"
        src="../../assets/images/gemini_white_logo.svg"
      />
      <mat-nav-list>
        <a
          mat-list-item
          routerLink="/dashboard/home"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <img
            [src]="getIcon('/dashboard/home')"
            alt=""
            class="sidenav-icon"
            style="width: 23px"
          />
          <div class="sidenav-options">Home</div>
        </a>

        <a
          mat-list-item
          routerLink="/dashboard/empDetails"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngxPermissionsOnly="permissions.empDetails"
        >
          <img
            [src]="getIcon('/dashboard/empDetails')"
            alt=""
            class="sidenav-icon"
          />
          <div class="sidenav-options">Employee Details</div>
        </a>

        <a
          mat-list-item
          routerLink="/dashboard/payroll"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngxPermissionsOnly="permissions.payroll"
        >
          <img
            [src]="getIcon('/dashboard/payroll')"
            alt=""
            class="sidenav-icon"
          />
          <div class="sidenav-options">Payroll</div>
        </a>

        <a
          mat-list-item
          routerLink="/dashboard/investmentDeclaration"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <img
            [src]="getIcon('/dashboard/investmentDeclaration')"
            alt=""
            class="sidenav-icon"
            style="width: 15px"
          />
          <div class="sidenav-options">Investment Declaration</div>
        </a>
        <a
          mat-list-item
          routerLink="/dashboard/proofOfIncome"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngxPermissionsOnly="permissions.poiReview"
        >
          <img
            [src]="getIcon('/dashboard/proofOfIncome')"
            alt=""
            class="sidenav-icon"
            style="width: 15px"
          />
          <div class="sidenav-options">POI Review</div>
        </a>

        <a
          mat-list-item
          routerLink="/dashboard/employee-poi"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <img
          [src]="getIcon('/dashboard/employee-poi')"
            alt=""
            class="sidenav-icon"
            style="width: 15px"
          />
          <div class="sidenav-options">POI Declaration</div>
        </a>

        <a
          mat-list-item
          routerLink="/dashboard/userPayroll"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <img
            [src]="getIcon('/dashboard/userPayroll')"
            alt=""
            class="sidenav-icon"
          />
          <div class="sidenav-options">User Payroll</div>
        </a>

        <a
          mat-list-item
          routerLink="/dashboard/ytdDetails"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <img
            [src]="getIcon('/dashboard/ytdDetails')"
            alt=""
            class="sidenav-icon"
          />
          <div class="sidenav-options">YTD Details</div>
        </a>

        <a
          mat-list-item
          routerLink="/dashboard/userCtcDetials"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <img
            [src]="getIcon('/dashboard/userCtcDetials')"
            alt=""
            class="sidenav-icon"
          />
          <div class="sidenav-options">CTC Details</div>
        </a>

        <a
          mat-list-item
          routerLink="/dashboard/allEmployeesYtdDetails"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngxPermissionsOnly="permissions.allEmployeesYtdDetails"
        >
          <img
            [src]="getIcon('/dashboard/allEmployeesYtdDetails')"
            alt=""
            class="sidenav-icon"
          />
          <div class="sidenav-options">All Employees YTD Details</div>
        </a>

        <mat-divider></mat-divider>
        <a
          mat-list-item
          routerLink="/dashboard/admin-settings"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngxPermissionsOnly="permissions.adminSettings"
        >
          <img
            [src]="getIcon('/dashboard/admin-settings')"
            alt=""
            class="sidenav-icon"
          />
          <div class="sidenav-options">Admin Settings</div>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content style="overflow-x: hidden; height: 100%">
      <div
        class="header nav-bar d-flex align-items-md-center justify-content-between"
      >
        <div class="d-flex align-items-center">
          <button class="menu-btn ml-3" mat-icon-button>
            <mat-icon
              [matTooltip]="sidenav.opened ? 'Close Menu' : 'Open Menu'"
              (click)="sidenav.toggle()"
              >segment</mat-icon
            >
          </button>
          <div class="ml-3">
            <span
              class="head"
              style="margin-left: 10px; position: relative; top: 3px"
              >{{ HeaderContentText }}</span
            >
          </div>
        </div>
        <div class="d-flex align-items-center">
          <!-- <div>
            <mat-icon [matBadge]="1" matBadgeSize="small" class="notf-icon mr-2 mt-1"
              (click)="openNotifications($event)">notifications</mat-icon>
          </div> -->
          <!-- <div class="vertical-divider"></div> -->
          <div class="d-flex align-items-md-center mt-3">
            <p class="user mr-2 ml-5 mt-1">{{ employeeName }}</p>
            <mat-icon class="user-icon mr-2">person</mat-icon>
          </div>
          <div class="notifications" *ngIf="showNotifications">
            <div class="notf-header">Notifications</div>
            <div class="notf-content">
              <div *ngIf="notifications?.length; else noNotf"></div>
              <ng-template #noNotf>
                <div class="no-notf">No new notification.</div>
              </ng-template>
            </div>
            <div class="notf-footer"></div>
          </div>
          <button
            class="signout-btn"
            (click)="openLogout()"
            mat-icon-button
            matTooltip="Logout"
          >
            <mat-icon>exit_to_app</mat-icon>
          </button>
        </div>
      </div>
      <div id="appContentWrapper" style="padding-top: 24px">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-template #logoutDialog>
  <div style="padding: 24px">
    <p class="logout">Do you want to logout?</p>
    <div class="btn-optn d-flex">
      <button disableRipple mat-raised-button class="btn" (click)="signOut()">
        Yes
      </button>
      <span></span>
      <button mat-raised-button class="btn" matDialogClose>No</button>
    </div>
  </div>
</ng-template>
