import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class InvestmentDeclarationService {
  private path;
  private params: HttpParams;

  constructor(private baseService: BaseService) {}

  getInvestmentLimitApi(): Observable<any> {
    this.path = `investLimitXref`;
    return this.baseService.get(this.path);
  }

  getInvestmentDelarationSectionApi(): Observable<any> {
    this.path = `options/INVESTMENT_DECLARATION_SECTION`;
    return this.baseService.get(this.path);
  }

  getInvestmentBySectionApi(section: any): Observable<any> {
    this.path = `options/investment/` + section;
    return this.baseService.get(this.path);
  }

  getEmployeeInvestDeclarationByEmpId(
    employeeId: any,
    year: any
  ): Observable<any> {
    this.path = `employeeInvestmentDeclaration/employeeInvestDeclarationByEmpIdAndFiscal?employeeId=${employeeId}&fiscal=${year}`;
    return this.baseService.get(this.path);
  }

  getIncomeTaxComparisonApi(
    fiscal: string,
    employeeId: string,
    requestBody
  ): Observable<any> {
    this.path = "incomeTax";
    this.params = new HttpParams()
      .set("employeeId", employeeId)
      .set("fiscal", fiscal);
    return this.baseService.post(this.path, requestBody, this.params);
  }

  postIncomeTaxApi(requestBody): Observable<any> {
    this.path = "incomeTax/amount";
    return this.baseService.post(this.path, requestBody);
  }
}
