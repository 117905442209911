<div class="edit-section">
  <div class="row header-row">
    <div
      class="col main-heading"
      matTooltip="Back"
      matTooltipPosition="right"
      (click)="onCloseEditScreen()"
    >
      <mat-icon>chevron_left</mat-icon>
      <div>Edit Employee Personal Details</div>
    </div>
  </div>
  <form [formGroup]="editEmployeePersonalDetails" class="mt-2 form-wrapper">
    <div class="row justify-content-evenly">
      <div
        class="col-md-4 mb-4 d-flex flex-column input-wrapper pl-5"
        *ngFor="let field of basicFields; index as i"
      >
        <label [for]="field.controlName" class="ctc-heading w-100 pb-2"
          >{{ field.label
          }}<span style="color: red" *ngIf="field.required">*</span></label
        >
        <div [ngSwitch]="field.directive">
          <input
            *ngSwitchCase="'numbersOnly'"
            class="ctc-box ctc-value form-control"
            type="text"
            [formControlName]="field.controlName"
            numbersOnly
          />

          <input
            *ngSwitchCase="'stringsOnly'"
            class="ctc-box ctc-value form-control"
            type="text"
            [formControlName]="field.controlName"
            stringsOnly
          />

          <input
            *ngSwitchDefault
            class="ctc-box ctc-value form-control"
            type="text"
            [formControlName]="field.controlName"
          />
        </div>
        <div *ngIf="isFieldInvalid(field.controlName)" style="color: red">
          <div
            *ngIf="
              editEmployeePersonalDetails.get(field.controlName)?.errors
                ?.required
            "
          >
            {{ field.label }} is required.
          </div>
          <div
            *ngIf="
              editEmployeePersonalDetails.get(field.controlName)?.errors?.email
            "
          >
            Please enter a valid email address.
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4 input-wrapper pl-5 d-flex flex-column">
        <label for="dateOfBirth"
          >Date Of Birth<span style="color: red">*</span></label
        >
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="dateOfBirth"
            id="dateOfBirth"
            required
            [matDatepicker]="dobPicker"
          />
          <mat-datepicker-toggle matSuffix [for]="dobPicker">
            <mat-icon matDatepickerToggleIcon>
              <img
                src="../../assets/images/date-icon.svg"
                alt=""
                style="width: 20px"
              />
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #dobPicker color="primary"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-4 mb-4 input-wrapper pl-5 d-flex flex-column">
        <label for="dateOfJoining" class="my-label"
          >Date of Joining<span style="color: red">*</span></label
        >
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="dateOfJoining"
            id="dateOfJoining"
            required
            [matDatepicker]="dojPicker"
          />
          <mat-datepicker-toggle matSuffix [for]="dojPicker">
            <mat-icon matDatepickerToggleIcon>
              <img
                src="../../assets/images/date-icon.svg"
                alt=""
                style="width: 20px"
              />
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #dojPicker color="primary"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-4 mb-4 input-wrapper pl-5 d-flex flex-column">
        <label for="dateOfExit" class="my-label"
          >Date of Exit<span style="color: red">*</span></label
        >
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="dateOfExit"
            id="dateOfExit"
            required
            [matDatepicker]="doePicker"
          />
          <mat-datepicker-toggle matSuffix [for]="doePicker">
            <mat-icon matDatepickerToggleIcon>
              <img
                src="../../assets/images/date-icon.svg"
                alt=""
                style="width: 20px"
              />
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #doePicker color="primary"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="d-flex justify-content-end align-items-center footer">
    <div>
      <button
        style="background-color: #38a3a5; color: white"
        class="mr-4"
        mat-button
        (click)="openConfirmationDialog()"
      >
        Submit
      </button>
    </div>
  </div>
</div>
