var EmployeePayslipDetailsComponent = /** @class */ (function () {
    function EmployeePayslipDetailsComponent() {
        this.showDetails = true;
    }
    EmployeePayslipDetailsComponent.prototype.toggleInfo = function () {
        this.showDetails = !this.showDetails;
    };
    EmployeePayslipDetailsComponent.prototype.trackByEmployeeDetail = function (detail) {
        return detail.objKey;
    };
    return EmployeePayslipDetailsComponent;
}());
export { EmployeePayslipDetailsComponent };
