import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from "@angular/core";
import { getCurrentFiscalYear } from "src/app/shared/app.utility";
import { concatMap, finalize, take } from "rxjs/operators";
import { from, of } from "rxjs";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
var ProofOfIncomeDetailComponent = /** @class */ (function () {
    function ProofOfIncomeDetailComponent(route, router, investmentService, dialog, snackBarService, spinnerService, employeeService) {
        this.route = route;
        this.router = router;
        this.investmentService = investmentService;
        this.dialog = dialog;
        this.snackBarService = snackBarService;
        this.spinnerService = spinnerService;
        this.employeeService = employeeService;
        this.employeeId = "";
        this.employeeName = "";
        this.empPoitatus = "";
        this.investmentDetails = [];
        this.fiscal = getCurrentFiscalYear();
        this.modalTitle = "";
        this.modalContent = [];
        this.isModalOpen = false;
        this.selectedRemarksTitle = "";
        this.selectedRemarksText = "";
        this.selectedDetails = null;
        this.approvedAmountError = false;
        this.empRemarksMap = new Map();
        this.adminRemarksMap = new Map();
        this.numberOfApproved = 0;
        this.numberOfRejected = 0;
        this.totalDeclarations = 0;
        this.baseUrl = "https://devapi.geminisolutions.com/gempayroll/dev/empInvestProof";
    }
    ProofOfIncomeDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.spinnerService.openSpinnerDialog();
        this.investmentService.getFormType().subscribe(function (res) {
            console.log("Form Types:", res);
        }, function (err) {
            console.error("Error fetching form types:", err);
        });
        this.route.paramMap.subscribe(function (params) {
            _this.employeeId = decodeURIComponent(params.get("employeeId") || "");
        });
        this.route.queryParams.subscribe(function (params) {
            _this.employeeName = decodeURIComponent(params["name"] || "Unknown");
        });
        this.fetchEmployeeDetails();
        this.route.queryParams.subscribe(function (params) {
            _this.empPoitatus = decodeURIComponent(params["status"] || "Unknown");
            console.log(_this.empPoitatus);
        });
        this.fetchInvestmentDetails();
    };
    ProofOfIncomeDetailComponent.prototype.fetchEmployeeDetails = function () {
        var _this = this;
        this.employeeService
            .getEmployeeDetailsByEmailApi(this.employeeId)
            .subscribe(function (data) {
            if (data) {
                _this.employeeDesignation = data.designation || "Not Available";
                _this.employeeAddress = data.permanentAddress || "Not Available";
                _this.employeePAN = data.pan || "Not Available";
            }
        }, function (error) {
            console.error("Error fetching employee details:", error);
        });
    };
    ProofOfIncomeDetailComponent.prototype.fetchInvestmentDetails = function () {
        var _this = this;
        this.investmentService
            .getEmpInvestProofDetails(this.employeeId, this.fiscal)
            .pipe(finalize(function () {
            _this.spinnerService.closeSpinnerDialog();
        }))
            .subscribe(function (data) {
            _this.investmentDetails = [];
            _this.numberOfApproved = 0;
            _this.numberOfRejected = 0;
            _this.totalDeclarations = 0;
            Object.keys(data).forEach(function (section) {
                var investments = [];
                Object.keys(data[section]).forEach(function (investmentType) {
                    data[section][investmentType].forEach(function (investment) {
                        var processedInvestment = tslib_1.__assign({}, investment);
                        if (section === "HRA" && processedInvestment.hraProofs) {
                            processedInvestment.investmentProofAttachments = [];
                            processedInvestment.hraProofs.forEach(function (hraProof) {
                                if (hraProof.hraInvestmentProofAttachments &&
                                    hraProof.hraInvestmentProofAttachments.length > 0) {
                                    hraProof.hraInvestmentProofAttachments.forEach(function (attachment) {
                                        processedInvestment.investmentProofAttachments.push(tslib_1.__assign({ hraInvestmentProofAttachmentId: attachment.hraInvestmentProofAttachmentId, investmentProofFileName: attachment.investmentProofFileName, investmentProofFilePath: attachment.investmentProofFilePath }, attachment));
                                    });
                                }
                            });
                            processedInvestment.isHRA = true;
                        }
                        else if (!processedInvestment.investmentProofAttachments) {
                            processedInvestment.investmentProofAttachments = [];
                        }
                        if (processedInvestment.status) {
                            if (processedInvestment.status === "REJECTED") {
                                _this.numberOfRejected++;
                            }
                            else if (processedInvestment.status === "APPROVED") {
                                _this.numberOfApproved++;
                            }
                        }
                        _this.totalDeclarations++;
                        investments.push(tslib_1.__assign({ investmentType: investmentType }, processedInvestment, { sectionName: section }));
                    });
                });
                _this.investmentDetails.push({
                    sectionName: section,
                    investments: investments,
                    isHRA: section === "HRA",
                });
            });
            console.log("Processed investment details:", _this.investmentDetails);
        }, function (error) {
            console.error("Error fetching investment details:", error);
            _this.snackBarService.add({
                message: "Failed to load investment details. Please try again.",
                action: "Close",
                config: { duration: 4000, panelClass: ["error-snackbar-class"] },
            });
        });
    };
    ProofOfIncomeDetailComponent.prototype.considerRejectDeclarationBtnToolTip = function () {
        return this.empPoitatus === "CONSIDERED"
            ? "POI Declaration is already considered"
            : !(this.totalDeclarations ===
                this.numberOfRejected + this.numberOfApproved)
                ? "Please approve or reject all the POI declarations"
                : null;
    };
    ProofOfIncomeDetailComponent.prototype.isConsiderRejectDeclarationBtnDisabled = function () {
        return (!(this.totalDeclarations ===
            this.numberOfRejected + this.numberOfApproved) || this.empPoitatus === "CONSIDERED");
    };
    ProofOfIncomeDetailComponent.prototype.goBack = function () {
        this.router.navigate(["/dashboard/proofOfIncome"]);
    };
    ProofOfIncomeDetailComponent.prototype.onReject = function () {
        this.selectedDetails.approvedAmount = "0";
        this.updateInvestmentStatus("REJECTED");
    };
    ProofOfIncomeDetailComponent.prototype.onApprove = function () {
        if (!this.selectedDetails ||
            !this.selectedDetails.approvedAmount ||
            this.selectedDetails.approvedAmount === "0" ||
            this.selectedDetails.approvedAmount === 0) {
            this.approvedAmountError = true;
            return;
        }
        this.approvedAmountError = false;
        this.updateInvestmentStatus("APPROVED");
    };
    ProofOfIncomeDetailComponent.prototype.openRemarksModal = function (type, element) {
        var _this = this;
        this.modalTitle = type;
        this.approvedAmountError = false;
        if (type === "Details") {
            this.selectedDetails = {
                empInvestProofId: element.empInvestProofId,
                declaredAmount: element.declaredAmount !== null && element.declaredAmount !== 0
                    ? element.declaredAmount
                    : "0",
                approvedAmount: element.approvedAmount !== null && element.approvedAmount !== 0
                    ? element.approvedAmount
                    : "0",
                attachments: element.investmentProofAttachments || [],
                adminRemarks: element.adminRemarks || "",
                employeeId: element.employeeId || "",
            };
            this.openDialog();
        }
        else if (type === "Employee Remarks") {
            var remarks = this.empRemarksMap.get(element.investmentType);
            if (!remarks) {
                this.investmentService
                    .getEmpRemarksByEmpInvestProofIdApi(element.employeeId, element.empInvestProofId)
                    .pipe(take(1), finalize(function () {
                    _this.openDialog();
                }))
                    .subscribe(function (res) {
                    _this.empRemarksMap.set(element.investmentType, res);
                    _this.modalContent = res;
                });
            }
            else {
                this.modalContent = remarks;
                this.openDialog();
            }
        }
        else if (type === "Admin Remarks") {
            var remarks = this.adminRemarksMap.get(element.investmentType);
            if (!remarks) {
                this.investmentService
                    .getAdminRemarksByEmpInvestProofIdApi(element.employeeId, element.empInvestProofId)
                    .pipe(take(1), finalize(function () {
                    _this.openDialog();
                }))
                    .subscribe(function (res) {
                    _this.adminRemarksMap.set(element.investmentType, res);
                    _this.modalContent = res;
                });
            }
            else {
                this.modalContent = remarks;
                this.openDialog();
            }
        }
    };
    ProofOfIncomeDetailComponent.prototype.openDialog = function () {
        var _this = this;
        var dialog = this.dialog.open(this.remarksDialog, {
            width: "500px",
            panelClass: "custom-modal",
        });
        dialog
            .afterClosed()
            .pipe(take(1))
            .subscribe(function () {
            _this.selectedDetails = null;
            _this.modalContent = [];
        });
    };
    ProofOfIncomeDetailComponent.prototype.updateInvestmentStatus = function (status) {
        var _this = this;
        if (!this.selectedDetails || !this.selectedDetails.empInvestProofId) {
            console.error("Investment Proof ID is missing.");
            return;
        }
        var empInvestProofId = this.selectedDetails.empInvestProofId;
        var adminRemarks = this.selectedDetails.adminRemarks || "";
        var approvedAmount = this.selectedDetails.approvedAmount || "0";
        this.investmentService
            .updateInvestmentStatus(empInvestProofId, status, adminRemarks, approvedAmount)
            .subscribe(function (response) {
            _this.investmentDetails.forEach(function (section) {
                section.investments.forEach(function (investment) {
                    if (investment.empInvestProofId === empInvestProofId) {
                        if (status === "APPROVED") {
                            if (investment.status === "REJECTED") {
                                _this.numberOfRejected--;
                            }
                            _this.numberOfApproved++;
                        }
                        else if (status === "REJECTED") {
                            if (investment.status === "APPROVED") {
                                _this.numberOfApproved--;
                            }
                            _this.numberOfRejected++;
                        }
                        investment.status = status;
                        investment.adminRemarks = adminRemarks;
                        investment.approvedAmount = approvedAmount;
                    }
                });
            });
            var successMessage = response && response.message
                ? response.message
                : "Status changed successfully!";
            _this.snackBarService.add({
                message: successMessage,
                action: "Close",
                config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
            });
            _this.dialog.closeAll();
        }, function (error) {
            var errorMessage = error && error.error && error.error.message
                ? error.error.message
                : "An error occurred while updating the status.";
            _this.snackBarService.add({
                message: errorMessage,
                action: "Close",
                config: { duration: 4000, panelClass: ["error-snackbar-class"] },
            });
        });
    };
    ProofOfIncomeDetailComponent.prototype.closeRemarksModal = function () {
        this.isModalOpen = false;
        this.approvedAmountError = false;
    };
    ProofOfIncomeDetailComponent.prototype.onRejectStatus = function () {
        this.postUpdateInvestmentStatus("REJECTED");
    };
    // onConsideredStatus() {
    //   this.postUpdateInvestmentStatus("CONSIDERED");
    // }
    ProofOfIncomeDetailComponent.prototype.onConsideredStatus = function () {
        this.postUpdateInvestmentStatus("CONSIDERED");
        this.generateAndSendForm();
    };
    ProofOfIncomeDetailComponent.prototype.generateAndSendForm = function () {
        var _this = this;
        var doc = new jsPDF();
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("FORM NO.12BB", doc.internal.pageSize.width / 2, 15, {
            align: "center",
        });
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.text("(See rule 26C)", doc.internal.pageSize.width / 2, 21, {
            align: "center",
        });
        var finalY = 30;
        autoTable(doc, {
            body: [
                [
                    "1. Name, Designation and Address of Employee",
                    this.employeeName + ", " + this.employeeDesignation + ", " + this.employeeAddress,
                ],
                ["2. Permanent Account Number (PAN) of Employee", this.employeePAN],
                ["3. Financial Year", this.fiscal],
            ],
            startY: finalY,
            theme: "grid",
            styles: { fontSize: 10, cellPadding: 3 },
            columnStyles: { 0: { fontStyle: "bold" } },
            tableLineColor: [0, 0, 0],
        });
        finalY = doc.lastAutoTable.finalY || finalY;
        var claimsRows = [
            [
                {
                    content: "Details of Claims and Evidence Thereof",
                    colSpan: 4,
                    styles: { fontStyle: "bold", halign: "center" },
                },
            ],
            ["Sl No.", "Nature of Claim", "Amount (Rs.)", "Evidence / Particulars"],
            ["(1)", "(2)", "(3)", "(4)"],
            ["1", "House Rent Allowance:", "", ""],
        ];
        var hraSection = this.investmentDetails.find(function (section) { return section.sectionName === "HRA"; });
        if (hraSection &&
            hraSection.investments &&
            hraSection.investments.length > 0) {
            var hraInvestment = hraSection.investments[0];
            if (hraInvestment.hraProofs && hraInvestment.hraProofs.length > 0) {
                var hraProof = hraInvestment.hraProofs[0];
                var monthlyRentAmount = hraProof.monthlyRentAmount || 0;
                var fromMonth = hraProof.fromMonth || 0;
                var toMonth = hraProof.toMonth || 0;
                var duration = (toMonth < 4 ? toMonth + 12 : toMonth) -
                    (fromMonth < 4 ? fromMonth + 12 : fromMonth) +
                    1;
                var totalRent = parseFloat(monthlyRentAmount) * duration;
                var attachmentName = "";
                if (hraProof.hraInvestmentProofAttachments &&
                    hraProof.hraInvestmentProofAttachments.length > 0) {
                    attachmentName =
                        hraProof.hraInvestmentProofAttachments[0].investmentProofFileName ||
                            "";
                }
                claimsRows.push([
                    "",
                    "Total Rent Amount",
                    totalRent.toString(),
                    attachmentName,
                ]);
            }
        }
        claimsRows.push([
            "",
            "Note: Permanent Account Number shall be furnished if the aggregate rent paid during the previous year exceeds one Lakh rupees",
            "",
            "",
        ], ["2", "Leave travel concessions or assistance", "", ""], ["3", "Deduction of interest on borrowing:", "", ""], ["", "(i) Interest payable/paid to the lender", "", ""], ["", "(ii) Name of the lender", "", ""], ["", "(iii) Address of the lender", "", ""], ["", "(iv) Permanent Account Number of the lender", "", ""], ["", "(a) Financial Institutions(if available)", "", ""], ["", "(b) Employer(if available)", "", ""], ["", "(c) Others", "", ""], ["4", "Deductions under Chapter VI-A", "", ""]);
        if (this.investmentDetails && Array.isArray(this.investmentDetails)) {
            this.investmentDetails.forEach(function (section) {
                if (section.sectionName !== "HRA") {
                    claimsRows.push(["", "(A) Section " + section.sectionName, "", ""]);
                    section.investments.forEach(function (investment, index) {
                        var attachmentNames = [];
                        if (investment.investmentProofAttachments &&
                            Array.isArray(investment.investmentProofAttachments)) {
                            attachmentNames = investment.investmentProofAttachments.map(function (attachment) { return attachment.investmentProofFileName; });
                        }
                        claimsRows.push([
                            "",
                            " ( " + String.fromCharCode(97 + index) + " ) " + investment.investmentType,
                            investment.declaredAmount
                                ? investment.declaredAmount.toString()
                                : "0",
                            attachmentNames.length > 0
                                ? attachmentNames.join(", ")
                                : "No Attachments",
                        ]);
                    });
                }
            });
        }
        claimsRows.push([
            "",
            "(B) Other sections (e.g. 80E, 80G, 80TTA, etc.) under Chapter VI-A.",
            "",
            "",
        ]);
        autoTable(doc, {
            body: claimsRows,
            startY: finalY,
            theme: "grid",
            styles: { fontSize: 10, cellPadding: 3 },
            columnStyles: {
                0: { fontStyle: "bold", cellWidth: 10 },
                1: { cellWidth: 65 },
                2: { cellWidth: 22 },
                3: { cellWidth: 85, overflow: "linebreak" },
            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontStyle: "bold",
            },
            tableLineColor: [0, 0, 0],
        });
        finalY = doc.lastAutoTable.finalY || finalY;
        var verificationRow = [
            [
                {
                    content: "Verification",
                    colSpan: 2,
                    styles: { fontStyle: "bold", halign: "center" },
                },
            ],
            [
                {
                    content: "I, .................., son/daughter of .............., certify that the information given above is complete and correct.",
                    colSpan: 2,
                },
            ],
            [
                {
                    content: "Place: ....................\n\nDate: ....................\n\nDesignation: ....................",
                    colSpan: 1,
                },
                {
                    content: "(Signature of the employee)\nFull Name",
                    colSpan: 1,
                    styles: { halign: "center", cellPadding: { top: 10, bottom: 3 } },
                },
            ],
        ];
        autoTable(doc, {
            body: verificationRow,
            startY: finalY,
            theme: "grid",
            styles: { fontSize: 10, cellPadding: 3 },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
            },
            tableLineColor: [0, 0, 0],
        });
        finalY = doc.lastAutoTable.finalY || finalY;
        finalY += 10;
        doc.setFontSize(10);
        doc.setFont("helvetica", "italic");
        doc.text("*For claiming deduction under 80D (Medical Insurance), a copy of Insurance certificate containing the name of the insured persons may be enclosed.", 15, finalY, { maxWidth: 180 });
        // PDF to a Blob
        var pdfBlob = doc.output("blob");
        var formData = new FormData();
        formData.append("formFile", pdfBlob, "Form12BB.pdf");
        var formsDto = {
            fiscal: this.fiscal,
            employeeId: this.employeeId,
            formType: "FORM12BB",
        };
        var jsonBlob = new Blob([JSON.stringify(formsDto)], {
            type: "application/json",
        });
        formData.append("formsDto", jsonBlob, "formsDto.json");
        this.investmentService.postUploadForm(formData).subscribe(function (response) {
            if (response.success === false) {
                console.error("API returned an error:", response);
                _this.snackBarService.add({
                    message: response || "Error uploading form.",
                    action: "Close",
                    config: { duration: 4000, panelClass: ["error-snackbar-class"] },
                });
            }
            else {
                _this.snackBarService.add({
                    message: "Form uploaded successfully!",
                    action: "Close",
                    config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
                });
            }
        }, function (error) {
            console.error("Error uploading form:", error);
            _this.snackBarService.add({
                message: "Error uploading form.",
                action: "Close",
                config: { duration: 4000, panelClass: ["error-snackbar-class"] },
            });
        });
    };
    ProofOfIncomeDetailComponent.prototype.postUpdateInvestmentStatus = function (status) {
        var _this = this;
        var employeeId = this.employeeId;
        var fiscal = this.fiscal;
        var investmentProofStatus = status;
        this.investmentService
            .postUpdateInvestmentStatusApi(employeeId, fiscal, investmentProofStatus)
            .subscribe(function (response) {
            var successMessage = response && response.message
                ? response.message
                : "Status changed successfully!";
            _this.snackBarService.add({
                message: successMessage,
                action: "Close",
                config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
            });
            _this.dialog.closeAll();
            _this.router.navigate(["/dashboard/proofOfIncome"]);
        }, function (error) {
            var errorMessage = error && error.error && error.error.message
                ? error.error.message
                : "An error occurred while updating the status.";
            _this.snackBarService.add({
                message: errorMessage,
                action: "Close",
                config: { duration: 4000, panelClass: ["error-snackbar-class"] },
            });
        });
    };
    ProofOfIncomeDetailComponent.prototype.openAttachment = function (employeeId, attachments, isHRA) {
        var _this = this;
        if (isHRA === void 0) { isHRA = false; }
        if (!attachments || attachments.length === 0) {
            console.warn("No attachments available.");
            return;
        }
        from(attachments)
            .pipe(concatMap(function (attachment) {
            return _this.investmentService
                .getAttachmentUrl(isHRA
                ? attachment.hraInvestmentProofAttachmentId
                : attachment.investmentProofAttachmentId, employeeId, isHRA)
                .pipe(concatMap(function (response) {
                if (response) {
                    return of({
                        response: response,
                        fileName: attachment.investmentProofFileName,
                    });
                }
                else {
                    throw new Error("No valid attachment URL received.");
                }
            }));
        }))
            .subscribe(function (_a) {
            var response = _a.response, fileName = _a.fileName;
            window.open(response, "_blank");
        }, function (error) {
            console.error("Error fetching attachment URL:", error);
        });
    };
    ProofOfIncomeDetailComponent.prototype.getStatusClass = function (status) {
        if (!status)
            return "";
        switch (status.toUpperCase()) {
            case "APPROVED":
                return "status-approved";
            case "REJECTED":
                return "status-rejected";
            case "OPEN":
                return "status-open";
            case "SAVED":
                return "status-saved";
            case "SUBMITTED":
                return "status-submitted";
            case "UPDATED":
                return "status-updated";
            default:
                return "";
        }
    };
    ProofOfIncomeDetailComponent.prototype.downloadProof = function () {
        var _this = this;
        var email = this.employeeId;
        var fiscal = this.fiscal;
        this.investmentService.downloadInvestmentProof(email, fiscal).subscribe(function (response) {
            var successMessage = response && response.message
                ? response.message
                : "Status changed successfully!";
            _this.snackBarService.add({
                message: successMessage,
                action: "Close",
                config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
            });
        }, function (error) {
            var errorMessage = error && error.error && error.error.message
                ? error.error.message
                : "An error occurred while updating the status.";
            _this.snackBarService.add({
                message: errorMessage,
                action: "Close",
                config: { duration: 4000, panelClass: ["error-snackbar-class"] },
            });
        });
    };
    return ProofOfIncomeDetailComponent;
}());
export { ProofOfIncomeDetailComponent };
