import { Injectable } from "@angular/core";
import { LOCAL_STORAGE_KEYS } from "src/app/account/login.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor() {}
  isUserLoggedIn() {
    const user = sessionStorage.getItem(LOCAL_STORAGE_KEYS.userLoginInfo);
    return !(user === null);
  }
}
