import { getDutchPaginatorIntl } from "./shared/paginator-intl";
import { AuthConfigService } from "./auth-config.service";
var isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    window.navigator.userAgent.indexOf("Trident/") > -1;
export function MSALInstanceFactory(authConfigService) {
    return authConfigService.getPublicClientApp();
}
var ɵ0 = getDutchPaginatorIntl();
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
