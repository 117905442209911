import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { PayrollService } from 'src/app/shared/services/payroll.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-employee-earnings',
  templateUrl: './employee-earnings.component.html',
  styleUrls: ['./employee-earnings.component.scss']
})
export class EmployeeEarningsComponent implements OnInit {
  @Input() selectedPayslipMonth: number
  @Input() selectedPayslipYear: number
  @Input() element: any
  @Output() earningsFormStatus = new EventEmitter<any>();
  @Output() EarningsFormGroup = new EventEmitter<any>();
  employeeEarningsList : any;
  earningsFormGroup : FormGroup
  idMap:any;
  showSpinner:boolean = true;
  constructor(private payrollService:PayrollService, private formBuilder:FormBuilder) { }

  ngOnInit() {
    this.getEmployeeEarningsKeys();
    this.earningsFormGroup = this.formBuilder.group({});
  }

  getEmployeeEarningsKeys(){
    this.payrollService.getEmployeeEarningsKeysApi().pipe(
      // Use mergeMap to chain the second API call based on the success of the first
      mergeMap((keysResponse) => {
        this.employeeEarningsList = keysResponse;
        this.showSpinner = false;
        this.initializeForm();
        return this.payrollService.getEmployeeMonthlyEarningsApi(
          this.selectedPayslipMonth,
          this.selectedPayslipYear,
          this.element.employeeId
        ).pipe(
          // Handle errors for the second API call
          catchError((err) => {
            console.log('Error occurred in the second API call:', err);
            return of(null);
          })
        );
      }),
      // Handle errors for the first API call
      catchError((err) => {
        console.log('Error occurred in the first API call:', err);
        this.showSpinner = false;
        this.initializeForm();
        return of(null); 
      })
    ).subscribe(
      (result) => {
        this.showSpinner = false;
        if (result) {
          this.patchFormValues(result)
        }
      },
      (err) => {
        console.log('Error occurred during subscription:', err);
      }
    );
  }

  initializeForm(): void {
    const controls: { [key: string]: FormControl } = {};
    const idMap: { [key: string]: number } = {};
    if (this.employeeEarningsList && this.employeeEarningsList.length > 0) {
      this.employeeEarningsList.forEach(earning => {
        controls[earning.optionValue] = new FormControl('');
        idMap[earning.optionValue] = earning.optionId;
      });
    }
    this.idMap=idMap
    this.earningsFormGroup = this.formBuilder.group(controls);
    this.earningsFormGroup.valueChanges.subscribe(() => { 
      if (this.earningsFormGroup.dirty) {
        this.emitFormStatus();
      }
    });
  }

  emitFormStatus(): void {
    const formValues = this.earningsFormGroup.value;
    this.EarningsFormGroup.emit(this.earningsFormGroup);
    this.earningsFormStatus.emit({
      hasValues: Object.values(formValues).some(value => value !== null && value !== ''),
      formData: this.preparePayload(formValues),
      touched:this.earningsFormGroup.dirty,
      empty:Object.values(formValues).every(value => value === null || value === '')
    });
  }

   preparePayload(formValues: any): any[] {
    return Object.keys(formValues).map(key => ({
      employeeId: this.element.employeeId,
      year: this.selectedPayslipYear,
      component: key,
      amount: Number(formValues[key]),
      month: this.selectedPayslipMonth,
      fiscal: `${this.selectedPayslipYear}`
    }));
  }

  patchFormValues(data: any[]) {
    const patchValues = {};
    data.forEach((item) => {
      if (this.earningsFormGroup.controls[item.component]) {
        patchValues[item.component] = item.amount;
      }
    });
    this.earningsFormGroup.patchValue(patchValues);
    this.emitFormStatus();
  }
}
