import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnInit, TemplateRef, } from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { FormBuilder, FormControl, FormGroup, } from "@angular/forms";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { finalize } from "rxjs/operators";
var ADD_POI_TABLE_COLUMNS = [
    { label: "Particulars", key: "particulars" },
    { label: "Attachment", key: "proofId" },
    { label: "Declared", key: "declared" },
    { label: "Approved", key: "approved" },
    { label: "Status", key: "status" },
    { label: "Remarks", key: "remarks" },
    { label: "Options", key: "options" },
];
var EmployeeAddPoiComponent = /** @class */ (function () {
    function EmployeeAddPoiComponent(dialog, formBuilder, loginService, poiService, invDecService, snackBarService, cdr) {
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.loginService = loginService;
        this.poiService = poiService;
        this.invDecService = invDecService;
        this.snackBarService = snackBarService;
        this.cdr = cdr;
        this.displayedColumns = ADD_POI_TABLE_COLUMNS;
        this.browsedFiles = [];
        this.savedAttachments = new Map();
        this.optionIdMap = new Map();
        this.optionRemarksMap = new Map();
        this.hraRemark = "";
        this.openNextSectionEvent = new EventEmitter();
        this.closeAddPoiComponentEvent = new EventEmitter();
        this.hraOptionsDataChange = new EventEmitter();
        this.hraAdminRemarks = null;
        this.isThereAnyFormChanges = false;
    }
    EmployeeAddPoiComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.sectionName === "HRA") {
            this.displayedColumns = this.displayedColumns.filter(function (el) { return el.key !== "approved"; });
        }
        this.sectionOptionsDataInitCopy = this.sectionOptionsData.map(function (el) { return (tslib_1.__assign({}, el)); });
        this.initPoiForm();
        this.setAddPoiTableData();
        this.setOptionIdMap();
        this.poiForm.valueChanges.subscribe(function () {
            _this.isThereAnyFormChanges = _this.isDraftFormUpdated();
        });
    };
    EmployeeAddPoiComponent.prototype.setAddPoiTableData = function () {
        if (this.sectionName === "HRA") {
            this.addPoiTableData = new MatTableDataSource(this.sectionOptionsData.sort(function (a, b) {
                var numA = parseInt(a.particulars.replace("House ", ""), 10);
                var numB = parseInt(b.particulars.replace("House ", ""), 10);
                return numA - numB;
            }));
        }
        else {
            this.addPoiTableData = new MatTableDataSource(this.sectionOptionsData.sort(function (a, b) { return (a.declared === "0" ? 1 : -1); }));
        }
        this.cdr.detectChanges();
    };
    EmployeeAddPoiComponent.prototype.setOptionIdMap = function () {
        var _this = this;
        this.sectionOptionsData.forEach(function (el) {
            var id = el.optionId ? el.optionId : null;
            _this.optionIdMap.set(el.particulars, id);
        });
    };
    EmployeeAddPoiComponent.prototype.initPoiForm = function () {
        var _this = this;
        this.poiForm = this.formBuilder.group({});
        this.poiFormCopy = this.formBuilder.group({});
        this.sectionOptionsData.forEach(function (el) {
            var control;
            control = new FormGroup({
                declared: new FormControl(el.declared),
                employeeRemark: new FormControl(el.employeeRemark),
                employerRemark: new FormControl({
                    value: el.employerRemark,
                    disabled: true,
                }),
                proofIds: new FormControl(el.proofIds),
                proofFiles: new FormControl([]),
                investmentViaId: new FormControl(el.investmentViaId),
                proofFileName: new FormControl(el.proofFileName),
            });
            if (_this.sectionName === "HRA") {
                control.addControl("fromMonth", new FormControl(el.fromMonth));
                control.addControl("toMonth", new FormControl(el.toMonth));
                control.addControl("monthlyRentAmount", new FormControl(el.monthlyRentAmount));
                control.addControl("metroCity", new FormControl(el.metroCity));
                if (el.proofFiles) {
                    control.patchValue({ proofFiles: el.proofFiles });
                }
            }
            _this.poiForm.addControl(el.particulars, control);
            var controlCopy = new FormGroup({});
            Object.keys(control.controls).forEach(function (key) {
                var originalControl = control.get(key);
                var newControl = new FormControl(originalControl.value, originalControl.validator, originalControl.asyncValidator);
                if (originalControl.disabled) {
                    newControl.disable();
                }
                controlCopy.addControl(key, newControl);
            });
            _this.poiFormCopy.addControl(el.particulars, controlCopy);
        });
        this.cdr.detectChanges();
    };
    EmployeeAddPoiComponent.prototype.getDisplayedColumns = function () {
        return this.displayedColumns.map(function (col) { return col.key; });
    };
    EmployeeAddPoiComponent.prototype.isStringValue = function (key) {
        return key === "particulars" || key === "declared";
    };
    EmployeeAddPoiComponent.prototype.openUploadProofDialog = function (templateRef, optionName, shouldUpdateMainForm) {
        var _this = this;
        if (shouldUpdateMainForm === void 0) { shouldUpdateMainForm = false; }
        this.selectedOptionName = optionName;
        this.shouldUpdateMainForm = shouldUpdateMainForm;
        var dialogRef = this.dialog.open(templateRef, {
            panelClass: "upload-panel-class",
        });
        dialogRef.afterClosed().subscribe(function (result) {
            _this.browsedFiles = [];
        });
    };
    EmployeeAddPoiComponent.prototype.openRemarksDialog = function (templateRef, optionName) {
        var _this = this;
        this.selectedOptionName = optionName;
        var remarks = this.optionRemarksMap.get(optionName);
        if (this.sectionName === "HRA") {
            if (!this.hraAdminRemarks && this.hraEmpInvestProofId) {
                this.invDecService
                    .getAdminRemarksByEmpInvestProofIdApi(this.loginService.employeeDetails.employeeId, this.hraEmpInvestProofId.toString())
                    .pipe(finalize(function () {
                    var dialogRef = _this.dialog.open(templateRef);
                }))
                    .subscribe(function (res) {
                    _this.hraAdminRemarks = res;
                });
            }
            else {
                var dialogRef = this.dialog.open(templateRef);
            }
        }
        else {
            if (!remarks && this.optionIdMap.get(optionName)) {
                this.invDecService
                    .getAdminRemarksByEmpInvestProofIdApi(this.loginService.employeeDetails.employeeId, this.optionIdMap.get(optionName).toString())
                    .pipe(finalize(function () {
                    var dialogRef = _this.dialog.open(templateRef);
                }))
                    .subscribe(function (res) {
                    _this.optionRemarksMap.set(optionName, res);
                });
            }
            else {
                var dialogRef = this.dialog.open(templateRef);
            }
        }
    };
    EmployeeAddPoiComponent.prototype.openEmployeeHraRemarksDialog = function (templateRef) {
        this.dialog.open(templateRef);
    };
    EmployeeAddPoiComponent.prototype.openEditOptionDialog = function (templateRef, optionName, status) {
        if (status.toLowerCase() !== "approved") {
            this.selectedOptionName = optionName;
            var mainFormGroup = this.poiForm.get(this.selectedOptionName);
            var copyFormGroup = this.poiFormCopy.get(this.selectedOptionName);
            copyFormGroup.patchValue(mainFormGroup.value);
            var dialogRef = this.dialog.open(templateRef);
        }
    };
    EmployeeAddPoiComponent.prototype.fileBrowseHandler = function (event) {
        var input = event.target;
        if (!input.files)
            return;
        var newFiles = Array.from(input.files);
        var totalSize = this.browsedFiles.reduce(function (sum, file) { return sum + file.size; }, 0);
        newFiles.forEach(function (file) {
            totalSize += file.size;
        });
        if (totalSize > 1 * 1024 * 1024) {
            this.snackBarService.add({
                message: "Total file size exceeds 1MB.",
                action: "Close",
                config: { duration: 3000, panelClass: ["custom-snackbar-class"] },
            });
        }
        else {
            this.browsedFiles = tslib_1.__spread(this.browsedFiles, newFiles);
            this.prepareFilesList();
            this.cdr.detectChanges();
        }
    };
    EmployeeAddPoiComponent.prototype.onFileDropped = function () {
        this.prepareFilesList();
    };
    EmployeeAddPoiComponent.prototype.prepareFilesList = function () {
        this.browsedFiles.forEach(function (file) {
            if (file.progress === undefined) {
                file.progress = 0;
            }
        });
        this.uploadFilesSimulator();
    };
    EmployeeAddPoiComponent.prototype.uploadFilesSimulator = function () {
        var _this = this;
        setTimeout(function () {
            var progressInterval = setInterval(function () {
                var allCompleted = true;
                _this.browsedFiles.forEach(function (file) {
                    if (file.progress < 100) {
                        file.progress += 5;
                        allCompleted = false;
                    }
                });
                if (allCompleted) {
                    clearInterval(progressInterval);
                }
            }, 20);
        }, 100);
    };
    EmployeeAddPoiComponent.prototype.formatBytes = function (bytes) {
        var decimals;
        if (bytes === 0) {
            return "0 Bytes";
        }
        var k = 1024;
        var dm = decimals <= 0 ? 0 : decimals || 2;
        var sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };
    EmployeeAddPoiComponent.prototype.addProofFileValue = function () {
        if (this.browsedFiles.length > 0) {
            var existingFileNames = [];
            if (this.poiForm.get(this.selectedOptionName) &&
                this.poiForm.get(this.selectedOptionName).value) {
                existingFileNames = this.poiForm.get(this.selectedOptionName).value
                    .proofFileName
                    ? this.poiForm
                        .get(this.selectedOptionName)
                        .value.proofFileName.split(",")
                    : [];
            }
            var newFileNames = this.browsedFiles.map(function (file) { return file.name; });
            var updatedFileNames = tslib_1.__spread(existingFileNames, newFileNames);
            var existingFiles = this.poiForm.get(this.selectedOptionName).value
                .proofFiles
                ? this.poiForm.get(this.selectedOptionName).value.proofFiles
                : [];
            var updatedFiles = tslib_1.__spread(existingFiles, this.browsedFiles);
            if (this.shouldUpdateMainForm) {
                this.poiForm.get(this.selectedOptionName).patchValue({
                    proofFiles: updatedFiles,
                    proofFileName: updatedFileNames.join(","),
                });
                if (this.sectionName === "HRA") {
                    this.updateFilesInOptionsData(this.selectedOptionName, updatedFiles);
                }
            }
            this.poiFormCopy.get(this.selectedOptionName).patchValue({
                proofFiles: updatedFiles,
                proofFileName: updatedFileNames.join(","),
            });
            this.snackBarService.add({
                message: "Files uploaded",
                action: "Close",
                config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
            });
        }
    };
    // for HRA
    EmployeeAddPoiComponent.prototype.updateFilesInOptionsData = function (house, proofFiles) {
        var houseData = this.sectionOptionsData.find(function (el) { return el.particulars === house; });
        var fileName = proofFiles.map(function (el) { return el.name; }).join(",");
        if (proofFiles.length > 1) {
            //removing last comma
            fileName = fileName.slice(0, -1);
        }
        houseData.proofFileName = fileName;
        houseData.proofFiles = proofFiles;
    };
    EmployeeAddPoiComponent.prototype.updateOptionsData = function (house, data) {
        var houseData = this.sectionOptionsData.find(function (el) { return el.particulars === house; });
        houseData.declared = data.declared;
        houseData.fromMonth = data.fromMonth;
        houseData.toMonth = data.toMonth;
        houseData.monthlyRentAmount = data.monthlyRentAmount;
        houseData.metroCity = data.metroCity;
    };
    EmployeeAddPoiComponent.prototype.onSaveAndNextButtonClick = function () {
        if (this.sectionName === "HRA") {
            var houses = this.poiForm.value;
            for (var house in houses) {
                if (houses[house].declared !== "0" && houses[house].proofFileName) {
                    continue;
                }
                else {
                    this.snackBarService.add({
                        message: "Please declare amount and add proof for all the houses or remove unwanted houses",
                        action: "Close",
                        config: { duration: 8000, panelClass: ["custom-snackbar-class"] },
                    });
                    return;
                }
            }
            this.saveHraPoi();
        }
        else {
            this.savePoi();
        }
    };
    EmployeeAddPoiComponent.prototype.saveHraPoi = function () {
        var _this = this;
        var formValue = this.poiForm.value;
        var formData = new FormData();
        var investmentProofDto = {
            declaredAmount: 0,
            amount: 0,
            employeeId: this.loginService.employeeDetails.employeeId,
            fiscal: getCurrentFiscalYear().toString(),
            investmentViaId: 0,
        };
        var hraProofs = [];
        var investmentViaId = 0;
        var declaredAmount = 0;
        for (var key in formValue) {
            // investmentViaId should not be a part of the form, there should be a map of option to id
            investmentViaId = formValue[key].investmentViaId;
            declaredAmount += Number(formValue[key].declared);
            var hraProof = {
                fromMonth: formValue[key].fromMonth,
                toMonth: formValue[key].toMonth,
                monthlyRentAmount: formValue[key].monthlyRentAmount,
                metroCity: formValue[key].metroCity,
                fileNames: formValue[key].proofFileName.split(","),
            };
            if (this.optionIdMap.get(key)) {
                hraProof.hraProofId = this.optionIdMap.get(key);
            }
            hraProofs.push(hraProof);
            if (Array.isArray(formValue[key].proofFiles)) {
                formValue[key].proofFiles.forEach(function (file) {
                    formData.append("file", file);
                });
            }
        }
        investmentProofDto.investmentViaId = investmentViaId;
        investmentProofDto.hraProofs = hraProofs;
        investmentProofDto.declaredAmount = declaredAmount;
        investmentProofDto.amount = declaredAmount;
        var jsonBlob = new Blob([JSON.stringify(investmentProofDto)], {
            type: "application/json",
        });
        formData.append("investmentProofDto", jsonBlob, "investmentProofDto.json");
        if (this.hraRemark !== "") {
            formData.append("remarks", this.hraRemark);
        }
        this.poiService
            .savePoiAsDraftApi(this.loginService.employeeDetails.employeeId, formData)
            .subscribe(function (res) {
            _this.openNextSection();
        }, function (err) {
            console.error("error", err);
        });
    };
    EmployeeAddPoiComponent.prototype.isDraftFormUpdated = function () {
        var e_1, _a;
        var formValue = this.poiForm.value;
        var result = true;
        var _loop_1 = function (key) {
            var e_2, _a;
            var isFormUpdated = false;
            var isFilesUpdated = false;
            var tempElement = this_1.sectionOptionsDataInitCopy.find(function (element) { return element.particulars === key; });
            if (this_1.sectionName === "HRA") {
                return { value: true };
            }
            else {
                isFormUpdated = formValue[key].declared !== tempElement.declared;
            }
            var newFiles = formValue[key].proofFileName
                ? formValue[key].proofFileName.split(",")
                : [];
            if (newFiles.length > 0) {
                if (!tempElement.proofFileName) {
                    isFilesUpdated = true;
                }
                else {
                    var oldFiles = tempElement.proofFileName.split(",");
                    if (newFiles.length !== oldFiles.length) {
                        isFilesUpdated = true;
                    }
                    else {
                        try {
                            for (var oldFiles_1 = (e_2 = void 0, tslib_1.__values(oldFiles)), oldFiles_1_1 = oldFiles_1.next(); !oldFiles_1_1.done; oldFiles_1_1 = oldFiles_1.next()) {
                                var element = oldFiles_1_1.value;
                                if (!formValue[key].proofIds.get(element)) {
                                    isFilesUpdated = true;
                                    break;
                                }
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (oldFiles_1_1 && !oldFiles_1_1.done && (_a = oldFiles_1.return)) _a.call(oldFiles_1);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                    }
                }
            }
            if (isFormUpdated || isFilesUpdated) {
                result =
                    result &&
                        formValue[key].declared &&
                        formValue[key].declared !== 0 &&
                        formValue[key].declared !== "0" &&
                        newFiles.length !== 0;
            }
            else {
                if (formValue[key].declared > 0 && newFiles.length === 0) {
                    result = result && false;
                }
            }
        };
        var this_1 = this;
        try {
            for (var _b = tslib_1.__values(Object.entries(formValue)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 1), key = _d[0];
                var state_1 = _loop_1(key);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return result;
    };
    EmployeeAddPoiComponent.prototype.savePoi = function () {
        var e_3, _a;
        var _this = this;
        var formValue = this.poiForm.value;
        var _loop_2 = function (key) {
            var e_4, _a;
            var isFormUpdated = false;
            var isFilesUpdated = false;
            var tempElement = this_2.sectionOptionsDataInitCopy.find(function (element) { return element.particulars === key; });
            if (this_2.sectionName === "HRA") {
                return { value: true };
            }
            else {
                isFormUpdated = formValue[key].declared !== tempElement.declared;
            }
            var newFiles = formValue[key].proofFileName
                ? formValue[key].proofFileName.split(",")
                : [];
            if (newFiles.length > 0) {
                if (!tempElement.proofFileName) {
                    isFilesUpdated = true;
                }
                else {
                    var oldFiles = tempElement.proofFileName.split(",");
                    if (newFiles.length !== oldFiles.length) {
                        isFilesUpdated = true;
                    }
                    else {
                        try {
                            for (var oldFiles_2 = (e_4 = void 0, tslib_1.__values(oldFiles)), oldFiles_2_1 = oldFiles_2.next(); !oldFiles_2_1.done; oldFiles_2_1 = oldFiles_2.next()) {
                                var element = oldFiles_2_1.value;
                                if (!formValue[key].proofIds.get(element)) {
                                    isFilesUpdated = true;
                                    break;
                                }
                            }
                        }
                        catch (e_4_1) { e_4 = { error: e_4_1 }; }
                        finally {
                            try {
                                if (oldFiles_2_1 && !oldFiles_2_1.done && (_a = oldFiles_2.return)) _a.call(oldFiles_2);
                            }
                            finally { if (e_4) throw e_4.error; }
                        }
                    }
                }
            }
            if (isFormUpdated || isFilesUpdated) {
                var formData_1 = new FormData();
                var investmentProofDto = {
                    declaredAmount: formValue[key].declared,
                    amount: formValue[key].declared,
                    employeeId: this_2.loginService.employeeDetails.employeeId,
                    fiscal: getCurrentFiscalYear().toString(),
                    investmentProofFileName: formValue[key].proofFileName,
                    investmentViaId: formValue[key].investmentViaId,
                };
                var employeeRemarks = formValue[key].employeeRemark
                    ? formValue[key].employeeRemark
                    : "";
                if (Array.isArray(formValue[key].proofFiles)) {
                    formValue[key].proofFiles.forEach(function (file) {
                        formData_1.append("file", file);
                    });
                }
                else if (formValue[key].proofFiles) {
                    formData_1.append("file", formValue[key].proofFiles);
                }
                var jsonBlob = new Blob([JSON.stringify(investmentProofDto)], {
                    type: "application/json",
                });
                formData_1.append("investmentProofDto", jsonBlob, "investmentProofDto.json");
                if (employeeRemarks) {
                    formData_1.append("remarks", employeeRemarks);
                }
                this_2.poiService
                    .savePoiAsDraftApi(this_2.loginService.employeeDetails.employeeId, formData_1)
                    .subscribe(function (res) {
                    _this.openNextSection();
                }, function (err) {
                    console.error("error", err);
                });
            }
        };
        var this_2 = this;
        try {
            for (var _b = tslib_1.__values(Object.entries(formValue)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 1), key = _d[0];
                var state_2 = _loop_2(key);
                if (typeof state_2 === "object")
                    return state_2.value;
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    EmployeeAddPoiComponent.prototype.openNextSection = function () {
        this.openNextSectionEvent.emit(this.sectionName);
    };
    EmployeeAddPoiComponent.prototype.closeAddPoiComponent = function () {
        if (this.sectionName === "HRA") {
            this.hraOptionsDataChange.emit(this.sectionOptionsDataInitCopy);
        }
        this.closeAddPoiComponentEvent.emit(this.sectionName);
    };
    EmployeeAddPoiComponent.prototype.getFormGroup = function () {
        return this.poiFormCopy.get(this.selectedOptionName);
    };
    EmployeeAddPoiComponent.prototype.isEmployerRemarksPresent = function () {
        if (this.sectionName === "HRA") {
            return this.hraAdminRemarks && this.hraAdminRemarks.length > 0;
        }
        var remarks = this.optionRemarksMap.get(this.selectedOptionName);
        return remarks && remarks.length > 0;
    };
    EmployeeAddPoiComponent.prototype.getEmployerRemarks = function () {
        if (this.sectionName === "HRA") {
            return this.hraAdminRemarks;
        }
        return this.optionRemarksMap.get(this.selectedOptionName);
    };
    EmployeeAddPoiComponent.prototype.downloadFile = function (particulars, fileName) {
        var proofId = this.poiForm.value[particulars].proofIds.get(fileName);
        this.invDecService
            .getAttachmentUrl(proofId, this.loginService.employeeDetails.employeeId)
            .subscribe(function (response) {
            if (response) {
                var link = document.createElement("a");
                link.href = response;
                link.setAttribute("download", "Employee_POI.pdf");
                link.setAttribute("target", "_blank");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else {
                console.error("No valid attachment URL received.");
            }
        }, function (error) {
            console.error("Error fetching attachment URL:", error);
        });
    };
    EmployeeAddPoiComponent.prototype.isSimpleValue = function (col) {
        var simpleValueCols = ["particulars", "approved", "status"];
        return simpleValueCols.includes(col);
    };
    EmployeeAddPoiComponent.prototype.getStatusClass = function (status, col) {
        if (col === "status") {
            switch (status.toUpperCase()) {
                case "CONSIDERED":
                    return "status-considered";
                case "APPROVED":
                    return "status-approved";
                case "REJECTED":
                    return "status-rejected";
                case "OPEN":
                    return "status-open";
                case "SAVED":
                    return "status-saved";
                case "SUBMITTED":
                    return "status-submitted";
                default:
                    return "";
            }
        }
        return "";
    };
    EmployeeAddPoiComponent.prototype.updatePoiForm = function () {
        var mainFormGroup = this.poiForm.get(this.selectedOptionName);
        var copyFormGroup = this.poiFormCopy.get(this.selectedOptionName);
        if (this.sectionName === "HRA") {
            var monthlyRent = this.getFormGroup().value.monthlyRentAmount;
            var fromMonth = this.getFormGroup().value.fromMonth;
            var toMonth = this.getFormGroup().value.toMonth;
            // months are 1 base index and plus one is to include toMonth in the duration
            var duration = (toMonth < 4 ? toMonth + 12 : toMonth) -
                (fromMonth < 4 ? fromMonth + 12 : fromMonth) +
                1;
            var declared = monthlyRent * duration;
            this.getFormGroup().patchValue({
                declared: declared,
            });
            var browsedFile = this.getFormGroup().value.proofFiles;
            if (browsedFile) {
                this.updateFilesInOptionsData(this.selectedOptionName, browsedFile);
            }
            this.updateOptionsData(this.selectedOptionName, {
                declared: declared.toString(),
                fromMonth: fromMonth,
                toMonth: toMonth,
                metroCity: this.getFormGroup().value.monthlyRentAmount.metroCity,
                monthlyRentAmount: monthlyRent,
            });
        }
        mainFormGroup.patchValue(copyFormGroup.value);
    };
    EmployeeAddPoiComponent.prototype.removeHouse = function (particulars, status) {
        if (status.toLowerCase() !== "approved") {
            var updatedArray = this.sectionOptionsData.filter(function (el) { return el.particulars !== particulars; });
            this.hraOptionsDataChange.emit(updatedArray);
            this.sectionOptionsData = updatedArray;
            this.reNumberHouses();
            this.setOptionIdMap();
            this.setAddPoiTableData();
            this.initPoiForm();
        }
    };
    EmployeeAddPoiComponent.prototype.reNumberHouses = function () {
        this.sectionOptionsData.forEach(function (el, index) {
            el.particulars = "House " + (index + 1);
        });
    };
    EmployeeAddPoiComponent.prototype.addHouse = function () {
        var house = {
            fromMonth: 4,
            toMonth: 4,
            monthlyRentAmount: 0,
            metroCity: false,
            investmentViaId: this.hraInvestmentViaId,
            particulars: "House " + (this.sectionOptionsData.length + 1),
            declared: "0",
            status: "OPEN",
            approved: "0",
        };
        this.sectionOptionsData.push(house);
        this.initPoiForm();
        this.setAddPoiTableData();
    };
    EmployeeAddPoiComponent.prototype.removeAddedAttachment = function (index) {
        var _this = this;
        var fileNames = this.poiFormCopy.get(this.selectedOptionName) &&
            this.poiFormCopy.get(this.selectedOptionName).value &&
            this.poiFormCopy.get(this.selectedOptionName).value.proofFileName
            ? this.poiFormCopy
                .get(this.selectedOptionName)
                .value.proofFileName.split(",")
            : [];
        if (fileNames.length > index) {
            var removedFileName_1 = fileNames.splice(index, 1)[0];
            var proofFiles = this.poiFormCopy.get(this.selectedOptionName).value
                .proofFiles;
            proofFiles = proofFiles.filter(function (el) { return el.name !== removedFileName_1; });
            this.poiFormCopy.get(this.selectedOptionName).patchValue({
                proofFileName: fileNames.length > 0 ? fileNames.join(",") : "",
                proofFiles: proofFiles,
            });
            var proofId = this.poiFormCopy
                .get(this.selectedOptionName)
                .value.proofIds.get(removedFileName_1);
            if (proofId) {
                this.invDecService
                    .deleteInvestmentProofByIf(this.loginService.employeeDetails.employeeId, proofId)
                    .subscribe(function (res) {
                    _this.poiForm.get(_this.selectedOptionName).patchValue({
                        proofFileName: fileNames.length > 0 ? fileNames.join(",") : "",
                    });
                    _this.poiFormCopy
                        .get(_this.selectedOptionName)
                        .value.proofIds.delete(removedFileName_1);
                    _this.poiForm
                        .get(_this.selectedOptionName)
                        .value.proofIds.delete(removedFileName_1);
                    _this.isThereAnyFormChanges = _this.isDraftFormUpdated();
                    _this.snackBarService.add({
                        message: res.message,
                        action: "Close",
                        config: {
                            duration: 3000,
                            panelClass: ["custom-snackbar-class"],
                        },
                    });
                }, function (error) {
                    _this.snackBarService.add({
                        message: "Files uploaded",
                        action: "Close",
                        config: {
                            duration: 2000,
                            panelClass: ["custom-snackbar-class"],
                        },
                    });
                });
            }
        }
    };
    EmployeeAddPoiComponent.prototype.removeFile = function (index) {
        this.browsedFiles.splice(index, 1);
    };
    return EmployeeAddPoiComponent;
}());
export { EmployeeAddPoiComponent };
