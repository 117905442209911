import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { BaseService } from "./base.service";
import {
  InvestmentDeclarationSectionInfo,
  SectionLimit,
} from "src/app/investment-declaration/investment-declaration.util";
import { getCurrentFiscalYear } from "../app.utility";

@Injectable({
  providedIn: "root",
})
export class InvestmentDeclarationService {
  private path;
  private params: HttpParams;

  constructor(private baseService: BaseService) {}

  getInvestmentLimitApi(): Observable<SectionLimit[]> {
    this.path = `investLimitXref`;
    return this.baseService.get(this.path);
  }

  getInvestmentDeclarationSectionApi(): Observable<
    InvestmentDeclarationSectionInfo[]
  > {
    this.path = `options/INVESTMENT_DECLARATION_SECTION`;
    return this.baseService.get(this.path);
  }

  getInvestmentBySectionApi(section: any): Observable<any> {
    this.path = `options/investment/` + section;
    return this.baseService.get(this.path);
  }

  getEmployeeInvestDeclarationByEmpId(
    employeeId: any,
    year: any
  ): Observable<any> {
    this.path = `employeeInvestmentDeclaration/employeeInvestDeclarationByEmpIdAndFiscal?employeeId=${employeeId}&fiscal=${year}`;
    return this.baseService.get(this.path);
  }

  getIncomeTaxComparisonApi(
    fiscal: string,
    employeeId: string,
    requestBody
  ): Observable<any> {
    this.path = "incomeTax";
    this.params = new HttpParams()
      .set("employeeId", employeeId)
      .set("fiscal", fiscal);
    return this.baseService.post(this.path, requestBody, this.params);
  }

  postEmployeeInvestmentDeclarationApi(requestBody): Observable<any> {
    this.path = "employeeInvestmentDeclaration";
    return this.baseService.post(this.path, requestBody);
  }

  downloadApprovedPoi(employeeId: string, fiscalYear: string): Observable<any> {
    const path = `forms/download`;
    const requestBody = {
      fiscal: fiscalYear,
      employeeId: employeeId,
      formType: "FORM12BB",
    };

    return this.baseService.post(path, requestBody, null, "text");
  }
  
  postUploadForm(formData: FormData) {
    this.path = "forms/upload";
    return this.baseService.post(this.path, formData, null, "text");
  }

  postEmpTaxRegimeApi(requestBody): Observable<any> {
    this.path = "empTaxRegime";
    return this.baseService.post(this.path, requestBody);
  }

  getEmpInvestProofDetails(
    employeeId: string,
    fiscal: string
  ): Observable<any> {
    const path = `empInvestProof/section`;
    const params = new HttpParams()
      .set("employeeId", employeeId)
      .set("fiscal", fiscal);
    return this.baseService.get(path, params);
  }

  getFormType(): Observable<any> {
    const path = `forms/form-types`;
    const params = new HttpParams();
    return this.baseService.get(path, params);
  }

  getAdminEmpInvestProofCount(fiscal: string): Observable<any> {
    const path = `adminEmpInvestProof/count`;
    const params = new HttpParams().set("fiscal", fiscal);
    return this.baseService.get(path, params);
  }

  updateInvestmentStatus(
    empInvestProofId: string,
    status: string,
    adminRemarks: string,
    approvedAmount: string
  ): Observable<any> {
    const path = `adminEmpInvestProof/review`;

    const body = {
      empInvestProofId,
      status,
      adminRemarks,
      approvedAmount,
    };

    return this.baseService.put(path, body);
  }

  postUpdateInvestmentStatusApi(
    employeeId: string,
    fiscal: string,
    investmentProofStatus: string
  ): Observable<any> {
    const path = "adminEmpInvestProof/status";
    const params = new HttpParams()
      .set("employeeId", employeeId)
      .set("fiscal", fiscal)
      .set("investmentProofStatus", investmentProofStatus);

    return this.baseService.put(path, null, params);
  }

  // getAttachmentUrl(
  //   investmentProofAttachmentId: string,
  //   employeeId: string
  // ): Observable<any> {
  //   const path = `empInvestProof/investProofFileByProofId`;
  //   const params = new HttpParams()
  //     .set("investmentProofAttachmentId", investmentProofAttachmentId)
  //     .set("employeeId", employeeId);
  //   return this.baseService.get(path, params, null, "text");
  // }
  getAttachmentUrl(
    attachmentId: string,
    employeeId: string,
    isHRA?: boolean
  ): Observable<any> {
    const path = `empInvestProof/investProofFileByProofId`;
    
    let params = new HttpParams().set("employeeId", employeeId);
    
    if (isHRA) {
      params = params.set("hraInvestmentProofId", attachmentId); 
    } else {
      params = params.set("investmentProofAttachmentId", attachmentId);
    }
    
    return this.baseService.get(path, params, null, "text");
  }

  downloadInvestmentProof(employeeId: string, fiscal: string): Observable<any> {
    const path = `empInvestProof/investProofByEmployeeEmailAndFiscal`;
    const params = new HttpParams()
      .set("employeeId", employeeId)
      .set("fiscal", fiscal);

    return this.baseService.get(path, params);
  }
  getEmpTaxRegime(): Observable<any[]> {
    const path = `empTaxRegime`;
    return this.baseService.get(path);
  }
  getAdminEmpInvestProof(
    fiscal: string,
    taxRegimeTypes?: string,
    status?: string
  ): Observable<any> {
    this.path = `adminEmpInvestProof`;
    this.params = new HttpParams().set("fiscal", fiscal);

    if (taxRegimeTypes && taxRegimeTypes !== "ALL") {
      this.params = this.params.set("taxRegimeTypes", taxRegimeTypes);
    }

    if (status && status !== "All") {
      this.params = this.params.set("status", status);
    }

    return this.baseService.get(this.path, this.params);
  }

  submitPoiApi(empId: string, fiscal: string): Observable<any> {
    this.path = "empInvestProof/status";
    this.params = new HttpParams()
      .set("employeeId", empId)
      .set("fiscal", fiscal)
      .set("employeeRemarks", "SUBMITTED");

    return this.baseService.put(this.path, {}, this.params);
  }

  releasePoiApi(fiscal: string): Observable<any> {
    this.path = `adminEmpInvestProof?fiscal=${fiscal}`;
    return this.baseService.post(this.path);
  }

  getSectionInvestmentDetails(
    employeeId: string,
    fiscal: string
  ): Observable<any> {
    const path = `empInvestProof/section/amount`;
    const params = new HttpParams()
      .set("employeeId", employeeId)
      .set("fiscal", fiscal);
    return this.baseService.get(path, params);
  }

  getEmployeePoiStatus(empId: string): Observable<any> {
    this.path = `adminEmpInvestProof?fiscal=${getCurrentFiscalYear()}&employeeId=${empId}`;
    return this.baseService.get(this.path);
  }

  deleteInvestmentProofByIf(empId, investmentProofAttachmentId) {
    this.path = `empInvestProof/investProofFile?employeeId=${empId}&investmentProofAttachmentId=${investmentProofAttachmentId}`;
    return this.baseService.delete(this.path);
  }

  getAdminRemarksByEmpInvestProofIdApi(
    empId: string,
    empInvestProofId: string
  ) {
    this.path = `empInvestProof/admin/remarks?empInvestProofId=${empInvestProofId}&employeeId=${empId}`;
    return this.baseService.get(this.path);
  }

  getEmpRemarksByEmpInvestProofIdApi(empId: string, empInvestProofId: string) {
    this.path = `empInvestProof/remarks?empInvestProofId=${empInvestProofId}&employeeId=${empId}`;
    return this.baseService.get(this.path);
  }
}
