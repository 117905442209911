/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-payslip-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./employee-payslip-details.component";
var styles_EmployeePayslipDetailsComponent = [i0.styles];
var RenderType_EmployeePayslipDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeePayslipDetailsComponent, data: {} });
export { RenderType_EmployeePayslipDetailsComponent as RenderType_EmployeePayslipDetailsComponent };
function View_EmployeePayslipDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "card-item pl-0 pb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "pb-2": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "emp-heading gem-text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "text  gem-text-dark"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, _v.context.last); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.label); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.selectedEmployeeDetails[((_v.context.$implicit == null) ? null : _v.context.$implicit.objKey)]; _ck(_v, 9, 0, currVal_2); }); }
function View_EmployeePayslipDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "emp-grid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmployeePayslipDetailsComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userDetails; var currVal_1 = _co.trackByEmployeeDetail; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_EmployeePayslipDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "emp-details gem-surface-secondary-50 pl-4 pt-3 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "grid-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "gem-text-dark card-heading pb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Employee Details"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "i", [["class", "ph pr-4 gem-text-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmployeePayslipDetailsComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ph pr-4 gem-text-primary"; var currVal_1 = (_co.showDetails ? "ph-eye" : "ph-eye-slash"); _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = _co.showDetails; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_EmployeePayslipDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-employee-payslip-details", [], null, null, null, View_EmployeePayslipDetailsComponent_0, RenderType_EmployeePayslipDetailsComponent)), i1.ɵdid(1, 49152, null, 0, i3.EmployeePayslipDetailsComponent, [], null, null)], null, null); }
var EmployeePayslipDetailsComponentNgFactory = i1.ɵccf("app-employee-payslip-details", i3.EmployeePayslipDetailsComponent, View_EmployeePayslipDetailsComponent_Host_0, { userDetails: "userDetails", selectedEmployeeDetails: "selectedEmployeeDetails" }, {}, []);
export { EmployeePayslipDetailsComponentNgFactory as EmployeePayslipDetailsComponentNgFactory };
