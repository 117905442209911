import * as tslib_1 from "tslib";
import { ElementRef, OnInit, TemplateRef, AfterViewInit, ChangeDetectorRef, } from "@angular/core";
import { MatPaginator, MatSort, } from "@angular/material";
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { DISPLAYED_COLUMNS, MONTHS, MD_SCREEN_SIZE_BREAKPOINT, COLS_WHICH_REQUIRE_TOOLTIP, EMPLOYEE_ID, getFormattedDate, getFirstDayOfNextMonth, } from "./payroll.utility";
import { AddEmployeeDetailsComponent } from "../add-employee-details/add-employee-details.component";
import { catchError, debounceTime, distinctUntilChanged, take, } from "rxjs/operators";
import { of, Subject } from "rxjs";
import { SNACKBAR_LABEL, } from "../payslip/payslip.utility";
import { PayrollDialogComponent } from "../payroll-dialog/payroll-dialog.component";
// (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
var PayrollComponent = /** @class */ (function () {
    function PayrollComponent(dialog, employeeService, _snackBar, payrollService, spinnerService, cdr, snackBarService) {
        var _this = this;
        this.dialog = dialog;
        this.employeeService = employeeService;
        this._snackBar = _snackBar;
        this.payrollService = payrollService;
        this.spinnerService = spinnerService;
        this.cdr = cdr;
        this.snackBarService = snackBarService;
        this.searchSubject = new Subject();
        this.durationInSeconds = 5;
        this.years = [];
        this.months = MONTHS;
        this.displayedColumns = DISPLAYED_COLUMNS;
        this.url = "";
        this.totalEmployees = 0;
        this.totalEmployeeRecords = 0;
        this.pageIndex = 0;
        this.pageSize = 10;
        this.isScreenSizeLessThanMdBreakPoint = false;
        this.showLoading = false;
        this.searchInputValue = "";
        this.showSpinner = true;
        this.openEarningsAndDeductions = false;
        this.today = new Date();
        this.years = Array.from(new Array(11), function (x, i) { return _this.getAdjustedYear() - i; });
    }
    Object.defineProperty(PayrollComponent.prototype, "paginator", {
        set: function (value) {
            if (this.empListDataSource) {
                this.empListDataSource.paginator = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    PayrollComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedPayslipMonth = this.getAdjustedMonth() + 1;
        this.setCurrentYear();
        this.getAllPayrollDetails(this.selectedPayslipYear, this.selectedPayslipMonth);
        this.getTotalEmployeesAndAmount(this.selectedPayslipYear, this.selectedPayslipMonth);
        this.formattedDate = getFormattedDate(getFirstDayOfNextMonth(this.today));
        this.searchSubject
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(function (searchTerm) {
            _this.performSearch(searchTerm);
        });
    };
    PayrollComponent.prototype.ngAfterViewInit = function () {
        this.setIsScreenSizeLessThanMdBreakPoint();
        this.cdr.detectChanges();
    };
    PayrollComponent.prototype.onResize = function (event) {
        this.setIsScreenSizeLessThanMdBreakPoint();
    };
    PayrollComponent.prototype.payslipExist = function (employeeId, dialog, element) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, payslipExists, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.payrollService
                                .getPayrollDetailsApi(employeeId, this.selectedPayslipMonth, this.selectedPayslipYear)
                                .pipe(take(1), catchError(function (error) {
                                _this.openSnackbar(error.error.message, SNACKBAR_LABEL);
                                return of(null);
                            }))
                                .toPromise()];
                    case 1:
                        res = _a.sent();
                        payslipExists = !!res;
                        if (payslipExists) {
                            this.openPayslipDialog(dialog, element);
                            this.payrollDetails = res;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.openSnackbar("Error fetching payroll details", SNACKBAR_LABEL);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PayrollComponent.prototype.setIsScreenSizeLessThanMdBreakPoint = function () {
        this.isScreenSizeLessThanMdBreakPoint =
            window.innerWidth >= MD_SCREEN_SIZE_BREAKPOINT ? false : true;
    };
    PayrollComponent.prototype.openConfirmationDialog = function () {
        var _this = this;
        this.dialog.open(PayrollDialogComponent, {
            data: {
                title: "Confirmation",
                msg: "Do you want to trigger monthly payroll generation for\n" + this.months[this.selectedPayslipMonth] + " " + this.selectedPayslipYear + " ?",
                dialogType: "post",
                onYesClickFunction: function (param) {
                    return _this.calculateMonthlyPayroll(param);
                },
            },
        });
    };
    PayrollComponent.prototype.setCurrentMonth = function () {
        this.selectedPayslipMonth = new Date().getMonth() + 1;
    };
    PayrollComponent.prototype.setCurrentYear = function () {
        this.selectedPayslipYear = this.getAdjustedYear();
    };
    PayrollComponent.prototype.getTotalEmployeesAndAmount = function (payrollYear, payrollMonth) {
        var _this = this;
        this.payrollService
            .getTotalEmployeeAndAmountApi(payrollYear, payrollMonth)
            .subscribe(function (res) {
            if (res) {
                _this.totalEmployees = res.totalEmployees || 0;
                _this.totalPayableAmount = res.totalPayableAmount || 0;
            }
            else {
                console.error("Invalid response structure for total employees and amount.");
                _this.totalEmployees = 0;
                _this.totalPayableAmount = 0;
            }
        }, function (error) {
            console.error("Failed to fetch total employees and amount.", error);
            _this.totalEmployees = 0;
            _this.totalPayableAmount = 0;
        });
    };
    PayrollComponent.prototype.getAllPayrollDetails = function (year, month, search) {
        var _this = this;
        this.showSpinner = true;
        this.payrollService
            .getAllPayrollDetailsApi(year, month, this.pageIndex, this.pageSize, search)
            .subscribe(function (res) {
            _this.showSpinner = false;
            if (res &&
                res.content &&
                res.content.length > 0 &&
                res.content[0].employees) {
                _this.allPayrollDetails = res.content[0].employees;
                _this.allEmpDetails = Object.keys(_this.allPayrollDetails).map(function (key) {
                    return tslib_1.__assign({}, _this.allPayrollDetails[key], { employeeId: key });
                });
                _this.totalEmployeeRecords = res.totalElements;
                _this.empListDataSource = _this.allEmpDetails;
                _this.empListDataSource.sort = _this.sort;
                _this.empListDataSource.paginator = _this.paginator;
            }
            else if (res.content.length === 0) {
                console.error("Invalid response structure or employees data is missing.");
                _this.allPayrollDetails = [];
                _this.allEmpDetails = [];
                _this.empListDataSource = _this.allEmpDetails;
                _this.totalEmployeeRecords = 0;
            }
        }, function (error) {
            _this.showSpinner = false;
            _this.snackBarService.add({
                message: "Failed to load payroll details, please try again later",
                action: "close",
                config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
            });
            console.error("Get all payroll details failed", error);
        });
    };
    PayrollComponent.prototype.onSearch = function () {
        var searchTerm = this.searchInputValue.trim();
        this.searchSubject.next(searchTerm);
    };
    PayrollComponent.prototype.performSearch = function (searchTerm) {
        this.pageIndex = 0;
        this.getAllPayrollDetails(this.selectedPayslipYear, this.selectedPayslipMonth, searchTerm);
    };
    PayrollComponent.prototype.onPageChange = function (event) {
        if (this.pageSize !== event.pageSize) {
            this.pageIndex = 0;
        }
        else {
            this.pageIndex = event.pageIndex;
        }
        this.pageSize = event.pageSize;
        this.getAllPayrollDetails(this.selectedPayslipYear, this.selectedPayslipMonth, this.searchInputValue);
    };
    PayrollComponent.prototype.getPayrollData = function (employeeId, col) {
        return this.allPayrollDetails
            ? this.allPayrollDetails[employeeId]
                ? this.allPayrollDetails[employeeId][col]
                    ? this.allPayrollDetails[employeeId][col]
                    : ""
                : ""
            : "";
    };
    PayrollComponent.prototype.onMonthChange = function () {
        var searchTerm = this.searchInputValue.trim();
        this.getAllPayrollDetails(this.selectedPayslipYear, this.selectedPayslipMonth, searchTerm);
    };
    PayrollComponent.prototype.onYearChange = function () {
        if (this.selectedPayslipYear === this.getAdjustedYear() &&
            this.selectedPayslipMonth > this.getAdjustedMonth() + 1) {
            this.selectedPayslipMonth = 1;
        }
        var searchTerm = this.searchInputValue.trim();
        this.getAllPayrollDetails(this.selectedPayslipYear, this.selectedPayslipMonth, searchTerm);
    };
    PayrollComponent.prototype.openPayslipDialog = function (templateRef, element) {
        var _this = this;
        this.selectedPayslipMonth = this.selectedPayslipMonth;
        this.selectedPayslipYear = this.selectedPayslipYear;
        this.payslipTitleText = element.firstName + " " + element.lastName.trim() + "'s Payslip";
        var dialogRef = this.dialog.open(templateRef, {
            width: "800px",
            height: "600px",
        });
        this.selectedEmployeeDetails = this.allEmpDetails.filter(function (el) { return el.employeeId.toLowerCase() === element.employeeId.toLowerCase(); })[0];
        dialogRef.afterClosed().subscribe(function (result) {
            _this.onClose();
        });
    };
    PayrollComponent.prototype.onClose = function () {
        this.dialog.closeAll();
        this.url = "";
        this.selectedEmployeeDetails = null;
        this.employeePayrollDetails = null;
    };
    PayrollComponent.prototype.calculateMonthlyPayroll = function (param) {
        var _this = this;
        this.spinnerService.openSpinnerDialog();
        this.payrollService
            .calculateMonthlyPayrollApi(this.selectedPayslipYear, this.selectedPayslipMonth)
            .subscribe(function (res) {
            _this.spinnerService.closeSpinnerDialog();
            setTimeout(function () {
                _this.openSnackbar(res.message, "Close");
            }, 500);
        }, function (error) {
            _this.spinnerService.closeSpinnerDialog();
            _this.openSnackbar("'Monthly payroll calculation failed'", "Close");
        });
    };
    PayrollComponent.prototype.getBankTransferSheet = function () {
        var _this = this;
        var snackbarRef = this.snackBarService.open("Cancel", 0);
        snackbarRef.progress = 1;
        snackbarRef.runProgressBar();
        this.payrollService
            .getPayrollExcelApi(this.selectedPayslipYear, this.selectedPayslipMonth)
            .subscribe(function (res) {
            var url = window.URL.createObjectURL(res.body);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display: none");
            a.href = url;
            a.download = "Bank_Transfer_Sheet_" + _this.months[_this.selectedPayslipMonth].toUpperCase() + "_" + _this.selectedPayslipYear + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
            snackbarRef.progress = 100;
            snackbarRef.runProgressBar();
            setTimeout(function () {
                snackbarRef.closeSnackbar();
            }, 2000);
        }, function (err) {
            snackbarRef.progressText =
                "Error occurred in downloading bank transfer sheet";
            setTimeout(function () {
                snackbarRef.closeSnackbar();
            }, 2000);
        });
    };
    PayrollComponent.prototype.getSalarySheet = function () {
        var _this = this;
        var snackbarRef = this.snackBarService.open("Cancel", 0);
        snackbarRef.progress = 1;
        snackbarRef.runProgressBar();
        this.payrollService
            .getPayrollExcelSalarySheetApi(this.selectedPayslipYear, this.selectedPayslipMonth)
            .subscribe(function (res) {
            var url = window.URL.createObjectURL(res.body);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display: none");
            a.href = url;
            a.download = "Salary_Sheet_" + _this.months[_this.selectedPayslipMonth].toUpperCase() + "_" + _this.selectedPayslipYear + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
            snackbarRef.progress = 100;
            snackbarRef.runProgressBar();
            setTimeout(function () {
                snackbarRef.closeSnackbar();
            }, 2000);
        }, function (err) {
            snackbarRef.progressText =
                "Error occurred in downloading salary sheet";
            setTimeout(function () {
                snackbarRef.closeSnackbar();
            }, 2000);
        });
    };
    PayrollComponent.prototype.getIsTooltipRequiredForCol = function (col) {
        return COLS_WHICH_REQUIRE_TOOLTIP.includes(col);
    };
    PayrollComponent.prototype.openSnackbar = function (message, action) {
        this._snackBar.open(message, action, {
            duration: this.durationInSeconds * 1000,
            panelClass: ["custom-snackbar-class"],
        });
    };
    PayrollComponent.prototype.openAddEmpDialog = function () {
        var dialogRef = this.dialog.open(AddEmployeeDetailsComponent, {
            width: "1154px",
            height: "610px",
            panelClass: "my-panel-class",
        });
        dialogRef.afterClosed().subscribe(function (result) { });
    };
    PayrollComponent.prototype.columnExist = function (employeeId, dialog, element, columnName) {
        if (columnName === EMPLOYEE_ID) {
            this.payslipExist(employeeId, dialog, element);
        }
    };
    PayrollComponent.prototype.getAdjustedMonth = function () {
        var now = new Date();
        var month = now.getMonth();
        var date = now.getDate();
        if (date >= 25) {
            return month;
        }
        else {
            return month === 0 ? 11 : month - 1;
        }
    };
    PayrollComponent.prototype.getAdjustedYear = function () {
        var now = new Date();
        var month = now.getMonth();
        var date = now.getDate();
        if (month > 0 || (month === 0 && date > 24)) {
            return now.getFullYear();
        }
        return now.getFullYear() - 1;
    };
    PayrollComponent.prototype.showMonth = function (monthKey) {
        --monthKey;
        if (this.getAdjustedYear() === this.selectedPayslipYear &&
            monthKey > this.getAdjustedMonth())
            return false;
        return true;
    };
    PayrollComponent.prototype.toggleEarningsAndDeductions = function (element) {
        this.currentEmployeeElement = element;
        this.openEarningsAndDeductions = !this.openEarningsAndDeductions;
    };
    PayrollComponent.prototype.toggleChildComponent = function (value) {
        this.openEarningsAndDeductions = value.openEarningsAndDeductions;
        if (value.isPayslipTriggered) {
            this.getAllPayrollDetails(this.selectedPayslipYear, this.selectedPayslipMonth);
        }
    };
    return PayrollComponent;
}());
export { PayrollComponent };
