<div class="initial-container">
  <div class="header">
    <button class="back-btn" (click)="goBack()">&lt;</button>
    <h2>
      Review POI: <span class="employee-name">{{ employeeName }}</span>
    </h2>
  </div>

  <div class="employee-card">
    <div class="card-details">
      <div class="card-item">
        <label>Employee Name:</label>
        <span>{{ employeeName }}</span>
      </div>
      <div class="card-item">
        <label>Employee ID:</label>
        <span>{{ employeeId }}</span>
      </div>
      <div class="card-actions">
        <button class="download-btn" (click)="downloadProof()">
          Download Proof
        </button>
      </div>
    </div>
    <hr class="divider" />

    <mat-accordion>
      <mat-expansion-panel
        *ngFor="let section of investmentDetails; let i = index"
        [expanded]="i === 0"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>{{ section.sectionName }}</mat-panel-title>
        </mat-expansion-panel-header>

        <table
          mat-table
          [dataSource]="section.investments"
          class="mat-elevation-z8"
        >
          <ng-container matColumnDef="Particulars">
            <th mat-header-cell *matHeaderCellDef>Particulars</th>
            <td mat-cell *matCellDef="let element">
              {{ element.investmentType || NA }}
            </td>
          </ng-container>

          <ng-container matColumnDef="attachments">
            <th mat-header-cell *matHeaderCellDef>Attachments</th>
            <td
            mat-cell
            *matCellDef="let element"
            [class.disabled]="
              !element.investmentProofAttachments ||
              element.investmentProofAttachments.length === 0
            "
            (click)="
              element.investmentProofAttachments &&
              element.investmentProofAttachments.length > 0
                ? openAttachment(
                    element.employeeId,
                    element.investmentProofAttachments,
                    section.sectionName === 'HRA'
                  )
                : null
            "
            class="cursor-pointer attachment-link"
          >
            <mat-icon class="attachment-icon">attach_file</mat-icon>
            Attachment{{
              element.investmentProofAttachments?.length > 1 ? "s" : ""
            }}
            ({{ element.investmentProofAttachments?.length || 0 }})
          </td>
          </ng-container>
          <ng-container matColumnDef="declared">
            <th mat-header-cell *matHeaderCellDef>Declared</th>
            <td mat-cell *matCellDef="let element">
              {{
                element.declaredAmount && element.declaredAmount !== 0
                  ? element.declaredAmount
                  : "0"
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="approved">
            <th mat-header-cell *matHeaderCellDef>Approved</th>
            <td mat-cell *matCellDef="let element">
              {{
                element.approvedAmount && element.approvedAmount !== 0
                  ? element.approvedAmount
                  : "0"
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngClass]="getStatusClass(element.status)"
            >
              {{ element.status ? element.status : "NA" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="employeeRemarks">
            <th mat-header-cell *matHeaderCellDef>Employee Remarks</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-button
                class="custom-view-btn"
                (click)="openRemarksModal('Employee Remarks', element)"
              >
                View
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="adminRemarks">
            <th mat-header-cell *matHeaderCellDef>Admin Remarks</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-button
                class="custom-view-btn"
                (click)="openRemarksModal('Admin Remarks', element)"
              >
                View
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef>Details</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-button
                class="custom-view-btn"
                (click)="openRemarksModal('Details', element)"
              >
                View
              </button>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="[
              'Particulars',
              'attachments',
              'declared',
              'approved',
              'status',
              'employeeRemarks',
              'adminRemarks',
              'details'
            ]"
          ></tr>
          <tr
            mat-row
            *matRowDef="
              let row;
              columns: [
                'Particulars',
                'attachments',
                'declared',
                'approved',
                'status',
                'employeeRemarks',
                'adminRemarks',
                'details'
              ]
            "
          ></tr>
        </table>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="card-footer">
      <button
        [disabled]="
          !(totalDeclarations === numberOfRejected + numberOfApproved) ||
          empPoitatus === 'CONSIDERED'
        "
        class="btn reject-btn"
        [matTooltip]="considerRejectDeclarationBtnToolTip()"
        [ngClass]="{
          'curser-none': isConsiderRejectDeclarationBtnDisabled()
        }"
        (click)="onRejectStatus()"
      >
        Reject
      </button>
      <button
        [disabled]="
          !(totalDeclarations === numberOfRejected + numberOfApproved) ||
          empPoitatus === 'CONSIDERED'
        "
        class="btn review-btn"
        [matTooltip]="considerRejectDeclarationBtnToolTip()"
        [ngClass]="{
          'curser-none': isConsiderRejectDeclarationBtnDisabled()
        }"
        (click)="onConsideredStatus()"
      >
        Consider
      </button>
    </div>
  </div>

  <ng-template #remarksDialog>
    <div class="modal-content">
      <div class="modal-header">
        <span>{{ modalTitle }}</span>
        <button class="close-button" mat-dialog-close>&times;</button>
      </div>

      <div class="modal-body">
        <ng-container *ngIf="modalTitle === 'Details'">
          <div class="row">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>Declared Amount</mat-label>
                <input
                  matInput
                  type="text"
                  [(ngModel)]="selectedDetails.declaredAmount"
                  disabled
                />
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field class="approve-input" appearance="outline">
                <mat-label>Approved Amount</mat-label>
                <input
                  matInput
                  type="text"
                  [(ngModel)]="selectedDetails.approvedAmount"
                />
              </mat-form-field>
              <div *ngIf="approvedAmountError" class="error-message">
                Approved amount cannot be 0 or empty.
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Attachments:</label>
            <span
            *ngIf="
              selectedDetails?.attachments &&
              selectedDetails.attachments.length > 0
            "
            (click)="
              openAttachment(
                selectedDetails.employeeId,
                selectedDetails.attachments,
                section.sectionName === 'HRA'
              )
            "
            class="cursor-pointer attachment-link"
          >
            <mat-icon class="attachment-icon">attach_file</mat-icon>
            Attachments ({{ selectedDetails.attachments.length }})
          </span>
            <span
              *ngIf="
                !selectedDetails?.attachments ||
                selectedDetails.attachments.length === 0
              "
            >
              No attachments available.
            </span>
          </div>

          <div class="form-group">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Add Remark</mat-label>
              <textarea
                matInput
                [(ngModel)]="selectedDetails.adminRemarks"
              ></textarea>
            </mat-form-field>
          </div>
        </ng-container>

        <ng-container *ngIf="modalTitle !== 'Details'">
          <ng-container *ngIf="modalContent && modalContent.length !== 0; else noRemarks">
            <div class="remarks-container">
              <ul>
                <li class="remark" *ngFor="let remarkInfo of modalContent">
                  <div>{{ remarkInfo.remark }}</div>
                  <div class="remark-timestamp">{{ remarkInfo.remarkDate }}</div>
                </li>
              </ul>
            </div>
          </ng-container>
          <ng-template #noRemarks>
            <div class="no-remarks">No remarks available</div>
          </ng-template>
        </ng-container>
      </div>

      <div class="modal-footer" *ngIf="modalTitle === 'Details'">
        <button
          [matTooltip]="
            empPoitatus === 'CONSIDERED'
              ? 'POI Declaration is already considered'
              : null
          "
          class="btn reject-button"
          (click)="onReject()"
          [ngClass]="{
            'curser-none': empPoitatus === 'CONSIDERED'
          }"
          [disabled]="empPoitatus === 'CONSIDERED'"
        >
          Reject
        </button>
        <button
          [matTooltip]="
            empPoitatus === 'CONSIDERED'
              ? 'POI Declaration is already considered'
              : null
          "
          class="btn review-btn"
          (click)="onApprove()"
          [ngClass]="{
            'curser-none': empPoitatus === 'CONSIDERED'
          }"
          [disabled]="empPoitatus === 'CONSIDERED'"
        >
          Approve
        </button>
      </div>
    </div>
  </ng-template>
</div>
