<div class="investment-declaration-wrapper">
  <div style="height: 100%; display: flex" *ngIf="!isPreviewScreen">
    <mat-spinner
      *ngIf="!isFormReady"
      class="spinner"
      [diameter]="60"
    ></mat-spinner>
    <div class="form-wrapper">
      <form
        [formGroup]="investmentDeclarationForm"
        class="inv-dec-form"
        *ngIf="isFormReady"
      >
        <div class="sections" formGroupName="investmentSections">
          <div
            class="d-flex justify-content-center"
            *ngFor="
              let rowNumber of getNumberOfRowsArray(getFormGroupsArray().length)
            "
          >
            <div class="row cards-wrapper" *ngIf="!isAddingInvestment()">
              <div
                class="col-lg-4 section-col d-lg-flex"
                *ngFor="
                  let section of getRowWiseSections(rowNumber);
                  let i = index
                "
                [ngClass]="{
                  'justify-content-end': i === 2,
                  'justify-content-center': i === 1
                }"
              >
                <investment-section-card
                  [sectionName]="allSections[rowNumber * 3 + i].optionValue"
                  [tooltip]="allSections[rowNumber * 3 + i].optionDesc"
                  [totalLimit]="
                    getLimit(allSections[rowNumber * 3 + i].optionValue)
                  "
                  [amountEntered]="
                    sectionTotalAmount[
                      allSections[rowNumber * 3 + i].optionValue
                    ]
                  "
                  (isAddingInvestmentToggleEvent)="
                    toggleIsAddingInvestment($event)
                  "
                ></investment-section-card>
              </div>
            </div>
          </div>
          <ng-container
            *ngFor="let section of getFormGroupsArray(); let i = index"
          >
            <ng-container
              *ngIf="isAddingInvestmentDict[allSections[i].optionValue]"
            >
              <add-investment
                [sectionName]="allSections[i].optionValue"
                [tooltip]="allSections[i].optionDesc"
                [totalLimit]="getLimit(allSections[i].optionValue)"
                [amountEntered]="sectionTotalAmount[allSections[i].optionValue]"
                [formGroupName]="groupNames[i]"
                [sectionInputs]="getSectionInputNames(section.value)"
                [parentFormGroup]="investmentDeclarationForm"
                (isAddingInvestmentToggleEvent)="
                  toggleIsAddingInvestment($event)
                "
                (openNextSectionEvent)="openNextSection($event)"
                (saveFormEvent)="saveInvestmentDeclarationForm()"
                (loadSavedFormEvent)="loadFormData($event)"
                (updateSectionTotalAmount)="updateSectionTotalAmount($event)"
                (resetSectionAmount)="resetSectionAmount($event)"
                (onRemoveHouseClickEmitter)="removeHouse($event)"
                [fiscalMonths]="fiscalMonths"
                [houses]="houses"
                [isFormSubmitted]="isFormSubmitted"
              >
              </add-investment>
            </ng-container>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="isPreviewScreen">
    <app-investment-declaration-preview
      [incomeTaxResponse]="incomeTaxResponse"
      (onRegimeSelect)="setSelectedRegime($event)"
    ></app-investment-declaration-preview>
  </div>
  <div class="footer py-3" *ngIf="!isAddingInvestment() && isFormReady">
    <div
      class="d-flex align-items-center justify-content-end"
      style="height: 100%"
    >
      <button
        *ngIf="isPreviewScreen"
        class="btn secondary-btn mr-4"
        (click)="toggleIsPreviewScreen()"
      >
        Previous
      </button>
      <button
        *ngIf="isPreviewScreen"
        class="btn submit-or-preview-btn mr-4"
        [disabled]="isFormSubmitted || !isInvestmentDeclarationWindowOpen"
        [matTooltip]="
          isInvestmentDeclarationWindowOpen
            ? isFormSubmitted
              ? 'Investment Declaration is already submitted'
              : null
            : 'Investment declaration submission window is closed'
        "
        (click)="onSubmitButtonClick()"
      >
        Submit
      </button>
      <button
        *ngIf="!isPreviewScreen && !isFormSubmitted"
        class="btn secondary-btn mr-4"
        (click)="resetInvestmentDeclarationForm()"
      >
        Reset All Sections
      </button>
      <button
        *ngIf="!isPreviewScreen"
        mat-button
        class="submit-or-preview-btn mr-4"
        (click)="toggleIsPreviewScreen()"
      >
        Preview Investment Declaration
      </button>
    </div>
  </div>
</div>
