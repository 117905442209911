import * as i0 from "@angular/core";
import * as i1 from "./base.service";
var AdminService = /** @class */ (function () {
    function AdminService(baseService) {
        this.baseService = baseService;
    }
    AdminService.prototype.getAllAdminConfigComponents = function () {
        this.path = "adminComponent";
        return this.baseService.get(this.path);
    };
    AdminService.prototype.getConfigDetailsByFiscalApi = function (fiscal) {
        this.path = "admin/config/" + fiscal;
        return this.baseService.get(this.path);
    };
    AdminService.prototype.getCtcEarningExistingComponents = function () {
        this.path = "ctc";
        return this.baseService.get(this.path);
    };
    AdminService.prototype.getCtcDeductionExistingComponents = function () {
        this.path = "deductionsXref ";
        return this.baseService.get(this.path);
    };
    AdminService.prototype.postCtcEarningExistingComponents = function (reqBody) {
        this.path = "ctc";
        return this.baseService.post(this.path, reqBody);
    };
    AdminService.prototype.postCtcDeductionExistingComponents = function (reqBody) {
        this.path = "deductionsXref ";
        return this.baseService.post(this.path, reqBody);
    };
    AdminService.prototype.postConfigDetailsApi = function (payload) {
        this.path = "admin/config";
        return this.baseService.post(this.path, payload);
    };
    AdminService.prototype.getAllTimeBoundFeaturesListApi = function () {
        this.path = "timeBound/featuresXref";
        return this.baseService.get(this.path);
    };
    AdminService.prototype.getAllTimeBoundFeaturesConfigurationsApi = function (fiscal) {
        this.path = "timeBound/featureRequests/" + fiscal;
        return this.baseService.get(this.path);
    };
    AdminService.prototype.postTimeBoundFeatureConfigurationApi = function (featureConfig) {
        this.path = "timeBound/featureRequests";
        return this.baseService.post(this.path, featureConfig);
    };
    AdminService.prototype.deleteTimeBoundFeatureConfigurationByNameApi = function (fiscal, featureName) {
        this.path = "timeBound/featureRequests/" + fiscal + "/" + featureName;
        return this.baseService.delete(this.path);
    };
    AdminService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.ɵɵinject(i1.BaseService)); }, token: AdminService, providedIn: "root" });
    return AdminService;
}());
export { AdminService };
