import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
/*Properties are initialized so that Object.keys(new AllEmployees()) return an array having columns in
desired order.*/
var AllEmployeesYtdTableData = /** @class */ (function () {
    function AllEmployeesYtdTableData(name, email, uanNo) {
        if (name === void 0) { name = ""; }
        if (email === void 0) { email = ""; }
        if (uanNo === void 0) { uanNo = "N/A"; }
        this.expandAction = true;
        this.name = "";
        this.email = "";
        this.uanNo = "";
        this.totalIncome = 0;
        this.totalDeductions = 0;
        this.netPay = 0;
        this.days = 0;
        this.name = name;
        this.email = email;
        this.uanNo = uanNo;
    }
    return AllEmployeesYtdTableData;
}());
var YtdComponentTableData = /** @class */ (function () {
    function YtdComponentTableData(catagories) {
        if (catagories === void 0) { catagories = ""; }
        this.categories = "";
        this.april = 0;
        this.may = 0;
        this.june = 0;
        this.july = 0;
        this.august = 0;
        this.september = 0;
        this.october = 0;
        this.november = 0;
        this.december = 0;
        this.january = 0;
        this.february = 0;
        this.march = 0;
        this.categories = catagories;
    }
    return YtdComponentTableData;
}());
var INCOME = 0;
var DEDUCTIONS = 1;
var NET_PAY = 2;
var DAYS = 3;
var TOTAL_INCOME_STRING = "Total Income";
var TOTAL_DEDUCTIONS_STRING = "Total Deductions";
var TOTAL_STRING = "TOTAL";
var COMPONENTS_STRING = "components";
var YTD_MODEL_LIST_STRING = "ytdModelList";
var TOTAL_WORKING_DAYS = "WorkingDays";
var TOTAL_DAYS_STRING = "Total Working Days";
var NET_PAY_STRING = "Net Pay";
var YtdDetailsAllEmployeesComponent = /** @class */ (function () {
    function YtdDetailsAllEmployeesComponent(ytdDetailsService, snackBarService) {
        this.ytdDetailsService = ytdDetailsService;
        this.snackBarService = snackBarService;
        this.displayedColumns = Object.keys(new AllEmployeesYtdTableData());
        this.expandedElement = null;
        this.fiscal = getCurrentFiscalYear();
        this.years = [];
        this.columnDisplayNames = {
            income: "Income",
            deductions: "Deductions",
            april: "Apr",
            may: "May",
            june: "Jun",
            july: "Jul",
            august: "Aug",
            september: "Sep",
            october: "Oct",
            november: "Nov",
            december: "Dec",
            january: "Jan",
            february: "Feb",
            march: "Mar",
        };
        this.tooltipForColumn = {
            name: true,
            email: true,
            employeeId: true,
            uanNo: true,
        };
        this.showSpinner = true;
        this.s = Object.keys(new YtdComponentTableData());
        this.incomeCols = tslib_1.__spread(["income"], Object.keys(new YtdComponentTableData()));
        this.deductionsCols = tslib_1.__spread([
            "deductions"
        ], Object.keys(new YtdComponentTableData()));
        this.searchText$ = new Subject();
    }
    Object.defineProperty(YtdDetailsAllEmployeesComponent.prototype, "paginator", {
        set: function (value) {
            if (this.allEmployeesYtdDetails) {
                this.allEmployeesYtdDetails.paginator = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    YtdDetailsAllEmployeesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchText$
            .pipe(debounceTime(300), distinctUntilChanged(), switchMap(function (value) {
            return _this.ytdDetailsService.getYtdDetailsForAllEmployeeAPI(_this.fiscal, value);
        }))
            .subscribe(function (res) {
            _this.allEmployeesYtdDetails =
                new MatTableDataSource(_this.convertToAllEmployeeYtdTableDataArray(res));
            _this.allEmployeesYtdDetails.sort = _this.sort;
            _this.allEmployeesYtdDetails.paginator = _this.paginator;
        });
        this.years = Array.from(new Array(11), function (x, i) { return getCurrentFiscalYear() - i; });
        this.getYtdDetailsForAllEmployees(this.fiscal);
        this.incomeCols.splice(1, 1);
        this.deductionsCols.splice(1, 1);
    };
    YtdDetailsAllEmployeesComponent.prototype.onSearch = function ($event) {
        var value = $event.target.value;
        this.searchText$.next(value);
    };
    YtdDetailsAllEmployeesComponent.prototype.generateNameFromEmail = function (email) {
        var nameArray = email.split("@")[0].split(".");
        if (nameArray.length === 1) {
            return this.capitalizeFirstLetter(nameArray[0]);
        }
        return this.capitalizeFirstLetter(nameArray[0]) + " " + this.capitalizeFirstLetter(nameArray[1]);
    };
    YtdDetailsAllEmployeesComponent.prototype.capitalizeFirstLetter = function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    YtdDetailsAllEmployeesComponent.prototype.convertToAllEmployeeYtdTableDataArray = function (data) {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d, e_5, _e;
        var convertedData = [];
        try {
            for (var data_1 = tslib_1.__values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                var emp = data_1_1.value;
                var email = emp.employeeId;
                var tableDataRow = new AllEmployeesYtdTableData(this.capitalizeFirstLetter(emp.firstName) + " " + this.capitalizeFirstLetter(emp.lastName), email, emp.uanNo);
                if (emp[YTD_MODEL_LIST_STRING].length !== 0) {
                    var totalIncomeComponent = emp[YTD_MODEL_LIST_STRING][INCOME][COMPONENTS_STRING][TOTAL_INCOME_STRING];
                    var totalDeductionsComponent = emp[YTD_MODEL_LIST_STRING][DEDUCTIONS][COMPONENTS_STRING][TOTAL_DEDUCTIONS_STRING];
                    var totalWorkingDays = emp[YTD_MODEL_LIST_STRING][DAYS][COMPONENTS_STRING][TOTAL_WORKING_DAYS];
                    var netPay = emp[YTD_MODEL_LIST_STRING][NET_PAY][COMPONENTS_STRING][NET_PAY_STRING];
                    /*This check is added because some test employees have undefined components*/
                    if (totalIncomeComponent &&
                        totalDeductionsComponent &&
                        totalWorkingDays) {
                        try {
                            for (var totalIncomeComponent_1 = (e_2 = void 0, tslib_1.__values(totalIncomeComponent)), totalIncomeComponent_1_1 = totalIncomeComponent_1.next(); !totalIncomeComponent_1_1.done; totalIncomeComponent_1_1 = totalIncomeComponent_1.next()) {
                                var el = totalIncomeComponent_1_1.value;
                                if (el.payrollKey === TOTAL_STRING) {
                                    tableDataRow.totalIncome = el.amount;
                                }
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (totalIncomeComponent_1_1 && !totalIncomeComponent_1_1.done && (_b = totalIncomeComponent_1.return)) _b.call(totalIncomeComponent_1);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        try {
                            for (var totalDeductionsComponent_1 = (e_3 = void 0, tslib_1.__values(totalDeductionsComponent)), totalDeductionsComponent_1_1 = totalDeductionsComponent_1.next(); !totalDeductionsComponent_1_1.done; totalDeductionsComponent_1_1 = totalDeductionsComponent_1.next()) {
                                var el = totalDeductionsComponent_1_1.value;
                                if (el.payrollKey === TOTAL_STRING) {
                                    tableDataRow.totalDeductions = el.amount;
                                }
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (totalDeductionsComponent_1_1 && !totalDeductionsComponent_1_1.done && (_c = totalDeductionsComponent_1.return)) _c.call(totalDeductionsComponent_1);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                        try {
                            for (var totalWorkingDays_1 = (e_4 = void 0, tslib_1.__values(totalWorkingDays)), totalWorkingDays_1_1 = totalWorkingDays_1.next(); !totalWorkingDays_1_1.done; totalWorkingDays_1_1 = totalWorkingDays_1.next()) {
                                var el = totalWorkingDays_1_1.value;
                                if (el.payrollKey === TOTAL_DAYS_STRING) {
                                    tableDataRow.days = el.amount;
                                }
                            }
                        }
                        catch (e_4_1) { e_4 = { error: e_4_1 }; }
                        finally {
                            try {
                                if (totalWorkingDays_1_1 && !totalWorkingDays_1_1.done && (_d = totalWorkingDays_1.return)) _d.call(totalWorkingDays_1);
                            }
                            finally { if (e_4) throw e_4.error; }
                        }
                        try {
                            for (var netPay_1 = (e_5 = void 0, tslib_1.__values(netPay)), netPay_1_1 = netPay_1.next(); !netPay_1_1.done; netPay_1_1 = netPay_1.next()) {
                                var el = netPay_1_1.value;
                                if (el.payrollKey === TOTAL_STRING) {
                                    tableDataRow.netPay = el.amount;
                                }
                            }
                        }
                        catch (e_5_1) { e_5 = { error: e_5_1 }; }
                        finally {
                            try {
                                if (netPay_1_1 && !netPay_1_1.done && (_e = netPay_1.return)) _e.call(netPay_1);
                            }
                            finally { if (e_5) throw e_5.error; }
                        }
                    }
                }
                else {
                    tableDataRow.totalIncome = 0;
                    tableDataRow.totalDeductions = 0;
                    tableDataRow.days = 0;
                    tableDataRow.netPay = 0;
                }
                convertedData.push(tableDataRow);
                this.empYtdComponentsTableData = tslib_1.__assign({}, this.empYtdComponentsTableData, this.convertToYtdComponentsTableDataArray(emp, email));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return convertedData;
    };
    YtdDetailsAllEmployeesComponent.prototype.convertToYtdComponentsTableDataArray = function (empData, empEmail) {
        var _a, e_6, _b, e_7, _c;
        var empYtdComponentsData = (_a = {},
            _a[empEmail] = {
                incomeData: [],
                deductionsData: [],
            },
            _a);
        /*Setting value for Income components.*/
        if (empData[YTD_MODEL_LIST_STRING].length !== 0) {
            for (var component in empData[YTD_MODEL_LIST_STRING][INCOME][COMPONENTS_STRING]) {
                var incomeData = new YtdComponentTableData(component);
                try {
                    for (var _d = (e_6 = void 0, tslib_1.__values(empData[YTD_MODEL_LIST_STRING][INCOME][COMPONENTS_STRING][component])), _e = _d.next(); !_e.done; _e = _d.next()) {
                        var el = _e.value;
                        incomeData[el.payrollKey.toLowerCase()] = el.amount;
                    }
                }
                catch (e_6_1) { e_6 = { error: e_6_1 }; }
                finally {
                    try {
                        if (_e && !_e.done && (_b = _d.return)) _b.call(_d);
                    }
                    finally { if (e_6) throw e_6.error; }
                }
                empYtdComponentsData[empEmail].incomeData.push(incomeData);
            }
            /*Setting value for Deduction components.*/
            for (var component in empData[YTD_MODEL_LIST_STRING][DEDUCTIONS][COMPONENTS_STRING]) {
                var deductionsData = new YtdComponentTableData(component);
                try {
                    for (var _f = (e_7 = void 0, tslib_1.__values(empData[YTD_MODEL_LIST_STRING][DEDUCTIONS][COMPONENTS_STRING][component])), _g = _f.next(); !_g.done; _g = _f.next()) {
                        var el = _g.value;
                        deductionsData[el.payrollKey.toLowerCase()] = el.amount;
                    }
                }
                catch (e_7_1) { e_7 = { error: e_7_1 }; }
                finally {
                    try {
                        if (_g && !_g.done && (_c = _f.return)) _c.call(_f);
                    }
                    finally { if (e_7) throw e_7.error; }
                }
                empYtdComponentsData[empEmail].deductionsData.push(deductionsData);
            }
        }
        return empYtdComponentsData;
    };
    YtdDetailsAllEmployeesComponent.prototype.getYtdDetailsForAllEmployees = function (fiscal, searchText) {
        var _this = this;
        if (fiscal === void 0) { fiscal = this.fiscal; }
        if (searchText === void 0) { searchText = this.searchInputValue; }
        this.ytdDetailsService
            .getYtdDetailsForAllEmployeeAPI(fiscal, searchText)
            .subscribe(function (res) {
            _this.allEmployeesYtdDetails =
                new MatTableDataSource(_this.convertToAllEmployeeYtdTableDataArray(res));
            _this.allEmployeesYtdDetails.sort = _this.sort;
            _this.allEmployeesYtdDetails.paginator = _this.paginator;
        }, function (err) {
            _this.snackBarService.add({
                message: "It's taking a bit long than expected, please try to reload the page.",
                action: "close",
                config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
            });
            _this.showSpinner = false;
        });
    };
    YtdDetailsAllEmployeesComponent.prototype.showTooltip = function (col) {
        return this.tooltipForColumn[col];
    };
    return YtdDetailsAllEmployeesComponent;
}());
export { YtdDetailsAllEmployeesComponent };
