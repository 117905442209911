import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "firstLetterToUpperCase",
})
export class FirstLetterToUpperCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }
    return value.replace(/\b\w/g, (char) => char.toUpperCase());
  }

}
