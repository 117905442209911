import { PipeTransform } from '@angular/core';
var NumberToWordsPipe = /** @class */ (function () {
    function NumberToWordsPipe() {
        this.ones = [
            '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
            'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
        ];
        this.tens = [
            '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
        ];
        this.scales = [
            '', 'thousand', 'lakh', 'crore'
        ];
    }
    NumberToWordsPipe.prototype.transform = function (value) {
        if (value === 0)
            return 'zero';
        if (value < 0)
            return 'minus ' + this.transform(-value);
        var words = [];
        var scale = 0;
        while (value > 0) {
            var chunk = void 0;
            if (scale === 1) {
                chunk = value % 100;
                value = Math.floor(value / 100);
            }
            else {
                chunk = value % 1000;
                value = Math.floor(value / 1000);
            }
            if (chunk) {
                var chunkWords = this.convertChunk(chunk);
                if (this.scales[scale]) {
                    words.unshift(chunkWords + ' ' + this.scales[scale]);
                }
                else {
                    words.unshift(chunkWords);
                }
            }
            scale++;
        }
        return words.join(' ').trim();
    };
    NumberToWordsPipe.prototype.convertChunk = function (value) {
        var hundreds = Math.floor(value / 100);
        var remainder = value % 100;
        var tens = Math.floor(remainder / 10);
        var units = remainder % 10;
        var chunkWords = [];
        if (hundreds) {
            chunkWords.push(this.ones[hundreds] + ' hundred');
        }
        if (remainder < 20) {
            chunkWords.push(this.ones[remainder]);
        }
        else {
            chunkWords.push(this.tens[tens]);
            if (units) {
                chunkWords.push(this.ones[units]);
            }
        }
        return chunkWords.join(' ').trim();
    };
    return NumberToWordsPipe;
}());
export { NumberToWordsPipe };
