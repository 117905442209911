import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[stringsOnly]'
})
export class StringOnlyDirective {

  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this.ngControl.value;
    this.ngControl.control.setValue(initialValue.replace(/[^a-zA-Z ]*/g, ''));
    if (initialValue !== this.ngControl.value) {
      event.stopPropagation();
    }
  }
}
