import { HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BaseService = /** @class */ (function () {
    function BaseService(http) {
        this.http = http;
        this.baseURL = environment.baseURL;
    }
    BaseService.prototype.get = function (path, httpParams, httpHeaders, responseType) {
        if (httpParams === void 0) { httpParams = null; }
        var url = this.getRequestURL(path);
        var apiRequest = this.http.get(url, {
            headers: this.setHeaders(httpHeaders),
            params: httpParams,
            responseType: responseType,
        });
        return apiRequest;
    };
    BaseService.prototype.getExcel = function (path, httpParams, httpHeaders) {
        if (httpParams === void 0) { httpParams = null; }
        var url = this.getRequestURL(path);
        var apiRequest = this.http.get(url, {
            headers: this.setHeaders(httpHeaders),
            params: httpParams,
            responseType: "blob",
            observe: "response",
        });
        return apiRequest;
    };
    BaseService.prototype.post = function (path, body, httpParams, responseType) {
        if (body === void 0) { body = {}; }
        if (httpParams === void 0) { httpParams = null; }
        var url = this.getRequestURL(path);
        var apiRequest = this.http.post(url, body, {
            headers: this.setHeaders(),
            params: httpParams,
            responseType: responseType,
        });
        return apiRequest;
    };
    BaseService.prototype.patch = function (path, body) {
        if (body === void 0) { body = {}; }
        var url = this.getRequestURL(path);
        var apiRequest = this.http.patch(url, body, {
            headers: this.setHeaders(),
        });
        return apiRequest;
    };
    BaseService.prototype.put = function (path, body, httpParams) {
        if (body === void 0) { body = {}; }
        if (httpParams === void 0) { httpParams = null; }
        var url = this.getRequestURL(path);
        var apiRequest = this.http.put(url, body, {
            headers: this.setHeaders(),
            params: httpParams,
        });
        return apiRequest;
    };
    BaseService.prototype.delete = function (path) {
        var url = this.getRequestURL(path);
        var apiRequest = this.http.delete(url, { headers: this.setHeaders() });
        return apiRequest;
    };
    BaseService.prototype.getRequestURL = function (path) {
        var url = "";
        if (path.startsWith("http://") || path.startsWith("https://")) {
            url = path;
        }
        else {
            url = this.baseURL + path;
        }
        return url;
    };
    BaseService.prototype.setHeaders = function (header) {
        if (!header) {
            header = new HttpHeaders();
        }
        header.append("Content-Type", "application/json");
        return header;
    };
    BaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseService_Factory() { return new BaseService(i0.ɵɵinject(i1.HttpClient)); }, token: BaseService, providedIn: "root" });
    return BaseService;
}());
export { BaseService };
