import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from "@angular/core";
import { FormControl, FormGroup, } from "@angular/forms";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { forkJoin, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { PayrollDialogComponent } from "../payroll-dialog/payroll-dialog.component";
var FEATURE_CONFIG_SAVED_SUCCESSFULLY_MSG = "Feature configuration was saved successfully";
var AdminSettingsComponent = /** @class */ (function () {
    function AdminSettingsComponent(adminService, formBuilder, snackBarService, dialog, spinnerService) {
        var _this = this;
        this.adminService = adminService;
        this.formBuilder = formBuilder;
        this.snackBarService = snackBarService;
        this.dialog = dialog;
        this.spinnerService = spinnerService;
        this.timeBoundFeaturesInfo = [];
        this.activeTimeBoundFeaturesConfigs = [];
        this.inactiveTimeBoundFeaturesConfigs = [];
        this.unsavedTimeBoundFeaturesConfigs = [];
        this.selectedTabIndex = 0;
        this.endDate = null;
        this.startDate = null;
        this.todaysDate = new Date();
        this.isEditingFeature = false;
        this.maxStartAndEndDate = new Date(getCurrentFiscalYear() + 1, 2, 31);
        this.editFeatureConfigDictionary = {};
        this.adminSettingsFormInfo = {};
        this.tooltipsDictionary = {};
        this.configSequenceAndIdDictionary = {};
        this.fiscal = getCurrentFiscalYear();
        this.timeBoundFeaturesFiscal = getCurrentFiscalYear();
        this.years = [];
        this.isAddingFeature = false;
        this.showSpinner = true;
        this.currentFiscalYear = getCurrentFiscalYear();
        this.orderBySequenceNumberAcs = function (a, b) {
            return (_this.configSequenceAndIdDictionary[a.key].sequenceNumber -
                _this.configSequenceAndIdDictionary[b.key].sequenceNumber);
        };
        this.recentAddedEarnComp = [];
        this.existingEarnComp = [];
        this.recentAddedDeductComp = [];
        this.existingDeductComp = [];
        this.ctcEarnForm = this.formBuilder.group({
            componentName: "",
            componentType: "",
            isTaxable: null,
        });
        this.ctcDeductForm = this.formBuilder.group({
            componentName: "",
            limit: "",
            isTaxSaving: null,
            componentType: "",
        });
        this.editEarnCompDetails = {};
        this.editEarnCompIndex = -1;
        this.editDeductCompDetails = {};
        this.editDeductCompIndex = -1;
    }
    AdminSettingsComponent.prototype.ngOnInit = function () {
        this.years = Array.from(new Array(11), function (x, i) { return getCurrentFiscalYear() - i; });
        this.adminSettingsForm = this.formBuilder.group({});
        this.setMinStartDate();
        this.getAdminConfigsAndSetFormControls();
        this.getAllTimeBoundFeaturesConfigurations();
        this.getCtcEarningExistingComponents();
        this.getCtcDeductionExistingComponents();
    };
    AdminSettingsComponent.prototype.getCtcEarningExistingComponents = function () {
        var _this = this;
        this.adminService.getCtcEarningExistingComponents().subscribe(function (data) {
            _this.existingEarnComp = data;
        }, function (err) {
            console.error(err.error);
        });
    };
    AdminSettingsComponent.prototype.getCtcDeductionExistingComponents = function () {
        var _this = this;
        this.adminService.getCtcDeductionExistingComponents().subscribe(function (data) {
            if (data) {
                _this.existingDeductComp = data;
            }
        }, function (err) {
            console.error(err.error);
        });
    };
    AdminSettingsComponent.prototype.postCtcEarningExistingComponents = function (reqBody) {
        var _this = this;
        this.spinnerService.openSpinnerDialog();
        this.adminService.postCtcEarningExistingComponents(reqBody).subscribe(function (response) {
            if (response) {
                _this.spinnerService.closeSpinnerDialog();
                _this.snackBarService.add({
                    message: response.message,
                    action: "close",
                    config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
                });
                _this.clearCtcData();
                _this.getCtcEarningExistingComponents();
            }
        }, function (err) {
            _this.spinnerService.closeSpinnerDialog();
            _this.snackBarService.add({
                message: err.error.message,
                action: "close",
                config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
            });
            console.error(err);
        });
    };
    AdminSettingsComponent.prototype.postCtcDeductionExistingComponents = function (reqBody) {
        var _this = this;
        this.spinnerService.openSpinnerDialog();
        this.adminService.postCtcDeductionExistingComponents(reqBody).subscribe(function (response) {
            if (response) {
                _this.spinnerService.closeSpinnerDialog();
                _this.snackBarService.add({
                    message: response.message,
                    action: "close",
                    config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
                });
                _this.clearCtcData();
                _this.getCtcDeductionExistingComponents();
            }
        }, function (err) {
            _this.spinnerService.closeSpinnerDialog();
            _this.snackBarService.add({
                message: err.error.message,
                action: "close",
                config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
            });
            console.error(err);
        });
    };
    AdminSettingsComponent.prototype.submitCtcData = function () {
        var _this = this;
        this.dialog.open(PayrollDialogComponent, {
            disableClose: true,
            width: this.selectedTabIndex ? "650px" : "550px",
            data: {
                title: "Confirmation",
                msg: "Below data will be added to CTC " + (this.selectedTabIndex ? "deduction" : "earning") + " existing components. Click on yes to submit the data.",
                dialogType: "post",
                ctcCompData: this.selectedTabIndex
                    ? this.recentAddedDeductComp
                    : this.recentAddedEarnComp,
                selectedCtcTab: this.selectedTabIndex,
                onYesClickFunction: function () {
                    switch (_this.selectedTabIndex) {
                        case 0:
                            _this.postCtcEarningExistingComponents(_this.recentAddedEarnComp);
                            break;
                        case 1:
                            _this.postCtcDeductionExistingComponents(_this.recentAddedDeductComp);
                            break;
                    }
                },
            },
        });
    };
    AdminSettingsComponent.prototype.clearCtcData = function () {
        switch (this.selectedTabIndex) {
            case 0:
                this.ctcEarnForm.reset();
                this.recentAddedEarnComp = [];
                this.editEarnCompDetails = {};
                this.editEarnCompIndex = -1;
                break;
            case 1:
                this.ctcDeductForm.reset();
                this.recentAddedDeductComp = [];
                this.editDeductCompDetails = {};
                this.editDeductCompIndex = -1;
                break;
        }
    };
    AdminSettingsComponent.prototype.displayCtcActionButton = function () {
        switch (this.selectedTabIndex) {
            case 0:
                return this.recentAddedEarnComp && this.recentAddedEarnComp.length
                    ? true
                    : false;
            case 1:
                return this.recentAddedDeductComp && this.recentAddedDeductComp.length
                    ? true
                    : false;
            default:
                return false;
        }
    };
    AdminSettingsComponent.prototype.setMinStartDate = function () {
        this.minStartDate = this.todaysDate;
    };
    AdminSettingsComponent.prototype.addFormControl = function (form, controlName, control) {
        form.addControl(controlName, control);
    };
    AdminSettingsComponent.prototype.isParentConfig = function (sequenceNumber) {
        return Number.isInteger(sequenceNumber);
    };
    AdminSettingsComponent.prototype.getParentSequenceNumber = function (childSequenceNumber) {
        return +childSequenceNumber.toString().split(".")[0];
    };
    AdminSettingsComponent.prototype.handleTabChange = function (event) {
        this.selectedTabIndex = event.index;
    };
    AdminSettingsComponent.prototype.getParentValue = function (configGroup, childSequenceNumber) {
        return this.adminSettingsFormInfo[configGroup][this.getParentSequenceNumber(childSequenceNumber)].lastValue;
    };
    /**
     * Updates AdminSettingsFormInfo with the 'updatedValue' if the config already exits, otherwise creates a config with 'lastValue' as null.
     */
    AdminSettingsComponent.prototype.updateAdminSettingsFormInfo = function (configName, sequenceNumber, configGroup, updatedValue) {
        if (this.isParentConfig(sequenceNumber)) {
            var parentConfigExists = !!this.adminSettingsFormInfo[configGroup][sequenceNumber];
            if (parentConfigExists) {
                this.adminSettingsFormInfo[configGroup][sequenceNumber].lastValue =
                    updatedValue;
            }
            else {
                var parentConfig = {
                    configName: configName,
                    lastValue: null,
                    children: {},
                };
                this.adminSettingsFormInfo[configGroup][sequenceNumber] = parentConfig;
            }
        }
        else {
            var childConfigExists = !!this.adminSettingsFormInfo[configGroup][this.getParentSequenceNumber(sequenceNumber)].children[configName];
            if (childConfigExists) {
                this.adminSettingsFormInfo[configGroup][this.getParentSequenceNumber(sequenceNumber)].children[configName].lastValue = updatedValue;
            }
            else {
                this.adminSettingsFormInfo[configGroup][this.getParentSequenceNumber(sequenceNumber)].children[configName] = {
                    lastValue: null,
                    childSequenceNumber: sequenceNumber,
                };
            }
        }
    };
    AdminSettingsComponent.prototype.updateSequenceInConfigSequenceAndIdDictionary = function (configName, sequenceNumber) {
        this.configSequenceAndIdDictionary[configName] = {
            sequenceNumber: sequenceNumber,
            settingId: null,
        };
    };
    AdminSettingsComponent.prototype.updateIdInConfigSequenceAndIdDictionary = function (configName, settingId) {
        this.configSequenceAndIdDictionary[configName].settingId = settingId;
    };
    AdminSettingsComponent.prototype.getConfigSequence = function (configName) {
        return this.configSequenceAndIdDictionary[configName].sequenceNumber;
    };
    AdminSettingsComponent.prototype.getConfigSettingId = function (configName) {
        return this.configSequenceAndIdDictionary[configName].settingId;
    };
    AdminSettingsComponent.prototype.updateTooltipDictionary = function (configName, tooltip) {
        this.tooltipsDictionary[configName] = tooltip;
    };
    AdminSettingsComponent.prototype.addConfigToAdminSettingsForm = function (configName, configGroup, value) {
        this.adminSettingsForm.get(configGroup).addControl(configName, new FormControl(value !== undefined ? value : null));
    };
    AdminSettingsComponent.prototype.addChildrenConfigsToAdminSettingsForm = function (children, configGroup) {
        for (var configName in children) {
            this.adminSettingsForm.get(configGroup).addControl(configName, new FormControl(children[configName].lastValue));
        }
    };
    AdminSettingsComponent.prototype.updateAdminSettingsForm = function (configName, configGroup, value) {
        var _a;
        this.adminSettingsForm.get(configGroup).patchValue((_a = {},
            _a[configName] = value,
            _a));
    };
    AdminSettingsComponent.prototype.removeChildrenConfigsFromAdminSettingsForm = function (config, configGroup) {
        var sequenceNumber = this.getConfigSequence(config);
        var children = this.adminSettingsFormInfo[configGroup][sequenceNumber].children;
        for (var configName in children) {
            this.adminSettingsForm.get(configGroup).removeControl(configName);
        }
    };
    /**
     * Calculates numbers of rows required in a particular config-group based on number of configs in that config-group.
     * @param noOfConfigs Number of configs in a config-group.
     * @returns Array of numbers containing elements from 0 to calculated required number of rows - 1.
     */
    AdminSettingsComponent.prototype.getNumberOfRowsArray = function (noOfConfigs) {
        var arrLength = noOfConfigs % 2 === 0 ? noOfConfigs / 2 : (noOfConfigs + 1) / 2;
        return Array.from({ length: arrLength }, function (_, index) { return index; });
    };
    AdminSettingsComponent.prototype.openSubmissionDialog = function () {
        var _this = this;
        this.dialog.open(PayrollDialogComponent, {
            data: {
                title: "Confirmation",
                msg: " Do you want to override the Admin Settings for fiscal year " + this.fiscal + "-" + (this.fiscal + 1) + "?",
                dialogType: "post",
                onYesClickFunction: function () { return _this.saveConfigDetails(); },
            },
        });
    };
    /**
     * Sets Parent configs as form controls for adminSettingsForm.
     */
    AdminSettingsComponent.prototype.getAdminConfigsAndSetFormControls = function () {
        var _this = this;
        this.adminService.getAllAdminConfigComponents().subscribe(function (res) {
            var _loop_1 = function (configGroup) {
                _this.adminSettingsForm.addControl(configGroup, new FormGroup({}));
                _this.adminSettingsFormInfo[configGroup] = {};
                res[configGroup].forEach(function (config) {
                    _this.updateAdminSettingsFormInfo(config.adminSubComponent, config.sequenceNumber, configGroup);
                    _this.updateSequenceInConfigSequenceAndIdDictionary(config.adminSubComponent, config.sequenceNumber);
                    _this.updateTooltipDictionary(config.adminSubComponent, config.description);
                    if (_this.isParentConfig(config.sequenceNumber)) {
                        _this.addConfigToAdminSettingsForm(config.adminSubComponent, configGroup);
                    }
                });
            };
            for (var configGroup in res) {
                _loop_1(configGroup);
            }
            _this.showSpinner = false;
            _this.getAdminConfigDetailsByFiscal();
        }, function (err) {
            _this.snackBarService.add({
                message: "It's taking a bit long than expected, please try to reload the page. ",
                action: "close",
                config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
            });
            _this.showSpinner = false;
        });
    };
    AdminSettingsComponent.prototype.onChange = function (radio, configGroup, config) {
        var configSequenceNumber = this.getConfigSequence(config);
        this.updateAdminSettingsFormInfo(config, configSequenceNumber, configGroup, radio.value);
        if (this.isParentConfig(configSequenceNumber)) {
            if (radio.value) {
                this.addChildrenConfigsToAdminSettingsForm(this.adminSettingsFormInfo[configGroup][configSequenceNumber]
                    .children, configGroup);
            }
            else {
                this.removeChildrenConfigsFromAdminSettingsForm(config, configGroup);
            }
        }
    };
    AdminSettingsComponent.prototype.getAdminConfigDetailsByFiscal = function () {
        var _this = this;
        this.adminService
            .getConfigDetailsByFiscalApi(this.fiscal)
            .subscribe(function (allConfigDetails) {
            _this.allConfigDetails = allConfigDetails;
            _this.setAdminSettingsFormControlValues(_this.allConfigDetails);
        });
    };
    /**
     * Sets all the child configs lastValue to null in AdminSettingsFormInfo
     */
    AdminSettingsComponent.prototype.resetAllChildrenConfigsInAdminSettingsFormInfo = function () {
        for (var configGroup in this.adminSettingsFormInfo) {
            for (var parentKey in this.adminSettingsFormInfo[configGroup]) {
                for (var child in this.adminSettingsFormInfo[configGroup][parentKey]
                    .children) {
                    this.adminSettingsFormInfo[configGroup][parentKey].children[child].lastValue = null;
                }
            }
        }
    };
    /**
     * Sets parent config values to null and removes child configs from AdminSettingsForm
     */
    AdminSettingsComponent.prototype.resetAdminSettingsFormAndFormInfoToInitState = function () {
        for (var configGroup in this.adminSettingsForm.controls) {
            for (var config in this.adminSettingsForm.controls[configGroup].controls) {
                if (this.isParentConfig(this.getConfigSequence(config))) {
                    this.removeChildrenConfigsFromAdminSettingsForm(config, configGroup);
                    this.updateAdminSettingsForm(config, configGroup, null);
                }
            }
        }
    };
    AdminSettingsComponent.prototype.onOrgSettingsFiscalChange = function () {
        this.resetAdminSettingsFormAndFormInfoToInitState();
        this.resetAllChildrenConfigsInAdminSettingsFormInfo();
        this.getAdminConfigDetailsByFiscal();
    };
    AdminSettingsComponent.prototype.onResetButtonClick = function () {
        this.resetAdminSettingsFormAndFormInfoToInitState();
        this.resetAllChildrenConfigsInAdminSettingsFormInfo();
        this.setAdminSettingsFormControlValues(this.allConfigDetails);
    };
    /**
     * Sets value for each parent config form-control of adminSettingsForm and add  child configs as form-controls (with value) if
     * corresponding parent elements value is true.
     */
    AdminSettingsComponent.prototype.setAdminSettingsFormControlValues = function (allConfigDetails) {
        var _this = this;
        allConfigDetails.forEach(function (configDetail) {
            if (_this.adminSettingsForm.get(configDetail.adminComponent)) {
                var configSequenceNumber = _this.getConfigSequence(configDetail.configuredSubComponent);
                _this.updateIdInConfigSequenceAndIdDictionary(configDetail.configuredSubComponent, configDetail.settingId);
                _this.updateAdminSettingsFormInfo(configDetail.configuredSubComponent, configSequenceNumber, configDetail.adminComponent, configDetail.status);
                if (_this.isParentConfig(configSequenceNumber)) {
                    _this.updateAdminSettingsForm(configDetail.configuredSubComponent, configDetail.adminComponent, configDetail.status);
                }
                else {
                    var parentConfigValue = _this.getParentValue(configDetail.adminComponent, configSequenceNumber);
                    if (parentConfigValue) {
                        // in case of reset, control might already be present
                        if (_this.adminSettingsForm.get(configDetail.adminComponent).get(configDetail.configuredSubComponent)) {
                            _this.updateAdminSettingsForm(configDetail.configuredSubComponent, configDetail.adminComponent, configDetail.status);
                        }
                        else {
                            _this.addConfigToAdminSettingsForm(configDetail.configuredSubComponent, configDetail.adminComponent, configDetail.status);
                        }
                    }
                }
            }
        });
    };
    /**
     * Hits POST API to save Admin settings
     */
    AdminSettingsComponent.prototype.saveConfigDetails = function () {
        var _this = this;
        var requestPayload = [];
        for (var configGroup in this.adminSettingsForm.value) {
            for (var config in this.adminSettingsForm.value[configGroup]) {
                var configObject = {
                    adminComponent: configGroup,
                    configuredSubComponent: config,
                    status: this.adminSettingsForm.value[configGroup][config],
                    fiscal: this.fiscal.toString(),
                    description: "",
                };
                requestPayload.push(configObject);
            }
        }
        this.spinnerService.openSpinnerDialog();
        this.adminService.postConfigDetailsApi(requestPayload).subscribe(function (res) {
            _this.spinnerService.closeSpinnerDialog();
            _this.snackBarService.add({
                message: res,
                action: "close",
                config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
            });
        }, function (err) {
            _this.spinnerService.closeSpinnerDialog();
            _this.setAdminSettingsFormControlValues(_this.allConfigDetails);
            _this.snackBarService.add({
                message: err.error.message,
                action: "close",
                config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
            });
            console.log(err);
        });
    };
    AdminSettingsComponent.prototype.getAllTimeBoundFeaturesInfoList = function (featureConfigs) {
        var _this = this;
        this.adminService
            .getAllTimeBoundFeaturesListApi()
            .subscribe(function (featuresInfo) {
            _this.timeBoundFeaturesInfo = featuresInfo;
            featureConfigs.forEach(function (featureConfig) {
                featuresInfo = featuresInfo.filter(function (featureInfo) {
                    return featureInfo.featureName !== featureConfig.featureName;
                });
            });
            _this.timeBoundFeatureNames = featuresInfo.map(function (featuresInfo) { return featuresInfo.featureName; });
        });
    };
    AdminSettingsComponent.prototype.addFeatureToActiveOrInactiveList = function (featureConfig) {
        new Date(featureConfig.endDate) < this.todaysDate
            ? this.inactiveTimeBoundFeaturesConfigs.push(featureConfig)
            : this.activeTimeBoundFeaturesConfigs.push(featureConfig);
    };
    AdminSettingsComponent.prototype.removeFeatureFromActiveOrInactiveList = function (status, index) {
        status === "ACTIVE"
            ? this.activeTimeBoundFeaturesConfigs.splice(index, 1)
            : this.inactiveTimeBoundFeaturesConfigs.splice(index, 1);
    };
    AdminSettingsComponent.prototype.getAllTimeBoundFeaturesConfigurations = function () {
        var _this = this;
        this.adminService
            .getAllTimeBoundFeaturesConfigurationsApi(this.timeBoundFeaturesFiscal.toString())
            .subscribe(function (featureConfigs) {
            featureConfigs.forEach(function (featureConfig) {
                _this.addFeatureToActiveOrInactiveList(featureConfig);
            });
            _this.getAllTimeBoundFeaturesInfoList(featureConfigs);
        });
    };
    AdminSettingsComponent.prototype.removeUnsavedFeatureFromTimeBoundFeatureNames = function (unsavedFeatureName) {
        this.timeBoundFeatureNames = this.timeBoundFeatureNames.filter(function (featureName) { return featureName !== unsavedFeatureName; });
    };
    AdminSettingsComponent.prototype.addUnsavedFeatureToTimeBoundFeatureNames = function (unsavedFeatureName) {
        this.timeBoundFeatureNames.push(unsavedFeatureName);
    };
    AdminSettingsComponent.prototype.resetAllFeatureInputs = function () {
        this.selectedFeature = null;
        this.startDate = null;
        this.endDate = null;
    };
    AdminSettingsComponent.prototype.updateEditFeatureConfigDictionary = function (feature) {
        this.editFeatureConfigDictionary[feature.featureName] = feature;
    };
    AdminSettingsComponent.prototype.addFeatureToUnsavedList = function () {
        var formatDate = function (date) {
            return date.toISOString().replace("T", " ").substring(0, 19);
        };
        var featureConfig = this.getTimeBoundFeatureConfigurationObj(this.selectedFeature, formatDate(new Date(this.startDate)), formatDate(new Date(this.endDate)));
        this.unsavedTimeBoundFeaturesConfigs.push(featureConfig);
        if (this.isAddingFeature) {
            this.removeUnsavedFeatureFromTimeBoundFeatureNames(this.selectedFeature);
        }
        else {
            this.isEditingUnsavedFeature = false;
            this.isEditingFeature = false;
            this.isAddingFeature = true;
        }
        this.resetAllFeatureInputs();
    };
    AdminSettingsComponent.prototype.getTimeBoundFeatureConfigurationObj = function (featureName, startDate, endDate) {
        return {
            featureName: featureName,
            startDate: startDate,
            endDate: endDate,
        };
    };
    AdminSettingsComponent.prototype.removeFeatureFromUnsavedList = function (index, featureName) {
        this.unsavedTimeBoundFeaturesConfigs.splice(index, 1);
        if (this.editFeatureConfigDictionary[featureName]) {
            var feature = this.editFeatureConfigDictionary[featureName];
            this.addFeatureToActiveOrInactiveList(feature);
        }
        else {
            this.addUnsavedFeatureToTimeBoundFeatureNames(featureName);
        }
    };
    AdminSettingsComponent.prototype.submitTimeBoundFeaturesConfigs = function () {
        var e_1, _a;
        var _this = this;
        var apiRequests = [];
        var _loop_2 = function (featureConfig) {
            apiRequests.push(this_1.adminService
                .postTimeBoundFeatureConfigurationApi(tslib_1.__assign({}, featureConfig, { fiscal: this_1.timeBoundFeaturesFiscal.toString() }))
                .pipe(map(function (featureConfig) { return ({
                status: "SUCCESS",
                featureConfig: featureConfig,
            }); }), catchError(function (err) {
                return of({
                    status: "FAILED",
                    msg: "Feature configurations submission failed for " + featureConfig.featureName + ".",
                });
            })));
        };
        var this_1 = this;
        try {
            for (var _b = tslib_1.__values(this.unsavedTimeBoundFeaturesConfigs), _c = _b.next(); !_c.done; _c = _b.next()) {
                var featureConfig = _c.value;
                _loop_2(featureConfig);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        forkJoin(apiRequests).subscribe(function (res) {
            var e_2, _a;
            var _loop_3 = function (feature) {
                if (feature.status === "SUCCESS") {
                    _this.unsavedTimeBoundFeaturesConfigs =
                        _this.unsavedTimeBoundFeaturesConfigs.filter(function (featureConfig) {
                            return featureConfig.featureName !==
                                feature.featureConfig
                                    .featureName;
                        });
                    _this.activeTimeBoundFeaturesConfigs.push(feature.featureConfig);
                    _this.snackBarService.add({
                        message: FEATURE_CONFIG_SAVED_SUCCESSFULLY_MSG,
                        action: "close",
                        config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
                    });
                }
                else if (feature.status === "FAILED") {
                    _this.snackBarService.add({
                        message: feature.msg,
                        action: "close",
                        config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
                    });
                }
            };
            try {
                for (var res_1 = tslib_1.__values(res), res_1_1 = res_1.next(); !res_1_1.done; res_1_1 = res_1.next()) {
                    var feature = res_1_1.value;
                    _loop_3(feature);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (res_1_1 && !res_1_1.done && (_a = res_1.return)) _a.call(res_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        });
    };
    AdminSettingsComponent.prototype.deleteTimeBoundFeatureConfigsByName = function (index, status, featureName) {
        var _this = this;
        this.adminService
            .deleteTimeBoundFeatureConfigurationByNameApi(this.timeBoundFeaturesFiscal.toString(), featureName)
            .subscribe(function (res) {
            _this.timeBoundFeatureNames.push(featureName);
            if (status === "ACTIVE") {
                _this.activeTimeBoundFeaturesConfigs.splice(index, 1);
            }
            else {
                _this.inactiveTimeBoundFeaturesConfigs.splice(index, 1);
            }
            _this.snackBarService.add({
                message: "Time bound configuration for " + featureName + " is deleted",
                action: "close",
                config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
            });
        }, function (err) {
            _this.snackBarService.add({
                message: err.error.message,
                action: "close",
                config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
            });
        });
    };
    AdminSettingsComponent.prototype.openDeleteFeatureDialog = function (index, status, featureName) {
        var _this = this;
        var dialogRef = this.dialog.open(PayrollDialogComponent, {
            data: {
                tittle: "Confirmation",
                dialogType: "delete",
                msg: " Do you want to delete time bound configurations for feature: " + featureName,
                onYesClickFunction: function () {
                    return _this.deleteTimeBoundFeatureConfigsByName(index, status, featureName);
                },
            },
        });
    };
    AdminSettingsComponent.prototype.openSubmitFeaturesDialog = function (templateRef) {
        this.dialog.open(templateRef);
    };
    AdminSettingsComponent.prototype.updateEndDateMinDate = function () {
        if (this.startDate) {
            var minEndDate = new Date(this.startDate);
            minEndDate = minEndDate < this.todaysDate ? this.todaysDate : minEndDate;
            return minEndDate;
        }
        return null;
    };
    AdminSettingsComponent.prototype.setFeatureInputs = function (feature) {
        this.selectedFeature = feature.featureName;
        this.startDate = new Date(feature.startDate);
        this.endDate = new Date(feature.endDate);
    };
    AdminSettingsComponent.prototype.editFeature = function (feature, index, status) {
        this.isEditingFeature = true;
        this.isAddingFeature = false;
        this.featureForEdit = feature;
        this.removeFeatureFromActiveOrInactiveList(status, index);
        this.updateEditFeatureConfigDictionary(this.featureForEdit);
        this.setFeatureInputs(feature);
    };
    AdminSettingsComponent.prototype.editUnsavedFeature = function (feature, index) {
        this.isEditingUnsavedFeature = true;
        this.isEditingFeature = true;
        this.isAddingFeature = false;
        this.featureForEdit = feature;
        this.unsavedTimeBoundFeaturesConfigs.splice(index, 1);
        this.setFeatureInputs(feature);
    };
    AdminSettingsComponent.prototype.removeFeaturesFromUnsavedListAndAddToActiveOrInactiveList = function () {
        var _this = this;
        this.unsavedTimeBoundFeaturesConfigs.forEach(function (feature) {
            if (_this.editFeatureConfigDictionary[feature.featureName]) {
                _this.addFeatureToActiveOrInactiveList(feature);
            }
        });
        this.unsavedTimeBoundFeaturesConfigs = [];
    };
    AdminSettingsComponent.prototype.onCancelClick = function () {
        var e_3, _a;
        if (this.isEditingFeature) {
            if (this.editFeatureConfigDictionary[this.featureForEdit.featureName]) {
                this.addFeatureToActiveOrInactiveList(this.editFeatureConfigDictionary[this.featureForEdit.featureName]);
                delete this.editFeatureConfigDictionary[this.featureForEdit.featureName];
            }
            else {
                this.addUnsavedFeatureToTimeBoundFeatureNames(this.featureForEdit.featureName);
            }
        }
        try {
            for (var _b = tslib_1.__values(this.unsavedTimeBoundFeaturesConfigs), _c = _b.next(); !_c.done; _c = _b.next()) {
                var feature = _c.value;
                if (this.editFeatureConfigDictionary[feature.featureName]) {
                    this.addFeatureToActiveOrInactiveList(this.editFeatureConfigDictionary[feature.featureName]);
                }
                else {
                    this.addUnsavedFeatureToTimeBoundFeatureNames(feature.featureName);
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.editFeatureConfigDictionary = {};
        this.unsavedTimeBoundFeaturesConfigs = [];
        this.isAddingFeature = false;
        this.isEditingFeature = false;
        this.isEditingUnsavedFeature = false;
        this.resetAllFeatureInputs();
    };
    AdminSettingsComponent.prototype.discardFeatureEdit = function () {
        if (this.isEditingUnsavedFeature) {
            this.isEditingUnsavedFeature = false;
            this.unsavedTimeBoundFeaturesConfigs.push(this.featureForEdit);
        }
        else {
            this.addFeatureToActiveOrInactiveList(this.featureForEdit);
        }
        this.isEditingFeature = false;
        this.isAddingFeature = true;
        this.resetAllFeatureInputs();
    };
    return AdminSettingsComponent;
}());
export { AdminSettingsComponent };
