import { OnInit } from "@angular/core";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(router, authService, loginService) {
        this.router = router;
        this.authService = authService;
        this.loginService = loginService;
        this.isIframe = false;
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.isIframe = window !== window.parent && !window.opener;
    };
    LoginComponent.prototype.login = function () {
        this.loginService.login();
    };
    return LoginComponent;
}());
export { LoginComponent };
