import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { LoginService } from "src/app/account/login.service";
import { SnackBarService } from "src/app/shared/services/snackbar.service";

const SESSION_EXPIRED_MESSAGE = "Session Expired.. Logging Out..";
const NOT_AUTHORIZED_MESSAGE  = "You are not authorized to access this service.";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  loginService: LoginService;
  snackBarService = this.injector.get(SnackBarService);

  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  constructor(private injector: Injector, private router: Router,private ngZone:NgZone) {}
  refreshToken(): Observable<any> {
    /* This if block covers the case when new token is still not received
     and the user makes another API request .*/
    if (this.refreshTokenInProgress) {
      return new Observable((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.loginService.silentRefresh().pipe(
        tap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        }),
        catchError((err) => {
          this.refreshTokenInProgress = false;
          return err;
        })
      );
    }
  }

  logout() {
    if (sessionStorage.getItem("user") !== null) {
      sessionStorage.removeItem("user");
    }
    this.ngZone.run(() => {
      this.router.navigate(["/login"]);
    });
    
  }

  isTokenExpired(): boolean {
    const expiresOn = moment(
      JSON.parse(sessionStorage.getItem("user")).expiresOn
    )
      .utc()
      .local();
    return expiresOn < moment(new Date());
  }

  handleResponseError(error, request?, next?) {
    switch (error.status) {
      case 400:
        return throwError(error);

      case 0:
        if (this.isTokenExpired()) {
          return this.refreshToken().pipe(
            switchMap(() => {
              request = this.loginService.addAuthHeader(request);
              return next.handle(request);
            }),
            catchError((e) => {
              this.snackBarService.add({
                message: SESSION_EXPIRED_MESSAGE,
                config: { duration: 2000,
                  panelClass: ['custom-snackbar-class']
                 },
              });
              this.logout();
              return throwError(e);
            })
          );
        } else {
          this.snackBarService.add({
            message:NOT_AUTHORIZED_MESSAGE,
            config: { duration: 2000,
              panelClass: ['custom-snackbar-class']
             },
          });
          return throwError(NOT_AUTHORIZED_MESSAGE);
        }

      case 500:
        return throwError(error);

      case 503:
        return throwError(error);

      default:
        return throwError(error);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.loginService = this.injector.get(LoginService);

    // Setting access token in request headers.
    req = this.loginService.addAuthHeader(req);

    return next.handle(req).pipe(
      catchError((error) => {
        return this.handleResponseError(error, req, next);
      })
    );
  }
}
