import { Component, OnInit, TemplateRef } from "@angular/core";
import {
  AdminService,
  AllAdminConfigComponentsResponse,
  ConfigDetailsByFiscalResponse,
  ConfigDetailsRequestPayload,
  TimeBoundFeature,
  TimeBoundFeatureConfiguration,
} from "../shared/services/admin.service";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
} from "@angular/forms";
import { SnackBarService } from "../shared/services/snackbar.service";
import { KeyValue } from "@angular/common";
import { SpinnerService } from "../shared/services/spinner.service";
import { MatDialog, MatDialogRef } from "@angular/material";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { Observable, forkJoin, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { PayrollDialogComponent } from "../payroll-dialog/payroll-dialog.component";
import { title } from "process";
import { PayrollDialogComponentInput } from "../payroll-dialog/payroll-dialog.utility";

const FEATURE_CONFIG_SAVED_SUCCESSFULLY_MSG =
  "Feature configuration was saved successfully";

type ParentConfig = {
  configName: string;
  lastValue: boolean;
  children: ChildConfig;
};

/**
 * childSequenceNumber is a float
 */
type ChildConfig = {
  [configName: string]: {
    lastValue: boolean;
    childSequenceNumber: number;
  };
};

/**
 * parentSequenceNumber is an integer
 */
type AdminSettingsFormInfo = {
  [configGroup: string]: {
    [parentSequenceNumber: number]: ParentConfig;
  };
};

type FeatureConfigSubmitError = {
  status: "FAILED" | "SUCCESS";
  msg: string;
};

type FeatureConfigSubmitSuccess = {
  status: "FAILED" | "SUCCESS";
  featureConfig: TimeBoundFeatureConfiguration;
};

type FeatureStatus = "ACTIVE" | "INACTIVE";
@Component({
  selector: "app-admin-settings",
  templateUrl: "./admin-settings.component.html",
  styleUrls: ["./admin-settings.component.scss"],
})
export class AdminSettingsComponent implements OnInit {
  allConfigDetails: ConfigDetailsByFiscalResponse[];
  timeBoundFeaturesInfo: TimeBoundFeature[] = [];
  selectedFeature: string;
  activeTimeBoundFeaturesConfigs: TimeBoundFeatureConfiguration[] = [];
  inactiveTimeBoundFeaturesConfigs: TimeBoundFeatureConfiguration[] = [];
  unsavedTimeBoundFeaturesConfigs: TimeBoundFeatureConfiguration[] = [];
  endDate: Date | null = null;
  startDate: Date | null = null;
  minStartDate: Date;
  minEndDate: Date;
  todaysDate = new Date();
  timeBoundFeatureNames: string[];
  isEditingFeature = false;
  featureForEdit: TimeBoundFeatureConfiguration;
  maxStartAndEndDate = new Date(getCurrentFiscalYear() + 1, 2, 31);
  editFeatureConfigDictionary: {
    [featureName: string]: TimeBoundFeatureConfiguration;
  } = {};
  isEditingUnsavedFeature: boolean;
  adminSettingsForm: FormGroup;
  adminSettingsFormInfo: AdminSettingsFormInfo = {};
  tooltipsDictionary: { [configName: string]: string } = {};
  configSequenceAndIdDictionary: {
    [index: string]: { sequenceNumber: number; settingId: number | null };
  } = {};
  fiscal: number = getCurrentFiscalYear();
  timeBoundFeaturesFiscal: number = getCurrentFiscalYear();
  years: number[] = [];
  isAddingFeature = false;
  showSpinner: boolean = true;
  currentFiscalYear: number = getCurrentFiscalYear();
  constructor(
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    this.years = Array.from(
      new Array(11),
      (x, i) => getCurrentFiscalYear() - i
    );
    this.adminSettingsForm = this.formBuilder.group({});
    this.setMinStartDate();
    this.getAdminConfigsAndSetFormControls();
    this.getAllTimeBoundFeaturesConfigurations();
  }

  setMinStartDate() {
    this.minStartDate = this.todaysDate;
  }

  addFormControl(
    form: FormGroup,
    controlName: string,
    control: AbstractControl
  ) {
    form.addControl(controlName, control);
  }

  isParentConfig(sequenceNumber: number): boolean {
    return Number.isInteger(sequenceNumber);
  }

  getParentSequenceNumber(childSequenceNumber: number): number {
    return +childSequenceNumber.toString().split(".")[0];
  }

  getParentValue(configGroup: string, childSequenceNumber: number): boolean {
    return this.adminSettingsFormInfo[configGroup][
      this.getParentSequenceNumber(childSequenceNumber)
    ].lastValue;
  }

  /**
   * Updates AdminSettingsFormInfo with the 'updatedValue' if the config already exits, otherwise creates a config with 'lastValue' as null.
   */
  updateAdminSettingsFormInfo(
    configName: string,
    sequenceNumber: number,
    configGroup: string,
    updatedValue?: boolean
  ) {
    if (this.isParentConfig(sequenceNumber)) {
      const parentConfigExists: boolean =
        !!this.adminSettingsFormInfo[configGroup][sequenceNumber];
      if (parentConfigExists) {
        this.adminSettingsFormInfo[configGroup][sequenceNumber].lastValue =
          updatedValue;
      } else {
        const parentConfig: ParentConfig = {
          configName: configName,
          lastValue: null,
          children: {},
        };
        this.adminSettingsFormInfo[configGroup][sequenceNumber] = parentConfig;
      }
    } else {
      const childConfigExists: boolean =
        !!this.adminSettingsFormInfo[configGroup][
          this.getParentSequenceNumber(sequenceNumber)
        ].children[configName];
      if (childConfigExists) {
        this.adminSettingsFormInfo[configGroup][
          this.getParentSequenceNumber(sequenceNumber)
        ].children[configName].lastValue = updatedValue;
      } else {
        this.adminSettingsFormInfo[configGroup][
          this.getParentSequenceNumber(sequenceNumber)
        ].children[configName] = {
          lastValue: null,
          childSequenceNumber: sequenceNumber,
        };
      }
    }
  }

  updateSequenceInConfigSequenceAndIdDictionary(
    configName: string,
    sequenceNumber: number
  ) {
    this.configSequenceAndIdDictionary[configName] = {
      sequenceNumber: sequenceNumber,
      settingId: null,
    };
  }

  updateIdInConfigSequenceAndIdDictionary(
    configName: string,
    settingId: number
  ) {
    this.configSequenceAndIdDictionary[configName].settingId = settingId;
  }

  getConfigSequence(configName: string): number {
    return this.configSequenceAndIdDictionary[configName].sequenceNumber;
  }

  getConfigSettingId(configName: string): number {
    return this.configSequenceAndIdDictionary[configName].settingId;
  }

  updateTooltipDictionary(configName: string, tooltip: string) {
    this.tooltipsDictionary[configName] = tooltip;
  }

  addConfigToAdminSettingsForm(
    configName: string,
    configGroup: string,
    value?: any
  ) {
    (this.adminSettingsForm.get(configGroup) as FormGroup).addControl(
      configName,
      new FormControl(value !== undefined ? value : null)
    );
  }

  addChildrenConfigsToAdminSettingsForm(
    children: ChildConfig,
    configGroup: string
  ) {
    for (const configName in children) {
      (this.adminSettingsForm.get(configGroup) as FormGroup).addControl(
        configName,
        new FormControl(children[configName].lastValue)
      );
    }
  }

  updateAdminSettingsForm(
    configName: string,
    configGroup: string,
    value: boolean | null
  ) {
    (this.adminSettingsForm.get(configGroup) as FormGroup).patchValue({
      [configName]: value,
    });
  }

  removeChildrenConfigsFromAdminSettingsForm(
    config: string,
    configGroup: string
  ) {
    const sequenceNumber = this.getConfigSequence(config);
    const children =
      this.adminSettingsFormInfo[configGroup][sequenceNumber].children;
    for (const configName in children) {
      (this.adminSettingsForm.get(configGroup) as FormGroup).removeControl(
        configName
      );
    }
  }

  /**
   * Calculates numbers of rows required in a particular config-group based on number of configs in that config-group.
   * @param noOfConfigs Number of configs in a config-group.
   * @returns Array of numbers containing elements from 0 to calculated required number of rows - 1.
   */
  getNumberOfRowsArray(noOfConfigs: number): number[] {
    const arrLength =
      noOfConfigs % 2 === 0 ? noOfConfigs / 2 : (noOfConfigs + 1) / 2;
    return Array.from({ length: arrLength }, (_, index) => index);
  }

  orderBySequenceNumberAcs = (
    a: KeyValue<string, FormControl>,
    b: KeyValue<string, FormControl>
  ): number => {
    return (
      this.configSequenceAndIdDictionary[a.key].sequenceNumber -
      this.configSequenceAndIdDictionary[b.key].sequenceNumber
    );
  };

  openSubmissionDialog() {
    this.dialog.open(PayrollDialogComponent, {
      data: {
        title: "Confirmation",
        msg: ` Do you want to override the Admin Settings for fiscal year ${
          this.fiscal
        }-${this.fiscal + 1}?`,
        dialogType: "post",
        onYesClickFunction:()=>this.saveConfigDetails(),
      } as PayrollDialogComponentInput,
    });
  }

  /**
   * Sets Parent configs as form controls for adminSettingsForm.
   */
  getAdminConfigsAndSetFormControls() {
    this.adminService
      .getAllAdminConfigComponents()
      .subscribe((res: AllAdminConfigComponentsResponse) => {
        for (const configGroup in res) {
          this.adminSettingsForm.addControl(configGroup, new FormGroup({}));
          this.adminSettingsFormInfo[configGroup] = {};
          res[configGroup].forEach((config) => {
            this.updateAdminSettingsFormInfo(
              config.adminSubComponent,
              config.sequenceNumber,
              configGroup
            );
            this.updateSequenceInConfigSequenceAndIdDictionary(
              config.adminSubComponent,
              config.sequenceNumber
            );
            this.updateTooltipDictionary(
              config.adminSubComponent,
              config.description
            );
            if (this.isParentConfig(config.sequenceNumber)) {
              this.addConfigToAdminSettingsForm(
                config.adminSubComponent,
                configGroup
              );
            }
          });
        }
        this.showSpinner = false;
        this.getAdminConfigDetailsByFiscal();
      },
    (err)=>{
      this.snackBarService.add({
        message: "It's taking a bit long than expected, please try to reload the page. ",
        action: "close",
        config: { duration: 4000,
          panelClass: ['custom-snackbar-class']
         },
      });
      this.showSpinner = false;
    });
  }

  onChange(radio, configGroup: string, config: string) {
    const configSequenceNumber = this.getConfigSequence(config);
    this.updateAdminSettingsFormInfo(
      config,
      configSequenceNumber,
      configGroup,
      radio.value
    );
    if (this.isParentConfig(configSequenceNumber)) {
      if (radio.value) {
        this.addChildrenConfigsToAdminSettingsForm(
          this.adminSettingsFormInfo[configGroup][configSequenceNumber]
            .children,
          configGroup
        );
      } else {
        this.removeChildrenConfigsFromAdminSettingsForm(config, configGroup);
      }
    }
  }

  getAdminConfigDetailsByFiscal() {
    this.adminService
      .getConfigDetailsByFiscalApi(this.fiscal)
      .subscribe((allConfigDetails: ConfigDetailsByFiscalResponse[]) => {
        this.allConfigDetails = allConfigDetails;
        this.setAdminSettingsFormControlValues(this.allConfigDetails);
      });
  }

  /**
   * Sets all the child configs lastValue to null in AdminSettingsFormInfo
   */
  resetAllChildrenConfigsInAdminSettingsFormInfo() {
    for (const configGroup in this.adminSettingsFormInfo) {
      for (const parentKey in this.adminSettingsFormInfo[configGroup]) {
        for (const child in this.adminSettingsFormInfo[configGroup][parentKey]
          .children) {
          this.adminSettingsFormInfo[configGroup][parentKey].children[
            child
          ].lastValue = null;
        }
      }
    }
  }

  /**
   * Sets parent config values to null and removes child configs from AdminSettingsForm
   */
  resetAdminSettingsFormAndFormInfoToInitState() {
    for (const configGroup in this.adminSettingsForm.controls) {
      for (const config in (
        this.adminSettingsForm.controls[configGroup] as FormGroup
      ).controls) {
        if (this.isParentConfig(this.getConfigSequence(config))) {
          this.removeChildrenConfigsFromAdminSettingsForm(config, configGroup);
          this.updateAdminSettingsForm(config, configGroup, null);
        }
      }
    }
  }

  onOrgSettingsFiscalChange() {
    this.resetAdminSettingsFormAndFormInfoToInitState();
    this.resetAllChildrenConfigsInAdminSettingsFormInfo();
    this.getAdminConfigDetailsByFiscal();
  }

  onResetButtonClick() {
    this.resetAdminSettingsFormAndFormInfoToInitState();
    this.resetAllChildrenConfigsInAdminSettingsFormInfo();
    this.setAdminSettingsFormControlValues(this.allConfigDetails);
  }

  /**
   * Sets value for each parent config form-control of adminSettingsForm and add  child configs as form-controls (with value) if
   * corresponding parent elements value is true.
   */
  setAdminSettingsFormControlValues(
    allConfigDetails: ConfigDetailsByFiscalResponse[]
  ) {
    allConfigDetails.forEach((configDetail) => {
      if (
        this.adminSettingsForm.get(configDetail.adminComponent) as FormGroup
      ) {
        const configSequenceNumber = this.getConfigSequence(
          configDetail.configuredSubComponent
        );
        this.updateIdInConfigSequenceAndIdDictionary(
          configDetail.configuredSubComponent,
          configDetail.settingId
        );
        this.updateAdminSettingsFormInfo(
          configDetail.configuredSubComponent,
          configSequenceNumber,
          configDetail.adminComponent,
          configDetail.status
        );
        if (this.isParentConfig(configSequenceNumber)) {
          this.updateAdminSettingsForm(
            configDetail.configuredSubComponent,
            configDetail.adminComponent,
            configDetail.status
          );
        } else {
          const parentConfigValue = this.getParentValue(
            configDetail.adminComponent,
            configSequenceNumber
          );
          if (parentConfigValue) {
            // in case of reset, control might already be present
            if (
              (
                this.adminSettingsForm.get(
                  configDetail.adminComponent
                ) as FormGroup
              ).get(configDetail.configuredSubComponent)
            ) {
              this.updateAdminSettingsForm(
                configDetail.configuredSubComponent,
                configDetail.adminComponent,
                configDetail.status
              );
            } else {
              this.addConfigToAdminSettingsForm(
                configDetail.configuredSubComponent,
                configDetail.adminComponent,
                configDetail.status
              );
            }
          }
        }
      }
    });
  }

  /**
   * Hits POST API to save Admin settings
   */
  saveConfigDetails() {
    const requestPayload: ConfigDetailsRequestPayload[] = [];
    for (const configGroup in this.adminSettingsForm.value) {
      for (const config in this.adminSettingsForm.value[configGroup]) {
        const configObject: ConfigDetailsRequestPayload = {
          adminComponent: configGroup,
          configuredSubComponent: config,
          status: this.adminSettingsForm.value[configGroup][config],
          fiscal: this.fiscal.toString(),
          description: "",
        };
        requestPayload.push(configObject);
      }
    }
    this.spinnerService.openSpinnerDialog();
    this.adminService.postConfigDetailsApi(requestPayload).subscribe(
      (res) => {
        this.spinnerService.closeSpinnerDialog();
        this.snackBarService.add({
          message: res,
          action: "close",
          config: { duration: 4000,
            panelClass: ['custom-snackbar-class']
           },
        });
      },
      (err) => {
        this.spinnerService.closeSpinnerDialog();
        this.setAdminSettingsFormControlValues(this.allConfigDetails);
        this.snackBarService.add({
          message: err.error.message,
          action: "close",
          config: { duration: 4000,
            panelClass: ['custom-snackbar-class']
           },
        });
        console.log(err);
      }
    );
  }

  getAllTimeBoundFeaturesInfoList(
    featureConfigs: TimeBoundFeatureConfiguration[]
  ) {
    this.adminService
      .getAllTimeBoundFeaturesListApi()
      .subscribe((featuresInfo) => {
        this.timeBoundFeaturesInfo = featuresInfo;
        featureConfigs.forEach((featureConfig) => {
          featuresInfo = featuresInfo.filter(
            (featureInfo) =>
              featureInfo.featureName !== featureConfig.featureName
          );
        });
        this.timeBoundFeatureNames = featuresInfo.map(
          (featuresInfo) => featuresInfo.featureName
        );
      });
  }

  addFeatureToActiveOrInactiveList(
    featureConfig: TimeBoundFeatureConfiguration
  ) {
    new Date(featureConfig.endDate) < this.todaysDate
      ? this.inactiveTimeBoundFeaturesConfigs.push(featureConfig)
      : this.activeTimeBoundFeaturesConfigs.push(featureConfig);
  }


  removeFeatureFromActiveOrInactiveList(status: FeatureStatus, index: number) {
    status === "ACTIVE"
      ? this.activeTimeBoundFeaturesConfigs.splice(index, 1)
      : this.inactiveTimeBoundFeaturesConfigs.splice(index, 1);
  }

  getAllTimeBoundFeaturesConfigurations() {
    this.adminService
      .getAllTimeBoundFeaturesConfigurationsApi(
        this.timeBoundFeaturesFiscal.toString()
      )
      .subscribe((featureConfigs) => {
        featureConfigs.forEach((featureConfig) => {
          this.addFeatureToActiveOrInactiveList(
           featureConfig
          );
        });
        this.getAllTimeBoundFeaturesInfoList(featureConfigs);
      });
  }

  removeUnsavedFeatureFromTimeBoundFeatureNames(unsavedFeatureName: string) {
    this.timeBoundFeatureNames = this.timeBoundFeatureNames.filter(
      (featureName) => featureName !== unsavedFeatureName
    );
  }

  addUnsavedFeatureToTimeBoundFeatureNames(unsavedFeatureName: string) {
    this.timeBoundFeatureNames.push(unsavedFeatureName);
  }

  resetAllFeatureInputs() {
    this.selectedFeature = null;
    this.startDate = null;
    this.endDate = null;
  }

  updateEditFeatureConfigDictionary(feature: TimeBoundFeatureConfiguration) {
    this.editFeatureConfigDictionary[feature.featureName] = feature;
  }

  addFeatureToUnsavedList() {
    const formatDate = (date: Date): string =>
      date.toISOString().replace('T', ' ').substring(0, 19);

    const featureConfig: TimeBoundFeatureConfiguration =
      this.getTimeBoundFeatureConfigurationObj(
        this.selectedFeature,
        formatDate(new Date(this.startDate)),
        formatDate(new Date(this.endDate))
      );
    this.unsavedTimeBoundFeaturesConfigs.push(featureConfig);
    if (this.isAddingFeature) {
      this.removeUnsavedFeatureFromTimeBoundFeatureNames(this.selectedFeature);
    } else {
      this.isEditingUnsavedFeature = false;
      this.isEditingFeature = false;
      this.isAddingFeature = true;
    }
    this.resetAllFeatureInputs();
  }

  getTimeBoundFeatureConfigurationObj(
    featureName: string,
    startDate: string,
    endDate: string
  ): TimeBoundFeatureConfiguration {
    return {
      featureName: featureName,
      startDate: startDate,
      endDate: endDate,
    };
  }

  removeFeatureFromUnsavedList(index: number, featureName: string) {
    this.unsavedTimeBoundFeaturesConfigs.splice(index, 1);
    if (this.editFeatureConfigDictionary[featureName]) {
      const feature: TimeBoundFeatureConfiguration =
        this.editFeatureConfigDictionary[featureName];
      this.addFeatureToActiveOrInactiveList(feature);
    } else {
      this.addUnsavedFeatureToTimeBoundFeatureNames(featureName);
    }
  }

  submitTimeBoundFeaturesConfigs() {
    const apiRequests: Observable<
      FeatureConfigSubmitSuccess | FeatureConfigSubmitError
    >[] = [];
    for (const featureConfig of this.unsavedTimeBoundFeaturesConfigs) {
      apiRequests.push(
        this.adminService
          .postTimeBoundFeatureConfigurationApi({
            ...featureConfig,
            fiscal: this.timeBoundFeaturesFiscal.toString(),
          })
          .pipe(
            map((featureConfig) => ({
              status: "SUCCESS" as const,
              featureConfig,
            })),
            catchError((err) => {
              return of({
                status: "FAILED" as const,
                msg: `Feature configurations submission failed for ${featureConfig.featureName}.`,
              });
            })
          )
      );
    }
    forkJoin(apiRequests).subscribe((res) => {
      for (const feature of res) {
        if (feature.status === "SUCCESS") {
          this.unsavedTimeBoundFeaturesConfigs =
            this.unsavedTimeBoundFeaturesConfigs.filter(
              (featureConfig) =>
                featureConfig.featureName !==
                (feature as FeatureConfigSubmitSuccess).featureConfig
                  .featureName
            );
          this.activeTimeBoundFeaturesConfigs.push(
              (feature as FeatureConfigSubmitSuccess).featureConfig
          );
          this.snackBarService.add({
            message: FEATURE_CONFIG_SAVED_SUCCESSFULLY_MSG,
            action: "close",
            config: { duration: 4000,
              panelClass: ['custom-snackbar-class']
             },
          });
        } else if (feature.status === "FAILED") {
          this.snackBarService.add({
            message: (feature as FeatureConfigSubmitError).msg,
            action: "close",
            config: { duration: 4000,
              panelClass: ['custom-snackbar-class']
             },
          });
        }
      }
    });
  }

  deleteTimeBoundFeatureConfigsByName(
    index: number,
    status: FeatureStatus,
    featureName: string
  ) {
    this.adminService
      .deleteTimeBoundFeatureConfigurationByNameApi(
        this.timeBoundFeaturesFiscal.toString(),
        featureName
      )
      .subscribe(
        (res) => {
          this.timeBoundFeatureNames.push(featureName);
          if (status === "ACTIVE") {
            this.activeTimeBoundFeaturesConfigs.splice(index, 1);
          } else {
            this.inactiveTimeBoundFeaturesConfigs.splice(index, 1);
          }
          this.snackBarService.add({
            message: `Time bound configuration for ${featureName} is deleted`,
            action: "close",
            config: { duration: 4000,
              panelClass: ['custom-snackbar-class']
             },
          });
        },
        (err) => {
          this.snackBarService.add({
            message: err.error.message,
            action: "close",
            config: { duration: 4000,
              panelClass: ['custom-snackbar-class']
             },
          });
        }
      );
  }

  openDeleteFeatureDialog(
    index: number,
    status: FeatureStatus,
    featureName: string
  ) {
    console.log('featureName',featureName);

    const dialogRef: MatDialogRef<any> = this.dialog.open(PayrollDialogComponent, {
      data: {
        tittle: 'Confirmation',
        dialogType: 'delete',
        msg: ` Do you want to delete time bound configurations for feature: ${featureName}`,
        onYesClickFunction: () => this.deleteTimeBoundFeatureConfigsByName(index, status, featureName),
      } as unknown as PayrollDialogComponentInput,
    });
  }

  openSubmitFeaturesDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  updateEndDateMinDate() {
    if (this.startDate) {
      let minEndDate = new Date(this.startDate);
      minEndDate = minEndDate < this.todaysDate ? this.todaysDate : minEndDate;
      return minEndDate;
    }
    return null;
  }

  setFeatureInputs(feature: TimeBoundFeatureConfiguration) {
    this.selectedFeature = feature.featureName;
    this.startDate = new Date(feature.startDate);
    this.endDate = new Date(feature.endDate);
  }

  editFeature(
    feature: TimeBoundFeatureConfiguration,
    index: number,
    status: FeatureStatus
  ) {
    this.isEditingFeature = true;
    this.isAddingFeature = false;
    this.featureForEdit = feature;
    this.removeFeatureFromActiveOrInactiveList(status, index);
    this.updateEditFeatureConfigDictionary(this.featureForEdit);
    this.setFeatureInputs(feature);
  }

  editUnsavedFeature(feature: TimeBoundFeatureConfiguration, index: number) {
    this.isEditingUnsavedFeature = true;
    this.isEditingFeature = true;
    this.isAddingFeature = false;
    this.featureForEdit = feature;
    this.unsavedTimeBoundFeaturesConfigs.splice(index, 1);
    this.setFeatureInputs(feature);
  }

  removeFeaturesFromUnsavedListAndAddToActiveOrInactiveList() {
    this.unsavedTimeBoundFeaturesConfigs.forEach((feature) => {
      if (this.editFeatureConfigDictionary[feature.featureName]) {
        this.addFeatureToActiveOrInactiveList(feature);
      }
    });
    this.unsavedTimeBoundFeaturesConfigs = [];
  }

  onCancelClick() {
    if (this.isEditingFeature) {
      if (this.editFeatureConfigDictionary[this.featureForEdit.featureName]) {
        this.addFeatureToActiveOrInactiveList(
          this.editFeatureConfigDictionary[this.featureForEdit.featureName]
        );
        delete this.editFeatureConfigDictionary[
          this.featureForEdit.featureName
        ];
      } else {
        this.addUnsavedFeatureToTimeBoundFeatureNames(
          this.featureForEdit.featureName
        );
      }
    }
    for (const feature of this.unsavedTimeBoundFeaturesConfigs) {
      if (this.editFeatureConfigDictionary[feature.featureName]) {
        this.addFeatureToActiveOrInactiveList(
          this.editFeatureConfigDictionary[feature.featureName]
        );
      } else {
        this.addUnsavedFeatureToTimeBoundFeatureNames(feature.featureName);
      }
    }
    this.editFeatureConfigDictionary = {};
    this.unsavedTimeBoundFeaturesConfigs = [];
    this.isAddingFeature = false;
    this.isEditingFeature = false;
    this.isEditingUnsavedFeature = false;
    this.resetAllFeatureInputs();
  }

  discardFeatureEdit() {
    if (this.isEditingUnsavedFeature) {
      this.isEditingUnsavedFeature = false;
      this.unsavedTimeBoundFeaturesConfigs.push(this.featureForEdit);
    } else {
      this.addFeatureToActiveOrInactiveList(this.featureForEdit);
    }
    this.isEditingFeature = false;
    this.isAddingFeature = true;
    this.resetAllFeatureInputs();
  }
}
