import * as i0 from "@angular/core";
import * as i1 from "./base.service";
var UserSettingsService = /** @class */ (function () {
    function UserSettingsService(baseService) {
        this.baseService = baseService;
    }
    UserSettingsService.prototype.getAllPFTypesApi = function () {
        this.path = "employeePf";
        return this.baseService.get(this.path);
    };
    UserSettingsService.prototype.getEmpPFPreferenceByEmpIdAndFiscalApi = function (empId, fiscal) {
        this.path = "employeePf/empPFPreferenceByEmpIdAndFiscal?employeeId=" + empId + "&fiscal=" + fiscal;
        return this.baseService.get(this.path);
    };
    UserSettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserSettingsService_Factory() { return new UserSettingsService(i0.ɵɵinject(i1.BaseService)); }, token: UserSettingsService, providedIn: "root" });
    return UserSettingsService;
}());
export { UserSettingsService };
