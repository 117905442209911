import { PipeTransform } from '@angular/core';
var CapitalizeFirstPipe = /** @class */ (function () {
    function CapitalizeFirstPipe() {
    }
    CapitalizeFirstPipe.prototype.transform = function (value) {
        if (!value)
            return '';
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    };
    return CapitalizeFirstPipe;
}());
export { CapitalizeFirstPipe };
