<div class="payroll-wrapper" *ngIf="!openEarningsAndDeductions">
  <div class="row">
    <div class="column col">
      <div class="heading">
        Payroll for the month of {{ months[selectedPayslipMonth] }}
        {{ selectedPayslipYear }}
      </div>
    </div>
    <div class="col" style="position: relative">
      <button
        mat-button
        class="custom-btn"
        matTooltip="download"
        [disableRipple]="true"
        [matMenuTriggerFor]="beforeMenu"
        style="position: absolute; right: -12px; top: -6px; border: none"
      >
        <img
          src="../../assets/images/download.svg"
          alt=""
          style="width: 35px; background-color: white"
        />
      </button>
      <mat-menu #beforeMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="getSalarySheet()">
          Download Salary Sheet
        </button>
        <button mat-menu-item (click)="getBankTransferSheet()">
          Download Bank Transfer Sheet
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="row info-row">
    <div class="column col-md info-col">
      <div class="column-content d-flex flex-row text-center">
        <div class="d-flex flex-row mx-auto">
          <mat-icon class="info-icon mr-2">people</mat-icon>
          <div class="info-text">
            <p>
              Total Employees -
              <span class="info-value">{{ totalEmployees }} </span>
            </p>
          </div>
        </div>
        <mat-divider
          *ngIf="!isScreenSizeLessThanMdBreakPoint"
          class="ml-auto"
          [vertical]="true"
          style="height: 30px; margin: 0 10px 0 10px !important"
        ></mat-divider>
      </div>
    </div>
    <div class="column col-md info-col">
      <div class="column-content d-flex flex-row">
        <div class="d-flex flex-row mx-auto">
          <mat-icon class="info-icon mr-2">currency_rupee</mat-icon>
          <div class="info-text">
            <p>
              Total Salary -
              <span class="info-value">{{ totalPayableAmount }}</span>
            </p>
          </div>
        </div>
        <mat-divider
          *ngIf="!isScreenSizeLessThanMdBreakPoint"
          class="ml-auto"
          [vertical]="true"
          style="height: 30px; margin: 0 10px 0 10px !important"
        ></mat-divider>
      </div>
    </div>
    <div class="column col-md info-col">
      <div class="column-content d-flex flex-row">
        <div class="d-flex flex-row mx-auto">
          <mat-icon class="info-icon mr-2">calendar_today</mat-icon>
          <div class="info-text">
            <p>
              Next Pay Day - <span class="info-value">{{ formattedDate }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="row filters-row">
      <div class="col-md-8 column">
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn search-icon" (click)="onSearch($event)">
              <i class="fa fa-search"></i>
            </button>
            <input
              type="text"
              class="form-control"
              name="search"
              placeholder="Search by Name, Emp Id, Email"
              (keyup)="onSearch($event)"
              [(ngModel)]="searchInputValue"
            />
          </div>
        </div>
      </div>
      <div
        class="col-md-4 column"
        [ngClass]="
          isScreenSizeLessThanMdBreakPoint
            ? 'mt-3'
            : 'd-flex justify-content-end'
        "
      >
        <select
          class="select-month mr-3 text-start"
          [(ngModel)]="selectedPayslipMonth"
          (change)="onMonthChange()"
        >
          <ng-container *ngFor="let month of months | keyValueOriginalOrder">
            <ng-container *ngIf="showMonth(month.key)">
              <option [ngValue]="+month.key">
                {{ month.value | abbreviateMonth }}
              </option>
            </ng-container>
          </ng-container>
        </select>
        <select
          class="select-year mr-3 text-start"
          [(ngModel)]="selectedPayslipYear"
          name="year"
          (change)="onYearChange()"
        >
          <option *ngFor="let year of years" [ngValue]="year">
            {{ year }}
          </option>
        </select>
        <button
          class="generate-payroll-btn text-center"
          (click)="openConfirmationDialog()"
        >
          <b>Generate Payroll</b>
        </button>
      </div>
    </div>
    <mat-spinner
      class="my-auto mx-auto"
      [diameter]="60"
      *ngIf="!empListDataSource && showSpinner"
    ></mat-spinner>
    <ng-container *ngIf="empListDataSource">
      <div class="table-wrapper">
        <table mat-table [dataSource]="empListDataSource">
          <ng-container
            *ngFor="let col of displayedColumns"
            [matColumnDef]="col"
          >
            <th mat-header-cell *matHeaderCellDef>
              {{ col | camelCaseToTitle }}
            </th>
            <ng-container *ngIf="col !== 'options'">
              <td
                mat-cell
                *matCellDef="let element"
                (click)="columnExist(element[col], payslipDialog, element, col)"
                matTooltip="{{
                  getIsTooltipRequiredForCol(col)
                    ? col === 'name'
                      ? element.firstName + ' ' + element.lastName
                      : element[col]
                    : null
                }}"
              >
                {{
                  col === "employeeId"
                    ? element?.employeeId
                    : col === "name"
                    ? element?.firstName + " " + element?.lastName
                    : element[col]
                }}
              </td>
            </ng-container>
            <ng-container *ngIf="col === 'options'">
              <td mat-cell *matCellDef="let element">
                <!-- <a [matTooltip]="'Edit Employee'"
                  ><i
                    class="far fa-edit fa-2x"
                    style="padding-right: 10px; color: grey; cursor: pointer"
                  ></i
                ></a>
                <a [matTooltip]="'Delete Employee'"
                  ><i
                    class="far fa-trash-alt fa-2x"
                    style="padding-right: 10px; color: #c82727; cursor: pointer"
                  ></i
                ></a> -->
                <a
                  [matTooltip]="'Earnings and deductions'"
                  (click)="toggleEarningsAndDeductions(element)"
                >
                  <i class="fas fa-users fa-lg" style="cursor: pointer"></i>
                </a>
              </td>
            </ng-container>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </ng-container>
    <div
      class="paginator shadow"
      *ngIf="allPayrollDetails && !showSpinner && empListDataSource"
    >
      <mat-paginator
        [length]="totalEmployeeRecords"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="[10, 20, 50]"
        (page)="onPageChange($event)"
      >
      </mat-paginator>
    </div>
    <div *ngIf="!empListDataSource" class="data-not-available">
      <div>
        No payroll data available for {{ months[selectedPayslipMonth] }}
        {{ selectedPayslipYear }}. Please generate a payroll to process and
        continue.
      </div>
    </div>
  </div>
  <ng-template #payslipDialog>
    <div class="d-flex justify-content-between payslip-dialog-title">
      <div class="payslip-title-text">{{ payslipTitleText }}</div>
      <mat-icon (click)="onClose()" style="cursor: pointer">close</mat-icon>
    </div>
    <app-payslip
      buttonText="Payslip"
      [selectedEmployeeDetails]="selectedEmployeeDetails"
      [showTable]="false"
      [payrollMonth]="selectedPayslipMonth"
      [payrollYear]="selectedPayslipYear"
      [payrollDetails]="payrollDetails"
    ></app-payslip>
  </ng-template>
</div>

<ng-container *ngIf="openEarningsAndDeductions">
  <app-earnings-deductions
    [element]="currentEmployeeElement"
    (openEarningsAndDeductionsComponent)="toggleChildComponent($event)"
    [selectedPayslipYear]="selectedPayslipYear"
    [selectedPayslipMonth]="selectedPayslipMonth"
  ></app-earnings-deductions>
</ng-container>
