import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit, TemplateRef, } from "@angular/core";
import { forkJoin, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { getCurrentFiscalYear } from "../shared/app.utility";
var EmployeePoiComponent = /** @class */ (function () {
    // numberOfRejectedDeclarations: number = 0;
    // totalDeclarations: number = 0;
    function EmployeePoiComponent(investmentDeclarationService, loginService, snackBarService, spinnerService, dialog, cdr) {
        this.investmentDeclarationService = investmentDeclarationService;
        this.loginService = loginService;
        this.snackBarService = snackBarService;
        this.spinnerService = spinnerService;
        this.dialog = dialog;
        this.cdr = cdr;
        this.sectionOptionsMap = new Map();
        this.isAddingPoiForSectionMap = new Map();
        this.sectionPoiDataMap = new Map();
        this.sectionPoiStatusRejectedMap = new Map();
        this.cardsReady = false;
        this.hraDeclaredAmount = null;
        this.hraApprovedAmount = null;
        this.hraEmpInvestProofId = null;
    }
    EmployeePoiComponent.prototype.ngOnInit = function () {
        this.spinnerService.openSpinnerDialog();
        this.setPoiStatus();
        this.initializeData();
    };
    EmployeePoiComponent.prototype.initializeData = function () {
        var _this = this;
        forkJoin([
            this.investmentDeclarationService
                .getInvestmentDeclarationSectionApi()
                .pipe(catchError(function (error) {
                console.error(error);
                return of([]);
            })),
            this.investmentDeclarationService.getInvestmentLimitApi().pipe(catchError(function (error) {
                console.error(error);
                return of([]);
            })),
        ])
            .pipe(switchMap(function (_a) {
            var _b = tslib_1.__read(_a, 2), sections = _b[0], limits = _b[1];
            if (!Array.isArray(sections)) {
                return of({
                    sections: sections,
                    limits: limits,
                    sectionOptionsInfo: new Map(),
                    invProofData: {},
                    invDecData: [],
                });
            }
            var sectionRequests = sections.map(function (section) {
                return _this.investmentDeclarationService
                    .getInvestmentBySectionApi(section.optionValue)
                    .pipe(catchError(function (error) {
                    console.error(error);
                    return of([]);
                }), map(function (investments) { return ({
                    key: section.optionValue,
                    value: investments,
                }); }));
            });
            return forkJoin(sectionRequests).pipe(switchMap(function (sectionResults) {
                var sectionOptionsInfo = new Map(sectionResults.map(function (res) { return [res.key, res.value]; }));
                return _this.investmentDeclarationService
                    .getEmpInvestProofDetails(_this.loginService.employeeDetails.employeeId, getCurrentFiscalYear().toString())
                    .pipe(catchError(function () { return of({}); }), switchMap(function (invProofData) {
                    return _this.investmentDeclarationService
                        .getEmployeeInvestDeclarationByEmpId(_this.loginService.employeeDetails.employeeId, getCurrentFiscalYear().toString())
                        .pipe(catchError(function () { return of({}); }), map(function (invDecData) { return ({
                        sections: sections,
                        limits: limits,
                        sectionOptionsInfo: sectionOptionsInfo,
                        invProofData: invProofData,
                        invDecData: invDecData,
                    }); }));
                }));
            }));
        }))
            .subscribe(function (_a) {
            var sections = _a.sections, limits = _a.limits, sectionOptionsInfo = _a.sectionOptionsInfo, invProofData = _a.invProofData, invDecData = _a.invDecData;
            _this.sectionOptionsMap = sectionOptionsInfo;
            _this.initIsAddingPoiForSectionMap(sections);
            _this.initInvestmentSectionsCards(sections, limits);
            _this.setHraInvestmentViaId(_this.sectionOptionsMap);
            // this.numberOfRejectedDeclarations = 0;
            // this.totalDeclarations = 0;
            if (Object.keys(invProofData).length === 0) {
                _this.setSectionPoiDataMapFromInvDecData(sectionOptionsInfo, invDecData);
            }
            else {
                console.log("invProofData", invProofData);
                var _loop_1 = function (sectionName) {
                    var sectionData = [];
                    var rejectedEntry = false;
                    var _loop_2 = function (optionName) {
                        if (sectionName === "HRA" && invProofData["HRA"]) {
                            console.log("here", invProofData["HRA"]["Rent Paid"]);
                            var hraInfo_1 = invProofData["HRA"]["Rent Paid"][0];
                            var hraData = hraInfo_1["hraProofs"];
                            _this.hraDeclaredAmount = hraInfo_1.declaredAmount;
                            _this.hraApprovedAmount = hraInfo_1.approvedAmount;
                            _this.hraEmpInvestProofId = hraInfo_1.empInvestProofId;
                            if (hraInfo_1.status === "REJECTED") {
                                // this.numberOfRejectedDeclarations++;
                                // this.totalDeclarations++;
                                rejectedEntry = true;
                            }
                            // else if (hraInfo.status === "APPROVED") {
                            //   this.totalDeclarations++;
                            // }
                            hraData.forEach(function (el, i) {
                                var approvedAmount = el.approvedAmount
                                    ? el.approvedAmount.toString()
                                    : "0";
                                var fileName = "";
                                var proofNameIdMap = new Map();
                                el.hraInvestmentProofAttachments.forEach(function (attachment) {
                                    fileName += attachment.investmentProofFileName + ",";
                                    proofNameIdMap.set(attachment.investmentProofFileName, attachment.hraInvestmentProofAttachmentId);
                                });
                                //removing last comma
                                fileName = fileName.slice(0, -1);
                                var monthlyRent = el.monthlyRentAmount;
                                var fromMonth = el.fromMonth;
                                var toMonth = el.toMonth;
                                // months are 1 base index and plus one is to include toMonth in the duration
                                var duration = (toMonth < 4 ? toMonth + 12 : toMonth) -
                                    (fromMonth < 4 ? fromMonth + 12 : fromMonth) +
                                    1;
                                var declared = parseFloat(monthlyRent) * duration;
                                var optionData = {
                                    particulars: "House " + (i + 1),
                                    declared: declared.toString(),
                                    approved: approvedAmount,
                                    investmentViaId: hraInfo_1.investmentViaId,
                                    status: hraInfo_1.status,
                                    fromMonth: fromMonth,
                                    toMonth: toMonth,
                                    monthlyRentAmount: monthlyRent,
                                    metroCity: el.metroCity,
                                    employeeRemark: el.employeeRemarks,
                                    employerRemark: el.adminRemarks,
                                    proofIds: proofNameIdMap,
                                    proofFileName: fileName,
                                    optionId: el.hraProofId,
                                };
                                sectionData.push(optionData);
                            });
                        }
                        else {
                            var data = invProofData[sectionName][optionName][0];
                            if (data.status === "REJECTED") {
                                // this.numberOfRejectedDeclarations++;
                                // this.totalDeclarations++;
                                rejectedEntry = true;
                            }
                            // else if (data.status === "APPROVED") {
                            //   this.totalDeclarations++;
                            // }
                            var approvedAmount = data.approvedAmount
                                ? data.approvedAmount.toString()
                                : "0";
                            var fileName_1 = "";
                            var proofNameIdMap_1 = new Map();
                            data.investmentProofAttachments.forEach(function (el) {
                                fileName_1 += el.investmentProofFileName + ",";
                                proofNameIdMap_1.set(el.investmentProofFileName, el.investmentProofAttachmentId);
                            });
                            //removing last comma
                            fileName_1 = fileName_1.slice(0, -1);
                            // employeeRemark & employerRemark should be removed from here since we fetch them from a separate API now
                            var optionData = {
                                particulars: optionName,
                                declared: data.declaredAmount,
                                investmentViaId: data.investmentViaId,
                                employeeRemark: data.employeeRemarks,
                                employerRemark: data.adminRemarks,
                                proofIds: proofNameIdMap_1,
                                proofFileName: fileName_1,
                                status: data.status,
                                approved: approvedAmount,
                                optionId: data.empInvestProofId,
                            };
                            sectionData.push(optionData);
                        }
                    };
                    for (var optionName in invProofData[sectionName]) {
                        _loop_2(optionName);
                    }
                    if (rejectedEntry) {
                        _this.sectionPoiStatusRejectedMap.set(sectionName, true);
                    }
                    else {
                        _this.sectionPoiStatusRejectedMap.set(sectionName, false);
                    }
                    _this.sectionPoiDataMap.set(sectionName, sectionData);
                };
                for (var sectionName in invProofData) {
                    _loop_1(sectionName);
                }
                _this.setSectionPoiDataMapFromInvDecData(sectionOptionsInfo, invDecData);
            }
            _this.setSectionsTotalAmount();
            _this.spinnerService.closeSpinnerDialog();
        }, function (error) {
            console.error("Error loading data:", error);
            _this.spinnerService.closeSpinnerDialog();
        });
    };
    EmployeePoiComponent.prototype.setHraInvestmentViaId = function (sectionOptionsInfo) {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(sectionOptionsInfo.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), sectionName = _d[0], value = _d[1];
                if (sectionName === "HRA") {
                    this.hraInvestmentViaId = value[0].investmentViaId;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    EmployeePoiComponent.prototype.setSectionsTotalAmount = function () {
        var e_2, _a;
        var _loop_3 = function (sectionName, data) {
            var total = 0;
            data.forEach(function (el) {
                total += Number(el.declared);
            });
            this_1.investmentSectionsCards.find(function (el) { return el.name === sectionName; }).totalAmountEntered = total;
        };
        var this_1 = this;
        try {
            for (var _b = tslib_1.__values(this.sectionPoiDataMap.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), sectionName = _d[0], data = _d[1];
                _loop_3(sectionName, data);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    EmployeePoiComponent.prototype.setSectionPoiDataMapFromInvDecData = function (sectionOptionsInfo, invDecData) {
        var e_3, _a;
        var _this = this;
        var _loop_4 = function (sectionName, options) {
            if (options.length > 0) {
                var sectionData_1 = [];
                if (sectionName === "HRA") {
                    var isHraDataSaved = this_2.sectionPoiDataMap.get("HRA");
                    if (!isHraDataSaved && invDecData.length > 0) {
                        var hraSectionData = invDecData.find(function (el) {
                            if (el.hras) {
                                return el.hras.length !== 0;
                            }
                        });
                        if (hraSectionData) {
                            var hraData = hraSectionData.hras;
                            hraData.forEach(function (el, i) {
                                var monthlyRent = el.monthlyRentAmount;
                                var fromMonth = el.fromMonth;
                                var toMonth = el.toMonth;
                                // months are 1 base index and plus one is to include toMonth in the duration
                                var duration = (toMonth < 4 ? toMonth + 12 : toMonth) -
                                    (fromMonth < 4 ? fromMonth + 12 : fromMonth) +
                                    1;
                                var declared = parseFloat(monthlyRent) * duration;
                                var optionData = {
                                    particulars: "House " + (i + 1),
                                    declared: declared.toString(),
                                    approved: "0",
                                    investmentViaId: options[0].investmentViaId,
                                    status: "OPEN",
                                    fromMonth: fromMonth,
                                    toMonth: toMonth,
                                    monthlyRentAmount: monthlyRent,
                                    metroCity: el.metroCity,
                                };
                                sectionData_1.push(optionData);
                            });
                        }
                    }
                }
                else {
                    options.forEach(function (option) {
                        if (!_this.savedDataExist(sectionName, option.investmentLabelName)) {
                            var declaredOption = invDecData.find(function (incDecElement) {
                                return incDecElement.investmentViaId === option.investmentViaId;
                            });
                            var optionData = {
                                particulars: option.investmentLabelName,
                                declared: declaredOption
                                    ? declaredOption.amount.toString()
                                    : "0",
                                status: "OPEN",
                                approved: "0",
                                investmentViaId: option.investmentViaId,
                            };
                            sectionData_1.push(optionData);
                        }
                    });
                }
                var savedData = this_2.sectionPoiDataMap.get(sectionName);
                if (!!savedData) {
                    sectionData_1 = tslib_1.__spread(savedData, sectionData_1);
                }
                this_2.sectionPoiDataMap.set(sectionName, sectionData_1);
            }
        };
        var this_2 = this;
        try {
            for (var _b = tslib_1.__values(sectionOptionsInfo.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), sectionName = _d[0], options = _d[1];
                _loop_4(sectionName, options);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    EmployeePoiComponent.prototype.savedDataExist = function (sectionName, optionName) {
        var sectionData = this.sectionPoiDataMap.get(sectionName);
        return (!!sectionData && !!sectionData.find(function (el) { return el.particulars === optionName; }));
    };
    EmployeePoiComponent.prototype.initIsAddingPoiForSectionMap = function (sections) {
        if (sections.length > 0) {
            this.isAddingPoiForSectionMap = new Map(sections.map(function (section) {
                return [section.optionValue, false];
            }));
        }
    };
    EmployeePoiComponent.prototype.initInvestmentSectionsCards = function (sections, limits) {
        if (sections.length > 0) {
            this.investmentSectionsCards = sections.map(function (el) {
                var foundLimit = limits.find(function (lim) { return lim.section === el.optionValue; });
                return {
                    name: el.optionValue,
                    tooltip: el.optionDesc,
                    totalAmountEntered: 0,
                    limit: foundLimit ? foundLimit.investmentLimit : 0,
                };
            });
            this.cardsReady = true;
        }
    };
    EmployeePoiComponent.prototype.getNumberOfRowsArray = function (noOfInputs) {
        var itemsPerRow = 3;
        var arrLength = noOfInputs % itemsPerRow === 0
            ? noOfInputs / itemsPerRow
            : Math.ceil(noOfInputs / itemsPerRow);
        return Array.from({ length: arrLength }, function (_, index) { return index; });
    };
    EmployeePoiComponent.prototype.getRowWiseSections = function (rowNumber) {
        var sections = this.investmentSectionsCards.slice(rowNumber * 3, (rowNumber + 1) * 3);
        return sections;
    };
    EmployeePoiComponent.prototype.toggleIsAddingPoiForSection = function (sectionName) {
        this.isAddingPoiForSectionMap.set(sectionName, true);
    };
    EmployeePoiComponent.prototype.isAddingPoi = function () {
        var e_4, _a;
        try {
            for (var _b = tslib_1.__values(this.isAddingPoiForSectionMap.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), key = _d[0], value = _d[1];
                if (value) {
                    return true;
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return false;
    };
    EmployeePoiComponent.prototype.getAddPoiSectionName = function () {
        var e_5, _a;
        try {
            for (var _b = tslib_1.__values(this.isAddingPoiForSectionMap.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), key = _d[0], value = _d[1];
                if (value) {
                    return key;
                }
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_5) throw e_5.error; }
        }
    };
    EmployeePoiComponent.prototype.getSectionOptionsData = function () {
        var e_6, _a;
        try {
            for (var _b = tslib_1.__values(this.isAddingPoiForSectionMap.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), key = _d[0], value = _d[1];
                if (value) {
                    return this.sectionPoiDataMap.get(key);
                }
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_6) throw e_6.error; }
        }
    };
    EmployeePoiComponent.prototype.updateHraOptionsData = function (newData) {
        this.sectionPoiDataMap.set("HRA", newData);
        this.cdr.detectChanges();
    };
    EmployeePoiComponent.prototype.openNextSection = function (currentSectionName) {
        this.isAddingPoiForSectionMap.set(currentSectionName, false);
        this.initializeData();
        // let isNextSection = false;
        // for (const [
        //   sectionName,
        //   value,
        // ] of this.isAddingPoiForSectionMap.entries()) {
        //   if (isNextSection) {
        //     this.isAddingPoiForSectionMap.set(sectionName, true);
        //     break;
        //   }
        //   if (sectionName === currentSectionName) {
        //     isNextSection = true;
        //   }
        // }
    };
    EmployeePoiComponent.prototype.closeAddPoiComponent = function (currentSectionName) {
        this.isAddingPoiForSectionMap.set(currentSectionName, false);
    };
    EmployeePoiComponent.prototype.submitPoi = function () {
        var _this = this;
        this.investmentDeclarationService
            .submitPoiApi(this.loginService.employeeDetails.employeeId, getCurrentFiscalYear().toString())
            .subscribe(function (res) {
            _this.setPoiStatus();
            _this.snackBarService.add({
                message: res.message,
                config: { duration: 2000 },
            });
        }, function (err) {
            console.error(err);
            _this.snackBarService.add({
                message: err.error.message,
                config: { duration: 2000 },
            });
        });
    };
    EmployeePoiComponent.prototype.setPoiStatus = function () {
        var _this = this;
        this.investmentDeclarationService
            .getEmployeePoiStatus(this.loginService.employeeDetails.employeeId)
            .subscribe(function (res) {
            if (res[0] && res[0].investmentProofStatus) {
                _this.empPoiStatus = res[0].investmentProofStatus;
            }
        });
    };
    EmployeePoiComponent.prototype.isSubmitDisabled = function () {
        var disableForStatus = [
            "CONSIDERED",
            "APPROVED",
            "SUBMITTED",
        ];
        return !this.empPoiStatus || disableForStatus.includes(this.empPoiStatus);
    };
    EmployeePoiComponent.prototype.isOverallStatusApproved = function () {
        var disableForStatus = ["CONSIDERED", "APPROVED"];
        return this.empPoiStatus && disableForStatus.includes(this.empPoiStatus);
    };
    EmployeePoiComponent.prototype.isOverallStatusRejected = function () {
        var disableForStatus = ["REJECTED"];
        return this.empPoiStatus && disableForStatus.includes(this.empPoiStatus);
    };
    EmployeePoiComponent.prototype.isOverallStatusOpen = function () {
        var disableForStatus = ["OPEN", "SAVED", "UPDATED"];
        return this.empPoiStatus && disableForStatus.includes(this.empPoiStatus);
    };
    EmployeePoiComponent.prototype.isOverallStatusSubmitted = function () {
        var disableForStatus = ["SUBMITTED"];
        return this.empPoiStatus && disableForStatus.includes(this.empPoiStatus);
    };
    EmployeePoiComponent.prototype.downloadApprovedPoi = function () {
        var _this = this;
        var employeeId = this.loginService.employeeDetails.employeeId;
        var fiscalYear = getCurrentFiscalYear().toString();
        this.investmentDeclarationService
            .downloadApprovedPoi(employeeId, fiscalYear)
            .subscribe(function (response) {
            try {
                var responseJson = JSON.parse(response);
                var fileUrl = responseJson.message;
                if (fileUrl) {
                    var link = document.createElement("a");
                    link.href = fileUrl;
                    link.setAttribute("download", "Approved_POI.pdf");
                    link.setAttribute("target", "_blank");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                else {
                    console.error("No valid file URL found in the response.");
                }
            }
            catch (error) {
                console.error("Error parsing API response:", error);
            }
        }, function (error) {
            console.error("Error fetching attachment URL:", error);
            _this.snackBarService.add({
                message: "Failed to download Form.",
                config: { duration: 2000 },
            });
        });
    };
    EmployeePoiComponent.prototype.getInvestmentStatusMessage = function () {
        if (this.isOverallStatusApproved()) {
            return "Your Proof of Investments (POIs) has been successfully approved.";
        }
        if (this.isOverallStatusRejected()) {
            return "Your Proof of Investments (POIs) have been rejected. Please review and resubmit the Proof of Investments accordingly.";
            //`${this.numberOfRejectedDeclarations} out of the ${this.totalDeclarations} Proof of Investments (POIs) have been rejected. Please review and resubmit the Proof of Investments accordingly.`;
        }
        if (this.isOverallStatusOpen()) {
            return "Your Proof of Investments (POIs) is currently open. Please review and submit your details before the deadline.";
        }
        if (this.isOverallStatusSubmitted()) {
            return "Your Proof of Investments (POIs) has been successfully submitted. No further action is required at this time.";
        }
        return "Proof of Investments (POIs) window is not opened yet";
    };
    EmployeePoiComponent.prototype.openConfirmationDialog = function (templateRef) {
        this.dialog.open(templateRef, {
            panelClass: "confirmation-dialog-class",
        });
    };
    return EmployeePoiComponent;
}());
export { EmployeePoiComponent };
