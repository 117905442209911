import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToWords'
})
export class NumberToWordsPipe implements PipeTransform {

  private readonly ones = [
    '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
  ];

  private readonly tens = [
    '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
  ];

  private readonly scales = [
    '', 'thousand', 'lakh', 'crore'
  ];

  transform(value: number): string {
    if (value === 0) return 'zero';
    if (value < 0) return 'minus ' + this.transform(-value);

    let words: string[] = [];
    let scale = 0;

    while (value > 0) {
      let chunk;
      if (scale === 1) {

        chunk = value % 100;
        value = Math.floor(value / 100);
      } else {

        chunk = value % 1000;
        value = Math.floor(value / 1000);
      }

      if (chunk) {
        const chunkWords = this.convertChunk(chunk);
        if (this.scales[scale]) {
          words.unshift(chunkWords + ' ' + this.scales[scale]);
        } else {
          words.unshift(chunkWords);
        }
      }

      scale++;
    }

    return words.join(' ').trim();
  }

  private convertChunk(value: number): string {
    const hundreds = Math.floor(value / 100);
    const remainder = value % 100;
    const tens = Math.floor(remainder / 10);
    const units = remainder % 10;

    let chunkWords: string[] = [];

    if (hundreds) {
      chunkWords.push(this.ones[hundreds] + ' hundred');
    }

    if (remainder < 20) {
      chunkWords.push(this.ones[remainder]);
    } else {
      chunkWords.push(this.tens[tens]);
      if (units) {
        chunkWords.push(this.ones[units]);
      }
    }

    return chunkWords.join(' ').trim();
  }
}
