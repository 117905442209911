import * as tslib_1 from "tslib";
import { formatDate } from "@angular/common";
import { AfterViewInit, ChangeDetectorRef, ElementRef, OnInit, TemplateRef, } from "@angular/core";
import { FormControl, Validators, } from "@angular/forms";
import * as moment from "moment";
import { getCurrentFiscalYear } from "../shared/app.utility";
var PF_PREFERENCE_VALUES = {
    VARIABLE: "variable",
    FIXED: "fixed",
    NO_PF: "NA",
};
var PF_STRING = "pf";
var MONTHLY_STRING = "monthly";
var TOTAL_STRING = "total";
var FISCAL = getCurrentFiscalYear(4);
var PF_CONFIGURABLE_STRING = "PF_CONFIGURABLE";
var PF_PREFERENCE_CONFIGURABLE_STRING = "PF_PREFERENCE_CONFIGURABLE";
var AddEmployeeDetailsComponent = /** @class */ (function () {
    function AddEmployeeDetailsComponent(dialogRef, _formBuilder, employeeService, _snackBar, adminService, userSettingsService, cdr, snackBarService, dialog, resetDialogRef) {
        this.dialogRef = dialogRef;
        this._formBuilder = _formBuilder;
        this.employeeService = employeeService;
        this._snackBar = _snackBar;
        this.adminService = adminService;
        this.userSettingsService = userSettingsService;
        this.cdr = cdr;
        this.snackBarService = snackBarService;
        this.dialog = dialog;
        this.resetDialogRef = resetDialogRef;
        this.personalFormDetails = [];
        this.bankFormDetails = [];
        this.ctcFormDetails = [];
        this.CTCComponentsDetails = [];
        this.totalCTCComponentsDetails = [];
        this.calculatedPayroll = [];
        this.durationInSeconds = 5;
        this.maxDate = new Date();
        this.years = [];
        this.displayErrors = true;
        this.firstName = "";
        this.lastName = "";
        this.disableCheckbox = true;
        var currYear = new Date().getFullYear();
        for (var i = currYear; i >= currYear - 10; i--) {
            this.years.push(i);
        }
        var currentDate = new Date();
        this.date = formatDate(currentDate, "dd/MM/yyyy", "en-US");
        this.getCTCCompoenets();
    }
    AddEmployeeDetailsComponent.prototype.ngAfterViewInit = function () {
        this.matStepperIcons = this.matStepperIconViewChildren.toArray();
        this.cdr.detectChanges();
    };
    AddEmployeeDetailsComponent.prototype.ngOnInit = function () {
        this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
        this.personalDetailsFormGroup = this._formBuilder.group({
            fullName: ["", Validators.required],
            emailAddress: [
                "",
                Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
            ],
            dateOfBirth: ["", Validators.required],
            dateOfJoining: ["", Validators.required],
            pan: ["", Validators.required],
            aadhaar: ["", Validators.pattern("^[0-9]{12}$")],
            permenantAddress: ["", Validators.required],
            correspondenceAddress: [""],
            uanNo: ["", Validators.required],
            pfNo: ["", Validators.required],
            employeeCode: ["", Validators.required],
            isChecked: [false],
        });
        this.bankDetailsFormGroup = this._formBuilder.group({
            bankAcctNo: ["", Validators.required],
            bankAcctName: ["", Validators.required],
            bankAcctAddress: ["", Validators.required],
            bankIFSC: ["", Validators.required],
        });
        this.ctcDetailsFormGroup = this._formBuilder.group({});
        this.allFormgroups = [
            this.personalDetailsFormGroup,
            this.bankDetailsFormGroup,
            this.ctcDetailsFormGroup,
        ];
        this.setPFPreferenceTypes();
    };
    AddEmployeeDetailsComponent.prototype.setPFPreferenceTypes = function () {
        var _this = this;
        this.userSettingsService
            .getAllPFTypesApi()
            .subscribe(function (pfPreferenceType) {
            _this.pfPreferenceTypes = pfPreferenceType;
        });
    };
    AddEmployeeDetailsComponent.prototype.addFormCOntrolsTOCtcDetailsForm = function (ctcComponentsDetails) {
        var e_1, _a;
        try {
            for (var ctcComponentsDetails_1 = tslib_1.__values(ctcComponentsDetails), ctcComponentsDetails_1_1 = ctcComponentsDetails_1.next(); !ctcComponentsDetails_1_1.done; ctcComponentsDetails_1_1 = ctcComponentsDetails_1.next()) {
                var el = ctcComponentsDetails_1_1.value;
                if (el.type != MONTHLY_STRING) {
                    var control = new FormControl("", el.componentName === PF_STRING ? [Validators.required] : []);
                    this.ctcDetailsFormGroup.addControl(el.componentName.toLowerCase(), control);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (ctcComponentsDetails_1_1 && !ctcComponentsDetails_1_1.done && (_a = ctcComponentsDetails_1.return)) _a.call(ctcComponentsDetails_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    AddEmployeeDetailsComponent.prototype.setDefaultPFPreferenceValueAndPFConfigurable = function () {
        var _this = this;
        this.adminService
            .getConfigDetailsByFiscalApi(FISCAL)
            .subscribe(function (details) {
            var e_2, _a, e_3, _b;
            try {
                for (var details_1 = tslib_1.__values(details), details_1_1 = details_1.next(); !details_1_1.done; details_1_1 = details_1.next()) {
                    var detail = details_1_1.value;
                    if (detail.configuredSubComponent === PF_CONFIGURABLE_STRING) {
                        if (detail.status) {
                            _this.pfConfigurable = true;
                            try {
                                for (var details_2 = (e_3 = void 0, tslib_1.__values(details)), details_2_1 = details_2.next(); !details_2_1.done; details_2_1 = details_2.next()) {
                                    var data = details_2_1.value;
                                    if (data.configuredSubComponent ===
                                        PF_PREFERENCE_CONFIGURABLE_STRING) {
                                        if (!data.status) {
                                            _this.pfPreferenceConfigurable = false;
                                            _this.ctcDetailsFormGroup
                                                .get(PF_STRING)
                                                .patchValue(PF_PREFERENCE_VALUES.VARIABLE);
                                            _this.ctcDetailsFormGroup.get(PF_STRING).disable();
                                        }
                                        else {
                                            _this.pfPreferenceConfigurable = true;
                                        }
                                        break;
                                    }
                                }
                            }
                            catch (e_3_1) { e_3 = { error: e_3_1 }; }
                            finally {
                                try {
                                    if (details_2_1 && !details_2_1.done && (_b = details_2.return)) _b.call(details_2);
                                }
                                finally { if (e_3) throw e_3.error; }
                            }
                        }
                        else {
                            _this.pfConfigurable = false;
                            _this.ctcDetailsFormGroup
                                .get(PF_STRING)
                                .patchValue(PF_PREFERENCE_VALUES.NO_PF);
                            _this.ctcDetailsFormGroup.get(PF_STRING).disable();
                        }
                        break;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (details_1_1 && !details_1_1.done && (_a = details_1.return)) _a.call(details_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        });
    };
    AddEmployeeDetailsComponent.prototype.getCTCCompoenets = function () {
        var _this = this;
        this.employeeService.getAllCTCComponentsApi().subscribe(function (res) {
            var e_4, _a;
            var totalTypeComponentsDetails = [];
            var pfTypeComponentsDetails = [];
            var othersTypeComponentsDetails = [];
            try {
                for (var res_1 = tslib_1.__values(res), res_1_1 = res_1.next(); !res_1_1.done; res_1_1 = res_1.next()) {
                    var element = res_1_1.value;
                    if (element && element.type && element.type === TOTAL_STRING) {
                        totalTypeComponentsDetails.push(element);
                    }
                    else if (element &&
                        element.componentName &&
                        element.componentName === PF_STRING) {
                        pfTypeComponentsDetails.push(element);
                    }
                    else if (element &&
                        element.type &&
                        element.type !== TOTAL_STRING &&
                        element.componentName &&
                        element.componentName !== PF_STRING) {
                        othersTypeComponentsDetails.push(element);
                    }
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (res_1_1 && !res_1_1.done && (_a = res_1.return)) _a.call(res_1);
                }
                finally { if (e_4) throw e_4.error; }
            }
            _this.CTCComponentsDetails = tslib_1.__spread(totalTypeComponentsDetails, pfTypeComponentsDetails, othersTypeComponentsDetails);
            _this.addFormCOntrolsTOCtcDetailsForm(res);
            _this.setDefaultPFPreferenceValueAndPFConfigurable();
        }, function (error) {
            console.error("get all CTC Components details failed", error);
        });
    };
    AddEmployeeDetailsComponent.prototype.postEmployeePFPreference = function (empId) {
        var _this = this;
        var requestBody = {
            employeeId: empId,
            pfType: this.ctcDetailsFormGroup.get(PF_STRING).value,
            fiscal: FISCAL.toString(),
        };
        this.employeeService.postEmployeePFPreferenceApi(requestBody).subscribe(function (res) {
            _this.snackBarService.add({
                message: "Employee pf preference added Successfully",
                action: "close",
                config: { duration: 2000 },
            });
            _this.postEmployeeCTCDetails(res.employeeId);
        }, function (err) {
            console.error("pf preference addition failed", err);
            _this.snackBarService.add({
                message: err.error.message,
                action: "close",
                config: { duration: 2000 },
            });
        });
    };
    AddEmployeeDetailsComponent.prototype.getFirstAndLastName = function () {
        var fullName = this.personalDetailsFormGroup.get("fullName").value;
        fullName = fullName.trim().replace(/\s+/g, " ");
        var words = fullName.split(" ");
        this.firstName = words[0];
        if (words.length === 1) {
            this.lastName = ".";
        }
        else {
            this.lastName = words.slice(1).join(" ");
        }
    };
    AddEmployeeDetailsComponent.prototype.postEmployeeDetails = function () {
        var _this = this;
        this.getFirstAndLastName();
        if (this.personalDetailsFormGroup.valid) {
            var element = {
                cityType: "M",
                firstName: this.firstName,
                lastName: this.lastName,
                employeeEmail: this.personalDetailsFormGroup.get("emailAddress").value,
                employeeId: this.personalDetailsFormGroup.get("emailAddress").value,
                dateOfBirth: moment(this.personalDetailsFormGroup.get("dateOfBirth").value).format("DD/MM/yyyy"),
                dateOfJoining: moment(this.personalDetailsFormGroup.get("dateOfJoining").value).format("DD/MM/yyyy"),
                bankAcctNo: this.bankDetailsFormGroup.get("bankAcctNo").value,
                bankAcctAddress: this.bankDetailsFormGroup.get("bankAcctAddress").value,
                bankAcctName: this.bankDetailsFormGroup.get("bankAcctName").value,
                bankIFSC: this.bankDetailsFormGroup.get("bankIFSC").value,
                aadhaar: this.personalDetailsFormGroup.get("aadhaar").value,
                permenantAddress: this.personalDetailsFormGroup.get("permenantAddress").value,
                correspondenceAddress: this.personalDetailsFormGroup.get("correspondenceAddress").value,
                uanNo: this.personalDetailsFormGroup.get("uanNo").value,
                pfNo: this.personalDetailsFormGroup.get("pfNo").value,
                pan: this.personalDetailsFormGroup.get("pan").value,
                employeeCode: this.personalDetailsFormGroup.get("employeeCode").value,
            };
            this.employeeService.postEmployeeDetailsApi(element).subscribe(function (res) {
                if (_this.pfConfigurable && _this.pfPreferenceConfigurable) {
                    _this.postEmployeePFPreference(res.employeeId);
                }
                else {
                    _this.postEmployeeCTCDetails(res.employeeId);
                }
                _this.snackBarService.add({
                    message: "Employee Personal Details Submitted Successfully.",
                    action: "close",
                    config: { duration: 2000 },
                });
            }, function (error) {
                console.error("addEmployeeService failed", error);
                if (error.status === 500) {
                    _this.snackBarService.add({
                        message: "Some issue occurred Please contact the backend team to resolve this.",
                        action: "close",
                        config: { duration: 2000 },
                    });
                }
                else {
                    _this.snackBarService.add({
                        message: error.error.message,
                        action: "close",
                        config: { duration: 2000 },
                    });
                }
            });
        }
    };
    AddEmployeeDetailsComponent.prototype.postEmployeeCTCDetails = function (empId) {
        var e_5, _a;
        var _this = this;
        if (this.ctcDetailsFormGroup.valid) {
            var requestBody = [];
            try {
                for (var _b = tslib_1.__values(this.CTCComponentsDetails), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var ctcComponentDetail = _c.value;
                    if (ctcComponentDetail.componentName.toLowerCase() !== PF_STRING &&
                        ctcComponentDetail.type.toLowerCase() !== MONTHLY_STRING &&
                        this.ctcDetailsFormGroup.get(ctcComponentDetail.componentName.toLowerCase()).value !== "" &&
                        this.ctcDetailsFormGroup.get(ctcComponentDetail.componentName.toLowerCase()).value !== "0") {
                        var data = {
                            amount: Number(this.ctcDetailsFormGroup.get(ctcComponentDetail.componentName.toLowerCase()).value),
                            ctcComponent: ctcComponentDetail.componentName,
                            employeeId: empId,
                            fiscal: FISCAL.toString(),
                            notes: "Some random text to see the change in CTC",
                            status: "ACTIVE",
                        };
                        requestBody.push(data);
                    }
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_5) throw e_5.error; }
            }
            this.employeeService.postEmployeeCTCDetailsApi(requestBody).subscribe(function (res) {
                _this.onClose();
                _this.snackBarService.add({
                    message: "Employee CTC Details Submitted Successfully",
                    action: "close",
                    config: { duration: 2000 },
                });
                _this.employeeService.newUserAdded = true;
            }, function (error) {
                if (error.status === 400) {
                    _this.snackBarService.add({
                        message: error.error.message,
                        action: "close",
                        config: { duration: 2000 },
                    });
                }
                else {
                    _this.snackBarService.add({
                        message: "Failed to submit ctc details.",
                        action: "close",
                        config: { duration: 2000 },
                    });
                    console.error("postEmployeeCTCDetails failed", error);
                }
            });
        }
    };
    AddEmployeeDetailsComponent.prototype.getFormDetails = function () {
        var _this = this;
        this.personalFormDetails = Object.keys(this.personalDetailsFormGroup.value).map(function (key) { return ({
            type: key,
            value: _this.personalDetailsFormGroup.value[key],
        }); });
        this.bankFormDetails = Object.keys(this.bankDetailsFormGroup.value).map(function (key) { return ({ type: key, value: _this.bankDetailsFormGroup.value[key] }); });
        this.ctcFormDetails = Object.keys(this.ctcDetailsFormGroup.value).map(function (key) { return ({
            type: key,
            value: _this.ctcDetailsFormGroup.value[key.toLowerCase()],
        }); });
    };
    AddEmployeeDetailsComponent.prototype.checkDateType = function (value) {
        if (value instanceof Date) {
            return true;
        }
        return false;
    };
    AddEmployeeDetailsComponent.prototype.onClose = function () {
        this.dialogRef.close();
    };
    AddEmployeeDetailsComponent.prototype.calculateCTC = function () {
        var e_6, _a;
        var _this = this;
        var data = [];
        try {
            for (var _b = tslib_1.__values(this.CTCComponentsDetails), _c = _b.next(); !_c.done; _c = _b.next()) {
                var element = _c.value;
                if (element.type !== TOTAL_STRING &&
                    element.type !== MONTHLY_STRING &&
                    element.componentName !== PF_STRING.toUpperCase()) {
                    data.push({
                        ctcComponent: element.componentName,
                        amount: this.ctcDetailsFormGroup.value[element.componentName.toLowerCase()],
                    });
                }
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_6) throw e_6.error; }
        }
        this.employeeService.calculateCTCApi(data).subscribe(function (res) {
            var e_7, _a, _b;
            _this.calculatedPayroll = res;
            try {
                for (var res_2 = tslib_1.__values(res), res_2_1 = res_2.next(); !res_2_1.done; res_2_1 = res_2.next()) {
                    var element = res_2_1.value;
                    _this.ctcDetailsFormGroup.patchValue((_b = {},
                        _b[element.ctcComponent.toLowerCase()] = element.amount,
                        _b));
                }
            }
            catch (e_7_1) { e_7 = { error: e_7_1 }; }
            finally {
                try {
                    if (res_2_1 && !res_2_1.done && (_a = res_2.return)) _a.call(res_2);
                }
                finally { if (e_7) throw e_7.error; }
            }
            _this.snackBarService.add({
                message: "Value of ctc and gross pay are updated",
                action: "close",
                config: { duration: 2000 },
            });
        }, function (error) {
            console.error("calculateCTC failed", error);
            _this.snackBarService.add({
                message: error.error.message,
                action: "close",
                config: { duration: 2000 },
            });
        });
    };
    AddEmployeeDetailsComponent.prototype.openSnackbar = function (message, action) {
        this._snackBar.open(message, action, {
            duration: this.durationInSeconds * 1000,
            panelClass: ["custom-snackbar-class"],
        });
    };
    AddEmployeeDetailsComponent.prototype.personalDetailsErrorMessage = function (formControlName) {
        if (Object.keys(this.personalDetailsFormGroup.controls[formControlName].errors)[0] === "required") {
            return "This field is required!";
        }
        else if (formControlName === "dateOfBirth") {
            switch (Object.keys(this.personalDetailsFormGroup.controls[formControlName].errors)[0]) {
                case "matDatepickerMax":
                    return "Employee should be at least 18 years old!";
            }
        }
        else if (formControlName === "emailAddress" ||
            formControlName === "employeeCode") {
            switch (Object.keys(this.personalDetailsFormGroup.controls[formControlName].errors)[0]) {
                case "pattern":
                    return "Enter a valid email address!";
            }
        }
        else if (formControlName === "aadhaar") {
            switch (Object.keys(this.personalDetailsFormGroup.controls[formControlName].errors)[0]) {
                case "pattern":
                    return "Aaadhaar number should be of 12 digits";
            }
        }
    };
    AddEmployeeDetailsComponent.prototype.bankDetailsErrorMessage = function (formControlName) {
        if (Object.keys(this.bankDetailsFormGroup.controls[formControlName].errors)[0] === "required") {
            return "This field is required!";
        }
    };
    AddEmployeeDetailsComponent.prototype.ctcDetailsErrorMessage = function (formControlName) {
        var control = this.ctcDetailsFormGroup.get(formControlName.toLowerCase());
        if (control && control.hasError("required")) {
            return "This field is required!";
        }
        return "";
    };
    AddEmployeeDetailsComponent.prototype.openReset = function (templateRef) {
        this.resetDialogRef = this.dialog.open(templateRef, {
            width: "280px",
            height: "140px",
        });
        this.resetDialogRef.afterClosed().subscribe(function (result) { });
    };
    AddEmployeeDetailsComponent.prototype.onResetNoClick = function () {
        this.resetDialogRef.close();
    };
    AddEmployeeDetailsComponent.prototype.onRestYesClick = function () {
        this.stepper.reset();
        this.resetButton1.nativeElement.click();
        this.resetButton2.nativeElement.click();
        this.resetButton3.nativeElement.click();
        this.resetDialogRef.close();
    };
    AddEmployeeDetailsComponent.prototype.resetForm = function () {
        this.clearErrorsAndMarkUntouched(this.personalDetailsFormGroup);
        this.clearErrorsAndMarkUntouched(this.bankDetailsFormGroup);
        this.clearErrorsAndMarkUntouched(this.ctcDetailsFormGroup);
    };
    AddEmployeeDetailsComponent.prototype.clearErrorsAndMarkUntouched = function (formGroup) {
        Object.keys(formGroup.controls).forEach(function (key) {
            formGroup.get(key).clearValidators();
        });
    };
    AddEmployeeDetailsComponent.prototype.onNextClick = function (formName) {
        formName.markAllAsTouched();
    };
    AddEmployeeDetailsComponent.prototype.getIconPath = function (index) {
        switch (index) {
            case 0:
                return "../../assets/images/personal-details-active-icon.svg";
            case 1:
                return "../../assets/images/bank-details-active-icon.svg";
            case 2:
                return "../../assets/images/ctc-details-active-icon.svg";
            case 3:
                return "../../assets/images/review-details-active-icon.svg";
            case 4:
                return "";
        }
    };
    AddEmployeeDetailsComponent.prototype.changeCheckboxValue = function ($event) {
        if ($event.checked) {
            this.personalDetailsFormGroup.patchValue({
                correspondenceAddress: this.personalDetailsFormGroup.get("permenantAddress").value,
            });
        }
        else {
            this.personalDetailsFormGroup.patchValue({
                correspondenceAddress: "",
            });
        }
    };
    AddEmployeeDetailsComponent.prototype.permanentAddressChanged = function ($event) {
        if ($event.target.value) {
            this.disableCheckbox = false;
        }
        else {
            if (!this.disableCheckbox)
                this.personalDetailsFormGroup.patchValue({
                    correspondenceAddress: this.personalDetailsFormGroup.get("permenantAddress").value,
                });
            this.disableCheckbox = true;
        }
    };
    AddEmployeeDetailsComponent.prototype.isValidDate = function (value) {
        if (value.toString().includes("India Standard Time"))
            return true;
        return false;
    };
    AddEmployeeDetailsComponent.prototype.nextTab = function () {
        if (this.tabGroup.selectedIndex < 2)
            this.tabGroup.selectedIndex += 1;
    };
    AddEmployeeDetailsComponent.prototype.previousTab = function () {
        if (this.tabGroup.selectedIndex > 0)
            this.tabGroup.selectedIndex -= 1;
    };
    AddEmployeeDetailsComponent.prototype.onStepChange = function () {
        this.getFormDetails();
    };
    return AddEmployeeDetailsComponent;
}());
export { AddEmployeeDetailsComponent };
