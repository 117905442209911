import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { addHouse } from "../../investment-declaration.util";
import { MatDialog } from "@angular/material";
import { PayrollDialogComponent } from "src/app/payroll-dialog/payroll-dialog.component";
import { PayrollDialogComponentInput } from "src/app/payroll-dialog/payroll-dialog.utility";

@Component({
  selector: "add-investment-hra-form",
  templateUrl: "./add-investment-hra-form.component.html",
  styleUrls: ["./add-investment-hra-form.component.scss"],
})
export class AddInvestmentHRAFormComponent implements OnInit {
  @Input() houses: any[];
  @Input() hraFormGroup: FormGroup;
  @Input() fiscalMonths: string[];
  @Input() isFormSubmitted: boolean;
  @Output() keyUpEmitter = new EventEmitter<any>();
  @Output() updateSectionTotalAmountEmitter = new EventEmitter<void>();
  @Output() onRemoveHouseClickEmitter = new EventEmitter<number>();
  deletedHousesCount = 0;
  houseMonthlyRentLimitMap = new Map<number, boolean>();
  houseInputsValidationMap = new Map<number, Map<string, boolean>>();
  toMonthOptions: string[];

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.toMonthOptions = this.fiscalMonths;
    if (!this.isFormSubmitted) {
      this.initSetup();
    } else {
      this.disableInputs();
    }
  }

  initSetup() {
    this.initializeHouseMonthlyRentLimitMap();
    this.initializeHouseInputsValidationMap();
    this.disableHouseInputsBasedOnValidations();
  }

  disableInputs() {
    this.houses.forEach((el, i) => {
      const fromMonthControl = (
        this.hraFormGroup.get(`House ${i + 1}`) as FormGroup
      ).get("fromMonth");
      const toMonthControl = (
        this.hraFormGroup.get(`House ${i + 1}`) as FormGroup
      ).get("toMonth");
      const monthlyRentControl = (
        this.hraFormGroup.get(`House ${i + 1}`) as FormGroup
      ).get("monthlyRent");
      fromMonthControl.disable();
      toMonthControl.disable();
      monthlyRentControl.disable;
    });
  }

  disableHouseInputsBasedOnValidations() {
    this.houses.forEach((el, i) => {
      const fromMonthControl = (
        this.hraFormGroup.get(`House ${i + 1}`) as FormGroup
      ).get("fromMonth");
      const toMonthControl = (
        this.hraFormGroup.get(`House ${i + 1}`) as FormGroup
      ).get("toMonth");
      const monthlyRentControl = (
        this.hraFormGroup.get(`House ${i + 1}`) as FormGroup
      ).get("monthlyRent");
      if (fromMonthControl.value === null) {
        toMonthControl.disable();
        monthlyRentControl.disable();
      } else if (toMonthControl.value === null) {
        monthlyRentControl.disable();
      }
    });
  }

  updateSectionTotalAmount(houseNumber: number) {
    const monthlyRentControl = this.hraFormGroup
      .get(`House ${houseNumber + 1}`)
      .get("monthlyRent");
    if (monthlyRentControl.value !== null) {
      this.updateSectionTotalAmountEmitter.emit();
    }
  }

  onFromMonthValueChange(event, houseNumber: number): void {
    this.updateSectionTotalAmount(houseNumber);
    this.toMonthOptions = this.fiscalMonths.slice(
      this.fiscalMonths.indexOf(event.value)
    );
    const toMonthControl = this.hraFormGroup
      .get(`House ${houseNumber + 1}`)
      .get("toMonth");
    if (toMonthControl.disabled) {
      toMonthControl.enable();
      this.houseInputsValidationMap.get(houseNumber + 1).set("toMonth", true);
    } else {
      if (toMonthControl.value !== null) {
        if (
          this.fiscalMonths.indexOf(event.value) >
          this.fiscalMonths.indexOf(toMonthControl.value)
        ) {
          toMonthControl.setValue(null);
        }
      }
    }
  }

  onToMonthValueChange(houseNumber: number): void {
    this.updateSectionTotalAmount(houseNumber);
    const monthlyRentControl = this.hraFormGroup
      .get(`House ${houseNumber + 1}`)
      .get("monthlyRent");
    if (monthlyRentControl.disabled) {
      monthlyRentControl.enable();
      this.houseInputsValidationMap
        .get(houseNumber + 1)
        .set("monthlyRent", true);
    }
  }

  initializeHouseInputsValidationMap() {
    this.houses.forEach((el, i) => {
      const fromMonthControl = this.hraFormGroup
        .get(`House ${i + 1}`)
        .get("fromMonth");
      const toMonthControl = this.hraFormGroup
        .get(`House ${i + 1}`)
        .get("toMonth");
      this.houseInputsValidationMap.set(
        i + 1,
        new Map<string, boolean>([
          ["toMonth", fromMonthControl.value !== null],
          ["monthlyRent", toMonthControl.value !== null],
        ])
      );
    });
  }

  initializeHouseMonthlyRentLimitMap() {
    this.houses.forEach((el, i) => {
      this.houseMonthlyRentLimitMap.set(i + 1, false);
    });
  }

  getTabLabel(index: number): string {
    return "House " + (index + 1);
  }

  onAddHouseClick() {
    addHouse(this.hraFormGroup, this.houses, this.formBuilder);
    const newHouse = this.hraFormGroup.get(
      `House ${this.houses.length}`
    ) as FormGroup;
    newHouse.get("toMonth").disable();
    newHouse.get("monthlyRent").disable();
    this.houseInputsValidationMap.set(
      this.houses.length,
      new Map([
        ["toMonth", false],
        ["monthlyRent", false],
      ])
    );
  }

  isConvertibleToNumber(value: string): boolean {
    if (value.trim() === "") {
      return false;
    }
    return !isNaN(Number(value));
  }

  onKeyUp(event, houseNumber) {
    if (event.key === "Backspace" || this.isConvertibleToNumber(event.key)) {
      this.updateIsInputDigitLimitReached(houseNumber + 1);
      this.keyUpEmitter.emit(event);
    }
  }

  /**
   * @param houseNumber should be 1 based index
   */
  updateIsInputDigitLimitReached(houseNumber): void {
    if (
      (this.hraFormGroup.get(`House ${houseNumber}`) as FormGroup).get(
        "monthlyRent"
      ).value.length === 8
    ) {
      this.houseMonthlyRentLimitMap.set(houseNumber, true);
    } else {
      this.houseMonthlyRentLimitMap.set(houseNumber, false);
    }
  }

  onRemoveHouseClick(index: number): void {
    this.dialog.open(PayrollDialogComponent, {
      ["data"]: {
        title: "Confirmation",
        msg: `Are you sure you want to delete info for House ${index + 1}`,
        dialogType: "delete",
        onYesClickFunction: () => this.removeHouse(index),
        noButtonText: "No",
        yesButtonText: "Yes",
      } as PayrollDialogComponentInput,
    });
  }

  removeHouse(index: number) {
    const keyToRemove = `House ${index + 1}`;
    this.hraFormGroup.removeControl(keyToRemove);

    // Renumber the remaining controls
    const controls = this.hraFormGroup.controls;
    const newControls: { [key: string]: FormGroup } = {};
    let houseCount = 1;

    for (let key in controls) {
      if (controls.hasOwnProperty(key)) {
        newControls[`House ${houseCount}`] = controls[key] as FormGroup;
        houseCount++;
      }
    }

    Object.keys(controls).forEach((key) =>
      this.hraFormGroup.removeControl(key)
    );
    Object.keys(newControls).forEach((key) =>
      this.hraFormGroup.addControl(key, newControls[key])
    );
    // this event should be emitted  after the hraFormGroup has been updated
    this.onRemoveHouseClickEmitter.emit(index);

    // Renumbering houseInputsValidationMap
    houseCount = 1;
    const keysToDelete: number[] = [];
    const entriesToAdd: [number, any][] = [];
    this.houseInputsValidationMap.delete(index + 1);

    for (let [key, value] of this.houseInputsValidationMap.entries()) {
      if (key !== houseCount) {
        entriesToAdd.push([houseCount, value]);
        keysToDelete.push(key);
      }
      houseCount++;
    }

    for (const key of keysToDelete) {
      this.houseInputsValidationMap.delete(key);
    }

    for (const [newKey, newValue] of entriesToAdd) {
      this.houseInputsValidationMap.set(newKey, newValue);
    }

    // Renumbering houseMonthlyRentLimitMap
    keysToDelete.length = 0;
    entriesToAdd.length = 0;
    houseCount = 1;
    this.houseMonthlyRentLimitMap.delete(index + 1);
    for (let [key, value] of this.houseMonthlyRentLimitMap.entries()) {
      if (key !== houseCount) {
        entriesToAdd.push([houseCount, value]);
        keysToDelete.push(key);
      }
      houseCount++;
    }

    for (const key of keysToDelete) {
      this.houseMonthlyRentLimitMap.delete(key);
    }

    for (const [newKey, newValue] of entriesToAdd) {
      this.houseMonthlyRentLimitMap.set(newKey, newValue);
    }
  }
}
