import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatTableDataSource } from "@angular/material";

type TaxRegimeInfo = {
  id: RegimeId;
  label: string;
  taxableIncome?: number;
  netTax?: number;
};
type RegimeId = "newRegime" | "oldRegime" | "OLD_REGIME" | "NEW_REGIME";
type TaxComparisonTableData = {
  particulars: string;
  oldTaxRegime?: string;
  newTaxRegime?: string;
};

type IncomeTaxResponse = {
  taxRegimeTypes: RegimeId;
  taxAmount: number;
  taxableIncome: number;
  investmentAcknowledged: {
    [investmentSection: string]: {
      [sectionComponent: string]: number;
    };
  };
};

const REGIME_NAMES = ["New", "Old"];
const TAX_COMPARISON_TABLE_COLUMNS: { id: string; label: string }[] = [
  { id: "particulars", label: "Particulars" },
  { id: "oldTaxRegime", label: "Old Tax Regime" },
  { id: "newTaxRegime", label: "New Tax Regime" },
];
@Component({
  selector: "app-investment-declaration-preview",
  templateUrl: "./investment-declaration-preview.component.html",
  styleUrls: ["./investment-declaration-preview.component.scss"],
})
export class InvestmentDeclarationPreviewComponent implements OnInit {
  taxRegimes: TaxRegimeInfo[] = [];
  selectedRegime: RegimeId;
  recommendedRegime: RegimeId;
  taxComparisonData: MatTableDataSource<TaxComparisonTableData>;
  displayedColumns = TAX_COMPARISON_TABLE_COLUMNS;
  @Input() incomeTaxResponse: IncomeTaxResponse[];
  @Output() onRegimeSelect = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
    this.setTaxRegimes();
    this.setTableData(this.incomeTaxResponse);
  }

  setTaxRegimes() {
    REGIME_NAMES.forEach((name) => {
      const label = `${name} Tax Regime`;
      const id = `${name.toLocaleLowerCase()}Regime` as RegimeId;
      this.taxRegimes.push({
        label: label,
        id: id,
      });
    });
  }

  setSelectedRegime(id: RegimeId): void {
    this.selectedRegime = id;
    this.emitSelectedRegime();
  }

  setRecommendedRegime(): void {
    this.recommendedRegime =
      this.taxRegimes[0].netTax < this.taxRegimes[1].netTax
        ? this.taxRegimes[0].id
        : this.taxRegimes[1].id;
    this.setSelectedRegime(this.recommendedRegime);
    this.emitSelectedRegime();
  }

  isRecommendedRegime(id: RegimeId): boolean {
    return id === this.recommendedRegime;
  }

  isSelectedRegime(id: RegimeId): boolean {
    return id === this.selectedRegime;
  }

  getDisplayedColumns(): string[] {
    return this.displayedColumns.map((col) => col.id);
  }

  setTableData(data: IncomeTaxResponse[]) {
    const tableData: TaxComparisonTableData[] = [];
    const oldRegimeData = data.find(
      (regime) => regime.taxRegimeTypes === "OLD_REGIME"
    );

    const oldRegime = this.taxRegimes.find((el) => el.id === "oldRegime");
    oldRegime.netTax = oldRegimeData.taxAmount;
    oldRegime.taxableIncome = oldRegimeData.taxableIncome;
    const oldSectionNames = Object.keys(oldRegimeData.investmentAcknowledged);
    oldSectionNames.forEach((sectionName) => {
      const tableDataElement: TaxComparisonTableData = {
        particulars: sectionName,
      };
      tableData.push(tableDataElement);
      const sectionComponentNames = Object.keys(
        oldRegimeData.investmentAcknowledged[sectionName]
      );
      sectionComponentNames.forEach((componentName) => {
        const sectionComponentValue =
          oldRegimeData.investmentAcknowledged[sectionName][componentName];
        const tableDataElement: TaxComparisonTableData = {
          particulars: componentName,
          oldTaxRegime: sectionComponentValue.toString(),
          newTaxRegime: "0",
        };
        tableData.push(tableDataElement);
      });
    });

    const newRegimeData = data.find(
      (regime) => regime.taxRegimeTypes === "NEW_REGIME"
    );

    const newRegime = this.taxRegimes.find((el) => el.id === "newRegime");
    newRegime.netTax = newRegimeData.taxAmount;
    newRegime.taxableIncome = newRegimeData.taxableIncome;
    const sectionNames = Object.keys(newRegimeData.investmentAcknowledged);
    sectionNames.forEach((sectionName) => {
      const sectionComponentNames = Object.keys(
        newRegimeData.investmentAcknowledged[sectionName]
      );
      sectionComponentNames.forEach((componentName) => {
        const sectionComponent = tableData.find(
          (el) => el.particulars === componentName
        );
        const sectionComponentValue =
          newRegimeData.investmentAcknowledged[sectionName][componentName];
        sectionComponent.newTaxRegime = sectionComponentValue.toString();
      });
    });
    tableData.push({
      particulars: "Net Tax",
      newTaxRegime: newRegime.netTax.toString(),
      oldTaxRegime: oldRegime.netTax.toString(),
    });
    this.taxComparisonData = new MatTableDataSource<TaxComparisonTableData>(
      tableData
    );
    this.setRecommendedRegime();
  }

  emitSelectedRegime() {
    if (this.selectedRegime === "newRegime") {
      this.onRegimeSelect.emit("NEW_REGIME");
    } else {
      this.onRegimeSelect.emit("OLD_REGIME");
    }
  }

  onRegimeCardClick(id: RegimeId) {
    this.setSelectedRegime(id);
    this.emitSelectedRegime();
  }
}
