import { Component } from "@angular/core";

@Component({
  selector: "app-user-not-found",
  templateUrl: "./user-not-found.component.html",
  styleUrls: ["./user-not-found.component.css"],
})
export class UserNotFoundComponent {
  constructor() {}
}
