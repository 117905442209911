import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import * as moment from "moment";
import { EmployeeDetails } from "src/app/core/interfaces/user";
import { PayrollDialogComponent } from "src/app/payroll-dialog/payroll-dialog.component";
import { PayrollDialogComponentInput } from "src/app/payroll-dialog/payroll-dialog.utility";
import { EmployeeService } from "src/app/shared/services/employee.service";
import { SnackBarService } from "src/app/shared/services/snackbar.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
type Council = { label: string; id: number };

@Component({
  selector: "app-edit-employee-personal-details",
  templateUrl: "./edit-employee-personal-details.component.html",
  styleUrls: ["./edit-employee-personal-details.component.scss"],
})
export class EditEmployeePersonalDetailsComponent implements OnInit {
  @Input() selectedEmployeeDetails: EmployeeDetails;
  @Output() close = new EventEmitter<void>();
  @Output() closeEditScreen = new EventEmitter<void>();
  editEmployeePersonalDetails: FormGroup;
  showSpinner: boolean = true;
  ecList: Council[] = [];
  dcList: Council[] = [];
  idEngineeringCouncilDict: any = {};
  idDeliveryCouncilDict: any = {};
  basicFields = [
    {
      label: "Full Name",
      controlName: "fullName",
      directive: "stringsOnly",
      required: true,
    },

    {
      label: "Employee Code",
      controlName: "employeeCode",
      directive: null,
      required: true,
    },
    {
      label: "Email Address",
      controlName: "employeeId",
      directive: null,
      required: true,
    },
    {
      label: "Aadhaar Card Number",
      controlName: "aadhaar",
      directive: "numbersOnly",
      required: true,
    },
    {
      label: "Permanent Account Number",
      controlName: "pan",
      directive: null,
      required: true,
    },
    {
      label: "UAN Number",
      controlName: "uanNo",
      directive: "numbersOnly",
      required: true,
    },
    {
      label: "PF Number",
      controlName: "pfNo",
      directive: null,
      required: true,
    },
    {
      label: "Permanent Address",
      controlName: "permanantAddress",
      required: true,
    },
    {
      label: "Correspondence Address",
      controlName: "correspondenceAddress",
      required: false,
    },
    {
      label: "Bank Account No",
      controlName: "bankAcctNo",
      directive: "numbersOnly",
      required: true,
    },
    {
      label: "Bank Name",
      controlName: "bankAcctName",
      directive: "stringsOnly",
      required: true,
    },
    {
      label: "Bank Account Address",
      controlName: "bankAcctAddress",
      directive: null,
      required: true,
    },
    {
      label: "Bank IFSC",
      controlName: "bankIFSC",
      directive: null,
      required: true,
    },
  ];
  firstName: string;
  lastName: string;

  constructor(
    private snackBarService: SnackBarService,
    private fb: FormBuilder,
    private employeeService: EmployeeService,
    public dialog: MatDialog,
    private spinnerService:SpinnerService
  ) {}

  ngOnInit() {
    this.getEngineeringCouncilList();
    this.getDeliveryCouncilList();
    this.editEmployeePersonalDetails = this.fb.group({
      employeeId: [this.selectedEmployeeDetails.employeeId],
      employeeCode: [this.selectedEmployeeDetails.employeeCode],
      fullName: [
        `${
          this.selectedEmployeeDetails.firstName +
          " " +
          this.selectedEmployeeDetails.lastName
        }`,
        [Validators.required], // Only alphabets
      ],

      pan: [
        this.selectedEmployeeDetails.pan,
        [Validators.required], // Only numbers
      ],
      aadhaar: [
        this.selectedEmployeeDetails.aadhaar,
        [Validators.required, Validators.pattern("^[0-9]{12}$")], // Only numbers
      ],
      dateOfBirth: [
        this.parseDate(this.selectedEmployeeDetails.dateOfBirth),
        [Validators.required],
      ],
      dateOfJoining: [
        this.parseDate(this.selectedEmployeeDetails.dateOfJoining),
        [Validators.required],
      ], // Date picker
      dateOfExit: [
        this.selectedEmployeeDetails.dateOfExit
          ? this.parseDate(this.selectedEmployeeDetails.dateOfExit)
          : null,
      ], // Date picker
      permanantAddress: [
        this.selectedEmployeeDetails.permanentAddress,
        [Validators.required],
      ],
      correspondenceAddress: [
        this.selectedEmployeeDetails.correspondenceAddress,
      ],
      uanNo: [
        this.selectedEmployeeDetails.uanNo,
        [Validators.required], // Only numbers
      ],
      pfNo: [this.selectedEmployeeDetails.pfNo, [Validators.required]],
      bankAcctNo: [
        this.selectedEmployeeDetails.bankAcctNo,
        [Validators.required],
      ],
      bankAcctName: [
        this.selectedEmployeeDetails.bankAcctName,
        [Validators.required],
      ],
      bankAcctAddress: [
        this.selectedEmployeeDetails.bankAcctAddress,
        [Validators.required],
      ],
      bankIFSC: [this.selectedEmployeeDetails.bankIFSC, [Validators.required]],
      cityType: [this.selectedEmployeeDetails.cityType, [Validators.required]],
      deliveryCouncil: [
        this.selectedEmployeeDetails.deliveryCouncil.deliveryCouncil,
        [Validators.required],
      ],
      engineeringCouncil: [
        this.selectedEmployeeDetails.engineeringCouncil.engineeringCouncil,
        [Validators.required],
      ],
    });

    this.changeAppContentWrapperStyle();
  }

  getEngineeringCouncilList(): void {
    this.employeeService.getAllEngineeringCouncil().subscribe(
      (res) => {
        this.ecList = [];
        if (res) {
          for (let ec of res) {
            this.idEngineeringCouncilDict[ec.engineeringCouncil] = ec;
            const council: Council = {
              label: ec.engineeringCouncilName,
              id: ec.engineeringCouncil,
            };
            this.ecList.push(council);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getDeliveryCouncilList(): void {
    this.employeeService.getAllDeliveryCouncil().subscribe(
      (res) => {
        this.dcList = [];
        if (res) {
          for (let dc of res) {
            this.idDeliveryCouncilDict[dc.deliveryCouncil] = dc;

            const council: Council = {
              label: dc.deliveryCouncilName,
              id: dc.deliveryCouncil,
            };
            this.dcList.push(council);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  changeAppContentWrapperStyle(): void {
    const appContentWrapperDiv = document.querySelector(
      "#appContentWrapper"
    ) as HTMLElement;
    appContentWrapperDiv.style.paddingTop = "0";
    appContentWrapperDiv.style.height = "100%"
    appContentWrapperDiv.style.backgroundColor = "white";
  }

  resetAppContentWrapperStyle(): void {
    const appContentWrapperDiv = document.querySelector(
      "#appContentWrapper"
    ) as HTMLElement;
    appContentWrapperDiv.style.paddingTop = "24px";
    appContentWrapperDiv.style.height = "auto"
    appContentWrapperDiv.style.backgroundColor = "";
  }
  onClose(): void {
    this.close.emit();
  }
  openConfirmationDialog(): void {
    this.dialog.open(PayrollDialogComponent, {
      data: {
        title: "Confirmation",
        msg: `Do you want to Edit CTC Details ?`,
        dialogType: "post",
        onYesClickFunction: (param?: any) => this.updateEmployeeDetails(),
      } as PayrollDialogComponentInput,
    });
  }
  parseDate(dateString: string | null): Date | null {
    if (!dateString) return null; // Handle null or empty date strings
    const [day, month, year] = dateString.split("/").map(Number); // Split the string by '/'
    return new Date(year, month - 1, day); // Create the date object (months are zero-indexed)
  }
  getFirstAndLastName() {
    let fullName = this.editEmployeePersonalDetails.get("fullName").value;
    fullName = fullName.trim().replace(/\s+/g, " ");
    const words = fullName.split(" ");
    this.firstName = words[0];
    if (words.length === 1) {
      this.lastName = ".";
    } else {
      this.lastName = words.slice(1).join(" ");
    }
  }
  updateEmployeeDetails() {
    this.getFirstAndLastName();
    this.spinnerService.openSpinnerDialog();
    let element = {
      cityType: "M",
      firstName: this.firstName,
      lastName: this.lastName,
      employeeEmail: this.editEmployeePersonalDetails.get("employeeId").value,
      employeeCode: this.editEmployeePersonalDetails.get("employeeCode").value,
      employeeId: this.editEmployeePersonalDetails.get("employeeId").value,
      dateOfBirth: moment(
        this.editEmployeePersonalDetails.get("dateOfBirth").value
      ).format("DD/MM/yyyy"),
      dateOfJoining: moment(
        this.editEmployeePersonalDetails.get("dateOfJoining").value
      ).format("DD/MM/yyyy"),
      dateOfExit: this.editEmployeePersonalDetails.get("dateOfExit").value
        ? moment(
            this.editEmployeePersonalDetails.get("dateOfExit").value
          ).format("DD/MM/yyyy")
        : null,
      bankAcctNo: this.editEmployeePersonalDetails.get("bankAcctNo").value,
      bankAcctAddress:
        this.editEmployeePersonalDetails.get("bankAcctAddress").value,
      bankAcctName: this.editEmployeePersonalDetails.get("bankAcctName").value,
      bankIFSC: this.editEmployeePersonalDetails.get("bankIFSC").value,
      aadhaar: this.editEmployeePersonalDetails.get("aadhaar").value,
      permanantAddress:
        this.editEmployeePersonalDetails.get("permanantAddress").value,
      correspondenceAddress: this.editEmployeePersonalDetails.get(
        "correspondenceAddress"
      ).value,
      uanNo: this.editEmployeePersonalDetails.get("uanNo").value,
      pfNo: this.editEmployeePersonalDetails.get("pfNo").value,
      pan: this.editEmployeePersonalDetails.get("pan").value,
      deliveryCouncil:
        this.idDeliveryCouncilDict[
          this.editEmployeePersonalDetails.get("deliveryCouncil").value
        ],
      engineeringCouncil:
        this.idEngineeringCouncilDict[
          this.editEmployeePersonalDetails.get("engineeringCouncil").value
        ],
    };
    this.employeeService.updateEmployeeDetails(element).subscribe(
      (res) => {
        this.spinnerService.closeSpinnerDialog();
        this.snackBarService.add({
          message: "Employee Personal Details Updated Successfully.",
          action: "close",
          config: { duration: 2000 },
        });
        this.onClose();
      },
      (error) => {
        this.spinnerService.closeSpinnerDialog();
        if (error.status === 500) {
          this.snackBarService.add({
            message:
              "Some issue occurred Please contact the backend team to resolve this.",
            action: "close",
            config: { duration: 2000 },
          });
        } else {
          this.snackBarService.add({
            message: error.error.message,
            action: "close",
            config: { duration: 2000 },
          });
        }
      }
    );
    // }
  }
  isFieldInvalid(controlName: string): boolean {
    const control = this.editEmployeePersonalDetails.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  onCloseEditScreen(){
    this.closeEditScreen.emit();
  }
  ngOnDestroy(): void {
    this.resetAppContentWrapperStyle();
  }
}
