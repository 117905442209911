import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InvestmentDeclarationService } from "../../shared/services/investment-declaration.service";
import { getCurrentFiscalYear } from "src/app/shared/app.utility";
import { MatDialog } from "@angular/material";
import { SnackBarService } from "src/app/shared/services/snackbar.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { concatMap, finalize, take } from "rxjs/operators";
import { from, of } from "rxjs";
import autoTable, { RowInput } from "jspdf-autotable";
import jsPDF from "jspdf";
import { EmployeeService } from "src/app/shared/services/employee.service";
@Component({
  selector: "app-proof-of-income-detail",
  templateUrl: "./proof-of-income-detail.component.html",
  styleUrls: ["./proof-of-income-detail.component.scss"],
})
export class ProofOfIncomeDetailComponent implements OnInit {
  employeeId: string = "";
  employeeName: string = "";
  empPoitatus: string = "";
  investmentDetails: any[] = [];
  fiscal: any = getCurrentFiscalYear();
  modalTitle: string = "";
  modalContent: { remark: string; remarkDate: string }[] = [];
  isModalOpen: boolean = false;
  selectedRemarksTitle: string = "";
  selectedRemarksText: string = "";
  selectedDetails: any = null;
  approvedAmountError: boolean = false;
  employeeDesignation: string;
  employeeAddress: string;
  employeePAN: string;
  empRemarksMap = new Map<string, { remark: string; remarkDate: string }[]>();
  adminRemarksMap = new Map<string, { remark: string; remarkDate: string }[]>();
  numberOfApproved: number = 0;
  numberOfRejected: number = 0;
  totalDeclarations: number = 0;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private investmentService: InvestmentDeclarationService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private spinnerService: SpinnerService,
    private employeeService: EmployeeService
  ) {}
  @ViewChild("remarksDialog", { static: false })
  remarksDialog!: TemplateRef<any>;
  private baseUrl =
    "https://devapi.geminisolutions.com/gempayroll/dev/empInvestProof";

  ngOnInit() {
    this.spinnerService.openSpinnerDialog();
    this.investmentService.getFormType().subscribe(
      (res) => {
        console.log("Form Types:", res);
      },

      (err) => {
        console.error("Error fetching form types:", err);
      }
    );

    this.route.paramMap.subscribe((params) => {
      this.employeeId = decodeURIComponent(params.get("employeeId") || "");
    });

    this.route.queryParams.subscribe((params) => {
      this.employeeName = decodeURIComponent(params["name"] || "Unknown");
    });

    this.fetchEmployeeDetails();
    this.route.queryParams.subscribe((params) => {
      this.empPoitatus = decodeURIComponent(params["status"] || "Unknown");
      console.log(this.empPoitatus);
    });

    this.fetchInvestmentDetails();
  }

  fetchEmployeeDetails() {
    this.employeeService
      .getEmployeeDetailsByEmailApi(this.employeeId)
      .subscribe(
        (data) => {
          if (data) {
            this.employeeDesignation = data.designation || "Not Available";
            this.employeeAddress = data.permanentAddress || "Not Available";
            this.employeePAN = data.pan || "Not Available";
          }
        },
        (error) => {
          console.error("Error fetching employee details:", error);
        }
      );
  }

  fetchInvestmentDetails() {
    this.investmentService
      .getEmpInvestProofDetails(this.employeeId, this.fiscal)
      .pipe(
        finalize(() => {
          this.spinnerService.closeSpinnerDialog();
        })
      )
      .subscribe(
        (data) => {
          this.investmentDetails = [];
          this.numberOfApproved = 0;
          this.numberOfRejected = 0;
          this.totalDeclarations = 0;

          Object.keys(data).forEach((section) => {
            let investments = [];

            Object.keys(data[section]).forEach((investmentType) => {
              data[section][investmentType].forEach((investment: any) => {
                const processedInvestment = { ...investment };

                if (section === "HRA" && processedInvestment.hraProofs) {
                  processedInvestment.investmentProofAttachments = [];

                  processedInvestment.hraProofs.forEach((hraProof: any) => {
                    if (
                      hraProof.hraInvestmentProofAttachments &&
                      hraProof.hraInvestmentProofAttachments.length > 0
                    ) {
                      hraProof.hraInvestmentProofAttachments.forEach(
                        (attachment: any) => {
                          processedInvestment.investmentProofAttachments.push({
                            hraInvestmentProofAttachmentId:
                              attachment.hraInvestmentProofAttachmentId,
                            investmentProofFileName:
                              attachment.investmentProofFileName,
                            investmentProofFilePath:
                              attachment.investmentProofFilePath,
                            ...attachment,
                          });
                        }
                      );
                    }
                  });

                  processedInvestment.isHRA = true;
                } else if (!processedInvestment.investmentProofAttachments) {
                  processedInvestment.investmentProofAttachments = [];
                }

                if (processedInvestment.status) {
                  if (processedInvestment.status === "REJECTED") {
                    this.numberOfRejected++;
                  } else if (processedInvestment.status === "APPROVED") {
                    this.numberOfApproved++;
                  }
                }

                this.totalDeclarations++;

                investments.push({
                  investmentType: investmentType,
                  ...processedInvestment,
                  sectionName: section,
                });
              });
            });

            this.investmentDetails.push({
              sectionName: section,
              investments: investments,
              isHRA: section === "HRA",
            });
          });

          console.log("Processed investment details:", this.investmentDetails);
        },
        (error) => {
          console.error("Error fetching investment details:", error);
          this.snackBarService.add({
            message: "Failed to load investment details. Please try again.",
            action: "Close",
            config: { duration: 4000, panelClass: ["error-snackbar-class"] },
          });
        }
      );
  }

  considerRejectDeclarationBtnToolTip(): string | null {
    return this.empPoitatus === "CONSIDERED"
      ? "POI Declaration is already considered"
      : !(
          this.totalDeclarations ===
          this.numberOfRejected + this.numberOfApproved
        )
      ? "Please approve or reject all the POI declarations"
      : null;
  }

  isConsiderRejectDeclarationBtnDisabled(): boolean {
    return (
      !(
        this.totalDeclarations ===
        this.numberOfRejected + this.numberOfApproved
      ) || this.empPoitatus === "CONSIDERED"
    );
  }

  goBack() {
    this.router.navigate(["/dashboard/proofOfIncome"]);
  }
  onReject() {
    this.selectedDetails.approvedAmount = "0";
    this.updateInvestmentStatus("REJECTED");
  }
  onApprove() {
    if (
      !this.selectedDetails ||
      !this.selectedDetails.approvedAmount ||
      this.selectedDetails.approvedAmount === "0" ||
      this.selectedDetails.approvedAmount === 0
    ) {
      this.approvedAmountError = true;
      return;
    }
    this.approvedAmountError = false;
    this.updateInvestmentStatus("APPROVED");
  }
  openRemarksModal(type: string, element: any) {
    this.modalTitle = type;
    this.approvedAmountError = false;
    if (type === "Details") {
      this.selectedDetails = {
        empInvestProofId: element.empInvestProofId,
        declaredAmount:
          element.declaredAmount !== null && element.declaredAmount !== 0
            ? element.declaredAmount
            : "0",
        approvedAmount:
          element.approvedAmount !== null && element.approvedAmount !== 0
            ? element.approvedAmount
            : "0",
        attachments: element.investmentProofAttachments || [],
        adminRemarks: element.adminRemarks || "",
        employeeId: element.employeeId || "",
      };
      this.openDialog();
    } else if (type === "Employee Remarks") {
      const remarks = this.empRemarksMap.get(element.investmentType);
      if (!remarks) {
        this.investmentService
          .getEmpRemarksByEmpInvestProofIdApi(
            element.employeeId,
            element.empInvestProofId
          )
          .pipe(
            take(1),
            finalize(() => {
              this.openDialog();
            })
          )
          .subscribe((res) => {
            this.empRemarksMap.set(element.investmentType, res);
            this.modalContent = res;
          });
      } else {
        this.modalContent = remarks;
        this.openDialog();
      }
    } else if (type === "Admin Remarks") {
      const remarks = this.adminRemarksMap.get(element.investmentType);
      if (!remarks) {
        this.investmentService
          .getAdminRemarksByEmpInvestProofIdApi(
            element.employeeId,
            element.empInvestProofId
          )
          .pipe(
            take(1),
            finalize(() => {
              this.openDialog();
            })
          )
          .subscribe((res) => {
            this.adminRemarksMap.set(element.investmentType, res);
            this.modalContent = res;
          });
      } else {
        this.modalContent = remarks;
        this.openDialog();
      }
    }
  }

  openDialog() {
    const dialog = this.dialog.open(this.remarksDialog, {
      width: "500px",
      panelClass: "custom-modal",
    });

    dialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.selectedDetails = null;
        this.modalContent = [];
      });
  }

  updateInvestmentStatus(status: string) {
    if (!this.selectedDetails || !this.selectedDetails.empInvestProofId) {
      console.error("Investment Proof ID is missing.");
      return;
    }

    const empInvestProofId = this.selectedDetails.empInvestProofId;
    const adminRemarks = this.selectedDetails.adminRemarks || "";
    const approvedAmount = this.selectedDetails.approvedAmount || "0";

    this.investmentService
      .updateInvestmentStatus(
        empInvestProofId,
        status,
        adminRemarks,
        approvedAmount
      )
      .subscribe(
        (response) => {
          this.investmentDetails.forEach((section) => {
            section.investments.forEach((investment) => {
              if (investment.empInvestProofId === empInvestProofId) {
                if (status === "APPROVED") {
                  if (investment.status === "REJECTED") {
                    this.numberOfRejected--;
                  }
                  this.numberOfApproved++;
                } else if (status === "REJECTED") {
                  if (investment.status === "APPROVED") {
                    this.numberOfApproved--;
                  }
                  this.numberOfRejected++;
                }
                investment.status = status;
                investment.adminRemarks = adminRemarks;
                investment.approvedAmount = approvedAmount;
              }
            });
          });

          const successMessage =
            response && response.message
              ? response.message
              : "Status changed successfully!";
          this.snackBarService.add({
            message: successMessage,
            action: "Close",
            config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
          });

          this.dialog.closeAll();
        },
        (error) => {
          const errorMessage =
            error && error.error && error.error.message
              ? error.error.message
              : "An error occurred while updating the status.";
          this.snackBarService.add({
            message: errorMessage,
            action: "Close",
            config: { duration: 4000, panelClass: ["error-snackbar-class"] },
          });
        }
      );
  }

  closeRemarksModal() {
    this.isModalOpen = false;
    this.approvedAmountError = false;
  }

  onRejectStatus() {
    this.postUpdateInvestmentStatus("REJECTED");
  }

  // onConsideredStatus() {
  //   this.postUpdateInvestmentStatus("CONSIDERED");
  // }
  onConsideredStatus() {
    this.postUpdateInvestmentStatus("CONSIDERED");
    this.generateAndSendForm();
  }
  generateAndSendForm() {
    const doc = new jsPDF();

    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("FORM NO.12BB", doc.internal.pageSize.width / 2, 15, {
      align: "center",
    });

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("(See rule 26C)", doc.internal.pageSize.width / 2, 21, {
      align: "center",
    });

    let finalY = 30;
    autoTable(doc, {
      body: [
        [
          "1. Name, Designation and Address of Employee",
          `${this.employeeName}, ${this.employeeDesignation}, ${this.employeeAddress}`,
        ],
        ["2. Permanent Account Number (PAN) of Employee", this.employeePAN],
        ["3. Financial Year", this.fiscal],
      ],
      startY: finalY,
      theme: "grid",
      styles: { fontSize: 10, cellPadding: 3 },
      columnStyles: { 0: { fontStyle: "bold" } },
      tableLineColor: [0, 0, 0],
    });

    finalY = (doc as any).lastAutoTable.finalY || finalY;

    const claimsRows: RowInput[] = [
      [
        {
          content: "Details of Claims and Evidence Thereof",
          colSpan: 4,
          styles: { fontStyle: "bold", halign: "center" },
        },
      ],
      ["Sl No.", "Nature of Claim", "Amount (Rs.)", "Evidence / Particulars"],
      ["(1)", "(2)", "(3)", "(4)"],
      ["1", "House Rent Allowance:", "", ""],
    ];

    const hraSection = this.investmentDetails.find(
      (section) => section.sectionName === "HRA"
    );
    if (
      hraSection &&
      hraSection.investments &&
      hraSection.investments.length > 0
    ) {
      const hraInvestment = hraSection.investments[0];
      if (hraInvestment.hraProofs && hraInvestment.hraProofs.length > 0) {
        const hraProof = hraInvestment.hraProofs[0];
        const monthlyRentAmount = hraProof.monthlyRentAmount || 0;
        const fromMonth = hraProof.fromMonth || 0;
        const toMonth = hraProof.toMonth || 0;

        const duration =
          (toMonth < 4 ? toMonth + 12 : toMonth) -
          (fromMonth < 4 ? fromMonth + 12 : fromMonth) +
          1;
        const totalRent = parseFloat(monthlyRentAmount) * duration;

        let attachmentName = "";
        if (
          hraProof.hraInvestmentProofAttachments &&
          hraProof.hraInvestmentProofAttachments.length > 0
        ) {
          attachmentName =
            hraProof.hraInvestmentProofAttachments[0].investmentProofFileName ||
            "";
        }

        claimsRows.push([
          "",
          "Total Rent Amount",
          totalRent.toString(),
          attachmentName,
        ]);
      }
    }
    claimsRows.push(
      [
        "",
        "Note: Permanent Account Number shall be furnished if the aggregate rent paid during the previous year exceeds one Lakh rupees",
        "",
        "",
      ],
      ["2", "Leave travel concessions or assistance", "", ""],
      ["3", "Deduction of interest on borrowing:", "", ""],
      ["", "(i) Interest payable/paid to the lender", "", ""],
      ["", "(ii) Name of the lender", "", ""],
      ["", "(iii) Address of the lender", "", ""],
      ["", "(iv) Permanent Account Number of the lender", "", ""],
      ["", "(a) Financial Institutions(if available)", "", ""],
      ["", "(b) Employer(if available)", "", ""],
      ["", "(c) Others", "", ""],
      ["4", "Deductions under Chapter VI-A", "", ""]
    );

    if (this.investmentDetails && Array.isArray(this.investmentDetails)) {
      this.investmentDetails.forEach((section: any) => {
        if (section.sectionName !== "HRA") {
          claimsRows.push(["", `(A) Section ${section.sectionName}`, "", ""]);

          section.investments.forEach((investment: any, index: number) => {
            let attachmentNames: string[] = [];

            if (
              investment.investmentProofAttachments &&
              Array.isArray(investment.investmentProofAttachments)
            ) {
              attachmentNames = investment.investmentProofAttachments.map(
                (attachment: any) => attachment.investmentProofFileName
              );
            }

            claimsRows.push([
              "",
              ` ( ${String.fromCharCode(97 + index)} ) ${
                investment.investmentType
              }`,
              investment.declaredAmount
                ? investment.declaredAmount.toString()
                : "0",
              attachmentNames.length > 0
                ? attachmentNames.join(", ")
                : "No Attachments",
            ]);
          });
        }
      });
    }

    claimsRows.push([
      "",
      "(B) Other sections (e.g. 80E, 80G, 80TTA, etc.) under Chapter VI-A.",
      "",
      "",
    ]);

    autoTable(doc, {
      body: claimsRows,
      startY: finalY,
      theme: "grid",
      styles: { fontSize: 10, cellPadding: 3 },
      columnStyles: {
        0: { fontStyle: "bold", cellWidth: 10 },
        1: { cellWidth: 65 },
        2: { cellWidth: 22 },
        3: { cellWidth: 85, overflow: "linebreak" },
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontStyle: "bold",
      },
      tableLineColor: [0, 0, 0],
    });

    finalY = (doc as any).lastAutoTable.finalY || finalY;

    const verificationRow: RowInput[] = [
      [
        {
          content: "Verification",
          colSpan: 2,
          styles: { fontStyle: "bold", halign: "center" },
        },
      ],
      [
        {
          content:
            "I, .................., son/daughter of .............., certify that the information given above is complete and correct.",
          colSpan: 2,
        },
      ],
      [
        {
          content:
            "Place: ....................\n\nDate: ....................\n\nDesignation: ....................",
          colSpan: 1,
        },
        {
          content: "(Signature of the employee)\nFull Name",
          colSpan: 1,
          styles: { halign: "center", cellPadding: { top: 10, bottom: 3 } },
        },
      ],
    ];

    autoTable(doc, {
      body: verificationRow,
      startY: finalY,
      theme: "grid",
      styles: { fontSize: 10, cellPadding: 3 },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      tableLineColor: [0, 0, 0],
    });

    finalY = (doc as any).lastAutoTable.finalY || finalY;
    finalY += 10;
    doc.setFontSize(10);
    doc.setFont("helvetica", "italic");
    doc.text(
      "*For claiming deduction under 80D (Medical Insurance), a copy of Insurance certificate containing the name of the insured persons may be enclosed.",
      15,
      finalY,
      { maxWidth: 180 }
    );

    // PDF to a Blob
    const pdfBlob = doc.output("blob");

    const formData = new FormData();

    formData.append("formFile", pdfBlob, "Form12BB.pdf");

    const formsDto = {
      fiscal: this.fiscal,
      employeeId: this.employeeId,
      formType: "FORM12BB",
    };
    const jsonBlob = new Blob([JSON.stringify(formsDto)], {
      type: "application/json",
    });
    formData.append("formsDto", jsonBlob, "formsDto.json");

    this.investmentService.postUploadForm(formData).subscribe(
      (response) => {
        if (response.success === false) {
          console.error("API returned an error:", response);
          this.snackBarService.add({
            message: response || "Error uploading form.",
            action: "Close",
            config: { duration: 4000, panelClass: ["error-snackbar-class"] },
          });
        } else {
          this.snackBarService.add({
            message: "Form uploaded successfully!",
            action: "Close",
            config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
          });
        }
      },
      (error) => {
        console.error("Error uploading form:", error);
        this.snackBarService.add({
          message: "Error uploading form.",
          action: "Close",
          config: { duration: 4000, panelClass: ["error-snackbar-class"] },
        });
      }
    );
  }
  postUpdateInvestmentStatus(status: string) {
    const employeeId = this.employeeId;
    const fiscal = this.fiscal;
    const investmentProofStatus = status;

    this.investmentService
      .postUpdateInvestmentStatusApi(employeeId, fiscal, investmentProofStatus)
      .subscribe(
        (response) => {
          const successMessage =
            response && response.message
              ? response.message
              : "Status changed successfully!";
          this.snackBarService.add({
            message: successMessage,
            action: "Close",
            config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
          });
          this.dialog.closeAll();
          this.router.navigate(["/dashboard/proofOfIncome"]);
        },
        (error) => {
          const errorMessage =
            error && error.error && error.error.message
              ? error.error.message
              : "An error occurred while updating the status.";
          this.snackBarService.add({
            message: errorMessage,
            action: "Close",
            config: { duration: 4000, panelClass: ["error-snackbar-class"] },
          });
        }
      );
  }
  openAttachment(
    employeeId: string,
    attachments: any[],
    isHRA: boolean = false
  ) {
    if (!attachments || attachments.length === 0) {
      console.warn("No attachments available.");
      return;
    }

    from(attachments)
      .pipe(
        concatMap((attachment) =>
          this.investmentService
            .getAttachmentUrl(
              isHRA
                ? attachment.hraInvestmentProofAttachmentId
                : attachment.investmentProofAttachmentId,
              employeeId,
              isHRA
            )
            .pipe(
              concatMap((response: string) => {
                if (response) {
                  return of({
                    response,
                    fileName: attachment.investmentProofFileName,
                  });
                } else {
                  throw new Error("No valid attachment URL received.");
                }
              })
            )
        )
      )
      .subscribe(
        ({ response, fileName }) => {
          window.open(response, "_blank");
        },
        (error) => {
          console.error("Error fetching attachment URL:", error);
        }
      );
  }

  getStatusClass(status: string): string {
    if (!status) return "";

    switch (status.toUpperCase()) {
      case "APPROVED":
        return "status-approved";
      case "REJECTED":
        return "status-rejected";
      case "OPEN":
        return "status-open";
      case "SAVED":
        return "status-saved";
      case "SUBMITTED":
        return "status-submitted";
      case "UPDATED":
        return "status-updated";
      default:
        return "";
    }
  }
  downloadProof() {
    const email = this.employeeId;
    const fiscal = this.fiscal;

    this.investmentService.downloadInvestmentProof(email, fiscal).subscribe(
      (response) => {
        const successMessage =
          response && response.message
            ? response.message
            : "Status changed successfully!";
        this.snackBarService.add({
          message: successMessage,
          action: "Close",
          config: { duration: 4000, panelClass: ["custom-snackbar-class"] },
        });
      },
      (error) => {
        const errorMessage =
          error && error.error && error.error.message
            ? error.error.message
            : "An error occurred while updating the status.";
        this.snackBarService.add({
          message: errorMessage,
          action: "Close",
          config: { duration: 4000, panelClass: ["error-snackbar-class"] },
        });
      }
    );
  }
}
