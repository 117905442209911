import { OnInit } from "@angular/core";
import { getCurrentFiscalYear } from "../shared/app.utility";
var UserCtcDetailsComponent = /** @class */ (function () {
    function UserCtcDetailsComponent(employeeService, loginService) {
        this.employeeService = employeeService;
        this.loginService = loginService;
        this.employeeCtcDetails = [];
        this.fiscalYear = getCurrentFiscalYear();
        this.totalAmount = 0;
    }
    UserCtcDetailsComponent.prototype.ngOnInit = function () {
        this.getEmployeeCTCDetails();
    };
    UserCtcDetailsComponent.prototype.getEmployeeCTCDetails = function () {
        var _this = this;
        this.employeeService
            .getEmployeeCTCDetailsApi(this.loginService.employeeDetails.employeeId, this.fiscalYear)
            .subscribe(function (data) {
            _this.employeeCtcDetails = data.filter(function (item) { return item.amount > 0; });
            _this.calculateTotal();
        }, function (error) {
            console.error("Error fetching employee CTC details", error);
        });
    };
    UserCtcDetailsComponent.prototype.calculateTotal = function () {
        this.totalAmount = this.employeeCtcDetails.reduce(function (sum, item) { return sum + item.amount; }, 0);
    };
    return UserCtcDetailsComponent;
}());
export { UserCtcDetailsComponent };
