import { Subject } from "rxjs";
import { OnDestroy } from "@angular/core";
import { SnackbarWithProgressComponent } from "src/app/snackbar-with-progress/snackbar-with-progress.component";
var SnackBarMessage = /** @class */ (function () {
    function SnackBarMessage() {
        this.action = null;
        this.config = null;
    }
    return SnackBarMessage;
}());
export { SnackBarMessage };
var CLOSE_ACTION = "close";
var SnackBarService = /** @class */ (function () {
    function SnackBarService(snackBar) {
        var _this = this;
        this.snackBar = snackBar;
        this.messageQueue = new Subject();
        this.msgQueue = [];
        this.subscription = this.messageQueue.subscribe(function (message) {
            _this.snackBarRef = _this.snackBar.open(message.message, message.action, message.config);
        });
    }
    SnackBarService.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    /**
     * Add a message
     * @param message The message to show in the snackbar.
     * @param action The label for the snackbar action.
     * @param config Additional configuration options for the snackbar.
     */
    SnackBarService.prototype.add = function (sbMessage) {
        if (!sbMessage.action) {
            sbMessage.action = CLOSE_ACTION;
        }
        if (!sbMessage.config.panelClass) {
            sbMessage.config.panelClass = ["custom-snackbar-class"];
        }
        this.msgQueue.push(sbMessage);
        if (!this.isInstanceVisible) {
            this.showNext();
        }
    };
    SnackBarService.prototype.showNext = function () {
        var _this = this;
        if (this.msgQueue.length === 0) {
            return;
        }
        var message = this.msgQueue.shift();
        this.isInstanceVisible = true;
        this.snackBarRef = this.snackBar.open(message.message, message.action, message.config);
        this.snackBarRef.afterDismissed().subscribe(function () {
            _this.isInstanceVisible = false;
            _this.showNext();
        });
    };
    SnackBarService.prototype.open = function (action, duration) {
        var snackBarRef = this.snackBar.openFromComponent(SnackbarWithProgressComponent, {
            data: { action: action },
            duration: duration * 1000,
            panelClass: ["custom-snackbar-class"],
        });
        return snackBarRef.instance;
    };
    return SnackBarService;
}());
export { SnackBarService };
