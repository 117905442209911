import { HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { getCurrentFiscalYear } from "../app.utility";
import * as i0 from "@angular/core";
import * as i1 from "./base.service";
var InvestmentDeclarationService = /** @class */ (function () {
    function InvestmentDeclarationService(baseService) {
        this.baseService = baseService;
    }
    InvestmentDeclarationService.prototype.getInvestmentLimitApi = function () {
        this.path = "investLimitXref";
        return this.baseService.get(this.path);
    };
    InvestmentDeclarationService.prototype.getInvestmentDeclarationSectionApi = function () {
        this.path = "options/INVESTMENT_DECLARATION_SECTION";
        return this.baseService.get(this.path);
    };
    InvestmentDeclarationService.prototype.getInvestmentBySectionApi = function (section) {
        this.path = "options/investment/" + section;
        return this.baseService.get(this.path);
    };
    InvestmentDeclarationService.prototype.getEmployeeInvestDeclarationByEmpId = function (employeeId, year) {
        this.path = "employeeInvestmentDeclaration/employeeInvestDeclarationByEmpIdAndFiscal?employeeId=" + employeeId + "&fiscal=" + year;
        return this.baseService.get(this.path);
    };
    InvestmentDeclarationService.prototype.getIncomeTaxComparisonApi = function (fiscal, employeeId, requestBody) {
        this.path = "incomeTax";
        this.params = new HttpParams()
            .set("employeeId", employeeId)
            .set("fiscal", fiscal);
        return this.baseService.post(this.path, requestBody, this.params);
    };
    InvestmentDeclarationService.prototype.postEmployeeInvestmentDeclarationApi = function (requestBody) {
        this.path = "employeeInvestmentDeclaration";
        return this.baseService.post(this.path, requestBody);
    };
    InvestmentDeclarationService.prototype.downloadApprovedPoi = function (employeeId, fiscalYear) {
        var path = "forms/download";
        var requestBody = {
            fiscal: fiscalYear,
            employeeId: employeeId,
            formType: "FORM12BB",
        };
        return this.baseService.post(path, requestBody, null, "text");
    };
    InvestmentDeclarationService.prototype.postUploadForm = function (formData) {
        this.path = "forms/upload";
        return this.baseService.post(this.path, formData, null, "text");
    };
    InvestmentDeclarationService.prototype.postEmpTaxRegimeApi = function (requestBody) {
        this.path = "empTaxRegime";
        return this.baseService.post(this.path, requestBody);
    };
    InvestmentDeclarationService.prototype.getEmpInvestProofDetails = function (employeeId, fiscal) {
        var path = "empInvestProof/section";
        var params = new HttpParams()
            .set("employeeId", employeeId)
            .set("fiscal", fiscal);
        return this.baseService.get(path, params);
    };
    InvestmentDeclarationService.prototype.getFormType = function () {
        var path = "forms/form-types";
        var params = new HttpParams();
        return this.baseService.get(path, params);
    };
    InvestmentDeclarationService.prototype.getAdminEmpInvestProofCount = function (fiscal) {
        var path = "adminEmpInvestProof/count";
        var params = new HttpParams().set("fiscal", fiscal);
        return this.baseService.get(path, params);
    };
    InvestmentDeclarationService.prototype.updateInvestmentStatus = function (empInvestProofId, status, adminRemarks, approvedAmount) {
        var path = "adminEmpInvestProof/review";
        var body = {
            empInvestProofId: empInvestProofId,
            status: status,
            adminRemarks: adminRemarks,
            approvedAmount: approvedAmount,
        };
        return this.baseService.put(path, body);
    };
    InvestmentDeclarationService.prototype.postUpdateInvestmentStatusApi = function (employeeId, fiscal, investmentProofStatus) {
        var path = "adminEmpInvestProof/status";
        var params = new HttpParams()
            .set("employeeId", employeeId)
            .set("fiscal", fiscal)
            .set("investmentProofStatus", investmentProofStatus);
        return this.baseService.put(path, null, params);
    };
    // getAttachmentUrl(
    //   investmentProofAttachmentId: string,
    //   employeeId: string
    // ): Observable<any> {
    //   const path = `empInvestProof/investProofFileByProofId`;
    //   const params = new HttpParams()
    //     .set("investmentProofAttachmentId", investmentProofAttachmentId)
    //     .set("employeeId", employeeId);
    //   return this.baseService.get(path, params, null, "text");
    // }
    InvestmentDeclarationService.prototype.getAttachmentUrl = function (attachmentId, employeeId, isHRA) {
        var path = "empInvestProof/investProofFileByProofId";
        var params = new HttpParams().set("employeeId", employeeId);
        if (isHRA) {
            params = params.set("hraInvestmentProofId", attachmentId);
        }
        else {
            params = params.set("investmentProofAttachmentId", attachmentId);
        }
        return this.baseService.get(path, params, null, "text");
    };
    InvestmentDeclarationService.prototype.downloadInvestmentProof = function (employeeId, fiscal) {
        var path = "empInvestProof/investProofByEmployeeEmailAndFiscal";
        var params = new HttpParams()
            .set("employeeId", employeeId)
            .set("fiscal", fiscal);
        return this.baseService.get(path, params);
    };
    InvestmentDeclarationService.prototype.getEmpTaxRegime = function () {
        var path = "empTaxRegime";
        return this.baseService.get(path);
    };
    InvestmentDeclarationService.prototype.getAdminEmpInvestProof = function (fiscal, taxRegimeTypes, status) {
        this.path = "adminEmpInvestProof";
        this.params = new HttpParams().set("fiscal", fiscal);
        if (taxRegimeTypes && taxRegimeTypes !== "ALL") {
            this.params = this.params.set("taxRegimeTypes", taxRegimeTypes);
        }
        if (status && status !== "All") {
            this.params = this.params.set("status", status);
        }
        return this.baseService.get(this.path, this.params);
    };
    InvestmentDeclarationService.prototype.submitPoiApi = function (empId, fiscal) {
        this.path = "empInvestProof/status";
        this.params = new HttpParams()
            .set("employeeId", empId)
            .set("fiscal", fiscal)
            .set("employeeRemarks", "SUBMITTED");
        return this.baseService.put(this.path, {}, this.params);
    };
    InvestmentDeclarationService.prototype.releasePoiApi = function (fiscal) {
        this.path = "adminEmpInvestProof?fiscal=" + fiscal;
        return this.baseService.post(this.path);
    };
    InvestmentDeclarationService.prototype.getSectionInvestmentDetails = function (employeeId, fiscal) {
        var path = "empInvestProof/section/amount";
        var params = new HttpParams()
            .set("employeeId", employeeId)
            .set("fiscal", fiscal);
        return this.baseService.get(path, params);
    };
    InvestmentDeclarationService.prototype.getEmployeePoiStatus = function (empId) {
        this.path = "adminEmpInvestProof?fiscal=" + getCurrentFiscalYear() + "&employeeId=" + empId;
        return this.baseService.get(this.path);
    };
    InvestmentDeclarationService.prototype.deleteInvestmentProofByIf = function (empId, investmentProofAttachmentId) {
        this.path = "empInvestProof/investProofFile?employeeId=" + empId + "&investmentProofAttachmentId=" + investmentProofAttachmentId;
        return this.baseService.delete(this.path);
    };
    InvestmentDeclarationService.prototype.getAdminRemarksByEmpInvestProofIdApi = function (empId, empInvestProofId) {
        this.path = "empInvestProof/admin/remarks?empInvestProofId=" + empInvestProofId + "&employeeId=" + empId;
        return this.baseService.get(this.path);
    };
    InvestmentDeclarationService.prototype.getEmpRemarksByEmpInvestProofIdApi = function (empId, empInvestProofId) {
        this.path = "empInvestProof/remarks?empInvestProofId=" + empInvestProofId + "&employeeId=" + empId;
        return this.baseService.get(this.path);
    };
    InvestmentDeclarationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvestmentDeclarationService_Factory() { return new InvestmentDeclarationService(i0.ɵɵinject(i1.BaseService)); }, token: InvestmentDeclarationService, providedIn: "root" });
    return InvestmentDeclarationService;
}());
export { InvestmentDeclarationService };
