export const MD_SCREEN_SIZE_BREAKPOINT = 768;
export const EMPLOYEE_ID = "employeeId";
export const MONTHS: { [month: number]: string } = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const DISPLAYED_COLUMNS: string[] = [
  "employeeId",
  "employeeCode",
  "name",
  "netPay",
  "totalIncome",
  "options",
];

export const COLUMNS_FOR_FILTER: string[] = [
  "firstName",
  "lastName",
  "employeeId",
  "employeeCode",
];

export const COLS_WHICH_REQUIRE_TOOLTIP = ["employeeId", "name", "totalIncome"];

export const HEADERS = [
  ["earningsComponent", "Earnings"],
  ["earningsComponentAmount", "Amount"],
  ["deductionsComponent", "Deductions"],
  ["deductionsComponentAmount", "Amount"],
];

export function getFormattedDate(date: Date): string {
  const daysOfWeek: string[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayOfWeek: string = daysOfWeek[date.getDay()];
  const day: number = date.getDate();
  const month: string = months[date.getMonth()];
  const year: number = date.getFullYear();

  let daySuffix: string;
  if (day >= 11 && day <= 13) {
    daySuffix = "th";
  } else {
    switch (day % 10) {
      case 1:
        daySuffix = "st";
        break;
      case 2:
        daySuffix = "nd";
        break;
      case 3:
        daySuffix = "rd";
        break;
      default:
        daySuffix = "th";
        break;
    }
  }
  return `${dayOfWeek}, ${day}${daySuffix} ${month} ${year}`;
}

export function getFirstDayOfNextMonth(today: Date): Date {
  const nextMonthDate = new Date(today);

  if (nextMonthDate.getMonth() === 11) {
    nextMonthDate.setFullYear(nextMonthDate.getFullYear() + 1);
    nextMonthDate.setMonth(0);
  } else {
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
  }
  nextMonthDate.setDate(1);
  return nextMonthDate;
}
