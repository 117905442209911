import { EventEmitter, OnInit } from "@angular/core";
import { getCurrentFiscalYear } from "src/app/shared/app.utility";
var EmployeePfUpdateComponent = /** @class */ (function () {
    function EmployeePfUpdateComponent(employeeService, snackBarService) {
        this.employeeService = employeeService;
        this.snackBarService = snackBarService;
        this.closePfEditScreen = new EventEmitter();
        this.close = new EventEmitter();
        this.ctcDetails = []; // To store CTC details
        this.selectedFiscalYear = getCurrentFiscalYear();
        this.fiscalYears = [];
        this.minYear = new Date();
        this.maxYear = new Date();
        this.pfContributionOptions = [];
        this.selectedPfType = "";
        this.deductionAmount = 0;
        this.basicPay = 0;
        this.months = [
            { name: "January", value: 1 },
            { name: "February", value: 2 },
            { name: "March", value: 3 },
            { name: "April", value: 4 },
            { name: "May", value: 5 },
            { name: "June", value: 6 },
            { name: "July", value: 7 },
            { name: "August", value: 8 },
            { name: "September", value: 9 },
            { name: "October", value: 10 },
            { name: "November", value: 11 },
            { name: "December", value: 12 },
        ];
    }
    EmployeePfUpdateComponent.prototype.ngOnInit = function () {
        this.selectedPfType = "fixed";
        this.calculateDeductionAmount();
        if (this.selectedEmployeeDetails) {
            this.getEmployeePFPreference();
            this.getEmployeeCTCDetails(this.selectedEmployeeDetails.employeeId);
        }
        this.getPfTypeValues();
        this.changeAppContentWrapperStyle();
    };
    EmployeePfUpdateComponent.prototype.changeAppContentWrapperStyle = function () {
        var appContentWrapperDiv = document.querySelector("#appContentWrapper");
        appContentWrapperDiv.style.paddingTop = "0";
        appContentWrapperDiv.style.height = "100%";
        appContentWrapperDiv.style.backgroundColor = "white";
    };
    EmployeePfUpdateComponent.prototype.resetAppContentWrapperStyle = function () {
        var appContentWrapperDiv = document.querySelector("#appContentWrapper");
        appContentWrapperDiv.style.paddingTop = "24px";
        appContentWrapperDiv.style.height = "auto";
        appContentWrapperDiv.style.backgroundColor = "";
    };
    EmployeePfUpdateComponent.prototype.getEmployeeCTCDetails = function (empId) {
        var _this = this;
        this.employeeService
            .getEmployeeCTCDetailsApi(empId, this.selectedFiscalYear)
            .subscribe(function (res) {
            _this.ctcDetails = res.filter(function (item) { return item.status === "ACTIVE"; });
            _this.fyear = res[0].fiscal;
            var basicPayDetail = _this.ctcDetails.find(function (item) { return item.ctcComponent === "BASIC_PAY"; });
            _this.basicPay = basicPayDetail ? basicPayDetail.amount : 0;
        }, function (error) {
            _this.ctcDetails = [];
            _this.snackBarService.open("Failed to fetch CTC details", "Close", {
                duration: 2000,
            });
            console.error("getEmployeeCTCDetailsApi failed", error);
        });
    };
    EmployeePfUpdateComponent.prototype.calculateDeductionAmount = function () {
        switch (this.selectedPfType) {
            case "fixed":
                this.deductionAmount = 1800;
                break;
            case "variable":
                this.deductionAmount = 0.12 * this.basicPay;
                break;
            case "default":
                this.deductionAmount = Math.min(1800 * 12, 0.12 * this.basicPay);
                break;
            case "NA":
            default:
                this.deductionAmount = 0;
                break;
        }
    };
    EmployeePfUpdateComponent.prototype.setCurrentMonth = function () {
        var currMonth = this.today.getMonth();
        var currMonthObj = this.months.filter(function (month) { return month.value == currMonth; });
        this.lwpMonth = currMonthObj[0].name;
    };
    EmployeePfUpdateComponent.prototype.getDetailsAsPerYear = function () {
        var _this = this;
        this.allEmpDetails.forEach(function (element) {
            if (_this.expandedElement === element) {
                _this.getEmployeeCTCDetails(element);
            }
        });
    };
    EmployeePfUpdateComponent.prototype.setCurrentYear = function () {
        var currYear = this.today.getFullYear();
        this.lwpYear = currYear;
    };
    EmployeePfUpdateComponent.prototype.onClosePfEditScreen = function () {
        this.closePfEditScreen.emit();
    };
    EmployeePfUpdateComponent.prototype.getPfTypeValues = function () {
        var _this = this;
        this.employeeService.getEmployeePFPreferenceApi().subscribe(function (res) {
            _this.pfContributionOptions = res;
        }, function (err) {
            console.error("Failed to fetch PF types:", err);
        });
    };
    EmployeePfUpdateComponent.prototype.getEmployeePFPreference = function () {
        var _this = this;
        var employeeId = this.selectedEmployeeDetails.employeeId;
        var fiscalYear = this.selectedFiscalYear;
        this.employeeService
            .getEmployeePFPreferenceByEmpIdAndFiscal(employeeId, fiscalYear)
            .subscribe(function (response) {
            _this.selectedPfType = response.pfType || "fixed";
            _this.calculateDeductionAmount();
        }, function (error) {
            console.error("Failed to fetch PF preference:", error);
            _this.snackBarService.open("Failed to fetch PF preference", "Close", {
                duration: 2000,
            });
        });
    };
    EmployeePfUpdateComponent.prototype.onPfTypeChange = function (event) {
        var selectElement = event.target;
        this.selectedPfType = selectElement.value;
        this.calculateDeductionAmount();
    };
    EmployeePfUpdateComponent.prototype.onClose = function () {
        this.close.emit();
    };
    EmployeePfUpdateComponent.prototype.updateEmployeeCtcDetails = function () {
        var _this = this;
        var hasInvalidFields = this.ctcDetails.some(function (ctc) {
            return ctc.amount === null || ctc.amount === undefined || ctc.amount === "";
        });
        if (hasInvalidFields) {
            this.snackBarService.open("Please ensure all fields are filled.", "Close", {
                duration: 2000,
            });
            return;
        }
        var requestBody = this.ctcDetails.map(function (ctc) { return ({
            employeeCtcId: ctc.employeeCtcId,
            employeeId: _this.selectedEmployeeDetails.employeeId,
            ctcComponent: ctc.ctcComponent,
            amount: ctc.amount,
            fiscal: _this.fyear,
            status: "ACTIVE",
            notes: "Updated CTC details",
        }); });
        this.employeeService.updateEmployeeCtc(requestBody).subscribe(function (response) {
            _this.snackBarService.open("CTC details updated successfully", "Close", {
                duration: 2000,
            });
            _this.onClose();
        }, function (error) {
            console.error("Failed to update CTC details:", error);
            _this.snackBarService.open("Failed to update CTC details", "Close", {
                duration: 2000,
            });
        });
    };
    EmployeePfUpdateComponent.prototype.updateEmployeePFPreference = function () {
        var _this = this;
        var employeeId = this.selectedEmployeeDetails.employeeId;
        var fiscalYear = this.selectedFiscalYear;
        // Fetch empPFPreferenceId first
        this.employeeService
            .getEmployeePFPreferenceByEmpIdAndFiscal(employeeId, fiscalYear)
            .subscribe(function (response) {
            var empPFPreferenceId = response.empPFPreferenceId;
            var requestBody = {
                empPFPreferenceId: empPFPreferenceId,
                employeeId: _this.selectedEmployeeDetails.employeeId,
                pfType: _this.selectedPfType,
                fiscal: _this.selectedFiscalYear,
            };
            _this.employeeService
                .putEmployeePFPreferenceApi(requestBody)
                .subscribe(function (response) {
                _this.snackBarService.open("PF preference updated successfully", "Close", { duration: 2000 });
                _this.onClose();
            }, function (error) {
                console.error("Failed to update PF preference:", error);
                _this.snackBarService.open("Failed to update PF preference", "Close", { duration: 2000 });
            });
        }, function (error) {
            console.error("Failed to fetch PF preference:", error);
            _this.snackBarService.open("Failed to fetch PF preference", "Close", {
                duration: 2000,
            });
        });
    };
    EmployeePfUpdateComponent.prototype.transformCtcComponent = function (component) {
        if (!component)
            return "";
        return component.replace(/_/g, " ").toUpperCase();
    };
    EmployeePfUpdateComponent.prototype.ngOnDestroy = function () {
        this.resetAppContentWrapperStyle();
    };
    return EmployeePfUpdateComponent;
}());
export { EmployeePfUpdateComponent };
