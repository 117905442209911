import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { MatDialog, MatTableDataSource } from "@angular/material";
import { PoiStatus } from "../employee-poi/employee-poi.component";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
} from "@angular/forms";
import { LoginService } from "../account/login.service";
import { getCurrentFiscalYear } from "../shared/app.utility";
import { PoiService } from "../shared/services/poi.service";
import { InvestmentDeclarationService } from "../shared/services/investment-declaration.service";
import { finalize } from "rxjs/operators";
import { SnackBarService } from "../shared/services/snackbar.service";

const ADD_POI_TABLE_COLUMNS = [
  { label: "Particulars", key: "particulars" },
  { label: "Attachment", key: "proofId" },
  { label: "Declared", key: "declared" },
  { label: "Approved", key: "approved" },
  { label: "Status", key: "status" },
  { label: "Remarks", key: "remarks" },
  { label: "Options", key: "options" },
];

interface AddPoiTableData {
  particulars: string;
  declared: string;
  status: PoiStatus;
  approved: string;
}

export interface PoiData extends AddPoiTableData {
  investmentViaId: number;
  proofFileName?: string;
  proofIds?: Map<string, number>;
  employeeRemark?: string;
  employerRemark?: string;
  proofFiles?: any[];
  optionId?: number;
}

export interface PoiHraData extends PoiData {
  fromMonth: number;
  toMonth: number;
  monthlyRentAmount: number;
  metroCity: boolean;
}

export interface hraProofDto {
  fromMonth: number;
  toMonth: number;
  monthlyRentAmount: number;
  metroCity: boolean;
  fileNames: string[];
  hraProofId?: number;
}

export interface InvestmentProofDto {
  investmentViaId: number;
  declaredAmount: number;
  employeeId: string;
  amount?: number;
  investmentProofFileName?: string;
  fiscal: string;
  hraProofs?: hraProofDto[];
}

@Component({
  selector: "app-employee-add-poi",
  templateUrl: "./employee-add-poi.component.html",
  styleUrls: ["./employee-add-poi.component.scss"],
})
export class EmployeeAddPoiComponent implements OnInit {
  @Input() sectionName: string;
  @Input() sectionOptionsData: PoiData[];
  @Input() poiStatus: PoiStatus;
  @Input() isSaveDisabled: boolean;
  @Input() empPoiStatus: null | PoiStatus;
  @Input() hraInvestmentViaId: number;
  @Input() hraDeclaredAmount: number | null;
  @Input() hraApprovedAmount: number | null;
  @Input() hraEmpInvestProofId: number | null;
  displayedColumns: { key: string; label: string }[] = ADD_POI_TABLE_COLUMNS;
  addPoiTableData: MatTableDataSource<AddPoiTableData>;
  browsedFile: any;
  browsedFiles: any[] = [];
  poiForm: FormGroup;
  poiFormCopy: FormGroup;
  selectedOptionName: string;
  savedAttachments = new Map<string, string>();
  sectionOptionsDataInitCopy: PoiData[];
  optionIdMap = new Map<string, number | null>();
  optionRemarksMap = new Map<
    string,
    { remark: string; remarkDate: string }[]
  >();
  hraRemark = "";

  @Output() openNextSectionEvent = new EventEmitter<string>();
  @Output() closeAddPoiComponentEvent = new EventEmitter<string>();
  @Output() hraOptionsDataChange = new EventEmitter<any[]>();

  shouldUpdateMainForm: boolean;
  hraAdminRemarks: string[] | null = null;
  isThereAnyFormChanges: boolean = false;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private poiService: PoiService,
    private invDecService: InvestmentDeclarationService,
    private snackBarService: SnackBarService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.sectionName === "HRA") {
      this.displayedColumns = this.displayedColumns.filter(
        (el) => el.key !== "approved"
      );
    }
    this.sectionOptionsDataInitCopy = this.sectionOptionsData.map((el) => ({
      ...el,
    }));
    this.initPoiForm();
    this.setAddPoiTableData();
    this.setOptionIdMap();
    this.poiForm.valueChanges.subscribe(() => {
      this.isThereAnyFormChanges = this.isDraftFormUpdated();
    });
  }

  setAddPoiTableData() {
    if (this.sectionName === "HRA") {
      this.addPoiTableData = new MatTableDataSource(
        this.sectionOptionsData.sort((a, b) => {
          let numA = parseInt(a.particulars.replace("House ", ""), 10);
          let numB = parseInt(b.particulars.replace("House ", ""), 10);
          return numA - numB;
        })
      );
    } else {
      this.addPoiTableData = new MatTableDataSource(
        this.sectionOptionsData.sort((a, b) => (a.declared === "0" ? 1 : -1))
      );
    }
    this.cdr.detectChanges();
  }

  setOptionIdMap() {
    this.sectionOptionsData.forEach((el) => {
      const id = el.optionId ? el.optionId : null;
      this.optionIdMap.set(el.particulars, id);
    });
  }

  initPoiForm() {
    this.poiForm = this.formBuilder.group({});
    this.poiFormCopy = this.formBuilder.group({});
    this.sectionOptionsData.forEach((el) => {
      let control: FormGroup;

      control = new FormGroup({
        declared: new FormControl(el.declared),
        employeeRemark: new FormControl(el.employeeRemark),
        employerRemark: new FormControl({
          value: el.employerRemark,
          disabled: true,
        }),
        proofIds: new FormControl(el.proofIds),
        proofFiles: new FormControl([]),
        investmentViaId: new FormControl(el.investmentViaId),
        proofFileName: new FormControl(el.proofFileName),
      });
      if (this.sectionName === "HRA") {
        control.addControl(
          "fromMonth",
          new FormControl((el as PoiHraData).fromMonth)
        );
        control.addControl(
          "toMonth",
          new FormControl((el as PoiHraData).toMonth)
        );
        control.addControl(
          "monthlyRentAmount",
          new FormControl((el as PoiHraData).monthlyRentAmount)
        );
        control.addControl(
          "metroCity",
          new FormControl((el as PoiHraData).metroCity)
        );
        if (el.proofFiles) {
          control.patchValue({ proofFiles: el.proofFiles });
        }
      }
      this.poiForm.addControl(el.particulars, control);

      const controlCopy = new FormGroup({});
      Object.keys(control.controls).forEach((key) => {
        const originalControl = control.get(key) as FormControl;
        const newControl = new FormControl(
          originalControl.value,
          originalControl.validator,
          originalControl.asyncValidator
        );
        if (originalControl.disabled) {
          newControl.disable();
        }
        controlCopy.addControl(key, newControl);
      });

      this.poiFormCopy.addControl(el.particulars, controlCopy);
    });
    this.cdr.detectChanges();
  }

  getDisplayedColumns(): string[] {
    return this.displayedColumns.map((col) => col.key);
  }

  isStringValue(key: string): boolean {
    return key === "particulars" || key === "declared";
  }

  openUploadProofDialog(
    templateRef: TemplateRef<any>,
    optionName: string,
    shouldUpdateMainForm = false
  ) {
    this.selectedOptionName = optionName;
    this.shouldUpdateMainForm = shouldUpdateMainForm;
    let dialogRef = this.dialog.open(templateRef, {
      panelClass: "upload-panel-class",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.browsedFiles = [];
    });
  }

  openRemarksDialog(templateRef: TemplateRef<any>, optionName: string) {
    this.selectedOptionName = optionName;
    const remarks = this.optionRemarksMap.get(optionName);
    if (this.sectionName === "HRA") {
      if (!this.hraAdminRemarks && this.hraEmpInvestProofId) {
        this.invDecService
          .getAdminRemarksByEmpInvestProofIdApi(
            this.loginService.employeeDetails.employeeId,
            this.hraEmpInvestProofId.toString()
          )
          .pipe(
            finalize(() => {
              const dialogRef = this.dialog.open(templateRef);
            })
          )
          .subscribe((res) => {
            this.hraAdminRemarks = res;
          });
      } else {
        const dialogRef = this.dialog.open(templateRef);
      }
    } else {
      if (!remarks && this.optionIdMap.get(optionName)) {
        this.invDecService
          .getAdminRemarksByEmpInvestProofIdApi(
            this.loginService.employeeDetails.employeeId,
            this.optionIdMap.get(optionName).toString()
          )
          .pipe(
            finalize(() => {
              const dialogRef = this.dialog.open(templateRef);
            })
          )
          .subscribe((res) => {
            this.optionRemarksMap.set(optionName, res);
          });
      } else {
        const dialogRef = this.dialog.open(templateRef);
      }
    }
  }

  openEmployeeHraRemarksDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  openEditOptionDialog(
    templateRef: TemplateRef<any>,
    optionName: string,
    status: string
  ) {
    if (status.toLowerCase() !== "approved") {
      this.selectedOptionName = optionName;
      const mainFormGroup = this.poiForm.get(this.selectedOptionName);
      const copyFormGroup = this.poiFormCopy.get(this.selectedOptionName);
      copyFormGroup.patchValue(mainFormGroup.value);
      const dialogRef = this.dialog.open(templateRef);
    }
  }

  fileBrowseHandler(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!input.files) return;

    const newFiles: File[] = Array.from(input.files);
    let totalSize = this.browsedFiles.reduce((sum, file) => sum + file.size, 0);

    newFiles.forEach((file) => {
      totalSize += file.size;
    });

    if (totalSize > 1 * 1024 * 1024) {
      this.snackBarService.add({
        message: "Total file size exceeds 1MB.",
        action: "Close",
        config: { duration: 3000, panelClass: ["custom-snackbar-class"] },
      });
    } else {
      this.browsedFiles = [...this.browsedFiles, ...newFiles];
      this.prepareFilesList();
      this.cdr.detectChanges();
    }
  }

  onFileDropped() {
    this.prepareFilesList();
  }

  prepareFilesList() {
    this.browsedFiles.forEach((file) => {
      if (file.progress === undefined) {
        file.progress = 0;
      }
    });
    this.uploadFilesSimulator();
  }

  uploadFilesSimulator() {
    setTimeout(() => {
      const progressInterval = setInterval(() => {
        let allCompleted = true;
        this.browsedFiles.forEach((file) => {
          if (file.progress < 100) {
            file.progress += 5;
            allCompleted = false;
          }
        });
        if (allCompleted) {
          clearInterval(progressInterval);
        }
      }, 20);
    }, 100);
  }

  formatBytes(bytes) {
    let decimals;
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  addProofFileValue() {
    if (this.browsedFiles.length > 0) {
      let existingFileNames = [];
      if (
        this.poiForm.get(this.selectedOptionName) &&
        this.poiForm.get(this.selectedOptionName).value
      ) {
        existingFileNames = this.poiForm.get(this.selectedOptionName).value
          .proofFileName
          ? this.poiForm
              .get(this.selectedOptionName)
              .value.proofFileName.split(",")
          : [];
      }
      const newFileNames = this.browsedFiles.map((file) => file.name);
      const updatedFileNames = [...existingFileNames, ...newFileNames];
      const existingFiles = this.poiForm.get(this.selectedOptionName).value
        .proofFiles
        ? this.poiForm.get(this.selectedOptionName).value.proofFiles
        : [];
      const updatedFiles = [...existingFiles, ...this.browsedFiles];

      if (this.shouldUpdateMainForm) {
        this.poiForm.get(this.selectedOptionName).patchValue({
          proofFiles: updatedFiles,
          proofFileName: updatedFileNames.join(","),
        });
        if (this.sectionName === "HRA") {
          this.updateFilesInOptionsData(this.selectedOptionName, updatedFiles);
        }
      }
      this.poiFormCopy.get(this.selectedOptionName).patchValue({
        proofFiles: updatedFiles,
        proofFileName: updatedFileNames.join(","),
      });
      this.snackBarService.add({
        message: "Files uploaded",
        action: "Close",
        config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
      });
    }
  }

  // for HRA
  updateFilesInOptionsData(house: string, proofFiles) {
    const houseData = this.sectionOptionsData.find(
      (el) => el.particulars === house
    );
    let fileName = proofFiles.map((el) => el.name).join(",");
    if (proofFiles.length > 1) {
      //removing last comma
      fileName = fileName.slice(0, -1);
    }
    houseData.proofFileName = fileName;
    houseData.proofFiles = proofFiles;
  }

  updateOptionsData(house, data: Partial<PoiHraData>) {
    const houseData: PoiHraData = (
      this.sectionOptionsData as PoiHraData[]
    ).find((el) => el.particulars === house);
    houseData.declared = data.declared;
    houseData.fromMonth = data.fromMonth;
    houseData.toMonth = data.toMonth;
    houseData.monthlyRentAmount = data.monthlyRentAmount;
    houseData.metroCity = data.metroCity;
  }

  onSaveAndNextButtonClick() {
    if (this.sectionName === "HRA") {
      const houses = this.poiForm.value;
      for (const house in houses) {
        if (houses[house].declared !== "0" && houses[house].proofFileName) {
          continue;
        } else {
          this.snackBarService.add({
            message:
              "Please declare amount and add proof for all the houses or remove unwanted houses",
            action: "Close",
            config: { duration: 8000, panelClass: ["custom-snackbar-class"] },
          });
          return;
        }
      }
      this.saveHraPoi();
    } else {
      this.savePoi();
    }
  }

  saveHraPoi() {
    const formValue = this.poiForm.value;
    const formData = new FormData();
    let investmentProofDto: InvestmentProofDto = {
      declaredAmount: 0,
      amount: 0,
      employeeId: this.loginService.employeeDetails.employeeId,
      fiscal: getCurrentFiscalYear().toString(),
      investmentViaId: 0,
    };
    const hraProofs: hraProofDto[] = [];
    let investmentViaId = 0;
    let declaredAmount = 0;
    for (const key in formValue) {
      // investmentViaId should not be a part of the form, there should be a map of option to id
      investmentViaId = formValue[key].investmentViaId;
      declaredAmount += Number(formValue[key].declared);
      const hraProof: hraProofDto = {
        fromMonth: formValue[key].fromMonth,
        toMonth: formValue[key].toMonth,
        monthlyRentAmount: formValue[key].monthlyRentAmount,
        metroCity: formValue[key].metroCity,
        fileNames: formValue[key].proofFileName.split(","),
      };
      if (this.optionIdMap.get(key)) {
        hraProof.hraProofId = this.optionIdMap.get(key);
      }
      hraProofs.push(hraProof);

      if (Array.isArray(formValue[key].proofFiles)) {
        formValue[key].proofFiles.forEach((file) => {
          formData.append("file", file);
        });
      }
    }
    investmentProofDto.investmentViaId = investmentViaId;
    investmentProofDto.hraProofs = hraProofs;
    investmentProofDto.declaredAmount = declaredAmount;
    investmentProofDto.amount = declaredAmount;
    const jsonBlob = new Blob([JSON.stringify(investmentProofDto)], {
      type: "application/json",
    });
    formData.append("investmentProofDto", jsonBlob, "investmentProofDto.json");
    if (this.hraRemark !== "") {
      formData.append("remarks", this.hraRemark);
    }
    this.poiService
      .savePoiAsDraftApi(this.loginService.employeeDetails.employeeId, formData)
      .subscribe(
        (res) => {
          this.openNextSection();
        },
        (err) => {
          console.error("error", err);
        }
      );
  }

  isDraftFormUpdated(): boolean {
    const formValue = this.poiForm.value;
    let result = true;
    for (const [key] of Object.entries(formValue)) {
      let isFormUpdated = false;
      let isFilesUpdated = false;
      let tempElement: any = this.sectionOptionsDataInitCopy.find(
        (element) => element.particulars === key
      );
      if (this.sectionName === "HRA") {
        return true;
      } else {
        isFormUpdated = formValue[key].declared !== tempElement.declared;
      }

      let newFiles = formValue[key].proofFileName
        ? formValue[key].proofFileName.split(",")
        : [];
      if (newFiles.length > 0) {
        if (!tempElement.proofFileName) {
          isFilesUpdated = true;
        } else {
          let oldFiles = tempElement.proofFileName.split(",");
          if (newFiles.length !== oldFiles.length) {
            isFilesUpdated = true;
          } else {
            for (let element of oldFiles) {
              if (!formValue[key].proofIds.get(element)) {
                isFilesUpdated = true;
                break;
              }
            }
          }
        }
      }
      if (isFormUpdated || isFilesUpdated) {
        result =
          result &&
          formValue[key].declared &&
          formValue[key].declared !== 0 &&
          formValue[key].declared !== "0" &&
          newFiles.length !== 0;
      } else {
        if (formValue[key].declared > 0 && newFiles.length === 0) {
          result = result && false;
        }
      }
    }
    return result;
  }

  savePoi() {
    const formValue = this.poiForm.value;
    for (const [key] of Object.entries(formValue)) {
      let isFormUpdated = false;
      let isFilesUpdated = false;
      let tempElement: any = this.sectionOptionsDataInitCopy.find(
        (element) => element.particulars === key
      );
      if (this.sectionName === "HRA") {
        return true;
      } else {
        isFormUpdated = formValue[key].declared !== tempElement.declared;
      }

      let newFiles = formValue[key].proofFileName
        ? formValue[key].proofFileName.split(",")
        : [];

      if (newFiles.length > 0) {
        if (!tempElement.proofFileName) {
          isFilesUpdated = true;
        } else {
          let oldFiles = tempElement.proofFileName.split(",");
          if (newFiles.length !== oldFiles.length) {
            isFilesUpdated = true;
          } else {
            for (let element of oldFiles) {
              if (!formValue[key].proofIds.get(element)) {
                isFilesUpdated = true;
                break;
              }
            }
          }
        }
      }

      if (isFormUpdated || isFilesUpdated) {
        const formData = new FormData();
        let investmentProofDto: InvestmentProofDto = {
          declaredAmount: formValue[key].declared,
          amount: formValue[key].declared,
          employeeId: this.loginService.employeeDetails.employeeId,
          fiscal: getCurrentFiscalYear().toString(),
          investmentProofFileName: formValue[key].proofFileName,
          investmentViaId: formValue[key].investmentViaId,
        };

        const employeeRemarks = formValue[key].employeeRemark
          ? formValue[key].employeeRemark
          : "";

        if (Array.isArray(formValue[key].proofFiles)) {
          formValue[key].proofFiles.forEach((file) => {
            formData.append("file", file);
          });
        } else if (formValue[key].proofFiles) {
          formData.append("file", formValue[key].proofFiles);
        }

        const jsonBlob = new Blob([JSON.stringify(investmentProofDto)], {
          type: "application/json",
        });
        formData.append(
          "investmentProofDto",
          jsonBlob,
          "investmentProofDto.json"
        );
        if (employeeRemarks) {
          formData.append("remarks", employeeRemarks);
        }
        this.poiService
          .savePoiAsDraftApi(
            this.loginService.employeeDetails.employeeId,
            formData
          )
          .subscribe(
            (res) => {
              this.openNextSection();
            },
            (err) => {
              console.error("error", err);
            }
          );
      }
    }
  }

  openNextSection() {
    this.openNextSectionEvent.emit(this.sectionName);
  }

  closeAddPoiComponent() {
    if (this.sectionName === "HRA") {
      this.hraOptionsDataChange.emit(this.sectionOptionsDataInitCopy);
    }
    this.closeAddPoiComponentEvent.emit(this.sectionName);
  }

  getFormGroup(): FormGroup {
    return this.poiFormCopy.get(this.selectedOptionName) as FormGroup;
  }

  isEmployerRemarksPresent() {
    if (this.sectionName === "HRA") {
      return this.hraAdminRemarks && this.hraAdminRemarks.length > 0;
    }
    const remarks = this.optionRemarksMap.get(this.selectedOptionName);
    return remarks && remarks.length > 0;
  }

  getEmployerRemarks() {
    if (this.sectionName === "HRA") {
      return this.hraAdminRemarks;
    }
    return this.optionRemarksMap.get(this.selectedOptionName);
  }

  downloadFile(particulars: string, fileName: string) {
    const proofId = this.poiForm.value[particulars].proofIds.get(fileName);
    this.invDecService
      .getAttachmentUrl(proofId, this.loginService.employeeDetails.employeeId)
      .subscribe(
        (response) => {
          if (response) {
            const link = document.createElement("a");
            link.href = response;
            link.setAttribute("download", "Employee_POI.pdf");
            link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            console.error("No valid attachment URL received.");
          }
        },
        (error) => {
          console.error("Error fetching attachment URL:", error);
        }
      );
  }

  isSimpleValue(col: string): boolean {
    const simpleValueCols = ["particulars", "approved", "status"];
    return simpleValueCols.includes(col);
  }

  getStatusClass(status: string, col): string {
    if (col === "status") {
      switch (status.toUpperCase()) {
        case "CONSIDERED":
          return "status-considered";
        case "APPROVED":
          return "status-approved";
        case "REJECTED":
          return "status-rejected";
        case "OPEN":
          return "status-open";
        case "SAVED":
          return "status-saved";
        case "SUBMITTED":
          return "status-submitted";
        default:
          return "";
      }
    }
    return "";
  }

  updatePoiForm() {
    const mainFormGroup = this.poiForm.get(this.selectedOptionName);
    const copyFormGroup = this.poiFormCopy.get(this.selectedOptionName);
    if (this.sectionName === "HRA") {
      const monthlyRent = this.getFormGroup().value.monthlyRentAmount;
      const fromMonth = this.getFormGroup().value.fromMonth;
      const toMonth = this.getFormGroup().value.toMonth;
      // months are 1 base index and plus one is to include toMonth in the duration
      const duration =
        (toMonth < 4 ? toMonth + 12 : toMonth) -
        (fromMonth < 4 ? fromMonth + 12 : fromMonth) +
        1;
      const declared = monthlyRent * duration;
      this.getFormGroup().patchValue({
        declared: declared,
      });
      const browsedFile = this.getFormGroup().value.proofFiles;
      if (browsedFile) {
        this.updateFilesInOptionsData(this.selectedOptionName, browsedFile);
      }
      this.updateOptionsData(this.selectedOptionName, {
        declared: declared.toString(),
        fromMonth: fromMonth,
        toMonth: toMonth,
        metroCity: this.getFormGroup().value.monthlyRentAmount.metroCity,
        monthlyRentAmount: monthlyRent,
      });
    }
    mainFormGroup.patchValue(copyFormGroup.value);
  }

  removeHouse(particulars: string, status: string) {
    if (status.toLowerCase() !== "approved") {
      const updatedArray = this.sectionOptionsData.filter(
        (el) => el.particulars !== particulars
      );
      this.hraOptionsDataChange.emit(updatedArray);
      this.sectionOptionsData = updatedArray;
      this.reNumberHouses();
      this.setOptionIdMap();
      this.setAddPoiTableData();
      this.initPoiForm();
    }
  }

  reNumberHouses() {
    this.sectionOptionsData.forEach((el, index) => {
      el.particulars = `House ${index + 1}`;
    });
  }

  addHouse() {
    const house: PoiHraData = {
      fromMonth: 4,
      toMonth: 4,
      monthlyRentAmount: 0,
      metroCity: false,
      investmentViaId: this.hraInvestmentViaId,
      particulars: `House ${this.sectionOptionsData.length + 1}`,
      declared: "0",
      status: "OPEN",
      approved: "0",
    };
    this.sectionOptionsData.push(house);
    this.initPoiForm();
    this.setAddPoiTableData();
  }

  removeAddedAttachment(index: number) {
    let fileNames =
      this.poiFormCopy.get(this.selectedOptionName) &&
      this.poiFormCopy.get(this.selectedOptionName).value &&
      this.poiFormCopy.get(this.selectedOptionName).value.proofFileName
        ? this.poiFormCopy
            .get(this.selectedOptionName)
            .value.proofFileName.split(",")
        : [];

    if (fileNames.length > index) {
      const removedFileName = fileNames.splice(index, 1)[0];

      let proofFiles = this.poiFormCopy.get(this.selectedOptionName).value
        .proofFiles;
      proofFiles = proofFiles.filter((el) => el.name !== removedFileName);

      this.poiFormCopy.get(this.selectedOptionName).patchValue({
        proofFileName: fileNames.length > 0 ? fileNames.join(",") : "",
        proofFiles: proofFiles,
      });
      const proofId = this.poiFormCopy
        .get(this.selectedOptionName)
        .value.proofIds.get(removedFileName);
      if (proofId) {
        this.invDecService
          .deleteInvestmentProofByIf(
            this.loginService.employeeDetails.employeeId,
            proofId
          )
          .subscribe(
            (res) => {
              this.poiForm.get(this.selectedOptionName).patchValue({
                proofFileName: fileNames.length > 0 ? fileNames.join(",") : "",
              });
              this.poiFormCopy
                .get(this.selectedOptionName)
                .value.proofIds.delete(removedFileName);
              this.poiForm
                .get(this.selectedOptionName)
                .value.proofIds.delete(removedFileName);
              this.isThereAnyFormChanges = this.isDraftFormUpdated();
              this.snackBarService.add({
                message: res.message,
                action: "Close",
                config: {
                  duration: 3000,
                  panelClass: ["custom-snackbar-class"],
                },
              });
            },
            (error) => {
              this.snackBarService.add({
                message: "Files uploaded",
                action: "Close",
                config: {
                  duration: 2000,
                  panelClass: ["custom-snackbar-class"],
                },
              });
            }
          );
      }
    }
  }

  removeFile(index: number) {
    this.browsedFiles.splice(index, 1);
  }
}
