<div class="screen-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-7">
        <div class="img-container">
          <img
            class="screen-icon"
            src="../../assets/images/user-not-found.svg"
            alt=""
          />
        </div>
      </div>
      <div class="col-12 col-lg-5 justify-content-center">
        <div class="card-container">
          <div class="card">
            <div class="message-container">
              <div class="message-heading">User Not Found</div>
              <div class="message">
                The user you are trying to find cannot be found. Please reach
                out to
                <a routerLink="#" style="color: blue">Payroll team</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
