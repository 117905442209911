import { PipeTransform } from '@angular/core';
var AbbreviateMonthPipe = /** @class */ (function () {
    function AbbreviateMonthPipe() {
    }
    AbbreviateMonthPipe.prototype.transform = function (value) {
        if (typeof value !== 'string') {
            return value;
        }
        var monthsMap = {
            'January': 'Jan',
            'February': 'Feb',
            'March': 'Mar',
            'April': 'Apr',
            'May': 'May',
            'June': 'Jun',
            'July': 'Jul',
            'August': 'Aug',
            'September': 'Sep',
            'October': 'Oct',
            'November': 'Nov',
            'December': 'Dec'
        };
        return monthsMap[value] || value;
    };
    return AbbreviateMonthPipe;
}());
export { AbbreviateMonthPipe };
