import { EventEmitter, OnInit } from "@angular/core";
var InvestmentSectionCardComponent = /** @class */ (function () {
    function InvestmentSectionCardComponent(dialog) {
        this.dialog = dialog;
        this.isAddingInvestmentToggleEvent = new EventEmitter();
    }
    InvestmentSectionCardComponent.prototype.ngOnInit = function () {
    };
    InvestmentSectionCardComponent.prototype.onAddInvestmentClick = function () {
        this.isAddingInvestmentToggleEvent.emit(this.sectionName);
    };
    InvestmentSectionCardComponent.prototype.formatAmount = function (amount) {
        if (typeof amount === 'number' && !isNaN(amount)) {
            return new Intl.NumberFormat('en-IN', { style: 'decimal', minimumFractionDigits: 2 }).format(amount);
        }
        return amount ? amount.toString() : "";
    };
    return InvestmentSectionCardComponent;
}());
export { InvestmentSectionCardComponent };
