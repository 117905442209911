import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MatDialog,
  MatPaginator,
  MatSnackBar,
  MatSort,
  MatTableDataSource,
} from "@angular/material";
import { EmployeeService } from "../shared/services/employee.service";
import { PayrollService } from "../shared/services/payroll.service";
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {
  DISPLAYED_COLUMNS,
  MONTHS,
  getFormattedDate,
  getFirstDayOfNextMonth,
  MD_SCREEN_SIZE_BREAKPOINT,
  COLS_WHICH_REQUIRE_TOOLTIP,
  EMPLOYEE_ID,
  COLUMNS_FOR_FILTER,
} from "./payroll.utility";
import { AddEmployeeDetailsComponent } from "../add-employee-details/add-employee-details.component";
import { SpinnerService } from "../shared/services/spinner.service";
import { catchError, take } from "rxjs/operators";
import { of } from "rxjs";
import {
  EmployeePayrollDetails,
  SNACKBAR_LABEL,
} from "../payslip/payslip.utility";
import { SnackBarService } from "../shared/services/snackbar.service";
import { EmployeeDetails } from "../core/interfaces/user";
import { PayrollDialogComponent } from "../payroll-dialog/payroll-dialog.component";
import { PayrollDialogComponentInput } from "../payroll-dialog/payroll-dialog.utility";
// (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "payroll",
  templateUrl: "./payroll.component.html",
  styleUrls: ["./payroll.component.scss"],
})
export class PayrollComponent implements OnInit, AfterViewInit {
  empListSourceRaw: any;
  today: Date;
  public empListSource: MatTableDataSource<any>;
  allEmpDetails: any;
  empListDataSource: any;
  allPayrollDetails: any;
  employeePayrollDetails: any;
  parsedEmployeePayrollDetails: any;
  durationInSeconds = 5;
  selectedEmployeeDetails: any;
  selectedPayslipMonth: number;
  selectedPayslipYear: number;
  years: number[] = [];
  months: { [month: number]: string } = MONTHS;
  displayedColumns: string[] = DISPLAYED_COLUMNS;
  payrollDetails: EmployeePayrollDetails;
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.empListDataSource) {
      this.empListDataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("payslipPDF", { static: true }) payslipPDF: ElementRef;
  url: any = "";
  totalEmployees: any;
  isScreenSizeLessThanMdBreakPoint = false;
  totalPayableAmount: number;
  formattedDate: string;
  payslipTitleText: string;
  showLoading: boolean = false;
  searchInputValue: string = "";
  showSpinner:boolean = true;
  openEarningsAndDeductions: boolean = false;
  currentEmployeeElement:any;
  constructor(
    public dialog: MatDialog,
    private employeeService: EmployeeService,
    private _snackBar: MatSnackBar,
    private payrollService: PayrollService,
    private spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef,
    private snackBarService: SnackBarService
  ) {
    this.today = new Date();
    this.years = Array.from(
      new Array(11),
      (x, i) => this.getAdjustedYear() - i
    );
  }
  ngAfterViewInit(): void {
    this.setIsScreenSizeLessThanMdBreakPoint();
    this.setCurrentYear();
    this.getAllPayrollDetails(
      this.selectedPayslipYear,
      this.selectedPayslipMonth
    );
    this.cdr.detectChanges();
  }
  ngOnInit() {
    this.selectedPayslipMonth = this.getAdjustedMonth()+1;
    this.getAllEmployeesDetails();
    this.formattedDate = getFormattedDate(getFirstDayOfNextMonth(this.today));
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.setIsScreenSizeLessThanMdBreakPoint();
  }

  async payslipExist(
    employeeId: string,
    dialog: any,
    element: EmployeeDetails
  ) {
    try {
      const res = await this.payrollService
        .getPayrollDetailsApi(
          employeeId,
          this.selectedPayslipMonth,
          this.selectedPayslipYear
        )
        .pipe(
          take(1),
          catchError((error) => {
            this.openSnackbar(error.error.message, SNACKBAR_LABEL);
            return of(null);
          })
        )
        .toPromise();

      const payslipExists = !!res;

      if (payslipExists) {
        this.openPayslipDialog(dialog, element);
        this.payrollDetails = res;
      }
    } catch (error) {
      this.openSnackbar("Error fetching payroll details", SNACKBAR_LABEL);
    }
  }

  setIsScreenSizeLessThanMdBreakPoint() {
    this.isScreenSizeLessThanMdBreakPoint =
      window.innerWidth >= MD_SCREEN_SIZE_BREAKPOINT ? false : true;
  }

  openConfirmationDialog() {
    this.dialog.open(PayrollDialogComponent, {
      data: {
        title: "Confirmation",
        msg: `Do you want to trigger monthly payroll generation for
${this.months[this.selectedPayslipMonth]} ${this.selectedPayslipYear} ?`,
        dialogType: "post",
        onYesClickFunction:(param?: any) => this.calculateMonthlyPayroll(param),
      } as PayrollDialogComponentInput,
    });
  }

  setCurrentMonth() {
    this.selectedPayslipMonth = new Date().getMonth() + 1;
  }

  setCurrentYear() {
    this.selectedPayslipYear = this.getAdjustedYear();
  }

  getAllPayrollDetails(year, month) {
    this.payrollService.getAllPayrollDetailsApi(year, month).subscribe(
      (res) => {
        this.totalPayableAmount = res.payableAmount;
        this.allPayrollDetails = res.employees;
      },
      (error) => {
        this.snackBarService.add({
          message: "Get all Payroll Details api failed",
          action: "close",
          config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
        });
        console.log("get all payroll details failed", error);
      }
    );
  }

  getAllEmployeesDetails() {
    this.employeeService.getAllEmployeesDetailsApi().subscribe(
      (res) => {
        this.allEmpDetails = res;
        this.empListDataSource = new MatTableDataSource<any>(
          this.allEmpDetails
        );
        this.empListDataSource.sort = this.sort;
        this.empListDataSource.paginator = this.paginator;
        this.empListDataSource.filterPredicate = (data, filter) => {
          let dataStr = "";
          for (const col of COLUMNS_FOR_FILTER) {
            dataStr += data[col];
            // since we have firstName and lastName separately in the data source, adding space so that filter can work
            if (col === "firstName") {
              dataStr += " ";
            }
          }
          return dataStr.toLowerCase().indexOf(filter) !== -1;
        };
      },
      (error) => {
        this.snackBarService.add({
          message: `It's taking a bit long than expected, please try to reload the page.`,
          action: "close",
          config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
        });
        this.showSpinner = false;
        console.log("get all emp details failed", error);
      }
    );
  }

  getPayrollData(employeeId: string, col: string) {
    return this.allPayrollDetails
      ? this.allPayrollDetails[employeeId]
        ? this.allPayrollDetails[employeeId][col]
          ? this.allPayrollDetails[employeeId][col]
          : ""
        : ""
      : "";
  }

  onMonthChange() {
    this.getAllPayrollDetails(
      this.selectedPayslipYear,
      this.selectedPayslipMonth
    );
  }

  onYearChange() {
    if (this.selectedPayslipYear === this.getAdjustedYear()) {
      if (this.selectedPayslipMonth > this.getAdjustedMonth() + 1) {
        this.selectedPayslipMonth = 1;
      }
    }
    this.getAllPayrollDetails(
      this.selectedPayslipYear,
      this.selectedPayslipMonth
    );
  }

  openPayslipDialog(templateRef: TemplateRef<any>, element: EmployeeDetails) {
    this.selectedPayslipMonth = this.selectedPayslipMonth;
    this.selectedPayslipYear = this.selectedPayslipYear;
    this.payslipTitleText = `${
      element.firstName
    } ${element.lastName.trim()}'s Payslip`;
    let dialogRef = this.dialog.open(templateRef, {
      width: "800px",
      height: "600px",
    });
    this.selectedEmployeeDetails = this.allEmpDetails.filter(
      (el) => el.employeeId.toLowerCase() === element.employeeId.toLowerCase()
    )[0];
    dialogRef.afterClosed().subscribe((result) => {
      this.onClose();
    });
  }

  onClose() {
    this.dialog.closeAll();
    this.url = "";
    this.selectedEmployeeDetails = null;
    this.employeePayrollDetails = null;
  }

  calculateMonthlyPayroll(param?:any) {
    this.spinnerService.openSpinnerDialog();
    this.payrollService
      .calculateMonthlyPayrollApi(
        this.selectedPayslipYear,
        this.selectedPayslipMonth
      )
      .subscribe(
        (res) => {
          this.spinnerService.closeSpinnerDialog();
          setTimeout(() => {
            this.openSnackbar(res.message, "Close");
          }, 500);
        },
        (error) => {
          this.spinnerService.closeSpinnerDialog();
          this.openSnackbar("'Monthly payroll calculation failed'", "Close");
          console.log("calculateMonthlyPayroll failed", error);
        }
      );
  }

  getBankTransferSheet() {
    const snackbarRef = this.snackBarService.open("Cancel", 0)
    snackbarRef.progress=1;
    snackbarRef.runProgressBar();
    this.payrollService
      .getPayrollExcelApi(this.selectedPayslipYear, this.selectedPayslipMonth)
      .subscribe((res) => {
        let url = window.URL.createObjectURL(res.body);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `Bank_Transfer_Sheet_${this.months[
          this.selectedPayslipMonth
        ].toUpperCase()}_${this.selectedPayslipYear}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        snackbarRef.progress=100;
      snackbarRef.runProgressBar();
      setTimeout(() => {
        snackbarRef.closeSnackbar();
      }, 2000);
      },
    (err)=>{
      snackbarRef.progressText="Error occurred in downloading bank transfer sheet";
      setTimeout(() => {
        snackbarRef.closeSnackbar();
      }, 2000);
    });
  }

  getSalarySheet() {
    const snackbarRef = this.snackBarService.open("Cancel", 0)
    snackbarRef.progress=1;
    snackbarRef.runProgressBar();
    this.payrollService
      .getPayrollExcelSalarySheetApi(
        this.selectedPayslipYear,
        this.selectedPayslipMonth
      )
      .subscribe((res) => {
        let url = window.URL.createObjectURL(res.body);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `Salary_Sheet_${this.months[
          this.selectedPayslipMonth
        ].toUpperCase()}_${this.selectedPayslipYear}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        snackbarRef.progress=100;
      snackbarRef.runProgressBar();
      setTimeout(() => {
        snackbarRef.closeSnackbar();
      }, 2000);
      },
    (err)=>{
      snackbarRef.progressText="Error occurred in downloading salary sheet";
    setTimeout(() => {
      snackbarRef.closeSnackbar();
    }, 2000);
    });
  }

  getIsTooltipRequiredForCol(col: string): boolean {
    return COLS_WHICH_REQUIRE_TOOLTIP.includes(col);
  }

  openSnackbar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: this.durationInSeconds * 1000,
      panelClass: ['custom-snackbar-class']
    });
  }

  openAddEmpDialog(): void {
    const dialogRef = this.dialog.open(AddEmployeeDetailsComponent, {
      width: "1154px",
      height: "610px",
      panelClass: "my-panel-class",
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  onSearch($event: Event) {
    const filterString = ($event.target as HTMLInputElement).value;
    this.empListDataSource.filter = filterString.trim().toLowerCase();
    if (this.empListDataSource.paginator) {
      this.empListDataSource.paginator.pageIndex = 0;
    }
  }

  columnExist(
    employeeId: string,
    dialog: any,
    element: any,
    columnName: string
  ): void {
    if (columnName === EMPLOYEE_ID) {
      this.payslipExist(employeeId, dialog, element);
    }
  }

  getAdjustedMonth(): number {
    const now = new Date();
    const month = now.getMonth();
    const date = now.getDate();

    if (date >= 25) {
      return month;
    } else {
      return month === 0 ? 11 : month - 1;
    }
  }

  getAdjustedYear(): number {
    const now = new Date();
    const month = now.getMonth();
    const date = now.getDate();

    if (month > 0 || (month === 0 && date > 24)) {
      return now.getFullYear();
    }
    return now.getFullYear() - 1;
  }

  showMonth(monthKey: number): boolean {
    // getAdjustedMonth return months with zero based indexing
    --monthKey;
    if (
      this.getAdjustedYear() === this.selectedPayslipYear &&
      monthKey > this.getAdjustedMonth()
    )
      return false;
    return true;
  }
  
  toggleEarningsAndDeductions(element){
    this.currentEmployeeElement = element;
    this.openEarningsAndDeductions = !this.openEarningsAndDeductions
  }

  toggleChildComponent(value:boolean){
    this.openEarningsAndDeductions = value;
  }
}
