import { Component, Input } from "@angular/core";
import { EmployeeDetails } from "src/app/core/interfaces/user";
import { UserDetails } from "../payslip.utility";
@Component({
  selector: "app-employee-payslip-details",
  templateUrl: "./employee-payslip-details.component.html",
  styleUrls: ["./employee-payslip-details.component.scss"],
})
export class EmployeePayslipDetailsComponent {
  showDetails: boolean = true;
  @Input() userDetails: UserDetails[];
  @Input() selectedEmployeeDetails: EmployeeDetails;

  toggleInfo(): void {
    this.showDetails = !this.showDetails;
  }

  trackByEmployeeDetail(detail: UserDetails): string {
    return detail.objKey;
  }
}
