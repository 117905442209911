import { Component, Input } from "@angular/core";
import { PayslipComponentOption } from "../payslip.utility";

@Component({
  selector: "app-income-card",
  templateUrl: "./income-card.component.html",
  styleUrls: ["./income-card.component.scss"],
})
export class IncomeCardComponent {
  @Input() income: PayslipComponentOption;
  @Input() total: PayslipComponentOption;
  @Input() amountType: string;

  trackByIncomeComponent(data: PayslipComponentOption): string {
    return data.label;
  }
}
