import * as tslib_1 from "tslib";
import { ElementRef, OnInit, QueryList, } from "@angular/core";
import { getCurrentFiscalYear } from "../shared/app.utility";
var HEADERS = [
    { displayName: "Income", mapping: "Income" },
    { displayName: "Deductions", mapping: "Deductions" },
    { displayName: "Days Worked", mapping: "DAYS" },
    { displayName: "Net Pay", mapping: "Net Pay" },
];
export var MONTHS_MAPPING = {
    january: 0,
    february: 1,
    march: 2,
    april: 3,
    may: 4,
    june: 5,
    july: 6,
    august: 7,
    september: 8,
    october: 9,
    november: 10,
    december: 11,
};
var EmployeeYtdTableData = /** @class */ (function () {
    function EmployeeYtdTableData(items) {
        if (items === void 0) { items = ""; }
        this.items = "";
        this.total = 0;
        this.april = 0;
        this.may = 0;
        this.june = 0;
        this.july = 0;
        this.august = 0;
        this.september = 0;
        this.october = 0;
        this.november = 0;
        this.december = 0;
        this.january = 0;
        this.february = 0;
        this.march = 0;
        this.items = items;
    }
    return EmployeeYtdTableData;
}());
var YtdDetailsComponent = /** @class */ (function () {
    function YtdDetailsComponent(ytdDetailsService, snackBarService, loginService) {
        this.ytdDetailsService = ytdDetailsService;
        this.snackBarService = snackBarService;
        this.loginService = loginService;
        /** Columns displayed in the table. */
        this.displayedColumns = Object.keys(new EmployeeYtdTableData());
        this.expandedElement = null;
        this.ytdDetailsData = {};
        this.itemSectionMapping = {};
        this.ytdDetailsComponentData = {};
        this.headers = HEADERS;
        this.years = [];
        this.fiscal = getCurrentFiscalYear();
        this.showSpinner = true;
    }
    YtdDetailsComponent.prototype.ngOnInit = function () {
        this.employeeDetails = this.loginService.employeeDetails;
        this.years = Array.from(new Array(11), function (x, i) { return getCurrentFiscalYear() - i; });
        this.getYtdDetails();
    };
    YtdDetailsComponent.prototype.dataProcessing = function (ytdDetailsApiData) {
        var e_1, _a, e_2, _b;
        try {
            for (var ytdDetailsApiData_1 = tslib_1.__values(ytdDetailsApiData), ytdDetailsApiData_1_1 = ytdDetailsApiData_1.next(); !ytdDetailsApiData_1_1.done; ytdDetailsApiData_1_1 = ytdDetailsApiData_1.next()) {
                var el = ytdDetailsApiData_1_1.value;
                if (el.components) {
                    this.ytdDetailsData[el.category] = [];
                    for (var componentKey in el.components) {
                        var dataObj = new EmployeeYtdTableData(componentKey);
                        this.itemSectionMapping[componentKey] = el.category;
                        try {
                            for (var _c = (e_2 = void 0, tslib_1.__values(el.components[componentKey])), _d = _c.next(); !_d.done; _d = _c.next()) {
                                var dataItem = _d.value;
                                dataObj[dataItem.payrollKey.toLowerCase()] = dataItem.amount;
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        if (el.category === "DAYS") {
                            dataObj["total"] = dataObj["total working days"];
                        }
                        this.ytdDetailsData[el.category].push(dataObj);
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (ytdDetailsApiData_1_1 && !ytdDetailsApiData_1_1.done && (_a = ytdDetailsApiData_1.return)) _a.call(ytdDetailsApiData_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    YtdDetailsComponent.prototype.isIncome = function (row) {
        return this.itemSectionMapping[row.items] === "INCOME";
    };
    YtdDetailsComponent.prototype.isDeduction = function (row) {
        return this.itemSectionMapping[row.items] === "DEDUCTIONS";
    };
    YtdDetailsComponent.prototype.getYtdDetails = function () {
        var _this = this;
        var id = this.employeeDetails.employeeId;
        this.ytdDetailsData = {};
        this.ytdDetailsService.getYtdDetailsAPI(id, this.fiscal).subscribe(function (res) {
            _this.ytdData = res;
            _this.dataProcessing(res);
        }, function (err) {
            _this.snackBarService.add({
                message: "Get all Payroll Details api failed",
                action: "close",
                config: { duration: 2000, panelClass: ["custom-snackbar-class"] },
            });
            _this.showSpinner = false;
        });
    };
    YtdDetailsComponent.prototype.getYearByMonth = function (month) {
        if (month === "january" || month === "february" || month === "march") {
            return ("" + (this.fiscal + 1)).substring(2, 4);
        }
        else {
            return ("" + this.fiscal).substring(2, 4);
        }
    };
    YtdDetailsComponent.prototype.isMonthName = function (col) {
        var months = [
            "january",
            "february",
            "march",
            "april",
            "may",
            "june",
            "july",
            "august",
            "september",
            "october",
            "november",
            "december",
        ];
        var lowerCaseStr = col.toLowerCase();
        return months.includes(lowerCaseStr);
    };
    YtdDetailsComponent.prototype.getMonthAbbreviation = function (monthName) {
        var abbreviation = monthName.substring(0, 3);
        abbreviation = abbreviation.charAt(0).toUpperCase() + abbreviation.slice(1);
        return abbreviation;
    };
    YtdDetailsComponent.prototype.getTdId = function (i, j, k) {
        return "td-" + i + "-" + j + "-" + k;
    };
    YtdDetailsComponent.prototype.showTooltip = function (tdId) {
        var tdElement = document.getElementById(tdId);
        if (tdElement) {
            var tdWidth = tdElement.offsetWidth;
            var contentWidth = tdElement.scrollWidth;
            return contentWidth > tdWidth;
        }
    };
    YtdDetailsComponent.prototype.shouldDisplayWorkingDays = function (col, header) {
        var date = new Date();
        var currMonth = date.getMonth();
        if (header.mapping === "DAYS" && col !== "total") {
            if (currMonth > 3) {
                if (MONTHS_MAPPING[col] >= currMonth || MONTHS_MAPPING[col] < 3) {
                    return false;
                }
            }
            else {
                if (currMonth === 3 ||
                    (MONTHS_MAPPING[col] >= currMonth && MONTHS_MAPPING[col] < 3)) {
                    return false;
                }
            }
        }
        return true;
    };
    YtdDetailsComponent.prototype.shouldDisplayValue = function (col, header) {
        return col !== "items" && this.shouldDisplayWorkingDays(col, header);
    };
    return YtdDetailsComponent;
}());
export { YtdDetailsComponent };
