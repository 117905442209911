import { PublicClientApplication, } from "@azure/msal-browser";
import { environment } from "../environments/environment";
import * as i0 from "@angular/core";
var AuthConfigService = /** @class */ (function () {
    function AuthConfigService() {
        this.publicClientApp = new PublicClientApplication({
            auth: {
                clientId: environment.clientId,
                authority: "https://login.microsoftonline.com/b9806c7d-9280-4e44-afea-6dc0ff495c2f",
                // redirectUri: "http://localhost:4200"
                redirectUri: environment.redirectUri,
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false,
            },
        });
    }
    AuthConfigService.prototype.getPublicClientApp = function () {
        if (!this.publicClientApp) {
            throw new Error("PublicClientApplication instance is not initialized");
        }
        return this.publicClientApp;
    };
    AuthConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthConfigService_Factory() { return new AuthConfigService(); }, token: AuthConfigService, providedIn: "root" });
    return AuthConfigService;
}());
export { AuthConfigService };
